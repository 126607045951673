
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { get } from 'lodash'
import StopPage from './StopPage'

const mapStateToProps = state => ({
  locale: state.locale,
  cmsMessages: get(state, 'content.cmsMessages', {}),
})

export default withRouter(connect(mapStateToProps)(StopPage))
