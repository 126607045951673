/**
 * @file Container component connecting presentational React component to Redux state
 * @see {@link http://redux.js.org/docs/basics/UsageWithReact.html}
 * @author Sami Tiilikainen
 */

import { find, size, get } from 'lodash'
import { connect } from 'react-redux'
import { formValueSelector } from 'redux-form'
import { withRouter } from 'react-router-dom'
import { injectIntl } from 'react-intl'
import RowsList from '../components/RowsList/RowsList'
import { INTRASTAT_FORM_NAME } from '../../../constants'
import { fetchCN8Codes, setOnlyInvalidRowsVisibility } from '../actions'
import { getSimstatMode, rowHasItemNumber } from '../../../utils'

const formSelector = formValueSelector(INTRASTAT_FORM_NAME)

const mapStateToProps = (state) => {
  const rows = formSelector(state, 'rows')
  const referencePeriod = formSelector(state, 'referencePeriod')
  const codeset = get(state, `intrastat.common.cachedCodesets[${referencePeriod}]`)
  const showOnlyInvalidRows = get(state, 'intrastat.declaration.showOnlyInvalidRows')
  const containsErrors = Boolean(rows && rows.length && find(rows, it => size(it.warnings)))
  const referencePeriodYear = referencePeriod.substr(0, 4)
  const hasItemNumber = rowHasItemNumber(rows)

  const secondaryUnits = {}
  if (codeset && codeset.secondaryUnits) {
    codeset.secondaryUnits.forEach((secondaryUnit) => {
      secondaryUnits[secondaryUnit.code] = secondaryUnit.abbreviation
    })
  }

  const flowCode = formSelector(state, 'flowCode')
  const psi = formSelector(state, 'psi')

  return {
    rows,
    rowCount: rows && rows.length,
    rowErrors: state.form[INTRASTAT_FORM_NAME].syncErrors,
    containsErrors,
    codeset,
    currentRow: state.intrastat.declaration.currentRow,
    referencePeriod,
    cn8Codes: get(state, `intrastat.declaration.cachedCN8Codes[${referencePeriodYear}]`),
    cn8Locale: get(state, 'intrastat.declaration.cachedCN8Codes.cn8Locale'),
    secondaryUnits,
    showOnlyInvalidRows,
    getSimstatMode: () => getSimstatMode(referencePeriod, flowCode, psi, state.intrastat.common.cachedCustomers),
    hasItemNumber,
  }
}

const mapActionCreators = {
  fetchCN8Codes,
  setOnlyInvalidRowsVisibility,
}
export default withRouter(
  connect(mapStateToProps, mapActionCreators)(
    injectIntl(
      RowsList
    )))
