import React from 'react'
import { ReactReduxContext } from 'react-redux'
import { injectReducer } from './root'

export const withReducer = (keyReducerPairs) => (WrappedComponent) => {
  class Extended extends React.Component {
    static WrappedComponent = WrappedComponent

    componentWillMount() {
      const { store } = this.context

      keyReducerPairs.forEach(item => injectReducer(store, { key: item.key, reducer: item.reducer }))
    }

    render() {
      return <WrappedComponent {...this.props} />
    }
  }

  Extended.contextType = ReactReduxContext

  return Extended
}

export default withReducer