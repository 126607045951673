import React from 'react'
import { FormControl } from 'react-bootstrap'
import InteractiveElement from 'src/components/InteractiveElement'
import Icon from 'src/components/Icon'
import styles from 'src/styles/_forms.scss'
import { FormattedMessage } from 'react-intl'
import { isObject } from 'lodash'
import './CN8Browser.scss'

export default class CN8TextSearchBox extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      value: '',
    }
  }

  render() {
    const { search, placeholder, searchText } = this.props
    return (
      <div className="form-inline pull-right">
        <div style={{ position: 'relative' }}>
          <FormControl
            type="text"
            value={this.state.value}
            placeholder={placeholder}
            onChange={(event) => { this.setState({ value: event.target.value }) }}
            onKeyDown={event => (event.keyCode === 13) && search(this.state.value)}
            ref={(ref) => { this.inputRef = ref }}
            autoFocus
            className="cn8-text-search-box"
            id-qa-test="input-cn8-text-search"
            aria-label={this.props.label}
          />
          <InteractiveElement
            onClick={() => search(this.state.value)}
            className={styles.inputActionIcon}
            id-qa-test="btn-search-cn8"
          >
            <Icon name="search" />
            <span className="sr-only">{isObject(searchText) ? <FormattedMessage {...searchText} /> : searchText}</span>
          </InteractiveElement>
        </div>
      </div>
    )
  }
}
