import React, { useEffect } from 'react'
import { Col, Row, Button } from 'react-bootstrap'
import { get, isEmpty } from 'lodash'
import moment from 'moment'
import Icon from 'src/components/Icon'
import ErrorBoundary from 'src/components/ErrorBoundary'
import PanelNavigation from 'src/components/PanelNavigation'
import Heading from 'src/layout/Heading'

import { FORMS_ROUTE_PATH } from '../../../constants'
import { LOCATION_INFORMATION_FORM_ROUTE_PATH } from '../../constants'
import SummaryContent from '../summary/SummaryContent'

export default function PrintSummary(props) {
  const {
    formData,
    history,
    cmsMessages,
  } = props

  useEffect(() => {
    if (isEmpty(props.formData)) {
      history.replace(`/${FORMS_ROUTE_PATH}/${LOCATION_INFORMATION_FORM_ROUTE_PATH}`)
    }
  }, [formData])

  const title = cmsMessages['/forms/location-information/summary/title']
  const panelHeading = cmsMessages['/forms/location-information/summary/panelHeading']
  const printButtonText = cmsMessages['/forms/location-information/summary/printButtonText']
  const printPageLabel = cmsMessages['/forms/location-information/summary/printPageLabel']
  const closePrintViewButtonText = cmsMessages['/forms/location-information/summary/closePrintViewButtonText']
  const date = get(formData, 'date', '')
  const dateFormatted = moment(date).format('DD.MM.YYYY HH:mm:ss')
  const code = get(formData, 'id', '')

  const logoRowButtons = (
    <div className="btn-toolbar pull-right">
      <Button onClick={() => window.print()} id-qa-test="btn-print">
        <Icon name="printer" />{printButtonText}
      </Button>
      <Button onClick={() => history.goBack()} id-qa-test="btn-close">
        <Icon name="close" />{closePrintViewButtonText}
      </Button>
    </div>
  )

  const heading = (
    <Heading
      message={title}
      logoRowContent={logoRowButtons}
      print
      titleExtras={
        <span className="sr-only">
          {printPageLabel}
        </span>
      }
    />
  )

  const summaryTitle = `${panelHeading} (${dateFormatted}, ${code})`

  return (
    <div>
      {heading}
      <main className="container" id="main">
        <Row>
          <Col xs={12}>
            <ErrorBoundary>
              <PanelNavigation
                title={summaryTitle}
                active
              >
                <SummaryContent
                  formData={formData}
                  cmsMessages={cmsMessages}
                />
              </PanelNavigation>
            </ErrorBoundary>
          </Col>
        </Row>
      </main>
    </div>
  )
}
