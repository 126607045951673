import { connect } from 'react-redux'
import { reduxForm, getFormValues } from 'redux-form'
import { withRouter } from 'react-router-dom'
import { injectIntl } from 'react-intl'
import { get, omit } from 'lodash'
import Summary from './Summary'
import { fetchCN8Codes, selectRow, setOnlyInvalidRowsVisibility } from '../rows/actions'
import { fetchNonCachedCodesets } from '../../../actions'
import { getSimstatMode, rowHasItemNumber } from '../../utils'
import {
  invalidateDeclaration,
  toggleAmendmentModal,
  toggleCopyModal,
  createAndOpenNewVersion,
  createAndOpenNewVersionWithoutTdp,
  createAndOpenNewCopy,
} from '../../actions'
import {
  INTRASTAT_FORM_NAME,
  DECLARATION_DEFACED,
  DECLARATION_VERSION_CREATED,
  DECLARATION_COPIED,
  DECLARATION_HEADERS_FETCHED,
  DECLARATION_ROWS_FETCHED,
} from '../../constants'
import { INTRASTAT_CACHE_CUSTOMERS } from '../../../constants'
import { getSelectedAuthorization, getSelectedDelegateCompanyFromAuthentication } from 'src/utils/auth'

const formConfig = {
  form: INTRASTAT_FORM_NAME,
  shouldValidate: () => false,
  destroyOnUnmount: true,
}

const getIntrastatFormValues = getFormValues(INTRASTAT_FORM_NAME)
const mapStateToProps = (state) => {
  const intrastatForm = getIntrastatFormValues(state)
  if (!intrastatForm) {
    return { fetchingDeclaration: true }
  }

  const {
    showAmendmentModal,
    showCopyModal,
    selectedRow,
  } = state.intrastat.declaration

  const {
    cachedCustomers,
  } = state.intrastat.common

  const fetchingCustomers = state.loading[INTRASTAT_CACHE_CUSTOMERS]
  const invalidatingDeclaration = state.loading[DECLARATION_DEFACED]
  const creatingNewVersion = state.loading[DECLARATION_VERSION_CREATED]
  const creatingNewCopy = state.loading[DECLARATION_COPIED]
  const fetchingDeclaration = state.loading[DECLARATION_HEADERS_FETCHED]
  const fetchingDeclarationRows = state.loading[DECLARATION_ROWS_FETCHED]

  const referencePeriodYear = intrastatForm.referencePeriod && intrastatForm.referencePeriod.substr(0, 4)
  const codeset = get(state, `intrastat.common.cachedCodesets[${intrastatForm.referencePeriod}]`)
  const showOnlyInvalidRows = get(state, 'intrastat.declaration.showOnlyInvalidRows')
  const secondaryUnits = {}
  if (codeset && codeset.secondaryUnits) {
    codeset.secondaryUnits.forEach((secondaryUnit) => {
      secondaryUnits[secondaryUnit.code] = secondaryUnit.abbreviation
    })
  }

  return {
    auth: state.auth,
    locale: state.locale,
    declaration: omit(intrastatForm, 'rows'),
    rows: intrastatForm.rows,
    showAmendmentModal,
    creatingNewVersion,
    showCopyModal,
    creatingNewCopy,
    codeset,
    showOnlyInvalidRows,
    secondaryUnits,
    cn8Codes: get(state, `intrastat.declaration.cachedCN8Codes[${referencePeriodYear}]`),
    cn8Locale: get(state, 'intrastat.declaration.cachedCN8Codes.cn8Locale'),
    selectedAuthorizationObject: getSelectedAuthorization(),
    selectedDelegateCompanyObject: getSelectedDelegateCompanyFromAuthentication(),
    fetchingCustomers,
    cachedCustomers,
    fetchingDeclaration,
    fetchingDeclarationRows,
    getSimstatMode: () => getSimstatMode(
      intrastatForm.referencePeriod,
      intrastatForm.flowCode,
      intrastatForm.psi,
      state.intrastat.common.cachedCustomers
    ),
    invalidatingDeclaration,
    selectedRow,
    nilDeclaration: intrastatForm.noDeclarationRows,
    hasItemNumber: rowHasItemNumber(intrastatForm.rows),
  }
}

const mapActionCreators = {
  selectRow,
  onShowAmendmentModal: () => toggleAmendmentModal(true),
  onHideAmendmentModal: () => toggleAmendmentModal(false),
  onShowCopyModal: () => toggleCopyModal(true),
  onHideCopyModal: () => toggleCopyModal(false),
  createAndOpenNewVersion,
  createAndOpenNewVersionWithoutTdp,
  createAndOpenNewCopy,
  fetchCN8Codes,
  invalidateDeclaration,
  setOnlyInvalidRowsVisibility,
  fetchCodesets: date =>
    fetchNonCachedCodesets(
      [
        'transactionTypes',
        'transportTypes',
        'memberStates',
        'countriesOfOrigin',
        'countriesOfOriginForSimstat',
        'currencies',
        'secondaryUnits',
      ],
      date
    ),
}

export default withRouter(
  connect(mapStateToProps, mapActionCreators)(
    reduxForm(formConfig)(
      injectIntl(
        Summary
      ))))
