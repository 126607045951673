import React from 'react'
import { defineMessages } from 'react-intl'
import InfoAlert from 'src/components/InfoAlert'
import Modal from 'src/components/Modal'
import { withRouter } from 'react-router-dom'
import { formatReferencePeriod } from '../utils'
import { INTRASTAT_ROUTE_PATH } from '../../constants'

// Header-related intl message definitions
const messages = defineMessages({
  title: {
    id: 'intrastat.confirmClose.title',
    description: 'Title for intrastat confirm close edit modal',
    defaultMessage: 'Are you sure you want to withdraw editing?',
  },
  description: {
    id: 'intrastat.confirmClose.description',
    description: 'Description for confirm close edit modal, containing variables declarationId and referencePeriod',
    defaultMessage:
      'In case of withdrawing the changes of declaration {declarationId} for period {referencePeriod} will be lost.',
  },
  continue: {
    id: 'intrastat.confirmClose.continue',
    description: 'Label for intrastat confirm close edit submit button',
    defaultMessage: 'Withdraw editing',
  },
  cancel: {
    id: 'intrastat.confirmClose.cancel',
    description: 'Label for intrastat confirm close edit cancel button',
    defaultMessage: 'Cancel',
  },
})

function ConfirmCloseModal(props) {
  const {
    show,
    onHide,
    history,
    declarationId,
    referencePeriod,
  } = props
  const loading = false
  return (
    <Modal
      backdrop
      show={show}
      showCancel
      showContinue
      cancelDisabled={loading}
      continueDisabled={loading}
      continueMessage={messages.continue}
      cancelMessage={messages.cancel}
      onClickCancel={onHide}
      onClickContinue={() => history.push(`/${INTRASTAT_ROUTE_PATH}`)}
      loading={loading}
      continueBtnStyle="primary"
      titleMessage={messages.title}
      focusButton
      focusDisableButton
    >

      <InfoAlert
        id={'dialog-message'}
        message={messages.description}
        values={{ declarationId, referencePeriod: formatReferencePeriod(referencePeriod) }}
      />
    </Modal>
  )
}

export default withRouter(ConfirmCloseModal)
