import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { injectIntl, FormattedMessage } from 'react-intl'
import { Row, Col, Button, Alert } from 'react-bootstrap'
import { find, xor, get, filter, concat, some, unionBy, set, has, omit } from 'lodash'
import Dropzone from 'react-dropzone'
import moment from 'moment'
import Icon from 'src/components/Icon'
import showNotification from 'src/utils/notifications'
import { uniqueId, collectCmsMessages } from 'src/utils'
import InfoElementHeaderArea from 'src/components/form_v2/InfoElementHeaderArea'
import KiloDescriptionInfoArea from '../KiloDescriptionInfoArea'
import PermitPageContext from '../PermitPage/context'
import {
  MAX_FILE_SIZE,
  ATTACHED_DOCUMENT_TYPE_CODE,
  ATTACHED_DOCUMENT_ID_CODE,
  ATTACHED_DOCUMENT_DATE_CODE,
} from './constants'
import AttachmentInformationModal from './components/AttachmentInformationModal'
import AttachmentList from './components/AttachmentList'
import api from '../../../api'
import {
  startAttachmentUploading,
  stopAttachmentUploading,
  attachmentsInProgress,
} from '../../actions'
import { readFile, isAllowedFileFormat } from './utils'
import attachmentMessages from './messages'
import commonMessages from '../../messages'
import { getPermitTypeFromReferenceNumber } from '../../utils'
import { PERMITS_TO_HIDE_KILO_DESCRIPTION } from '../../constants'

const messages = {
  ...commonMessages,
  ...attachmentMessages,
}
class Attachments extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      rejectedFiles: [],
      isFetching: false,
      showInformationModal: false,
      selectedAttachment: null,
      fileObjects: [],
      showErrorLoadingAttachments: false,
      showErrorUploadingAttachments: false,
    }
    this.getAttachmentsTimeoutFn = null
    this.isReadOnly = false
  }

  componentDidMount() {
    this.isReadOnly = has(this.props, 'type') && this.props.type === 'readonly'
    this.getAttachments()
  }

  componentWillUnmount() {
    if (this.getAttachmentsTimeoutFn) {
      clearTimeout(this.getAttachmentsTimeoutFn)
    }
  }

  onDeleteAttachment = (attachmentId) => {
    const { formApi, saveDraft } = this.props
    const attachments = get(formApi.values, 'attachedDocument') || []
    const newAttachments = filter(attachments, attachment => attachment.id !== attachmentId)
    const attachmentCount = get(formApi.values, 'attachedDocumentCount.attachedDocumentCount')
    formApi.setValue('attachedDocument', newAttachments)
    formApi.setValue('attachedDocumentCount.attachedDocumentCount', attachmentCount - 1)
    showNotification({
      level: 'success',
      icon: 'checkmark',
      message: messages.attachmentDeleted,
      autoDismiss: 4,
    })
    this.getAttachments(newAttachments, true)
  }

  getAttachments = (uploadedAttachments, saveAfterUploadingIsDone = false) => {
    const { match: { params: { permitId } }, formApi, setAttachmentStatusInProgress, saveDraft } = this.props
    const attachments = uploadedAttachments || get(formApi.values, 'attachedDocument') || []
    const referenceNumber = permitId.split(':')[0]
    this.setState({ isFetching: true })
    api.fetchAttachments(referenceNumber)
      .then((response) => {
        if (attachments.length > 0 && response.length === 0) {
          return Promise.reject()
        }
        const updatedAttachments = []
        const failedAttachments = []
        const attachmentsToKeep = []
        let allAttachments
        attachments.forEach((attachment) => {
          const currentFile = find(response, file => file.id === attachment[ATTACHED_DOCUMENT_ID_CODE])
          if (currentFile) {
            if (currentFile.conversionStatus === 'FAILED') {
              failedAttachments.push(currentFile)
            } else {
              updatedAttachments.push({
                ...attachment,
                id: attachment[ATTACHED_DOCUMENT_ID_CODE],
                status: currentFile.conversionStatus,
                filename: currentFile.filename,
              })
            }
          } else if (attachment.status === 'WAIT_UPLOADING') {
            attachmentsToKeep.push(attachment)
          }
        })
        this.setState({
          isFetching: false,
          rejectedFiles: concat(this.state.rejectedFiles, failedAttachments),
        })
        allAttachments = concat(updatedAttachments, attachmentsToKeep)
        formApi.setValue('attachedDocument', allAttachments)
        formApi.setValue('attachedDocumentCount.attachedDocumentCount', updatedAttachments.length)
        if (some(updatedAttachments, { status: 'IN_PROGRESS' })) {
          setAttachmentStatusInProgress(true)
          if (this.getAttachmentsTimeoutFn) {
            clearTimeout(this.getAttachmentsTimeoutFn)
          }
          this.getAttachmentsTimeoutFn = setTimeout(() => {
            this.getAttachments(attachments, saveAfterUploadingIsDone)
          }, 5000)
        } else {
          setAttachmentStatusInProgress(false)
          if (this.state.rejectedFiles.length > 0 || saveAfterUploadingIsDone) {
            saveDraft({
              ...formApi.values,
              attachedDocument: allAttachments,
            })
          }
        }
        return null
      })
      .catch(() => {
        this.setState({
          showErrorLoadingAttachments: true,
          isFetching: false,
        })
      })
  }

  static contextType = PermitPageContext

  addAttachments = (files, rejectedFiles) => {
    const { formApi, infoElement } = this.props
    if (!has(formApi.values, infoElement.code)) {
      formApi.values[infoElement.code] = []
    }
    const attachments = formApi.values[infoElement.code]
    const fileObjects = {}
    const rejectedFilesByFormat = filter(files, file => !isAllowedFileFormat(file.name))
    const acceptedFiles = xor(files, rejectedFilesByFormat)
    acceptedFiles.forEach((file) => {
      const generatedId = uniqueId()
      attachments.push({
        id: generatedId,
        [ATTACHED_DOCUMENT_TYPE_CODE]: null,
        filename: file.name,
        status: 'WAIT_UPLOADING',
      })
      fileObjects[generatedId] = file
    })
    formApi.setValue(infoElement.code, attachments)
    this.setState({
      fileObjects,
      rejectedFiles: rejectedFiles.concat(rejectedFilesByFormat),
      showInformationModal: true,
    })
  }

  saveAttachmentInformationAndStartUpload = (filesWithInfo) => {
    const { formApi, infoElement } = this.props
    const attachmentsList = formApi.values[infoElement.code]
    const alreadyUploading = attachmentsList.length > 1 && some(attachmentsList, { status: 'UPLOADING' })
    formApi.setValue(infoElement.code, unionBy(filesWithInfo, attachmentsList, 'id'))
    this.setState({
      showInformationModal: false,
      rejectedFiles: [],
    })

    if (!alreadyUploading) {
      this.upload(unionBy(filesWithInfo, attachmentsList, 'id'))
    }
  }

  cancelAttachmentInformations = () => {
    const { formApi, infoElement } = this.props
    const attachments = filter(formApi.values[infoElement.code], attachment => attachment.status !== 'WAIT_UPLOADING')
    formApi.setValue(infoElement.code, attachments)
    this.setState({
      showInformationModal: false,
    })
  }

  uploadFile = async (props) => {
    const {
      referenceNumber,
      fileToUpload,
      permitTypeCode,
    } = props
    try {
      const fileContent = await readFile(this.state.fileObjects[fileToUpload.id])
      const response = await api.createAttachment(referenceNumber, permitTypeCode, {
        filename: fileToUpload.filename,
        content: fileContent.split(';base64,').pop(),
        typeCode: get(fileToUpload, 'applicationAttachedDocumentType', ''),
      })
      if (has(response, 'id')) {
        const newFile = {
          ...omit(fileToUpload, ['fileObjId']),
          id: response.id,
          [ATTACHED_DOCUMENT_ID_CODE]: response.id,
          [ATTACHED_DOCUMENT_DATE_CODE]: moment().format('YYYY-MM-DD'),
          status: 'IN_PROGRESS',
        }
        return newFile
      }
    } catch (e) {
      const failed = set(fileToUpload, 'status', 'FAILED')
      showNotification({
        level: 'error',
        message: messages.attachmentGenericUploadErrorMessage,
        modal: true,
        title: messages.errorModalTitle,
      })
      return failed
    }
    return null
  }

  upload = async (attachments) => {
    const {
      match: {
        params: {
          permitId,
        },
      },
      formApi,
      infoElement,
      startUploading,
      stopUploading,
      structure,
      saveDraft,
    } = this.props
    let permitTypeCode = get(structure, 'code')
    if (permitTypeCode === 'PAA' || permitTypeCode === 'KUM') {
      const applicationDecisionReferenceNumber = formApi.values?.applicationDecisionReferenceNumber?.applicationDecisionReferenceNumber
      permitTypeCode = getPermitTypeFromReferenceNumber(applicationDecisionReferenceNumber)
    }
    const referenceNumber = permitId.split(':')[0]
    const filesToUpload = filter(attachments, { status: 'WAIT_UPLOADING' })
    const files = attachments.filter(att => att.status !== 'WAIT_UPLOADING')
    if (filesToUpload.length > 0) {
      startUploading()

      for (const fileToUpload of filesToUpload) {
        const otherFiles = filter(attachments, attachment => attachment.id !== fileToUpload.id)
        formApi.setValue(infoElement.code, concat(set(fileToUpload, 'status', 'UPLOADING'), otherFiles))

        const file = await this.uploadFile({
          fileToUpload,
          referenceNumber,
          permitTypeCode,
        })
        files.push(file)
      }
      saveDraft({
        ...formApi.values,
        attachedDocument: files,
      })
    }
    this.setState({
      fileObjects: {},
    })

    this.getAttachments(files, true)

    stopUploading()
  }

  render() {
    const {
      infoElement,
      locale,
      renderField,
      formApi,
      codesets,
      helpTexts,
      match: {
        params: {
          permitId,
        },
      },
    } = this.props
    const { customerHasAEO } = this.context
    const {
      rejectedFiles,
      showErrorLoadingAttachments,
      showInformationModal,
      isFetching,
    } = this.state

    const attachedDocumentTypeField = find(infoElement.fields, field => field.code === ATTACHED_DOCUMENT_TYPE_CODE)
    const attachedDocumentIdField = find(infoElement.fields, field => field.code === ATTACHED_DOCUMENT_ID_CODE)
    const attachedDocumentDateField = find(infoElement.fields, field => field.code === ATTACHED_DOCUMENT_DATE_CODE)

    const otherFields = xor(infoElement.fields, [
      attachedDocumentTypeField,
      attachedDocumentIdField,
      attachedDocumentDateField,
    ])

    const referenceNumber = permitId.split(':')[0]
    const attachments = filter(formApi.values[infoElement.code], { status: 'WAIT_UPLOADING' })
    const doneAttachments = filter(formApi.values[infoElement.code], { status: 'DONE' })
    const attachedDocumentTypeCodeset = get(codesets, [
      attachedDocumentTypeField.codesetName,
      '##',
      attachedDocumentTypeField.subCodesetName,
    ].join(''), [])

    const hideKiloDescriptionForPermit = PERMITS_TO_HIDE_KILO_DESCRIPTION.includes(formApi.getValue('applicationTypeCode'))

    const showKiloDescriptionInfoArea = !hideKiloDescriptionForPermit && !this.isReadOnly && !customerHasAEO && doneAttachments.length === 0
    const descriptionText = infoElement?.descriptionText

    return (
      <div>
        {showInformationModal && !this.isReadOnly &&
          <AttachmentInformationModal
            attachments={attachments}
            rejectedFiles={rejectedFiles}
            locale={locale}
            infoElement={infoElement}
            renderField={renderField}
            saveAttachments={this.saveAttachmentInformationAndStartUpload}
            cancelAttachmentInformations={this.cancelAttachmentInformations}
            attachmentTypes={this.attachmentTypes}
            defaultAttachmentType={this.defaultAttachmentType}
          />
        }
        {showKiloDescriptionInfoArea &&
          <Col xs={12}>
            <KiloDescriptionInfoArea
              formApi={this.props.formApi}
              helpTexts={helpTexts}
            />
          </Col>
        }
        <Col xs={12}>
          <InfoElementHeaderArea
            infoElement={infoElement}
            locale={locale}
            showHelp={!this.isReadOnly}
            descriptionText={descriptionText}
          />
        </Col>
        <Col xs={12}>
          {!this.isReadOnly && !showErrorLoadingAttachments &&
            <Row>
              <Col xs={12}>
                <Dropzone
                  ref={(node) => { this.dropzoneRef = node }}
                  onDrop={(accepted, rejected) => this.addAttachments(accepted, rejected)}
                  disableClick
                  multiple
                  maxSize={MAX_FILE_SIZE}
                  className="dropzone"
                  activeClassName="dropzone-active"
                >
                  <div className="dropzone-buttons">
                    <Button
                      onClick={() => { this.dropzoneRef.open() }}
                      bsStyle="primary"
                      id-qa-test="btn-add-file"
                    >
                      <Icon name="addnew" /><FormattedMessage {...messages.addAttachment} />
                    </Button>
                  </div>
                  <div className="dropzone-help centered">
                    <FormattedMessage {...messages.attachmentAdditionalHelp} />
                  </div>
                </Dropzone>
              </Col>
            </Row>
          }
          {!showErrorLoadingAttachments &&
            <AttachmentList
              attachments={formApi.values[infoElement.code]}
              rejectedFiles={rejectedFiles}
              attachedDocumentTypeCodeset={attachedDocumentTypeCodeset}
              onDeleteAttachment={this.onDeleteAttachment}
              cancelUploading={this.cancelUploading}
              locale={locale}
              infoElement={infoElement}
              renderField={renderField}
              typeFieldCode={attachedDocumentTypeField.code}
              referenceNumber={referenceNumber}
              readOnly={this.isReadOnly}
              isFetching={isFetching}
              informationModalVisible={showInformationModal}
            />
          }
          {showErrorLoadingAttachments &&
            <Alert bsStyle="danger">
              <FormattedMessage {...messages.attachmentGenericFetchError} />
            </Alert>
          }
          <Row>
            <Col xs={12}>
              {otherFields && otherFields.map(field => renderField({
                field,
              }))}
            </Col>
          </Row>
        </Col>
      </div>
    )
  }
}

const mapStateToProps = state => {
  const cmsMessages = get(state, 'content.cmsMessages', {})
  return {
    helpTexts: collectCmsMessages('/permits', cmsMessages),
    locale: state.locale,
    cmsMessages,
    codesets: get(state, 'codeset.cachedCodesets'),
  }
}

const mapActionCreators = {
  startUploading: startAttachmentUploading,
  stopUploading: stopAttachmentUploading,
  setAttachmentStatusInProgress: attachmentsInProgress,
}

export default withRouter(
  connect(mapStateToProps, mapActionCreators)(
    injectIntl(
      Attachments
    )))
