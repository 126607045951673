import { handleActions, combineActions } from 'redux-actions'
import { get } from 'lodash'
import { INTRASTAT_DESTROY_STATE } from '../constants'
import headersReducerMap from './routes/headers/reducerMap'
import rowsReducerMap from './routes/rows/reducerMap'
import confirmationReducerMap from './routes/confirmation/reducerMap'
import {
  HEADERS_ROUTE_PATH,
  FORM_STEP_CHANGE,
  DECLARATION_IS_DUPLICATE,
  DECLARATION_SET_TO_NEW,
  TOGGLE_PRESERVE_DRAFT_ON_EXIT_MODAL,
  TOGGLE_DECLARATION_AMENDMENT_MODAL,
  TOGGLE_COPY_DECLARATION_MODAL,
  TOGGLE_DELETE_DECLARATION_MODAL,
  TOGGLE_CONFIRM_CLOSE_MODAL,
  DESTROY_DECLARATION_STATE,
  DECLARATION_DELETED,
  DECLARATION_DEFACED,
  DECLARATION_VERSION_CREATED,
  DECLARATION_COPIED,
} from './constants'

const initialState = {
  currentStep: HEADERS_ROUTE_PATH,
  errorDeletingDeclaration: false,
  errorFetchingDeclaration: false,
  errorFetchingDeclarationRows: false,
  errorDeletingRow: false,
  fetchingCN8Tree: false,
  cachedCN8Codes: {},
  importing: false,
  showImportModal: false,
  showAcceptedModal: false,
  showOnlyInvalidRows: false,
  preservedRowFieldValues: {},
}

const commonReducerMap = {
  [FORM_STEP_CHANGE]: (state, action) => ({
    ...state,
    currentStep: action.step,
  }),
  [DECLARATION_IS_DUPLICATE]: (state, { payload }) => ({
    ...state,
    duplicateWarning: payload,
  }),
  [DECLARATION_SET_TO_NEW]: state => ({
    ...state,
    isNew: true,
  }),
  [TOGGLE_PRESERVE_DRAFT_ON_EXIT_MODAL]: (state, { payload }) => ({
    ...state,
    showPreserveDraftOnExitModal: typeof payload === 'boolean' ? payload : !state.showPreserveDraftOnExitModal,
  }),
  [TOGGLE_DECLARATION_AMENDMENT_MODAL]: (state, { payload }) => ({
    ...state,
    showAmendmentModal: typeof payload === 'boolean' ? payload : !state.showAmendmentModal,
  }),
  [TOGGLE_COPY_DECLARATION_MODAL]: (state, { payload }) => ({
    ...state,
    showCopyModal: typeof payload === 'boolean' ? payload : !state.showCopyModal,
  }),
  [TOGGLE_DELETE_DECLARATION_MODAL]: (state, { payload }) => ({
    ...state,
    showDeleteModal: typeof payload === 'boolean' ? payload : !state.showDeleteModal,
  }),
  [TOGGLE_CONFIRM_CLOSE_MODAL]: (state, { payload }) => ({
    ...state,
    showConfirmCloseModal: typeof payload === 'boolean' ? payload : !state.showConfirmCloseModal,
  }),
  [combineActions(DESTROY_DECLARATION_STATE, INTRASTAT_DESTROY_STATE)]: () => ({
    ...initialState,
  }),
  [DECLARATION_DELETED]: {
    next: state => ({
      ...state,
      errorDeletingDeclaration: false,
      lastSave: Date.now(),
    }),
    throw: (state, action) => ({
      ...state,
      errorDeletingDeclaration: get(action, 'meta.message') || true,
    }),
  },
  [DECLARATION_DEFACED]: {
    next: state => ({
      ...state,
      errorInvalidatingDeclaration: false,
      lastSave: Date.now(),
    }),
    throw: (state, action) => ({
      ...state,
      errorInvalidatingDeclaration: get(action, 'meta.message') || true,
    }),
  },
  [DECLARATION_VERSION_CREATED]: {
    next: state => ({
      ...state,
      showAmendmentModal: false,
    }),
    throw: state => ({
      ...state,
      showAmendmentModal: false,
    }),
  },
  [DECLARATION_COPIED]: {
    next: state => ({
      ...state,
      showCopyModal: false,
    }),
    throw: state => ({
      ...state,
      showCopyModal: false,
    }),
  },
}

export default handleActions({
  ...commonReducerMap,
  ...headersReducerMap,
  ...rowsReducerMap,
  ...confirmationReducerMap,
}, initialState)
