import React from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import Icon from 'src/components/Icon'
import classNames from 'classnames'
import styles from './InfoAlert.scss'

function InfoAlert(props) {
  const { message, values, id } = props
  return (
    <div id={id} className={classNames(styles.infoAlert)}>
      <Icon name="info-modern" className="no-left-margin" lg />
      <div className={classNames(styles.infoAlertContent, 'h3')}>
        <FormattedMessage {...message} values={values} />
      </div>
    </div>
  )
}

export default injectIntl(InfoAlert)
