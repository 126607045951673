import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { injectIntl } from 'react-intl'
import { get } from 'lodash'

import { getSelectedAuthorization } from 'src/utils/auth'
import { getURLParam } from 'src/utils'

import IGRoot from './IGRoot'
import { INDIVIDUAL_GUARANTEE_CATEGORIES } from './constants'

const mapStateToProps = (state) => {
  const correlationId = getURLParam('correlationId')
  const grn = getURLParam('grn')
  const timestamp = getURLParam('timestamp')
  const payment = getURLParam('payment')
  const guaranteeCategory = getURLParam('guaranteeCategory')
  const checksum = getURLParam('checksum')

  const invalidParameters = !(grn && correlationId && timestamp && checksum) ||
    (guaranteeCategory && !INDIVIDUAL_GUARANTEE_CATEGORIES.includes(guaranteeCategory))

  return {
    locale: state.locale,
    selectedOrganizationId: get(getSelectedAuthorization(), 'id'),
    cmsMessages: get(state, 'content.cmsMessages', {}),
    userName: get(state, 'auth.name'),
    authorization: getSelectedAuthorization(),
    bootstrapConfig: state.config.bootstrapConfig,
    payment,
    grn,
    correlationId,
    timestamp,
    checksum,
    guaranteeCategory,
    invalidParameters,
  }
}

const mapActionCreators = {}

export default withRouter(
  connect(mapStateToProps, mapActionCreators)(injectIntl(IGRoot)))
