import { defineMessages } from 'react-intl'
import commonIntrastatMessages from '../messages'

const messages = defineMessages({
  nextStep: {
    id: 'intrastat.nextStep',
    description: 'Button for moving from one Intrastat step to next one',
    defaultMessage: 'Next',
  },
  previousStep: {
    id: 'intrastat.previousStep',
    description: 'Button for moving from one Intrastat step to previous one',
    defaultMessage: 'Previous',
  },
  exit: {
    id: 'intrastat.exit',
    description: 'Button for exiting from Intrastat form',
    defaultMessage: 'Exit',
  },
  heading: {
    id: 'intrastat.heading',
    description: 'Main heading for Intrastat app',
    defaultMessage: 'Intrastat declaration',
  },
  headingNew: {
    id: 'intrastat.heading.new',
    description: 'Main heading for Intrastat form when creating new declaration',
    defaultMessage: 'Create Intrastat declaration',
  },
  headingEdit: {
    id: 'intrastat.heading.edit',
    description: 'Main heading for Intrastat form when editing declaration',
    defaultMessage: 'Edit Intrastat declaration',
  },
  readOnlyHeading: {
    id: 'intrastat.readOnlyPanelHeading',
    description: 'Panel heading for read only declaration view',
    defaultMessage: 'Declaration {declarationId}',
  },
  status: {
    id: 'intrastat.status',
    description: 'Intrastat declaration status',
    defaultMessage: 'Status',
  },
  rows: {
    id: 'intrastat.rows',
    description: 'Link to form step 1',
    defaultMessage: 'Rows',
  },
  fetchingDeclaration: {
    id: 'intrastat.declaration.fetching',
    description: 'Information message for declaration loading status',
    defaultMessage: 'Fetching declaration...',
  },
  fetchingCustomer: {
    id: 'intrastat.customer.fetching',
    description: 'Information message for customer data loading status',
    defaultMessage: 'Fetching customer information...',
  },
  fetchingRows: {
    id: 'intrastat.rows.fetching',
    description: 'Information message for row loading status',
    defaultMessage: 'Fetching rows...',
  },
  headers: {
    id: 'intrastat.headers',
    description: 'Link to form step 2',
    defaultMessage: 'Headers',
  },
  confirmation: {
    id: 'form.confirmation',
    description: 'Link to confirmation page',
    defaultMessage: 'Summary and confirmation',
  },
  statusIncorrect: {
    id: 'intrastat.headers.declarationStatusIncorrect',
    description: 'Warning message for intrastat declaration status incorrect',
    defaultMessage: 'This declaration has status incorrect.',
  },
  importLabel: {
    id: 'intrastat.heading.import',
    description: 'Label for intrastat synopsis label import',
    defaultMessage: 'Import',
  },
  exportLabel: {
    id: 'intrastat.heading.export',
    description: 'Label for intrastat synopsis label export',
    defaultMessage: 'Export',
  },
  rowCountSummary: {
    id: 'intrastat.heading.rowCountSummary',
    description: 'Text presentation of row count summary, with variable {rowsCount}',
    defaultMessage: '{rowsCount, plural, =0 {No rows} one {One row} other {# rows}}',
  },
  rowErrorCountSummary: {
    id: 'intrastat.heading.rowErrorCountSummary',
    description: 'Text presentation of row error count summary, with variable {warningRowsCount}',
    defaultMessage: '{warningRowsCount, plural, =0 {} one {Errors on one row} other {Errors on # rows}}',
  },
  stateSentToCustoms: {
    id: 'intrastat.heading.sentToCustoms',
    description: 'Declaration has been sent to customs',
    defaultMessage: 'Sent to customs',
  },
  declarationId: {
    id: 'intrastat.confirmation.declarationId',
    description: 'Label for intrastat declaration id',
    defaultMessage: 'Statistical number',
  },
  totalNumberLines: {
    id: 'intrastat.confirmation.totalNumberLines',
    description: 'Label for total number of intrastat rows',
    defaultMessage: 'Rows',
  },
  totalNetMass: {
    id: 'intrastat.confirmation.totalNetMass',
    description: 'Label for total net mass of intrastat rows',
    defaultMessage: 'Net mass',
  },
  totalInvoicedAmount: {
    id: 'intrastat.confirmation.totalInvoicedAmount',
    description: 'Label for total invoiced amount of intrastat rows',
    defaultMessage: 'Invoiced amount',
  },
  dateCreated: {
    id: 'intrastat.confirmation.dateCreated',
    description: 'Label for intrastat declaration creation date',
    defaultMessage: 'Created',
  },
  stateDraft: {
    id: 'intrastat.heading.draft',
    description: 'Declaration is in state draft',
    defaultMessage: 'Draft',
  },
  stateInvalidated: {
    id: 'intrastat.heading.invalidated',
    description: 'Declaration is in state invalidated',
    defaultMessage: 'Invalidated',
  },
  errorFetchingDeclaration: {
    id: 'intrastat.headers.errorFetchingDeclaration',
    description: 'Message for error in fetching declaration',
    defaultMessage: 'Could not fetch declaration data',
  },
  errorFetchingDeclarationRows: {
    id: 'intrastat.headers.errorFetchingDeclarationRows',
    description: 'Message for error in fetching declaration rows',
    defaultMessage: 'Could not fetch declaration row data',
  },
  lastSave: {
    id: 'intrastat.form.lastSaveTime',
    description: 'Declaration last save time message, containing formatted time in value {time}',
    defaultMessage: 'Last saved {time}',
  },
  lastSaveLabel: {
    id: 'intrastat.form.lastSaveLabel',
    description: 'Declaration last save time label',
    defaultMessage: 'Last saved',
  },
  errorDeletingDeclaration: {
    id: 'intrastat.form.errorDeletingDeclaration',
    description: 'Common message for any error in deleting declaration',
    defaultMessage: 'Could not delete declaration.',
  },
  doneDeletingDeclaration: {
    id: 'intrastat.form.doneDeletingDeclaration',
    description: 'Success message shown after deleting declaration',
    defaultMessage: 'Declaration {declarationId} was successfully deleted.',
  },
  errorInvalidatingDeclaration: {
    id: 'intrastat.form.errorInvalidatingDeclaration',
    description: 'Common message for any error in invalidating declaration',
    defaultMessage: 'Could not invalidate declaration',
  },
  doneInvalidatingDeclaration: {
    id: 'intrastat.form.doneInvalidatingDeclaration',
    description: 'Success message shown after invalidating declaration',
    defaultMessage: 'Declaration {declarationId} was successfully invalidated',
  },
  preserveDraftOnExitModalMessage: {
    id: 'intrastat.form.preserveDraftOnExitModalMessage',
    description: 'Message shown in the preserve declaration on exit modal',
    defaultMessage: 'Do you want to preserve your declaration currently saved as a draft?',
  },
  preserveDraftOnExitModalCancel: {
    id: 'intrastat.form.preserveDraftOnExitModalCancel',
    description: 'Cancel button text in preserve on exit modal. If user clicks this, declaration is deleted',
    defaultMessage: 'No',
  },
  preserveDraftOnExitModalTitle: {
    id: 'intrastat.form.preserveDraftOnExitModalTitle',
    description: 'Title of the delete on exit modal',
    defaultMessage: 'Preserve draft?',
  },
  preserveDraftOnExitModalContinue: {
    id: 'intrastat.form.preserveDraftOnExitModalContinue',
    description: 'Continue button text in delete on exit modal',
    defaultMessage: 'Yes',
  },
  doneCreatingNewDeclarationVersion: {
    id: 'intrastat.declaration.doneCreatingNewDeclarationVersion',
    description: 'Notification message shown after creating new version of declaration',
    defaultMessage: 'New version {newDeclarationId} was successfully created from declaration {oldDeclarationId}',
  },
  errorCreatingNewDeclarationVersion: {
    id: 'intrastat.declaration.errorCreatingNewDeclarationVersion',
    description: 'Error notification message shown when creating new declaration version fails.',
    defaultMessage: 'Could not create new declaration version.',
  },
  doneCreatingNewDeclarationCopy: {
    id: 'intrastat.declaration.doneCreatingNewDeclarationCopy',
    description: 'Notification message shown after creating new copy of declaration',
    defaultMessage: 'New version {newDeclarationId} was successfully created from declaration {oldDeclarationId}',
  },
  errorCreatingNewDeclarationCopy: {
    id: 'intrastat.declaration.errorCreatingNewDeclarationCopy',
    description: 'Error notification message shown when creating new declaration copy fails.',
    defaultMessage: 'Could not create new declaration copy.',
  },
  previousDeclaration: {
    id: 'intrastat.declaration.previousDeclarationVersion',
    description: 'Label for link referencing to previous declaration version.',
    defaultMessage: 'Previous declaration version',
  },
  nextDeclaration: {
    id: 'intrastat.declaration.nextDeclarationVersion',
    description: 'Label for link referencing to next declaration version.',
    defaultMessage: 'Next declaration version',
  },
  replacedDeclarationVersion: {
    id: 'intrastat.declaration.replacedDeclarationVersion',
    description: 'Label for declaration replaced by other declaration.',
    defaultMessage: 'Replaced',
  },
  replacingDeclarationVersion: {
    id: 'intrastat.declaration.replacingDeclarationVersion',
    description: 'Label for declaration replacing other declaration.',
    defaultMessage: 'Amendment',
  },
  referencePeriodOption: {
    id: 'intrastat.headers.referencePeriod.option',
    description: 'Option text for Intrastat reference period',
    defaultMessage: '{label} (deadline: {deadline})',
  },
  printView: {
    id: 'intrastat.summary.printView',
    description: 'Print view button label in summary',
    defaultMessage: 'Print view',
  },
  print: {
    id: 'intrastat.print',
    description: 'Print button in declaration',
    defaultMessage: 'Print',
  },
  close: {
    id: 'intrastat.close',
    description: 'Close button in declaration',
    defaultMessage: 'Close',
  },
  nilDeclaration: {
    id: 'intrastat.nilDeclaration',
    description: 'Label for Intrastat nil declaration (no rows)',
    defaultMessage: 'No rows',
  },
  zeroRows: {
    id: 'intrastat.validation.noRows',
    description: 'Error message for no rows on non nil declaration',
    defaultMessage: 'Missing rows',
  },
  referencePeriodGroupTitle: {
    id: 'intrastat.headers.referencePeriodGroupTitle',
    description: 'Group title for reference period list',
    defaultMessage: 'Year {referencePeriodYear}',
  },
  declarationPages: {
    id: 'intrastat.navigation.declarationPages',
    description: 'Accessibility heading for the side navigation',
    defaultMessage: 'Declaration pages',
  },
  removeTdpNotificationMessage: {
    id: 'intrastat.removeTdpNotificationMessage',
    description: 'TODO',
    // eslint-disable-next-line max-len
    defaultMessage: 'You are about to continue filling in the declaration started by the agent {tdpName}. If you wish to submit the declaration using your current transaction role, you must first delete the information on the agent.',
  },
  removeTdpNotificationButton: {
    id: 'intrastat.removeTdpNotificationButton',
    description: 'TODO',
    defaultMessage: 'Delete the information on the agent',
  },
  removeTdpInfoMessage: {
    id: 'intrastat.removeTdpInfoMessage',
    description: 'TODO',
    // eslint-disable-next-line max-len
    defaultMessage: 'Note that a replacement version of the declaration has been created. The replacement version can only be viewed by the data provider.',
  },
  validationRemoveTdp: {
    id: 'intrastat.validation.removeTdp',
    description: 'TODO',
    // eslint-disable-next-line max-len
    defaultMessage: 'If you wish to submit the declaration using your current transaction role, you must first delete the information on the agent.',
  },
})

export default {
  ...commonIntrastatMessages,
  ...messages,
}

