import React from 'react'
import { get, isEmpty, isNil, isString, toNumber, toString } from 'lodash'
import { injectIntl } from 'react-intl'
import classNames from 'classnames'
import { connect } from 'react-redux'
import { Button } from 'react-bootstrap'

import styles from './Table.scss'
import Icon from '../Icon'
import InteractiveElement from '../InteractiveElement'
import AccessibleMessage from '../AccessibleMessage'
import messages from '../messages'

const ASCENDING = 'ascending'
const DESCENDING = 'descending'
const NONE = 'none'

const SORTING_TYPE_NUMBER = 'number'

function isCellValueValid(cellValue) {
  if (isNil(cellValue)) {
    return false
  }
  if (isString(cellValue) && cellValue.length === 0) {
    return false
  }

  return true
}

function defaultFormatter(value) {
  return isCellValueValid(value) ? value : <span>-</span>
}

export {
  ASCENDING,
  DESCENDING,
  SORTING_TYPE_NUMBER,
}

class Table extends React.Component {
  constructor(props) {
    super(props)
    let pageAmount
    const { pagination, contentRows } = props
    if (pagination) {
      pageAmount = Math.ceil(contentRows.length / pagination.amountOfResultsPerPage)
    }
    this.state = {
      sorting: {
        type: props.sortByDirection,
        field: props.sortByColumn,
      },
      activePageIndex: 0,
      pageAmount,
    }
  }

  isSortable() {
    const { contentRows, sortable } = this.props
    if (isEmpty(contentRows) || !sortable) {
      return false
    }
    return true
  }

  sort(type, field) {
    if (this.isSortable()) {
      const numberSort = get(this.props, ['sortingTypes', field]) === SORTING_TYPE_NUMBER

      const formatValue = (value) => {
        let returnValue = value
        if (value === undefined) {
          returnValue = numberSort ? 0 : ''
        } else if (numberSort) {
          returnValue = toNumber(value)
        } else {
          returnValue = toString(returnValue)
        }
        return returnValue
      }

      if (type === ASCENDING) {
        return (a, b) => {
          let returnVal = 0
          const aValue = formatValue(a[field])
          const bValue = formatValue(b[field])
          if (numberSort) {
            returnVal = aValue - bValue
          } else {
            returnVal = aValue.localeCompare(bValue)
          }
          return returnVal
        }
      } else if (type === DESCENDING) {
        return (a, b) => {
          let returnVal = 0
          const aValue = formatValue(a[field])
          const bValue = formatValue(b[field])
          if (numberSort) {
            returnVal = bValue - aValue
          } else {
            returnVal = bValue.localeCompare(aValue)
          }
          return returnVal
        }
      }
    }

    return a => a
  }

  selectSorting(field) {
    const sortingType = { type: '', field }
    const selectedType = this.state.sorting.type
    if (field !== this.state.sorting.field) {
      sortingType.type = ASCENDING
    } else if (selectedType === ASCENDING) {
      sortingType.type = DESCENDING
    } else {
      sortingType.type = ASCENDING
    }
    this.setState({ sorting: sortingType })
  }

  isSortedField(property) {
    if (this.isSortable() && this.state.sorting.field === property) {
      return true
    }
    return false
  }

  isAscending() {
    return this.state.sorting.type === ASCENDING
  }

  formatValue(row, property, index) {
    let formattedValue = row[property]
    const formatter = get(this.props.formatters, property)
    if (formatter) {
      formattedValue = formatter(row, property, index)
    } else if (this.props.defaultFormatter) {
      formattedValue = this.props.defaultFormatter(row, property)
    }
    return defaultFormatter(formattedValue)
  }

  renderHeading(property, item) {
    const { ascendingSortMessage, descendingSortMessage } = this.props
    const { value, hide, isSortableHeading } = item

    if (hide === true) {
      return (
        <span className={styles.hidden}>
          {value}
        </span>
      )
    } else if (value && value.length) {
      const content = [
        <span key={0} className={classNames(this.isSortedField(property) ? styles.selected : '', styles.heading)}>
          {value}
        </span>,
      ]
      if (this.isSortedField(property)) {
        content.push(<Icon key={1} name={this.isAscending() ? 'arrow-up' : 'arrow-down'} />)
        content.push(
          <AccessibleMessage
            key={2}
            message={this.isAscending() ? ascendingSortMessage : descendingSortMessage}
            hideMessage
          />
        )
      }

      return this.isSortable() && isSortableHeading !== false ? (
        <button type="button" className={'btn-default'} onClick={() => this.selectSorting(property)}>
          {content}
        </button>
      ) :
        content
    }

    return null
  }

  renderRowCells(properties, row, rowIndex) {
    const { useRowHeadings, onRowClick } = this.props

    return properties.map((prop, cellIndex) => {
      const firstCellIsTh = useRowHeadings === true && cellIndex === 0
      const Tag = firstCellIsTh === true ? 'th' : 'td'
      const content = (
        <Tag key={cellIndex} scope={firstCellIsTh === true ? 'row' : undefined}>
          {onRowClick && cellIndex === 0 ?
            <button type="button" className={'btn-default'}>
              {this.formatValue(row, prop, rowIndex)}
            </button>
            :
            this.formatValue(row, prop, rowIndex)
          }
        </Tag>
      )

      return content
    })
  }

  nextPage = () => {
    const { activePageIndex, pageAmount } = this.state
    if (pageAmount !== activePageIndex + 1) {
      this.setState({ activePageIndex: activePageIndex + 1 })
    }
  }

  previousPage = () => {
    const { activePageIndex } = this.state
    if (activePageIndex !== 0) {
      this.setState({ activePageIndex: activePageIndex - 1 })
    }
  }

  getShownRows = (rows) => {
    const { pagination } = this.props
    if (pagination) {
      const { activePageIndex } = this.state
      const { amountOfResultsPerPage } = pagination

      if (activePageIndex === 0) {
        return rows.slice(0, amountOfResultsPerPage)
      } else {
        const firstIndex = activePageIndex * amountOfResultsPerPage
        return rows.slice(firstIndex, firstIndex + amountOfResultsPerPage)
      }
    }
    return rows
  }

  render() {
    const {
      headings,
      contentRows,
      properties,
      onRowClick,
      className,
      noRowsText = '',
      dontBoldFirstCell,
      caption,
      intl,
      onRowClickDisabled = false,
      pagination,
    } = this.props

    const { activePageIndex, sorting, pageAmount } = this.state
    const { type, field } = sorting

    let onClickHandler
    if (onRowClick && !onRowClickDisabled) {
      onClickHandler = true
    } else {
      onClickHandler = false
    }

    const shownRows = this.getShownRows(contentRows)

    return (
      <>
        <div tabIndex={0} role="region" aria-label={intl.formatMessage(messages.table)} className={styles.scrollWrapper}>
          <table className={classNames(styles.table, className, (!this.isSortable() && styles.notSortable) || '')}>
            {caption && <caption className={'sr-only'}>{caption}</caption>}
            {headings && !isEmpty(headings) &&
              <thead>
                <tr>
                  {headings.map((item, index) => (
                    <InteractiveElement
                      type="th"
                      key={index}
                      excludeTabIndex
                      excludeRole
                      scope="col"
                      aria-sort={(this.isSortable() &&
                        (this.isSortedField(properties[index]) ? type : NONE)) || undefined}
                      usePointerCursor={item.isSortableHeading}
                    >
                      {this.renderHeading(properties[index], item)}
                    </InteractiveElement>
                  ))
                  }
                </tr>
              </thead>
            }
            <tbody>
              {(shownRows && !isEmpty(shownRows) &&
                [...shownRows].sort(this.sort(type, field)).map((row, index) => (
                  <InteractiveElement
                    type="tr"
                    key={index}
                    onClick={onClickHandler ? () => onRowClick({ row, index }) : undefined}
                    className={classNames(!dontBoldFirstCell && styles.boldFirstCell, onRowClick && styles.hover)}
                    excludeTabIndex
                    excludeRole
                  >
                    {this.renderRowCells(properties, row, index)}
                  </InteractiveElement>
                )))
                ||
                <tr className={styles.noRows}>
                  <td colSpan={headings.length}>
                    {noRowsText}
                  </td>
                </tr>
              }
            </tbody>
          </table>

        </div>
        {pagination && contentRows.length > pagination.amountOfResultsPerPage &&
          <div className={styles.pagination}>
            <Button onClick={this.previousPage} className={styles.pageLeft}>
              <span className="sr-only">{intl.formatMessage(messages.previousPage)}</span>
              <Icon name="chevron-tight-left" md />
            </Button>
            <Button onClick={this.nextPage} className={styles.pageRight}>
              <span className="sr-only">{intl.formatMessage(messages.nextPage)}</span>
              <Icon name="chevron-tight-right" md />
            </Button>
            <div className={styles.paginationText}>
              <strong className={styles.pageNumber}>{activePageIndex + 1} / {pageAmount}</strong> ({pagination.paginationRowText?.replace('{amountOfGoodsItems}', contentRows.length)})
            </div>
          </div>
        }
      </>
    )
  }
}

const mapStateToProps = (state) => {
  const cmsMessages = get(state, 'content.cmsMessages', {})
  const ascendingText = cmsMessages['/tableAscendingSortMessage']
  const descendingText = cmsMessages['/tableDescendingSortMessage']
  return {
    ascendingSortMessage: ascendingText,
    descendingSortMessage: descendingText,
  }
}

export default connect(mapStateToProps)(injectIntl(Table))
