import React from 'react'
import PanelNavigation from 'src/components/PanelNavigation'
import ReactMarkdown from 'react-markdown'
import {
  Grid,
  Col,
  Row,
} from 'react-bootstrap'
import Icon from 'src/components/Icon'
import { getURLParam } from 'src/utils'

import {
  getMessage,
} from '../../utils'
import PaymentRoot from '../../components/PaymentRoot'
import styles from './StopPage.scss'
import { INDIVIDUAL_GUARANTEE_CATEGORY_TRANSIT } from '../../constants'

function IconRenderer({ src, alt: type }) {
  if (type === 'ICON') {
    const iconName = src
    return <Icon name={iconName} md />
  }
  return null
}

export default function StopPage(props) {
  const {
    cmsMessages,
  } = props
  const heading = getMessage('stopPagePanelHeading', cmsMessages)
  let contentInfo = getMessage('stopPageContentInfo', cmsMessages)
  const guaranteeCategory = getURLParam('guaranteeCategory')

  if (guaranteeCategory && guaranteeCategory === INDIVIDUAL_GUARANTEE_CATEGORY_TRANSIT) {
    contentInfo = getMessage('stopPageContentInfoTransit', cmsMessages)
  }
  const contentBody = [contentInfo, getMessage('stopPageContentNextSteps', cmsMessages)].join('\n')

  return (
    <PaymentRoot cmsMessages={cmsMessages}>
      <PanelNavigation
        title={heading}
        active
      >
        <Grid className={styles.grid}>
          <Row>
            <Col>
              <ReactMarkdown
                className={styles.markdown}
                source={contentBody}
                renderers={{
                  Image: IconRenderer,
                }}
              />
            </Col>
          </Row>
        </Grid>
      </PanelNavigation>
    </PaymentRoot>
  )
}
