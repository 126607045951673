import React from 'react'

import styles from './HeaderMenu.scss'

export default function HeaderMenu(props) {
  return (
    <ol className={styles.orderedList}>
      {props.items.map((item, i) => (
        <li
          key={i}
          className={item.active ? styles.rowContainerActive : styles.rowContainer}
          // eslint-disable-next-line jsx-a11y/aria-props
          aria-current={item.active ? 'step' : undefined}
        >
          <span className={'sr-only'}>{i + 1} {item.text}</span>
          <div className={styles.menuRow} aria-hidden>
            <div className={styles.rowMarker}>
              {i + 1}
            </div>
            <div className={styles.menuRowText}>
              {item.text}
            </div>
          </div>
          {(i < (props.items.length - 1)) &&
            <div className={styles.divider}>&nbsp;</div>
          }
        </li>
      ))}
    </ol>
  )
}
