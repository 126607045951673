import React from 'react'
import InputArea from 'src/components/form/InputArea'
import { getValidationClassName } from 'src/utils/validation'

// eslint-disable-next-line no-unused-vars
export default function RowFormParseOnBlurInput({ helpText: fieldHelp, onBlurParser, ...fieldProps }) {
  return (
    <div className={getValidationClassName(fieldProps)}>
      <InputArea
        {...fieldProps}
        onBlur={(event) => {
          event.preventDefault()
          fieldProps.input.onBlur(onBlurParser(event.target.value))
          return false
        }}
      />
    </div>
  )
}
