export const TABLE_HEADER_HEIGHT = 30
export const TABLE_ROW_HEIGHT = 40
export const TABLE_MIN_HEIGHT = Math.ceil(TABLE_HEADER_HEIGHT + (TABLE_ROW_HEIGHT * 8.5))
export const DEFAULT_SORT_BY = 'dateOfModify'
export const DECLARATION_STATUS_DRAFT = 'DRAFT'
export const DECLARATION_STATUS_READY = 'READY'
export const DECLARATION_STATUS_ACCEPTED = 'ACCEPTED'
export const DECLARATION_STATUS_INVALIDATED = 'DEFACED'
export const INITIAL_FETCH_ITEMS_COUNT = 100

export const MORE_DECLARATIONS_RECEIVED = '@@nakki/intrastat/LIST_MORE_DECLARATIONS_RECEIVED'
export const STATUS_FILTER_CHANGE = '@@nakki/intrastat/LIST_STATUS_FILTER_CHANGE'
export const INITIAL_DECLARATIONS_RECEIVED = '@@nakki/intrastat/LIST_INITIAL_DECLARATIONS_RECEIVED'
export const DESTROY_DECLARATIONS_LIST_CACHE = '@@nakki/intrastat/DESTROY_DECLARATIONS_LIST_CACHE'
export const ACTIVATE_DECLARATION_IN_LIST = '@@nakki/intrastat/ACTIVATE_DECLARATION_IN_LIST'
