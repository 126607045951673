import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { FormattedMessage, injectIntl } from 'react-intl'
import { has, find, get } from 'lodash'
import { wrapPreventDefault } from 'src/utils'
import InteractiveElement from 'src/components/InteractiveElement'
import DraftsTab from './DraftsTab'
import PermitApplicationsTab from './PermitApplicationsTab'
import PermitsTab from './PermitsTab'
import { storeListViewState } from '../actions'
import messages from '../messages'
import commonMessages from '../../permit/messages'
import { isDateAfterToday, userHasAdminOrHandlerAuthorizations } from '../utils'
import {
  PERMIT_TAB_DRAFTS,
  PERMIT_TAB_APPLICATIONS,
  PERMIT_TAB_REPLIES,
  PERMIT_TAB_PERMITS,
  PERMIT_APPLICATION_STATUS_DRAFT,
  PERMIT_APPLICATION_STATUS_REGISTERED,
  PERMIT_APPLICATION_STATUS_VERIFICATION,
  PERMIT_APPLICATION_STATUS_ACCEPTED,
  PERMIT_APPLICATION_STATUS_NOT_ACCEPTED,
  PERMIT_APPLICATION_STATUS_FAVOURABLE,
  PERMIT_APPLICATION_STATUS_NON_FAVOURABLE,
  PERMIT_APPLICATION_STATUS_WITHDRAWN,
} from '../constants'
import {
  PERMIT_STATUS_DRAFT,
  PERMIT_STATUS_APPROVED,
  PERMIT_STATUS_ACTIVE,
  PERMIT_STATUS_SUSPENDED,
  PERMIT_STATUS_ENDED,
  PERMIT_STATUS_ANNULLED,
  PERMIT_STATUS_REVOKED,
} from '../../constants'
import { getApplicant } from '../../permit/permitHelper'
import RepliesTab from './RepliesTab'
import api from '../../api'
import InlineNotification from '../../permit/components/InlineNotification'


function userIsAuthorizedToViewDraftsAndApplications(isPerson) {
  return userHasAdminOrHandlerAuthorizations() || isPerson
}

function getInitiallyOpenTab(isPerson) {
  if (userIsAuthorizedToViewDraftsAndApplications(isPerson)) {
    return PERMIT_TAB_DRAFTS
  }

  return PERMIT_TAB_PERMITS
}


class PermitsList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      activeDecisionProposalExists: false,
    }

    this.storeStateInRedux = this.storeStateInRedux.bind(this)
    this.selectTab = this.selectTab.bind(this)
    this.formatRowData = this.formatRowData.bind(this)

    if (!props.permitsListInRedux.selectedTab) {
      this.props.storeListViewState({
        ...this.props.permitsListInRedux,
        selectedTab: getInitiallyOpenTab(props.isPerson),
      })
    }
  }

  componentDidMount() {
    this.fetchDecisionProposalsAndRejoinders()
  }

  fetchDecisionProposalsAndRejoinders() {
    const decisionProposalsAndRejoindersPromise = api.fetchRepliesAndProposals(this.props.applicant?.authorizationOrDecisionHolderIdentification).then((result) => {
      const activeDecisionProposalExists = result
        .some(decision => decision.endDate && isDateAfterToday(new Date(decision.endDate)))
      this.setState({ activeDecisionProposalExists })
      return result
    })
    this.setState({ decisionProposalsAndRejoindersPromise })
  }

  selectTab(tab) {
    this.props.storeListViewState({
      ...this.props.permitsListInRedux,
      selectedTab: tab,
    })
  }

  storeStateInRedux(state) {
    this.props.storeListViewState({
      selectedTab: this.props.selectedTab,
      showOnlyActivePermits: this.props.showOnlyActivePermits,
      ...state,
    })
  }

  getPermitTypeName(decisionTypeCode) {
    const { decisionTypes, locale } = this.props
    const decisionType = decisionTypes.find(type => decisionTypeCode === type.code)

    return get(decisionType, `name[${locale}]`)
  }

  // Format row data for sorting etc.
  formatRowData(row, result) {
    const { permitTypes, locale, companies, intl: { formatMessage } } = this.props

    // Name of application
    let applicationTypeCode = row.applicationTypeCode || row.authorisationTypeCode
    if (row.applicationDecisionReference) {
      applicationTypeCode = row.applicationDecisionReference.attributes.authorizationTypeCode
    } else if (row.decisionReference) {
      applicationTypeCode = row.decisionReference.attributes.authorizationTypeCode
    }
    const permitType = find(permitTypes, { code: applicationTypeCode })
    const permitTypeName = has(permitType, `name.${locale}`) ? permitType.name[locale] : '-'
    const name = `${permitTypeName} (${applicationTypeCode})`

    // Company name
    const company = find(companies, { eori: row.authorizationOrDecisionHolderIdentification })
    const companyName = company ? company.name : '-'

    const isProposal = row.decisionType?.includes('PROPOSAL')
    // Status name
    let statusName = this.formatStatus(row.status || row.state)
    if (isProposal && result) {
      const rejoinder = result.find(proposal => proposal.decisionReference?.attributes.referenceNumber === proposal.referenceNumber)
      statusName = this.formatDecisionProposalStatus(row.state || row.state, rejoinder)
    } else if (row.applicationTypeCode === 'PAA') {
      statusName = this.formatRejoinderStatus(row.status || row.state)
    }

    // Application type
    let type
    const permitDecisionName = row.decisionType && this.getPermitTypeName(row.decisionType)

    if(permitDecisionName) {
      type = permitDecisionName
    } else if (row.applicationTypeCode) {
      type = formatMessage(messages.labelPermitTypeApplication) // Hakemus
      if (row.applicationTypeCode === 'KUM') {
        type = formatMessage(messages.labelPermitTypeRevocation) // Kumoaminen
      } else if (row.applicationTypeCode === 'PAA') {
        type = formatMessage(messages.labelPermitTypeRejoinder) // Vastine
      }
    } else if (row.authorisationTypeCode) {
      type = formatMessage(messages.labelPermitTypeAuthorisation) // Lupa/Päätös
      if (row.decisionType === 'REVOKE_DECISION') {
        type = formatMessage(messages.labelPermitTypeRevocation) // Kumoaminen
      } else if (isProposal) {
        type = formatMessage(messages.labelPermitTypeDecisionProposal) // Päätösesitys
      }
    }

    // Add "registeredDate" for rejoinder drafts to display it on the column
    let registeredDate
    if (row.state === 'DRAFT' && row.applicationTypeCode === 'PAA') {
      registeredDate = row.updateDate
    }

    return {
      registeredDate,
      ...row,
      name,
      statusName,
      companyName,
      type,
    }
  }

  formatStatus(status) {
    const { intl: { formatMessage } } = this.props
    switch (status) {
    case PERMIT_APPLICATION_STATUS_DRAFT:
      return formatMessage(messages.labelApplicationStatusDraft)
    case PERMIT_APPLICATION_STATUS_REGISTERED:
      return formatMessage(messages.labelApplicationStatusRegistered)
    case PERMIT_APPLICATION_STATUS_VERIFICATION:
      return formatMessage(messages.labelApplicationStatusVerification)
    case PERMIT_APPLICATION_STATUS_ACCEPTED:
      return formatMessage(messages.labelApplicationStatusAccepted)
    case PERMIT_APPLICATION_STATUS_NOT_ACCEPTED:
      return formatMessage(messages.labelApplicationStatusNotAccepted)
    case PERMIT_APPLICATION_STATUS_FAVOURABLE:
      return formatMessage(messages.labelApplicationStatusFavourable)
    case PERMIT_APPLICATION_STATUS_NON_FAVOURABLE:
      return formatMessage(messages.labelApplicationStatusNonFavourable)
    case PERMIT_APPLICATION_STATUS_WITHDRAWN:
      return formatMessage(messages.labelApplicationStatusWithdrawn)
    case PERMIT_STATUS_DRAFT:
      return formatMessage(messages.labelPermitStatusDraft)
    case PERMIT_STATUS_APPROVED:
      return formatMessage(messages.labelPermitStatusApproved)
    case PERMIT_STATUS_ACTIVE:
      return formatMessage(messages.labelPermitStatusActive)
    case PERMIT_STATUS_SUSPENDED:
      return formatMessage(messages.labelPermitStatusSuspended)
    case PERMIT_STATUS_ENDED:
      return formatMessage(messages.labelPermitStatusEnded)
    case PERMIT_STATUS_ANNULLED:
      return formatMessage(messages.labelPermitStatusAnnulled)
    case PERMIT_STATUS_REVOKED:
      return formatMessage(messages.labelPermitStatusRevoked)
    default:
      return status
    }
  }

  formatDecisionProposalStatus(status, rejoinder) {
    const { intl: { formatMessage } } = this.props
    switch (status) {
    case PERMIT_STATUS_ACTIVE:
      if (rejoinder?.state === PERMIT_APPLICATION_STATUS_FAVOURABLE || rejoinder?.state === PERMIT_APPLICATION_STATUS_NON_FAVOURABLE) {
        return formatMessage(messages.labelDecisionProposalStatusProcessed) // käsitelty
      }
      return formatMessage(messages.labelDecisionProposalStatusDeadlineFuture) // määräaikaa jäljellä
    case PERMIT_STATUS_ENDED:
      return formatMessage(messages.labelDecisionProposalStatusDeadlinePast) // määräaika päättynyt
    default:
      return this.formatStatus(status)
    }
  }

  formatRejoinderStatus(status) {
    const { intl: { formatMessage } } = this.props
    switch (status) {
    case PERMIT_APPLICATION_STATUS_FAVOURABLE:
    case PERMIT_APPLICATION_STATUS_NON_FAVOURABLE:
      return formatMessage(messages.labelRejoinderStatusProcessed)
    default:
      return this.formatStatus(status)
    }
  }

  render() {
    const {
      activeDecisionProposalExists,
      decisionProposalsAndRejoindersPromise,
    } = this.state

    const {
      selectedTab,
      locale,
      intl: { formatMessage },
      isPerson,
      showProposalsAndRejoinders,
    } = this.props

    return (
      <div>
        {activeDecisionProposalExists && showProposalsAndRejoinders &&
          <InlineNotification
            message={formatMessage(messages.decisionProposalExists)}
            buttonText={formatMessage(messages.openDecisionProposals)}
            onClick={wrapPreventDefault(() => {
              this.selectTab(PERMIT_TAB_REPLIES)
              setTimeout(() => document.getElementById('permit-tab-reply-tab').focus(), 500)
            })}
            ariaLabel={formatMessage(commonMessages.attention)}
            info
          />
        }
        <ul className="nav nav-tabs" role="tablist">
          {userIsAuthorizedToViewDraftsAndApplications(isPerson) &&
            [
              <li
                key="0"
                role="presentation"
                className={selectedTab === PERMIT_TAB_DRAFTS ? 'active' : null}
              >
                <InteractiveElement
                  id="permit-tab-drafts-tab"
                  role="tab"
                  aria-controls="permit-tab-drafts-tabpanel"
                  type="a"
                  onClick={wrapPreventDefault(() => this.selectTab(PERMIT_TAB_DRAFTS))}
                  id-qa-test="label-permit-tab-drafts"
                  aria-selected={selectedTab === PERMIT_TAB_DRAFTS}
                >
                  <FormattedMessage id="permit-tab-drafts-label" {...messages.tabLabelDraftPermits} />
                </InteractiveElement>
              </li>,
              <li
                key="1"
                role="presentation"
                className={selectedTab === PERMIT_TAB_APPLICATIONS ? 'active' : null}
              >
                <InteractiveElement
                  id="permit-tab-applications-tab"
                  role="tab"
                  aria-controls="permit-tab-applications-tabpanel"
                  type="a"
                  onClick={wrapPreventDefault(() => this.selectTab(PERMIT_TAB_APPLICATIONS))}
                  id-qa-test="label-permit-tab-applications"
                  aria-selected={selectedTab === PERMIT_TAB_APPLICATIONS}
                >
                  <FormattedMessage id="permit-tab-applications-label" {...messages.tabLabelProcessingPermits} />
                </InteractiveElement>
              </li>,
            ]
          }
          {showProposalsAndRejoinders &&
            <li
              role="presentation"
              className={selectedTab === PERMIT_TAB_REPLIES ? 'active' : null}
            >
              <InteractiveElement
                id="permit-tab-reply-tab"
                role="tab"
                aria-controls="permit-tab-reply-tabpanel"
                type="a"
                onClick={wrapPreventDefault(() => this.selectTab(PERMIT_TAB_REPLIES))}
                id-qa-test="label-permit-tab-reply"
                aria-selected={selectedTab === PERMIT_TAB_REPLIES}
              >
                <FormattedMessage id="permit-tab-reply-label" {...messages.tabLabelReplyPermits} />
              </InteractiveElement>
            </li>
          }
          <li
            role="presentation"
            className={selectedTab === PERMIT_TAB_PERMITS ? 'active' : null}
          >
            <InteractiveElement
              id="permit-tab-permits-tab"
              role="tab"
              aria-controls="permit-tab-permits-tabpanel"
              type="a"
              onClick={wrapPreventDefault(() => this.selectTab(PERMIT_TAB_PERMITS))}
              id-qa-test="label-permit-tab-permits"
              aria-selected={selectedTab === PERMIT_TAB_PERMITS}
            >
              <FormattedMessage id="permit-tab-permits-label" {...messages.tabLabelReadyPermits} />
            </InteractiveElement>
          </li>
        </ul>
        {selectedTab === PERMIT_TAB_DRAFTS &&
          <DraftsTab
            storeStateInRedux={this.storeStateInRedux}
            selectedAuthorizationOrDecisionHolderIdentification={this.props.applicant.authorizationOrDecisionHolderIdentification}
            formatRowData={this.formatRowData}
            locale={locale}
          />
        }
        {selectedTab === PERMIT_TAB_APPLICATIONS &&
          <PermitApplicationsTab
            storeStateInRedux={this.storeStateInRedux}
            selectedAuthorizationOrDecisionHolderIdentification={this.props.applicant.authorizationOrDecisionHolderIdentification}
            formatRowData={this.formatRowData}
            locale={locale}
          />
        }
        {selectedTab === PERMIT_TAB_REPLIES && showProposalsAndRejoinders &&
          <RepliesTab
            storeStateInRedux={this.storeStateInRedux}
            selectedOrganizationEori={this.props.applicant.authorizationOrDecisionHolderIdentification}
            formatRowData={this.formatRowData}
            locale={locale}
            decisionProposalsAndRejoindersPromise={decisionProposalsAndRejoindersPromise}
          />
        }
        {selectedTab === PERMIT_TAB_PERMITS &&
          <PermitsTab
            storeStateInRedux={this.storeStateInRedux}
            selectedAuthorizationOrDecisionHolderIdentification={this.props.applicant.authorizationOrDecisionHolderIdentification}
            formatRowData={this.formatRowData}
            locale={locale}
          />
        }
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const {
    selectedTab,
    showOnlyActivePermits,
  } = state.permits.list
  const applicant = getApplicant()

  return {
    permitsListInRedux: state.permits.list,
    companies: state.auth.authorizations,
    applicant,
    locale: state.locale,
    selectedTab,
    showOnlyActivePermits,
    showProposalsAndRejoinders: get(state, 'config.features.SHOW_PROPOSALS_AND_REJOINDERS', false),
  }
}

const mapActionCreators = {
  storeListViewState,
}

export default withRouter(
  connect(mapStateToProps, mapActionCreators)(
    injectIntl(PermitsList)
  )
)
