import { connect } from 'react-redux'
import { get } from 'lodash'
import { collectCmsMessages } from 'src/utils'
import Feedback from './components/Feedback'

const mapStateToProps = (state) => {
  const cmsMessages = get(state, 'content.cmsMessages', {})
  const content = collectCmsMessages('/feedback', cmsMessages)

  return {
    content,
  }
}

export default connect(mapStateToProps)(Feedback)

