import React from 'react'
import { FormattedMessage } from 'react-intl'

/**
 * Render single notification
 * @module components/Notification
 * @params {Object} props
 */
export default function Notification({ remove, category, message, wrapInContainer = true }) {
  const getAlertClass = () => {
    switch (category) {
    case 'error':
      return 'danger'
    default:
      return category
    }
  }
  const messageOutput = typeof message === 'string' ? message : <FormattedMessage {...message} />
  return (
    <div className={`alert alert-${getAlertClass()}`}>
      <div className={wrapInContainer ? 'container' : ''}>
        <button
          onClick={() => remove()}
          type="button"
          className="close"
          data-dismiss="alert"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
        {messageOutput}
      </div>
    </div>
  )
}
