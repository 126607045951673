import { get, omit } from 'lodash'
import TreeModel from 'tree-model'
import {
  SELECT_DECLARATION_ROW,
  TOGGLE_NEW_ROW_EDITING,
  DECLARATION_ROW_CREATED,
  DECLARATION_ROWS_SAVED,
  DECLARATION_ROWS_CHUNK_SAVED,
  DECLARATION_ROW_UPDATED,
  DECLARATION_ROW_DELETED,
  DECLARATION_ROWS_FETCHED,
  CN8_TREE_REQUEST,
  CN8_TREE_CHUNK,
  CN8_TREE_CHUNKS_FETCHED,
  CN8_TREE_BRANCH_REQUEST,
  CN8_FETCHED,
  CN8_TEXT_SEARCH_REQUEST,
  CN8_TEXT_SEARCH_RESULT,
  PRESERVE_ROW_FIELD_VALUE,
  CN8_CLEAR_SEARCH_RESULTS,
  SHOW_ONLY_INVALID_ROWS,
} from '../../constants'

function formatCN8TreeNode(cn8) {
  const newCN8 = omit(cn8, ['ancestors', 'siblings'])

  if (!newCN8.children) {
    return newCN8
  }

  const children = newCN8.children
    .map((child) => {
      if (typeof child !== 'object') {
        return false
      }

      return formatCN8TreeNode(child)
    })
    .filter(Boolean)

  if (children.length === 0) {
    return omit(newCN8, 'children')
  }

  return Object.assign(cn8, { children })
}

function addOrReplaceChildNode(targetNode, childNode) {
  // TODO: Why are we trying to add children to parents they don't belong to?
  if (childNode.model.parent !== targetNode.model.code) {
    return
  }

  const existingChild = targetNode.first(node => node.model.code === childNode.model.code)
  if (!existingChild) {
    targetNode.addChild(childNode)
    return
  }

  const index = existingChild.getIndex()
  existingChild.drop()
  targetNode.addChildAtIndex(childNode, index)
}

export default {
  [SELECT_DECLARATION_ROW]: (state, { payload }) => {
    if (state.newRow && payload != null) {
      return state
    }
    if (!payload) {
      return {
        ...state,
        selectedRow: null,
      }
    }
    return {
      ...state,
      selectedRow: payload,
      currentRow: payload,
      newRow: false,
    }
  },
  [DECLARATION_ROWS_CHUNK_SAVED]: (state, { meta }) => ({
    ...state,
    rowSaveProgress: { current: meta.chunk, total: meta.totalChunks },
    lastSave: Date.now(),
  }),
  [DECLARATION_ROWS_SAVED]: {
    next: (state, { payload }) => ({
      ...state,
      rowSaveProgress: undefined,
      rowSaveResult: payload,
      lastSave: Date.now(),
    }),
    throw: (state, action, meta) => ({
      ...state,
      rowSaveProgress: undefined,
      rowSaveResult: meta,
      lastSave: Date.now(),
    }),
  },
  [TOGGLE_NEW_ROW_EDITING]: (state, { payload }) => {
    if (payload === true) {
      return {
        ...state,
        newRow: true,
        selectedRow: null,
      }
    }
    return {
      ...state,
      newRow: payload === false ? payload : !state.newRow,
    }
  },
  [DECLARATION_ROW_UPDATED]: state => ({
    ...state,
    lastSave: Date.now(),
  }),
  [DECLARATION_ROW_CREATED]: (state, { payload }) => ({
    ...state,
    currentRow: payload.id,
    lastSave: Date.now(),
  }),
  [DECLARATION_ROW_DELETED]: {
    next: (state, { payload }) => {
      if (payload.id !== state.selectedRow) {
        return state
      }
      return {
        ...state,
        selectedRow: null,
        currentRow: null,
        errorDeletingRow: false,
        lastSave: Date.now(),
      }
    },
    throw: (state, action) => ({
      ...state,
      errorDeletingRow: get(action, 'meta.message') || true,
    }),
  },
  [DECLARATION_ROWS_FETCHED]: {
    next: state => ({
      ...state,
      errorFetchingDeclarationRows: false,
    }),
    throw: (state, action) => ({
      ...state,
      errorFetchingDeclarationRows: action.payload || true,
    }),
  },
  [PRESERVE_ROW_FIELD_VALUE]: (state, { payload }) => ({
    ...state,
    preservedRowFieldValues: {
      ...state.preservedRowFieldValues,
      [payload.fieldName]: payload.value,
    },
  }),
  [SHOW_ONLY_INVALID_ROWS]: (state, { payload }) => ({
    ...state,
    showOnlyInvalidRows: payload,
  }),
  [CN8_TREE_REQUEST]: state => ({
    ...state,
    fetchingCN8Tree: true,
  }),
  [CN8_FETCHED]: {
    next: (state, { payload, meta }) => {
      const referencePeriodYear = meta.referencePeriod.substr(0, 4)
      const newState = {
        ...state,
        cachedCN8Codes: {
          ...state.cachedCN8Codes,
          [referencePeriodYear]: {
            ...state.cachedCN8Codes[referencePeriodYear],
          },
          cn8Locale: meta.cn8Locale,
        },
      }
      payload.forEach((data) => {
        newState.cachedCN8Codes[referencePeriodYear][data.cn8Code] = data
      })
      return newState
    },
    throw: state => ({
      ...state,
    }),
  },
  [CN8_TEXT_SEARCH_REQUEST]: state => ({
    ...state,
    searchingCN8Text: true,
  }),
  [CN8_TEXT_SEARCH_RESULT]: {
    next: (state, { payload, meta }) => ({
      ...state,
      searchingCN8Text: false,
      cn8SearchResults: {
        resultItems: payload,
        referencePeriod: meta.referencePeriod,
        searchText: meta.text,
      },
    }),
    throw: state => ({
      ...state,
      searchingCN8Text: false,
    }),
  },
  [CN8_CLEAR_SEARCH_RESULTS]: state => ({
    ...state,
    cn8SearchResults: null,
  }),
  [CN8_TREE_BRANCH_REQUEST]: (state, { payload }) => {
    const newState = {
      ...state,
    }
    const existingRoot = get(newState, ['cn8Trees', payload.referencePeriod.substr(0, 4), 'root'])
    let targetNode
    existingRoot.walk((node) => {
      // eslint-disable-next-line no-param-reassign
      node.model.active = false
      if (node.model.code === payload.code) {
        targetNode = node
      }
    })
    if (targetNode) {
      targetNode.model.active = true
      targetNode.model.loading = true
      targetNode.model.loadError = false
    }
    return newState
  },
  [CN8_TREE_CHUNK]: {
    next: (state, { payload, meta: { referencePeriod, cn8Locale } }) => {
      const newState = {
        ...state,
      }

      const referencePeriodYear = referencePeriod.substr(0, 4)
      const existingRoot = get(newState, ['cn8Trees', referencePeriodYear, 'root'])
      const existingTree = get(newState, ['cn8Trees', referencePeriodYear, 'tree'])


      // The state does not yet contain a cn8 tree model for the given reference period year,
      // so initialize one from this payload. This assumes that the first request sent is for the root node.
      if (!existingRoot) {
        const tree = new TreeModel()
        const root = tree.parse(formatCN8TreeNode(payload))
        return {
          ...newState,
          cn8Trees: {
            ...newState.cn8Trees,
            [referencePeriodYear]: {
              tree,
              root,
            },
            cn8Locale,
          },
        }
      }

      // If the cn8 tree already exists, try and find the node that this payload contains.
      const targetNode = existingRoot.first(node => node.model.code === payload.code)

      // If the node is found from the tree (probably because the user is manually clicking through
      // the tree), expand it by adding the child nodes from this cn8 tree chunk (if any)
      if (targetNode) {
        targetNode.model.loading = false
        targetNode.model.loadError = false
        targetNode.model.loaded = true

        if (payload.children) {
          payload.children.forEach((child) => {
            const childNode = existingTree.parse(formatCN8TreeNode(child))
            addOrReplaceChildNode(targetNode, childNode)
          })
        }

        return newState
      }

      // If the requested node is not found from the tree, it means that an arbitrary chunk of the tree
      // was returned, probably due to search action. In order to fit this chunk in to the tree model, we
      // must first build the path to the returned node from its ancestors

      // If no ancestors were returned, we have no way of fitting this chunk in to the tree
      if (!payload.ancestors) {
        throw new Error('Unable to build CN8 tree due to missing ancestors')
      }

      const ancestors = payload.ancestors
      let parentIndex
      let parent

      // Find first matching ancestor. This is the node up to which point we already have the ancestry tree.
      ancestors.some((ancestor, index) => {
        const parentNode = existingRoot.first(node => node.model.code === ancestor.code)
        if (parentNode) {
          parentIndex = index
          parent = parentNode
          return true
        }
        return false
      })

      // Add missing ancestors from first match backwards to the tree
      for (let i = parentIndex - 1; i >= 0; i -= 1) {
        const childNode = existingTree.parse(formatCN8TreeNode(ancestors[i]))
        addOrReplaceChildNode(parent, childNode)
      }

      // Add the node itself to the tree
      const childNode = existingTree.parse(formatCN8TreeNode(payload))
      addOrReplaceChildNode(parent, childNode)

      return newState
    },
    throw: (state, { meta: { code, referencePeriod } }) => {
      const newState = {
        ...state,
        fetchingCN8Tree: false,
      }
      const existingRoot = get(newState, ['cn8Trees', referencePeriod.substr(0, 4), 'root'])
      const targetNode = existingRoot && existingRoot.first(node => node.model.code === code)
      if (targetNode) {
        targetNode.model.loading = false
        targetNode.model.loadError = true
      }
      return newState
    },
  },
  [CN8_TREE_CHUNKS_FETCHED]: state => ({
    ...state,
    fetchingCN8Tree: false,
  }),
}
