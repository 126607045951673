import { iban, bic, sepaCountry, getRequiredMessage } from 'src/utils/validation'
import {
  FORM_STATE,
} from './constants'


export function getMessage(messageKey, cmsMessages = {}) {
  return cmsMessages[`/individual-guarantee/${messageKey}`]
}

export function validateIbanField(value) {
  let paybackIbanError = iban(value, true)

  if (!paybackIbanError) {
    paybackIbanError = sepaCountry(value)
  }
  return paybackIbanError
}

export function validateBicField(value) {
  return bic(value, true)
}

export function formatIban(value) {
  return value.replace(/([A-Z0-9]{4})/g, '$1 ')
}

export function formatEuros(value) {
  if (value) {
    return new Intl.NumberFormat('fi-FI', { style: 'currency', currency: 'EUR' }).format(value)
  }
  return ''
}

export function validateCheckedTerms(value) {
  if (value === false) {
    return getRequiredMessage()
  }
  return ''
}

export function isGuaranteePaid(guaranteeState) {
  return guaranteeState === FORM_STATE.PAID ||
          guaranteeState === FORM_STATE.AVAILABLE ||
          guaranteeState === FORM_STATE.RESERVED ||
          guaranteeState === FORM_STATE.LIABILITY_RESERVED
}
