import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { injectIntl, FormattedMessage } from 'react-intl'
import { Button } from 'react-bootstrap'
import { get } from 'lodash'
import classNames from 'classnames'
import Immutable from 'immutable'
import ReactMarkdown from 'react-markdown'
import { formatUTCdateStringIntoFinnishFormat } from 'src/utils'
import Icon from 'src/components/Icon'
import styles from 'src/styles/_tables.scss'
import InfoArea from 'src/components/InfoArea'
import ErrorBoundary from 'src/components/ErrorBoundary'
import Loader from 'src/components/Loader'
import Table, { DESCENDING } from 'src/components/Table/Table'
import { focusElementById } from 'src/utils/index'
import DeleteApplicationModal from '../../permit/components/DeleteApplicationModal'
import messages from '../messages'
import api from '../../api'
import {
  DRAFT_DEFAULT_SORT_BY,
  PERMIT_APPLICATION_STATUS_DRAFT,
} from '../constants'
import { PERMITS_ROUTE_PATH, PERMIT_APPLICATION_ROUTE_PATH } from '../../constants'
import { INIT_ROUTE_PATH } from '../../permit/constants'
import { companySelectionChanged } from '../../permit/utils'

function filterData(permits) {
  return permits.filter(permit => permit.status === PERMIT_APPLICATION_STATUS_DRAFT)
}

class DraftsTab extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      list: Immutable.List([]),
      fetchingPermits: false,
      showDeleteApplicationModal: false,
      selectedDraftReferenceNumber: null,
      selectedDraftVersion: null,
      selectedRowId: props.selectedRowId,
    }

    this.onRowClick = this.onRowClick.bind(this)
    this.selectRow = this.selectRow.bind(this)
    this.handleGlobalClick = this.handleGlobalClick.bind(this)
    this.fetchRows = this.fetchRows.bind(this)
    this.resetDeleteApplicationState = this.resetDeleteApplicationState.bind(this)
    this.getFormatters = this.createFormatters.bind(this)
  }

  componentDidMount() {
    this.fetchRows()
    setTimeout(() => window.addEventListener('click', this.handleGlobalClick), 0)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (companySelectionChanged(this.props, nextProps)) {
      this.fetchRows(nextProps.selectedAuthorizationOrDecisionHolderIdentification)
    }
    if (this.props.locale !== nextProps.locale) {
      this.fetchRows()
    }
  }

  componentWillUnmount() {
    this.props.storeStateInRedux({
      selectedRowId: this.state.selectedRowId || null,
    })
    window.removeEventListener('click', this.handleGlobalClick)
  }

  onRowClick({ row }) {
    // If focus is on delete icon, we dont want to open draft with enter key
    if (document.activeElement?.id.includes('deleteIcon-')) {
      return
    }

    if (row && row.id) {
      this.selectRow(row.id)
      this.props.history.push(
        // eslint-disable-next-line max-len
        `/${PERMITS_ROUTE_PATH}/${PERMIT_APPLICATION_ROUTE_PATH}/${row.id}:${row.version}/${INIT_ROUTE_PATH}`
      )
    }
  }

  storeStateInRedux() {
    this.props.storeListViewState({
      selectedRowId: this.state.selectedRowId || null,
    })
  }

  handleGlobalClick(event) {
    // Remove row activation when user clicks outside this component
    if (this.state.selectedRowId && this.containerRef && !this.containerRef.contains(event.target)) {
      this.selectRow(null)
    }
  }

  fetchRows(authorizationOrDecisionHolderIdentification) {
    const { selectedAuthorizationOrDecisionHolderIdentification, formatRowData } = this.props
    this.setState({ fetchingPermits: true, errorFetchingRows: null })
    const identification = authorizationOrDecisionHolderIdentification || selectedAuthorizationOrDecisionHolderIdentification

    api.fetchPermitApplications(identification)
      .then((result) => {
        const formattedRows = []
        result.forEach(row => formattedRows.push(formatRowData(row)))
        return formattedRows
      })
      .then((rows) => {
        const filteredList = filterData(Immutable.List(rows))
        const nextState = {
          list: filteredList,
        }
        this.setState(nextState)
      })
      .catch((fetchError) => {
        if (fetchError && fetchError.id) {
          this.setState({ errorFetchingRows: fetchError })
        } else if (fetchError) {
          this.setState({ errorFetchingRows: messages.permitsListFetchError })
        }
      })
      .then(() => this.setState({ fetchingPermits: false }))
  }

  selectRow(id) {
    this.setState({ selectedRowId: id })
  }

  showDeleteApplicationModal(event, rowData) {
    event.stopPropagation()
    this.setState({
      showDeleteApplicationModal: true,
      selectedDraftReferenceNumber: rowData.id,
      selectedDraftVersion: rowData.version,
    })
  }

  resetDeleteApplicationState(selectedId = null) {
    // If we cancel the deletion of the draft, we focus the delete icon on correct row
    if (selectedId) {
      focusElementById(`deleteIcon-${selectedId}`)
    }

    this.setState({
      showDeleteApplicationModal: false,
      selectedDraftReferenceNumber: null,
      selectedDraftVersion: null,
    })
  }

  createFormatters() {
    const { intl: { formatMessage } } = this.props
    return {
      updateDate: (row, property) => formatUTCdateStringIntoFinnishFormat(row[property]),
      deleteDraft: row => (
        <Button
          bsStyle="link"
          className={classNames('btn-row btn-default', styles.deleteButton)}
          onClick={event => this.showDeleteApplicationModal(event, row)}
          id-qa-test={`btn-delete-draft-${row.id}`}
          id={`deleteIcon-${row.id}`}
        >
          <Icon name="delete" />
          <span className={'sr-only'}>{formatMessage(messages.actionsColumnScreenReaderLabel)}</span>
        </Button>
      ),
    }
  }

  render() {
    const { list, fetchingPermits } = this.state

    const {
      intl: { formatMessage },
      showDraftsRemovalNotification,
      showApplicationType,
    } = this.props

    const noRows = list.size === 0

    // remove showApplicationType usage after Q4/2023
    const typeOrCompanyLabel = { value: formatMessage(showApplicationType ? messages.columnLabelType : messages.columnLabelCompany) }
    const typeOrCompanyProperty = showApplicationType ? 'type' : 'companyName'

    const tableProperties = ['id', typeOrCompanyProperty, 'name', 'updateDate', 'deleteDraft']
    const tableHeadings = [
      { value: formatMessage(messages.columnLabelDraftId) },
      typeOrCompanyLabel,
      { value: formatMessage(messages.columnLabelPermitName) },
      { value: formatMessage(messages.columnLabelDateModified) },
      { value: formatMessage(messages.columnLabelActions), hide: true },
    ]

    return (
      <div ref={(container) => { this.containerRef = container }}>
        {this.state.showDeleteApplicationModal &&
          <DeleteApplicationModal
            referenceNumber={this.state.selectedDraftReferenceNumber}
            version={this.state.selectedDraftVersion}
            onClickContinue={() => {
              this.resetDeleteApplicationState()
              this.fetchRows()
            }}
            onClickCancel={() => this.resetDeleteApplicationState(this.state.selectedDraftReferenceNumber)}
          />
        }
        <div id="permit-tab-drafts-tabpanel" className="panel" role="tabpanel" aria-labelledby="permit-tab-drafts-tab">
          <div
            className={classNames('panel-body', 'panel-navigation', noRows && styles.verticalAlignmentForChildren)}
          >
            <ErrorBoundary>
              {fetchingPermits && (
                <div>
                  <Loader blocking message={messages.loadingApplications} />
                </div>
              )}
              {this.state.errorFetchingRows && (
                <div className="text-danger text-center">
                  <FormattedMessage {...this.state.errorFetchingRows} />
                </div>
              )}
              {showDraftsRemovalNotification &&
                <InfoArea icon="info" type="info">
                  <ReactMarkdown
                    source={formatMessage({ id: '/permits/applicationDraftRemovalInfo' })}
                  />
                </InfoArea>
              }
              {(!fetchingPermits && !this.state.errorFetchingRows) &&
                <Table
                  headings={tableHeadings}
                  contentRows={list}
                  properties={tableProperties}
                  formatters={this.createFormatters()}
                  onRowClick={this.onRowClick}
                  sortByColumn={DRAFT_DEFAULT_SORT_BY}
                  sortByDirection={DESCENDING}
                  sortable
                  useRowHeadings
                  className={styles.rowPadding}
                />
              }
            </ErrorBoundary>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  selectedRowId: get(state.permits.list, 'selectedRowId'),
  showDraftsRemovalNotification: get(state.config.features, 'PERMITS_OLD_DRAFTS', false),
  showApplicationType: get(state.config.features, 'ALLOW_REVOKE_DECISION', false),
})

export default withRouter(
  connect(mapStateToProps, undefined)(
    injectIntl(DraftsTab)
  )
)
