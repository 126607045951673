import React from 'react'
import { connect } from 'react-redux'
import { injectIntl, FormattedMessage } from 'react-intl'
import { withRouter } from 'react-router-dom'
import { Button } from 'react-bootstrap'
import Loader from 'src/components/Loader'
import { fetchNonCachedCodesets } from 'src/components/codeset/actions'
import { get } from 'lodash'
import { fetchCustomerInformation, fetchRepresentativeInformation } from 'src/components/customer/actions'
import Modal from 'src/components/Modal'
import PermitsFrontpage from './list/components/PermitsFrontpage'
import {
  switchLocale,
} from '../../actions'
import messages from './messages'
import { getApplicant, getRepresentative } from './permit/permitHelper'
import { isApplicantPerson } from '../../utils/auth'

class PermitsRoot extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      redirecting: false,
    }
  }

  // eslint-disable-next-line
  componentDidMount() {
    const { locale, permitChatId } = this.props
    let language = 'fi_FI'
    if (locale === 'en') {
      language = 'en_US'
    } else if (locale === 'sv') {
      language = 'sv_SE'
    }

    if (permitChatId) {
      const script = document.createElement('script')
      script.id = 'oc-start-up'
      script.setAttribute('data-oc-service', permitChatId)
      script.setAttribute('data-main', 'https://occhat.elisa.fi/chatserver//Scripts/oc-chat')
      script.setAttribute('data-oc-language', language)
      script.src = 'https://occhat.elisa.fi/chatserver//Scripts/oc-chat-v2.js'
      document.body.appendChild(script)
    }
  }

  render() {
    const { children, locale, history, onSwitchLocale, intl } = this.props

    if (locale !== 'fi' && locale !== 'sv') {
      return (
        <Modal
          show
          showCancel={false}
          showContinue={false}
          size={'lg'}
          locale={locale}
          autoFocus
          titleMessage={intl.formatMessage(messages.permitsLanguageNotSupportedHeading)}
          onSwitchLocale={onSwitchLocale}
          focusButton
          disableEscKey
          customFooterButtons={(
            <React.Fragment>
              <Button
                key="buttonCancel"
                bsStyle="default"
                onClick={() => history.replace('/')}
                id-qa-test="button-cancel"
                autoFocus
              >
                <FormattedMessage {...messages.cancel} />
              </Button>
              <div className="pull-right">
                <Button
                  key="buttonLanguageSV"
                  bsStyle="primary"
                  onClick={() => onSwitchLocale('sv')}
                  id-qa-test="button-change-language-sv"
                >
                  <FormattedMessage {...messages.changeLanguageSv} />
                </Button>
                <Button
                  key="buttonLanguageFI"
                  bsStyle="primary"
                  onClick={() => onSwitchLocale('fi')}
                  id-qa-test="button-change-language-fi"
                >
                  <FormattedMessage {...messages.changeLanguageFi} />
                </Button>
              </div>
            </React.Fragment>
          )}
        >
          <div>
            <p><FormattedMessage {...messages.permitsEnglishNotSupported} /></p>
          </div>
        </Modal>
      )
    }

    return (
      <div>
        {this.state.redirecting && <Loader blocking />}
        {children && <div>{children}</div>}
        {!children && <PermitsFrontpage {...this.props} />}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const applicant = getApplicant()
  const representative = getRepresentative()
  const isPerson = isApplicantPerson()
  const hasEori = get(state.customer, `customerInformation.${applicant.id}.hasEori`, false)

  return {
    locale: state.locale,
    permitChatId: get(state.config, 'bootstrapConfig.nakki_permit_chat_id'),
    customerHasEori: hasEori,
    customer: state.customer,
    codeset: get(state, 'codeset.cachedCodesets'),
    applicant,
    representative,
    isPerson,
  }
}

const mapActionCreators = {
  onSwitchLocale: switchLocale,
  fetchCustomerInformation,
  fetchRepresentativeInformation,
  fetchNonCachedCodesets,
}

export default withRouter(connect(mapStateToProps, mapActionCreators)(injectIntl(PermitsRoot)))
