import { isEmpty, trim } from 'lodash'
import {
  InvalidFileSyntaxError,
  ImportValidationError,
  parseOrganizationIdAndReportingUnit,
  parseInteger,
} from '../utils'
import messages from './messages'

const CSV_PSI_INDEX = 0
const CSV_REFERENCE_PERIOD_INDEX = 1
const CSV_DIRECTION_INDEX = 2
const CSV_TDP_INDEX = 3
const CSV_CN8CODE_INDEX = 4
const CSV_TRANSACTION_INDEX = 5
const CSV_MEMBER_STATE_INDEX = 6
const CSV_ORIGIN_COUNTRY_INDEX = 7
const CSV_TRANSPORT_INDEX = 8
const CSV_NET_MASS_INDEX = 9
const CSV_QUANTITY_IN_SU_INDEX = 10
const CSV_INVOICED_AMOUNT_INDEX = 11
const CSV_STAT_VALUE_INDEX = 12
const CSV_REFERENCE_INDEX = 13
const TOTAL_NUMBER_OF_COLUMNS = 14

/**
 * Parse declaration headers from array of comma separated string data.
 * Header data must be set on line 1.
 */
function parseHeaderData(arrayOfLines, userOrganizationId, userOrganizationName, confirmationCallback) {
  if (arrayOfLines.length < 2) {
    throw new InvalidFileSyntaxError({
      _error: {
        ...messages.invalidFileSyntax,
      },
    })
  }
  const headerData = arrayOfLines[1].split(/;/)
  if (headerData.length !== TOTAL_NUMBER_OF_COLUMNS) {
    throw new InvalidFileSyntaxError({
      _error: messages.invalidNumberOfHeaderColumnsInCSV,
    })
  }
  const directionMapping = { 1: 'ARRIVAL_OF_GOODS', 2: 'DISPATCH_OF_GOODS' }
  const psiData = parseOrganizationIdAndReportingUnit(headerData[CSV_PSI_INDEX])
  const tdpData = parseOrganizationIdAndReportingUnit(headerData[CSV_TDP_INDEX])
  const tdpIsRelevant = userOrganizationId !== psiData.organizationId
  if (tdpIsRelevant && tdpData.organizationId !== userOrganizationId) {
    const userAllowsTdpReplace = confirmationCallback({
      ...messages.invalidTdpConfirmation,
      values: {
        invalidOrganizationId: tdpData.organizationId,
        userOrganizationId,
        userOrganizationName,
      },
    })
    if (!userAllowsTdpReplace) {
      throw new ImportValidationError({
        _error: {
          ...messages.invalidTdpRejectedByUser,
          values: {
            invalidTdp: tdpData.organizationId,
          },
        },
      })
    }
    tdpData.organizationId = userOrganizationId
    tdpData.reportingUnit = undefined
  }
  return {
    psi: psiData.organizationId,
    psiReportingUnit: psiData.reportingUnit,
    referencePeriod: headerData[CSV_REFERENCE_PERIOD_INDEX],
    flowCode: directionMapping[headerData[CSV_DIRECTION_INDEX]] || undefined,
    tdp: tdpIsRelevant ? tdpData.organizationId : undefined,
    tdpReportingUnit: tdpIsRelevant ? tdpData.reportingUnit : undefined,
  }
}

/**
 * Parse declaration rows from array of comma separated string data.
 * Row data begins from line 1.
 */
function parseRowData(arrayOfLines) {
  const rows = []
  for (let i = 1; i < arrayOfLines.length; i += 1) {
    const row = arrayOfLines[i].split(/;/)

    if (row.length !== TOTAL_NUMBER_OF_COLUMNS) {
      throw new InvalidFileSyntaxError({
        _error: {
          ...messages.invalidNumberOfDataColumnsInCSV,
          values: { lineNumber: i + 1 },
        },
      })
    }

    const rowData = {
      index: i,
      CN8Code: trim(row[CSV_CN8CODE_INDEX]) || undefined,
      natureOfTransactionCode: parseInteger(row[CSV_TRANSACTION_INDEX]) || undefined,
      memberState: trim(row[CSV_MEMBER_STATE_INDEX]) || undefined,
      countryOfOrigin: trim(row[CSV_ORIGIN_COUNTRY_INDEX]) || undefined,
      modeOfTransport: parseInteger(row[CSV_TRANSPORT_INDEX]) || undefined,
      netMass: trim(row[CSV_NET_MASS_INDEX]),
      quantityInSU: parseInteger(row[CSV_QUANTITY_IN_SU_INDEX]) || undefined,
      invoicedAmount: parseInteger(row[CSV_INVOICED_AMOUNT_INDEX]) || undefined,
      statisticalValue: parseInteger(row[CSV_STAT_VALUE_INDEX]) || undefined,
      invoiceNumber: trim(row[CSV_REFERENCE_INDEX]) || undefined,
      partnerId: trim(row[CSV_TDP_INDEX]) || undefined,
    }

    // In netMass only non-zero empty values are considered undefined
    // NOTE: On the contrary in quantityInSU also zeros are considered undefined!
    if (isEmpty(rowData.netMass)) {
      rowData.netMass = undefined
    } else {
      rowData.netMass = parseInteger(rowData.netMass)
    }
    // In statistical value convert zeros to undefined
    // NOTE: On the contrary in invoiced amount zeros are allowed!
    if (rowData.statisticalValue && rowData.statisticalValue === 0) {
      rowData.statisticalValue = undefined
    }
    if (rowData.statisticalValue) {
      rowData.statisticalValueCurrencyCode = 'EUR'
    }
    if (rowData.invoicedAmount) {
      rowData.invoicedValueCurrencyCode = 'EUR'
    }

    rows.push(rowData)
  }
  return rows
}

export default function parse(data, onlyRows, userOrganizationId, userOrganizationName, confirmationCallback) {
  const arrayOfLines = data.match(/[^\r\n]+/g)
  const parsedData = {}
  if (!onlyRows) {
    parsedData.headers = parseHeaderData(arrayOfLines, userOrganizationId, userOrganizationName, confirmationCallback)
  }
  parsedData.rows = parseRowData(arrayOfLines)
  return parsedData
}
