import React from 'react'
import { injectIntl, defineMessages, FormattedMessage } from 'react-intl'
import classNames from 'classnames'
import { untouch } from 'redux-form'
import Autosuggest from 'react-autosuggest'
import { get } from 'lodash'
import { updateStatusMessageForInput, fixAutocompleteAccessibility } from 'src/utils'
import InteractiveElement from 'src/components/InteractiveElement'
import Icon from 'src/components/Icon'
import styles from 'src/styles/_forms.scss'
import FormControlGroup from './FormControlGroup'
import { messages as inputMessages } from './InputArea'
import './autocomplete.scss'

const messages = defineMessages({
  errorNoOptions: {
    id: 'form.error.noOptionsPlaceholder',
    description: 'Error placeholder for input without necessary data',
    defaultMessage: 'Error in loading options',
  },
})

class Autocomplete extends React.Component {
  static renderSuggestion(option, { query }) {
    const suggestion = option.title || option.value
    const filterMatchAt = suggestion.toLowerCase().indexOf(query.toLowerCase())
    if (!query || !query.length || filterMatchAt < 0) {
      return suggestion
    }
    const beginning = suggestion.slice(0, filterMatchAt)
    const match = suggestion.slice(filterMatchAt, filterMatchAt + query.length)
    const end = suggestion.slice(filterMatchAt + query.length)
    return (
      <span>{beginning}<strong>{match}</strong>{end}</span>
    )
  }

  constructor(props) {
    super(props)
    this.onChange = this.onChange.bind(this)
    this.onBlur = this.onBlur.bind(this)
    this.onClearSelection = this.onClearSelection.bind(this)
    this.onSuggestionsFetchRequested = this.onSuggestionsFetchRequested.bind(this)
    this.onSuggestionsClearRequested = this.onSuggestionsClearRequested.bind(this)
    this.onSuggestionSelected = this.onSuggestionSelected.bind(this)
    this.filterSuggestions = this.filterSuggestions.bind(this)
    this.shouldRenderSuggestions = this.shouldRenderSuggestions.bind(this)

    let selectedOption
    if (props.options && props.input.value) {
      selectedOption = props.options.find(it => it.value === props.input.value)
    }
    this.state = {
      value: selectedOption ? selectedOption.title : '',
      suggestions: props.options || [],
    }
  }

  componentDidMount() {
    const inputName = get(this.props, 'input.name')
    fixAutocompleteAccessibility(inputName)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!nextProps.input.value && this.props.input.value) {
      this.setState({ value: '' })
      return
    }

    if (!nextProps.input.value && nextProps.initialized) {
      this.setState({ value: '' })
      return
    }
    if (!nextProps.input.value || nextProps.input.value === this.props.input.value) {
      return
    }
    const selectedOption = nextProps.options && nextProps.options.find(it => it.value === nextProps.input.value)
    this.setState({
      value: selectedOption ? selectedOption.title : '',
    })
  }

  componentDidUpdate() {
    const inputName = get(this.props, 'input.name')
    fixAutocompleteAccessibility(inputName)
  }

  onChange(event, { newValue }) {
    this.setState({
      value: newValue,
    })
  }

  onBlur() {
    const value = this.state.value
    const matchingOption = value && this.filterSuggestions(value)[0]
    if (matchingOption) {
      this.props.input.onBlur(matchingOption.value)
    } else {
      this.setState({ value: '' })
      this.props.input.onBlur(null)
      updateStatusMessageForInput(this.props.input.name)
    }
  }

  onClearSelection() {
    const { meta, input } = this.props
    this.setState({ value: '' })
    input.onChange(null)
    if (meta && !meta.initial) {
      meta.dispatch(untouch(meta.form, input.name))
    }
    setTimeout(() => this.autosuggestRef.input.focus(), 0)
  }

  onSuggestionsFetchRequested({ value }) {
    this.setState({
      suggestions: this.filterSuggestions(value) || [],
    })
  }

  onSuggestionsClearRequested() {
    this.setState({
      suggestions: [],
    })
  }

  onSuggestionSelected(event, { suggestionValue }) {
    const matchingOption = this.props.options.find(it => it.title === suggestionValue)
    this.props.input.onChange(matchingOption.value)
  }

  shouldRenderSuggestions(value) {
    if (!value) {
      return true
    }
    const suggestions = this.filterSuggestions(value)
    if (suggestions.length === 0) {
      return false
    }
    if (suggestions.length === 1 && suggestions[0].value.title === value) {
      return false
    }
    return true
  }

  filterSuggestions(value) {
    const inputValue = value.trim().toLowerCase()
    const inputLength = inputValue.length

    if (!this.props.options || !this.props.options.length) {
      return []
    }

    if (inputLength === 0) {
      return this.props.options
    }

    const options = this.props.options
      .map((option) => {
        let relevance = 0

        const valueMatchOffset = option.value.toLowerCase().indexOf(inputValue)

        if (valueMatchOffset === 0) {
          relevance += 100
        } else if (valueMatchOffset > 0) {
          relevance += 30
        }

        const titleMatchOffset = option.title.toLowerCase().indexOf(inputValue)

        if (titleMatchOffset === 0) {
          relevance += 50
        } else if (titleMatchOffset > 0) {
          relevance += 1
        }

        return Object.assign({}, option, { relevance })
      })

    return options
      .filter(option => option.relevance > 0)
      .sort((optionA, optionB) => {
        if (optionA.relevance === optionB.relevance) {
          return optionA.title.localeCompare(optionB.title)
        }

        return optionB.relevance - optionA.relevance
      })
  }

  render() {
    const {
      value,
      title,
      suggestions,
    } = this.state

    const {
      input,
      disabled,
      options,
      mandatory,
      fetchError,
      ariaLabel,
      labelId,
      className,
      intl: { formatMessage },
      autoFocus,
    } = this.props

    let { placeholderMessage } = this.props

    if (fetchError && !(options && options.length)) {
      placeholderMessage = messages.errorNoOptions
    }

    return (
      <div>
        <Autosuggest
          id={`${input.name}-options`}
          ref={(el) => { this.autosuggestRef = el }}
          suggestions={suggestions}
          getSuggestionValue={option => option.title}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          onSuggestionSelected={this.onSuggestionSelected}
          shouldRenderSuggestions={this.shouldRenderSuggestions}
          renderSuggestion={Autocomplete.renderSuggestion}
          inputProps={{
            id: input.name,
            className: classNames('form-control autocomplete', className),
            onChange: this.onChange,
            onBlur: this.onBlur,
            value,
            title,
            disabled,
            autoComplete: 'chrome-off',
            'aria-label': labelId ? undefined : ariaLabel,
            'aria-labelledby': labelId,
            'aria-required': mandatory,
            placeholder: formatMessage(placeholderMessage),
            'id-qa-test': `select-${input.name}`,
            autoFocus,
          }}
        />
        {!this.state.value &&
          <span
            className={styles.inputActionIcon}
            style={{ pointerEvents: 'none', cursor: 'pointer' }}
          >
            <Icon name="chevron-tight-down" inline />
          </span>
        }
        {this.state.value &&
          <InteractiveElement
            id={`${input.name}-clear`}
            className={styles.inputActionIcon}
            onClick={this.onClearSelection}
            id-qa-test={`btn-clearSelection-${input.name}`}
          >
            <Icon name="close" inline />
            <span className="sr-only"><FormattedMessage {...inputMessages.clearInputValue} /></span>
          </InteractiveElement>
        }
      </div>
    )
  }
}

export default injectIntl(Autocomplete)

export function AutocompleteFieldGroup(props) {
  return (
    <FormControlGroup {...props}>
      <Autocomplete {...props} />
    </FormControlGroup>
  )
}
