import React from 'react'
import Modal from 'src/components/Modal'
import { FormattedMessage, defineMessages } from 'react-intl'
import './Confirm.scss'

const messages = defineMessages({
  continue: {
    id: 'intrastat.confirmation.save',
    description: 'Label for Intrastat copy declaration modal submit button',
    defaultMessage: 'OK',
  },
  cancel: {
    id: 'intrastat.confirmation.cancel',
    description: 'Label for Intrastat copy declaration modal cancel button',
    defaultMessage: 'Cancel',
  },
  confirmContent: {
    id: 'intrastat.confirmation.confirmContent',
    description: 'Confirm changes?',
    defaultMessage: 'OK',
  },
  confirmTitle: {
    id: 'intrastat.confirmation.confirmTitle',
    description: 'Label for Intrastat copy declaration modal cancel button',
    defaultMessage: 'Confirm',
  },
})

const fixDialogRoles = () => {
  const elements = Array.from(document.querySelectorAll('div[role="dialog"],div[role="document"]'))

  const el = elements[elements.length - 1]
  if (el && el.classList.contains('modal')) {
    el.setAttribute('aria-labelledby', 'dialog-save-title')
    el.setAttribute('aria-describedby', 'dialog-save-message')
  } else {
    el.removeAttribute('role')
  }
}

const keyboardNavigationHandler = (event) => {
  if (event.keyCode === 9) {
    const modals = Array.from(document.querySelectorAll('div[role="dialog"],div[role="document"]'))
    const modal = modals[modals.length - 1]
    const fieldElements = modal.querySelectorAll('a, input, select, button, textarea')
    if (fieldElements.length > 0) {
      const firstFocusableElement = fieldElements[0]
      const lastFocusableElement = fieldElements[fieldElements.length - 1]

      if (event.shiftKey && document.activeElement === firstFocusableElement) {
        event.preventDefault()
        lastFocusableElement.focus()
      } else if (!event.shiftKey && document.activeElement === lastFocusableElement) {
        event.preventDefault()
        firstFocusableElement.focus()
      }
    }
  }
}

const addKeyDownHandler = () => {
  const elements = Array.from(document.querySelectorAll('div.modal[role="dialog"]'))
  const element = elements[elements.length - 1]

  if (element) {
    element.addEventListener('keydown', keyboardNavigationHandler, false)
  }
}

const setMainModalAriaHidden = (hidden) => {
  const elements = Array.from(document.querySelectorAll('div.modal[role="dialog"]'))
  const element = elements[0]

  if (!hidden) {
    element.removeAttribute('aria-hidden')
    element.parentElement.removeAttribute('aria-hidden')
  } else {
    element.setAttribute('aria-hidden', hidden)
    element.parentElement.setAttribute('aria-hidden', hidden)
  }
}


function Confirm({ show, onCancel, onConfirm }) {
  const onShowCb = () => {
    fixDialogRoles()
    addKeyDownHandler()
    setMainModalAriaHidden(true)
  }

  const onExited = () => {
    setMainModalAriaHidden(false)
  }

  return (
    <Modal
      showCancel
      showContinue
      size={'xs'}
      show={show}
      onClickCancel={onCancel}
      onClickContinue={onConfirm}
      continueMessage={messages.continue}
      cancelMessage={messages.cancel}
      className={'confirmModal'}
      focusButton
      focusDisableButton
      onExited={onExited}
      disable
      onShowCb={onShowCb}
    >
      <h3 id="dialog-save-title">
        <FormattedMessage {...messages.confirmTitle} />
      </h3>
      <div id="dialog-save-message" className="confirm-content">
        <FormattedMessage {...messages.confirmContent} />
      </div>
    </Modal>
  )
}

export default Confirm
