import React, { Fragment, useEffect } from 'react'
import Heading from 'src/layout/Heading'
import { Button, ButtonToolbar } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import Icon from 'src/components/Icon'
import { connect } from 'react-redux'
import { get } from 'lodash'
import { withRouter, Link } from 'react-router-dom'

import DeclarationsList from '../containers/DeclarationsListContainer'
import messages from '../messages'
import { INTRASTAT_ROUTE_PATH, DECLARATION_ROUTE_PATH, INTRASTAT_CACHE_CUSTOMERS } from '../../constants'
import { selectPsi } from '../../actions'
import {
  HEADERS_ROUTE_PATH,
  NEW_ROUTE_PATH, PSI, PSI_REPORTER_TYPES, TDP_REPORTER_TYPES,
} from '../../declaration/constants'
import { getSelectedAuthorization, getSelectedDelegateCompanyFromAuthentication } from '../../../../utils/auth'
import InfoArea from '../../../../components/InfoArea'
import { checkReporterTypes } from '../../utils'
import { collectCmsMessages } from '../../../../utils/index'
import withReducer from '../../../../reducers/withReducer'
import intrastatListReducer from '../../list/reducer'

function IntrastatFrontpage(props) {
  const {
    cachedCustomers,
    selectedAuthorizationId,
    selectedDelegateCompanyId,
    fetchingCustomers,
    content,
    selectPsiAndTdp,
  } = props

  useEffect(() => {
    selectPsiAndTdp(selectedAuthorizationId, selectedDelegateCompanyId, true, selectedAuthorizationId === selectedDelegateCompanyId)
  }, [])

  // In order to be allowed to use the Intrastat service, the selected authorization(Tiedonantovelvollinen)
  // has to have reporterType of PSI or reportingUnits that are type PSI or REPORTING_UNIT
  // The delegate company(Asiamies) can have reportingUnits that are type PSI, TDP or REPORTING_UNIT
  const correctSelectedAuthorizationReporterType = cachedCustomers.some(customer =>
    customer.id === selectedAuthorizationId && (customer.reporterType === PSI || checkReporterTypes(customer.reportingUnits, PSI_REPORTER_TYPES))
  )
  const correctDelegateCompanyReporterType =
    cachedCustomers.some(customer =>
      // TDP company usually has reportingUnits that have reporterType, but it can also have reporterType on the top level. So we check both
      customer.id === selectedDelegateCompanyId && (checkReporterTypes(customer.reportingUnits, TDP_REPORTER_TYPES) || TDP_REPORTER_TYPES.includes(customer.reporterType))
    )
  const showDeclarationList = selectedDelegateCompanyId ? correctSelectedAuthorizationReporterType && correctDelegateCompanyReporterType : correctSelectedAuthorizationReporterType

  return (
    <div>
      <Heading message={messages.title} />
      <main className="container" role="main" id="main">
        {!showDeclarationList && !fetchingCustomers &&
          <InfoArea title={get(content, 'askareErrorInfoTitle')}>
            {get(content, 'askareErrorInfoBody')}
          </InfoArea>
        }
        {showDeclarationList &&
          <Fragment>
            <ButtonToolbar>
              <Link to={`/${INTRASTAT_ROUTE_PATH}/${DECLARATION_ROUTE_PATH}/${NEW_ROUTE_PATH}/${HEADERS_ROUTE_PATH}`}>
                <Button bsStyle="primary">
                  <Icon name="add-bold" /><FormattedMessage {...messages.createNewDeclaration} />
                </Button>
              </Link>
            </ButtonToolbar>

            <DeclarationsList />
          </Fragment>
        }
      </main>
    </div >
  )
}
const mapActionCreators = {
  selectPsiAndTdp: selectPsi,
}
const mapStateToProps = (state) => {
  const cmsMessages = get(state, 'content.cmsMessages', {})
  const content = collectCmsMessages('/intrastat/frontpage', cmsMessages)

  return {
    content,
    cachedCustomers: state.intrastat.common.cachedCustomers,
    selectedAuthorizationId: get(getSelectedAuthorization(), 'id'),
    selectedDelegateCompanyId: get(getSelectedDelegateCompanyFromAuthentication(), 'id'),
    fetchingCustomers: state.loading[INTRASTAT_CACHE_CUSTOMERS],
  }
}
export default withReducer([
  { key: 'intrastat.list', reducer: intrastatListReducer },
])(withRouter(connect(mapStateToProps, mapActionCreators)(IntrastatFrontpage)))
