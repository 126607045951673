import React from 'react'
import moment from 'moment'
import { get, sortedUniq } from 'lodash'
import { withRouter } from 'react-router-dom'

import cn from 'classnames/bind'
import Icon from 'src/components/Icon'
import InteractiveElement from 'src/components/InteractiveElement'
import { sortServices } from 'src/utils'
import styles from './frontpage.scss'

const boundCn = cn.bind(styles)


// eslint-disable-next-line
const createMaintenanceNotificationsFilter = (visibilityType) => {
  const now = moment()
  return notification =>
    notification.visibility.includes(visibilityType) &&
    now.isBetween(notification.notificationStartDate, notification.notificationEndDate, null, '[]')
}
// eslint-disable-next-line
const createMaintenanceNotificationFormatter = locale =>
  it => ({
    message: it.message[locale],
    startDate: it.maintenanceStartDate && moment(it.maintenanceStartDate).locale(locale).format('l LT'),
    endDate: it.maintenanceEndDate && moment(it.maintenanceEndDate).locale(locale).format('l LT'),
  })

class ServiceList extends React.Component {
  static sortServices(services) {
    return sortServices(services)
  }
  constructor(props) {
    super(props)
    this.resizeServiceElementsByLargest = this.resizeServiceElementsByLargest.bind(this)
    this.serviceRefs = {}
  }
  componentDidMount() {
    this.resizeServiceElementsByLargest()
  }

  componentDidUpdate() {
    this.resizeServiceElementsByLargest()
  }

  resizeServiceElementsByLargest() {
    const serviceRefsArray = Object.values(this.serviceRefs).filter(ref => ref != null)
    const sortedUniqueHeights = sortedUniq(serviceRefsArray.map(ref => ref.clientHeight).sort((a, b) => b - a))
    if (sortedUniqueHeights.length > 1) {
      serviceRefsArray.forEach((ref) => {
        // eslint-disable-next-line no-param-reassign
        ref.style.minHeight = `${sortedUniqueHeights[0]}px`
      })
    }
  }

  redirectToService = (service) => {
    const { history, locale } = this.props

    const localPath = get(service.extensionValues.find(extValue => extValue.extensionName === 'localPath'), 'value')

    const externalUrl = service.extensionValues.find(
      extValue => extValue.extensionName === 'externalUrl' && extValue.languageCode === locale
    )

    if (localPath && localPath.length) {
      history.push(localPath)
    } else if (externalUrl && externalUrl.value.length) {
      window.location.href = externalUrl.value
    } else {
      throw new Error('Error in redirecting to service: no local path or external Url found')
    }
  }

  render() {
    const { services, locale } = this.props

    return (
      <ul className="row nav equal">
        {services && ServiceList.sortServices(services).map(((service) => {
          const basicAttribute = service.basicAttributes.find(attribute => attribute.languageCode === locale)

          const titleShort = basicAttribute.abbreviation
          const titleLong = basicAttribute.name
          const description = basicAttribute.description
          const icon = service.extensionValues.find(extValue => extValue.extensionName === 'icon').value

          /* Disable notifications for now
          const activeNotifications = service.maintenanceNotifications && service.maintenanceNotifications
            .filter(createMaintenanceNotificationsFilter(visibility))
            .map(createMaintenanceNotificationFormatter(locale))
          */
          const activeNotifications = false

          return (
            <li
              id={service.code}
              key={service.code}
              className={boundCn(styles.frontpageService, `${service.code}Service`, 'col-md-4 col-sm-6 col-xs-12')}
              ref={(serviceRef) => { this.serviceRefs[service.code] = serviceRef }}
            >
              <InteractiveElement
                className="media"
                onClick={() => this.redirectToService(service)}
                id-qa-test={`${service.code}-${titleShort}`}
              >
                {icon && icon.length &&
                  <div className="media-object" style={{ backgroundColor: '#99a6bd', color: 'white' }}>
                    <Icon name={icon} />
                  </div>
                }
                <div className="media-body">
                  {(titleLong || titleShort) &&
                    <h3 className="media-heading">
                      {titleLong || titleShort}
                    </h3>
                  }
                  {description &&
                    <div className="description">
                      {description}
                    </div>
                  }
                  {activeNotifications &&
                    <div className={styles.maintenanceBreaks}>
                      {activeNotifications.map((notification, index) =>
                        <div key={index}>
                          {notification.message} ({notification.startDate} - {notification.endDate})
                        </div>
                      )}
                    </div>
                  }
                </div>
              </InteractiveElement>
            </li>
          )
        }))}
      </ul>
    )
  }
}

export default withRouter(ServiceList)