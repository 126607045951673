/* eslint-disable no-param-reassign */
import {
  createValidator,
  minLength,
  maxLength,
  max,
  integer,
  positiveInteger,
  positiveIntegerOrEmpty,
  nonNegativeInteger,
  required,
  regEx,
  messages as validationMessages,
} from 'src/utils/validation'
import moment from 'moment'
import get from 'lodash/get'
import messages from './messages'
import apiMessages from '../../apiMessages'
import { SMALL_VALUE_CN8CODE, CN8CODES_WITH_OPTIONAL_NET_MASS, NATURE_OF_TRANSACTIONS, MODES_OF_TRANSPORT, ITEM_GROUPS, UNDEFINED_AREA_CODE_QV } from './constants'
import { rowErrorField } from '../../constants'

function addValidationErrors(errorObject, key, validationResult) {
  if (validationResult) {
    // eslint-disable-next-line no-param-reassign
    errorObject[key] = validationResult
  }
}

export const shouldValidateRow = ({
  values,
  nextProps,
  props,
  initialRender,
  structure,
  lastFieldValidatorKeys,
  fieldValidatorKeys,
}) => {
  // Row values are not initialized on initial render, should not validate
  if (initialRender) {
    return false
  }
  // Row validation depends on CN8 data
  // Never validate while fetching, always validate after done fetching
  if (props.fetchingCN8 === true) {
    return !nextProps.fetchingCN8
  }

  // Default functionality from Redux Form's defaultShouldValidate.js
  return !structure.deepEqual(values, nextProps.values) ||
    !structure.deepEqual(lastFieldValidatorKeys, fieldValidatorKeys)
}

const isValidRegex = (text, expression) => regEx(expression)(text) === null


const validateSpecificCountry = (countryCode, rest) => {
  switch (countryCode) {
  case 'BE':
    return isValidRegex(rest, /^\d{10}$/i)
  case 'BG':
    return isValidRegex(rest, /^\d{9,10}$/i)
  case 'ES':
    return isValidRegex(rest, /^([A-Za-z]{1})(\d{8})$/i) || isValidRegex(rest, /^(\d{8})([A-Za-z]{1})$/i) ||
        isValidRegex(rest, /^([A-Za-z]{1})(\d{7})([A-Za-z]{1})$/i)
  case 'NL':
    return isValidRegex(rest, /^(\d{9})(B{1})(\d{2})$/i)
  case 'IE':
    return isValidRegex(rest, /^(\d{1})(.{1})(\d{5})([A-Za-z])$/i) ||
        isValidRegex(rest, /^(\d{7})([A-Za-z]{2})$/i)
  case 'GB':
    return isValidRegex(rest, /^\d{9}$/i) || isValidRegex(rest, /^\d{12}$/i) ||
        isValidRegex(rest, /^(GD)(\d{3})$/i) || isValidRegex(rest, /^(HA)(\d{3})$/i)
  case 'XI':
    return isValidRegex(rest, /^\d{9}$/i) || isValidRegex(rest, /^\d{12}$/i) ||
        isValidRegex(rest, /^(GD)(\d{3})$/i) || isValidRegex(rest, /^(HA)(\d{3})$/i)
  case 'IT':
    return isValidRegex(rest, /^\d{11}$/i)
  case 'AT':
    return isValidRegex(rest, /^(U)(\d{8})$/i)
  case 'EL':
    return isValidRegex(rest, /^\d{9}$/i)
  case 'HR':
    return isValidRegex(rest, /^\d{11}$/i)
  case 'CY':
    return isValidRegex(rest, /^\d{8}([A-Za-z]{1})$/i)
  case 'LV':
    return isValidRegex(rest, /^\d{11}$/i)
  case 'LT':
    return isValidRegex(rest, /^\d{9}$/i) || isValidRegex(rest, /^\d{12}$/i)
  case 'LU':
    return isValidRegex(rest, /^\d{8}$/i)
  case 'MT':
    return isValidRegex(rest, /^\d{8}$/i)
  case 'PT':
    return isValidRegex(rest, /^\d{9}$/i)
  case 'PL':
    return isValidRegex(rest, /^\d{10}$/i)
  case 'FR':
    return isValidRegex(rest, /^([A-Za-z0-9]{2})(\d{9})$/i)
  case 'RO':
    return isValidRegex(rest, /^\d{2,10}$/i)
  case 'SE':
    return isValidRegex(rest, /^(\d{10})(01)$/i)
  case 'DE':
    return isValidRegex(rest, /^\d{9}$/i)
  case 'SK':
    return isValidRegex(rest, /^\d{10}$/i)
  case 'SI':
    return isValidRegex(rest, /^\d{8}$/i)
  case 'FI':
    return isValidRegex(rest, /^\d{8}$/i)
  case 'DK':
    return isValidRegex(rest, /^\d{8}$/i)
  case 'CZ':
    return isValidRegex(rest, /^\d{8,10}$/i)
  case 'HU':
    return isValidRegex(rest, /^\d{8}$/i)
  case 'EE':
    return isValidRegex(rest, /^\d{9}$/i)
  default:
    return true
  }
}

const validatePartnerId = (values, warnings, selectOptions, referencePeriod) => {
  const memberStates = get(selectOptions, 'memberStates', [])
  const partnerId = values.partnerId
  const isFrom2021 = moment(referencePeriod, 'YYYYMM').year() === 2021
  const countriesOfOrigin = get(selectOptions, 'countriesOfOrigin', [])
  const countriesOfOriginSimStat = get(selectOptions, 'countriesOfOriginForSimstat')

  if (!partnerId || partnerId.length <= 2) {
    addValidationErrors(warnings, 'partnerId', validationMessages.invalidPartnerId)
  } else {
    const match = partnerId.match(/^([A-Z\s]{2})(.*)/i)
    if (match) {
      const countryCode = match[1]
      const rest = match[2]
      const countryFromList = memberStates.some(item => item.value === countryCode)
      const countryFromOriginList = countriesOfOrigin.some(item => item.value === countryCode)
      const countryFromOriginSimStat = countriesOfOriginSimStat.some(item => item.value === countryCode)

      // Kolmikantakauppa
      if (rest === '999999999999') {
        if (countryFromOriginList || countryFromOriginSimStat) {
          /*
            Usually the memberState(Viennin kohdemaa) and the countryCode(kauppakumppanin maakoodi osuus)
            cannot be the same, except when they have the value 'QV'(Määrittelemätön alue)
            https://jira.tulli.csc.fi/browse/NAKKI-4536
          */
          if (values.memberState === countryCode && countryCode !== UNDEFINED_AREA_CODE_QV) {
            addValidationErrors(warnings, 'partnerId', validationMessages.invalidPartnerId)
          }
        }
        if (countryCode === 'GR') {
          addValidationErrors(warnings, 'partnerId', validationMessages.invalidPartnerId)
        }
        return
      }

      const isValid = validateSpecificCountry(countryCode, rest)

      if (!isValid) {
        addValidationErrors(warnings, 'partnerId', validationMessages.invalidPartnerId)
        return
      }

      if (countryFromList) {
        if (countryCode === UNDEFINED_AREA_CODE_QV) {
          if (!isFrom2021) {
            addValidationErrors(warnings, 'partnerId', validationMessages.invalidPartnerId)
          }
        } else if (values.memberState !== countryCode) {
          addValidationErrors(warnings, 'partnerId', validationMessages.parterIdMisMatch)
        }
      } else if (countryCode === 'QN') {
        if (values.memberState === 'FI' || rest !== '999999999999') {
          addValidationErrors(warnings, 'partnerId', validationMessages.invalidPartnerId)
        }
      } else if (countryCode !== 'EL' && countryCode !== 'XX') {
        addValidationErrors(warnings, 'partnerId', validationMessages.invalidPartnerId)
      }
    } else {
      addValidationErrors(warnings, 'partnerId', validationMessages.invalidPartnerId)
    }
  }
}

const validateModeOfTransport = (values, warnings) => {
  if (ITEM_GROUPS.FIXED_TRANSPORT_REQUIRED.includes(values.CN8Code)) {
    if (values.modeOfTransport !== MODES_OF_TRANSPORT.FIXED_TRANSPORT) {
      addValidationErrors(warnings, 'modeOfTransport', apiMessages['modeOfTransport.mustBe7'])
    }
  } else if (values.modeOfTransport === MODES_OF_TRANSPORT.FIXED_TRANSPORT) {
    if (!ITEM_GROUPS.FIXED_TRANSPORT_ALLOWED.includes(values.CN8Code)) {
      addValidationErrors(warnings, 'modeOfTransport', apiMessages['modeOfTransport.mustNotBe7'])
    }
  } else if (values.modeOfTransport === MODES_OF_TRANSPORT.OWN_PROPULSION) {
    if (!ITEM_GROUPS.OWN_PROPULSION_ALLOWED.includes(values.CN8Code)) {
      addValidationErrors(warnings, 'modeOfTransport', apiMessages['modeOfTransport.mustNotBe9'])
    }
  }
}

/**
 * Row content validation as described in backend handling rules (`käsittelysäännöt`).
 * Backend will allow storing data with these errors, but UI form should prevent submitting for usability reasons.
 *
 * If onlyStaticValidation is true, cn8Data related validation is not executed.
 */
export const validateRowContent = (values, {
  cn8Data,
  flowCode,
  getSimstatMode,
  onlyStaticValidation = false,
  selectOptions,
  referencePeriod,
  rowFieldValues,
  serverValues,
}) => {
  const serverErrors = rowFieldValues.warnings

  const simstatMode = getSimstatMode()
  const staticContentRules = {
    memberState: required,
    invoicedAmount: required,
    invoicedValueCurrencyCode: required,
    // Validation rule added for consistence, does not exist in backend handling rules (`käsittelysäännöt`).
    invoiceNumber: regEx(/^[A-Za-z0-9_-]*$/i, 'A-Z, a-z, 0-9, -, _'),
    CN8Code: [integer, minLength(8), maxLength(8)],
    partnerId: maxLength(14),
  }

  const warnings = createValidator(staticContentRules)(values)

  if (values.CN8Code !== SMALL_VALUE_CN8CODE) {
    addValidationErrors(warnings, 'modeOfTransport', required(values.modeOfTransport))
    addValidationErrors(warnings, 'natureOfTransactionCode', required(values.natureOfTransactionCode))
    if (flowCode === 'ARRIVAL_OF_GOODS' || simstatMode === 3) {
      addValidationErrors(warnings, 'countryOfOrigin', required(values.countryOfOrigin))
    }
    if (flowCode === 'DISPATCH_OF_GOODS' && simstatMode === 3) {
      addValidationErrors(warnings, 'partnerId', required(values.partnerId))
    }
  }

  if (values.modeOfTransport && values.CN8Code) {
    validateModeOfTransport(values, warnings)
  }

  if (values.statisticalValue) {
    addValidationErrors(warnings, 'statisticalValueCurrencyCode', required(values.statisticalValueCurrencyCode))
  }

  if (onlyStaticValidation) {
    return warnings
  }

  if (values.partnerId) {
    validatePartnerId(values, warnings, selectOptions, referencePeriod)
  }

  if (cn8Data && !CN8CODES_WITH_OPTIONAL_NET_MASS.includes(values.CN8Code)) {
    if (!cn8Data.quantityInSU) {
      addValidationErrors(warnings, 'netMass', required(values.netMass))
    } else {
      addValidationErrors(warnings, 'quantityInSU', required(values.quantityInSU))
    }
  }

  /**
   * If there are server errors:
   * 1. Check if they are in '_error' field, if so, check case by case correct error or swallow
   * 2. If they are exact field errors, trigger error if untouched
   * Latter else if validates combination error for code-21 without server error (nicer UX)
   */
  if (serverValues && serverErrors) {
    Object.entries(serverErrors).forEach(([key, value]) => {
      if (isErrorKey(key) && values?.id) {
        // we need to add handlers for case by case, otherwise swallow and let FormRow.js global warning render it.
        if (checkCode21CountriesAreSame(flowCode, values)) {
          addcountryOfOriginMustEqualMemberStateError(warnings)
        }

        // swallowing
      } else {
        // When field is touched it most likely is fixed so ignore them
        let isInitialValue = serverValues[key] === values[key]
        // NAKKI-4194
        // If case is partner id country code mismatch error, we must include member state
        // initial vs. edited into "initialValue" handling, because also changing the member state
        // can remove that error from partner id field. If member state edited, we clear the
        // backend-error. After that, form validation catches if wrong values are put and
        // when saving row, backend will re-validate it so everything should go right ..
        if (key === 'partnerId' && values.warnings?.partnerId?.id.includes('countryCodeMismatch')) {
          if (serverValues.memberState !== values.memberState) {
            delete values.warnings.partnerId
            isInitialValue = false
          }
        }

        if (isInitialValue) {
          addValidationErrors(warnings, key, value)
        }
      }
    })
  } else if (checkCode21CountriesAreSame(flowCode, values)) {
    addcountryOfOriginMustEqualMemberStateError(warnings)
  }

  return warnings
}

function isErrorKey(key) {
  return key === rowErrorField
}

function checkCode21CountriesAreSame(flowCode, values) {
  return flowCode === 'ARRIVAL_OF_GOODS' && values.natureOfTransactionCode === NATURE_OF_TRANSACTIONS.RETURN_GOODS && values.memberState !== values.countryOfOrigin
}

function addcountryOfOriginMustEqualMemberStateError(warnings) {
  addValidationErrors(warnings, 'countryOfOrigin', apiMessages['country.countryOfOriginMustEqualMemberState'])
}


/**
 * Most strict existence and formality validation required for row data storing.
 *
 * Successful validation result only means data can be sent to backend for storing, not that it is entirely valid.
 */
export const validateRowFormality = (values) => {
  const staticFormalityRules = {
    modeOfTransport: positiveIntegerOrEmpty,
    natureOfTransactionCode: integer,
    partnerId: maxLength(14),
    memberState: maxLength(2),
    countryOfOrigin: maxLength(2),
    CN8Code: [required, maxLength(8, { ignoreSpaces: true })],
    netMass: [nonNegativeInteger, max(1000000000)],
    quantityInSU: [positiveInteger, max(1000000000)],
    invoicedAmount: [nonNegativeInteger, max(1000000000)],
    invoicedValueCurrencyCode: maxLength(3),
    statisticalValue: [positiveIntegerOrEmpty, max(1000000000)],
    statisticalValueCurrencyCode: maxLength(3),
    invoiceNumber: maxLength(14),
  }
  return createValidator(staticFormalityRules)(values)
}

/**
 * Slacker version of the row validation for file import (fixes quantityInSU problem)
 */
export const validateImportRowFormality = (values) => {
  const staticFormalityRules = {
    modeOfTransport: positiveIntegerOrEmpty,
    natureOfTransactionCode: integer,
    partnerId: maxLength(14),
    memberState: maxLength(2),
    countryOfOrigin: maxLength(2),
    CN8Code: [required, maxLength(8, { ignoreSpaces: true })],
    netMass: [nonNegativeInteger, max(1000000000)],
    quantityInSU: [nonNegativeInteger, max(1000000000)],
    invoicedAmount: [nonNegativeInteger, max(1000000000)],
    invoicedValueCurrencyCode: maxLength(3),
    statisticalValue: [positiveIntegerOrEmpty, max(1000000000)],
    statisticalValueCurrencyCode: maxLength(3),
    invoiceNumber: maxLength(14),
  }
  return createValidator(staticFormalityRules)(values)
}

/**
 * Validator combining all row validation rules.
 *
 * If onlyFormalityValidation is true, only row formality required for storing in backend is validated.
 */
export const validateRow = (values, props) => {
  let formalityValidationErrors
  if (props.import) {
    formalityValidationErrors = validateImportRowFormality(values)
  } else {
    formalityValidationErrors = validateRowFormality(values)
  }

  if (props.onlyFormalityValidation) {
    return formalityValidationErrors
  }
  const contentValidationErrors = validateRowContent(values, props)
  return {
    ...formalityValidationErrors,
    ...contentValidationErrors,
  }
}

export const shouldValidateRowsView = ({
  nextProps,
  props,
  initialRender,
}) =>
  // Only validate if fields affecting 'noDeclarationRows' validation change.
  // Consider all falsy values equal.
  /* eslint-disable eqeqeq */
  initialRender ||
  props.newRow != nextProps.newRow ||
  props.noDeclarationRows != nextProps.noDeclarationRows ||
  props.rowCount != nextProps.rowCount
/* eslint-enable eqeqeq */


/**
 * Warn about incorrect nil declaration to make sure user can switch between steps and fix the issue.
 */
export const warnValidator = (data) => {
  const containsRows = data.rows && data.rows.length > 0
  if (containsRows && data.noDeclarationRows) {
    return { _warning: messages.errorNilDeclarationHasRows }
  }
  return {}
}
