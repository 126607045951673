import React from 'react'
import { isEmpty, isEqual, get } from 'lodash'
import { injectIntl, FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { Table } from 'react-bootstrap'
import cn from 'classnames'
import Icon from 'src/components/Icon'
import tableStyles from 'src/styles/_tables.scss'
import messages from '../messages'
import { generateRowsListData, generateCodesetData } from './helpers'


/**
 * Printer friendly rows listing rendering all rows to DOM.
 */
class PrintRowsList extends React.Component {
  constructor(props, context) {
    super(props, context)
    const { rows, rowErrors, codeset, locale } = props

    this.state = {
      list: generateRowsListData(rows, rowErrors),
      codeset: generateCodesetData(codeset, locale),
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const nextState = {}
    // Regenerate list data when row related data changes (and on first render)
    if (!this.state.list
      || !isEqual(nextProps.rows, this.props.rows)
      || !isEqual(nextProps.rowErrors, this.props.rowErrors)
    ) {
      nextState.list = generateRowsListData(nextProps.rows, nextProps.rowErrors)
    }
    if (!isEqual(nextProps.codeset, this.props.codeset)) {
      nextState.codeset = generateCodesetData(nextProps.codeset, nextState.locale)
    }

    this.setState(nextState)
  }

  /**
   * Sorted rows data is generated on each render (when underlying data changes)
   */
  render() {
    const {
      flowCode,
      cn8Codes,
      secondaryUnits,
      getSimstatMode,
      hasItemNumber,
    } = this.props

    const {
      list,
      codeset,
    } = this.state

    const simstatMode = getSimstatMode()

    const showCountryOfOriginColumn = (flowCode === 'ARRIVAL_OF_GOODS' || simstatMode !== 1)

    const rowsContainErrors = list &&
      list.findIndex(rowData => !isEmpty(rowData.errors) || !isEmpty(rowData.warnings)) !== -1

    let countriesOfOriginCodeset = 'countriesOfOrigin'
    if (simstatMode !== 1 && flowCode === 'DISPATCH_OF_GOODS') {
      countriesOfOriginCodeset = 'countriesOfOriginForSimstat'
    }

    return (
      <div className="panel-indent">
        <Table
          id="intrastatPrintRowsList"
          className={cn(tableStyles.rowPairTable, tableStyles.printTable)}
        >
          <colgroup>
            <col width="50" />
            <col />
            {showCountryOfOriginColumn && <col />}
            <col />
            <col />
            <col width="90" />
            <col width="115" />
            <col width="135" />
            <col />
            {rowsContainErrors && <col width="30" />}
          </colgroup>
          <thead>
            <tr>
              <th><FormattedMessage {...messages.rowIdLabel} /></th>
              <th>
                <FormattedMessage {...messages.CN8CodeInputLabel} />
                <br />
                {flowCode === 'ARRIVAL_OF_GOODS' && <FormattedMessage {...messages.arrivalMemberStateInputLabel} />}
                {flowCode === 'DISPATCH_OF_GOODS' && <FormattedMessage {...messages.dispatchMemberStateInputLabel} />}
              </th>
              {showCountryOfOriginColumn &&
                <th>
                  <FormattedMessage {...messages.CN8CodeInputLabel} />
                  <br />
                  <FormattedMessage {...messages.countryOfOriginInputLabel} />
                </th>
              }
              <th><FormattedMessage {...messages.natureOfTransactionCodeInputLabel} /></th>
              <th><FormattedMessage {...messages.modeOfTransportInputLabel} /></th>
              <th><FormattedMessage {...messages.netMassInputLabel} /></th>
              <th><FormattedMessage {...messages.quantityInSUInputLabel} /></th>
              <th><FormattedMessage {...messages.invoicedAmountInEurLabel} /></th>
              <th>
                <FormattedMessage {...messages.partnerIdInputLabel} />
                <br />
                <FormattedMessage {...messages.invoiceNumberInputLabel} />
              </th>
              {rowsContainErrors && <th />}
            </tr>
          </thead>
          {list.map((rowData) => {
            const cn8Item = get(cn8Codes, rowData.CN8Code, {})
            return (
              <tbody key={rowData.index}>
                <tr key={`${rowData.index}-1}`}>
                  <td key="index">
                    {hasItemNumber ? rowData.index : rowData.index + 1}
                  </td>
                  <td key="CN8Code" className="tulli-blue">
                    {rowData.CN8Code}
                  </td>
                  <td
                    colSpan={showCountryOfOriginColumn ? 6 : 5}
                    className={cn('tulli-blue', tableStyles.limitedWidthTableCell)}
                  >
                    {cn8Item && (cn8Item.selfExplanatoryText || cn8Item.description)}
                  </td>
                  <td key="partnerId" className={tableStyles.limitedWidthTableCell}>
                    {rowData.partnerId}
                  </td>
                  {rowsContainErrors && <td />}
                </tr>
                <tr key={`${rowData.index}-2}`}>
                  <td key="index" />
                  <td key="memberState" className={tableStyles.limitedWidthTableCell}>
                    {rowData.memberState} - {get(codeset, ['memberStates', rowData.memberState])}
                  </td>
                  {(flowCode === 'ARRIVAL_OF_GOODS' || simstatMode !== 1) &&
                    <td key="countryOfOrigin" className={tableStyles.limitedWidthTableCell}>
                      {rowData.countryOfOrigin} - {get(codeset, [countriesOfOriginCodeset, rowData.countryOfOrigin])}
                    </td>
                  }
                  <td key="natureOfTransactionCode" className={tableStyles.limitedWidthTableCell}>
                    {rowData.natureOfTransactionCode} - {
                      get(codeset, ['transactionTypes', rowData.natureOfTransactionCode])}
                  </td>
                  <td key="modeOfTransport" className={tableStyles.limitedWidthTableCell}>
                    {rowData.modeOfTransport} - {get(codeset, ['transportTypes', rowData.modeOfTransport])}
                  </td>
                  <td key="netMass" className={tableStyles.limitedWidthTableCell}>
                    {rowData.netMass} {rowData.netMass &&
                      (<FormattedMessage {...messages.abbreviationKilograms} />)
                    }
                  </td>
                  <td key="quantityInSU" className={tableStyles.limitedWidthTableCell}>
                    {rowData.quantityInSU} {rowData.quantityInSU && secondaryUnits[cn8Item.quantityInSU]}
                  </td>
                  <td key="invoicedAmountInEur" className={tableStyles.limitedWidthTableCell}>
                    {rowData.invoicedAmountInEur} {rowData.invoicedAmountInEur && <span>&euro;</span>}
                  </td>
                  <td key="invoiceNumber" className={tableStyles.limitedWidthTableCell}>
                    {rowData.invoiceNumber}
                  </td>
                  {rowsContainErrors &&
                    <td key="errors">
                      {!(isEmpty(rowData.errors) && isEmpty(rowData.warnings)) &&
                        <Icon
                          name="attention"
                          className="text-danger"
                        />
                      }
                    </td>
                  }
                </tr>
              </tbody>
            )
          })}
        </Table>
      </div>
    )
  }
}

const mapStateToProps = state => ({ locale: state.locale })

export default connect(mapStateToProps)(injectIntl(PrintRowsList))
