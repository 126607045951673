/**
 * @file Container component connecting presentational React component to Redux state
 * @see {@link http://redux.js.org/docs/basics/UsageWithReact.html}
 * @author Sami Tiilikainen
 */

import { connect } from 'react-redux'
import { reduxForm, getFormValues, SubmissionError } from 'redux-form'
import { withRouter } from 'react-router-dom'
import { injectIntl } from 'react-intl'
import { get } from 'lodash'
import { focusFirstElementByIdOrName } from 'src/utils'
import HeadersView from './components/HeadersView'
import { syncValidator as validate, warnValidator as warn, shouldValidate } from './validator'
import { stepChange, togglePreserveDraftOnExitModal, removeTdp } from '../../actions'
import { headersStepSubmit, importFromFile, ignoreInvalidTdp, toggleImportModal } from './actions'
import { selectPsi } from '../../../actions'
import {
  INTRASTAT_FORM_NAME,
  DECLARATION_STATUS_ACCEPTED,
  DECLARATION_STATUS_DEFACED,
  DECLARATION_STATUS_DELETED,
  HEADERS_ROUTE_PATH,
  ROWS_ROUTE_PATH,
  DECLARATION_HEADERS_FETCHED,
  DECLARATION_ROWS_FETCHED,
  SUBMITTING_HEADERS,
} from '../../constants'
import { INTRASTAT_CACHE_CUSTOMERS } from '../../../constants'
import { getSelectedAuthorization, getSelectedDelegateCompanyFromAuthentication } from '../../../../../utils/auth'
import { collectCmsMessages } from '../../../../../utils/index'

const onSubmitFail = (errors, dispatch, submitError) => {
  if (submitError instanceof SubmissionError) {
    // optionally do something
  } else if (errors) {
    const errorIds = Object.keys(errors)
    focusFirstElementByIdOrName(errorIds)
  }
}

const formConfig = {
  form: INTRASTAT_FORM_NAME,
  validate,
  warn,
  shouldValidate,
  destroyOnUnmount: false,
  onSubmitFail,
}

const getIntrastatFormValues = getFormValues(INTRASTAT_FORM_NAME)
const mapStateToProps = (state) => {
  const {
    id,
    status,
    flowCode,
    psi,
    tdp,
    tdpReportingUnit,
    rows,
    declarationId,
    noDeclarationRows,
    psiReportingUnit,
    previousDeclarationId,
    referencePeriod,
  } = getIntrastatFormValues(state)

  const {
    currentStep,
    rowSaveResult,
    rowSaveProgress,
    showImportModal,
    importing,
    importErrors,
    isNew,
    userAcceptsInvalidTdp,
  } = state.intrastat.declaration

  const {
    cachedCustomers,
  } = state.intrastat.common

  const fetchingCustomers = state.loading[INTRASTAT_CACHE_CUSTOMERS]
  const fetchingDeclaration = state.loading[DECLARATION_HEADERS_FETCHED]
  const fetchingDeclarationRows = state.loading[DECLARATION_ROWS_FETCHED]
  const submittingHeaders = state.loading[SUBMITTING_HEADERS]
  const cmsMessages = get(state, 'content.cmsMessages', {})

  return {
    auth: state.auth,
    locale: state.locale,
    cmsMessages,
    content: collectCmsMessages('/intrastat/headers', cmsMessages),
    accepted: status === DECLARATION_STATUS_ACCEPTED,
    invalidated: status === DECLARATION_STATUS_DEFACED,
    flowCode,
    id,
    psi,
    tdp,
    tdpReportingUnit,
    rows,
    declarationId,
    previousDeclarationId,
    noDeclarationRows,
    psiReportingUnit,
    referencePeriod,
    selectedAuthorizationObject: getSelectedAuthorization(),
    selectedDelegateCompanyObject: getSelectedDelegateCompanyFromAuthentication(),
    thisStep: HEADERS_ROUTE_PATH,
    nextStep: ROWS_ROUTE_PATH,
    prevStep: null,
    currentStep,
    fetchingCustomers,
    cachedCustomers,
    rowSaveResult,
    rowSaveProgress,
    showImportModal,
    fetchingDeclaration,
    fetchingDeclarationRows,
    importing,
    importErrors,
    isNew,
    deleted: status === DECLARATION_STATUS_DELETED,
    userAcceptsInvalidTdp,
    syncErr: state.form[INTRASTAT_FORM_NAME].syncErrors,
    submitErr: state.form[INTRASTAT_FORM_NAME].submitErrors,
    submittingHeaders,
  }
}

const mapActionCreators = {
  importFromFile,
  onSubmit: headersStepSubmit,
  onStepChange: stepChange,
  selectPsi,
  removeTdp,
  onIgnoreInvalidTdp: ignoreInvalidTdp,
  showPreserveDraftOnExitModalAction: () => togglePreserveDraftOnExitModal(true),
  onHideImportModal: () => toggleImportModal(false),
}

export default withRouter(
  connect(mapStateToProps, mapActionCreators)(
    reduxForm(formConfig)(
      injectIntl(
        HeadersView
      ))))
