import React from 'react'
import ReactMarkdown from 'react-markdown'
import classNames from 'classnames'
import { FormattedMessage, injectIntl } from 'react-intl'
import { isObject } from 'lodash'
import InteractiveElement from 'src/components/InteractiveElement'
import Icon from 'src/components/Icon'
import LinkRenderer from 'src/components/LinkRenderer'
import messages from './messages'

class PanelNavigation extends React.Component {
  constructor(props) {
    super(props)
    let showHelp = true
    if (typeof this.props.isHelpVisible === 'function') {
      showHelp = this.props.isHelpVisible()
    }
    this.state = {
      showHelp,
    }
    this.toggleHelp = this.toggleHelp.bind(this)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.helpText !== nextProps.helpText) {
      this.setState({ showHelp: true })
    }

    if (this.panelHeading &&
      this.props.title &&
      nextProps.title !== this.props.title &&
      nextProps.intl.locale === this.props.intl.locale) {
      this.panelHeading.focus()
    }
  }

  toggleHelp(event) {
    if (event) {
      event.preventDefault()
    }
    const showHelp = !this.state.showHelp
    this.setState({ showHelp })
    if (typeof this.props.setHelpVisible === 'function') {
      this.props.setHelpVisible(showHelp)
    }
    return false
  }

  render() {
    const { active, title, titleExtras, children, helpText, style, intl, className } = this.props

    let panelBody
    if (active) {
      panelBody = (<div className="panel-body panel-navigation" style={style} >{children}</div>)
    }
    const showOpenHelpLink = helpText && active && !this.state.showHelp
    const showHelp = helpText && active && this.state.showHelp
    const helpMessage = this.state.showHelp ? messages.close : messages.help
    return (
      <div
        id={this.props.id}
        className={classNames('panel', 'panel-primary', { active }, className)}
        style={style}
      >
        <div
          ref={(ref) => { this.panelHeading = ref }}
          tabIndex="-1"
          className="panel-heading"
          role="heading"
          id-qa-test={'btn-panelHeading'}
        >
          { this.props.arialabel &&
          <div id={`${this.props.id}-aria-label`}>
            {title && (isObject(title) ? <FormattedMessage {...title} /> : title)}
          </div>
          }
          { !this.props.arialabel && (
            title && (isObject(title) ? <FormattedMessage {...title} /> : title))
          }
          <span className="panel-heading-extras hidden-xs" aria-hidden>{titleExtras}</span>
        </div>
        {showHelp &&
          <div className="alert panel-help text-primary">
            <Icon name="info" lg />
            <span className="panel-help-text" tabIndex="0">
              {helpText &&
                <ReactMarkdown
                  source={helpText}
                  renderers={{ Link: LinkRenderer }}
                />
              }
            </span>
            <InteractiveElement
              type="a"
              className="panel-help-toggle"
              onClick={this.toggleHelp}
              id-qa-test={'btn-toggle-help'}
              aria-label={intl.formatMessage(messages.closeHelp)}
            >
              <Icon
                name={showOpenHelpLink ? 'help' : 'close'}
                md
              />
            </InteractiveElement>
          </div>
        }
        {showOpenHelpLink &&
          <InteractiveElement
            type="a"
            className="panel-help-toggle"
            onClick={this.toggleHelp}
            id-qa-test={'btn-toggle-help'}
          >
            <Icon
              name={showOpenHelpLink ? 'help' : 'close'}
              md
            /> <FormattedMessage {...helpMessage} />
          </InteractiveElement>
        }
        {panelBody}
      </div>
    )
  }
}

export default injectIntl(PanelNavigation)
