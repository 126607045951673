import React from 'react'

import ErrorBoundary from 'src/components/ErrorBoundary'
import Heading from 'src/layout/Heading'
import IndividualGuaranteeForm from './components/IndividualGuaranteeForm'
import { getMessage } from './utils'

export default function IGRoot(props) {
  const {
    cmsMessages = {},
  } = props

  return (
    <div>
      <Heading message={getMessage('heading', cmsMessages)} />
      <main className="container" id="main">
        <ErrorBoundary>
          <IndividualGuaranteeForm
            cmsMessages={cmsMessages}
            {...props}
          />
        </ErrorBoundary>
      </main>
    </div>
  )
}
