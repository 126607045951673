import React from 'react'
import moment from 'moment'
import { withRouter } from 'react-router-dom'
import { isEmpty, get, first } from 'lodash'
import Heading from 'src/layout/Heading'
import Loader from 'src/components/Loader'
import showNotification from 'src/utils/notifications'
import InfoArea from 'src/components/InfoArea'
import ReactMarkdown from 'react-markdown'

import PermitsFrontPageInfoBoxes from './PermitsFrontPageInfoBoxes'
import PermitsList from '../components/PermitsList'
import PermitSelector from '../../permit/containers/PermitSelectorContainer'
import listMessages from '../messages'
import messages from '../../messages'
import { userHasAdminOrHandlerAuthorizations } from '../utils'
import api from '../../api'
import ErrorBoundary from '../../../../components/ErrorBoundary'
import {
  PERMITS_ROUTE_PATH,
} from '../../constants'
import permitMessages from '../../permit/messages'
import { getURLParam } from '../../../../utils/index'
import { DECISION_TYPE_CODESET } from '../../../../components/codeset/constants'

function userIsAuthorizedToCreateNewPermitApplication() {
  return userHasAdminOrHandlerAuthorizations()
}

class PermitsFrontpage extends React.Component {
  constructor(props) {
    super(props)

    this.doRedirect = false
    this.state = {
      permitTypes: [],
      fetchingPermitTypes: false,
      fetchingPermitDecisions: false,
      fetchError: null,
      customerInformationUnavailable: false,
    }
  }

  UNSAFE_componentWillMount() {
    const { history } = this.props
    const redirect = getURLParam('redirect')
    if (redirect) {
      this.doRedirect = true
      history.replace(`/${PERMITS_ROUTE_PATH}/${redirect}`)
    }
  }

  componentDidMount() {
    const { applicant, representative, isPerson } = this.props

    if (!this.doRedirect) {
      this.fetchPermitTypes()
    }

    if (!isPerson && applicant.id) {
      this.props.fetchCustomerInformation(applicant.id)
    }

    if (!isPerson && representative.id) {
      this.props.fetchRepresentativeInformation(representative.id)
    }

    this.getDecisionTypes()
    this.handleServiceError(this.props)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { applicant, isPerson } = this.props
    const { applicant: nextApplicant } = nextProps

    if (!isPerson && applicant.id && applicant.id !== nextApplicant.id) {
      this.props.fetchCustomerInformation(nextApplicant.id)
    }
    if (get(this.props, 'customer.error') !== get(nextProps, 'customer.error')) {
      this.handleServiceError(nextProps)
    }
  }

  shouldComponentUpdate() {
    return !this.doRedirect
  }

  handleServiceError = (props) => {
    if (get(props, 'customer.error.code') === 'serviceUnavailable') {
      this.setState({ customerInformationUnavailable: true })
      showNotification({
        level: 'error',
        message: permitMessages.fetchCustomerError,
        modal: true,
        title: permitMessages.errorModalTitle,
      })
    }
  }

  fetchPermitTypes() {
    this.setState({
      fetchingPermitTypes: true,
      fetchError: null,
      permitTypes: [],
    })
    api.fetchPermitTypes()
      .then(permitTypes => this.setState({
        permitTypes,
      }))
      .catch(fetchError => this.setState({ fetchError }))
      .then(() => this.setState({ fetchingPermitTypes: false }))
  }

  async getDecisionTypes() {
    this.setState({
      fetchingPermitDecisions: true,
    })

    const date = moment().format('YYYY-MM-DD')

    try {
      const codesets = await this.props.fetchNonCachedCodesets([DECISION_TYPE_CODESET], date)

      this.setState({
        fetchingPermitDecisions: false,
        decisionTypes: get(first(codesets), `${DECISION_TYPE_CODESET}.[${date}]`, []),
      })

      return 
    } catch (error) {
      this.setState({
        fetchingPermitDecisions: false,
      })

      throw error 
    }
  }

  render() {
    const { permitTypes, decisionTypes, fetchError, fetchingPermitTypes, fetchingPermitDecisions, customerInformationUnavailable } = this.state
    const { customerHasEori, intl: { formatMessage }, isFetchingCustomer, representative, isPerson } = this.props

    const userIsDelegateButMissingAuthorizationOrDecisionHolderIdentification = get(representative, 'id') && !get(representative, 'representativeIdentification')
    const canCreateApplication = (userIsAuthorizedToCreateNewPermitApplication() && customerHasEori && !userIsDelegateButMissingAuthorizationOrDecisionHolderIdentification) || isPerson

    let permitSelector

    if (canCreateApplication) {
      permitSelector = (<PermitSelector
        permitTypes={permitTypes}
        customerInformationUnavailable={customerInformationUnavailable}
      />)
    }

    const showMissingEori = !isPerson && ((!isFetchingCustomer && !customerInformationUnavailable && !customerHasEori) || userIsDelegateButMissingAuthorizationOrDecisionHolderIdentification)

    const showList = () => {
      if (isPerson) {
        return true
      }

      if (customerHasEori && !isFetchingCustomer && !customerInformationUnavailable && !userIsDelegateButMissingAuthorizationOrDecisionHolderIdentification) {
        return true
      }

      return false
    }

    const loading = fetchingPermitTypes && fetchingPermitDecisions

    return (
      <div>
        <Heading message={listMessages.title} />
        <main className="container" role="main" id="main">
          <ErrorBoundary forceError={!!fetchError || !!customerInformationUnavailable}>
            {loading &&
              <div style={{ position: 'relative', height: '70px' }}>
                <Loader blocking message={messages.loadingPermitTypes} />
              </div>
            }
            {!loading && !isEmpty(permitTypes) &&
              <div>
                <PermitsFrontPageInfoBoxes permitSelector={permitSelector} isPerson={isPerson} />
                <h2 className="sr-only">{formatMessage({ id: '/permits/headerBoxSrOnly' })}</h2>
                {showMissingEori &&
                  <InfoArea title={messages.missingEori}>
                    <ReactMarkdown
                      source={formatMessage({ id: '/permits/missingEORIText' })}
                    />
                  </InfoArea>
                }
                {showList() &&
                  <PermitsList permitTypes={permitTypes} decisionTypes={decisionTypes} isPerson={isPerson} />
                }
              </div>
            }
          </ErrorBoundary>
        </main>
      </div>
    )
  }
}

export default withRouter(PermitsFrontpage)
