import React from 'react'
import { ButtonToolbar, Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import ErrorBoundary from 'src/components/ErrorBoundary'
import Loader from 'src/components/Loader'
import Icon from 'src/components/Icon'
import Modal from 'src/components/Modal'
import logger from 'src/utils/logger'
import AccessibleMessage from 'src/components/AccessibleMessage'
import { find, findIndex, isEmpty, get, chunk, size, isNil } from 'lodash'
import commonMessages from '../../messages'
import RowsList from '../rows/components/RowsList/RowsList'
import PrintRowsList from '../rows/components/PrintRowsList'
import headerMessages from '../headers/messages'
import rowMessages from '../rows/messages'
import summaryMessages from './messages'
import confirmationMessages from '../confirmation/messages'
import {
  formatReferencePeriod,
  getStepPath,
  getReferencePeriodSelectOptions,
  calculateRowTotals,
} from '../../utils'
import { INTRASTAT_ROUTE_PATH } from '../../../constants'
import AmendmentModal from '../../components/AmendmentModal'
import CopyModal from '../../components/CopyModal'
import RowSummaryModal from './RowSummaryModal'
import DeclarationSummary from '../../components/DeclarationSummary'
import {
  DECLARATION_STATUS_ACCEPTED,
  SUMMARY_PRINT_ROUTE_PATH,
  DECLARATION_STATUS_DEFACED,
} from '../../constants'
import { focusElementById } from '../../../../../utils/index'


const messages = {
  ...commonMessages,
  ...headerMessages,
  ...rowMessages,
  ...confirmationMessages,
  ...summaryMessages,
}

const MAX_ROWS_FOR_CN8_FETCH = 50

export default class Summary extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showInvalidateModal: false,
      allCodesetsReady: false,
      fetchingCN8Codes: false,
      allCN8CodesReady: false,
      printModeCN8FetchingInitialized: false,
    }
    this.toggleInvalidateModal = this.toggleInvalidateModal.bind(this)
    this.setGetNextAndPrevRowFns = this.setGetNextAndPrevRowFns.bind(this)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const nextReferencePeriod = get(nextProps, 'declaration.referencePeriod', undefined)
    const currentReferencePeriod = get(this.props, 'declaration.referencePeriod', undefined)
    if (nextReferencePeriod && nextReferencePeriod !== currentReferencePeriod) {
      nextProps.fetchCodesets(nextReferencePeriod)
        .then(() => {
          this.setState({
            allCodesetsReady: true,
          })
        })
    }

    const nextModeIsPrint = this.isPrintMode(nextProps)
    const { printModeDataFetchingInitialized } = this.state
    const rowCountChanges = size(nextProps.rows) !== size(this.props.rows)
    const rowsReady = !isEmpty(nextProps.rows) && !nextProps.fetchingDeclarationRows
    if (nextModeIsPrint && rowsReady && (!printModeDataFetchingInitialized || rowCountChanges)) {
      // Fetch all cn8 data for rows when in print mode and rows have been fetched.
      // Only necessary when initializing print data for the first time or row count changes.
      this.fetchAllCN8CodesForRows(nextProps.rows)
    }
  }

  setGetNextAndPrevRowFns(getNextRowIdFn, getPrevRowIdFn) {
    this.getNextRowId = getNextRowIdFn
    this.getPrevRowId = getPrevRowIdFn
  }

  toggleInvalidateModal() {
    this.setState({
      showInvalidateModal: !this.state.showInvalidateModal,
    })
  }

  fetchAllCN8CodesForRows(rows) {
    const { fetchCN8Codes, declaration, cn8Codes } = this.props
    const { fetchingCN8Codes } = this.state

    if (isEmpty(rows) || fetchingCN8Codes) {
      return
    }

    this.setState({
      printModeCN8FetchingInitialized: true,
      fetchingCN8Codes: true,
    })

    const cn8CodesToFetch = Object.keys(
      rows.reduce((resultCodes, { CN8Code }) => {
        if (!resultCodes[CN8Code] && (isEmpty(cn8Codes) || !cn8Codes[CN8Code])) {
          resultCodes[CN8Code] = true // eslint-disable-line no-param-reassign
        }
        return resultCodes
      }, {})
    )

    const fetchPromises = chunk(cn8CodesToFetch, MAX_ROWS_FOR_CN8_FETCH)
      .map(cn8Chunk => fetchCN8Codes(cn8Chunk, declaration.referencePeriod))

    Promise.all(fetchPromises)
      .then(() => {
        this.setState({
          allCN8CodesReady: true,
          fetchingCN8Codes: false,
        })
      })
      .catch((error) => {
        logger.error('Could not fetch CN8 codes', error)
        this.setState({
          allCN8CodesReady: true,
          fetchingCN8Codes: false,
        })
      })
  }

  isPrintMode(props = this.props) {
    return get(props, 'match.params.viewMode') === 'print'
  }

  isReadyForPrinting() {
    const { declaration, fetchingDeclaration, fetchingDeclarationRows, rows, fetchingCustomers } = this.props
    if (!declaration || !declaration.id || fetchingDeclaration || fetchingDeclarationRows || fetchingCustomers) {
      return false
    }
    if (isEmpty(rows)) {
      return true
    }
    return this.state.allCodesetsReady && this.state.allCN8CodesReady
  }

  render() {
    const {
      locale,
      declaration,
      rows,
      cachedCustomers,
      fetchingDeclaration,
      fetchingCustomers,
      selectedDelegateCompanyObject,
      fetchingDeclarationRows,
      cn8Codes,
      cn8Locale,
      fetchCN8Codes,
      error,
      intl,
      history,
      codeset,
      secondaryUnits,
      getSimstatMode,
      invalidateDeclaration,
      invalidatingDeclaration,
      showAmendmentModal,
      creatingNewVersion,
      showCopyModal,
      creatingNewCopy,
      onShowAmendmentModal,
      onHideAmendmentModal,
      createAndOpenNewVersion,
      createAndOpenNewVersionWithoutTdp,
      onHideCopyModal,
      createAndOpenNewCopy,
      onShowCopyModal,
      selectedRow,
      selectRow,
      nilDeclaration,
      hasItemNumber,
    } = this.props

    if (fetchingDeclaration) {
      return <Loader blocking message={messages.fetchingDeclaration} />
    }
    if (fetchingCustomers) {
      return <Loader blocking message={messages.fetchingCustomer} />
    }

    const usePrintView = this.isPrintMode()
    const RowsListComponent = usePrintView ? PrintRowsList : RowsList
    const selectedRowIndex = selectedRow && findIndex(rows, { id: selectedRow })
    const selectedRowData = !fetchingDeclarationRows && selectedRowIndex >= 0 && rows[selectedRowIndex]
    const selectedPsiObject = find(cachedCustomers, { id: declaration.psi })
    const rowTotals = calculateRowTotals(rows)
    const loadingRowRelatedData = fetchingDeclarationRows || (usePrintView && !this.isReadyForPrinting())
    const declarationAccepted = declaration.status === DECLARATION_STATUS_ACCEPTED

    const findOrganization = id => find(cachedCustomers, { id })
    const selectedTdpObject = declaration.tdp && findOrganization(declaration.tdp)
    const showTdpRemoveNotification = declaration.status === DECLARATION_STATUS_DEFACED &&
      (declaration.nextDeclarationId || declaration.previousDeclarationId) &&
      selectedDelegateCompanyObject &&
      declaration.tdp && 
      !declaration.ownByTdp
    const tdpName = !selectedDelegateCompanyObject && !isNil(declaration.tdp) && selectedTdpObject && `${selectedTdpObject.name || ''} (${selectedTdpObject.id})`

    return (
      <ErrorBoundary>
        <ErrorBoundary>
          <DeclarationSummary
            {...declaration}
            intl={intl}
            totalNetMass={rowTotals.totalNetMass}
            totalStatisticalValue={rowTotals.totalStatisticalValue}
            totalInvoicedAmount={rowTotals.totalInvoicedAmount}
            cachedCustomers={cachedCustomers}
            rowCount={rows && rows.length}
            usePrintView={usePrintView}
            showTdpRemoveNotification={showTdpRemoveNotification}
          />
        </ErrorBoundary>

        {loadingRowRelatedData &&
          <Loader blocking message={messages.fetchingRows} />
        }

        {!loadingRowRelatedData && !isEmpty(rows) &&
          <ErrorBoundary>
            <RowsListComponent
              rows={rows}
              rowCount={rows && rows.length}
              flowCode={declaration.flowCode}
              referencePeriod={declaration.referencePeriod}
              intl={intl}
              codeset={codeset}
              cn8Codes={cn8Codes}
              cn8Locale={cn8Locale}
              secondaryUnits={secondaryUnits}
              fetchCN8Codes={fetchCN8Codes}
              fetchAllCN8Codes={this.fetchAllCN8Codes}
              getSimstatMode={getSimstatMode}
              selectRow={selectRow}
              selectedRow={selectedRow}
              setGetNextAndPrevRowFns={this.setGetNextAndPrevRowFns}
              setOnlyInvalidRowsVisibility={this.props.setOnlyInvalidRowsVisibility}
              showOnlyInvalidRows={this.props.showOnlyInvalidRows}
              hasItemNumber={hasItemNumber}
            />
          </ErrorBoundary>
        }
        {!usePrintView &&
          <ButtonToolbar className="panel-footer summaryButtons">
            <Link className="printDeclaration" to={getStepPath(SUMMARY_PRINT_ROUTE_PATH, declaration.declarationId)}>
              <Button id-qa-test="btn-printDeclaration" className="printDeclaration">
                <Icon name="printer" /><FormattedMessage {...messages.printView} />
              </Button>
            </Link>
            {!nilDeclaration && declarationAccepted &&
              <Button
                onClick={this.toggleInvalidateModal}
                className="pull-right"
                id="invalidateDeclaration"
                id-qa-test="btn-invalidateDeclaration"
              >
                <FormattedMessage {...messages.invalidate} />
              </Button>
            }
            {!nilDeclaration && declarationAccepted && !declaration.nextDeclarationId &&
              <Button
                onClick={onShowAmendmentModal}
                className="pull-right"
                id-qa-test="btn-declaration-amendment"
              >
                <Icon name="edit" /><FormattedMessage {...messages.correctButton} />
              </Button>
            }
            <Button
              onClick={onShowCopyModal}
              className="pull-right"
              id-qa-test="btn-copyDeclaration"
              id="copyDeclaration"
            >
              <Icon name="add" /><FormattedMessage {...messages.copyDeclarationButton} />
            </Button>
          </ButtonToolbar>
        }

        {this.state.showInvalidateModal && declarationAccepted &&
          <Modal
            show={declarationAccepted && this.state.showInvalidateModal}
            showCancel
            showContinue
            cancelMessage={messages.invalidateModalCancel}
            titleMessage={messages.invalidateModalTitle}
            continueMessage={messages.invalidateModalInvalidate}
            cancelDisabled={invalidatingDeclaration}
            continueDisabled={invalidatingDeclaration}
            onClickContinue={
              () => invalidateDeclaration(
                rows, declaration.declarationId,
                () => history.push(`/${INTRASTAT_ROUTE_PATH}`),
              )
            }
            onClickCancel={() => {
              this.toggleInvalidateModal()
              focusElementById('invalidateDeclaration')
            }}
            loading={invalidatingDeclaration}
            focusDisableButton
          >
            <p className="lead">
              <FormattedMessage
                {...messages.invalidateModalMessage}
                values={{
                  declarationId: declaration.declarationId,
                  referencePeriod: formatReferencePeriod(declaration.referencePeriod),
                }}
              />
            </p>
          </Modal>
        }
        {showAmendmentModal &&
          <AmendmentModal
            tdpName={tdpName}
            show={showAmendmentModal}
            loading={creatingNewVersion}
            onHide={onHideAmendmentModal}
            declarationId={declaration.declarationId}
            referencePeriod={declaration.referencePeriod}
            createAndOpenNewVersion={createAndOpenNewVersion}
            createAndOpenNewVersionWithoutTdp={createAndOpenNewVersionWithoutTdp}
          />
        }

        {showCopyModal &&
          <CopyModal
            show={showCopyModal}
            loading={creatingNewCopy}
            onHide={onHideCopyModal}
            declarationId={declaration.declarationId}
            getReferencePeriods={() => getReferencePeriodSelectOptions(
              declaration.flowCode, declaration.psiReportingUnit, selectedPsiObject, intl.formatMessage, locale
            )}
            createAndOpenNewCopy={createAndOpenNewCopy}
          />
        }

        {selectedRowData &&
          <RowSummaryModal
            rowData={selectedRowData}
            rowIndex={selectedRowIndex}
            onHide={() => selectRow(null)}
            codeset={codeset}
            cn8Codes={cn8Codes}
            fetchCN8Codes={code => fetchCN8Codes(code, declaration.referencePeriod)}
            flowCode={declaration.flowCode}
            getNextRowId={this.getNextRowId}
            getPrevRowId={this.getPrevRowId}
            selectRow={selectRow}
            referencePeriod={declaration.referencePeriod}
            hasItemNumber={hasItemNumber}
          />
        }

        {error && error.id &&
          <div className="text-danger text-right">
            <AccessibleMessage message={intl.formatMessage(error)} />
          </div>
        }
      </ErrorBoundary>
    )
  }
}
