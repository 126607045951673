
import React from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import StepContainer from './containers/StepContainer'
import PermitsChildReducer from './reducer'
import PermitPageContainer from './containers/PermitPageContainer'
import withReducer from '../../../reducers/withReducer'
import PageNotFound from 'src/components/PageNotFound'

const PermitsChildRoutes = () => {
  let { path } = useRouteMatch()

  return (
    <Switch>
      <Route exact path={`${path}/:permitId/:pathId`}>
        <PermitPageContainer>
          <StepContainer />
        </PermitPageContainer>
      </Route>
      <Route component={PageNotFound} />
    </Switch>
  )
}

export default withReducer([
  { key: 'permits.permit', reducer: PermitsChildReducer },
])(PermitsChildRoutes)
