import React from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'

import IntrastatRootContainer from './container'
import IntrastatDeclarationPage from './declaration/IntrastatDeclarationRoutes'
import { DECLARATION_ROUTE } from '../constants'
import withReducer from '../../reducers/withReducer'
import intrastatReducer from './reducer'
import PageNotFound from 'src/components/PageNotFound'
import requireAuthentication from '../../containers/requireAuthentication'
import { checkIntrastatAuthorization } from '../../utils/auth'

const IntrastatRoutes = () => {
  let { path } = useRouteMatch()
  return (
    <Switch>
      <Route exact path={path}>
        <IntrastatRootContainer />
      </Route>
      <Route path={`${path}${DECLARATION_ROUTE}`}>
        <IntrastatRootContainer>
          <IntrastatDeclarationPage />
        </IntrastatRootContainer>
      </Route>
      <Route component={PageNotFound} />
    </Switch>
  )
}

export default withReducer([
  { key: 'intrastat.common', reducer: intrastatReducer },
])(requireAuthentication(IntrastatRoutes, checkIntrastatAuthorization))