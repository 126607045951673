import React from 'react'

import ErrorBoundary from 'src/components/ErrorBoundary'
import Heading from 'src/layout/Heading'
import { getMessage } from '../utils'

function PaymentRoot(props) {
  const {
    children,
    cmsMessages = {},
  } = props

  return (
    <div>
      <Heading message={getMessage('heading', cmsMessages)} />
      <main className="container" role="main" id="main">
        <ErrorBoundary>
          {children}
        </ErrorBoundary>
      </main>
    </div>
  )
}

export default PaymentRoot
