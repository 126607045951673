import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { injectIntl } from 'react-intl'
import { get } from 'lodash'
import DeclarationsList from '../components/DeclarationsList'
import { fetchInitialDeclarations, destroyCacheState, activateDeclarationRow } from '../actions'
import { INITIAL_DECLARATIONS_RECEIVED } from '../constants'
import { getSelectedAuthorization } from '../../../../utils/auth'

const mapStateToProps = (state) => {
  const {
    statusFilter,
    declarations,
    totalDeclarationsCount,
    fetchError,
    fetchMoreError,
    activeRowId,
  } = state.intrastat.list

  const initialFetchingDeclarations = state.loading[INITIAL_DECLARATIONS_RECEIVED]

  return {
    companies: state.auth.authorizations,
    selectedOrganizationId: get(getSelectedAuthorization(), 'id'),
    locale: state.locale,
    initialFetchingDeclarations,
    statusFilter,
    declarations,
    declarationsCount: (declarations && declarations.length) || 0,
    totalDeclarationsCount,
    fetchError,
    fetchMoreError,
    activeRowId,
  }
}

const mapActionCreators = {
  fetchDeclarations: fetchInitialDeclarations,
  destroyCacheState,
  activateDeclarationRow,
}

export default withRouter(
  connect(mapStateToProps, mapActionCreators)(
    injectIntl(DeclarationsList)
  )
)
