import { handleActions } from 'redux-actions'
import { FORMS_DESTROY_STATE, FORMS_SAVE_DATA } from './constants'

const initialState = {}

export default handleActions({
  [FORMS_DESTROY_STATE]: () => initialState,
  [FORMS_SAVE_DATA]: (state, action) => ({
    ...state,
    ...action.payload,
  }),
}, initialState)

