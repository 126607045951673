import { get } from 'lodash'
import {
  IGNORE_INVALID_TDP,
  DECLARATION_HEADERS_FETCHED,
  DECLARATION_HEADERS_SAVED,
  TOGGLE_IMPORT_MODAL,
  IMPORT_STARTED,
  IMPORT_DONE,
} from '../../constants'

export default {
  [DECLARATION_HEADERS_SAVED]: state => ({
    ...state,
    lastSave: Date.now(),
  }),
  [DECLARATION_HEADERS_FETCHED]: {
    next: state => ({
      ...state,
      errorFetchingDeclaration: false,
    }),
    throw: (state, action) => ({
      ...state,
      errorFetchingDeclaration: get(action, 'meta.message') || true,
    }),
  },
  [IGNORE_INVALID_TDP]: state => ({
    ...state,
    userAcceptsInvalidTdp: true,
  }),
  [TOGGLE_IMPORT_MODAL]: (state, { payload }) => ({
    ...state,
    showImportModal: typeof payload === 'boolean' ? payload : !state.showImportModal,
  }),
  [IMPORT_STARTED]: state => ({
    ...state,
    importing: true,
    showImportModal: true,
    importErrors: undefined,
  }),
  [IMPORT_DONE]: {
    next: (state, action) => ({
      ...state,
      importing: false,
      importErrors: undefined,
      showImportModal: action.payload.warningCount > 0,
    }),
    throw: (state, action) => ({
      ...state,
      importing: false,
      importErrors: get(action, 'meta.errors') || undefined,
    }),
  },
}
