import { last, get } from 'lodash'
import { handleActions } from 'redux-actions'
import {
  STATUS_FILTER_CHANGE,
  INITIAL_DECLARATIONS_RECEIVED,
  MORE_DECLARATIONS_RECEIVED,
  DESTROY_DECLARATIONS_LIST_CACHE,
  ACTIVATE_DECLARATION_IN_LIST,
} from './constants'
import { INTRASTAT_DESTROY_STATE } from '../constants'

const initialState = {
  statusFilter: null,
  declarations: [],
  totalDeclarationsCount: 0,
  lastDeclarationId: 0,
  fetchError: false,
  fetchMoreError: false,
  activeRowId: null,
}

export default handleActions({
  [STATUS_FILTER_CHANGE]: (state, { payload }) => ({
    ...state,
    statusFilter: payload,
    declarations: initialState.declarations,
    fetchError: false,
    fetchMoreError: false,
  }),
  [INITIAL_DECLARATIONS_RECEIVED]: {
    next: (state, { payload, meta }) => {
      if (meta.filterByStatus !== state.statusFilter) {
        return state
      }

      return {
        ...state,
        declarations: payload.declarations || [],
        totalDeclarationsCount: payload.totalCount || 0,
        lastDeclarationId: get(last(payload.declarations), 'id'),
        fetchError: false,
      }
    },
    throw: (state, { payload, meta }) => {
      if (meta.filterByStatus !== state.statusFilter) {
        return state
      }
      return {
        ...state,
        fetchError: payload,
      }
    },
  },
  [MORE_DECLARATIONS_RECEIVED]: {
    next: (state, { payload, meta }) => {
      if (meta.filterByStatus !== state.statusFilter) {
        return state
      }
      return {
        ...state,
        fetchMoreError: false,
        declarations: [
          ...state.declarations,
          ...payload.declarations,
        ],
        lastDeclarationId: get(last(payload.declarations), 'id'),
      }
    },
    throw: (state, { payload, meta }) => {
      if (meta.filterByStatus !== state.statusFilter) {
        return state
      }
      return {
        ...state,
        fetchMoreError: payload,
      }
    },
  },
  [DESTROY_DECLARATIONS_LIST_CACHE]: state => ({
    ...state,
    declarations: initialState.declarations,
    totalDeclarationsCount: initialState.totalDeclarationsCount,
    lastDeclarationId: initialState.lastDeclarationId,
  }),
  [INTRASTAT_DESTROY_STATE]: () => ({
    ...initialState,
  }),
  [ACTIVATE_DECLARATION_IN_LIST]: (state, action) => ({
    ...state,
    activeRowId: get(action, 'payload.id') || null,
  }),
}, initialState)
