import {
  initialize,
  stopSubmit,
  SubmissionError,
} from 'redux-form'
import {
  omitBy,
  isNil,
  isEqual,
} from 'lodash'
import { createDefaultAction } from 'src/utils/redux'
import { showGlobalNotification } from 'src/actions'
import logger from 'src/utils/logger'
import { apiCall } from 'src/utils/http'
import { getFieldErrors } from 'src/utils/validation'
import {
  INTRASTAT_FORM_NAME,
  DECLARATION_STATUS_ACCEPTED,
  DECLARATION_STATUS_CHANGED,
} from '../../constants'
import apiMessages from '../../apiMessages'
import { handleServerErrors } from '../../actions'
import { fetchDeclarationRows } from '../rows/actions'

/**
 * Everything should be up to date when entering confirmation, so leaving from
 * confirmation requires no server requests.
 *
 * If user sends a status change (send to customs), submitAcceptedStatus action
 * is used instead of this function.
 */
// eslint-disable-next-line no-unused-vars
export const submitConfirmation = formData => dispatch => Promise.resolve()

export const declarationStatusChange = createDefaultAction(DECLARATION_STATUS_CHANGED)

function submitStatus(dispatch, getState, formData, status, skipDuplicateCheck = false) {
  const { rows, ...declaration } = formData // eslint-disable-line no-unused-vars
  const base = getState().config.bootstrapConfig.tm_intrastat_ext_url
  const apiUrl = `${base}/declarations/${declaration.declarationId}?skipDuplicateCheck=${Boolean(skipDuplicateCheck)}`
  const callParams = {
    method: 'PATCH',
    body: JSON.stringify({
      ...omitBy(declaration, isNil),
      status,
    }),
  }

  return apiCall(apiUrl, callParams, { }, dispatch, true)
    .catch(handleServerErrors.bind(this, dispatch))
    .then((response) => {
      const { errors, ...responseDeclaration } = response
      dispatch(initialize(
        INTRASTAT_FORM_NAME,
        { ...responseDeclaration, rows },
        { keepDirty: false, keepSubmitSucceeded: true }
      ))
      dispatch(declarationStatusChange(responseDeclaration))

      /* eslint-disable no-underscore-dangle */
      // In case of server-side warning messages, throw them as submission errors
      // Should not happen in status change, since unsuccessful status change should lead to `handleServerErrors`.
      // Anyhow it seems this does happen e.g. for totalNumberLines.
      if (errors) {
        const convertedErrors = getFieldErrors(response, apiMessages)
        if (!convertedErrors._error && convertedErrors.totalNumberLines) {
          // totalNumberLines is not shown in UI, add it to global errors
          convertedErrors._error = convertedErrors.totalNumberLines
        }
        // Must refresh rows when general errors occur, since rows may now have new errors
        // _error = error in parametrized server validation = error in rows
        if (convertedErrors._error) {
          fetchDeclarationRows(declaration.declarationId)(dispatch, getState)
            .then((fetchedRows) => {
              if (!isEqual(rows, fetchedRows)) {
                dispatch(initialize(
                  INTRASTAT_FORM_NAME,
                  {
                    ...responseDeclaration,
                    rows: fetchedRows,
                  },
                  {
                    keepDirty: false,
                    keepSubmitSucceeded: true,
                  }
                ))
              }
            })
        }
        throw new SubmissionError(convertedErrors)
      }
      return responseDeclaration.status
    })
    .catch((error) => {
      logger.error('Error in submitting Intrastat declaration confirmation', JSON.stringify(error))

      if (error && error.errors && error.errors._error) {
        const errorKey = error.errors._error.id
        let errorCode = null
        if (errorKey.startsWith('api.intrastat.exception.')) {
          errorCode = errorKey.slice(24)
        }
        const globalIntlMessage = apiMessages[errorCode]
        if (errorCode) {
          dispatch(showGlobalNotification({
            level: 'error',
            message: globalIntlMessage,
          }))
        }
      }
      throw error
    })
}

export const submitAcceptedStatus = formData =>
  (dispatch, getState) => submitStatus(dispatch, getState, formData, DECLARATION_STATUS_ACCEPTED, false)

export const forceSubmitAcceptedStatus = formData =>
  (dispatch, getState) => submitStatus(dispatch, getState, formData, DECLARATION_STATUS_ACCEPTED, true)

export const clearFormErrors = () => stopSubmit(INTRASTAT_FORM_NAME, {})
