import React from 'react'
import { connect } from 'react-redux'
import { Row, Col, Button, ButtonToolbar } from 'react-bootstrap'
import { FormattedMessage, injectIntl } from 'react-intl'
import moment from 'moment'
import { filter, has, findIndex, set, get, includes, isEmpty } from 'lodash'
import ReactMarkdown from 'react-markdown'
import showNotification from 'src/utils/notifications'
import Icon from 'src/components/Icon'
import Loader from 'src/components/Loader'
import Modal from 'src/components/Modal'
import { FEATURES } from 'src/constants'
import { getEnabledPermitTypes } from 'src/utils/features'
import api from '../../api'
import messages from '../messages'
import { storeListViewState } from '../../list/actions'
import {
  PERMITS_ROUTE_PATH,
  PERMIT_APPLICATION_ROUTE_PATH,
  PERMIT_STATUS_DRAFT,
  PERMIT_STATUS_APPROVED,
  PERMIT_STATUS_ACTIVE,
  PERMIT_STATUS_SUSPENDED,
  PERMIT_STATUS_ENDED,
  PERMIT_STATUS_ANNULLED,
  PERMIT_STATUS_REVOKED,
  PERMIT_APPLICATION_STATUS_FAVOURABLE,
  PERMIT_APPLICATION_STATUS_NON_FAVOURABLE,
} from '../../constants'
import { getApplicant, getRepresentative, mapPersonAuthorizationOrDecisionHolder, mapPersonRepresentativeDetails, replaceApplicationInfoElementData } from '../permitHelper'
import {
  INIT_ROUTE_PATH,
  PERMIT_TYPES_ALLOW_RENEW,
  APPLICATION_TYPE_AMENDMENT_ALIAS,
  APPLICATION_TYPE_RENEW_ALIAS,
  APPLICATION_TYPE_REVOCATION_ALIAS,
  APPLICATION_TYPE_REJOINDER_ALIAS,
  applicationTypeHelpTexts,
} from '../constants'
import { fetchDecisionFile, encodeReferenceNumber, handleApplicationExistsError } from '../utils'
import { isDateAfterToday, userHasAdminOrHandlerAuthorizations } from '../../list/utils'
import './PermitSummaryReadOnly.scss'
import { PERMIT_TAB_PERMITS } from '../../list/constants'
import InlineNotification from './InlineNotification'
import { checkPrivateAuthorization, isApplicantPerson } from '../../../../utils/auth'


function userIsAuthorizedToCreateAmendmentApplication() {
  if (checkPrivateAuthorization()) {
    return true
  }

  return userHasAdminOrHandlerAuthorizations()
}

class PermitSummaryReadOnly extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      isAppliedByPdf: false,
      applicationExistsModalTitle: '',
      applicationExistsModalText: '',
      applicationExistsModalContinue: '',
      showApplicationExistsModal: false,
    }
    this.fetchDecision = this.fetchDecision.bind(this)
    this.handleNewApplication = this.handleNewApplication.bind(this)
    this.closeAndOpenDecisionsTab = this.closeAndOpenDecisionsTab.bind(this)
  }

  getPermitTypeByCode(code) {
    const { permitData: { permitTypes }, locale } = this.props
    const permitType = filter(permitTypes, type => type.code === code)
    return permitType[0].name[locale]
  }

  getPermitTypeName() {
    const { permitData: { permit }, decisionTypes, locale } = this.props
    const decisionType = decisionTypes.find(type => permit.decisionType === type.code)

    return get(decisionType, `name[${locale}]`)
  }

  preventRenew = permitState =>
    [PERMIT_STATUS_SUSPENDED, PERMIT_STATUS_REVOKED, PERMIT_STATUS_ANNULLED].includes(permitState)

  formatStatus() {
    const { permitData: { permit: { state, decisionType }, rejoinder } } = this.props
    const isProposal = decisionType?.includes('PROPOSAL')
    switch (state) {
    case PERMIT_STATUS_DRAFT:
      return <FormattedMessage {...messages.labelPermitStatusDraft} />
    case PERMIT_STATUS_APPROVED:
      return <FormattedMessage {...messages.labelPermitStatusApproved} />
    case PERMIT_STATUS_ACTIVE:
      if (isProposal) {
        if (rejoinder?.state === PERMIT_APPLICATION_STATUS_FAVOURABLE || rejoinder?.state === PERMIT_APPLICATION_STATUS_NON_FAVOURABLE) {
          return <FormattedMessage {...messages.labelDecisionProposalStatusProcessed} /> // käsitelty
        }
        return <FormattedMessage {...messages.labelDecisionProposalStatusDeadlineFuture} /> // määräaikaa jäljellä
      }
      return <FormattedMessage {...messages.labelPermitStatusActive} />
    case PERMIT_STATUS_SUSPENDED:
      return <FormattedMessage {...messages.labelPermitStatusSuspended} />
    case PERMIT_STATUS_ENDED:
      if (isProposal) {
        return <FormattedMessage {...messages.labelDecisionProposalStatusDeadlinePast} /> // määräaika päättynyt
      }
      return <FormattedMessage {...messages.labelPermitStatusEnded} />
    case PERMIT_STATUS_ANNULLED:
      return <FormattedMessage {...messages.labelPermitStatusAnnulled} />
    case PERMIT_STATUS_REVOKED:
      return <FormattedMessage {...messages.labelPermitStatusRevoked} />
    default:
      return state
    }
  }

  fetchDecision() {
    const {
      permitData,
      intl: { formatMessage },
    } = this.props

    fetchDecisionFile({
      permitData: permitData.permit,
      formatMessage,
      setLoader: () => this.setState({ isLoading: true }),
      clearLoader: () => this.setState({ isLoading: false }),
    })
  }

  handleNewApplication = async (applicationType) => {
    const {
      isPerson,
      applicant,
      representative,
      permitData: { permit },
      permitTypesInUse,
      helpTexts,
    } = this.props

    const authorizationOrDecisionHolderIdentification = get(applicant, 'authorizationOrDecisionHolderIdentification')
    const representativeIdentification = get(representative, 'representativeIdentification')

    if (permit &&
       permit.authorisationTypeCode &&
       includes(permitTypesInUse, permit.authorisationTypeCode)
    ) {
      this.setState({ isLoading: true })

      const fetchLatestApplicationPromise = api.fetchLatestApplication(authorizationOrDecisionHolderIdentification)
      const fetchLatestApplicationDataPromise = fetchLatestApplicationPromise.then((permitApplication) => {
        if (!isEmpty(permitApplication)) {
          return api.fetchPermitApplication(permitApplication.id, permitApplication.version)
        }
        return {}
      })

      const encodedReferenceNumber = encodeReferenceNumber(permit.referenceNumber)
      let newApplicationPromise

      switch (applicationType) {
      case APPLICATION_TYPE_AMENDMENT_ALIAS:
        newApplicationPromise = api.amendDecision(encodedReferenceNumber, permit.version)
        break
      case APPLICATION_TYPE_REVOCATION_ALIAS:
        newApplicationPromise = api.revokeDecision(encodedReferenceNumber, permit.version)
        break
      case APPLICATION_TYPE_RENEW_ALIAS:
        newApplicationPromise = api.renewDecision(encodedReferenceNumber, permit.version)
        break
      case APPLICATION_TYPE_REJOINDER_ALIAS:
        newApplicationPromise = api.replyDecision(encodedReferenceNumber, permit.version)
        break
      default:
      }

      try {
        // eslint-disable-next-line max-len
        const [latestApplicationData, newApplicationData] = await Promise.all([fetchLatestApplicationDataPromise, newApplicationPromise])
        // eslint-disable-next-line max-len
        const applicationData = await replaceApplicationInfoElementData(newApplicationData, latestApplicationData, 'personInCharge')

        if (applicationData) {
          applicationData.groupValues.forEach((groupValue, groupValueIndex) => {
            groupValue.groupedValues.forEach((groupedValue, groupedValueIndex) => {
              const authorizationOrDecisionHolderIndex = findIndex(groupedValue.infoElements, {
                code: 'authorizationOrDecisionHolder',
              })

              if (authorizationOrDecisionHolderIndex >= 0) {
                if(isPerson) {
                  const applicantDetails = mapPersonAuthorizationOrDecisionHolder(applicant)

                  set(applicationData,
                    // eslint-disable-next-line max-len
                    `groupValues[${groupValueIndex}].groupedValues[${groupedValueIndex}].infoElements[${authorizationOrDecisionHolderIndex}]`,
                    {
                      code: 'authorizationOrDecisionHolder',
                      fieldValues: applicantDetails,
                    }
                  )
                } else {
                  set(applicationData,
                    // eslint-disable-next-line max-len
                    `groupValues[${groupValueIndex}].groupedValues[${groupedValueIndex}].infoElements[${authorizationOrDecisionHolderIndex}].fieldValues[0].value`,
                    authorizationOrDecisionHolderIdentification
                  )
                }
              }


              if(representativeIdentification) {
                const representativeIdentificationIndex = findIndex(groupedValue.infoElements, {
                  code: 'representativeDetails',
                })

                if (representativeIdentificationIndex >= 0) {
                  if(isPerson) {
                    const representativeDetails = mapPersonRepresentativeDetails(representative)

                    set(applicationData,
                      // eslint-disable-next-line max-len
                      `groupValues[${groupValueIndex}].groupedValues[${groupedValueIndex}].infoElements[${representativeIdentificationIndex}]`,
                      {
                        code: 'representativeDetails',
                        fieldValues: representativeDetails,
                      }
                    )
                  } else {
                    set(applicationData,
                    // eslint-disable-next-line max-len
                      `groupValues[${groupValueIndex}].groupedValues[${groupedValueIndex}].infoElements[${representativeIdentificationIndex}].fieldValues[0].value`,
                      representativeIdentification
                    )
                  }
                }
              }
            })
          })

          const response = await api.createDraft(applicationData)
            .then(createResponse =>
              api.saveDraft(applicationData, createResponse.referenceNumber, createResponse.version)
            )
          this.redirectToApplication(response.referenceNumber, response.version)
        } else {
          showNotification({
            level: 'warning',
            message: get(helpTexts.common, applicationTypeHelpTexts[applicationType].growlErrorMessage, ''),
            autoDismiss: true,
          })
        }
      } catch (e) {
        const applicationExistsError = handleApplicationExistsError(
          applicationType,
          e?.value,
          helpTexts.common,
          {
            error: true,
            errorDetails: {
              level: 'error',
              message: get(helpTexts.common, applicationTypeHelpTexts[applicationType].growlErrorMessage, ''),
              autoDismiss: true,
            },
          }
        )

        if (applicationExistsError.error && applicationExistsError.errorDetails) {
          showNotification(applicationExistsError.errorDetails)
        } else if (applicationExistsError.state) {
          this.setState(applicationExistsError.state)
        }
      }
    } else {
      this.setState({
        isAppliedByPdf: true,
      })
    }
    this.setState({ isLoading: false })

    return null
  }

  redirectToApplication = (referenceNumber, version) => {
    // eslint-disable-next-line max-len
    this.props.history.replace(`/${PERMITS_ROUTE_PATH}?redirect=${PERMIT_APPLICATION_ROUTE_PATH}/${referenceNumber}:${version}/${INIT_ROUTE_PATH}`)
  }

  redirectToExistingApplication = () => {
    const { existingApplicationReferenceNumber, existingApplicationVersion } = this.state
    if (existingApplicationReferenceNumber && existingApplicationVersion) {
      this.redirectToApplication(existingApplicationReferenceNumber, existingApplicationVersion)
    } else {
      // should not happen
    }
  }

  closeAndOpenDecisionsTab = () => {
    this.props.storeListViewState({
      ...this.props.permitsListInRedux,
      selectedTab: PERMIT_TAB_PERMITS,
    })
    this.props.history.push(`/${PERMITS_ROUTE_PATH}`)
    setTimeout(() => document.getElementById('permit-tab-permits-tab')?.focus(), 1500)
  }

  render() {
    const {
      permitData: { customerInformation, permit },
      intl: { formatMessage },
      locale,
      helpTexts,
      applicant,
      isPerson,
    } = this.props
    const {
      isLoading,
      isAppliedByPdf,
      showApplicationExistsModal,
      applicationExistsModalText,
      applicationExistsModalContinue,
      applicationExistsModalTitle,
      showContinueButton,
    } = this.state
    const status = get(permit, 'state')
    const isRevocation = permit.decisionType === 'REVOKE_DECISION'
    const isProposal = permit.decisionType?.includes('PROPOSAL')
    const rejoinderPossible = isProposal && isDateAfterToday(new Date(permit.endDate)) && (permit.state === PERMIT_STATUS_ACTIVE || permit.state === PERMIT_STATUS_APPROVED)

    let decisionReferenceNumber = permit.applicationDecisionReference?.attributes.referenceNumber
    if (isProposal && decisionReferenceNumber === undefined && permit.recordNumber) {
      decisionReferenceNumber = permit.recordNumber
    }

    let inlineNotification
    if (isProposal) {
      if (rejoinderPossible) {
        inlineNotification = <InlineNotification
          message={formatMessage(messages.decisionInfoAlertContent, { endDate: moment(permit.endDate).format('L') })}
          ariaLabel={formatMessage(messages.attention)}
          info
        />
      } else {
        inlineNotification = <InlineNotification
          message={formatMessage(messages.decisionWarningAlertContent)}
          ariaLabel={formatMessage(messages.attention)}
          onClick={this.closeAndOpenDecisionsTab}
          buttonText={formatMessage(messages.openDecisions)}
          warning
        />
      }
    }

    // If information from auth matches from the permit's applicant
    const applicantIsAuthorizedUser = permit.authorizationOrDecisionHolderIdentification === applicant.authorizationOrDecisionHolderIdentification

    return (
      <div>
        {isLoading && <Loader blocking />}
        <Modal
          show={isAppliedByPdf}
          showContinue={false}
          cancelMessage={messages.close}
          onClickCancel={() => this.setState({ isAppliedByPdf: false })}
          locale={locale}
          focusButton
          titleMessage={messages.attention}
        >
          <ReactMarkdown
            source={get(helpTexts, 'common.amendDecisionByPDF', '')}
            linkTarget="_blank"
          />
        </Modal>
        <Modal
          show={showApplicationExistsModal}
          titleMessage={applicationExistsModalTitle}
          continueMessage={applicationExistsModalContinue}
          cancelMessage={get(helpTexts, 'common.applicationExistsModalCancel')}
          onClickCancel={() => this.setState({
            showApplicationExistsModal: false,
          })}
          onClickContinue={this.redirectToExistingApplication}
          locale={locale}
          focusDisableButton
          showContinue={showContinueButton}
          focusButton
        >
          <ReactMarkdown
            source={applicationExistsModalText}
          />
        </Modal>
        {inlineNotification}
        <Row>
          <Col xs={12} md={4}>
            <b>
              {isProposal ?
                <FormattedMessage {...messages.decisionProposalOccupantTitle} />
                :
                <FormattedMessage {...messages.permitOccupantTitle} />
              }
            </b>
            <br />
            {isPerson && applicantIsAuthorizedUser && (<div>
              {applicant.name}<br />
              {applicant.address}<br />
              {applicant.zipCode} {applicant.city}<br />
              {applicant.countryCode}
            </div>)}
            {!isPerson && (<div>
              {customerInformation.fullName}<br />
              {customerInformation.addresses[0].address}<br />
              {customerInformation.addresses[0].zipCode} {customerInformation.addresses[0].city}<br />
              {customerInformation.addresses[0].countryCode}
            </div>)}
            <br /><br />
            {!isProposal &&
            <React.Fragment>
              <b><FormattedMessage {...messages.permitOccupantIdentificationTitle} /></b>
              <br />
              {permit.authorizationOrDecisionHolderIdentification}
              <br /><br />
            </React.Fragment>
            }
          </Col>
          <Col xs={12} md={4}>
            {isProposal ?
              <React.Fragment>
                <b><FormattedMessage {...messages.permitPermitTypeTitle} /></b>
                <br />
                {this.getPermitTypeName()}
                <br /><br />
                <b><FormattedMessage {...messages.permitPermitApplicationIdTitle} /></b>
                <br />
                {permit.recordNumber}
                <br /><br />
                <b><FormattedMessage {...messages.permitPermitReferenceNumberTitle} /></b>
                <br />
                {permit.referenceNumber}
                <br /><br />
              </React.Fragment>
              :
              <React.Fragment>

                <b><FormattedMessage {...messages.permitPermitNameTitle} /></b>
                <br />
                {this.getPermitTypeName()}
                <br /><br />
                <b><FormattedMessage {...messages.permitPermitDecisionDateTitle} /></b>
                <br />
                {moment(permit.decisionDate).format('L')}
                <br /><br />
                <b><FormattedMessage {...messages.permitPermitStartDateTitle} /></b>
                <br />
                {moment(permit.startDate).format('L')}
                <br /><br />
                <b><FormattedMessage {...messages.permitPermitDecisionReferenceNumberTitle} /></b>
                <br />
                {permit.referenceNumber}
                <br /><br />
              </React.Fragment>
            }
          </Col>
          <Col xs={12} md={4}>
            <b><FormattedMessage {...messages.permitPermitStatusTitle} /></b>
            <br />
            {has(permit, 'state') ? this.formatStatus() : '-'}
            <br /><br />
            {!isProposal &&
            <React.Fragment>
              <b><FormattedMessage {...messages.permitPermitVersionTitle} /></b>
              <br />
              {permit.version}
              <br /><br />
            </React.Fragment>
            }
            <b><FormattedMessage {...messages.permitPermitEndDateTitle} /></b>
            <br />
            {has(permit, 'endDate') ? moment(permit.endDate).format('L') : '-'}
            <br /><br />
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <ButtonToolbar>
              {permit.hasPdf && <Button
                bsStyle="default"
                className="pull-right"
                onClick={this.fetchDecision}
                id-qa-test="btn-openPermitPdf"
              >
                <Icon name="pdf" /><FormattedMessage {...messages.openPdf} />
              </Button>}
              {FEATURES.PERMIT_AMENDMENT && permit.hasPdf &&
              userIsAuthorizedToCreateAmendmentApplication() &&
              permit.authorisationTypeCode !== 'KUM' &&
              permit.authorisationTypeCode !== 'PAA' &&
                <Button
                  bsStyle="default"
                  className="pull-right"
                  onClick={() => this.handleNewApplication(APPLICATION_TYPE_AMENDMENT_ALIAS)}
                  id-qa-test="btn-amendDecision"
                  disabled={status !== PERMIT_STATUS_ACTIVE}
                >
                  <FormattedMessage {...messages.amendDecision} />
                </Button>
              }
              {/* Pdf tarkistuksella tarkistetaan että kyseessä ei ole komission lupa tai asrekista migroitu lupa */}
              {permit.hasPdf && userIsAuthorizedToCreateAmendmentApplication() &&
              PERMIT_TYPES_ALLOW_RENEW.includes(permit.authorisationTypeCode) &&
              <Button
                bsStyle="default"
                className="pull-right"
                onClick={() => this.handleNewApplication(APPLICATION_TYPE_RENEW_ALIAS)}
                id-qa-test="btn-renewDecision"
                disabled={this.preventRenew(status) || permit.version !== permit.latestVersion}
              >
                <FormattedMessage {...messages.renewDecision} />
              </Button>}
              {permit.hasPdf && userIsAuthorizedToCreateAmendmentApplication() &&
               this.props.allowRevokeDecision &&
               permit.authorisationTypeCode !== 'KUM' &&
               permit.authorisationTypeCode !== 'PAA' &&
               <Button
                 bsStyle="default"
                 className="pull-right"
                 onClick={() => this.handleNewApplication(APPLICATION_TYPE_REVOCATION_ALIAS)}
                 id-qa-test="btn-revokeDecision"
                 disabled={status !== PERMIT_STATUS_ACTIVE}
               >
                 <FormattedMessage {...messages.revokeDecision} />
               </Button>
              }
              {permit.hasPdf &&
               permit.authorisationTypeCode === 'PAA' &&
               rejoinderPossible &&
               <Button
                 bsStyle="default"
                 className="pull-right"
                 onClick={() => this.handleNewApplication(APPLICATION_TYPE_REJOINDER_ALIAS)}
                 id-qa-test="btn-replyDecision"
               >
                 <FormattedMessage {...messages.replyDecision} />
               </Button>
              }
            </ButtonToolbar>
          </Col>
        </Row>
        {(permit.authorisationTypeCode === 'KUM' || permit.authorisationTypeCode === 'PAA') && permit.applicationDecisionReference &&
        <div>
          <hr />
          <h2>
            {isProposal ?
              <FormattedMessage {...messages.permitDecisionReferenceTitle} />
              :
              <FormattedMessage {...messages.permitRevocationDecisionReferenceHeading} />
            }
          </h2>
          <Row>
            <Col xs={12} md={4}>
              <b>
                {isRevocation ?
                  <FormattedMessage {...messages.permitRevocationDecisionReferenceNameTitle} />
                  :
                  <FormattedMessage {...messages.permitDecisionPermitNameTitle} />
                }
              </b>
              <br />
              {this.getPermitTypeByCode(permit.applicationDecisionReference.attributes.authorizationTypeCode)} ({permit.applicationDecisionReference.attributes.authorizationTypeCode})
              <br /><br />
            </Col>
            <Col xs={12} md={8}>
              <b>
                {isRevocation ?
                  <FormattedMessage {...messages.permitRevocationDecisionReferenceNumberTitle} />
                  :
                  <FormattedMessage {...messages.permitApplicationIdOrDecisionReferenceNumberTitle} />
                }
              </b>
              <br />
              {decisionReferenceNumber}
              <br /><br />
            </Col>
          </Row>
        </div>
        }
      </div>
    )
  }
}

const mapStateToProps = state => ({
  applicant: getApplicant(),
  representative: getRepresentative(),
  isFetchingCustomer: get(state, 'customer.fetchingCustomer'),
  permitTypesInUse: getEnabledPermitTypes(get(state, 'config.features')),
  allowRevokeDecision: get(state, 'config.features.ALLOW_REVOKE_DECISION', false),
  isPerson: isApplicantPerson(),
})

const mapActionCreators = {
  storeListViewState,
}

export default connect(mapStateToProps, mapActionCreators)(injectIntl(PermitSummaryReadOnly))
