/**
 * @file React Intl translation messages for common components
 * @see {@link https://github.com/yahoo/react-intl}
 */

import { defineMessages } from 'react-intl'

export default defineMessages({
  close: {
    id: 'notifications.close',
    description: 'Close button text for notification text boxes',
    defaultMessage: 'Close',
  },
  closeHelp: {
    id: 'notifications.help.close',
    description: 'Text reader label for close help button',
    defaultMessage: 'Close help',
  },
  help: {
    id: 'notifications.help',
    description: 'Help button text for opening instruction text boxes',
    defaultMessage: 'Help',
  },
  mandatory: {
    id: 'form.mandatory',
    description: 'Title text for mandatory form field icon',
    defaultMessage: 'Mandatory',
  },
  formFieldOptional: {
    id: 'form.optional',
    description: 'Title text for optional form field',
    defaultMessage: 'Optional',
  },
  closeInfoDialogButton: {
    id: 'notifications.closeInfoDialogButton',
    description: 'Button for closing common help dialogs',
    defaultMessage: 'Close',
  },
  monthLabel: {
    id: 'form.datepicker.monthLabel',
    description: 'Label for month',
    defaultMessage: 'Month',
  },
  yearLabel: {
    id: 'form.datepicker.yearLabel',
    description: 'Label for year',
    defaultMessage: 'Year',
  },
  overThreeMonthsNotice: {
    id: 'intrastat.headers.referencePeriod.overThreeMonthsNotice',
    description: 'Notice for reference periods over three months old',
    defaultMessage: 'Attn. The chosen reference period is over three months old.',
  },
  dateHelpText: {
    id: 'form.datepicker.help',
    description: 'Date input help text',
    defaultMessage: 'Date format is dd.mm.yyyy',
  },
  dateHelpTextA11y: {
    id: 'form.datepicker.help.a11y',
    description: 'Date input help text in screen reader format',
    defaultMessage: 'Date format is dd dot mm dot yyyy',
  },
  dateDefaultPlaceholder: {
    id: 'form.datepicker.defaultPlaceholder',
    description: 'Default placeholder for the datepicker',
    defaultMessage: 'dd.mm.yyyy',
  },
  openCalendar: {
    id: 'form.datepicker.openCalendar',
    description: 'Label for open calendar',
    defaultMessage: 'Open calendar',
  },
  minutes: {
    id: 'sessionTimer.minutes',
    description: 'Label for minutes',
    defaultMessage: 'minutes',
  },
  idleTimeOutWarningHeader: {
    id: 'sessionTimer.header',
    description: 'Label for idle modal header',
    defaultMessage: 'Your session is about to expire',
  },
  idleTimeOutWarningInfo: {
    id: 'sessionTimer.idleInfo',
    description: 'Label for idle modal info',
    defaultMessage: 'Your session will expire in 5 minutes',
  },
  sessionTimeOutWarning: {
    id: 'sessionTimer.sessionWarning',
    description: 'Label for session modal warning',
    defaultMessage: 'Your session will expire in 15 minutes. You cannot extend your session anymore. ' +
      'Please log out and login again if needed.',
  },
  sessionTimeOutWarningHeader: {
    id: 'sessionTimer.sessionHeader',
    description: 'Label for session modal header',
    defaultMessage: 'Your session will expire in 15 minutes',
  },
  sessionTimeOutWarningInfo: {
    id: 'sessionTimer.sessionInfo',
    description: 'Label for session modal info',
    defaultMessage: 'Your session will expire in 15 minutes',
  },
  idleTimeOutWarningQuestion: {
    id: 'sessionTimer.question',
    description: 'Label for idle modal header',
    defaultMessage: 'Do you want to extend your session with 45 minutes',
  },
  logout: {
    id: 'sessionTimer.logout',
    description: 'Label for modal logout button',
    defaultMessage: 'Log out',
  },
  reLogin: {
    id: 'sessionTimer.reLogin',
    description: 'Label for modal relogin button',
    defaultMessage: 'Log in again',
  },
  extendSession: {
    id: 'sessionTimer.extendSession',
    description: 'Label for modal extend session button',
    defaultMessage: 'Extend the session',
  },
  dismissSessionInfo: {
    id: 'sessionTimer.dismissSessionInfo',
    description: 'Label for modal dismiss button',
    defaultMessage: 'Dismiss the message',
  },
  table: {
    id: 'table.label',
    description: 'Table aria text',
    defaultMessage: 'Table',
  },
  nextPage: {
    id: 'table.nextPage',
    description: 'Pagination next button screen reader text',
    defaultMessage: 'Next',
  },
  previousPage: {
    id: 'table.previousPage',
    description: 'Pagination previous button screen reader text',
    defaultMessage: 'Previous',
  },
  linkOpensInNewWindow: {
    id: 'link.opensInNewWindow',
    description: 'Opens in new window',
    defaultMessage: 'Opens in new window',
  },
  cancel: {
    id: 'modal.cancel',
    description: 'Modal cancel button text',
    defaultMessage: 'Cancel',
  },
})
