/* eslint-disable max-len */
import { defineMessages } from 'react-intl'

export default defineMessages({
  heading: {
    id: 'forms.customsInformation.heading',
    description: 'Heading of the section',
    defaultMessage: 'Customs Information Service for our customers',
  },
  headingPrivateCustomer: {
    id: 'forms.customsInformation.heading.privateCustomer',
    description: 'Heading of the section',
    defaultMessage: 'Information Service for private customers',
  },
  headingBusinessCustomer: {
    id: 'forms.customsInformation.heading.businessCustomer',
    description: 'Heading of the section',
    defaultMessage: 'Information Service for business customers',
  },
  headingPanelCustomsInformationService: {
    id: 'forms.customsInformation.panelheading.customsInformationService',
    description: 'Heading of the panel',
    defaultMessage: 'Customs Information Service',
  },
  radioSelectionTypePrivate: {
    id: 'forms.customsInformation.radioSelectionTypePrivate',
    description: 'Label of radio selection',
    defaultMessage: 'Information Service for private customers',
  },
  radioSelectionTypeBusiness: {
    id: 'forms.customsInformation.radioSelectionTypeBusiness',
    description: 'Label of radio selection',
    defaultMessage: 'Information Service for business customers',
  },
  customerTypeTextPrivate: {
    id: 'forms.customsInformation.customerTypeTextPrivate',
    description: 'Text',
    defaultMessage: 'Private customer',
  },
  customerTypeTextBusiness: {
    id: 'forms.customsInformation.customerTypeTextBusiness',
    description: 'Text',
    defaultMessage: 'Business customer',
  },
  customerTypeFieldLabel: {
    id: 'forms.customsInformation.customerTypeFieldLabel',
    description: 'Label of field',
    defaultMessage: 'Customer type',
  },
  nameFieldLabel: {
    id: 'forms.customsInformation.nameFieldLabel',
    description: 'Label of field',
    defaultMessage: 'Name',
  },
  emailFieldLabel: {
    id: 'forms.customsInformation.emailFieldLabel',
    description: 'Label of field',
    defaultMessage: 'Email',
  },
  phoneFieldLabel: {
    id: 'forms.customsInformation.phoneFieldLabel',
    description: 'Label of field',
    defaultMessage: 'Phone',
  },
  subjectFieldLabel: {
    id: 'forms.customsInformation.subjectFieldLabel',
    description: 'Label of field',
    defaultMessage: 'Subject',
  },
  questionFieldLabel: {
    id: 'forms.customsInformation.questionFieldLabel',
    description: 'Label of field',
    defaultMessage: 'Question',
  },
  instructionsFieldLabel: {
    id: 'forms.customsInformation.instructionsFieldLabel',
    description: 'Label of field',
    defaultMessage: 'Instructions',
  },
  submit: {
    id: 'forms.customsInformation.submit',
    description: 'Text of button',
    defaultMessage: 'Submit',
  },
  thankyou: {
    id: 'forms.customsInformation.summary.thankyou',
    description: 'Title of infoarea',
    defaultMessage: 'Thank you for your question.',
  },
  questionReceived: {
    id: 'forms.customsInformation.summary.questionReceived',
    description: 'Text of infoarea',
    defaultMessage: 'Thank you for your question. Your question ({code}) has been received. We aim to reply to your question within 3 working days.',
  },
  summaryTitle: {
    id: 'forms.customsInformation.summary.title',
    description: 'Title of panel',
    defaultMessage: 'Summary of the question sent',
  },
  return: {
    id: 'forms.customsInformation.summary.return',
    description: 'Text of button',
    defaultMessage: 'Return',
  },
  printing: {
    id: 'forms.customsInformation.summary.printing',
    description: 'Text of button',
    defaultMessage: 'Print',
  },
  print: {
    id: 'forms.customsInformation.summary.print',
    description: 'Text of button',
    defaultMessage: 'Print',
  },
  exit: {
    id: 'forms.customsInformation.summary.exit',
    description: 'Text of button',
    defaultMessage: 'Exit',
  },
  close: {
    id: 'forms.customsInformation.summary.close',
    description: 'Text of button',
    defaultMessage: 'Close',
  },
  errorSubmittingForm: {
    id: 'forms.customsInformation.errorSubmittingForm',
    description: 'Text of notification',
    defaultMessage: 'Your question was not sent successfully. Please try again later.',
  },
  phoneValidationError: {
    id: 'forms.customsInformation.phoneValidationError',
    description: 'Text of field validation error',
    defaultMessage: 'The format provided is incorrect.',
  },
  emailValidationError: {
    id: 'forms.customsInformation.emailValidationError',
    description: 'Text of field validation error',
    defaultMessage: 'The format provided is incorrect.',
  },
  questionValidationError: {
    id: 'forms.customsInformation.questionValidationError',
    description: 'Text of field validation error',
    defaultMessage: 'The text is too long. The maximum length of the text can be 2,000 characters.',
  },
  questionFieldPlaceholder: {
    id: 'forms.customsInformation.questionFieldPlaceholder',
    description: 'Placeholder of field',
    defaultMessage: 'Write your question here',
  },
})
