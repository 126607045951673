/* eslint-disable max-len */
import { defineMessages } from 'react-intl'

export default defineMessages({
  heading: {
    id: 'forms.eori.heading',
    description: 'Heading of the section',
    defaultMessage: 'Eori registration application',
  },
  headingPanelApplicantInformation: {
    id: 'forms.eori.headingPanel.applicantInformation',
    description: 'Heading of panel',
    defaultMessage: 'Applicant information',
  },
  headingPanelContactInformation: {
    id: 'forms.eori.headingPanel.contactInformation',
    description: 'Heading of panel',
    defaultMessage: 'Contact information',
  },
  headingPanelVATNumbers: {
    id: 'forms.eori.headingPanel.VATNumbers',
    description: 'Heading of panel',
    defaultMessage: 'VAT numbers admitted in other EU countries',
  },
  companyFullNameFieldLabel: {
    id: 'forms.eori.companyFullNameFieldLabel',
    description: 'Label of field',
    defaultMessage: 'Full name of company',
  },
  customerIdFieldLabel: {
    id: 'forms.eori.customerIdFieldLabel',
    description: 'Label of field',
    defaultMessage: 'Customer ID',
  },
  alvNumberFieldLabel: {
    id: 'forms.eori.alvNumberFieldLabel',
    description: 'Label of field',
    defaultMessage: 'VAT number',
  },
  vatNumberFieldLabel: {
    id: 'forms.eori.vatNumberFieldLabel',
    description: 'Label of field',
    defaultMessage: 'VAT number',
  },
  addressFieldLabel: {
    id: 'forms.eori.addressFieldLabel',
    description: 'Label of field',
    defaultMessage: 'Address',
  },
  zipCodeFieldLabel: {
    id: 'forms.eori.zipCodeFieldLabel',
    description: 'Label of field',
    defaultMessage: 'Zip code',
  },
  cityFieldLabel: {
    id: 'forms.eori.cityFieldLabel',
    description: 'Label of field',
    defaultMessage: 'City',
  },
  contactNameFieldLabel: {
    id: 'forms.eori.contactNameFieldLabel',
    description: 'Label of field',
    defaultMessage: 'EORI-contact name',
  },
  contactEmailFieldLabel: {
    id: 'forms.eori.contactEmailFieldLabel',
    description: 'Label of field',
    defaultMessage: 'EORI-contact email address',
  },
  countryCodeFieldLabel: {
    id: 'forms.eori.countryCodeFieldLabel',
    description: 'Label of field',
    defaultMessage: 'Country',
  },
  addNewBtnText: {
    id: 'forms.eori.addNewBtnText',
    description: 'Label of button',
    defaultMessage: 'Add new',
  },
  removeBtnText: {
    id: 'forms.eori.removeBtnText',
    description: 'Label of button',
    defaultMessage: 'Remove',
  },
  selectGroupPopularTitle: {
    id: 'forms.eori.selectGroupPopularTitle',
    description: 'Title for popular group in select',
    defaultMessage: 'Popular',
  },
  applicationReceived: {
    id: 'forms.eori.summary.applicationReceived',
    description: 'Text of infoarea',
    defaultMessage: 'EORI application has been sent successfully.',
  },
  summaryTitle: {
    id: 'forms.eori.summary.title',
    description: 'Title of panel',
    defaultMessage: 'Summary of sent information',
  },
  submit: {
    id: 'forms.eori.submit',
    description: 'Text of button',
    defaultMessage: 'Submit',
  },
  return: {
    id: 'forms.eori.summary.return',
    description: 'Text of button',
    defaultMessage: 'Return',
  },
  printing: {
    id: 'forms.eori.summary.printing',
    description: 'Text of button',
    defaultMessage: 'Print',
  },
  print: {
    id: 'forms.eori.summary.print',
    description: 'Text of button',
    defaultMessage: 'Print',
  },
  exit: {
    id: 'forms.eori.summary.exit',
    description: 'Text of button',
    defaultMessage: 'Exit',
  },
  close: {
    id: 'forms.eori.summary.close',
    description: 'Text of button',
    defaultMessage: 'Close',
  },
  errorSubmittingForm: {
    id: 'forms.eori.errorSubmittingForm',
    description: 'Text of notification',
    defaultMessage: 'Your application was not sent successfully. Please try again later.',
  },
  enterVatNumber: {
    id: 'forms.eori.enterVatNumberErrorMessage',
    description: 'Error message below field',
    defaultMessage: '?? Enter the VAT number',
  },
  enterCountry: {
    id: 'forms.eori.enterCountryErrorMessage',
    description: 'Error message below field',
    defaultMessage: '?? Enter the country from which the VAT number was issued',
  },
})
