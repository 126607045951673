/**
 * @file React Intl translation messages for Intrastat confirmation page
 * @see {@link https://github.com/yahoo/react-intl}
 * @author Sami Tiilikainen
 */

import { defineMessages } from 'react-intl'

export default defineMessages({
  confirmationHeading: {
    id: 'intrastat.confirmation.heading',
    description: 'Heading for intrastat confirmation',
    defaultMessage: 'Intrastat declaration',
  },
  submitForm: {
    id: 'intrastat.form.submit',
    description: 'Text in "submit form" button',
    defaultMessage: 'Submit',
  },
  saveDraft: {
    id: 'intrastat.form.saveDraft',
    description: 'Text in "save draft" form button',
    defaultMessage: 'Save draft',
  },
  deleteDraft: {
    id: 'intrastat.form.deleteDraft',
    description: 'Text in delete draft button',
    defaultMessage: 'Delete draft',
  },
  confirmation: {
    id: 'form.confirmation.description',
    description: 'Form confirmation page description',
    defaultMessage: 'This is confirmation page. Review your inputs and save.',
  },
  tdp: {
    id: 'intrastat.confirmation.tdp',
    description: 'Label for intrastat confirmation field TDP',
    defaultMessage: 'Third declaring party',
  },
  psi: {
    id: 'intrastat.confirmation.psi',
    description: 'Label for intrastat confirmation field PSI',
    defaultMessage: 'Provider of statistical information',
  },
  flowCodeAndReferencePeriod: {
    id: 'intrastat.confirmation.flowCodeAndReferencePeriod',
    description: 'Label for intrastat confirmation field combination flowCode and referencePeriod',
    defaultMessage: 'Flow code and reference period',
  },
  flowCodeArrival: {
    id: 'intrastat.confirmation.flowCodeArrival',
    description: 'Value for intrastat confirmation flowCode "arrival of goods"',
    defaultMessage: 'Arrival of goods',
  },
  flowCodeDispatch: {
    id: 'intrastat.confirmation.flowCodeDispatch',
    description: 'Value for intrastat confirmation flowCode "dispatch of goods"',
    defaultMessage: 'Dispatch of goods',
  },
  reference: {
    id: 'intrastat.confirmation.reference',
    description: 'Label for intrastat confirmation field reference',
    defaultMessage: 'Reference',
  },
  totalStatisticalValue: {
    id: 'intrastat.confirmation.totalStatisticalValue',
    description: 'Label for total statistical value of intrastat rows',
    defaultMessage: 'Statistical value',
  },
  btnSubmitting: {
    id: 'intrastat.confirmation.submitting',
    description: 'Submit button label for ongoing submission to customs',
    defaultMessage: 'Submitting...',
  },
  btnAccepted: {
    id: 'intrastat.confirmation.accepted',
    description: 'Submit button label after successfully accepted to customs',
    defaultMessage: 'Accepted',
  },
  sentToCustoms: {
    id: 'intrastat.confirmation.sentToCustoms',
    description: 'Message for successfull submission to customs (state accepted)',
    defaultMessage: 'Declaration was sent to customs and accepted',
  },
  acceptedModalTitle: {
    id: 'intrastat.confirmation.acceptedModalTitle',
    description: 'Title for modal message after declaration has been sent to customs',
    defaultMessage: 'Intrastat',
  },
  acceptedModalContinue: {
    id: 'intrastat.confirmation.acceptedModalContinue',
    description: 'Label for continue button in modal shown after declaration has been sent to customs',
    defaultMessage: 'Ok',
  },
  acceptedModalCancel: {
    id: 'intrastat.confirmation.acceptedModalCancel',
    description: 'Label for cancel button in modal shown after declaration has been sent to customs',
    defaultMessage: 'Cancel',
  },
  deleteModalTitle: {
    id: 'intrastat.confirmation.deleteModalTitle',
    description: 'Title for modal message in delete declaration confirmation modal',
    defaultMessage: 'Delete draft?',
  },
  deleteModalCancel: {
    id: 'intrastat.confirmation.deleteModalCancel',
    description: 'Label for cancel button in delete declaration confirmation modal',
    defaultMessage: 'Cancel',
  },
  deleteModalDelete: {
    id: 'intrastat.confirmation.deleteModalDelete',
    description: 'Label for delete button in delete declaration confirmation modal',
    defaultMessage: 'Delete',
  },
  deleteModalMessage: {
    id: 'intrastat.confirmation.deleteDeclaration',
    description: 'Message for delete declaration confirmation modal',
    defaultMessage: 'Are you sure you want to delete this draft?',
  },
  duplicateWarning: {
    id: 'intrastat.confirmation.duplicateWarning',
    description: 'Message for duplicate declaration dialog',
    defaultMessage: 'Similar declaration exists. Do you really want to proceed sending this declaration?',
  },
  duplicateWarningModalTitle: {
    id: 'intrastat.confirmation.duplicateWarningModalTitle',
    description: 'Title for duplicate declaration warning dialog',
    defaultMessage: 'Intrastat',
  },
  duplicateWarningModalContinue: {
    id: 'intrastat.confirmation.duplicateWarningModalContinue',
    description: 'Label for continue button in duplicate declaration warning dialog',
    defaultMessage: 'Ok',
  },
  duplicateWarningModalCancel: {
    id: 'intrastat.confirmation.duplicateWarningModalCancel',
    description: 'Label for cancel button in duplicate declaration warning',
    defaultMessage: 'Cancel',
  },
  correctButton: {
    id: 'intrastat.confirmation.correctButton',
    description: 'Label for declaration correction button',
    defaultMessage: 'Correct',
  },
  copyDeclarationButton: {
    id: 'intrastat.confirmation.copyDeclarationButton',
    description: 'Label for declaration copy button',
    defaultMessage: 'Copy',
  },
  sendSuccessModalButton: {
    id: 'intrastat.confirmation.sendSuccessModalButton',
    description: 'Label for send success button',
    defaultMessage: 'Ok',
  },
})
