import { get, sortBy, find, uniq } from 'lodash'
import { createDefaultAction } from 'src/utils/redux'
import logger from 'src/utils/logger'
import { apiCall } from 'src/utils/http'
import { change } from 'redux-form'
import { showGlobalNotification, loadingAction } from 'src/actions'
import { referencePeriodToDate } from './utils'
import messages from './messages'
import {
  INTRASTAT_DESTROY_STATE,
  INTRASTAT_CACHE_CODESET,
  INTRASTAT_CODESET_REQUEST,
  INTRASTAT_CACHE_CUSTOMERS,

} from './constants'
import { INTRASTAT_FORM_NAME } from './declaration/constants'

export const destroyIntrastatState = createDefaultAction(INTRASTAT_DESTROY_STATE)

const codesetRequest = createDefaultAction(INTRASTAT_CODESET_REQUEST)

export const cacheCodeset = createDefaultAction(INTRASTAT_CACHE_CODESET)

export const fetchCodeset = (name, referencePeriod) =>
  (dispatch, getState) => {
    dispatch(codesetRequest({ name, referencePeriod }))
    const { locale, config: { bootstrapConfig } } = getState()
    const codesetDate = referencePeriodToDate(referencePeriod)
    const codesetPaths = {
      transactionTypes: 'intrastat/transactionTypes',
      countries: 'intrastat/countries',
      countriesOfOrigin: 'intrastat/countriesOfOrigin',
      countriesOfOriginForSimstat: 'intrastat/countriesOfOriginForSimstat',
      memberStates: 'intrastat/memberStates',
      currencies: 'intrastat/currencies',
      transportTypes: 'transportTypes',
      flowtypes: 'flowtypes',
      secondaryUnits: 'secondaryUnits',
    }
    const codesetSortKeys = {
      transactionTypes: 'code',
      countries: 'name',
      countriesOfOrigin: 'name',
      countriesOfOriginForSimstat: 'name',
      memberStates: 'name',
      currencies: 'code',
      transportTypes: 'code',
      flowtypes: 'code',
      secondaryUnits: 'code',
    }
    const nameToParamsMap = {
      transactionTypes: {
        name: 'Kauppatapahtumanluonne(instat)',
        subset: '',
      },
      countries: {
        name: 'Maa',
        subset: '',
      },
      countriesOfOrigin: {
        name: 'Maa',
        subset: 'Alkuperamaa(instat)',
      },
      countriesOfOriginForSimstat: {
        name: 'Maa',
        subset: 'Alkuperämaa(Simstatvienti)',
      },
      memberStates: {
        name: 'Maa',
        subset: 'Määrämaa(instat)',
      },
      currencies: {
        name: 'Valuuttakurssi(itu)',
        subset: '',
      },
      transportTypes: {
        name: 'Kuljetusmuoto',
        subset: '',
      },
      flowtypes: {
        name: 'Suunta',
        subset: '',
      },
      secondaryUnits: {
        name: 'Paljousyksikkö',
        subset: '',
      },
    }

    if (!codesetPaths[name]) {
      throw new Error(`Unknown codeset ${name}`)
    }
    const codesetName = nameToParamsMap[name].name
    const subsetName = nameToParamsMap[name].subset
    let url = `${bootstrapConfig.tm_codeset_ext_url}/codeset/?codeset=${codesetName}&date=${codesetDate}`
    if (subsetName) {
      url += `&subset=${subsetName}`
    }

    return apiCall(
      url,
      { method: 'GET', cache: 'default' },
      null,
      dispatch,
      false
    )
      .then((data) => {
        let formattedData
        switch (name) {
        case 'memberStates':
        case 'countriesOfOrigin':
        case 'countriesOfOriginForSimstat':
        case 'countries':
          formattedData = data.map(codesetItem => codesetItem.basicAttributes.map(basicAttribute => ({
            code: codesetItem.code,
            name: basicAttribute.name,
            description: basicAttribute.description,
            startDate: codesetItem.startDate,
            endDate: codesetItem.endDate,
            locale: basicAttribute.languageCode,
          })))
          break
        case 'transactionTypes':
          formattedData = data.map(codesetItem => codesetItem.basicAttributes.map(basicAttribute => ({
            code: codesetItem.code,
            name: basicAttribute.name,
            description: basicAttribute.description,
            startDate: codesetItem.startDate,
            endDate: codesetItem.endDate,
            locale: basicAttribute.languageCode,
          })))
          break
        case 'currencies':
          formattedData = data.map((codesetItem) => {
            const basicAttributes = find(codesetItem.basicAttributes, i => i.languageCode === locale) || {}
            const euroRate = find(codesetItem.extensionValues, i => i.extensionName === 'euroRate') || {}
            return {
              code: codesetItem.code,
              name: basicAttributes.name,
              euroRate: euroRate.value,
              startDate: codesetItem.startDate,
              endDate: codesetItem.endDate,
            }
          })
          break
        case 'transportTypes':
          formattedData = data.map(codesetItem => codesetItem.basicAttributes.map(basicAttribute => ({
            code: codesetItem.code,
            name: basicAttribute.name,
            description: basicAttribute.description,
            startDate: codesetItem.startDate,
            endDate: codesetItem.endDate,
            locale: basicAttribute.languageCode,
          })))
          break
        case 'flowtypes':
          formattedData = data.map((codesetItem) => {
            const basicAttributes = find(codesetItem.basicAttributes, i => i.languageCode === locale) || {}
            return {
              code: codesetItem.code,
              name: basicAttributes.name,
              abbreviation: basicAttributes.abbreviation,
              description: basicAttributes.description,
              startDate: codesetItem.startDate,
              endDate: codesetItem.endDate,
            }
          })
          break
        case 'secondaryUnits':
          formattedData = data.map((codesetItem) => {
            const basicAttributes = find(codesetItem.basicAttributes, i => i.languageCode === locale) || {}
            const abbr = find(codesetItem.extensionValues, i => i.extensionName === 'officialAbbreviation') || {}
            const shortName = find(codesetItem.extensionValues, i => i.extensionName === 'shortName') || {}
            return {
              code: codesetItem.code,
              name: basicAttributes.name,
              shortName: shortName.value,
              abbreviation: basicAttributes.abbreviation,
              officialAbbreviation: abbr.value,
              description: basicAttributes.description,
              startDate: codesetItem.startDate,
              endDate: codesetItem.startDate,
            }
          })
          break
        default:
          break
        }
        const sortedData = sortBy(formattedData, codesetSortKeys[name] || 'code')
        dispatch(cacheCodeset(sortedData, { name, referencePeriod }))
      })
      .catch((error) => {
        logger.error(`Error in fetching codeset ${name} for ${referencePeriod}`, error)
        dispatch(cacheCodeset(error, { name, referencePeriod }))
      })
  }

export const fetchNonCachedCodesets = (codesetNames, referencePeriod) =>
  (dispatch, getState) => {
    const state = getState()
    const fetchingCodesets = get(state, `intrastat.common.fetchingCodesets[${referencePeriod}]`)
    const cachedCodesets = get(state, `intrastat.common.cachedCodesets[${referencePeriod}]`)
    const shouldFetchCodeset = (codeset) => {
      if (!fetchingCodesets || !fetchingCodesets[codeset]) {
        if (!cachedCodesets || !cachedCodesets[codeset]) {
          return true
        }
      }
      return false
    }
    const fetchPromises = codesetNames
      .filter(shouldFetchCodeset)
      .map(name => fetchCodeset(name, referencePeriod)(dispatch, getState))

    return Promise.all([...fetchPromises])
  }

export const cacheCustomers = createDefaultAction(INTRASTAT_CACHE_CUSTOMERS)

export const fetchCustomers = customerIds =>
  (dispatch, getState) => {
    dispatch(loadingAction({ key: INTRASTAT_CACHE_CUSTOMERS, value: true }))

    const {
      auth: { authorizations },
      config: { bootstrapConfig },
    } = getState()

    const customerReqPromises = customerIds.map(id =>
      apiCall(
        `${bootstrapConfig.tm_intrastat_ext_url}/intrastat/customer/${id}`,
        { method: 'GET', cache: 'default' },
        null,
        dispatch,
        false
      )
    )
    return Promise
      .all(customerReqPromises)
      .then(customers => customers.map((customer) => {
        if (customer.id && !customer.name) {
          const customerInAuthorizations = authorizations
            .find(authorization => authorization.id === customer.id)
          if (customerInAuthorizations) {
            return Object.assign(customer, { name: customerInAuthorizations.name })
          }
        }
        return customer
      }))
      .then((customers) => {
        dispatch(cacheCustomers(customers))
        dispatch(loadingAction({ key: INTRASTAT_CACHE_CUSTOMERS, value: false }))
      })
      .catch((error) => {
        logger.error('Error in fetching customer data', error)
        const message = error && error.globalIntlMessage
        dispatch(cacheCustomers(error, { message }))
        dispatch(loadingAction({ key: INTRASTAT_CACHE_CUSTOMERS, value: false }))
        dispatch(showGlobalNotification({
          level: 'error',
          modal: true,
          message: messages.errorFetchingCustomers,
          additionalInfo: message && message.id ? message : undefined,
        }))
        throw error
      })
  }

export const fetchNonCachedCustomers = customerIds =>
  (dispatch, getState) => {
    const cachedCustomers = getState().intrastat.common.cachedCustomers
    const nonCachedCustomers = customerIds.filter(id => find(cachedCustomers, { id }) === undefined)
    if (nonCachedCustomers && nonCachedCustomers.length) {
      return fetchCustomers(nonCachedCustomers)(dispatch, getState)
    }
    return Promise.resolve()
  }

export const selectPsi = (psiId = null, tdpId = null, clearPsiReportingUnit, clearTdpReportingUnit) =>
  (dispatch, getState) => {
    fetchNonCachedCustomers(uniq([psiId, tdpId].filter(id => id !== null)))(dispatch, getState)
    if (clearPsiReportingUnit) {
      dispatch(change(INTRASTAT_FORM_NAME, 'psiReportingUnit', null))
    }
    if (clearTdpReportingUnit) {
      dispatch(change(INTRASTAT_FORM_NAME, 'tdpReportingUnit', null))
    }
    dispatch(change(INTRASTAT_FORM_NAME, 'psi', psiId))
    dispatch(change(INTRASTAT_FORM_NAME, 'tdp', tdpId === psiId ? null : tdpId))
  }
