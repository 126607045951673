/**
 * @file Container component connecting presentational React component to Redux state
 * @see {@link http://redux.js.org/docs/basics/UsageWithReact.html}
 * @author Sami Tiilikainen
 */

import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { injectIntl } from 'react-intl'
import { getFormValues } from 'redux-form'
import { reduce, size, pick, get, set } from 'lodash'
import EditDeclaration from '../components/EditDeclaration'
import {
  stepChange,
  initializeForm,
  destroyState,
  setToNew,
  deleteDraft,
  togglePreserveDraftOnExitModal,
  toggleConfirmCloseModal,
} from '../actions'
import { setOnlyInvalidRowsVisibility } from '../routes/rows/actions'
import { selectPsi } from '../../actions'
import {
  DECLARATION_STATUS_INCORRECT,
  DECLARATION_STATUS_ACCEPTED,
  DECLARATION_STATUS_DEFACED,
  INTRASTAT_FORM_NAME,
  DECLARATION_DELETED,
  DECLARATION_HEADERS_FETCHED,
  DECLARATION_ROWS_FETCHED,
} from '../constants'
import { getSelectedAuthorization } from '../../../../utils/auth'

const commonFormFields = [
  'id',
  'declarationId',
  'nextDeclarationId',
  'previousDeclarationId',
  'psi',
  'tdp',
  'referencePeriod',
  'flowCode',
  'noDeclarationRows',
  'status',
  'dateOfModify',
  'dateOfCreate',
]

const mapStateToProps = (state) => {
  const {
    currentStep,
    errorFetchingDeclaration,
    errorFetchingDeclarationRows,
    lastSave,
    showPreserveDraftOnExitModal,
    showConfirmCloseModal,
    isNew,
    showOnlyInvalidRows,
  } = state.intrastat.declaration

  const {
    cachedCustomers,
  } = state.intrastat.common

  const deletingDeclaration = state.loading[DECLARATION_DELETED]
  const fetchingDeclaration = state.loading[DECLARATION_HEADERS_FETCHED]
  const fetchingDeclarationRows = state.loading[DECLARATION_ROWS_FETCHED]

  const formValues = getFormValues(INTRASTAT_FORM_NAME)(state)
  if (!formValues) {
    return {
      isFormReady: false,
    }
  }

  const commonHeaderFields = pick(formValues, commonFormFields)
  const rows = formValues.rows

  // Collect server-side warnings from rows
  let rowsCount = 0
  let warningRowsCount = 0
  if (rows && rows.length) {
    rowsCount = rows.length
    warningRowsCount = reduce(rows, (sum, row) => sum + (size(row.warnings) > 0 ? 1 : 0), 0)
  }

  // TODO: Calculate total invoiced amount
  let totalNetMass = 0
  let totalStatisticalValue = 0
  let totalInvoicedAmount = 0
  if (rows && rows.length) {
    for (const row of rows) {
      totalNetMass += parseInt(row.netMass, 10) || 0
      totalStatisticalValue += parseInt(row.statisticalValueInEur, 10) || 0
      totalInvoicedAmount += parseInt(row.invoicedAmountInEur, 10) || 0
    }
  }

  const psiObject = cachedCustomers.find(customer => customer.id === formValues.psi)

  let lastSaveTime = lastSave
  if (!lastSave && formValues && formValues.dateOfModify) {
    lastSaveTime = new Date(formValues.dateOfModify).getTime()
  }

  const userId = state.auth.userId
  const parseTogglesFromLocalStorage = () => JSON.parse(localStorage.getItem('uiToggles') || '{}')
  return {
    isFormReady: formValues !== undefined,
    formFieldValues: commonHeaderFields,
    isNew,
    rowsCount,
    warningRowsCount,
    totalNetMass,
    totalStatisticalValue,
    totalInvoicedAmount,
    psiObject,
    incorrect: formValues && formValues.status === DECLARATION_STATUS_INCORRECT,
    accepted: formValues && formValues.status === DECLARATION_STATUS_ACCEPTED,
    invalidated: formValues && formValues.status === DECLARATION_STATUS_DEFACED,
    locale: state.locale,
    currentStep,
    fetchingDeclaration,
    fetchingDeclarationRows,
    errorFetchingDeclaration,
    errorFetchingDeclarationRows,
    cmsMessages: state.content.cmsMessages,
    lastSaveTime,
    showPreserveDraftOnExitModal,
    showConfirmCloseModal,
    deletingDeclaration,
    showOnlyInvalidRows,
    selectedOrganizationId: get(getSelectedAuthorization(), 'id'),
    isHelpVisible: helpPath => get(parseTogglesFromLocalStorage(), `${userId}.help.${helpPath}`, true),
    setHelpVisible: (helpPath, visible = true) => {
      const allToggles = parseTogglesFromLocalStorage()
      set(allToggles, `${userId}.help.${helpPath}`, visible)
      localStorage.setItem('uiToggles', JSON.stringify(allToggles))
    },
  }
}

const mapActionCreators = {
  onStepChange: stepChange,
  initializeForm,
  destroyState,
  deleteDraft,
  setToNew,
  selectPsi,
  setOnlyInvalidRowsVisibility,
  onHidePreserveDraftOnExitModal: () => togglePreserveDraftOnExitModal(false),
  onShowConfirmCloseModal: () => toggleConfirmCloseModal(true),
  onHideConfirmCloseModal: () => toggleConfirmCloseModal(false),
}

export default withRouter(
  connect(mapStateToProps, mapActionCreators)(
    injectIntl(
      EditDeclaration
    )))
