/**
 * @file React Intl translation messages for Intrastat confirmation page
 * @see {@link https://github.com/yahoo/react-intl}
 * @author Ville Miekk-oja
 */

import { defineMessages } from 'react-intl'

export default defineMessages({
  invalidate: {
    id: 'intrastat.summary.invalidate',
    description: 'Summary view invalidate button text',
    defaultMessage: 'Invalidate',
  },
  invalidateModalCancel: {
    id: 'intrastat.summary.invalidate.modal.cancel',
    description: 'Invalidate modal cancel button text',
    defaultMessage: 'Cancel',
  },
  invalidateModalInvalidate: {
    id: 'intrastat.summary.invalidate.modal.continue',
    description: 'Invalidate modal continue button text',
    defaultMessage: 'Invalidate',
  },
  invalidateModalTitle: {
    id: 'intrastat.summary.invalidate.modal.title',
    description: 'Invalidate modal title text',
    defaultMessage: 'Invalidate declaration?',
  },
  invalidateModalMessage: {
    id: 'intrastat.summary.invalidate.modal.message',
    description: 'Invalidate modal confirmation message',
    defaultMessage: 'Are you sure you want to invalidate intrastat declaration with id {declarationId} ' +
                    'and reference period {referencePeriod}?',
  },
})
