import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { get } from 'lodash'
import MRNRoot from './MRNRoot'

const mapStateToProps = state => ({
  locale: state.locale,
  cmsMessages: get(state, 'content.cmsMessages', {}),
})

const mapActionCreators = {}

export default withRouter(
  connect(mapStateToProps, mapActionCreators)(
    MRNRoot
  ))
