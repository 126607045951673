import React from 'react'
import { Row, Col, Button } from 'react-bootstrap'
import classNames from 'classnames'

import Icon from 'src/components/Icon'
import styles from './InlineNotification.scss'

export default function InlineNotification({
  buttonText,
  buttonIcon,
  onClick,
  warning,
  info,
  children,
}) {
  let alertClass = ''
  let iconName = ''

  if (info) {
    alertClass = styles.info
    iconName = 'info'
  }
  if (warning) {
    alertClass = styles.warning
    iconName = 'attention'
  }

  return (
    <section className={classNames(styles.alert, alertClass, styles.flex)} aria-label={children}>
      <Row>
        <Col xs={2} sm={1} md={1}>
          <Icon name={iconName} lg className={classNames(styles.icon, styles.inlineIcon)} />
        </Col>
        <Col xs={10} sm={11} md={11}>
          <p>{children}</p>
          {onClick &&
          <Button onClick={onClick} className={styles.button}>
            { buttonIcon && <Icon name={buttonIcon} /> }
            <span>{buttonText}</span>
          </Button>
          }
        </Col>
      </Row>
    </section>
  )
}
