import logger from 'src/utils/logger'
import { memoize } from 'lodash'
import { apiCall } from 'src/utils/http'
import { getStore } from '../../store'

function get(url) {
  return apiCall(url, { method: 'GET' }, { }, undefined, false)
    .catch((error) => {
      logger.error('API call failed', error)
      throw error
    })
}

const getTmFormulaUrl = memoize(() => getStore().getState().config.bootstrapConfig.tm_formula_ext_url)

const api = {
  getFormGroupTypes: groupId =>
    get(`${getTmFormulaUrl()}/form/${groupId}/types`),
}

export default api
