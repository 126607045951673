
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { get } from 'lodash'
import Summary from './Summary'

const mapStateToProps = state => {
  return {
    locale: state.locale,
    formData: get(state, 'forms'),
    cmsMessages: get(state, 'content.cmsMessages', {}),
  }
}

const mapActionCreators = {}

export default withRouter(
  connect(mapStateToProps, mapActionCreators)(
    Summary
  ))
