import React from 'react'
import { injectIntl, FormattedMessage, defineMessages } from 'react-intl'
import { connect } from 'react-redux'
import { Grid, Row, Col, Button } from 'react-bootstrap'
import { find, get, isNil } from 'lodash'
import Modal from 'src/components/Modal'
import rowMessages from '../rows/messages'

// Header-related intl message definitions
const modalMessages = defineMessages({
  title: {
    id: 'intrastat.summary.rowModal.title',
    description: 'Title for intrastat amendment request modal',
    defaultMessage: 'Nomenclature row {rowNumber}',
  },
  close: {
    id: 'intrastat.summary.rowModal.close',
    description: 'Label for intrastat row summary modal close button',
    defaultMessage: 'Close',
  },
})

const messages = {
  ...rowMessages,
  ...modalMessages,
}

class RowSummaryModal extends React.Component {
  constructor(props) {
    super(props)
    this.toggleCopyRows = this.toggleCopyRows.bind(this)
    this.state = {
      copyRows: true,
    }
  }

  toggleCopyRows() {
    this.setState({
      copyRows: !this.state.copyRows,
    })
  }

  render() {
    const {
      rowData,
      rowIndex,
      onHide,
      flowCode,
      codeset,
      cn8Codes,
      fetchCN8Codes,
      getNextRowId,
      getPrevRowId,
      selectRow,
      locale,
      hasItemNumber,
    } = this.props

    const customHeaderButtons = []
    const nextRowId = getNextRowId()

    if (nextRowId) {
      customHeaderButtons.push(
        <Button
          key="nextRowButton"
          bsStyle="link"
          className="shoulder-button__right"
          active
          onClick={() => selectRow(nextRowId)}
        >
          <FormattedMessage {...messages.next} />
        </Button>
      )
    }

    const prevRowId = getPrevRowId()

    if (prevRowId) {
      customHeaderButtons.push(
        <Button
          key="prevRowButton"
          bsStyle="link"
          className="shoulder-button__left"
          active
          onClick={() => selectRow(prevRowId)}
        >
          <FormattedMessage {...messages.previous} />
        </Button>
      )
    }

    let titleMessage
    if (!isNil(rowIndex)) {
      titleMessage = {
        ...messages.title,
        values: { rowNumber: hasItemNumber ? rowData.itemNumber : rowIndex + 1 },
      }
    }

    const filterByLocale = (data, llocale) => data.flat().filter(item => item.locale === llocale)

    const memberStateData =
      find(filterByLocale(codeset.memberStates, locale), { code: rowData.memberState })
    const countryOfOriginData =
      find(filterByLocale(codeset.countriesOfOrigin, locale), { code: rowData.countryOfOrigin })
    const transactionTypeData =
      find(filterByLocale(codeset.transactionTypes, locale), { code: String(rowData.natureOfTransactionCode) })
    const transportTypeData =
      find(filterByLocale(codeset.transportTypes, locale), { code: String(rowData.modeOfTransport) })
    const cn8Data = find(cn8Codes, { cn8Code: rowData.CN8Code })
    if (!cn8Data) {
      fetchCN8Codes(rowData.CN8Code)
    }
    return (
      <Modal
        show
        showCancel={false}
        showContinue
        continueMessage={messages.close}
        continueBtnStyle="primary"
        onClickContinue={onHide}
        disableEscKey
        titleMessage={titleMessage}
        animation={false}
        customHeaderButtons={customHeaderButtons}
        focusButton
      >
        <Grid fluid>
          <Row>
            <Col sm={4}>
              <label>
                <FormattedMessage
                  {...(flowCode === 'ARRIVAL_OF_GOODS' ?
                    messages.arrivalMemberStateInputLabel : messages.dispatchMemberStateInputLabel)}
                />
              </label>
            </Col>
            <Col sm={1}>
              {rowData.memberState}
            </Col>
            <Col sm={7}>
              {memberStateData && memberStateData.name}
            </Col>
          </Row>
          {rowData.partnerId &&
            <Row>
              <Col sm={4}>
                <label><FormattedMessage {...messages.partnerIdInputLabel} /></label>
              </Col>
              <Col sm={8}>
                {rowData.partnerId}
              </Col>
            </Row>
          }
          {rowData.countryOfOrigin &&
            <Row>
              <Col sm={4}>
                <label><FormattedMessage {...messages.countryOfOriginInputLabel} /></label>
              </Col>
              <Col sm={1}>
                {rowData.countryOfOrigin}
              </Col>
              <Col sm={7}>
                {countryOfOriginData && countryOfOriginData.name}
              </Col>
            </Row>
          }
          {rowData.natureOfTransactionCode &&
            <Row>
              <Col sm={4}>
                <label><FormattedMessage {...messages.natureOfTransactionCodeInputLabel} /></label>
              </Col>
              <Col sm={1}>
                {rowData.natureOfTransactionCode}
              </Col>
              <Col sm={7}>
                {transactionTypeData && transactionTypeData.name}
              </Col>
            </Row>
          }
          {rowData.modeOfTransport &&
            <Row>
              <Col sm={4}>
                <label><FormattedMessage {...messages.modeOfTransportInputLabel} /></label>
              </Col>
              <Col sm={1}>
                {rowData.modeOfTransport}
              </Col>
              <Col sm={7}>
                {transportTypeData && transportTypeData.name}
              </Col>
            </Row>
          }
          {rowData.CN8Code &&
            <Row>
              <Col sm={4}>
                <label><FormattedMessage {...messages.CN8CodeInputLabel} /></label>
              </Col>
              <Col sm={8}>
                {rowData.CN8Code} {cn8Data &&
                  <span className="text-muted">{cn8Data.selfExplanatoryText || cn8Data.description}</span>
                }
              </Col>
            </Row>
          }

          {rowData.netMass > 0 &&
            <Row>
              <Col sm={4}>
                <label><FormattedMessage {...messages.netMassInputLabel} /></label>
              </Col>
              <Col sm={8}>
                {rowData.netMass} <FormattedMessage {...messages.abbreviationKilograms} />
              </Col>
            </Row>
          }

          {rowData.quantityInSU > 0 &&
            <Row>
              <Col sm={4}>
                <label><FormattedMessage {...messages.quantityInSUInputLabel} /></label>
              </Col>
              <Col sm={8}>
                {rowData.quantityInSU} {
                  cn8Data && get(find(codeset.secondaryUnits, { code: cn8Data.quantityInSU }), 'abbreviation')
                }
              </Col>
            </Row>
          }

          {rowData.invoicedAmount > 0 &&
            <Row>
              <Col sm={4}>
                <label><FormattedMessage {...messages.invoicedAmountInputLabel} /></label>
              </Col>
              <Col sm={8}>
                {rowData.invoicedAmount} {rowData.invoicedValueCurrencyCode}
                {rowData.invoicedAmountInEur && rowData.invoicedValueCurrencyCode !== 'EUR' &&
                  <span className="text-muted"> ({rowData.invoicedAmountInEur} EUR)</span>
                }
              </Col>
            </Row>
          }

          {rowData.statisticalValue > 0 &&
            <Row>
              <Col sm={4}>
                <label><FormattedMessage {...messages.statisticalValueInputLabel} /></label>
              </Col>
              <Col sm={8}>
                {rowData.statisticalValue} {rowData.statisticalValueCurrencyCode}
                {rowData.statisticalValueInEur && rowData.statisticalValueCurrencyCode !== 'EUR' &&
                  <span className="text-muted"> ({rowData.statisticalValueInEur} EUR)</span>
                }
              </Col>
            </Row>
          }

          {rowData.invoiceNumber &&
            <Row>
              <Col sm={4}>
                <label><FormattedMessage {...messages.invoiceNumberInputLabel} /></label>
              </Col>
              <Col sm={8}>
                {rowData.invoiceNumber}
              </Col>
            </Row>
          }
        </Grid>
      </Modal>
    )
  }
}

const mapStateToProps = state => ({ locale: state.locale })

export default connect(mapStateToProps)(injectIntl(RowSummaryModal))
