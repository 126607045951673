import { apiCall } from 'src/utils/http'
import logger from 'src/utils/logger'
import { memoize } from 'lodash'
import {
  INDIVIDUAL_GUARANTEE_GET_GUARANTEE_STATUS_PATH,
  INDIVIDUAL_GUARANTEE_SEND_BANKING_DETAILS_PATH,
  PAYMENT_START_ROUTE,
} from './constants'
import { getStore } from '../../store'

const getTmGuaranteeUrl = memoize(() => getStore().getState().config.bootstrapConfig.tm_guarantee_ext_url)

function get(url) {
  return apiCall(url, { method: 'GET' }, {}, undefined, false, false)
    .catch((error) => {
      logger.error('API call failed', error)
      throw error
    })
}

function post(url, body = {}) {
  return apiCall(url, { method: 'POST', body: JSON.stringify(body) }, {}, undefined, false, false)
    .catch((error) => {
      logger.error('API call failed', error)
      throw error
    })
}

const composeParameters = (grn, correlationId, timestamp, checksum, guaranteeCategory) => {
  const optionalParameters = guaranteeCategory ? `&guaranteeCategory=${guaranteeCategory}` : ''
  return `?grn=${grn}&mrn=${correlationId}&timestamp=${timestamp}&checksum=${encodeURIComponent(checksum)}` +
  `${optionalParameters}`
}

export const getIndividualGuaranteeStatus = (grn, correlationId, timestamp, checksum, guaranteeCategory) => {
  const apiUrl = `${getTmGuaranteeUrl()}/${INDIVIDUAL_GUARANTEE_GET_GUARANTEE_STATUS_PATH}` +
  `${composeParameters(grn, correlationId, timestamp, checksum, guaranteeCategory)}`
  return get(apiUrl)
}

export const sendBankingDetails = (grn, correlationId, timestamp, checksum, bankingDetails, guaranteeCategory) => {
  const apiUrl = `${getTmGuaranteeUrl()}/${INDIVIDUAL_GUARANTEE_SEND_BANKING_DETAILS_PATH}` +
  `${composeParameters(grn, correlationId, timestamp, checksum, guaranteeCategory)}`
  return post(apiUrl, bankingDetails)
}

export const startPayment = (grn, correlationId, timestamp, checksum, guaranteeCategory) => {
  const apiUrl = `${getTmGuaranteeUrl()}/${PAYMENT_START_ROUTE}` +
  `${composeParameters(grn, correlationId, timestamp, checksum, guaranteeCategory)}`
  return get(apiUrl)
}
