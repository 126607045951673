import { apiCall } from 'src/utils/http'
import { getStore } from '../../store'

export default function sendIatLoginRequest(companyId, locale) {
  const { config: { bootstrapConfig } } = getStore().getState()

  return apiCall(
    `${bootstrapConfig.tm_ext_auth_ext_url}/iat/${companyId}?locale=${locale}`,
    { method: 'GET' },
    { },
    undefined,
    false
  )
    .then((response) => {
      if (response && response.redirect) {
        // Logout response should contain redirect url to external logout service
        if (!/^https?:\/\//.test(response.redirect)) {
          throw new Error('Invalid IAT login redirect')
        }
      }
      return response
    })
}
