import { combineReducers } from 'redux'
import { reducer as form } from 'redux-form'
import { set } from 'lodash'
import codeset from '../components/codeset/reducer'
import customer from '../components/customer/reducer'
import notifications from './notifications'
import loading from './loading'
import auth from './auth'
import content from './content'
import locale from './locale'
import config from './config'
import cookies from './cookies'

/**
 * Version of combineReducers supporting hierarchy of reducers.
 *
 * Based on https://github.com/randallknutson/redux-injector/blob/master/src/ReduxInjector.js
 */
export const combineReducersRecurse = (reducers) => {
  // If this is a leaf or already combined.
  if (typeof reducers === 'function') {
    return reducers
  }

  // If this is an object of functions, combine reducers.
  if (typeof reducers === 'object') {
    const combinedReducers = {}
    for (const key of Object.keys(reducers)) {
      combinedReducers[key] = combineReducersRecurse(reducers[key])
    }
    return combineReducers(combinedReducers)
  }

  // If we get here we have an invalid item in the reducer path.
  throw new Error({
    message: 'Invalid item in reducer tree',
    item: reducers,
  })
}

/**
 * Root reducer combining initially specified and asynchronous reducers
 */
export const reducers = asyncReducers =>
  combineReducersRecurse({
    locale,
    loading,
    auth,
    content,
    form,
    notifications,
    codeset,
    customer,
    config,
    cookies,
    ...asyncReducers,
  })

/**
 * Inject asynchronously loaded reducers to Redux store.
 * Key is lodash.set compatible path to key in store.
 */
export const injectReducer = (store, { key, reducer }) => {
  set(store.asyncReducers, key, reducer)
  store.replaceReducer(reducers(store.asyncReducers))
}
