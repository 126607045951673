import React, { useContext } from 'react'
import { Row, Col } from 'react-bootstrap'
import { find, endsWith, xor, get } from 'lodash'
import { FormattedMessage } from 'react-intl'
import InfoElementHeaderArea from 'src/components/form_v2/InfoElementHeaderArea'
import {
  PERMIT_DEBT_GUARANTEE_LEVELS,
  PERMIT_LIABILITY_GUARANTEE_LEVELS,
} from '../constants'
import PermitPageContext from './PermitPage/context'
import messages from '../messages'
import { getCodeset } from './PermitPage/customParams'

export default function (props) {
  const { customerHasAEO } = useContext(PermitPageContext)
  const { infoElement, locale, formApi, renderField, helpTexts } = props
  const guaranteeLevelField = find(infoElement.fields, field => endsWith(field.code, 'guaranteeLevel'))

  const fieldProps = {
    input: {
      multiple: false,
    },
  }

  const otherFields = xor(infoElement.fields, [
    guaranteeLevelField,
  ])

  const debtGuaranteeLevel = {
    field: {
      ...guaranteeLevelField,
      code: 'guaranteeLevel.debtGuaranteeLevel',
      codesetName: 'VakuudenTaso',
      codesetValidValues: PERMIT_DEBT_GUARANTEE_LEVELS,
      name: messages.debtGuaranteeLevel,
      help: {
        [locale]: get(helpTexts, 'fields.debtGuaranteeLevel'),
      },
    },
    ...fieldProps,
    input: {
      ...fieldProps.input,
      type: customerHasAEO ? 'codeset' : 'codesetStatic',
    },
  }

  const liabilityGuaranteeLevel = {
    field: {
      ...guaranteeLevelField,
      code: 'guaranteeLevel.liabilityGuaranteeLevel',
      codesetName: 'VakuudenTaso',
      codesetValidValues: PERMIT_LIABILITY_GUARANTEE_LEVELS,
      name: messages.liabilityGuaranteeLevel,
      help: {
        [locale]: get(helpTexts, 'fields.liabilityGuaranteeLevel'),
      },
    },
    ...fieldProps,
    input: {
      ...fieldProps.input,
      type: 'codeset',
    },
  }

  const customsProcedureGuaranteeCodeset = getCodeset('CustomsProcedureGuarantee', { codesets: props.codesets })
  const guaranteeCustomsProcedures = get(formApi.values, 'guaranteePerCustomsProcedure') || []

  const debtGuaranteeProcedureTypes = guaranteeCustomsProcedures.filter((procedure) => {
    const existsInCodeset = Object.values(
      customsProcedureGuaranteeCodeset).find(customsProcedureGuarantee =>
      customsProcedureGuarantee.code === procedure.guaranteeCustomsProcedure
    )

    if (existsInCodeset && get(existsInCodeset, 'extensionValues.velka')) {
      return true
    }

    return false
  })

  const liabilityGuaranteeProcedureTypes = guaranteeCustomsProcedures.filter((procedure) => {
    const existsInCodeset = Object.values(
      customsProcedureGuaranteeCodeset).find(customsProcedureGuarantee =>
      customsProcedureGuarantee.code === procedure.guaranteeCustomsProcedure
    )

    if (existsInCodeset && get(existsInCodeset, 'extensionValues.vastuu')) {
      return true
    }

    return false
  })


  const showDebtGuaranteeLevelSelector = debtGuaranteeProcedureTypes && debtGuaranteeProcedureTypes.length > 0
  const showLiabilityGuaranteeLevelSelector = liabilityGuaranteeProcedureTypes
    && liabilityGuaranteeProcedureTypes.length > 0
    
  return (
    <Col sm={6} xs={12}>
      <Row>
        <Col xs={12}>
          <InfoElementHeaderArea
            infoElement={infoElement}
            locale={locale}
          />
        </Col>
      </Row>
      <Row>
        {!debtGuaranteeProcedureTypes && !liabilityGuaranteeProcedureTypes && <Col xs={12}>
          <FormattedMessage {...messages.missingGuaranteeText} /><br /><br />
        </Col>}
        <Col xs={12}>
          {showDebtGuaranteeLevelSelector && renderField({
            ...debtGuaranteeLevel,
          })}
        </Col>
        <Col xs={12}>
          {showLiabilityGuaranteeLevelSelector && renderField({
            ...liabilityGuaranteeLevel,
          })}
        </Col>
        <Col xs={12}>
          {otherFields && otherFields.map((field) => {
            const fixedField = Object.assign({}, field, { code: `guaranteeLevel.${field.code}` })
            return renderField({
              field: fixedField,
              ...fieldProps,
            })
          })}
        </Col>
      </Row>
    </Col>
  )
}
