import React, { useEffect, useState } from 'react'
import { Col, Row, Button, ButtonToolbar } from 'react-bootstrap'
import { FormattedMessage, injectIntl } from 'react-intl'
import { Form } from 'react-form'
import { get } from 'lodash'
import CommonFormGroup from 'src/components/form_v2/CommonFormGroup'
import { messages as validationMessages } from 'src/utils/validation'
import { focusFirstElementByIdOrName } from 'src/utils'
import messages from './messages'
import api from './api'
import {
  arexPattern,
  arrivalNotificationPattern,
  defaultPattern,
  elexPattern,
  pousPattern,
  presentationNotificationPattern,
  utuEntry,
  utuExportRegexPattern,
  utuImportRegexPattern,
} from './constants'
import Alert from '../../components/Alert'
import Icon from '../../components/Icon'

class MRNForm extends React.Component {
  constructor(props) {
    super(props)
    this.formApi = null

    this.connectFormApi = this.connectFormApi.bind(this)
    this.doSearch = this.doSearch.bind(this)
  }

  connectFormApi(formApi) {
    this.formApi = formApi
  }

  doSearch(formData) {
    if (!get(formData, 'mrn')) {
      this.formApi.setError('mrn', validationMessages.required)
      return null
    }

    return this.props.onSubmit({ ...formData })
  }

  render() {
    const {
      helpTexts,
      onReset,
      tooManyResults,
      setIsUtuEntryMrn,
      isUtuEntryMrn,
      intl: { formatMessage },
    } = this.props

    return (
      <Form
        onSubmit={this.doSearch}
        getApi={this.connectFormApi}
        render={formApi => (
          <MRNFormInner
            formApi={formApi}
            onReset={onReset}
            helpTexts={helpTexts}
            tooManyResults={tooManyResults}
            setIsUtuEntryMrn={setIsUtuEntryMrn}
            isUtuEntryMrn={isUtuEntryMrn}
            formatMessage={formatMessage}
          />
        )}
      />)
  }
}

function MRNFormInner({ formApi, onReset, helpTexts, tooManyResults, isUtuEntryMrn, setIsUtuEntryMrn, formatMessage }) {
  const [validUtuArrivalRegexPatterns, setValidUtuArrivalRegexPatterns] = useState([])
  const [showTransportDocIdentifier, setShowTransportDocIdentifier] = useState(false)
  const [showGoodsItem, setShowGoodsItem] = useState(true)

  useEffect(() => {
    const fetchCountryCodes = async () => {
      const { countryCodes } = await api.getCountryCodesEU()

      /**
       * ACCEPTABLE:
       * SHOW MRN AND TAVARAERÄ FIELDS:
       * ELEX: ^[0-9]{2}FI[0-9A-Z]{12}E[0-9A-Z]$
       * AREX: ^[0-9]{2}FI[0-9A-Z]{12}I[0-9A-Z]$
       * UTU TUONTI: ^[0-9]{2}FIU[0-9A-Z]{11}[0-9A-WY-Z][0-9A-Z]$
       *
       * ACCEPTABLE:
       * SHOW MRN, TAVARAERÄ AND KULJETUSTUNNUS FIELDS:
       * UTU SAAPUMINEN: ^[0-9]{2}[DYNAMIC_EU_COUNTRY_CODE][0-9A-DF-TV-Z][0-9A-Z]{10}AT[0-9A-Z]$
       * UTU SAAPUMINEN: ^[0-9]{2}FIE[0-9A-Z]{13}$
       *
       * ACCEPTABLE:
       * SHOW ONLY MRN FIELD:
       * POUS: ^[0-9A-Z]{16}P[0-9A-Z]$
       *
       * SHOW ERROR:
       * SAAPUMISEN ESITTÄMISILMOITUS: ^[0-9A-Z]{2}FIU[0-9A-Z]{11}X[0-9A-Z]$
       * SAAPUMISILMOITUS: ^[0-9A-Z]{16}Z[0-9A-Z]$
       * ~any other string~
       */

      const UtuArrivalRegexPrefix = '^[0-9]{2}'
      const UtuArrivalRegexSuffix = '[0-9A-DF-TV-Z][0-9A-Z]{10}AT[0-9A-Z]'

      const utuRegexPatterns = []
      utuRegexPatterns.push('^[0-9]{2}FIE[0-9A-Z]{13}$')

      // eslint-disable-next-line guard-for-in,no-restricted-syntax
      for (const i in countryCodes) {
        utuRegexPatterns.push(UtuArrivalRegexPrefix + countryCodes[i].code + UtuArrivalRegexSuffix)
      }

      setValidUtuArrivalRegexPatterns(utuRegexPatterns)
    }

    fetchCountryCodes()
  }, [])

  // Returns false when the string is a real MRN string, otherwise it returns an error message.
  function validateMRN(mrnString) {
    const mrn = mrnString.trim()
      .toUpperCase()

    if (utuEntry.test(mrn)) {
      setIsUtuEntryMrn(true)
    } else {
      setIsUtuEntryMrn(false)
    }

    // Test against SAAPUMISEN ESITTÄMISILMOITUS
    if (presentationNotificationPattern.test(mrn)) {
      setShowTransportDocIdentifier(false)
      setShowGoodsItem(true)
      return messages.mrnValidationUTUPresentationNotificationError
    }

    // Test against SAAPUMISILMOITUS
    if (arrivalNotificationPattern.test(mrn)) {
      setShowTransportDocIdentifier(false)
      setShowGoodsItem(true)
      return messages.mrnValidationErrorNoticeOfArrival
    }

    // Test against PoUS
    if (pousPattern.test(mrn)) {
      setShowTransportDocIdentifier(false)
      setShowGoodsItem(false)
      return false
    }

    // Test against AREX, ELEX and UTU Import
    if (arexPattern.test(mrn) || elexPattern.test(mrn) || utuImportRegexPattern.test(mrn)) {
      setShowTransportDocIdentifier(false)
      setShowGoodsItem(true)
      return false
    }

    // Test against UTU Export
    if (utuExportRegexPattern.test(mrn)) {
      setShowGoodsItem(false)
      return false
    }

    // Test against UTU Arrival
    // eslint-disable-next-line no-restricted-syntax,guard-for-in
    for (const i in validUtuArrivalRegexPatterns) {
      const pattern = new RegExp(validUtuArrivalRegexPatterns[i])


      if (pattern.test(mrn)) {
        setShowTransportDocIdentifier(true)
        setShowGoodsItem(true)
        return false
      }
    }

    // Test against default pattern
    if (defaultPattern.test(mrn)) {
      setShowGoodsItem(false)
      return false
    }

    setShowTransportDocIdentifier(false)
    setShowGoodsItem(true)
    return messages.mrnValidationError
  }

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault()
        formApi.submitForm()
          .then(() => {
            const errors = get(formApi.getFormState(), 'errors', {})
            focusFirstElementByIdOrName(Object.keys(errors))
          })
      }}
    >
      <Row>
        <Col xs={12} md={showTransportDocIdentifier ? 4 : 7}>
          <CommonFormGroup
            errors={formApi.errors}
            formApi={formApi}
            formGroupClassname="formElementGutter"
            input={{
              name: 'mrn',
              type: 'text',
              help: { content: get(helpTexts, 'fields.mrn', null) },
              validate: true,
              validation: {
                strict: true,
                mandatory: true,
                maxLength: '18',
                minLength: '0',
                validator: {
                  fn: validateMRN,
                },
              },
              visible: true,
            }}
            label={messages.mrnFieldLabel}
            showLabel
            type="TEXT"
          />
        </Col>
        {showTransportDocIdentifier &&
          <Col xs={12} md={4}>
            <CommonFormGroup
              errors={formApi.errors}
              formApi={formApi}
              formGroupClassname="formElementGutter"
              input={{
                name: 'transportDocumentID',
                type: 'text',
                help: { content: get(helpTexts, 'fields.transportDocumentID', null) },
                validate: true,
                validation: {
                  strict: false,
                  // Normally this is always mandatory
                  // But when we have an utu-entry MRN, then it is mandatory if goodsItem field is filled
                  mandatory: !isUtuEntryMrn ? true : get(formApi, 'values.goodsItem'),
                  maxLength: '70',
                  minLength: '1',
                },
                visible: true,
              }}
              label={messages.transportDocumentIDFieldLabel}
              showLabel
              type="TEXT"
            />
          </Col>
        }
        {showGoodsItem && <Col xs={12} md={showTransportDocIdentifier ? 4 : 5}>
          <CommonFormGroup
            errors={formApi.errors}
            formApi={formApi}
            formGroupClassname="formElementGutter"
            input={{
              name: 'goodsItem',
              type: 'text',
              help: { content: get(helpTexts, 'fields.goodsItem', null) },
              validate: true,
              validation: {
                strict: false,
                mandatory: false,
                maxLength: '5',
                minLength: '0',
                regex: {
                  expression: /^[0-9]{1,5}$/,
                  errorMessage: messages.goodsItemValidationError,
                },
              },
              visible: true,
            }}
            label={messages.goodsItemFieldLabel}
            showLabel
            type="TEXT"
          />
        </Col>}
      </Row>
      {tooManyResults && <Row>
        <Col xs={12}>
          <Alert error>
            <Icon name="attention" lg />
            <div>{formatMessage(messages.tooManyResults)}</div>
          </Alert>
        </Col>
      </Row>}
      <Row>
        <Col xs={12}>
          <ButtonToolbar>
            <Button
              type="submit"
              bsStyle="primary"
              id-qa-test="button-search"
            >
              <FormattedMessage {...messages.search} />
            </Button>
            <Button
              type="submit"
              bsStyle="default"
              id-qa-test="button-clear"
              onClick={onReset}
            >
              <FormattedMessage {...messages.clear} />
            </Button>
          </ButtonToolbar>
        </Col>
      </Row>
    </form>)
}

export default injectIntl(MRNForm)
