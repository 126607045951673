import { handleActions } from 'redux-actions'
import {
  STORE_UI_STATE,
} from './constants'

const initialState = {
  selectedRowId: null,
  selectedTab: null,
  showOnlyActivePermits: false,
}

export default handleActions({
  [STORE_UI_STATE]: (state, { payload }) => ({
    ...state,
    selectedRowId: payload.selectedRowId,
    selectedTab: payload.selectedTab,
    showOnlyActivePermits: payload.showOnlyActivePermits,
  }),
}, initialState)
