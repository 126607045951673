import React from 'react'
import { injectIntl } from 'react-intl'
import ReactMarkdown from 'react-markdown'
import { ControlLabel, Button } from 'react-bootstrap'
import { isEmpty } from 'lodash'
import Icon from 'src/components/Icon'
import Popper from 'src/components/Popper'
import LinkRenderer from 'src/components/LinkRenderer'
import messages from '../messages'

/**
 * Label area for a form control. Renders responsive grid column instead of simple ControlLabel if horizontal=true
 */
function LabelArea({
  id,
  htmlFor,
  input,
  children,
  help,
  mandatory,
  disabled,
  style,
  label,
  intl: { formatMessage },
}) {
  const className = disabled ? 'text-muted' : ''
  let helpButton
  if (help && !isEmpty(help.content)) {
    const helpLabel = `${label} ${formatMessage(messages.help)}`
    const helpPopperContent = (
      <ReactMarkdown
        source={help.content}
        renderers={{ Link: LinkRenderer }}
      />
    )
    helpButton = (
      <Popper
        type="popover"
        id={`help-popover-${input.name}`}
        className="help-toggle"
        trigger="click"
        rootClose
        placement="bottom"
        content={helpPopperContent}
        closeIcon
        large
        usePortal
      >
        <Button
          aria-label={helpLabel}
          className="btn-help"
          bsStyle="link"
          bsSize="xs"
          id-qa-test={`btn-help-${input.name}`}
        >
          <Icon name="help" />
        </Button>
      </Popper>
    )
  }
  let mandatoryIcon = ''
  if (mandatory) {
    mandatoryIcon = (
      <span
        className="mandatory"
        title={formatMessage(messages.mandatory)}
      >
        <span aria-hidden="true">*</span>
      </span>
    )
  }
  return (
    <div style={{ position: 'relative' }}>
      <ControlLabel id={id} style={style} className={className} htmlFor={htmlFor}>
        {label}{mandatoryIcon}
      </ControlLabel>
      {children}
      {helpButton}
    </div>
  )
}

export default injectIntl(LabelArea)
