/**
 * @file Container component connecting presentational React component to Redux state
 * @see {@link http://redux.js.org/docs/basics/UsageWithReact.html}
 * @author Sami Tiilikainen
 */

import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { injectIntl } from 'react-intl'
import ReadOnlyDeclaration from '../components/ReadOnlyDeclaration'
import {
  initializeForm,
  destroyState,
} from '../actions'
import {
  DECLARATION_HEADERS_FETCHED,
  DECLARATION_ROWS_FETCHED,
} from '../constants'

const mapStateToProps = (state) => {
  const {
    errorFetchingDeclaration,
    errorFetchingDeclarationRows,
  } = state.intrastat.declaration

  const fetchingDeclaration = state.loading[DECLARATION_HEADERS_FETCHED]
  const fetchingDeclarationRows = state.loading[DECLARATION_ROWS_FETCHED]

  return {
    locale: state.locale,
    fetchingDeclaration,
    fetchingDeclarationRows,
    errorFetchingDeclaration,
    errorFetchingDeclarationRows,
  }
}

const mapActionCreators = {
  initializeForm,
  destroyState,
}

export default withRouter(
  connect(mapStateToProps, mapActionCreators)(
    injectIntl(
      ReadOnlyDeclaration
    )))
