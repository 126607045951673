export const FORMS_ROUTE = '/forms'
export const IAT_ROUTE = '/iat'
export const FEEDBACK_ROUTE = '/feedback'
export const LOGOUT_ROUTE = '/logout'
export const MRN_ROUTE = '/mrn'
export const INTRASTAT_ROUTE = '/intrastat'
export const UTU_ROUTE = '/ext-auth'
export const PERMITS_ROUTE = '/permits'
export const CUSTOMER_INFORMATION_ROUTE = '/customer-information'
export const INDIVIDUAL_GUARANTEE_ROUTE = '/guarantee'

// Intrastat sub routes
export const DECLARATION_ROUTE = '/declaration'
export const HEADERS_ROUTE = '/headers'
export const ROWS_ROUTE = '/rows'
export const CONFIRMATION_ROUTE = '/confirmation'
export const SUMMARY_ROUTE = '/summary'
export const SUMMARY_PRINT_ROUTE = `/${SUMMARY_ROUTE}/print`
export const NEW_ROUTE = '/new'
export const NEW_DECLARATION_PATH_ID = 'new'