import React from 'react'
import { injectIntl } from 'react-intl'
import { get, find, isEmpty } from 'lodash'
import { Field } from 'redux-form'
import { SelectFieldGroup } from 'src/components/form/Select'
import messages from '../messages'
import { REPORTING_UNIT, PSI } from '../../../constants'

function getPsiReportingUnitSelectOptions(selectedPsiObject, psiHasLiability) {
  if (!psiHasLiability || !selectedPsiObject || isEmpty(selectedPsiObject.reportingUnits)) {
    return []
  }
  return selectedPsiObject.reportingUnits
    .filter(reportingUnit =>
      [REPORTING_UNIT, PSI].includes(reportingUnit.reporterType)
    )
    .map(reportingUnit =>
      ({
        id: reportingUnit.intCode,
        value: reportingUnit.intCode,
        title: reportingUnit.intCode,
      })
    )
}

function isMandatory(selectedPsiObject) {
  return get(selectedPsiObject, 'reporterType') !== PSI
}

function PsiReportingUnitColumn(props) {
  const selectedPsiObject = find(props.cachedCustomers, ['id', props.psi])
  const psiHasLiability = selectedPsiObject && props.hasLiability(selectedPsiObject)
  const selectOptions = getPsiReportingUnitSelectOptions(selectedPsiObject, psiHasLiability)
  const label = props.intl.formatMessage(messages.reportingUnitLabel)
  const mandatory = isMandatory(selectedPsiObject)

  let placeholderMessage
  if (mandatory) {
    placeholderMessage = selectOptions.length ? messages.reportingUnitPlaceholder : messages.noReportingUnitsPlaceholder
  } else {
    placeholderMessage = messages.noReportingUnitPlaceholder
  }

  return (
    <Field
      component={SelectFieldGroup}
      name="psiReportingUnit"
      props={{
        disabled: props.disabled || !selectOptions.length || !psiHasLiability,
        label,
        options: selectOptions,
        placeholderMessage,
        help: ({
          content: props.helpText,
        }),
      }}
    />
  )
}

export default injectIntl(PsiReportingUnitColumn)
