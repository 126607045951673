import React from 'react'
import { Doughnut } from 'react-chartjs-2'
import { Chart, ArcElement } from 'chart.js'
import classNames from 'classnames'

import styles from './DoughnutGraph.scss'

Chart.register(ArcElement)

const mapData = data => ({
  datasets: [
    {
      data: data.map(item => item.value),
      backgroundColor: data.map(item => item.color),
    },
  ],
})

const doughnutOptions = cutout => ({
  plugins: {
    legend: {
      display: false,
    },
  },
  events: [],
  hover: { mode: null },
  tooltip: {
    enabled: false,
  },
  rotation: -90,
  circumference: 180,
  cutout,
  maintainAspectRatio: true,
  responsive: false,
  borderWidth: 0,
})

function DoughnutGraph({ description, isSingle, className, percentage, isSmallPercentageText, data }) {
  // Trying to emulate old recharts look & feel
  const height = isSingle ? '90px' : '60px'
  const width = isSingle ? '180px' : '120px'
  const cutout = isSingle ? '89%' : '85%'

  return (
    <div className={classNames(className, styles.percentageWrapper, isSingle ? styles.single : '')}>
      <div className={styles.doughnutWrapper}>
        <Doughnut
          className={styles.doughnut}
          height={height}
          width={width}
          data={mapData(data)}
          options={doughnutOptions(cutout)}
        />
        <div className={styles.percentageTextWrapper}>
          <div className={classNames(styles.percentageText, isSmallPercentageText ? styles.smallPercentageText : '')}>{percentage}</div>
        </div>
      </div>

      <div className={styles.description}>
        {description}
      </div>
    </div>)
}

export default DoughnutGraph
