import React, { useEffect } from 'react'
import Loader from 'src/components/Loader'
import { apiCall } from 'src/utils/http'
import { withRouter } from 'react-router-dom'

import PaymentRoot from '../../components/PaymentRoot'
import { INDIVIDUAL_GUARANTEE_ROUTE_PATH, PAYMENT_CANCEL_API_ROUTE } from '../../constants'
import styles from './PaymentCancel.scss'

function redirectToError(props, timestamp, checksum, paymentReference, correlationId, guaranteeCategory, grn) {
  const { history, location } = props
  const paymentStatus = 'error'
  let path = `/${INDIVIDUAL_GUARANTEE_ROUTE_PATH}?payment=${paymentStatus}`
  if (timestamp) {
    path = `${path}&timestamp=${timestamp}`
  }
  if (checksum) {
    path = `${path}&checksum=${checksum}`
  }
  if (paymentReference) {
    path = `${path}&paymentReferenceNumber=${paymentReference}`
  }
  if (correlationId) {
    path = `${path}&correlationId=${correlationId}`
  }
  if (grn) {
    path = `${path}&grn=${grn}`
  }
  if (guaranteeCategory) {
    path = `${path}&guaranteeCategory=${guaranteeCategory}`
  }

  history.push(path)
}

function processPayment(props) {
  const {
    location: { search },
    config,
    match: {
      params: { timestamp, checksum, paymentReference, correlationId, guaranteeCategory },
    },
  } = props

  if (search) {
    const paytrailParams = `&${search.substring(1)}`

    let url

    if(guaranteeCategory) {
      url = `${config.tm_guarantee_ext_url}/${PAYMENT_CANCEL_API_ROUTE}?mrn=` +
      `${correlationId}&timestamp=${timestamp}&checksum=${checksum}&paymentReference=${paymentReference}&guaranteeCategory=${guaranteeCategory}` +
      `${paytrailParams}`
    } else {
      url = `${config.tm_guarantee_ext_url}/${PAYMENT_CANCEL_API_ROUTE}?mrn=` +
      `${correlationId}&timestamp=${timestamp}&checksum=${checksum}&paymentReference=${paymentReference}` +
      `${paytrailParams}`
    }

    apiCall(url)
      .then((response) => {
        const { grn } = response
        redirectToError(props, timestamp, checksum, paymentReference, correlationId, guaranteeCategory, grn)
      })
      .catch(() => {
        redirectToError(props, timestamp, checksum, paymentReference, correlationId, guaranteeCategory)
      })
  }
}

export default function PaymentCancel(props) {
  useEffect(() => {
    processPayment(props)
  }, [])


  const { cancelLoadingText } = props
  return (
    <PaymentRoot>
      <Loader active center />
      <div>
        <p className={styles.text}>{cancelLoadingText}</p>
      </div>
    </PaymentRoot>
  )
}
