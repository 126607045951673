import React from 'react'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { Grid, Row, Col, Button } from 'react-bootstrap'
import ReactMarkdown from 'react-markdown'
import styles from './PermitsFrontPageInfoBoxes.scss'
import { storeListViewState } from '../actions'
import { PERMIT_TAB_PERMITS } from '../constants'
import { userHasAdminOrHandlerAuthorizations } from '../utils'

class PermitsFrontPageInfoBoxes extends React.Component {
  constructor(props) {
    super(props)
    this.selectPermissionTabInRedux = this.selectPermissionTabInRedux.bind(this)
  }

  selectPermissionTabInRedux(state) {
    this.props.storeListViewState({
      ...state,
      selectedTab: PERMIT_TAB_PERMITS,
      showOnlyActivePermits: true,
    })
    setTimeout(() => document.getElementById('permit-tab-permits-tab').focus(), 500)
  }


  render() {
    const { intl: { formatMessage } } = this.props
    return (
      <Grid className={styles.addDoubleBottom}>
        <Row>
          <Col sm={6} md={3} xs={12} className={styles.permitTopBoxColumn}>
            <div className={styles.box}>
              <h2>
                {formatMessage({ id: '/permits/headerBoxTitle1' })}
              </h2>
              <div>
                <ReactMarkdown source={formatMessage({ id: '/permits/headerBoxContent1' })} />
              </div>
            </div>
            {this.props.permitSelector && (
              <div className={styles.horizontallyCenteringFlexContainer}>
                <div className={styles.permitSelector}>
                  {this.props.permitSelector}
                </div>
              </div>
            )}
          </Col>
          <Col sm={6} md={3} xs={12} className={styles.permitTopBoxColumn}>
            <div className={styles.box}>
              <h2>
                {formatMessage({ id: '/permits/headerBoxTitle2' })}
              </h2>
              <div>
                <ReactMarkdown source={formatMessage({ id: '/permits/headerBoxContent2' })} />
              </div>
            </div>
          </Col>
          <Col sm={6} md={3} xs={12} className={styles.permitTopBoxColumn}>
            <div className={styles.box}>
              <h2>
                {formatMessage({ id: '/permits/headerBoxTitle3' })}
              </h2>
              <div>
                <ReactMarkdown source={formatMessage({ id: '/permits/headerBoxContent3' })} />
              </div>
            </div>
          </Col>
          <Col sm={6} md={3} xs={12} className={styles.permitTopBoxColumn}>
            <div className={styles.box}>
              <h2>
                {formatMessage({ id: '/permits/headerBoxTitle4' })}
              </h2>
              <div>
                <ReactMarkdown source={formatMessage({ id: '/permits/headerBoxContent4' })} />
              </div>
            </div>
            {userHasAdminOrHandlerAuthorizations() &&
              <div className={styles.horizontallyCenteringFlexContainer}>
                <Button
                  className={styles.changePermitButton}
                  bsStyle="primary"
                  id-qa-test="button-return"
                  onClick={() => {
                    this.selectPermissionTabInRedux({})
                  }}
                >
                  {formatMessage({ id: '/permits/openDecisions' })}
                </Button>
              </div>
            }
          </Col>
        </Row>
      </Grid>
    )
  }
}


const mapStateToProps = (state) => {
  const {
    selectedTab,
  } = state.permits.list

  return {
    selectedTab,
  }
}

const mapActionCreators = {
  storeListViewState,
}

export default
connect(mapStateToProps, mapActionCreators)(
  injectIntl(PermitsFrontPageInfoBoxes)
)
