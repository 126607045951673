import React from 'react'
import classNames from 'classnames'
import InteractiveElement from 'src/components/InteractiveElement'
import Loader from 'src/components/Loader'
import CollapsedIcon from 'src/images/cn8_branch_collapsed.svg'
import ExpandedIcon from 'src/images/cn8_branch_expanded.svg'

export default function CN8TreeNode(props) {
  const {
    selectCN8TreeBranch,
    selectCN8TreeLeaf,
    node,
    viewState,
    odd,
  } = props

  const {
    hasChildren,
    loading,
    loadError,
    level,
    cn8Code,
  } = node.model

  const active = viewState.activeNode === node.model.code
  const selected = viewState.selectedNode === node.model.code
  const expanded = Boolean(viewState.expandedNodes[node.model.code])

  const oddRow = Boolean(odd)
  let oddChild = !oddRow

  let children = []

  if (expanded && node.children) {
    children = node.children.map((child) => {
      const childCN8Node = (
        <CN8TreeNode
          key={child.model.code}
          node={child}
          selectCN8TreeBranch={selectCN8TreeBranch}
          selectCN8TreeLeaf={selectCN8TreeLeaf}
          odd={oddChild}
          viewState={viewState}
        />)
      oddChild = !oddChild
      return childCN8Node
    })
  }

  const onClick = (event) => {
    if (hasChildren) {
      selectCN8TreeBranch(node.model.code, node.model.cn8Code, event)
    } else if (cn8Code && cn8Code.length === 8) {
      selectCN8TreeLeaf(node.model.cn8Code, event)
    }
  }

  let icon
  if (loading) {
    icon = <Loader tiny inline />
  } else if (hasChildren) {
    icon = <img src={expanded ? ExpandedIcon : CollapsedIcon} alt="" />
  }

  const oddClass = oddRow ? 'odd' : 'even'
  const activeClass = active ? 'cn8-active' : ''
  const selectedClass = selected ? 'cn8-selected' : ''
  const levelClass = `cn8-level-${level}`
  const errorClass = loadError ? 'error' : ''
  const expandedClass = expanded ? 'expanded' : 'collapsed'
  const completeCN8Class = cn8Code && cn8Code.length === 8 ? 'complete-cn8' : ''
  const selectableRow = completeCN8Class || hasChildren ? 'selectable-row' : ''

  const rowClassNames = classNames(
    'cn8-row',
    activeClass,
    selectedClass,
    levelClass,
    errorClass,
    oddClass,
    expandedClass,
    completeCN8Class,
    selectableRow
  )

  const interactiveElementProps = {
    role: !hasChildren ? 'button' : 'treeitem',
    'aria-level': level - 1,
    className: rowClassNames,
    onClick,
    'id-qa-test': `btn-cn8-row-${node.model.code}`,
    id: `cn8-tree-node-${node.model.code}`,
  }

  if (hasChildren) {
    interactiveElementProps['aria-expanded'] = expanded
  }

  return (
    <div className="cn8-node">
      {level > 1 &&
        <InteractiveElement
          {...interactiveElementProps}
        >
          <div className="cn8-meta">
            <span className="cn8-icon">{icon}</span>
            <span className="cn8-code">{node.model.cn8Code ? node.model.cn8Code : '· · ·'}</span>
          </div>
          <div className="cn8-description">{node.model.description}</div>
        </InteractiveElement>
      }
      {hasChildren && expanded &&
        <div className="cn8-children" role="group">{children}</div>}
    </div>
  )
}
