import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { injectIntl } from 'react-intl'
import { get } from 'lodash'
import RepresentativeInformation from '../components/RepresentativeInformation'
import { getRepresentative } from '../../../routes/permits/permit/permitHelper'

const mapStateToProps = (state) => {
  const customer = get(state, 'customer')
  const representative = getRepresentative()
  return {
    customer,
    representative,
  }
}

export default withRouter(
  connect(mapStateToProps)(
    injectIntl(
      RepresentativeInformation
    )))
