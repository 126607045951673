/**
 * React root component wrapping store, intl, router etc
 * @module containers/Root
 */

import { LANGUAGE_NATION } from 'src/constants'
import React from 'react'
import { Provider, connect } from 'react-redux'
import { IntlProvider } from 'react-intl'
import { get } from 'lodash'
import { BrowserRouter as Router } from 'react-router-dom'

import * as i18n from '../i18n'
import Snoobi from '../components/Snoobi'
import App from './App'

function Root({
  store,
  locale,
  snoobiAccount,
  cmsMessages,
  cmsContentLoaded,
}) {

  document.documentElement.lang = `${locale}-${LANGUAGE_NATION[locale]}`

  const allMessages = Object.assign({}, i18n[locale], cmsMessages)

  if (!cmsContentLoaded) {
    return null
  }

  return (
    <>
      <Snoobi key="snoobi" account={snoobiAccount} />
      <Provider key="main" store={store}>
        <IntlProvider locale={locale} messages={allMessages}>
          <Router basename={BASENAME}>
            <App />
          </Router>
        </IntlProvider>
      </Provider>
    </>
  )
}

const mapStateToProps = (state) => {
  const cmsMessages = get(state, 'content.cmsMessages')

  return {
    locale: state.locale,
    cmsContentLoaded: get(state, 'content.cmsContentLoaded'),
    cmsMessages,
    snoobiAccount: get(state.config, 'bootstrapConfig.nakki_snoobi_account'),
  }
}

export default connect(mapStateToProps, null)(Root)
