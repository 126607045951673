import React, { useEffect } from 'react'
import { get, isEmpty } from 'lodash'
import { PATH_TO_TITLE_MAP, RETURN_URI_QUERY_PARAM } from 'src/constants'
import { defineMessages } from 'react-intl'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { collectCmsMessages, focusFirstElementByIdOrName } from 'src/utils'
import { Form } from 'react-form'
import CommonFormGroup from 'src/components/form_v2/CommonFormGroup'
import Modal from './Modal'
import { formatSelectedAuthorizationName, getAllDelegateIds, setPageTitle } from '../utils/index'
import { changeReturnUriAfterRoleSwitch } from '../actions'

// Header-related intl message definitions
const messages = defineMessages({
  title: {
    id: 'roleSelector.title',
    description: 'Title for role selection modal',
    defaultMessage: 'Role selection',
  },
  continue: {
    id: 'roleSelector.continue',
    description: 'Button value for role selection operation "continue"',
    defaultMessage: 'Continue',
  },
  cancel: {
    id: 'roleSelector.cancel',
    description: 'Button value for role selector cancel operation (resulting in logout)',
    defaultMessage: 'Logout',
  },
})

let formApi

function RoleSelector(props) {

  const {
    authorizations,
    onRejected,
    locale,
    onSwitchLocale,
    children,
    content,
    onSelect,
    returnUriAfterRoleSwitch,
    history,
    dropdownMinItemCount,
    setReturnUriAfterRoleSwitch,
  } = props

  useEffect(() => {
    setPageTitle(PATH_TO_TITLE_MAP, RETURN_URI_QUERY_PARAM, locale)
  }, [])

  useEffect(() => {
    history.listen(() => {
      // This is to handle back button click
      if (history.action === 'POP') {
        setReturnUriAfterRoleSwitch(null)
      }
    })
  }, [history])

  useEffect(() => {
    setPageTitle(PATH_TO_TITLE_MAP, RETURN_URI_QUERY_PARAM, locale)
  }, [locale])

  const onClick = () => {
    formApi.submitForm().then(() => {
      const errors = get(formApi.getFormState(), 'errors', {})

      if (errors) {
        focusFirstElementByIdOrName(Object.keys(errors))
      }
    })
  }

  const connectFormApi = (formApiConnect) => {
    formApi = formApiConnect
  }

  const delegateCompanyLabel = get(content, 'delegateCompanyLabel')
  const showDropdown = authorizations.length >= dropdownMinItemCount

  return (
    <div>
      <Modal
        show
        titleMessage={messages.title}
        cancelMessage={messages.cancel}
        showCancel
        onClickCancel={onRejected}
        continueMessage={messages.continue}
        onClickContinue={onClick}
        showContinue
        locale={locale}
        onSwitchLocale={onSwitchLocale}
        bodyStyle={{ paddingTop: '25px' }}
        disableEscKey
        focusLocaleSwitch
      >
        <Form
          getApi={connectFormApi}
          onSubmit={() => {
            const formState = formApi.getFormState()
            const authorization = get(formState, 'values.authorization')
            const parsedAuthorization = JSON.parse(authorization)
            const { id, delegateCompany } = parsedAuthorization

            const selectedAuthorization = authorizations.find(auth => auth.id === id)
            const delegateCompanies = get(selectedAuthorization, 'delegateCompanies', [])
            const selectedDelegateCompany = delegateCompanies.find(auth => auth.id === delegateCompany)
            onSelect({
              identifier: selectedAuthorization.identifier,
              delegateCompany: selectedDelegateCompany ? selectedDelegateCompany.identifier : undefined,
            }, returnUriAfterRoleSwitch, history)
          }}
          render={formApi => (
            <form>
              <CommonFormGroup
                errors={formApi.errors}
                formApi={formApi}
                formGroupClassname="formElementGutter"
                formName="RoleSelector"
                input={{
                  name: 'authorization',
                  static: false,
                  type: showDropdown ? 'select' : 'radioListWithField',
                  placeholder: showDropdown ? get(content, 'roleSelectorDropdownPlaceholder') : undefined,
                  validate: true,
                  validation: {
                    mandatory: true,
                  },
                  visible: true,
                  multilineLabel: true,
                }}
                label={get(content, 'roleSelectorBodyText')}
                options={authorizations
                  .flatMap((authorization) => {
                    const delegateCompanies = get(authorization, 'delegateCompanies')
                    if (!isEmpty(delegateCompanies)) {
                      return getAllDelegateIds(authorization, delegateCompanies, delegateCompanyLabel)
                    }
                    return {
                      title: formatSelectedAuthorizationName(authorization),
                      value: JSON.stringify({
                        id: authorization.id,
                      }),
                    }
                  })
                }
                showLabel
                type="BOOLEAN"
              />
            </form>
          )}
        />
      </Modal>
      <div id="dialog-message">
        {children}
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  const cmsMessages = get(state, 'content.cmsMessages', {})
  const authenticationContent = collectCmsMessages('/authentication', cmsMessages)

  return {
    content: authenticationContent,
    locale: state.locale,
    dropdownMinItemCount: get(state.config, 'bootstrapConfig.role_selector_dropdown_min_item_count', 10),
  }
}

const mapActionCreators = {
  setReturnUriAfterRoleSwitch: changeReturnUriAfterRoleSwitch,
}

export default withRouter(connect(mapStateToProps, mapActionCreators)(RoleSelector))
