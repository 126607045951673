import React from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { Button, FormGroup, Col, Row } from 'react-bootstrap'
import CN8Browser from 'src/components/cn8/CN8Browser'
import LabelArea from 'src/components/form/LabelArea'
import InputArea from 'src/components/form/InputArea'
import { getValidationState } from 'src/utils/validation'
import messages from '../messages'
import { CN8_ROOT_NODE_ID } from '../constants'

function isProperCN8(value) {
  return /[0-9]{8}/.test(value)
}

/**
 * This component:
 *  1. Renders form group containing text input that is not connected to Redux Form
 *  2. Updates CN8 code to actual Redux Form field on proper changes
 *  3. Controls CN8Browser open/hide states
 */
class RowFormCN8Field extends React.Component {
  constructor(props) {
    super(props)
    this.showCN8Browser = this.showCN8Browser.bind(this)
    this.onCN8SearchInputChange = this.onCN8SearchInputChange.bind(this)
    this.selectCN8Code = this.selectCN8Code.bind(this)
    this.clearSelectedCN8Code = this.clearSelectedCN8Code.bind(this)
    this.hideCN8Browser = this.hideCN8Browser.bind(this)
    this.state = {
      cn8Search: this.props.input.value || '',
      isCN8BrowserOpen: false,
      isBrowseButtonFocused: false,
      isCN8SearchInputFocused: false,
    }
    this.focusCN8Input = this.focusCN8Input.bind(this)
  }

  componentDidMount() {
    const { input, referencePeriod, fetchCN8Code, getCN8Code } = this.props
    if (input.value && isProperCN8(input.value) && !getCN8Code(input.value)) {
      fetchCN8Code(input.value, referencePeriod)
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.input.value && nextProps.input.value !== this.state.cn8Search) {
      this.setState({ cn8Search: nextProps.input.value })
    }
  }

  componentDidUpdate(prevProps) {
    const { input, referencePeriod, fetchCN8Code, getCN8Code } = this.props
    if (prevProps.input.value !== input.value && isProperCN8(input.value) && !getCN8Code(input.value)) {
      fetchCN8Code(input.value, referencePeriod)
    }
    if (this.state.isCN8SearchInputFocused) {
      setTimeout(() => {
        const element = document.getElementById('CN8Code')
        element.focus()
      }, 5)
      if (this.state.isCN8SearchInputFocused) {
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({ isCN8SearchInputFocused: false })
      }
    }
  }

  onCN8SearchInputChange(event) {
    const { target: { value } } = event
    if (value !== this.state.cn8Search) {
      this.setState({ cn8Search: value })
      if (isProperCN8(value)) {
        this.selectCN8Code(value)
      }
    }
  }

  showCN8Browser() {
    this.setState({
      isCN8BrowserOpen: true,
    })
    this.props.setShouldScrollToCN8(true)
    this.props.removeFirstFieldFocus()
  }

  hideCN8Browser() {
    this.setState({
      isCN8BrowserOpen: false,
    })
    this.props.setShouldScrollToCN8(false)
    document.getElementById('showCN8BrowserButton').focus()
  }

  focusCN8Input() {
    if (this.cn8SearchInputRef) {
      this.cn8SearchInputRef.focus()
    }
  }

  selectCN8Code(cn8Code) {
    this.setState({ cn8Search: cn8Code })
    this.props.input.onChange(cn8Code)
  }

  clearSelectedCN8Code() {
    this.setState({ cn8Search: '' })
    this.props.input.onChange('')
    this.cn8SearchInputRef.focus()
  }

  render() {
    const {
      cn8Trees,
      cn8Locale,
      cn8SearchResults,
      fetchCN8Code,
      fetchingCN8Tree,
      searchCN8Text,
      clearCN8SearchResults,
      selectCN8TreeNode,
      fetchCN8TreeRoot,
      input,
      disabled,
      placeholderMessage,
      getCN8Code,
      fetchingCN8,
      searchingCN8Text,
      referencePeriod,
      meta,
      labelMessage,
      helpText,
      mandatory,
      forceValidation,
      intl,
      scrollToCN8InProgress,
      modalRef,
      intl: { formatMessage },
    } = this.props

    const {
      isCN8BrowserOpen,
    } = this.state

    const showCN8Browser = isCN8BrowserOpen && !scrollToCN8InProgress
    const cn8Details = isProperCN8(input.value) ? getCN8Code(input.value) : null

    return (
      <div className="cn8-row-container">
        <FormGroup
          controlId={input.name}
          className="clearfix"
          validationState={getValidationState(this.props, !forceValidation)}
        >
          <LabelArea
            input={input}
            help={{
              content: helpText,
            }}
            mandatory={mandatory}
            disabled={disabled}
            label={formatMessage(labelMessage)}
          />
          <Row>
            <Col xs={disabled ? 12 : 8} md={disabled ? 12 : 9}>
              <div ref={(ref) => { this.inputContainerRef = ref }}>
                <InputArea
                  {...this.props}
                  id="CN8Code"
                  name="CN8Code"
                  loading={fetchingCN8}
                  showFeedback={false}
                  onClear={input.value && this.clearSelectedCN8Code}
                  inputRef={(ref) => { this.cn8SearchInputRef = ref }}
                  componentClass="input"
                  type="text"
                  disabled={disabled || false}
                  placeholderMessage={placeholderMessage}
                  onKeyUp={event => (event.keyCode === 13) && this.showCN8Browser()}
                  ariaRequired="true"
                />
              </div>
            </Col>
            {!disabled &&
              <Col xs={4} md={3}>
                <Button
                  id="showCN8BrowserButton"
                  bsStyle="primary"
                  onClick={this.showCN8Browser}
                  disabled={showCN8Browser}
                  block
                  id-qa-test="btn-browse-cn8"
                  aria-label={intl.formatMessage(messages.CN8BrowseButtonDescription)}
                >
                  <FormattedMessage {...messages.CN8BrowseButtonLabel} />
                </Button>
              </Col>
            }
          </Row>
        </FormGroup>
        <CN8Browser
          ref={(ref) => { this.cn8BrowserRef = ref }}
          open={isCN8BrowserOpen}
          hideCN8Browser={this.hideCN8Browser}
          cn8Trees={cn8Trees}
          cn8Locale={cn8Locale}
          fetchingCN8Tree={fetchingCN8Tree}
          cn8SearchResults={cn8SearchResults}
          searchCN8Text={searchCN8Text}
          clearCN8SearchResults={clearCN8SearchResults}
          searchingCN8Text={searchingCN8Text}
          referencePeriod={referencePeriod}
          fetchCN8Code={fetchCN8Code}
          fetchCN8TreeRoot={fetchCN8TreeRoot}
          selectCN8TreeNode={selectCN8TreeNode}
          setCN8Value={this.selectCN8Code}
          cn8InputValue={input.value}
          focusCN8Input={this.focusCN8Input}
          cn8RootNodeId={CN8_ROOT_NODE_ID}
          intl={intl}
          placement="bottom"
          useOverlay
          overlayTarget={this.inputContainerRef}
          overlayContainer={modalRef}
        />
        {cn8Details && !meta.invalid &&
          <div style={{ marginTop: '-15px', marginBottom: '20px' }}>
            <span className="help-block">{cn8Details.selfExplanatoryText}</span>
          </div>
        }
      </div>
    )
  }
}

export default injectIntl(RowFormCN8Field)
