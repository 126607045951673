import { PERMIT_USER_TYPES, selectedAuthorizationHasSomeRole } from 'src/utils/auth'
import {
  PERMIT_APPLICATION_STATUS_DRAFT,
  PERMIT_APPLICATION_STATUS_ACCEPTED,
  PERMIT_APPLICATION_STATUS_FAVOURABLE,
  PERMIT_APPLICATION_STATUS_NON_FAVOURABLE,
  PERMIT_APPLICATION_STATUS_NOT_ACCEPTED,
  PERMIT_APPLICATION_STATUS_REGISTERED,
  PERMIT_APPLICATION_STATUS_VERIFICATION,
  PERMIT_APPLICATION_STATUS_WITHDRAWN,
} from '../constants'

export function userHasAdminOrHandlerAuthorizations() {
  return selectedAuthorizationHasSomeRole([PERMIT_USER_TYPES.admin, PERMIT_USER_TYPES.handler])
}

export function isApplication(state) {
  return [
    PERMIT_APPLICATION_STATUS_DRAFT,
    PERMIT_APPLICATION_STATUS_REGISTERED,
    PERMIT_APPLICATION_STATUS_VERIFICATION,
    PERMIT_APPLICATION_STATUS_ACCEPTED,
    PERMIT_APPLICATION_STATUS_NOT_ACCEPTED,
    PERMIT_APPLICATION_STATUS_FAVOURABLE,
    PERMIT_APPLICATION_STATUS_NON_FAVOURABLE,
    PERMIT_APPLICATION_STATUS_WITHDRAWN,
  ].includes(state)
}

export function isDateAfterToday(date) {
  return new Date(date.toDateString()) >= new Date(new Date().toDateString())
}

