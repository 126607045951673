import React from 'react'
import { AutoAffix } from 'react-overlays'
import { get, findIndex } from 'lodash'
import { Col, Row } from 'react-bootstrap'
import { useParams } from 'react-router-dom'

import StepNavigation from './StepNavigation'

export default function PermitDraftNavigationLayout({ children, formatMessage, structure, locale, container }) {
  const groups = get(structure, 'groups')
  const params = useParams()
  const { pathId } = params
  const currentNavIndex = findIndex(groups, g => g.code === pathId)

  return (
    <Row>
      <Col md={3} lg={3} sm={4}>
        <AutoAffix viewportOffsetTop={20} container={container}>
          <StepNavigation
            formatMessage={formatMessage}
            structure={structure}
            currentNavIndex={currentNavIndex}
            locale={locale}
            params={params}
          />
        </AutoAffix>
      </Col>
      <Col md={9} lg={9} sm={8}>
        {children}
      </Col>
    </Row>
  )
}
