// Redux forms
export const PERMITS_FORM_NAME = 'permits'

// Error codes
export const ERROR_CODE_PERMIT_EXISTS = 'permit.exists'

// Route paths
export const INIT_ROUTE_PATH = 'init'
export const SUMMARY_ROUTE_PATH = 'summary'

// Action types
export const PERMIT_SELECTED = '@@nakki/permits/PERMIT_SELECTED'
export const DESTROY_PERMIT_STATE = '@@nakki/permits/DESTROY_PERMIT_STATE'
export const PERMIT_STRUCTURE_FETCHING = '@@nakki/permits/PERMIT_STRUCTURE_FETCHING'
export const PERMIT_STRUCTURE_FETCHED = '@@nakki/permits/PERMIT_STRUCTURE_FETCHED'
export const PERMIT_DATA_FETCHING = '@@nakki/permits/PERMIT_DATA_FETCHING'
export const PERMIT_DATA_FETCHED = '@@nakki/permits/PERMIT_DATA_FETCHED'
export const PERMIT_DATA_SAVED = '@@nakki/permits/PERMIT_DATA_SAVED'
export const APPLICATION_ATTACHMENT_UPLOADING = '@@nakki/permits/APPLICATION_ATTACHMENT_UPLOADING'
export const APPLICATION_ATTACHMENT_UPLOADED = '@@nakki/permits/APPLICATION_ATTACHMENT_UPLOADED'
export const APPLICATION_ATTACHMENT_IN_PROGRESS = '@@nakki/permits/APPLICATION_ATTACHMENT_IN_PROGRESS'
export const SAVE_INITIAL_VALUES = '@@nakki/permits/SAVE_INITIAL_VALUES'

// Application types
export const APPLICATION_TYPE_NEW = 1
export const APPLICATION_TYPE_AMENDMENT = 2
export const APPLICATION_TYPE_RENEW = 3
export const APPLICATION_TYPE_REVOCATION = 4
export const APPLICATION_TYPE_REJOINDER = 5
export const APPLICATION_TYPE_NEW_ALIAS = 'NEW'
export const APPLICATION_TYPE_AMENDMENT_ALIAS = 'AMEND'
export const APPLICATION_TYPE_RENEW_ALIAS = 'RENEW'
export const APPLICATION_TYPE_REVOCATION_ALIAS = 'REVOCATION'
export const APPLICATION_TYPE_REJOINDER_ALIAS = 'REJOINDER'
export const APPLICATION_TYPE_MAP = {
  [APPLICATION_TYPE_NEW]: APPLICATION_TYPE_NEW_ALIAS,
  [APPLICATION_TYPE_AMENDMENT]: APPLICATION_TYPE_AMENDMENT_ALIAS,
  [APPLICATION_TYPE_RENEW]: APPLICATION_TYPE_RENEW_ALIAS,
  [APPLICATION_TYPE_REVOCATION]: APPLICATION_TYPE_REVOCATION_ALIAS,
  [APPLICATION_TYPE_REJOINDER]: APPLICATION_TYPE_REJOINDER_ALIAS,
}
export const applicationTypeHelpTexts = {

  [APPLICATION_TYPE_AMENDMENT_ALIAS]: {
    growlErrorMessage: 'amendDecisionError',
    draftErrorModalBody: 'amendDecisionDraftExistsModalBody',
    applicationErrorModalBody: 'amendDecisionApplicationExistsModalBody',
    errorModalTitle: 'amendDecisionModalTitle',
  },
  [APPLICATION_TYPE_REVOCATION_ALIAS]: {
    growlErrorMessage: 'revokeDecisionError',
    draftErrorModalBody: 'revokeDecisionDraftExistsModalBody',
    applicationErrorModalBody: 'revokeDecisionApplicationExistsModalBody',
    errorModalTitle: 'revokeDecisionModalTitle',
  },
  [APPLICATION_TYPE_RENEW_ALIAS]: {
    growlErrorMessage: 'renewDecisionError',
    draftErrorModalBody: 'renewDecisionDraftExistsModalBody',
    applicationErrorModalBody: 'renewDecisionApplicationExistsModalBody',
    errorModalTitle: 'renewDecisionModalTitle',
  },
  [APPLICATION_TYPE_REJOINDER_ALIAS]: {
    growlErrorMessage: 'replyDecisionError',
    draftErrorModalBody: 'replyDecisionDraftExistsModalBody',
    applicationErrorModalBody: 'replyDecisionApplicationExistsModalBody',
    errorModalTitle: 'replyDecisionModalTitle',
  },
}

export const APPLICATION_DRAFT_ALREADY_EXISTS = 'APPLICATION_DRAFT_ALREADY_EXISTS'
export const APPLICATION_ALREADY_EXISTS = 'APPLICATION_ALREADY_EXISTS'

export const PERMIT_GROUP_GUARANTEE = 'GUARANTEE'
export const PERMIT_GROUP_SIMPLIFICATION = 'SIMPLIFICATION'
export const PERMIT_GROUP_TRANSPORT_WAREHOUSING = 'TRANSPORT_WAREHOUSING'
export const PERMIT_GROUP_SPECIAL_PROCEDURES = 'SPECIAL_PROCEDURES'
export const PERMIT_GROUP_OTHER = 'OTHER'
export const PERMIT_GROUP_RELIEF = 'RELIEF'


export const OPO = 'OPO'
export const EUS = 'EUS'
export const CWP = 'CWP'
export const CW1 = 'CW1'
export const CW2 = 'CW2'
export const TAT = 'TAT'
export const TAP = 'TAP'
export const SSA = 'SSA'
export const RVA = 'RVA'
export const VYE = 'VYE'

export const PERSON_ALLOWED_PERMIT_TYPES = [TAT, TAP]
export const HIDE_AEO_PERMIT_TYPES = [RVA, VYE]
export const PERMITS_TO_HIDE_KILO_DESCRIPTION = [...PERSON_ALLOWED_PERMIT_TYPES, 'SSA', 'KUM']

// Field name for the same as customs responsible radio
export const APPLICANT_CUSTOMS_RESPONSIBLE_RADIO_NAME = 'checkboxSameAsCustomsResponsibleRadio'

export const WAREHOUSE_TYPE_CODES = [
  CWP,
  CW1,
  CW2,
]

export const VVI = 'VVI'

// Permit type codes
export const PERMIT_TYPE_CODE_CGU = 'CGU'

export const PERMIT_TYPE_CODE_PPP = 'PPP'

// Permit type groups
export const PERMIT_TYPE_GROUPS = {
  CGU: PERMIT_GROUP_GUARANTEE,
  DPO: PERMIT_GROUP_GUARANTEE,
  AWB: PERMIT_GROUP_SIMPLIFICATION,
  CVA: PERMIT_GROUP_SIMPLIFICATION,
  EIA: PERMIT_GROUP_SIMPLIFICATION,
  EIR: PERMIT_GROUP_SIMPLIFICATION,
  SDE: PERMIT_GROUP_SIMPLIFICATION,
  CW1: PERMIT_GROUP_TRANSPORT_WAREHOUSING,
  CW2: PERMIT_GROUP_TRANSPORT_WAREHOUSING,
  CWP: PERMIT_GROUP_TRANSPORT_WAREHOUSING,
  TST: PERMIT_GROUP_TRANSPORT_WAREHOUSING,
  ACR: PERMIT_GROUP_TRANSPORT_WAREHOUSING,
  ACE: PERMIT_GROUP_TRANSPORT_WAREHOUSING,
  ACT: PERMIT_GROUP_TRANSPORT_WAREHOUSING,
  ACP: PERMIT_GROUP_TRANSPORT_WAREHOUSING,
  SSE: PERMIT_GROUP_TRANSPORT_WAREHOUSING,
  HPA: PERMIT_GROUP_TRANSPORT_WAREHOUSING,
  IPO: PERMIT_GROUP_SPECIAL_PROCEDURES,
  OPO: PERMIT_GROUP_SPECIAL_PROCEDURES,
  EUS: PERMIT_GROUP_SPECIAL_PROCEDURES,
  TEA: PERMIT_GROUP_SPECIAL_PROCEDURES,
  TAT: PERMIT_GROUP_RELIEF,
  TAP: PERMIT_GROUP_RELIEF,
  PPP: PERMIT_GROUP_OTHER,
  VVI: PERMIT_GROUP_OTHER,
  SSA: PERMIT_GROUP_OTHER,
  RVA: PERMIT_GROUP_OTHER,
  VYE: PERMIT_GROUP_OTHER,
}

// Codeset field types
export const PERMIT_FIELD_TYPES_AS_CODESETS = [
  'CODESET',
  'COUNTRY_CODE',
  'CURRENCY',
  'GUARANTEE_LEVEL',
  'CUSTOMSOFFICE_REGISTRY',
  'CODESET_AUTOCOMPLETE',
]

// Codeset filtered values
export const PERMIT_DEBT_GUARANTEE_PROCEDURE_TYPES = ['01', '07', '40', '42', '45', '49', '61', '63', '68']
export const PERMIT_LIABILITY_GUARANTEE_PROCEDURE_TYPES =
  ['44', '46', '48', '51', '53', '71', '80', 'XR', 'XS', 'XU', 'XX']
export const PERMIT_DEBT_GUARANTEE_LEVELS = ['AA', 'AB']
export const DEFAULT_PERMIT_LIABILITY_GUARANTEE_LEVEL = 'BA'
export const PERMIT_LIABILITY_GUARANTEE_LEVELS = ['BA', 'BB', 'BC', 'BD']
export const POPULAR_COUNTRY_CODES = ['FI', 'SE', 'GB', 'DK'] // In desired order
export const POPULAR_CURRENCIES = ['EUR', 'GBP', 'USD']

// Customs debt and liability procedure types visibility by application type
export const PERMIT_GUARANTEE_PROCEDURE_TYPE_ALL_VISIBLE = ['EIA', 'EIR', 'SDE']
export const PERMIT_DEBT_GUARANTEE_PROCEDURE_TYPE_SELECTION_VISIBLE = ['CGU', 'DPO']
export const PERMIT_LIABILITY_GUARANTEE_PROCEDURE_TYPE_SELECTION_VISIBLE = ['CGU']

export const PERMIT_BOOLEAN_CHECKBOX_FIELDS = [
  'MessageProvider',
  'MessageProviderDeclarant',
]


// Full featured goodsToBeUnderProcedure or equivalentGood component for specific permit types
export const PERMIT_GOODS_COMBINED_IN = ['CW1', 'CW2', 'CWP', 'IPO', 'TEA', 'EUS', 'OPO']

// Amount (and Currency) and Quantity (and MeasurementUnit) are optionals for CWP, CW1 and CW2
export const PERMIT_GOODS_CW_NOT_MANDATORY_IN = ['CW1', 'CW2', 'CWP']

// TODO: this does not match documentation.
// The documentation say it's only mandatory in EIR, IPO, OPO and TEA
// Which one is wrong?
export const PERMIT_GOODS_TARIC_CODE_MANDATORY_IN = ['IPO', 'TEA', 'OPO', 'EIR', 'PPP', 'CVA', 'EIA']
export const PERMIT_GOODS_UNDER_PROCEDURE_QUANTITY_FEATURED = ['PPP']
export const PERMIT_GOODS_UNDER_PROCEDURE_FULL_FEATURED = ['CWP', 'CW1', 'CW2', 'TST', 'IPO', 'OPO', 'EUS', 'TEA']
export const PERMIT_EQUIVALENT_GOOD_FULL_FEATURED = ['CW1', 'CW2', 'CWP']
export const PERMIT_PROCESSED_PRODUCT_FULL_FEATURED = []
export const PERMIT_REPLACEMENT_PRODUCT_FULL_FEATURED = ['OPO']
export const PERMIT_ORIGINATING_PRODUCT_FULL_FEATURED = ['VVI']

// Application types that should show the KILO info area on summary page
export const PERMIT_GROUP_KILO_DESCRIPTION =
  ['CWP', 'CW1', 'CW2', 'TST', 'IPO', 'OPO', 'EUS', 'TEA', 'ACR', 'ACE', 'ACT', 'SSE', 'HPA', 'CGU', 'SDE', 'EIR', 'CVA', 'EIA']

// Value of guaranteeForm
export const GUARANTOR_CODE_UNDERTAKING = '2'

export const PERMIT_GROUP_GOODS_INFOELEMENTS = ['IPO', 'OPO']

// Permit types that can be renewed
export const PERMIT_TYPES_ALLOW_RENEW = ['IPO', 'OPO', 'EUS', 'TEA']

export const PERMIT_TYPES_REQUIRING_TERMS_ACCEPTANGE = ['SSA', 'TAT']

export const PERMIT_TYPES_HIDE_BUSINESS_ID_SEARCH_BUTTON = ['SSA2']

// Defines infoElement groups and its infoElements
export const infoElementGroups = {
  goods: [
    'goodsToBePlacedUnderProcedure',
    'yieldRate',
    'equivalentGood',
    'goodsIdentification',
    'economicConditions',
  ],
}

// Permit types sorting
export const PERMIT_TYPES_SORT_ORDER = {
  ACR: 1,
  SSE: 2,
  ACE: 3,
  ACT: 4,
  ACP: 5,
  TST: 6,
  CWP: 7,
  CW1: 8,
  CW2: 9,
}

// InfoElement sorting
export const PERMIT_INFOELEMENT_SORT_ORDER = {
  authorizationOrDecisionHolder: 1,
  representative: 2,
  representativeDetails: 3,
  applicationContactPerson: 4,
  concentToSendingDecisionElectronically: 5,
  applicantCustomsResponsible: 6,
  accountsLocation: 7,
  accountsType: 8,
  recordsLocation: 9,
  recordsType: 10,
  customsProcedureType: 11,
  operationCount: 12,
  goodsToBePlacedUnderProcedure: 13,
  prohibitionsAndRestrictions: 14,
  goodsLocation: 15,
  attachedDocument: 16,
  additionalInformation: 17,
  unionGoodsStorage: 18,
  storageFacilityDetails: 18,
  temporaryRemoval: 20,
  lossRate: 21,
  transferOfRightsAndObligations: 22,
  guaranteeCustomsOffice: 23,
  guarantee: 24,
  guaranteeIsRequired: 25,
}

// Fields sorting
export const PERMIT_FIELD_SORT_ORDER = {
  'applicantCustomsResponsible.applicantCustomsResponsibleName': 1,
  'applicantCustomsResponsible.applicantCustomsResponsiblePhone': 2,
  'applicantCustomsResponsible.applicantCustomResponsibleEmail': 3,
  'applicationContactPerson.applicationContactPersonName': 1,
  'applicationContactPerson.applicationContactPersonPhone': 2,
  'applicationContactPerson.applicationContactPersonEmail': 3,
  'representativeDetails.representativeName': 1,
  'representativeDetails.representativeAddress': 2,
  'representativeDetails.representativePostalCode': 3,
  'representativeDetails.representativeCity': 4,
  'representativeDetails.representativeCountryCode': 5,
  'accountsLocation.accountsLocationAddress': 1,
  'accountsLocation.accountsLocationPostalCode': 2,
  'accountsLocation.accountsLocationCity': 3,
  'accountsLocation.accountsLocationCountryCode': 4,
  'recordsLocation.recordsLocationAddress': 1,
  'recordsLocation.recordsLocationPostalCode': 2,
  'recordsLocation.recordsLocationCity': 3,
  'recordsLocation.recordsLocationCountryCode': 4,
  goodsToBePlacedUnderProcedureCombinedNomenclatureAndTaricCode: 1,
  goodsToBePlacedUnderProcedureDescription: 2,
  goodsToBePlacedUnderProcedureQuantity: 3,
  goodsToBePlacedUnderProcedureMeasurementUnit: 4,
  goodsToBePlacedUnderProcedureAmount: 5,
  goodsToBePlacedUnderProcedureCurrency: 6,
  equivalentGoodCombinedNomenclatureCode: 1,
  equivalentGoodDescription: 2,
  equivalentGoodQuantity: 3,
  equivalentGoodMeasurementUnit: 4,
  equivalentGoodAmount: 5,
  equivalentGoodCurrency: 6,
  goodsLocationIdentification: 1,
  goodsLocationCountryCode: 2,
  goodsLocationTypeCode: 3,
  goodsLocationIdentificationQualifier: 4,
  goodsLocationAdditionalIdentifier: 5,
  goodsMovementLegalBaseCode: 1,
  goodsMovementEori: 2,
  goodsMovementLocationIdentification: 3,
  goodsMovementCountryCode: 4,
  goodsMovementLocationType: 5,
  goodsMovementIdentificationQualifier: 6,
  goodsMovementAdditionalIdentifier: 7,
  processingOrUsePlaceIdentification: 1,
  processingOrUsePlaceCountryCode: 2,
  processingOrUsePlaceCodeType: 3,
  processingOrUsePlaceIdentificationQualifier: 4,
  processingOrUsePlaceAdditionalIdentifier: 5,
  'firstProcessingOrUsePlace.firstProcessingOrUsePlaceIdentification': 1,
  'firstProcessingOrUsePlace.firstProcessingOrUsePlaceCountryCode': 2,
  'firstProcessingOrUsePlace.firstProcessingOrUsePlaceCodeType': 3,
  'firstProcessingOrUsePlace.firstProcessingOrUsePlaceIdentificationQualifier': 4,
  'firstProcessingOrUsePlace.firstProcessingOrUsePlaceAdditionalIdentifier': 5,
  goodsOwnerName: 1,
  goodsOwnerAddress: 2,
  goodsOwnerPostalCode: 3,
  goodsOwnerCity: 4,
  goodsOwnerCountryCode: 5,
}

const NOT_FOUND_YJA_FIELD = 'notFound'
const REQUIRED_YJA_FIELD = 'requiredError'
const TOO_LONG_YJA_FIELD = 'errorTooLong'
const TOO_SHORT_YJA_FIELD = 'errorTooShort'
const INCORRECT_NUMBER_YJA_FIELD = 'errorNumberIncorrect'
const INCORRECT_EMAIL_YJA_FIELD = 'errorEmailIncorrect'
const INVALID_COUNTRY_CODE_YJA_FIELD = 'errorCustomerRegistryInvalidCountryCode'
const CUSTOMER_NOT_FOUND_YJA_FIELD = 'errorCustomerRegistryEntryNotFound'
const CUSTOMER_DATA_MISSING_YJA_FIELD = 'errorCustomerRegistryMandatoryDataMissing'
const WAREHOUSE_INVALID_WAREHOUSE_CODE_YJA_FIELD = 'errorWarehouseInvalidWarehouseCode'
const TARIC_CONTAINS_WHITESPACE_YJA_FIELD = 'errorTaricContainsWhitespace'
const TARIC_NOT_FOUND_YJA_FIELD = 'errorTaricEntryNotFound'
const CUSTOMS_OFFICE_NOT_FOUND_YJA_FIELD = 'errorCustomsOfficeRegistryEntryNotFound'
const INVALID_REFERENCE_NUMBER_YJA_FIELD = 'errorReferenceNumberInvalid'
const REFERENCE_NUMBER_TOO_BIG_YJA_FIELD = 'errorReferenceNumberTooBig'
const REFERENCE_NUMBER_NOT_UNIQUE_YJA_FIELD = 'errorReferenceNumberNotUnique'
const REFERENCE_NUMBER_CHANGED_YJA_FIELD = 'errorReferenceNumberChanged'
const LOCATION_TOO_MANY_LINES_YJA_FIELD = 'errorLocationLocationHasTooManyLines'
const CODE_NOT_FOUND_YJA_FIELD = 'errorCodesetNotFound'
const INVALID_DATE_YJA_FIELD = 'errorDateIncorrect'

export const ERROR_TYPES = {
  NOT_FOUND: NOT_FOUND_YJA_FIELD,
  REQUIRED: REQUIRED_YJA_FIELD,
  TOO_LONG: TOO_LONG_YJA_FIELD,
  TOO_SHORT: TOO_SHORT_YJA_FIELD,
  INCORRECT_NUMBER: INCORRECT_NUMBER_YJA_FIELD,
  INCORRECT_EMAIL: INCORRECT_EMAIL_YJA_FIELD,
  INVALID_EMAIL: INCORRECT_EMAIL_YJA_FIELD,
  INVALID_COUNTRY_CODE: INVALID_COUNTRY_CODE_YJA_FIELD,
  CUSTOMER_NOT_FOUND: CUSTOMER_NOT_FOUND_YJA_FIELD,
  CUSTOMER_DATA_MISSING: CUSTOMER_DATA_MISSING_YJA_FIELD,
  WAREHOUSE_INVALID_WAREHOUSE_CODE: WAREHOUSE_INVALID_WAREHOUSE_CODE_YJA_FIELD,
  TARIC_CONTAINS_WHITESPACE: TARIC_CONTAINS_WHITESPACE_YJA_FIELD,
  TARIC_NOT_FOUND: TARIC_NOT_FOUND_YJA_FIELD,
  CUSTOMS_OFFICE_NOT_FOUND: CUSTOMS_OFFICE_NOT_FOUND_YJA_FIELD,
  INVALID_REFERENCE_NUMBER: INVALID_REFERENCE_NUMBER_YJA_FIELD,
  REFERENCE_NUMBER_TOO_BIG: REFERENCE_NUMBER_TOO_BIG_YJA_FIELD,
  REFERENCE_NUMBER_NOT_UNIQUE: REFERENCE_NUMBER_NOT_UNIQUE_YJA_FIELD,
  REFERENCE_NUMBER_CHANGED: REFERENCE_NUMBER_CHANGED_YJA_FIELD,
  LOCATION_TOO_MANY_LINES: LOCATION_TOO_MANY_LINES_YJA_FIELD,
  CODE_NOT_FOUND: CODE_NOT_FOUND_YJA_FIELD,
  INVALID_DATE: INVALID_DATE_YJA_FIELD,
}

export const FLAG_YES = 1
export const MESSAGE_SERVICE_USAGE_YES_CODE = '2'

export const FOCUS_TARGET_ID_PREFIX = 'focus-target-'

export const PERMIT_TEXTS_CODESET_NAME = 'LupaTekstit'
export const RELIEF_SUB_CODESET_NAME = 'Tullittomuuslupa'
export const EXCEPTION_SUB_CODESET_NAME = 'Poikkeuslupa'
export const RELIEF_TITLE_SUB_CODESET_NAME = 'Otsikko'

export const APPLICATION_BASIS_TAT_INFO_ELEMENT_CODE = 'applicationBasis'
export const APPLICATION_BASIS_TAP_INFO_ELEMENT_CODE = 'applicationBasis2'

export const APPLICATION_BASIS_TAT_FIELD_CODE = 'applicationBasis'
export const APPLICATION_BASIS_TAP_FIELD_CODE = 'applicationBasis2'

export const APPLICATION_BASIS_TITLE_KEY = 'applicationBasisTitle'

export const APPLICATION_BASIS_TAT_ADDITIONAL_INFORMATION_CODE = 'additionalInformation'
export const APPLICATION_BASIS_TAP_ADDITIONAL_INFORMATION_CODE = 'additionalInformation2'

export const APPLICATION_BASIS_TAT_MUU_VALUE = 'TtA muu'
export const APPLICATION_BASIS_TAP_MUU_VALUE = 'TtA muu'

export const APPLICATION_BASIS_ADDITIONAL_INFORMATION_TEMPORARY_CODE = 'temp-additionalInformation'

export const SHOW_CODESET_OPTION_ONLY_FOR_PERSON = '0'
export const SHOW_CODESET_OPTION_ONLY_FOR_BUSINESS = '1'

export const EXTENSION_VALUE_NAME_BUSINESS_TYPE = 'HenkilöVaiYritys.fi'
export const EXTENSION_VALUE_NAME_TITLE_TYPE = 'KuuluuOtsikkoon.fi'

export const APPLICANT_TYPE = {
  COMPANY: 'COMPANY',
  PERSON: 'PERSON',
}
