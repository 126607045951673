
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { withRouter } from 'react-router-dom'
import { get } from 'lodash'

import PaymentPending from './PaymentPending'

function mapStateToProps(state) {
  const cmsMessages = get(state, 'content.cmsMessages', {})
  const loadingText = cmsMessages['/individual-guarantee/pendingLoadingText']
  return {
    locale: state.locale,
    config: state.config.bootstrapConfig,
    pendingLoadingText: loadingText,
  }
}

const mapActionCreators = {}

export default withRouter(connect(mapStateToProps, mapActionCreators)(injectIntl(PaymentPending)))
