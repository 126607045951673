
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { get } from 'lodash'
import { injectIntl } from 'react-intl'
import Summary from './Summary'

const mapStateToProps = state => ({
  locale: state.locale,
  formData: get(state, 'forms'),
  codeset: get(state, 'codeset.cachedCodesets.Maa'),
})

const mapActionCreators = {}

export default withRouter(
  connect(mapStateToProps, mapActionCreators)(
    injectIntl(
      Summary
    )))
