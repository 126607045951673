export const MRN_ROUTE_PATH = 'mrn'

export const TABLE_HEADER_HEIGHT = 40
export const TABLE_ROW_HEIGHT = 40
export const TABLE_MIN_HEIGHT = Math.ceil(TABLE_HEADER_HEIGHT + (TABLE_ROW_HEIGHT * 8.5))
export const DEFAULT_SORT_BY = 'mrn'

// Regexes for different mrns
export const presentationNotificationPattern = new RegExp('^[0-9A-Z]{2}FIU[0-9A-Z]{11}X[0-9A-Z]$')
export const arrivalNotificationPattern = new RegExp('^[0-9A-Z]{16}Z[0-9A-Z]$')
export const pousPattern = new RegExp('^[0-9A-Z]{16}P[0-9A-Z]$')
export const elexPattern = new RegExp('^[0-9]{2}FI[0-9A-Z]{12}E[0-9A-Z]$')
export const arexPattern = new RegExp('^[0-9]{2}FI[0-9A-Z]{12}I[0-9A-Z]$')
export const utuImportRegexPattern = new RegExp('^[0-9]{2}FIU[0-9A-Z]{11}[0-9A-WY-Z][0-9A-Z]$')
export const utuExportRegexPattern = new RegExp('^[0-9]{2}[A-Z]{2}V[0-9A-Z]{11}[ABCDE][0-9A-Z]$')
export const defaultPattern = new RegExp('^[0-9]{2}[A-Z]{2}[0-9A-Z]{13}[0-9]$')
export const utuEntry = new RegExp('^[0-9]{2}[A-Z]{2}[0-9A-DF-TV-Z][0-9A-Z]{10}AT[0-9A-Z]$')
