import React from 'react'
import { Field } from 'redux-form'
import { Form, Col, Button, Row } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { isEmpty, get, isFunction, isNil, omitBy } from 'lodash'
import { focusFirstElementByIdOrName } from 'src/utils'

import Select from 'src/components/form/Select'
import Autocomplete from 'src/components/form/Autocomplete'
import Loader from 'src/components/Loader'
import Icon from 'src/components/Icon'
import Modal from 'src/components/Modal'
import AccessibleMessage from 'src/components/AccessibleMessage'
import RowFormQuantityFields from './RowFormQuantityFields'
import RowFormCommonField from './RowFormCommonField'
import RowFormCurrencyFields from './RowFormCurrencyFields'
import messages from '../messages'
import RowFormCN8Field from './RowFormCN8Field'
import { parseInteger, formatInteger, parseString } from '../../../utils'
import { SMALL_VALUE_CN8CODE, CN8CODES_WITH_OPTIONAL_NET_MASS } from '../constants'
import { collectCmsMessages } from '../../../../../../utils/index'
import Confirm from './Confirm'

const initialValues = {
  invoicedValueCurrencyCode: 'EUR',
  statisticalValueCurrencyCode: 'EUR',
  natureOfTransactionCode: 11,
  modeOfTransport: 1,
}

export default class RowForm extends React.Component {
  static refreshSupplementaryData({
    selectedRowData,
    fetchCodesets,
    referencePeriod,
    fetchCN8Code,
    getCN8Code,
    selectOptions,
  }) {
    // Fetch related codesets and cn8 details
    if (referencePeriod) {
      if (isEmpty(selectOptions)) {
        fetchCodesets(referencePeriod)
      }
      if (selectedRowData && selectedRowData.CN8Code) {
        const CN8CodeDetails = getCN8Code(selectedRowData.CN8Code)
        if (isEmpty(CN8CodeDetails)) {
          fetchCN8Code(selectedRowData.CN8Code, referencePeriod)
        }
      }
    }
  }

  constructor(props) {
    super(props)
    this.createRow = this.createRow.bind(this)
    this.updateRow = this.updateRow.bind(this)
    this.afterSaveRow = this.afterSaveRow.bind(this)
    this.deleteRow = this.deleteRow.bind(this)
    this.cancel = this.cancel.bind(this)
    this.setShouldScrollToCN8 = this.setShouldScrollToCN8.bind(this)
    this.onScrollToCN8TransitionEnd = this.onScrollToCN8TransitionEnd.bind(this)
    this.confirmUnsavedChangesAndSelectRow = this.confirmUnsavedChangesAndSelectRow.bind(this)
    this.setConfirmRowId = this.setConfirmRowId.bind(this)
    this.removeFirstFieldFocus = this.removeFirstFieldFocus.bind(this)
    this.preValidate = this.preValidate.bind(this)
    this.onConfirm = this.onConfirm.bind(this)
    this.onCancel = this.onCancel.bind(this)
    this.setShowConfirm = this.setShowConfirm.bind(this)
    this.state = {
      firstFieldFocused: true,
      isCN8dialogOpen: false,
      shouldScrollToCN8: false,
      scrollToCN8InProgress: false,
      cn8ScrollTop: 0,
      helpTexts: {},
      preValidating: false,
      isInitialized: false,
      showConfirm: false,
      confirmRowId: null,
    }
  }

  componentDidMount() {
    const values = this.props.selectedRowData ? this.props.selectedRowData : this.props.preservedRowFieldValues
    this.setShowAccessiblemessage(true)
    this.props.initialize({
      ...initialValues,
      ...omitBy(values, isNil),
    })
    this.updateHelpTexts()
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // Reinitialize form data when row selection changes
    const currentSelectedRowId = get(this.props, 'selectedRowData.id')
    const nextSelectedRowId = get(nextProps, 'selectedRowData.id')
    if ((!currentSelectedRowId && nextSelectedRowId) || (currentSelectedRowId !== nextSelectedRowId)) {
      this.props.initialize(nextProps.selectedRowData)
    }
    if (nextProps.referencePeriod) {
      const currentCN8 = get(this.props, 'selectedRowData.CN8Code')
      const nextCN8 = get(nextProps, 'selectedRowData.CN8Code')
      if (nextProps.referencePeriod !== this.props.referencePeriod || (nextCN8 && nextCN8 !== currentCN8)) {
        RowForm.refreshSupplementaryData(nextProps)
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const currentSelectedRowId = get(this.props, 'selectedRowData.id')
    const prevSelectedRowId = get(prevProps, 'selectedRowData.id')
    if (prevProps.cmsMessages !== this.props.cmsMessages) {
      this.updateHelpTexts()
    }
    if (currentSelectedRowId !== prevSelectedRowId) {
      this.preValidate()
      this.setShowAccessiblemessage(true)
    } else if (this.state.showAccessiblemessage) {
      this.setShowAccessiblemessage(false)
    }
    if (this.props.hasDoneNotificationVisible && !prevProps.hasDoneNotificationVisible) {
      this.setShowSuccessMessage(true)
      this.setShowAccessiblemessage(false)
    } else if (!this.props.hasDoneNotificationVisible && prevProps.hasDoneNotificationVisible) {
      this.setShowAccessiblemessage(false)
      this.setShowSuccessMessage(false)
    }

    if (!this.state.showConfirm && prevState.showConfirm) {
      this.setShowAccessiblemessage(true)
    }

    if (!this.state.preValidating && !prevProps.submitFailed && this.props.submitFailed) {
      this.props.notifyMissingInformation()
    }

    if (this.state.isInitialized !== prevState.isInitialized && currentSelectedRowId) {
      this.preValidate()
    }

    if (prevProps.initialized !== this.props.initialized) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState(state => ({ ...state, isInitialized: true }))
    }
  }

  onScrollToCN8TransitionEnd() {
    this.setState({
      scrollToCN8InProgress: false,
    })
  }

  onConfirm() {
    this.props.handleSubmit(data =>
      (this.props.newRow ? this.createRow(data, false, false) : this.updateRow(data, false, false))
    )()

    this.props.selectRow(this.state.confirmRowId)
    this.setShowConfirm(false)
  }

  onCancel() {
    this.setConfirmRowId(null)
    this.setShowConfirm(false)
  }

  setShouldScrollToCN8(scroll) {
    this.setState({
      shouldScrollToCN8: scroll,
      scrollToCN8InProgress: scroll || this.state.scrollToCN8InProgress,
      cn8ScrollTop: scroll ? this.getCN8FieldOffsetTop() : 0,
      isCN8dialogOpen: scroll,
    })
  }

  setShowConfirm(showConfirm) {
    this.setState(prevState => ({ ...prevState, showConfirm }))
  }

  setConfirmRowId(confirmRowId) {
    this.setState(prevState => ({ ...prevState, confirmRowId }))
  }

  setShowAccessiblemessage(show) {
    this.setState(prevState => ({ ...prevState, showAccessiblemessage: show }))
  }

  setShowSuccessMessage(show) {
    this.setState(prevState => ({ ...prevState, showSuccessMessage: show }))
  }

  getCN8FieldOffsetTop() {
    return this.contentRef && (this.contentRef.getElementsByClassName('cn8-row-container').item(0).offsetTop - 20)
  }

  preValidate() {
    this.setState(prevState => ({ ...prevState, preValidating: true }))
    setTimeout(() => {
      this.props.handleSubmit(() => { })()
      this.setState(prevState => ({ ...prevState, preValidating: false }))
    }, 100)
  }

  updateHelpTexts() {
    const helpTexts = collectCmsMessages('/declarations/intrastat', this.props.cmsMessages)
    this.setState({
      helpTexts,
    })
  }

  deleteRow() {
    const declarationId = this.props.declarationId
    if (!declarationId) {
      throw new Error('Declaration is not saved')
    }
    return this.props.deleteRow(declarationId, this.props.rowFieldValues)
  }

  afterSaveRow(closeAfterSave, initializeAfter = true) {
    const {
      rowFieldValues,
      notifyDoneSavingRow,
      preserveRowFieldValue,
      initialize,
    } = this.props

    const updatedPreservedRowFieldValues = {}
    if (this.props.newRow) {
      // Update all already preserved values on new row save
      const preservedRowFieldNames = Object.keys(omitBy(this.props.preservedRowFieldValues, isNil))
      preservedRowFieldNames.forEach((fieldName) => {
        updatedPreservedRowFieldValues[fieldName] = rowFieldValues[fieldName]
        preserveRowFieldValue(fieldName, rowFieldValues[fieldName])
      })
    }

    if (closeAfterSave) {
      this.cancel()
      return
    }

    // Focus the member state input upon saving if the user has opted to add another row.
    setTimeout(() => {
      if (this.memberStateInputRef) {
        const input = this.memberStateInputRef.getElementsByClassName('form-control')[0]

        if (input) {
          input.focus()
        }
      }
    }, 0)

    if (initializeAfter) {
      initialize({
        ...initialValues,
        ...updatedPreservedRowFieldValues,
      })
    }

    notifyDoneSavingRow()
  }

  createRow(data, closeAfterSave = true, initializeAfter = true) {
    const { declarationId, addSingleNewRow } = this.props
    if (!declarationId) {
      throw new Error('Declaration is not saved')
    }
    return addSingleNewRow(declarationId, data)
      .then(() => this.afterSaveRow(closeAfterSave, initializeAfter))
  }

  updateRow(data, closeAfterSave = true, initializeAfter = true) {
    const declarationId = this.props.declarationId
    if (!declarationId) {
      throw new Error('Declaration is not saved')
    }
    return this.props.fetchAndUpdateExistingRow(declarationId, data)
      .then(() => this.afterSaveRow(closeAfterSave, initializeAfter))
  }

  cancel(e) {
    if (e) {
      e.preventDefault()
    }
    if (this.props.newRow) {
      this.props.toggleNewRowEditing(false)
    } else {
      this.props.cancelRowEditing()
    }
    this.props.cancelCallback()
  }


  confirmUnsavedChangesAndSelectRow(rowId) {
    const { handleSubmit, dirty, selectRow } = this.props

    const errors = handleSubmit(() => { })()

    if (!errors) {
      if (!dirty) {
        selectRow(rowId)
        setTimeout(() => {
          if (this.memberStateInputRef) {
            const input = this.memberStateInputRef.getElementsByClassName('form-control')[0]

            if (input) {
              input.focus()
            }
          }
        }, 0)
        return
      }

      // Setup row to navigate to if confirmed, do this before to avoid unneeded notification
      this.setConfirmRowId(rowId)

      this.setShowConfirm(true)
    } else {
      const errorIds = Object.keys(errors)
      focusFirstElementByIdOrName(errorIds)
    }
  }

  removeFirstFieldFocus() {
    this.setState({
      firstFieldFocused: false,
    })
  }

  render() {
    const {
      newRow,
      selectedRowData,
      selectedRowNumber,
      handleSubmit,
      flowCode,
      cn8Trees,
      cn8Locale,
      cn8SearchResults,
      clearCN8SearchResults,
      fetchError,
      fetchCN8Code,
      searchCN8Text,
      fetchCN8TreeRoot,
      fetchingCN8Tree,
      selectCN8TreeNode,
      getCN8Code,
      selectOptions,
      codesetErrors,
      fetchingCodesets,
      fetchingCN8,
      searchingCN8Text,
      rowFieldValues,
      cn8Data,
      error,
      secondaryUnits,
      accepted,
      invalidated,
      referencePeriod,
      submitting,
      deletingRow,
      getSimstatMode,
      getNextRowId,
      getPrevRowId,
      preserveRowFieldValue,
      preservedRowFieldValues,
      hasDoneNotificationVisible,
    } = this.props

    const {
      isCN8dialogOpen,
      showSuccessMessage,
      showAccessiblemessage,
    } = this.state

    const simstatMode = getSimstatMode()
    let countriesOfOriginCodeset = 'countriesOfOrigin'
    if (simstatMode !== 1 && flowCode === 'DISPATCH_OF_GOODS') {
      countriesOfOriginCodeset = 'countriesOfOriginForSimstat'
    }
    const disabled = Boolean(accepted || invalidated || submitting || deletingRow)
    const warnings = rowFieldValues.warnings || []
    // eslint-disable-next-line no-underscore-dangle
    const globalWarning = warnings && warnings._error

    let requiredUnitForQuantityInSU = get(cn8Data, 'quantityInSU')
    if (requiredUnitForQuantityInSU) {
      requiredUnitForQuantityInSU = secondaryUnits[requiredUnitForQuantityInSU]
    }

    let titleMessage = messages.newRowHeading
    if (selectedRowNumber) {
      titleMessage = {
        ...messages.rowEditHeading,
        values: { rowNumber: selectedRowNumber },
      }
    }

    const customHeaderButtons = []
    const nextRowId = isFunction(getNextRowId) && getNextRowId()

    if (!newRow && nextRowId) {
      customHeaderButtons.push(
        <Button
          key="nextRowButton"
          bsStyle="link"
          className="shoulder-button__right"
          active
          onClick={() => this.confirmUnsavedChangesAndSelectRow(nextRowId)}
          id-qa-test="btn-next-row"
        >
          <FormattedMessage {...messages.next} />
        </Button>
      )
    }

    const prevRowId = isFunction(getPrevRowId) && getPrevRowId()

    if (!newRow && prevRowId) {
      customHeaderButtons.push(
        <Button
          key="prevRowButton"
          bsStyle="link"
          className="shoulder-button__left"
          active
          onClick={() => this.confirmUnsavedChangesAndSelectRow(prevRowId)}
          id-qa-test="btn-previous-row"
        >
          <FormattedMessage {...messages.previous} />
        </Button>
      )
    }

    const modalButtons = []
    modalButtons.push(
      <Button key="rowModalCancel" className="pull-left" id-qa-test="btn-rowModalCancel" onClick={this.cancel}>
        <FormattedMessage {...messages.cancelRow} />
      </Button>
    )
    if (!accepted && !invalidated) {
      const saveButtons = []

      if (newRow) {
        saveButtons.push(
          <Button
            key="rowModalSaveAndCreateAnother"
            onClick={handleSubmit(data =>
              (newRow ? this.createRow(data, false) : this.updateRow(data, false))
            )}
            disabled={disabled}
            id-qa-test="btn-rowModalSaveAndCreateAnother"
          >
            <Icon name="addnew" /><FormattedMessage {...messages.saveRowAndCreateAnother} />
          </Button>
        )
      }

      saveButtons.push(
        <Button
          key="rowModalSaveAndClose"
          bsStyle="primary"
          onClick={handleSubmit(data =>
            (newRow ? this.createRow(data, true) : this.updateRow(data, true))
          )}
          disabled={disabled}
          id-qa-test="btn-rowModalSaveAndClose"
        >
          <Icon name="addnew" /><FormattedMessage {...messages.saveRow} />
        </Button>
      )

      modalButtons.push(<div className="pull-right">{saveButtons.map(btn => btn)}</div>)
    }
    if (!newRow && !accepted && !invalidated) {
      modalButtons.push(
        <Button
          key="rowModalDelete"
          className="pull-right"
          id-qa-test="btn-rowModalDelete"
          onClick={this.deleteRow}
          disabled={disabled}
        >
          <Icon name="delete" /><FormattedMessage {...messages.deleteRow} />
        </Button>
      )
    }


    const getFieldProps = (fieldName, codesetKey, passThroughProps = {}) => {
      const autocompleteFields = ['memberState', 'countryOfOrigin']

      const defaultPassThroughProps = {
        disabled,
        flowCode,
        newRow,
        warning: warnings[fieldName],
        helpText: this.state.helpTexts[fieldName],
        rememberFieldHelpText: this.state.helpTexts.rememberField,
        forceValidation: Boolean(selectedRowData),
        labelMessage: messages[`${fieldName}InputLabel`],
        placeholderMessage: messages[`${fieldName}InputPlaceholder`],
        preserveRowFieldValue,
        preservedRowFieldValues,
      }
      if (codesetKey) {
        defaultPassThroughProps.InputChildComponent = autocompleteFields.includes(fieldName) ? Autocomplete : Select
        defaultPassThroughProps.options = selectOptions[codesetKey]
        defaultPassThroughProps.fetchError = codesetErrors[codesetKey]
        defaultPassThroughProps.loading = fetchingCodesets[codesetKey] === true
        defaultPassThroughProps.initialized = this.props.initialized
      }
      return {
        name: fieldName,
        props: {
          ...defaultPassThroughProps,
          ...passThroughProps,
        },
      }
    }

    return (
      <Modal
        show={Boolean(newRow || selectedRowData)}
        size="lg"
        showCancel={false}
        showContinue={false}
        onEscKey={this.cancel}
        titleMessage={titleMessage}
        customHeaderButtons={customHeaderButtons}
        customFooterButtons={!isCN8dialogOpen && modalButtons}
        animation={false}
        bodyStyle={{ overflow: 'hidden' }}
        contentRef={(ref) => { this.modalContentRef = ref }}
        disableNavigationHandling={isCN8dialogOpen}
      >
        <div
          ref={(ref) => { this.contentRef = ref }}
        >
          {(showAccessiblemessage || showSuccessMessage) &&
            <AccessibleMessage
              message={hasDoneNotificationVisible ? messages.doneSavingRow : titleMessage}
              hideMessage
            />}
          <Confirm show={this.state.showConfirm} onCancel={this.onCancel} onConfirm={this.onConfirm} />
          <Form onSubmit={handleSubmit(newRow ? this.createRow : this.updateRow)}>
            {(submitting || deletingRow) && <Loader blocking />}
            {!isCN8dialogOpen &&
              <Row>
                <Col sm={6}>
                  <div ref={(ref) => { this.memberStateInputRef = ref }}>
                    <Field
                      component={RowFormCommonField}
                      {...getFieldProps('memberState', 'memberStates', {
                        labelMessage: flowCode === 'ARRIVAL_OF_GOODS' ?
                          messages.arrivalMemberStateInputLabel : messages.dispatchMemberStateInputLabel,
                        placeholderMessage: flowCode === 'ARRIVAL_OF_GOODS' ?
                          messages.arrivalMemberStateInputPlaceholder : messages.dispatchMemberStateInputPlaceholder,
                      })}
                      mandatory
                      autoFocus={this.state.firstFieldFocused}
                      ariaRequired="true"
                    />
                  </div>
                </Col>
                <Col sm={6}>
                  {(flowCode === 'ARRIVAL_OF_GOODS' || simstatMode !== 1) &&
                    <Field
                      component={RowFormCommonField}
                      {...getFieldProps('countryOfOrigin', countriesOfOriginCodeset)}
                      mandatory={
                        (flowCode === 'ARRIVAL_OF_GOODS' || simstatMode === 3) &&
                        rowFieldValues.CN8Code !== SMALL_VALUE_CN8CODE
                      }
                      ariaRequired={
                        (flowCode === 'ARRIVAL_OF_GOODS' || simstatMode === 3) &&
                        rowFieldValues.CN8Code !== SMALL_VALUE_CN8CODE
                      }
                    />
                  }
                </Col>
              </Row>
            }

            {!isCN8dialogOpen &&
              <Row>
                <Col sm={6}>
                  <Field
                    component={RowFormCommonField}
                    {...getFieldProps('natureOfTransactionCode', 'transactionTypes')}
                    mandatory={rowFieldValues.CN8Code !== SMALL_VALUE_CN8CODE}
                    parse={parseInteger}
                    format={formatInteger}
                    ariaRequired={rowFieldValues.CN8Code !== SMALL_VALUE_CN8CODE}
                  />
                </Col>
                <Col sm={6}>
                  <Field
                    component={RowFormCommonField}
                    {...getFieldProps('modeOfTransport', 'transportTypes')}
                    mandatory={rowFieldValues.CN8Code !== SMALL_VALUE_CN8CODE}
                    parse={parseInteger}
                    format={formatInteger}
                    ariaRequired={rowFieldValues.CN8Code !== SMALL_VALUE_CN8CODE}
                  />
                </Col>
              </Row>
            }

            {!isCN8dialogOpen &&
              <Row>
                <Col sm={12}>
                  {simstatMode !== 1 && flowCode === 'DISPATCH_OF_GOODS' &&
                    <Field
                      component={RowFormCommonField}
                      {...getFieldProps('partnerId')}
                      parse={parseString}
                      mandatory={
                        flowCode === 'DISPATCH_OF_GOODS' && simstatMode === 3 &&
                        rowFieldValues.CN8Code !== SMALL_VALUE_CN8CODE
                      }
                      ariaRequired={
                        flowCode === 'DISPATCH_OF_GOODS' && simstatMode === 3 &&
                        rowFieldValues.CN8Code !== SMALL_VALUE_CN8CODE
                      }
                    />
                  }
                </Col>
              </Row>
            }

            <Field
              component={RowFormCN8Field}
              {...getFieldProps('CN8Code', undefined, {
                referencePeriod,
                fetchError,
                cn8Trees,
                cn8Locale,
                cn8SearchResults,
                getCN8Code,
                fetchCN8Code,
                searchCN8Text,
                clearCN8SearchResults,
                fetchingCN8,
                searchingCN8Text,
                fetchCN8TreeRoot,
                selectCN8TreeNode,
                fetchingCN8Tree,
                setShouldScrollToCN8: this.setShouldScrollToCN8,
                scrollToCN8InProgress: this.state.scrollToCN8InProgress,
                modalRef: this.modalContentRef,
                isCN8dialogOpen,
                removeFirstFieldFocus: this.removeFirstFieldFocus,
              })}
              mandatory
              parse={value => parseString(value.replace(/\s/g, ''))}
            />

            {!isCN8dialogOpen &&
              <RowFormQuantityFields
                optional={!cn8Data || CN8CODES_WITH_OPTIONAL_NET_MASS.includes(rowFieldValues.CN8Code)}
                disabled={disabled}
                helpTexts={this.state.helpTexts}
                cn8Code={rowFieldValues.CN8Code}
                requiredUnitForQuantityInSU={requiredUnitForQuantityInSU}
                warnings={warnings}
                forceValidation={Boolean(selectedRowData)}
              />
            }

            {!isCN8dialogOpen &&
              <Row>
                <Col sm={6}>
                  <RowFormCurrencyFields
                    mandatory
                    helpText={this.state.helpTexts.invoicedAmount}
                    labelMessage={messages.invoicedAmountInputLabel}
                    currencyLabelMessage={messages.invoicedAmountCurrencyInputLabel}
                    currencyFieldProps={getFieldProps('invoicedValueCurrencyCode', 'currencies')}
                    valueFieldProps={{
                      ...getFieldProps('invoicedAmount'),
                      parse: parseInteger,
                    }}
                    valueInEur={rowFieldValues.invoicedAmountInEur}
                    valueInEurMessage={messages.invoicedAmountInEurLabel}
                  />
                </Col>
                <Col sm={6}>
                  <RowFormCurrencyFields
                    helpText={this.state.helpTexts.statisticalValue}
                    labelMessage={messages.statisticalValueInputLabel}
                    currencyLabelMessage={messages.statisticalValueCurrencyInputLabel}
                    currencyFieldProps={{
                      ...getFieldProps('statisticalValueCurrencyCode', 'currencies'),
                      parse: parseString,
                    }}
                    valueFieldProps={{
                      ...getFieldProps('statisticalValue'),
                      parse: parseInteger,
                    }}
                    valueInEur={rowFieldValues.statisticalValueInEur}
                    valueInEurMessage={messages.statisticalValueInEurLabel}
                  />
                </Col>
              </Row>
            }
            {!isCN8dialogOpen &&
              <Field
                component={RowFormCommonField}
                {...getFieldProps('invoiceNumber', undefined, { noPreserveToggle: true })}
                parse={parseString}
              />
            }
          </Form>
          <Row>
            <Col md={8} sm={12}>
              {error && error.id &&
                <div className="text-danger" role="alert"><FormattedMessage {...error} /></div>
              }
              {globalWarning && globalWarning.id &&
                <div className="text-danger" role="alert"><FormattedMessage {...globalWarning} /></div>
              }
            </Col>
          </Row>
        </div>
      </Modal>
    )
  }
}
