import {
  findServicePath,
  getApplicationAuthorizationConfig,
  getAuthorizationTypes,
  redirectToRoleSelector,
} from 'src/utils/auth'
import { PATH_TO_TITLE_MAP, RETURN_URI_QUERY_PARAM } from 'src/constants'
import React, { useEffect } from 'react'
import { withRouter } from "react-router-dom"
import { connect } from 'react-redux'
import { get } from 'lodash'
import AuthorizationWrapper from '../components/AuthorizationWrapper'
import CookieNotice from '../components/CookieNotice'
import Modal from '../components/Modal'
import {
  sendSelectAuthorization,
  sendAcceptTerms,
  switchLocale,
  sendLogoutRequestAndExit,
  sendSelectAuthorizationType,
  changeShowSwitchRoleConfirmationModal,
  changeShowLogoutConfirmationModal,
  changeReturnUriAfterRoleSwitch,
} from '../actions'
import { collectCmsMessages, setPageTitle } from '../utils/index'
import Routes from '../routes/Routes'
import CoreLayout from '../layout/CoreLayout'

const focusOnContent = () => {
  document.getElementById('accessibility').focus()
}

function App({
  locale,
  onSwitchLocale,
  auth,
  onSelectAuthorization,
  onSelectAuthorizationType,
  onTermsAccepted,
  onLogout,
  bootstrapConfig,
  hideRoleSelectorConfirmationModal,
  showSwitchRoleConfirmationModal,
  authenticationContent,
  showLogoutConfirmationModal,
  hideLogoutConfirmationModal,
  setReturnUriAfterRoleSwitch,
  location,
  history,
}) {

  const authorizationTypes = getAuthorizationTypes(bootstrapConfig)
  const uri = findServicePath(location.pathname)
  const returnUrl = `?${RETURN_URI_QUERY_PARAM}=${uri}`

  useEffect(() => {
    setPageTitle(PATH_TO_TITLE_MAP, location.pathname, locale)
  }, [])

  useEffect(() => {
    setPageTitle(PATH_TO_TITLE_MAP, location.pathname, locale)
  }, [locale])

  useEffect(() => {
    if (typeof snoobi !== 'undefined') {
      snoobi.trackPageView(`/asiointipalvelu${location.pathname}`)
    }

    const oldServicePath = localStorage.getItem('previousServicePath')
    const newServicePath = findServicePath(location.pathname)

    if (newServicePath !== '/' && oldServicePath !== newServicePath) {
      localStorage.setItem('previousServicePath', newServicePath)
    }

    setPageTitle(PATH_TO_TITLE_MAP, location.pathname, locale)
  }, [location.pathname])

  const continueToLogout = () => {
    hideLogoutConfirmationModal()
    history.push('/logout')
  }

  const {
    roleSelectorConfirmationModalContinueButtonText,
    roleSelectorConfirmationModalTitle,
    roleSelectorConfirmationModalBodyText,
    logoutConfirmationModalContinueButtonText,
    logoutConfirmationModalTitle,
    logoutConfirmationModalBodyText,
  } = authenticationContent

  return (
    <>
      <AuthorizationWrapper
        auth={auth}
        onSelectAuthorization={onSelectAuthorization}
        onSelectAuthorizationType={onSelectAuthorizationType}
        onTermsAccepted={onTermsAccepted}
        onLogout={onLogout}
        onSwitchLocale={onSwitchLocale}
        locale={locale}
        authorizationTypes={authorizationTypes}
        suomiFiAuthorization={
          auth.suomiFiAuthorizationRequest === true
            ? getApplicationAuthorizationConfig(bootstrapConfig)
            : null
        }
      >
        <CoreLayout>
          <Routes locale={locale} />
        </CoreLayout>

        <CookieNotice onAccept={focusOnContent} />
        <Modal
          show={showSwitchRoleConfirmationModal}
          continueMessage={roleSelectorConfirmationModalContinueButtonText}
          onClickCancel={hideRoleSelectorConfirmationModal}
          onClickContinue={() => redirectToRoleSelector(returnUrl, history, uri, setReturnUriAfterRoleSwitch, hideRoleSelectorConfirmationModal)}
          locale={locale}
          focusDisableButton
          showContinue
          titleMessage={roleSelectorConfirmationModalTitle}
        >
          <div id="dialog-message">
            {roleSelectorConfirmationModalBodyText}
          </div>
        </Modal>
        <Modal
          show={showLogoutConfirmationModal}
          continueMessage={logoutConfirmationModalContinueButtonText}
          onClickCancel={hideLogoutConfirmationModal}
          onClickContinue={() => continueToLogout()}
          locale={locale}
          focusDisableButton
          showContinue
          titleMessage={logoutConfirmationModalTitle}
        >
          <div id="dialog-message">
            {logoutConfirmationModalBodyText}
          </div>
        </Modal>
      </AuthorizationWrapper>
    </>
  )
}

function mapStateToProps(state) {
  const cmsMessages = get(state, 'content.cmsMessages')

  return {
    locale: state.locale,
    authenticationContent: collectCmsMessages('/authentication', cmsMessages),
    bootstrapConfig: state.config.bootstrapConfig,
    showSwitchRoleConfirmationModal: get(state, 'auth.showSwitchRoleConfirmationModal', false),
    showLogoutConfirmationModal: get(state, 'auth.showLogoutConfirmationModal', false),
    authenticationContent: collectCmsMessages('/authentication', cmsMessages),
    auth: state.auth,
  }
}

const mapDispatchToProps = {
  setReturnUriAfterRoleSwitch: changeReturnUriAfterRoleSwitch,
  onSelectAuthorization: sendSelectAuthorization,
  onSelectAuthorizationType: sendSelectAuthorizationType,
  onTermsAccepted: sendAcceptTerms,
  onLogout: sendLogoutRequestAndExit,
  onSwitchLocale: switchLocale,
  hideRoleSelectorConfirmationModal: () => changeShowSwitchRoleConfirmationModal(false),
  hideLogoutConfirmationModal: () => changeShowLogoutConfirmationModal(false),
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App))
