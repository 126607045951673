
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { get } from 'lodash'
import { injectIntl } from 'react-intl'
import { getSelectedAuthorization } from 'src/utils/auth'
import { fetchCustomerInformation } from 'src/components/customer/actions'
import { fetchNonCachedCodesets } from 'src/components/codeset/actions'
import { submitForm } from './actions'
import EoriRegistrationForm from './components/EoriRegistrationForm'

const mapStateToProps = state => ({
  locale: state.locale,
  selectedAuthorizationObject: getSelectedAuthorization(),
  cmsMessages: get(state, 'content.cmsMessages', {}),
  customer: state.customer,
  fetchingCustomer: get(state.customer, 'fetchingCustomer'),
  fetchingCodesets: get(state.codeset, 'fetchingCodesets'),
  cachedCodesets: get(state.codeset, 'cachedCodesets'),
})

const mapActionCreators = {
  submitForm,
  fetchCustomerInformation,
  fetchNonCachedCodesets,
}

export default withRouter(
  connect(mapStateToProps, mapActionCreators)(
    injectIntl(EoriRegistrationForm)
  )
)
