import { includes, get } from 'lodash'
import { createDefaultAction } from 'src/utils/redux'
import { showGlobalNotification } from 'src/actions'
import { apiCall } from 'src/utils/http'
import {
  FORMS_SAVE_DATA,
  FORMS_DESTROY_STATE,
} from './constants'
import messages from './messages'

export const destroyFormsState = createDefaultAction(FORMS_DESTROY_STATE)

export const saveFormData = createDefaultAction(FORMS_SAVE_DATA)

export const submitForm = formData =>
  (dispatch, getState) => {
    const { config: { bootstrapConfig } } = getState()
    const customerType = get(formData, 'customerType')

    const mappedData = []
    // eslint-disable-next-line array-callback-return
    formData.data.map((item) => {
      if (!includes(['name'], item.id)) {
        mappedData.push({
          title: item.title,
          value: item.value,
        })
      }
    })

    const base = bootstrapConfig.tm_formula_ext_url
    // eslint-disable-next-line max-len
    const apiUrl = `${base}/form/${formData.formType}/${formData.locale}?customerType=${customerType.toUpperCase()}`
    const callParams = {
      method: 'POST',
      body: JSON.stringify(mappedData),
    }

    return apiCall(apiUrl, callParams, { }, undefined, false)
      .then((response) => {
        dispatch(saveFormData({
          ...formData,
          id: response.id,
          date: response.date,
        }))
      })
      .catch(() => {
        dispatch(showGlobalNotification({
          level: 'error',
          modal: true,
          message: messages.errorSubmittingForm,
        }))
      })
  }
