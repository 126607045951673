export const TABLE_HEADER_HEIGHT = 60
export const TABLE_ROW_HEIGHT = 40
export const TABLE_MIN_HEIGHT = Math.ceil(TABLE_HEADER_HEIGHT + (TABLE_ROW_HEIGHT * 8.5))
export const DEFAULT_SORT_BY = 'index'
export const SMALL_VALUE_CN8CODE = '99500000'
export const CN8CODES_WITH_OPTIONAL_NET_MASS = [SMALL_VALUE_CN8CODE, '99312400', '99319900']

export const CN8_ROOT_NODE_ID = '000000000090'

export const ROWS_FETCH_RESULT_SIZE = 500

export const NATURE_OF_TRANSACTIONS = {
  GOODS: 11,
  GIFT: 34, // NOTE: this value is used only by unit tests. The value is stored in config: nature_of_transaction_for_gift
  REPAIR: 60,
  CHANGE: 22,
  RETURN_GOODS: 21,
}

export const MODES_OF_TRANSPORT = {
  FIXED_TRANSPORT: 7,
  OWN_PROPULSION: 9,
}

export const ITEM_GROUPS = {
  FIXED_TRANSPORT_REQUIRED: ['27112100', '27160000', '38252000'],
  FIXED_TRANSPORT_ALLOWED: ['38249992'],
  OWN_PROPULSION_ALLOWED: ['88021100', '88021200', '88022000', '88023000', '88024000', '89011010', '89012010', '89013010',
    '89019010', '89020010', '89032210', '89032310', '89033210', '89033310', '89040010', '89040091', '89051010', '89059010',
    '89061000', '89069010', '89080000'],
}

export const UNDEFINED_AREA_CODE_QV = 'QV'
