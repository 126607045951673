import React from 'react'
import { isNil } from 'lodash'
import { FormGroup } from 'react-bootstrap'
import { injectIntl } from 'react-intl'
import LabelArea from 'src/components/form/LabelArea'
import InputArea from 'src/components/form/InputArea'
import Checkbox from 'src/components/form/Checkbox'
import { getValidationState } from 'src/utils/validation'

function RowFormCommonField(props) {
  const {
    input,
    InputChildComponent,
    mandatory,
    disabled,
    helpText,
    rememberFieldHelpText,
    forceValidation,
    labelMessage,
    placeholderMessage,
    noPreserveToggle,
    preserveRowFieldValue,
    preservedRowFieldValues,
    newRow,
    intl: { formatMessage },
  } = props


  const inputType = InputChildComponent ? undefined : 'text'
  const inputComponentClass = InputChildComponent ? undefined : 'input'

  return (
    <FormGroup
      controlId={input.name}
      className="clearfix"
      validationState={getValidationState(props, !forceValidation)}
    >
      <LabelArea
        id={`${input.name}Label`}
        input={input}
        help={{
          content: helpText,
        }}
        mandatory={mandatory}
        disabled={disabled}
        label={formatMessage(labelMessage)}
      >
        {newRow && !noPreserveToggle &&
          <Checkbox
            onChange={checked => preserveRowFieldValue(input.name, checked || null)}
            checked={preservedRowFieldValues && !isNil(preservedRowFieldValues[input.name])}
            title={rememberFieldHelpText}
            pin
            className="pin-toggle"
            name={`pin-${input.name}`}
          />
        }
      </LabelArea>
      <InputArea
        {...props}
        placeholderMessage={placeholderMessage}
        type={inputType}
        componentClass={inputComponentClass}
      >
        {InputChildComponent &&
          <InputChildComponent
            {...props}
            placeholderMessage={placeholderMessage}
          />
        }
      </InputArea>
    </FormGroup>
  )
}

export default injectIntl(RowFormCommonField)
