import { createValidator, required, maxLength, regEx } from 'src/utils/validation'
import { find, get } from 'lodash'

import { shouldRenderNoReferencePeriods } from '../../utils'
import messages from './messages'
import { PSI } from '../../constants'

export const shouldValidate = ({
  values,
  nextProps,
  props,
  initialRender,
  structure,
  lastFieldValidatorKeys,
  fieldValidatorKeys,
}) => {
  if (initialRender) {
    return true
  }
  // Header validation depends on customer data
  // Never validate while fetching customers, always validate after done fetching
  if (props.fetchingCustomers === true) {
    return !nextProps.fetchingCustomers
  }

  // Default functionality from Redux Form's defaultShouldValidate.js
  return !structure.deepEqual(values, nextProps.values) ||
    !structure.deepEqual(lastFieldValidatorKeys, fieldValidatorKeys)
}

/**
 * Synchronous validator for form fields - prevents all submitting (even drafts)
 */
export const syncValidator = (values, { cachedCustomers, fetchingCustomers, onlyStaticValidation = false }) => {
  const staticRules = {
    flowCode: required,
    psi: required,
    referencePeriod: required,
    reference: [maxLength(15), regEx(/^[A-Za-z0-9_-]*$/i, 'A-Z, a-z, 0-9, -, _')],
  }

  const validationErrors = createValidator(staticRules)(values)
  if (onlyStaticValidation || fetchingCustomers) {
    return validationErrors
  }

  const psiData = find(cachedCustomers, { id: values.psi }, undefined)
  const psiReportingUnitMandatory = get(psiData, 'reporterType') !== PSI
  const reportingUnits = get(psiData, 'reportingUnits', [])
  let referencePeriods

  if (values.psi) {
    if (!psiData) {
      return {
        ...validationErrors,
        psi: messages.invalidPsi,
      }
    } else if (shouldRenderNoReferencePeriods(psiData)) {
      validationErrors.psi = messages.hasNoReferencePeriods
    } else if (psiReportingUnitMandatory && reportingUnits.length > 0 && !values.psiReportingUnit) {
      validationErrors.psiReportingUnit = required(values.psiReportingUnit)
    }
    referencePeriods = psiData.referencePeriods
    if (values.psiReportingUnit) {
      const psiReportingUnitData = find(psiData.reportingUnits, { intCode: values.psiReportingUnit }, undefined)
      if (!psiReportingUnitData) {
        return {
          ...validationErrors,
          psiReportingUnit: messages.invalidPsiReportingUnit,
        }
      }
      referencePeriods = psiReportingUnitData.referencePeriods
    }
  }

  if (values.tdp) {
    const tdpData = find(cachedCustomers, { id: values.tdp }, undefined)
    if (!tdpData) {
      return {
        ...validationErrors,
        tdp: messages.invalidTdp,
      }
    }
    if (values.tdpReportingUnit) {
      const tdpReportingUnitData = find(tdpData.reportingUnits, { intCode: values.tdpReportingUnit }, undefined)
      if (!tdpReportingUnitData) {
        return {
          ...validationErrors,
          tdpReportingUnit: messages.invalidTdpReportingUnit,
        }
      }
    }
  }

  if (values.flowCode) {
    const matchingFlowCodeData = find(referencePeriods, { flow: values.flowCode }, undefined)
    if (!matchingFlowCodeData) {
      validationErrors.flowCode = messages.invalidFlowCode
    }
  }

  if (values.referencePeriod) {
    const matchingReferencePeriodData = find(
      referencePeriods,
      { referencePeriod: values.referencePeriod, flow: values.flowCode },
      undefined
    )
    if (!matchingReferencePeriodData) {
      validationErrors.referencePeriod = messages.invalidReferencePeriod
    }
  }

  return validationErrors
}

/**
 * Warning validation for form fields - never prevents submitting
 */
export const warnValidator = (values) => {
  const result = {}
  if (values.noDeclarationRows === true && values.rows && values.rows.length) {
    result.noDeclarationRows = messages.validationRowsOnNilDeclaration
  }
  return result
}
