import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { FormattedMessage, defineMessages } from 'react-intl'
import { get } from 'lodash'
import Modal from 'src/components/Modal'
import {
  switchLocale,
} from 'src/actions'

const messages = defineMessages({
  error: {
    id: 'utu.error',
    description: 'Message shown in error modal when transition to utu failed',
    defaultMessage: 'An unexpected error occurred. Please try again later',
  },
  cancel: {
    id: 'utu.cancel',
    description: 'Return to online services',
    defaultMessage: 'Return to online services',
  },
})

class UtuRoot extends React.Component {
  constructor(props) {
    super(props)
    const correctService = props.services.find(service => service.code === this.props.match.params.serviceId)

    this.state = {
      showErrorModal: !correctService,
    }
  }

  // eslint-disable-next-line class-methods-use-this
  UNSAFE_componentWillMount() {
    const { href } = window.location

    const serviceId = this.props.match.params.serviceId
    const correctService = this.props.services.find(service => service.code === this.props.match.params.serviceId)

    if (!correctService) {
      return
    }

    // Extract the part of the pathname that follows serviceId. This is appended to the redirect URL.
    const restOfTheUrl = href.substr(href.indexOf(serviceId) + serviceId.length)

    // By using the replace method this step will work like a redirect, i.e. pressing
    // back button on the browser will not return to this path.

    const serviceUrl = get(
      correctService.extensionValues.find(extValue => extValue.extensionName === 'serviceUrl'),
      'value'
    )
    window.location.replace(`${serviceUrl}${restOfTheUrl}`)
  }

  render() {
    if (this.state.showErrorModal) {
      return (
        <Modal
          show
          showCancel={false}
          disableEscKey
          titleMessage={messages.error}
          continueMessage={messages.cancel}
          onClickContinue={() => this.props.router.push('/')}
          onSwitchLocale={this.props.onSwitchLocale}
          locale={this.props.locale}
        >
          <p><FormattedMessage {...messages.error} /></p>
        </Modal>
      )
    }
    return (null)
  }
}


const mapStateToProps = state => ({
  locale: state.locale,
  services: state.config.services,
})

const mapActionCreators = {
  onSwitchLocale: switchLocale,
}

export default withRouter(connect(mapStateToProps, mapActionCreators)(UtuRoot))
