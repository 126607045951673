import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { get } from 'lodash'
import CommonCodeset from '../components/CommonCodeset'

const mapStateToProps = (state) => {
  const codesets = get(state, 'codeset.cachedCodesets')
  const fetchingCodesets = get(state, 'codeset.fetchingCodesets', {})

  return {
    auth: state.auth,
    codesets,
    fetchingCodesets,
  }
}

export default withRouter(
  connect(mapStateToProps)(
    CommonCodeset
  ))
