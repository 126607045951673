import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { injectIntl } from 'react-intl'

import requireAuthentication from 'src/containers/requireAuthentication'
import {
  fetchCustomerInformation,
  fetchGuaranteeStatuses,
  setShowNoCustomerInfoModal,
} from 'src/components/customer/actions'
import { get } from 'lodash'
import { collectCmsMessages } from 'src/utils'
import CustomerInformationPage from './components/CustomerInformationPage'
import { checkCustomerInformationAuthorization } from '../../utils/auth'

const mapStateToProps = (state) => {
  const cmsMessages = get(state, 'content.cmsMessages', {})
  const customerInformationContent = collectCmsMessages('/customer-information', cmsMessages)
  const modalTexts = collectCmsMessages('/customer-information/locationinformation/modal', cmsMessages)

  return {
    locale: state.locale,
    customer: state.customer,
    enableGuarantees: get(state.config, 'features.CUSTOMER_INFORMATION_GUARANTEE', false),
    customerInformationPageContent: customerInformationContent,
    closeModalButtonText: modalTexts.closeModalButtonText,
    fetchingCustomer: state.customer.fetchingCustomer,
    fetchingGuaranteeStatuses: state.customer.fetchingGuaranteeStatuses,
  }
}

const mapActionCreators = {
  fetchCustomerInformation,
  fetchGuaranteeStatuses,
  setShowNoCustomerInfoModal,
}

export default withRouter(connect(mapStateToProps, mapActionCreators)(
  requireAuthentication(injectIntl(CustomerInformationPage), checkCustomerInformationAuthorization)
))
