/**
 * @file React Intl translation messages for Intrastat headers page
 * @see {@link https://github.com/yahoo/react-intl}
 * @author Sami Tiilikainen
 */

import { defineMessages } from 'react-intl'

const messages = defineMessages({
  tdpLabel: {
    id: 'intrastat.headers.tdp.label',
    description: 'Input label for Intrastat TDP (third declaring party)',
    defaultMessage: 'Third declaring party',
  },
  psiSelectionPlaceholder: {
    id: 'intrastat.headers.psi.placeholder',
    description: 'Input placeholder for Intrastat PSI (Provider of statistical information)',
    defaultMessage: 'Provider of statistical information',
  },
  psiSelectionLabel: {
    id: 'intrastat.headers.psi.label',
    description: 'Input label for Intrastat PSI (provider of statistical information)',
    defaultMessage: 'Provider of statistical information',
  },
  noReportingUnitPlaceholder: {
    id: 'intrastat.headers.noReportingUnit.placeholder',
    description: 'Input placeholder for no Intrastat reporting unit',
    defaultMessage: 'No reporting unit',
  },
  reportingUnitPlaceholder: {
    id: 'intrastat.headers.reportingUnit.placeholder',
    description: 'Input placeholder for Intrastat reporting unit',
    defaultMessage: 'Reporting unit',
  },
  reportingUnitLabel: {
    id: 'intrastat.headers.reportingUnit.label',
    description: 'Input label for Intrastat reporting unit',
    defaultMessage: 'Reporting unit',
  },
  noReportingUnitsPlaceholder: {
    id: 'intrastat.headers.reportingUnit.noOptionsPlaceholder',
    description: 'Input placeholder for empty Intrastat reporting units list (nothing to select)',
    defaultMessage: 'No reporting units',
  },
  tdpPlaceholderTdpEqualsPsi: {
    id: 'intrastat.headers.tdp.placeholder.tdpEqualsPsi',
    description: 'Placeholder for TDP, when tdp equals psi',
    defaultMessage: 'No third declaring party',
  },
  reportingUnitTdpEqualsPsi: {
    id: 'intrastat.headers.reportingUnit.tdpEqualsPsi',
    description: 'Placeholder for TDP reporting unit, when tdp equals psi',
    defaultMessage: 'No reporting unit',
  },
  flowCodeInputLabel: {
    id: 'intrastat.headers.flowCode.label',
    description: 'Label for Intrastat flow code (for import/export)',
    defaultMessage: 'Direction',
  },
  referencePeriodInputPlaceholder: {
    id: 'intrastat.headers.referencePeriod.placeholder',
    description: 'Input placeholder for Intrastat reference period',
    defaultMessage: 'Reference period',
  },
  referencePeriodInputLabel: {
    id: 'intrastat.headers.referencePeriod.label',
    description: 'Label for Intrastat reference period',
    defaultMessage: 'Reference period',
  },
  importFromFile: {
    id: 'intrastat.importFromFile.label',
    description: 'Label for Intrastat file import shown in button and header',
    defaultMessage: 'Import from file',
  },
  importSuccessTitle: {
    id: 'intrastat.import.successTitle',
    description: 'Title for Intrastat file import success notification',
    defaultMessage: 'Imported successfully',
  },
  importSuccessMessage: {
    id: 'intrastat.import.successMessage',
    description: 'Message for Intrastat file import success notification',
    defaultMessage: 'Declaration and {rowCount} rows were imported successfully',
  },
  fillFormHeader: {
    id: 'intrastat.fillFormHeader',
    description: 'Header label for Intrastat header form heading for manual form fill',
    defaultMessage: 'Fill form',
  },
  referenceInputPlaceholder: {
    id: 'intrastat.headers.reference.placeholder',
    description: 'Input placeholder for Intrastat empty reference',
    defaultMessage: 'Reference',
  },
  referenceInputLabel: {
    id: 'intrastat.headers.reference.label',
    description: 'Label for Intrastat input reference',
    defaultMessage: 'Reference',
  },
  noDeclarationRowsInputLabel: {
    id: 'intrastat.noRows.label',
    description: 'Label for Intrastat checkbox "no rows" (nil declaration)',
    defaultMessage: 'No rows',
  },
  noDeclarationRowsInputPlaceholder: {
    id: 'intrastat.noRows.description',
    description: 'Description for Intrastat checkbox "no rows" (nil declaration)',
    defaultMessage: 'No rows to declare for this period',
  },
  validationRowsOnNilDeclaration: {
    id: 'intrastat.validation.rowsOnNilDeclaration',
    description: 'Validation error for "no rows" checkbox when there are rows in the declaration',
    defaultMessage: 'There are rows on this declaration. Remove rows or unmark this field.',
  },
  directionRadioOptionImport: {
    id: 'intrastat.headers.direction.import.label',
    description: 'Label for Intrastat direction import',
    defaultMessage: 'Import',
  },
  directionRadioOptionExport: {
    id: 'intrastat.headers.direction.export.label',
    description: 'Label for Intrastat direction export',
    defaultMessage: 'Export',
  },
  hasNoReferencePeriods: {
    id: 'intrastat.headers.hasNoReferencePeriods',
    description: 'Has no reference periods text (tiedonantovelvollisuutta)',
    defaultMessage: 'No liability in this period',
  },
  invalidPsi: {
    id: 'intrastat.headers.invalidPsi',
    description: 'Validation error message for invalid PSI value',
    defaultMessage: 'Invalid provider of statistical information',
  },
  invalidPsiReportingUnit: {
    id: 'intrastat.headers.invalidPsiReportingUnit',
    description: 'Validation error message for invalid PSI reporting unit value',
    defaultMessage: 'Invalid reporting unit for selected PSI',
  },
  invalidTdp: {
    id: 'intrastat.headers.invalidTdp',
    description: 'Validation error message for invalid TDP value',
    defaultMessage: 'Invalid third declaring party',
  },
  invalidTdpReportingUnit: {
    id: 'intrastat.headers.invalidTdpReportingUnit',
    description: 'Validation error message for invalid TDP reporting unit value',
    defaultMessage: 'Invalid reporting unit for selected TDP',
  },
  invalidReferencePeriod: {
    id: 'intrastat.headers.invalidReferencePeriod',
    description: 'Validation error message for invalid reference period value',
    defaultMessage: 'Invalid reference period',
  },
  invalidFlowCode: {
    id: 'intrastat.headers.invalidFlowCode',
    description: 'Validation error message for invalid flow code value',
    defaultMessage: 'Invalid flow code',
  },
  importToExistingDeclaration: {
    id: 'intrastat.headers.fileImport.existingDeclaration',
    description: 'Information shown for file import in existing declaration only.',
    defaultMessage: 'This declaration already has headers, only rows will be imported.',
  },
  invalidTdpDescription: {
    id: 'intrastat.headers.invalidTdpDialog.description',
    description: 'Declaration description message (1/3) shown when user role does not match with tdp or psi.',
    defaultMessage: 'You have chosen role {currentRoleName} ({currentRoleVat}), ' +
      'but third declaring party in declaration is {tdpName} ({tdpVat}).',
  },
  invalidTdpConfirmation: {
    id: 'intrastat.headers.invalidTdpDialog.confirmation',
    description: 'Declaration confirmation message (2/3) shown when user role does not match with tdp or psi.',
    defaultMessage: 'Would you like to change third declaring party in declaration to match your current role?',
  },
  invalidTdpNotice: {
    id: 'intrastat.headers.invalidTdpDialog.notice',
    description: 'Declaration notice message (3/3) shown when user role does not match with tdp or psi.',
    defaultMessage: 'Please note that declarations created by other third declaring partys ' +
      'will not be shown in declarations list.',
  },
  fixInvalidTdp: {
    id: 'intrastat.headers.invalidTdpDialog.fix',
    description: 'Button label for fixing declaration tdp in invalid tdp confirmation dialog.',
    defaultMessage: 'Change third declaring party in form',
  },
  ignoreInvalidTdp: {
    id: 'intrastat.headers.invalidTdpDialog.ignore',
    description: 'Button label for ignoring invalid tdp in confirmation dialog',
    defaultMessage: 'Ignore',
  },
})

export default messages
