import React from 'react'
import { injectIntl } from 'react-intl'

import { find, get } from 'lodash'
import { Row, Col } from 'react-bootstrap'
import { renderField } from './InfoElementField'
import { InlineAlert } from './InlineNotification'
import messages from '../messages'

function ConcentToSendingDecisionElectronically(props) {
  const { infoElement, formApi, intl } = props

  const field = find(infoElement.fields, infoElementField =>
    infoElementField.code === 'concentToSendingDecisionElectronically.concentToSendingDecisionElectronically'
  )

  if (!field) {
    return null
  }

  const value = get(formApi.values, 'concentToSendingDecisionElectronically.concentToSendingDecisionElectronically')

  return (
    <React.Fragment>
      <Col sm={6} xs={12}>
        <Row>
          <Col xs={12}>
            {renderField({ field })}
          </Col>
        </Row>
      </Col>
      {value === 'false' && <Row>
        <Col xs={12}>
          <InlineAlert
            warning
            icon={'attention'}
            message={get(intl.messages, '/permits/concentToSendingDecisionElectronicallyMessage')}
            srMessage={intl.formatMessage(messages.attentionIcon)}
            contentId={'concentNo'}
          />
        </Col>
      </Row>}
    </React.Fragment>
  )
}

export default injectIntl(ConcentToSendingDecisionElectronically)
