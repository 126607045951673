import React from 'react'
import { FormattedMessage } from 'react-intl'
import { withRouter } from 'react-router-dom'
import { find } from 'lodash'
import moment from 'moment'
import Icon from 'src/components/Icon'
import classNames from 'classnames'
import Table, { DESCENDING } from 'src/components/Table/Table'
import { wrapPreventDefault } from 'src/utils'
import styles from 'src/styles/_tables.scss'
import ErrorBoundary from 'src/components/ErrorBoundary'
import InteractiveElement from 'src/components/InteractiveElement'
import noRowsRenderer from 'src/components/lists/noRowsRenderer'
import Loader from 'src/components/Loader'
import messages from '../messages'
import {
  DEFAULT_SORT_BY,
  DECLARATION_STATUS_DRAFT,
  DECLARATION_STATUS_READY,
  DECLARATION_STATUS_ACCEPTED,
  DECLARATION_STATUS_INVALIDATED,
} from '../constants'
import { INTRASTAT_ROUTE_PATH, DECLARATION_ROUTE_PATH } from '../../constants'
import {
  HEADERS_ROUTE_PATH,
  SUMMARY_ROUTE_PATH,
} from '../../declaration/constants'

class DeclarationsList extends React.Component {
  constructor(props, context) {
    super(props, context)
    this.selectRow = this.selectRow.bind(this)
    this.addCompanyNames = this.addCompanyNames.bind(this)
    this.handleGlobalClick = this.handleGlobalClick.bind(this)
  }

  componentDidMount() {
    const { statusFilter, initialFetchingDeclarations, fetchDeclarations } = this.props
    if (!initialFetchingDeclarations) {
      fetchDeclarations(statusFilter || DECLARATION_STATUS_DRAFT)
    }
    setTimeout(() => window.addEventListener('click', this.handleGlobalClick), 0)
  }


  UNSAFE_componentWillReceiveProps(nextProps) {
    // Refetch all declarations when company selection changes
    if (this.props.selectedOrganizationId && nextProps.selectedOrganizationId !== this.props.selectedOrganizationId) {
      nextProps.fetchDeclarations(nextProps.statusFilter || DECLARATION_STATUS_DRAFT)
    }
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.handleGlobalClick)
    this.props.destroyCacheState()
  }

  handleGlobalClick(event) {
    // Remove row activation when user clicks outside this component
    if (this.props.activeRowId && this.containerRef && !this.containerRef.contains(event.target)) {
      this.props.activateDeclarationRow(null)
    }
  }

  selectRow(declaration) {
    const { history } = this.props
    const pagePath =
      (declaration.status === DECLARATION_STATUS_ACCEPTED || declaration.status === DECLARATION_STATUS_INVALIDATED) ?
        SUMMARY_ROUTE_PATH : HEADERS_ROUTE_PATH
    history.push(
      `/${INTRASTAT_ROUTE_PATH}/${DECLARATION_ROUTE_PATH}/${declaration.declarationId}/${pagePath}`
    )
    this.props.activateDeclarationRow(declaration)
  }

  addCompanyNames(list) {
    if (!list) {
      return []
    }
    const listWithCompanyNames = list.map((listItem) => {
      const company = listItem && listItem.psi && find(this.props.companies, { id: listItem.psi })
      if (company && company.name) {
        return Object.assign({}, listItem, { corporateName: company.name })
      }
      return listItem
    })

    return listWithCompanyNames
  }

  render() {
    const {
      intl: { formatMessage },
      statusFilter,
      initialFetchingDeclarations,
      fetchDeclarations,
      fetchError,
      fetchMoreError,
      activateDeclarationRow,
      declarations,
    } = this.props

    const declarationsWithCompanyNames = this.addCompanyNames(declarations)

    const onRowClick = ({ row }) => {
      this.selectRow(row)
    }

    let errorFetchingRows
    if (fetchError && fetchError.id) {
      errorFetchingRows = fetchError
    } else if (fetchMoreError && fetchMoreError.id) {
      errorFetchingRows = fetchMoreError
    } else if (fetchError || fetchMoreError) {
      errorFetchingRows = messages.declarationsListFetchError
    }

    let dateHeaderMessage = messages.columnLabelDateModified
    if (statusFilter === DECLARATION_STATUS_READY) {
      dateHeaderMessage = messages.columnLabelDateSent
    }

    const noRows = declarationsWithCompanyNames.length === 0

    const switchStatus = (status) => {
      fetchDeclarations(status)
      activateDeclarationRow(null)
    }

    const headings = [
      { value: formatMessage(messages.columnLabelDeclarationId) },
      { value: formatMessage(messages.columnLabelCompany) },
      { value: formatMessage(messages.columnLabelFlowCode) },
      { value: formatMessage(messages.columnLabelPeriod) },
      { value: formatMessage(messages.columnLabelReference) },
      { value: formatMessage(dateHeaderMessage) },
      { value: formatMessage(messages.columnLabelStatus) },
    ]
    const properties = [
      'declarationId',
      'corporateName',
      'flowCode',
      'referencePeriod',
      'reference',
      'dateOfModify',
      'status',
    ]

    const translationMessages = {
      RECEIVED: messages.statusDraft,
      ACCEPTED: messages.statusReady,
      INCORRECT: messages.statusError,
      INVALIDATED: messages.statusInvalidated,
      DEFACED: messages.statusDefaced,
      ARRIVAL_OF_GOODS: messages.typeImport,
      DISPATCH_OF_GOODS: messages.typeExport,
    }

    const translationFormatter = value => formatMessage(translationMessages[value])

    const formatters = {
      flowCode: (row, property) => translationFormatter(row[property]),
      referencePeriod: (row, property) => {
        let content = row[property]
        if (content && /^[0-9]{6}$/.test(content)) {
          content = `${content.slice(0, 4)}/${content.slice(4, 6)}`
        }
        return content
      },
      dateOfModify: (row, property) => moment(row[property]).format('L'),
      status: (row, property) => {
        if (row[property] !== 'INCORRECT') {
          return translationFormatter(row[property])
        }

        return (
          <div className="text-danger">
            <Icon name="attention-bold" />
            {translationFormatter(row[property])}
          </div>
        )
      },
    }

    return (
      <div ref={(container) => { this.containerRef = container }}>
        <ul className="nav nav-tabs" role="tablist">
          <li
            role="tab"
            className={statusFilter === DECLARATION_STATUS_DRAFT ? 'active' : null}
            aria-controls="declaration-status-draft-tabpanel"
            aria-selected={statusFilter === DECLARATION_STATUS_DRAFT}
          >
            <InteractiveElement
              id="declaration-status-draft-tabpanel"
              role="tabpanel"
              type="a"
              onClick={wrapPreventDefault(() => switchStatus(DECLARATION_STATUS_DRAFT))}
              aria-labelledby="declaration-status-draft-label"
            >
              <FormattedMessage id="declaration-status-draft-label" {...messages.tabLabelDraftDeclarations} />
            </InteractiveElement>
          </li>
          <li
            role="tab"
            className={statusFilter === DECLARATION_STATUS_READY ? 'active' : null}
            aria-controls="declaration-status-ready-tabpanel"
            aria-selected={statusFilter === DECLARATION_STATUS_READY}
          >
            <InteractiveElement
              id="declaration-status-ready-tabpanel"
              role="tabpanel"
              type="a"
              onClick={wrapPreventDefault(() => switchStatus(DECLARATION_STATUS_READY))}
              aria-labelledby="declaration-status-ready-label"
            >
              <FormattedMessage id="declaration-status-ready-label" {...messages.tabLabelReadyDeclarations} />
            </InteractiveElement>
          </li>
        </ul>
        <div className="panel">
          <div
            className={classNames('panel-body', 'panel-navigation', noRows && styles.verticalAlignmentForChildren)}
          >
            <ErrorBoundary>
              {initialFetchingDeclarations && (
                <div>
                  <Loader blocking message={messages.loadingDeclarations} />
                </div>
              )}
              {noRows && !errorFetchingRows && !initialFetchingDeclarations &&
                noRowsRenderer(messages.noDeclarations)
              }
              {!noRows && (!initialFetchingDeclarations) &&
                <Table
                  headings={headings}
                  properties={properties}
                  contentRows={declarationsWithCompanyNames}
                  formatters={formatters}
                  onRowClick={onRowClick}
                  sortable
                  sortByColumn={DEFAULT_SORT_BY}
                  sortByDirection={DESCENDING}
                />
              }
              {errorFetchingRows && (
                <div className="text-danger text-center">
                  <FormattedMessage {...errorFetchingRows} />
                </div>
              )}
            </ErrorBoundary>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(DeclarationsList)