
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { get } from 'lodash'
import PrintSummary from './PrintSummary'


const mapStateToProps = state => ({
  locale: state.locale,
  formData: get(state, 'forms'),
  cmsMessages: get(state, 'content.cmsMessages', {}),
})

const mapActionCreators = {}

export default withRouter(
  connect(mapStateToProps, mapActionCreators)(
    PrintSummary
  ))

