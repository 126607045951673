import React from 'react'
import classNames from 'classnames'

import styles from './Alert.scss'

export default function Alert(props) {
  const { className } = props
  let alertClass = styles.info
  let stretch = null
  if (props.success) {
    alertClass = styles.success
  }
  if (props.error) {
    alertClass = styles.error
  }
  if (props.warning) {
    alertClass = styles.warning
  }
  if (props.stretch) {
    stretch = styles.stretch
  }
  return (
    <div className={classNames(alertClass, className, stretch)}>
      {props.children}
    </div>
  )
}
