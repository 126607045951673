import React from 'react'
import FormControlGroup from 'src/components/form/FormControlGroup'
import { FormattedMessage } from 'react-intl'
import Radio from 'src/components/form/Radio'
import messages from '../messages'

export default function FlowRadioFieldGroup(props) {
  return (
    <FormControlGroup {...props}>
      <div className="radio" role="radiogroup" aria-required={props.mandatory}>
        <Radio
          {...props.input}
          disabled={props.disabled || false}
          inline
          value="ARRIVAL_OF_GOODS"
          checked={props.input.value === 'ARRIVAL_OF_GOODS'}
          labelId={`${props.input.name}Label`}
        >
          <FormattedMessage {...messages.directionRadioOptionImport} />
        </Radio>
        <Radio
          {...props.input}
          disabled={props.disabled || false}
          inline
          value="DISPATCH_OF_GOODS"
          checked={props.input.value === 'DISPATCH_OF_GOODS'}
          labelId={`${props.input.name}Label`}
        >
          <FormattedMessage {...messages.directionRadioOptionExport} />
        </Radio>
      </div>
    </FormControlGroup>
  )
}
