import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { connect } from 'react-redux'
import { get } from 'lodash'

import PageNotFound from 'src/components/PageNotFound'
import Frontpage from './frontpage'
import Logout from './logout'
import Feedback from './feedback'
import Iat from './iat'
import Forms from './forms/FormsRoutes'
import Mrn from './mrn'
import Intrastat from './intrastat/IntrastatRoutes'
import Utu from './ext-auth'
import Permits from './permits/PermitsRoutes'
import CustomerInformation from './customer-information'
import IndividualGuarantee from './individual-guarantee/IndividualGuaranteeRoutes'

import {
  FORMS_ROUTE,
  IAT_ROUTE,
  FEEDBACK_ROUTE,
  LOGOUT_ROUTE,
  MRN_ROUTE,
  INTRASTAT_ROUTE,
  UTU_ROUTE,
  PERMITS_ROUTE,
  CUSTOMER_INFORMATION_ROUTE,
  INDIVIDUAL_GUARANTEE_ROUTE,
} from './constants'


function Routes(props) {
  const {
    features,
  } = props

  const {
    MRN,
    FORMS,
    PERMITS,
    CUSTOMER_INFORMATION,
    INDIVIDUAL_GUARANTEE,
  } = features
  return (
    <Switch>
      <Route exact path="/">
        <Frontpage />
      </Route>
      <Route path={LOGOUT_ROUTE}>
        <Logout />
      </Route>
      <Route path={FEEDBACK_ROUTE}>
        <Feedback />
      </Route>
      <Route path={IAT_ROUTE}>
        <Iat />
      </Route>
      {PERMITS &&
        <Route path={PERMITS_ROUTE}>
          <Permits />
        </Route>
      }
      {FORMS &&
        <Route path={FORMS_ROUTE}>
          <Forms features={features} />
        </Route>
      }
      {MRN &&
        <Route path={MRN_ROUTE}>
          <Mrn />
        </Route>
      }
      <Route path={INTRASTAT_ROUTE}>
        <Intrastat />
      </Route>
      <Route path={`${UTU_ROUTE}/:serviceId`}>
        <Utu />
      </Route>
      {CUSTOMER_INFORMATION &&
        <Route path={CUSTOMER_INFORMATION_ROUTE}>
          <CustomerInformation />
        </Route>
      }
      {INDIVIDUAL_GUARANTEE &&
        <Route path={INDIVIDUAL_GUARANTEE_ROUTE}>
          <IndividualGuarantee />
        </Route>
      }
      <Route component={PageNotFound} />
    </Switch>
  )
}

const mapStateToProps = (state) => {
  return {
    locale: get(state, 'locale'),
    features: get(state, 'config.features'),
  }
}

export default connect(mapStateToProps, null)(Routes)
