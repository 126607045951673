import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Button, Overlay } from 'react-bootstrap'
import { get, isEmpty, size, replace } from 'lodash'
import Highlighter from 'react-highlight-words'
import InteractiveElement from 'src/components/InteractiveElement'
import Icon from 'src/components/Icon'
import Loader from 'src/components/Loader'
import messages from './messages'
import CN8TreeNode from './CN8TreeNode'
import CN8TextSearchBox from './CN8TextSearchBox'
import './CN8Browser.scss'


const CN8_VIEW_MODE_BROWSE_TREE = 'browseTree'
const CN8_VIEW_MODE_SEARCH_TEXT = 'searchText'

class CN8Browser extends React.Component {
  static getRootNode(props) {
    return get(props.cn8Trees, [props.referencePeriod.substr(0, 4), 'root'])
  }

  static parseSearchTextInput(value = '') {
    // Replace unnecessary input characters that will not get past mod_security
    return replace(value, /['"]/, '')
  }

  constructor(props) {
    super(props)

    this.initialState = {
      currentCN8Code: '',
      isCN8BrowserLoading: false,
      viewState: {
        viewMode: CN8_VIEW_MODE_BROWSE_TREE,
        selectedNode: null,
        activeNode: null,
        expandedNodes: {
          [props.cn8RootNodeId]: true,
        },
      },
    }
    this.state = JSON.parse(JSON.stringify(this.initialState))

    this.onGlobalKeyUp = this.onGlobalKeyUp.bind(this)
    this.scrollActiveCN8TreeNodeIntoView = this.scrollActiveCN8TreeNodeIntoView.bind(this)
    this.getNormalizedInputValue = this.getNormalizedInputValue.bind(this)

    this.resetState = this.resetState.bind(this)

    this.selectCN8TreeBranch = this.selectCN8TreeBranch.bind(this)
    this.selectCN8TreeLeaf = this.selectCN8TreeLeaf.bind(this)

    this.browse = this.browse.bind(this)
    this.search = this.search.bind(this)

    this.keyboardNavigationHandler = this.keyboardNavigationHandler.bind(this)
  }

  componentDidMount() {
    const { cn8SearchResults, referencePeriod, fetchCN8TreeRoot, clearCN8SearchResults, cn8Locale } = this.props
    document.addEventListener('keyup', this.onGlobalKeyUp)
    if (!CN8Browser.getRootNode(this.props) || cn8Locale !== this.props.intl.locale) {
      fetchCN8TreeRoot(referencePeriod)
    }
    if (cn8SearchResults && cn8SearchResults.referencePeriod !== referencePeriod) {
      clearCN8SearchResults()
    }
    document.addEventListener('keydown', this.keyboardNavigationHandler, false)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // When initial CN8 fetching is completed...
    if (this.state.isCN8BrowserLoading && this.props.fetchingCN8Tree && !nextProps.fetchingCN8Tree) {
      this.setState({ isCN8BrowserLoading: false })
    }
    // When CN8Browser is opened...
    if (nextProps.open && !this.props.open) {
      if (!nextProps.cn8InputValue || /^[0-9]*?$/.test(nextProps.cn8InputValue)) {
        // Show tree view on numeric or empty input
        this.setState({
          isCN8BrowserLoading: true,
        })
        this.browse().then(() => {
          this.setState({
            isCN8BrowserLoading: this.props.fetchingCN8Tree,
          })
        })
      }
    }
  }

  componentDidUpdate(prevProps) {
    const browserOpenedOnUpdate = this.props.open && !prevProps.open
    if (browserOpenedOnUpdate || this.forceScrollAfterUpdate) {
      this.scrollActiveCN8TreeNodeIntoView()
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keyup', this.onGlobalKeyUp)
    document.removeEventListener('keydown', this.keyboardNavigationHandler, false)
  }

  onGlobalKeyUp(event) {
    if (event.key === 'Escape' && this.props.open) {
      this.props.hideCN8Browser()
    }
  }

  getLastElementInCN8Tree(cn8Root) {
    const lastNode = cn8Root.children[cn8Root.children.length - 1]
    if (lastNode.children.length === 0) {
      return lastNode
    }
    return this.getLastElementInCN8Tree(lastNode)
  }

  getNormalizedInputValue() {
    return this.props.cn8InputValue.toString().replace(/\s/g, '')
  }

  keyboardNavigationHandler(event) {
    const firstFocusable = document.getElementById('hideCN8Browser')

    let currentLastFocusable = null

    if (!this.props.cn8SearchResults) {
      const root = get(this.props.cn8Trees, [this.props.referencePeriod.substr(0, 4), 'root'])
      if (root) {
        const currentLastElementCode = this.getLastElementInCN8Tree(root).model.code
        currentLastFocusable = document.getElementById(`cn8-tree-node-${currentLastElementCode}`)
      }
    } else if (this.props.cn8SearchResults) {
      const lastSearchElementIndex = this.props.cn8SearchResults.resultItems.length - 1
      currentLastFocusable = document.getElementById(`cn8-search-item-button-${lastSearchElementIndex}`)
    }

    if (event.keyCode === 9) {
      if (event.shiftKey && document.activeElement === firstFocusable) {
        event.preventDefault()
        currentLastFocusable.focus()
      } else if (!event.shiftKey && document.activeElement === currentLastFocusable) {
        event.preventDefault()
        firstFocusable.focus()
      }
    }
  }

  resetState() {
    this.setState(JSON.parse(JSON.stringify(this.initialState)))
  }

  switchViewMode(mode) {
    this.setState({
      viewState: {
        ...this.state.viewState,
        viewMode: mode,
      },
    })
  }

  scrollActiveCN8TreeNodeIntoView() {
    this.forceScrollAfterUpdate = false
    if (!this.props.open) {
      return
    }
    if (this.state.isCN8BrowserLoading || !this.node) {
      this.forceScrollAfterUpdate = true
      return
    }

    let classNameToSearchFor
    if (this.state.viewState.activeNode) {
      classNameToSearchFor = 'cn8-active'
    } else if (this.state.viewState.selectedNode) {
      classNameToSearchFor = 'cn8-selected'
    } else {
      return
    }

    const containerElement = this.node.getElementsByClassName('cn8-content').item(0)
    const activeElement = this.node.getElementsByClassName(classNameToSearchFor).item(0)
    if (activeElement) {
      activeElement.focus()
      // scrollTop property is used instead of Element.scrollIntoView because we don't
      // want to cause the browser window to change scroll position in any situation
      containerElement.scrollTop =
        // Scroll active or selected node to the middle of the view
        (activeElement.offsetTop - (containerElement.offsetHeight / 2)) + (activeElement.offsetHeight / 2)
    }
  }

  selectCN8TreeBranch(code, cn8Code, event) {
    event.stopPropagation()
    event.preventDefault()

    if (this.state.viewState.expandedNodes[code]) {
      this.collapseNode(code)
    } else {
      this.expandNode(code)
      const targetCloseToContainerBottom =
        event.target.offsetTop > (event.target.offsetParent.scrollTop + event.target.offsetParent.offsetHeight) - 120
      if (targetCloseToContainerBottom) {
        this.forceScrollAfterUpdate = true
      }
    }

    this.props.selectCN8TreeNode(code, this.props.referencePeriod)
  }

  selectCN8TreeLeaf(cn8Code, event) {
    event.stopPropagation()
    event.preventDefault()

    this.props.focusCN8Input()
    this.props.hideCN8Browser()
    this.resetState()
    this.props.setCN8Value(cn8Code)
  }

  expandNode(code) {
    this.setState({
      viewState: {
        ...this.state.viewState,
        activeNode: code,
        expandedNodes: {
          ...this.state.viewState.expandedNodes,
          [code]: true,
        },
      },
    })
  }

  collapseNode(code) {
    this.setState({
      viewState: {
        ...this.state.viewState,
        activeNode: null,
        expandedNodes: {
          ...this.state.viewState.expandedNodes,
          [code]: false,
        },
      },
    })
  }

  expandAndSelectNodes(ancestorCodes) {
    const expandedNodes = this.state.viewState.expandedNodes
    ancestorCodes.forEach((code) => { expandedNodes[code] = true })

    this.setState({
      viewState: {
        ...this.state.viewState,
        activeNode: null,
        selectedNode: ancestorCodes[ancestorCodes.length - 1],
        expandedNodes,
      },
    })
  }

  browse() {
    this.setState({
      currentCN8Code: '',
      viewState: {
        ...this.state.viewState,
        selectedNode: null,
        viewMode: CN8_VIEW_MODE_BROWSE_TREE,
      },
    })

    const cn8Code = this.getNormalizedInputValue()
    if (isEmpty(cn8Code)) {
      return Promise.resolve()
    }
    return this.fetchAndExpandCN8Code(cn8Code)
  }

  search(text) {
    this.switchViewMode(CN8_VIEW_MODE_SEARCH_TEXT)
    return this.props.searchCN8Text(text, this.props.referencePeriod)
  }

  showCodeInTreeView(cn8Code) {
    this.setState({
      isCN8BrowserLoading: true,
    })
    this.switchViewMode(CN8_VIEW_MODE_BROWSE_TREE)
    this.fetchAndExpandCN8Code(cn8Code)
  }

  fetchAndExpandCN8Code(cn8Code) {
    this.setState({ currentCN8Code: cn8Code })
    return this.props.fetchCN8Code(cn8Code, this.props.referencePeriod)
      .then((ancestors) => {
        if (Array.isArray(ancestors)) {
          this.expandAndSelectNodes(ancestors)
        }
      })
      .then(() => { this.scrollActiveCN8TreeNodeIntoView() })
  }

  render() {
    const {
      open,
      cn8Trees,
      cn8SearchResults,
      searchingCN8Text,
      referencePeriod,
      intl,
      hideCN8Browser,
      useOverlay,
      overlayTarget,
      overlayContainer,
    } = this.props

    if (!open) {
      return null
    }

    const viewMode = get(this.state, 'viewState.viewMode')
    const cn8Root = get(cn8Trees, [referencePeriod.substr(0, 4), 'root'])
    const headingMessage = viewMode === CN8_VIEW_MODE_BROWSE_TREE ?
      messages.browseHeading : messages.searchHeading

    let output = (
      <CN8View>
        <div ref={(node) => { this.node = node }}>

          {(searchingCN8Text || (this.state.isCN8BrowserLoading)) && <Loader blocking />}

          <div className="cn8-heading clearfix">
            <h3 className="pull-left" id="cn8ViewTitle"><FormattedMessage {...headingMessage} /></h3>
            <InteractiveElement
              className="pull-right"
              onClick={() => {
                hideCN8Browser()
                this.resetState()
                document.getElementById('showCN8BrowserButton').focus()
              }}
              id-qa-test="btn-hideCN8Browser"
              id="hideCN8Browser"
            >
              <Icon name="close" />
              <span className="sr-only"><FormattedMessage {...messages.close} /></span>
            </InteractiveElement>
            <CN8TextSearchBox
              label={intl.formatMessage(messages.searchInputLabel)}
              placeholder={intl.formatMessage(messages.searchInputPlaceholder)}
              search={this.search}
              searchText={messages.searchIconText}
            />
          </div>
          <div className="cn8-content">
            {viewMode === CN8_VIEW_MODE_BROWSE_TREE &&
              <div className="cn8-tree" role="tree">
                {cn8Root &&
                  <CN8TreeNode
                    key={cn8Root.model.code}
                    node={cn8Root}
                    selectCN8TreeBranch={this.selectCN8TreeBranch}
                    selectCN8TreeLeaf={this.selectCN8TreeLeaf}
                    viewState={this.state.viewState}
                  />}
              </div>
            }
            {viewMode === CN8_VIEW_MODE_SEARCH_TEXT &&
              <div className="cn8-search">
                {!cn8SearchResults &&
                  <p><FormattedMessage {...messages.noResults} /></p>
                }
                {cn8SearchResults &&
                  <div>
                    <div id="numberOfResults" tabIndex="-1">
                      <p>
                        <FormattedMessage
                          {...(size(cn8SearchResults.resultItems) === 100 ?
                            messages.searchResultDescriptionForOver100 : messages.searchResultDescription
                          )}
                          values={{
                            searchText: <strong style={{ color: '#00205b' }}>{cn8SearchResults.searchText}</strong>,
                            resultCount: size(cn8SearchResults.resultItems) || '0',
                          }}
                        />
                      </p>
                    </div>
                    {size(cn8SearchResults.resultItems) > 0 && cn8SearchResults.resultItems.map((result, index) =>
                      <div key={result.cn8Code} className="cn8-search-item">
                        <div className="cn8-search-item-wrapper">
                          <InteractiveElement
                            id={`cn8-search-item-${index}`}
                            type="div"
                            onClick={event => this.selectCN8TreeLeaf(result.cn8Code, event)}
                            id-qa-test="btn-selectCN8TreeLeaf"
                            className="cn8-search-item-select-item focus_within_fix"
                            excludeRole
                          >
                            <div
                              id={`cn8-search-item-code-${index}`}
                              className="tulli-blue cn8-item-code"
                              role="button"
                            >
                              {result.cn8Code}
                            </div>
                            <div id={`cn8-search-item-description-${index}`} className="cn8-search-item-description">
                              <Highlighter
                                textToHighlight={result.description}
                                autoEscape
                                searchWords={cn8SearchResults.searchText.split(/\s/)}
                              />
                            </div>
                          </InteractiveElement>
                          <div>
                            <Button
                              className="cn8-search-item-in-tree"
                              onClick={(event) => {
                                event.stopPropagation()
                                this.showCodeInTreeView(result.cn8Code)
                              }}
                              title={intl.formatMessage(messages.showInTreeView)}
                              id-qa-test="btn-showCodeInCN8TreeView"
                              id={`cn8-search-item-button-${index}`}
                              aria-describedby={`cn8-search-item-code-${index}`}
                            >
                              <Icon name="treeview" style={{ fontSize: '1.9rem', marginLeft: '8px' }} />
                              <span className="sr-only"><FormattedMessage {...messages.showInTreeView} /></span>
                            </Button>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                }
              </div>
            }
          </div>
        </div>
      </CN8View>
    )
    if (useOverlay) {
      output = (
        <Overlay
          show={open}
          placement="bottom"
          target={overlayTarget}
          container={overlayContainer}
          shouldUpdatePosition
        >
          {output}
        </Overlay>
      )
    }
    return output
  }
}

// Simple component for handling Overlay props, only style may be passed to div
const CN8View = ({ style, children }) => (
  <div className="cn8-view" style={{ top: style.top }} role="dialog" aria-labelledby="cn8ViewTitle">
    {children}
  </div>
)


export default CN8Browser
