import React from 'react'
import { Button } from 'react-bootstrap'


export default function ActionButton({ onClick, ariaProps, id, children }) {
  return <Button
    id={id}
    className="action-button"
    onClick={onClick}
    id-qa-test={id}
    {...ariaProps}

  >
    {children}
  </Button>
}
