/**
 * @file Container component connecting presentational React component to Redux state
 * @see {@link http://redux.js.org/docs/basics/UsageWithReact.html}
 * @author Sami Tiilikainen
 */

import { connect } from 'react-redux'
import { reduxForm, getFormValues } from 'redux-form'
import { withRouter } from 'react-router-dom'
import { injectIntl } from 'react-intl'
import { get } from 'lodash'
import RowsView from '../components/RowsView'
import {
  INTRASTAT_FORM_NAME,
  DECLARATION_STATUS_ACCEPTED,
  DECLARATION_STATUS_DEFACED,
  DECLARATION_STATUS_DELETED,
  HEADERS_ROUTE_PATH,
  ROWS_ROUTE_PATH,
  CONFIRMATION_ROUTE_PATH,
  DECLARATION_HEADERS_FETCHED,
  DECLARATION_ROWS_FETCHED,
} from '../../../constants'
import { INTRASTAT_CACHE_CUSTOMERS } from '../../../../constants'
import { stepChange, togglePreserveDraftOnExitModal } from '../../../actions'
import { rowsStepSubmit, selectRow, toggleNewRowEditing } from '../actions'
import { fetchNonCachedCodesets } from '../../../../actions'
import { warnValidator as warn, shouldValidateRowsView as shouldValidate } from '../validator'
import { getSelectedDelegateCompanyFromAuthentication } from '../../../../../../utils/auth'

const formConfig = {
  form: INTRASTAT_FORM_NAME,
  warn,
  shouldValidate,
  destroyOnUnmount: false,
}

const getIntrastatFormValues = getFormValues(INTRASTAT_FORM_NAME)

const mapStateToProps = (state) => {
  const {
    id,
    rows,
    referencePeriod,
    noDeclarationRows,
    flowCode,
    status,
    tdp,
  } = getIntrastatFormValues(state)

  const {
    currentStep,
    newRow,
    selectedRow,
    isNew,
  } = state.intrastat.declaration

  const {
    cachedCustomers,
  } = state.intrastat.common

  const fetchingCustomers = state.loading[INTRASTAT_CACHE_CUSTOMERS]
  const fetchingDeclaration = state.loading[DECLARATION_HEADERS_FETCHED]
  const fetchingDeclarationRows = state.loading[DECLARATION_ROWS_FETCHED]
  const fetchingCodesets = get(state, ['intrastat', 'common', 'fetchingCodesets', referencePeriod], {})

  return {
    locale: state.locale,
    thisStep: ROWS_ROUTE_PATH,
    nextStep: CONFIRMATION_ROUTE_PATH,
    prevStep: HEADERS_ROUTE_PATH,
    referencePeriod,
    flowCode,
    noDeclarationRows,
    rows,
    rowCount: rows && rows.length,
    accepted: status === DECLARATION_STATUS_ACCEPTED,
    invalidated: status === DECLARATION_STATUS_DEFACED,
    deleted: status === DECLARATION_STATUS_DELETED,
    currentStep,
    newRow,
    selectedRow,
    fetchingDeclaration,
    fetchingDeclarationRows,
    fetchingCustomers,
    isNew,
    id,
    tdp,
    fetchingCodesets,
    selectedDelegateCompanyObject: getSelectedDelegateCompanyFromAuthentication(),
    cachedCustomers,
  }
}

const mapActionCreators = {
  onStepChange: stepChange,
  onSubmit: rowsStepSubmit,
  toggleNewRowEditing,
  selectRow,
  showPreserveDraftOnExitModalAction: () => togglePreserveDraftOnExitModal(true),
  fetchCodesets: date =>
    fetchNonCachedCodesets(
      [
        'transactionTypes',
        'transportTypes',
        'memberStates',
        'countriesOfOrigin',
        'countriesOfOriginForSimstat',
        'currencies',
        'secondaryUnits',
      ],
      date
    ),
}
export default withRouter(
  connect(mapStateToProps, mapActionCreators)(
    reduxForm(formConfig)(
      injectIntl(
        RowsView
      ))))
