import React from 'react'
import { has, isEmpty } from 'lodash'
import { FormattedMessage, injectIntl } from 'react-intl'
import Table, { DESCENDING } from 'src/components/Table/Table'
import ErrorBoundary from 'src/components/ErrorBoundary'
import noRowsRenderer from 'src/components/lists/noRowsRenderer'
import messages from '../messages'
import ActionButton from '../../permits/list/components/ActionButton'
import Icon from '../../../components/Icon'
import styles from './SearchResults.scss'

const SearchResults = (props) => {
  const {
    rows,
    MRN,
    intl: { formatMessage },
    isUtuEntry,
    onRowClick,
  } = props

  const noRows = isEmpty(rows)

  const tableProperties = {
    default: {
      headings: [
        { value: formatMessage(messages.columnLabelMRN) },
        { value: formatMessage(messages.columnLabelGoodsItem) },
        { value: formatMessage(messages.columnLabelStatus) },
        { value: formatMessage(messages.columnLabelDeclarationType) },
      ],
      properties: [
        'mrn',
        'goodsItem',
        'status',
        'declarationType',
      ],
    },
    utuEntry: {
      headings: [
        { value: formatMessage(messages.columnLabelMRN) },
        { value: formatMessage(messages.columnLabelTransportDocument) },
        { value: formatMessage(messages.columnLabelGoodsItemAmount) },
        { value: formatMessage(messages.columnLabelActions) },
      ],
      properties: [
        'mrn',
        'transportDocumentID',
        'amountOfGoodsItems',
        'actions',
      ],
    },
  }

  const usedProperties = isUtuEntry ? tableProperties.utuEntry : tableProperties.default

  const formatters = {
    status: row => (has(messages, `state_${row.state}`) ?
      <FormattedMessage {...messages[`state_${row.state}`]} /> : row.state),
    declarationType: row => (has(messages, `type_${row.type}`) ?
      <FormattedMessage {...messages[`type_${row.type}`]} /> : row.type),
    actions: (row, heading, index) => {
      return <div key={index}>
        <div id={`action-buttons-${index}`} className={styles.actionButtons}>
          <ActionButton
            id={`btn-action-copy-${index}`}
            onClick={() => onRowClick({ show: true, rowData: row })}
          >
            <Icon name="row-add" md />
            {formatMessage(messages.showGoodsItemsButtonText)}
          </ActionButton>
        </div>
      </div>
    },
  }

  return (
    <ErrorBoundary>
      {noRows && noRowsRenderer({ ...messages.noResults, values: { mrn: MRN } })}
      {!noRows &&
        <Table
          {...usedProperties}
          contentRows={rows}
          formatters={formatters}
          sortable
          sortByColumn="mrn"
          sortByDirection={DESCENDING}
        />
      }
    </ErrorBoundary>
  )
}

export default injectIntl(SearchResults)
