import React from 'react'
import { FormGroup, Col } from 'react-bootstrap'
import { injectIntl } from 'react-intl'
import { Field } from 'redux-form'
import LabelArea from 'src/components/form/LabelArea'
import messages from '../messages'
import { parseInteger, parseString } from '../../../utils'
import RowFormParseOnBlurInput from './RowFormParseOnBlurInput'

function rowFormQuantityFields(props) {
  const {
    optional,
    disabled,
    helpTexts,
    requiredUnitForQuantityInSU,
    warnings,
    forceValidation,
    cn8Code,
    intl: { formatMessage },
  } = props

  const quantityInSuUnit = requiredUnitForQuantityInSU ? ` (${requiredUnitForQuantityInSU})` : ''

  const netMassLabel = `${formatMessage(messages.netMassInputLabel)} (${formatMessage(messages.abbreviationKilograms)})`
  const quantityInSuLabel = `${formatMessage(messages.quantityInSUInputLabel)}${quantityInSuUnit}`

  return (
    <FormGroup className="clearfix row">
      <Col sm={6}>
        <LabelArea
          input={{ name: 'netMass' }}
          help={{
            content: helpTexts.netMass,
          }}
          mandatory={cn8Code && !optional && !requiredUnitForQuantityInSU}
          disabled={disabled}
          htmlFor="netMass"
          label={netMassLabel}
        />
        <Field
          labelMessage={messages.netMassInputLabel}
          component={RowFormParseOnBlurInput}
          name="netMass"
          parse={parseString}
          mandatory={!optional && !requiredUnitForQuantityInSU}
          props={{
            id: 'netMass',
            disabled,
            forceValidation,
            type: 'text',
            componentClass: 'input',
            placeholderMessage: messages.netMassInputPlaceholder,
            warning: warnings.netMass,
            onBlurParser: parseInteger,
            'aria-required': !optional && !requiredUnitForQuantityInSU,
          }}
        />
      </Col>
      <Col sm={6}>
        <LabelArea
          input={{ name: 'quantityInSu' }}
          help={{
            content: helpTexts.quantityInSU,
          }}
          mandatory={!optional && requiredUnitForQuantityInSU}
          disabled={disabled || !requiredUnitForQuantityInSU}
          htmlFor="quantityInSU"
          label={quantityInSuLabel}
        />
        <Field
          labelMessage={messages.quantityInSUInputLabel}
          component={RowFormParseOnBlurInput}
          name="quantityInSU"
          parse={parseString}
          mandatory={!optional && requiredUnitForQuantityInSU}
          props={{
            id: 'quantityInSU',
            forceValidation,
            disabled: disabled || !requiredUnitForQuantityInSU,
            type: 'text',
            componentClass: 'input',
            placeholderMessage: messages.quantityInSUInputPlaceholder,
            warning: warnings.quantityInSU,
            onBlurParser: parseInteger,
            'aria-required': !optional && requiredUnitForQuantityInSU,
          }}
        />
      </Col>
    </FormGroup>
  )
}

export default injectIntl(rowFormQuantityFields)
