import { defineMessages } from 'react-intl'

export default defineMessages({
  statusDraft: {
    id: 'intrastat.listing.statusDraft',
    description: 'Value for Intrastat declarations list status "draft"',
    defaultMessage: 'Draft',
  },
  statusError: {
    id: 'intrastat.listing.statusError',
    description: 'Value for Intrastat declarations list status "error"',
    defaultMessage: 'Error',
  },
  statusReady: {
    id: 'intrastat.listing.statusReady',
    description: 'Value for Intrastat declarations list status "ready"',
    defaultMessage: 'Ready',
  },
  statusInvalidated: {
    id: 'intrastat.listing.statusInvalidated',
    description: 'Value for Intrastat declarations list status "invalidated"',
    defaultMessage: 'Invalidated',
  },
  statusDefaced: {
    id: 'intrastat.listing.statusDefaced',
    description: 'Warning message for intrastat declaration status defaced',
    defaultMessage: 'Defaced',
  },
  statusDeleted: {
    id: 'intrastat.listing.statusDeleted',
    description: 'Warning message for intrastat declaration status deleted',
    defaultMessage: 'This declaration has status deleted.',
  },
  errorFetchingCustomers: {
    id: 'intrastat.headers.errorFetchingCustomers',
    description: 'Error message shown when customers could not be fetched.',
    defaultMessage: 'Could not fetch customer information.',
  },
})
