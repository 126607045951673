import React, { useEffect } from 'react'
import { Col, Row, Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { get, isEmpty } from 'lodash'
import moment from 'moment'
import InfoArea from 'src/components/InfoArea'
import Icon from 'src/components/Icon'
import ErrorBoundary from 'src/components/ErrorBoundary'
import PanelNavigation from 'src/components/PanelNavigation'
import Heading from 'src/layout/Heading'

import { FORMS_ROUTE_PATH } from '../../../constants'
import { LOCATION_INFORMATION_FORM_ROUTE_PATH, PRINT_SUMMARY_ROUTE_PATH, CUSTOMER_INFORMATION_PATH } from '../../constants'
import styles from './Summary.scss'
import SummaryContent from './SummaryContent'

export default function Summary(props) {
  const {
    history,
    formData,
    cmsMessages,
  } = props

  useEffect(() => {
    if (isEmpty(formData)) {
      history.replace(`/${FORMS_ROUTE_PATH}/${LOCATION_INFORMATION_FORM_ROUTE_PATH}`)
    }
  }, [formData])

  const title = cmsMessages['/forms/location-information/summary/title']
  const panelHeading = cmsMessages['/forms/location-information/summary/panelHeading']
  const topBoxHeading = cmsMessages['/forms/location-information/summary/topBoxHeading']
  const topBoxContent = cmsMessages['/forms/location-information/summary/topBoxContent']
  const backButtonText = cmsMessages['/forms/location-information/summary/backButtonText']
  const printButtonText = cmsMessages['/forms/location-information/summary/printButtonText']
  const date = get(formData, 'date', '')
  const dateFormatted = moment(date).format('DD.MM.YYYY HH:mm:ss')
  const code = get(formData, 'id', '')

  const heading = (
    <Heading
      message={title}
    />
  )

  const summaryTitle = `${panelHeading} (${dateFormatted}, ${code})`

  return (
    <div>
      {heading}
      <main className="container" id="main">
        <Row>
          <Col xs={12}>
            <InfoArea
              icon="checkmark"
            >
              <div className={styles.topBox}>
                <span className={styles.title}>{topBoxHeading} ({code})</span>
                <span>{topBoxContent}</span>
              </div>
            </InfoArea>
            <ErrorBoundary>
              <PanelNavigation
                title={summaryTitle}
                active
              >
                <SummaryContent
                  formData={formData}
                  cmsMessages={cmsMessages}
                />
                <div style={{ margin: '20px 10px' }}>
                  <Link active={false} to={`/${CUSTOMER_INFORMATION_PATH}`}>
                    <Button
                      bsStyle="default"
                      id-qa-test="button-return"
                    >
                      <Icon name="chevron-left" /> {backButtonText}
                    </Button>
                  </Link>
                  <Link
                    to={`/${FORMS_ROUTE_PATH}/${LOCATION_INFORMATION_FORM_ROUTE_PATH}/${PRINT_SUMMARY_ROUTE_PATH}/print`}
                  >
                    <Button
                      bsStyle="default"
                      className="pull-right"
                      id-qa-test="button-print"
                    >
                      <Icon name="printer" /> {printButtonText}
                    </Button>
                  </Link>
                </div>
              </PanelNavigation>
            </ErrorBoundary>
          </Col>
        </Row>
      </main>
    </div>
  )
}
