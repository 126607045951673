import {
  initialize,
  stopSubmit,
  destroy,
  SubmissionError,
  getFormValues,
  change,
} from 'redux-form'
import { createDefaultAction } from 'src/utils/redux'
import { find, omitBy, omit, isNil, get } from 'lodash'
import { showGlobalNotification, loadingAction } from 'src/actions'
import logger from 'src/utils/logger'
import commonApiMessages from 'src/utils/apiMessages'
import intrastatApiMessages from './apiMessages'
import {
  INTRASTAT_FORM_NAME,
  ERROR_CODE_DECLARATION_EXISTS,
  DECLARATION_STATUS_DELETED,
  DECLARATION_STATUS_DEFACED,
  HEADERS_ROUTE_PATH,
  FORM_STEP_CHANGE,
  DECLARATION_IS_DUPLICATE,
  DECLARATION_SET_TO_NEW,
  TOGGLE_PRESERVE_DRAFT_ON_EXIT_MODAL,
  TOGGLE_DECLARATION_AMENDMENT_MODAL,
  TOGGLE_DELETE_DECLARATION_MODAL,
  TOGGLE_COPY_DECLARATION_MODAL,
  TOGGLE_CONFIRM_CLOSE_MODAL,
  DESTROY_DECLARATION_STATE,
  DECLARATION_DELETED,
  DECLARATION_DEFACED,
  DECLARATION_VERSION_CREATED,
  DECLARATION_COPIED,
} from './constants'
import { getFieldErrors } from '../../../utils/validation'
import { apiCall } from '../../../utils/http'
import { fetchHeaders } from './routes/headers/actions'
import { fetchDeclarationRows } from './routes/rows/actions'
import { fetchNonCachedCustomers } from '../actions'
import { getSelectedDelegateCompanyFromAuthentication } from '../../../utils/auth'
import messages from './messages'
import { getStepPath } from './utils'
import api from '../api'

const apiMessages = {
  ...commonApiMessages,
  ...intrastatApiMessages,
}

export const stepChange = createDefaultAction(FORM_STEP_CHANGE)
export const duplicateDeclaration = createDefaultAction(DECLARATION_IS_DUPLICATE)
export const setToNew = createDefaultAction(DECLARATION_SET_TO_NEW)
export const togglePreserveDraftOnExitModal = createDefaultAction(TOGGLE_PRESERVE_DRAFT_ON_EXIT_MODAL)
export const toggleAmendmentModal = createDefaultAction(TOGGLE_DECLARATION_AMENDMENT_MODAL)
export const toggleCopyModal = createDefaultAction(TOGGLE_COPY_DECLARATION_MODAL)
export const toggleConfirmCloseModal = createDefaultAction(TOGGLE_CONFIRM_CLOSE_MODAL)
export const destroyDeclarationState = createDefaultAction(DESTROY_DECLARATION_STATE)

/**
 * Handle network and server errors in failed (non persisted) save operation.
 *
 * @throws {Error|SubmissionError}
 */
export const handleServerErrors = (dispatch, error) => {
  /* eslint-disable no-underscore-dangle */
  if (error.name === 'ServerValidationError' && error.value) {
    if (error.value.errors && find(error.value.errors, it => it.code === ERROR_CODE_DECLARATION_EXISTS) !== undefined) {
      dispatch(duplicateDeclaration(true))
    }
    const i18nErrors = error.getFieldErrors(apiMessages)
    if (!i18nErrors._error && error.value.code && apiMessages[error.value.code]) {
      i18nErrors._error = apiMessages[error.value.code]
    } else if (!i18nErrors._error && i18nErrors.totalNumberLines) {
      // totalNumberLines is not shown in UI, add it to global errors
      i18nErrors._error = i18nErrors.totalNumberLines
    }
    // Throw SubmissionError for Redux Form
    throw new SubmissionError({ ...i18nErrors })
  }
  throw error
}


export const initializeForm = (declarationId, history) =>
  (dispatch, getState) => {
    // Initialize empty form for creating new declaration
    if (!declarationId) {
      const initialValues = {
        noDeclarationRows: false,
        status: 'RECEIVED',
        source: 'FORM',
      }
      dispatch(initialize(INTRASTAT_FORM_NAME, initialValues, { keepDirty: false, updateUnregisteredFields: true }))
      return
    }

    const userTdp = getSelectedDelegateCompanyFromAuthentication()

    // Initialize empty form first - must not keep any data since there shouldn't be any user edits at this point
    dispatch(initialize(INTRASTAT_FORM_NAME, {}, { keepDirty: false, updateUnregisteredFields: true }))

    // Initialize populated form for declaration editing
    fetchHeaders(declarationId, history)(dispatch, getState)
      .then((response) => {
        const { errors, ...declaration } = response
        const customers = []
        if (userTdp && userTdp.id && declaration.tdp !== userTdp.id) {
          const initialValues = {
            noDeclarationRows: false,
            status: 'RECEIVED',
            source: 'FORM',
          }
          dispatch(initialize(INTRASTAT_FORM_NAME, initialValues, { keepDirty: false, updateUnregisteredFields: true }))
          return
        }
        if (declaration.psi) {
          customers.push(declaration.psi)
        }
        if (declaration.tdp && declaration.tdp !== declaration.psi) {
          customers.push(declaration.tdp)
        }
        // In some cases (eg. after copying) a declaration already exists
        // but does not have a source attribute associated with it.
        if (!declaration.source) {
          declaration.source = 'FORM'
        }
        fetchNonCachedCustomers(customers)(dispatch, getState)

        // Fetching rows may take a while, initialize headers while waiting
        dispatch(
          initialize(
            INTRASTAT_FORM_NAME,
            declaration,
            { keepDirty: false, updateUnregisteredFields: true }
          )
        )

        fetchDeclarationRows(declaration.declarationId)(dispatch, getState)
          .then((rows) => {
            const currentlyOpenDeclarationId = get(getState(), 'form.intrastat.values.declarationId')

            // Fetching declaration rows may take some time, during which the user may navigate to another declaration
            // or leave the declaration form entirely. Since we (currently) have no method to cancel the already
            // sent requests, we have to make sure that the data received is still relevant to the user's context.
            if (currentlyOpenDeclarationId !== declaration.declarationId) {
              return
            }

            if (rows && rows.length) {
              dispatch(
                initialize(
                  INTRASTAT_FORM_NAME,
                  { ...declaration,
                    rows,
                  },
                  { keepDirty: true, updateUnregisteredFields: true }
                )
              )
            }
            if (errors) {
              dispatch(stopSubmit(INTRASTAT_FORM_NAME, getFieldErrors(response, apiMessages)))
            }
          })
      })
  }

export const destroyState = () =>
  (dispatch) => {
    dispatch(destroy(INTRASTAT_FORM_NAME))
    dispatch(destroyDeclarationState())
  }

export const toggleDeleteModal = createDefaultAction(TOGGLE_DELETE_DECLARATION_MODAL)
export const declarationDeleted = createDefaultAction(DECLARATION_DELETED)

export const deleteDraft = (formData, redirectToRoot) =>
  (dispatch, getState) => {
    dispatch(loadingAction({ key: DECLARATION_DELETED, value: true }))
    const {
      id,
      declarationId,
      psi,
      tdp,
    } = formData

    const { config: { bootstrapConfig } } = getState()

    const base = bootstrapConfig.tm_intrastat_ext_url
    const apiUrl = `${base}/declarations/${formData.declarationId}`
    const callParams = {
      method: 'DELETE',
      body: JSON.stringify({
        ...omitBy({ id, declarationId, psi, tdp }, isNil),
      }),
    }
    return apiCall(apiUrl, callParams, {}, dispatch, false)
      .catch(handleServerErrors.bind(this, dispatch))
      .then((response) => {
        const { errors, ...responseDeclaration } = response // eslint-disable-line no-unused-vars
        const rows = getFormValues(INTRASTAT_FORM_NAME)(getState()).rows
        dispatch(initialize(
          INTRASTAT_FORM_NAME,
          { ...responseDeclaration, rows },
          { keepDirty: false, keepSubmitSucceeded: true }
        ))
        if (responseDeclaration.status === DECLARATION_STATUS_DELETED) {
          dispatch(declarationDeleted())
          dispatch(loadingAction({ key: DECLARATION_DELETED, value: false }))
          dispatch(showGlobalNotification({
            level: 'success',
            autoDismiss: true,
            message: {
              ...messages.doneDeletingDeclaration,
              values: {
                declarationId,
              },
            },
          }))
          redirectToRoot()
        } else {
          throw new Error('Virhe luonnoksen poistamisessa')
        }
      })
      .catch((error) => {
        logger.error('Error in deleting Intrastat declaration', error)
        const message = get(error, 'errors._error')
        dispatch(declarationDeleted(error, { message }))
        dispatch(showGlobalNotification({
          level: 'error',
          modal: true,
          message: messages.errorDeletingDeclaration,
          additionalInfo: message && message.id ? message : undefined,
        }))
      })
  }

export const declarationInvalidated = createDefaultAction(DECLARATION_DEFACED)

export const invalidateDeclaration = (rows, declarationId, redirectToRoot) =>
  (dispatch, getState) => {
    dispatch(loadingAction({ key: DECLARATION_DEFACED, value: true }))

    const { config: { bootstrapConfig } } = getState()

    const base = bootstrapConfig.tm_intrastat_ext_url
    const apiUrl = `${base}/declarations/${declarationId}`
    const declarationValues = getState().form.intrastat.values
    const payload = {
      status: DECLARATION_STATUS_DEFACED,
      source: declarationValues.source,
      noDeclarationRows: declarationValues.noDeclarationRows,
      referencePeriod: declarationValues.referencePeriod,
      psi: declarationValues.psi,
      flowCode: declarationValues.flowCode,
    }
    const callParams = {
      method: 'PATCH',
      body: JSON.stringify(payload),
    }
    return apiCall(apiUrl, callParams, {}, dispatch, false)
      .catch(handleServerErrors.bind(this, dispatch))
      .then((response) => {
        const { errors, ...responseDeclaration } = response // eslint-disable-line no-unused-vars
        if (responseDeclaration.status === DECLARATION_STATUS_DEFACED) {
          dispatch(declarationInvalidated())
          dispatch(showGlobalNotification({
            level: 'success',
            autoDismiss: true,
            message: {
              ...messages.doneInvalidatingDeclaration,
              values: {
                declarationId,
              },
            },
          }))
          redirectToRoot()
        } else {
          throw new Error('Virhe ilmoituksen mitätöinnissä')
        }
      })
      .catch((error) => {
        logger.error('Error in invalidating Intrastat declaration', error)
        const message = get(error, 'errors._error')
        dispatch(declarationInvalidated(error, { message }))
        dispatch(loadingAction({ key: DECLARATION_DEFACED, value: false }))
        dispatch(showGlobalNotification({
          level: 'error',
          modal: true,
          message: messages.errorInvalidatingDeclaration,
          additionalInfo: message && message.id ? message : undefined,
        }))
      })
  }

export const declarationVersionCreated = createDefaultAction(DECLARATION_VERSION_CREATED)

export const createAndOpenNewVersion = (declarationId, copyRows, history) =>
  (dispatch, getState) => {
    dispatch(loadingAction({ key: DECLARATION_VERSION_CREATED, value: true }))

    const { config: { bootstrapConfig } } = getState()

    const base = bootstrapConfig.tm_intrastat_ext_url
    const apiUrl = `${base}/declarations/${declarationId}/version?copyRows=${copyRows}`

    return apiCall(apiUrl, { method: 'POST' }, {}, dispatch, false)
      .catch(handleServerErrors.bind(this, dispatch))
      .then((response) => {
        const { errors, ...responseDeclaration } = response // eslint-disable-line no-unused-vars
        dispatch(declarationVersionCreated())
        history.push(getStepPath(HEADERS_ROUTE_PATH, responseDeclaration.declarationId))
        dispatch(loadingAction({ key: DECLARATION_VERSION_CREATED, value: false }))
        dispatch(showGlobalNotification({
          level: 'success',
          autoDismiss: true,
          message: {
            ...messages.doneCreatingNewDeclarationVersion,
            values: {
              oldDeclarationId: declarationId,
              newDeclarationId: responseDeclaration.declarationId,
            },
          },
        }))
      })
      .catch((error) => {
        logger.error('Error in creating Intrastat declaration version', error)
        const message = get(error, 'errors._error')
        dispatch(declarationVersionCreated(error, { message }))
        dispatch(loadingAction({ key: DECLARATION_VERSION_CREATED, value: false }))
        dispatch(showGlobalNotification({
          level: 'error',
          modal: true,
          message: messages.errorCreatingNewDeclarationVersion,
          additionalInfo: message && message.id ? message : undefined,
        }))
      })
  }

export const declarationCopied = createDefaultAction(DECLARATION_COPIED)

export const createAndOpenNewCopy = (declarationId, referencePeriod, copyRows, copyQuantities, history) =>
  (dispatch, getState) => {
    dispatch(loadingAction({ key: DECLARATION_COPIED, value: true }))

    const { config: { bootstrapConfig } } = getState()

    const base = bootstrapConfig.tm_intrastat_ext_url
    const apiUrl = `${base}/declarations/${declarationId}/copy?` +
      `referencePeriod=${referencePeriod}&copyRows=${copyRows}&discardRowQuantities=${!copyQuantities}`

    return apiCall(apiUrl, { method: 'POST' }, {}, dispatch, false)
      .catch(handleServerErrors.bind(this, dispatch))
      .then((response) => {
        const { errors, ...responseDeclaration } = response // eslint-disable-line no-unused-vars
        dispatch(declarationCopied())
        dispatch(loadingAction({ key: DECLARATION_COPIED, value: false }))
        history.push(getStepPath(HEADERS_ROUTE_PATH, responseDeclaration.declarationId))
        dispatch(showGlobalNotification({
          level: 'success',
          autoDismiss: true,
          message: {
            ...messages.doneCreatingNewDeclarationCopy,
            values: {
              oldDeclarationId: declarationId,
              newDeclarationId: responseDeclaration.declarationId,
            },
          },
        }))
      })
      .catch((error) => {
        logger.error('Error in creating Intrastat declaration copy', error)
        const message = get(error, 'errors._error')
        dispatch(declarationCopied(error, { message }))
        dispatch(loadingAction({ key: DECLARATION_COPIED, value: false }))
        dispatch(showGlobalNotification({
          level: 'error',
          modal: true,
          message: messages.errorCreatingNewDeclarationCopy,
          additionalInfo: message && message.id ? message : undefined,
        }))
      })
  }

export const createAndOpenNewVersionWithoutTdp = (declarationId, copyRows, history) =>
  (dispatch, getState) => {
    dispatch(loadingAction({ key: DECLARATION_VERSION_CREATED, value: true }))
    dispatch(change(INTRASTAT_FORM_NAME, 'tdp', null))

    const declarationValues = getState().form.intrastat.values
    const payloadForInvalidation = {
      status: DECLARATION_STATUS_DEFACED,
      source: declarationValues.source,
      noDeclarationRows: declarationValues.noDeclarationRows,
      referencePeriod: declarationValues.referencePeriod,
      psi: declarationValues.psi,
      flowCode: declarationValues.flowCode,
    }

    return api.createNewDeclarationVersion(declarationId, copyRows)
      .catch(handleServerErrors.bind(this, dispatch))
      .then((response) => {
        const { errors, ...responseDeclaration } = response
        delete responseDeclaration.tdp
        return api.invalidateOldDeclarationVersion(payloadForInvalidation, declarationId)
          .catch(handleServerErrors.bind(this, dispatch))
          .then(() => responseDeclaration)
      }).then(responseDeclaration => api.removeTdpFromDeclaration(responseDeclaration, responseDeclaration.declarationId)
        .catch(handleServerErrors.bind(this, dispatch))
        .then(() => {
          dispatch(declarationVersionCreated())
          history.push(getStepPath(HEADERS_ROUTE_PATH, responseDeclaration.declarationId))
          dispatch(loadingAction({ key: DECLARATION_VERSION_CREATED, value: false }))
          dispatch(showGlobalNotification({
            level: 'success',
            autoDismiss: true,
            message: {
              ...messages.doneCreatingNewDeclarationVersion,
              values: {
                oldDeclarationId: declarationId,
                newDeclarationId: responseDeclaration.declarationId,
              },
            },
          }))
        }))
      .catch((error) => {
        logger.error('Error in creating Intrastat declaration version', error)
        const message = get(error, 'errors._error')
        dispatch(declarationVersionCreated(error, { message }))
        dispatch(loadingAction({ key: DECLARATION_VERSION_CREATED, value: false }))
        dispatch(showGlobalNotification({
          level: 'error',
          modal: true,
          message: messages.errorCreatingNewDeclarationVersion,
          additionalInfo: message && message.id ? message : undefined,
        }))
      })
  }

export const removeTdp = () =>
  (dispatch, getState) => {
    dispatch(change(INTRASTAT_FORM_NAME, 'tdp', null))

    const declarationValues = getState().form.intrastat.values
    const declarationValuesWithoutTdp = omit(declarationValues, 'rows')

    return api.removeTdpFromDeclaration(declarationValuesWithoutTdp, declarationValues.declarationId)
      .catch(handleServerErrors.bind(this, dispatch))
      .then((response) => {
        const { errors, ...responseDeclaration } = response
        if (errors) {
          throw new SubmissionError(getFieldErrors(response, apiMessages))
        }
        return responseDeclaration
      })
      .catch((error) => {
        logger.error('Error in removing Tdp information', JSON.stringify(error))

        if (error && error.errors && error.errors._error) {
          const errorKey = error.errors._error.id
          let errorCode = null
          if (errorKey.startsWith('api.intrastat.exception.')) {
            errorCode = errorKey.slice(24)
          }
          const globalIntlMessage = apiMessages[errorCode]
          if (errorCode) {
            dispatch(showGlobalNotification({
              level: 'error',
              message: globalIntlMessage,
            }))
          }
        }
        throw error
      })
  }
