/**
 * @file Container component connecting presentational React component to Redux state
 * @see {@link http://redux.js.org/docs/basics/UsageWithReact.html}
 * @author Sami Tiilikainen
 */

import { connect } from 'react-redux'
import { reduxForm, getFormValues } from 'redux-form'
import { withRouter } from 'react-router-dom'
import { injectIntl } from 'react-intl'
import { omit } from 'lodash'
import ConfirmationView from './ConfirmationView'
import {
  stepChange,
  duplicateDeclaration,
  deleteDraft,
  togglePreserveDraftOnExitModal,
  toggleAmendmentModal,
  toggleCopyModal,
  toggleDeleteModal,
  createAndOpenNewVersion,
  createAndOpenNewCopy,
  removeTdp,
} from '../../actions'
import {
  submitAcceptedStatus,
  forceSubmitAcceptedStatus,
  submitConfirmation,
  clearFormErrors,
} from './actions'
import {
  INTRASTAT_FORM_NAME,
  DECLARATION_STATUS_ACCEPTED,
  DECLARATION_STATUS_DEFACED,
  DECLARATION_STATUS_DELETED,
  ROWS_ROUTE_PATH,
  CONFIRMATION_ROUTE_PATH,
  DECLARATION_DELETED,
  DECLARATION_VERSION_CREATED,
  DECLARATION_COPIED,
  DECLARATION_HEADERS_FETCHED,
  DECLARATION_ROWS_FETCHED,
  SUBMITTING_HEADERS,
} from '../../constants'
import { getSelectedDelegateCompanyFromAuthentication } from 'src/utils/auth'


const formConfig = {
  form: INTRASTAT_FORM_NAME,
  destroyOnUnmount: false,
}
const mapStateToProps = (state) => {
  const formValues = getFormValues(INTRASTAT_FORM_NAME)(state)
  const declaration = formValues && omit(formValues, 'rows')
  const rows = formValues && formValues.rows
  const {
    currentStep,
    showAmendmentModal,
    showDeleteModal,
    showAcceptedModal,
    duplicateWarning,
    showCopyModal,
    isNew,
  } = state.intrastat.declaration

  const deletingDeclaration = state.loading[DECLARATION_DELETED]
  const creatingNewVersion = state.loading[DECLARATION_VERSION_CREATED]
  const creatingNewCopy = state.loading[DECLARATION_COPIED]
  const fetchingDeclaration = state.loading[DECLARATION_HEADERS_FETCHED]
  const fetchingDeclarationRows = state.loading[DECLARATION_ROWS_FETCHED]
  const submittingHeaders = state.loading[SUBMITTING_HEADERS]

  return {
    declaration,
    rows,
    accepted: declaration && declaration.status === DECLARATION_STATUS_ACCEPTED,
    invalidated: declaration && declaration.status === DECLARATION_STATUS_DEFACED,
    deleted: declaration && declaration.status === DECLARATION_STATUS_DELETED,
    creatingNewVersion,
    creatingNewCopy,
    duplicateWarning,
    showAcceptedModal,
    showDeleteModal,
    showAmendmentModal,
    showCopyModal,
    locale: state.locale,
    currentStep,
    fetchingDeclaration,
    fetchingDeclarationRows,
    deletingDeclaration,
    submittingHeaders,
    thisStep: CONFIRMATION_ROUTE_PATH,
    nextStep: null,
    prevStep: ROWS_ROUTE_PATH,
    cachedCustomers: state.intrastat.common.cachedCustomers,
    id: declaration && declaration.id,
    isNew,
    nilDeclaration: formValues && formValues.noDeclarationRows,
    selectedDelegateCompanyObject: getSelectedDelegateCompanyFromAuthentication(),
  }
}

const mapActionCreators = {
  onStepChange: stepChange,
  onSubmit: submitConfirmation,
  onShowAmendmentModal: () => toggleAmendmentModal(true),
  onHideAmendmentModal: () => toggleAmendmentModal(false),
  onShowCopyModal: () => toggleCopyModal(true),
  onHideCopyModal: () => toggleCopyModal(false),
  onShowDeleteModal: () => toggleDeleteModal(true),
  onHideDeleteModal: () => toggleDeleteModal(false),
  createAndOpenNewVersion,
  createAndOpenNewCopy,
  deleteDraft,
  submitAcceptedStatus,
  forceSubmitAcceptedStatus,
  showPreserveDraftOnExitModalAction: () => togglePreserveDraftOnExitModal(true),
  dismissDuplicateWarning: () => duplicateDeclaration(false),
  clearFormErrors,
  removeTdp,
}

export default withRouter(
  connect(mapStateToProps, mapActionCreators)(
    reduxForm(formConfig)(
      injectIntl(
        ConfirmationView
      ))))
