import React from 'react'
import classNames from 'classnames'
import { injectIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import Loader from 'src/components/Loader'
import { get, isEqual } from 'lodash'

import Modal from '../../../components/Modal'
import Heading from '../../../layout/Heading'
import Icon from '../../../components/Icon'
import styles from './CustomerInformationPage.scss'
import CustomerInformationPanel from './CustomerInformationPanel'
import GuaranteeInformationPanel from './GuaranteeInformationPanel'
import LocationInformationPanel from './LocationInformationPanel'
import LocationModalContent from './LocationModalContent'
import ErrorBoundary from '../../../components/ErrorBoundary'
import { focusElementById } from '../../../utils/index'

class CustomerInformationPage extends React.Component {
  state = {
    showModal: false,
    rowData: {},
  }
  componentDidMount() {
    this.fetchCustomer()
    this.fetchGuaranteeStatuses()
  }

  componentDidUpdate(prevProps) {
    const { selectedAuthorizationObject } = this.props

    if (selectedAuthorizationObject && prevProps.selectedAuthorizationObject &&
      selectedAuthorizationObject.id !== prevProps.selectedAuthorizationObject.id) {
      this.fetchCustomer()
      this.fetchGuaranteeStatuses()
    }

    if ((!isEqual(this.props.customer.fetchingCustomer, prevProps.customer.fetchingCustomer)
      || !isEqual(this.props.customer.fetchingGuaranteeStatuses, prevProps.customer.fetchingGuaranteeStatuses))
      && this.props.customer.fetchingGuaranteeStatuses === false
      && this.props.customer.fetchingCustomer === false) {
      this.handleCustomerInformation()
    }
  }

  fetchCustomer = (props = this.props) => {
    const { selectedAuthorizationObject, fetchCustomerInformation, fetchingCustomer } = props
    if (selectedAuthorizationObject && !fetchingCustomer) {
      fetchCustomerInformation(selectedAuthorizationObject.id, true, true)
    }
  }

  handleCustomerInformation = () => {
    const { customer, selectedAuthorizationObject } = this.props
    const selectedCustomer = get(customer.customerInformation, `[${selectedAuthorizationObject.id}]`, null)
    // NAKKI-1727 If the user has no offices, they are redirected to the front page
    const offices = get(selectedCustomer, 'offices', [])

    if (selectedCustomer && offices.length === 0) {
      this.dontShowCustomerInformation()
    } else if (selectedCustomer === null &&
      customer.error &&
      customer.error.code === 'customer.notFound') {
      this.dontShowCustomerInformation()
    }
  }

  fetchGuaranteeStatuses = () => {
    const {
      selectedAuthorizationObject,
      fetchGuaranteeStatuses,
      enableGuarantees,
    } = this.props
    const { eori, id } = selectedAuthorizationObject

    if (fetchGuaranteeStatuses && selectedAuthorizationObject && enableGuarantees && eori) {
      fetchGuaranteeStatuses(eori, id)
    }
  }

  dontShowCustomerInformation = () => {
    const { setShowNoCustomerInfoModal, history } = this.props
    history.push('/')
    setShowNoCustomerInfoModal(true)
  }

  hideModal = () => {
    this.setState({ showModal: false })
  }

  openModal = (rowProperties) => {
    const { row } = rowProperties
    this.setState({ showModal: true, rowData: row })
    focusElementById('location-information-modal-officeId', 300)
  }

  renderModal = (rowData) => {
    const { selectedAuthorizationObject, customer, closeModalButtonText } = this.props
    const { id = '' } = rowData

    const customerInformation = get(customer, `customerInformation[${selectedAuthorizationObject.id}]`, null)
    const office = get(customerInformation, 'offices', []).find((off) => {
      const splitIdArray = id.split(' ')
      const officeCode = splitIdArray[1]

      return officeCode === off.customerOfficeCode
    })

    if (!office) {
      return null
    }

    const title = `${id}, ${office.name}`

    return (
      <Modal
        titleMessage={title}
        show={this.state.showModal}
        onEscKey={this.hideModal}
        showContinue
        showCancel={false}
        onClickContinue={this.hideModal}
        continueMessage={closeModalButtonText}
        className={styles.modalDialog}
        bodyStyle={{ paddingLeft: '0', paddingRight: '0', paddingTop: '20px', backgroundColor: 'white' }}
      >
        <LocationModalContent
          office={office}
          fullName={customerInformation.fullName}
          id={id}
        />
      </Modal>
    )
  }

  render() {
    const {
      customer,
      enableGuarantees,
      customerInformationPageContent,
      selectedAuthorizationObject,
    } = this.props

    const {
      backButtonText,
      instructionsButtonLink,
      instructionsButtonText,
      pageTitle,
    } = customerInformationPageContent

    const customerInformation = get(customer, `customerInformation[${selectedAuthorizationObject.id}]`, null)
    return (
      <div className={styles.customerInformation}>
        <Heading
          wrapHeadingContent
          className={styles.heading}
          message={pageTitle}
          logoRowContent={(
            <div className={styles.buttonContainer}>
              <Link to="/" className={classNames('btn btn-default', styles.headingButton, styles.leftButton)}>
                <Icon name="arrow-left" />
                <span>{backButtonText}</span>
              </Link>
              <a
                href={instructionsButtonLink}
                target="_blank"
                rel="noopener noreferrer"
                className={classNames('btn btn-default', styles.headingButton)}
              >
                <Icon name="help" />
                <span>{instructionsButtonText}</span>
              </a>
            </div>
          )}
        />
        <ErrorBoundary>
          {this.renderModal(this.state.rowData)}
          <main className="container" id="main">
            {customer.fetchingCustomer === true &&
              <Loader inline center />
            }
            {!customer.fetchingCustomer && !customer.fetchingGuaranteeStatuses &&
              <div>
                <div className={styles.firstPanelRow}>
                  <CustomerInformationPanel
                    selectedAuthorizationObject={selectedAuthorizationObject}
                    wholeRow={!enableGuarantees}
                    className={classNames(styles.customerPanel, styles.panel,
                      enableGuarantees ? styles.rightMargin : '')}
                    customerInformation={customerInformation}
                  />
                  {enableGuarantees &&
                    <GuaranteeInformationPanel
                      className={classNames(styles.guaranteePanel, styles.panel,
                        enableGuarantees ? styles.leftMargin : '')}
                      customerInformation={customerInformation}
                    />
                  }
                </div>
                <LocationInformationPanel
                  className={styles.panel}
                  rowClick={this.openModal}
                  customerInformation={customerInformation}
                />
              </div>
            }
          </main>
        </ErrorBoundary>
      </div>
    )
  }
}

export default CustomerInformationPage
