import React from 'react'
import { injectIntl, defineMessages } from 'react-intl'
import { FormControl } from 'react-bootstrap'
import { find } from 'lodash'
import Icon from 'src/components/Icon'
import styles from 'src/styles/_forms.scss'
import FormControlGroup from './FormControlGroup'

const messages = defineMessages({
  errorNoOptions: {
    id: 'form.error.noOptionsPlaceholder',
    description: 'Error placeholder for input without necessary data',
    defaultMessage: 'Error in loading options',
  },
})

const Select = injectIntl((props) => {
  const {
    input,
    multiple,
    disabled,
    options,
    onChange,
    mandatory,
    nonExistingValueFormatter,
    fetchError,
    ariaLabel,
    labelId,
    intl: { formatMessage },
    warning,
    warningText,
    autoFocus,
    onBlur,
  } = props

  let {
    placeholderMessage,
  } = props

  const onChangeObject = {}

  if (onChange) {
    onChangeObject.onChange = onChange
  }

  if (fetchError && !(options && options.length)) {
    placeholderMessage = messages.errorNoOptions
  }

  let combinedOptions = []

  if (placeholderMessage) {
    combinedOptions.push(
      <option key="placeholder" disabled={mandatory} hidden={mandatory} value="" id-qa-test={'option-placeholder'}>
        {formatMessage(placeholderMessage)}
      </option>
    )
  }

  const currentValueNotInOptions = Boolean(input.value && !find(options, it => it.value === input.value))

  if (currentValueNotInOptions) {
    combinedOptions.push(
      <option key={input.value} value={input.value} id-qa-test={`option-${input.value}`}>
        {nonExistingValueFormatter ? nonExistingValueFormatter(input.value) : input.value}
      </option>
    )
  }

  const groups = {
  }
  const nonGrouped = []

  if (options) {
    options.forEach((option) => {
      if (option.group) {
        const groupLabel = formatMessage(option.group.labelMessage, option.group.labelMessageParams)

        if (!groups[groupLabel]) {
          groups[groupLabel] = []
        }

        groups[groupLabel].push(option)
      } else {
        nonGrouped.push(option)
      }
    })
  }

  Object
    .keys(groups)
    .sort((a, b) => groups[a][0].group.sort - groups[b][0].group.sort)
    .forEach((key) => {
      const groupOptions = groups[key].map(option =>
        <option key={option.id} value={option.value} id-qa-test={`option-${option.value}`}>
          {option.message ? formatMessage(option.message) : option.title}
        </option>
      )

      combinedOptions.push(
        <optgroup
          key={key}
          label={key}
        >
          {groupOptions}
        </optgroup>
      )
    })

  combinedOptions = combinedOptions.concat(nonGrouped.map(option =>
    <option key={option.id} value={option.value} id-qa-test={`option-${option.value}`}>
      {option.message ? formatMessage(option.message) : option.title}
    </option>
  ))

  return (
    <div>
      <FormControl
        {...input}
        id-qa-test={`select-${props.input.name}`}
        componentClass="select"
        disabled={disabled}
        multiple={multiple}
        aria-label={ariaLabel}
        aria-labelledby={labelId}
        aria-required={mandatory}
        {...onChangeObject}
        autoFocus={autoFocus}
        aria-describedby={`${props.input.name}-error ${props.input.name}-warning`}
        onBlur={onBlur}
      >
        {combinedOptions}
      </FormControl>
      <div>
        <span
          className={styles.inputActionIcon}
          style={{ pointerEvents: 'none', cursor: 'pointer' }}
        >
          <Icon
            name="chevron-tight-down"
            inline
          />
        </span>
      </div>
      { warning &&
        <div id={`${props.input.name}-warning`} className="help-block">
          <span>{warningText}</span>
        </div>
      }
    </div>
  )
})

export default Select
/**
 * Wraps Select with FormControlGroup
 */
export function SelectFieldGroup(props) {
  return (
    <FormControlGroup {...props}>
      <Select {...props} />
    </FormControlGroup>
  )
}
