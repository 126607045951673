import React from 'react'
import { injectIntl } from 'react-intl'
import { FormGroup } from 'react-bootstrap'
import { getValidationState } from '../../utils/validation'
import LabelArea from './LabelArea'
import InputArea from './InputArea'

/**
 * Form control group including help, label, validation and optionally a form control.
 * Form control is created if componentClass is provided in props.
 */
function FormControlGroup(props) {
  const {
    label,
    input,
    children,
    forceValidation,
    warning,
    intl: { formatMessage },
  } = props

  const labelContent = (label && label.id) ? formatMessage(label) : label
  return (
    <FormGroup
      controlId={input.name}
      style={props.formGroupStyle}
      validationState={getValidationState(props, !forceValidation)}
      className={warning ? 'clearfix has-warning' : 'clearfix'}
    >
      {label &&
        <LabelArea
          id={`${input.name}Label`}
          input={input}
          help={props.help}
          mandatory={props.mandatory}
          disabled={props.disabled}
          warning={props.warning}
          label={labelContent}
        />
      }
      <InputArea
        {...props}
        forceValidation={forceValidation}
      >
        {children}
      </InputArea>
    </FormGroup>
  )
}

export default injectIntl(FormControlGroup)
