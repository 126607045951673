import { createDefaultAction } from 'src/utils/redux'
import { showGlobalNotification } from 'src/actions'
import { apiCall } from 'src/utils/http'
import {
  FORMS_SAVE_DATA,
  FORMS_DESTROY_STATE,
} from './constants'
import messages from './messages'

export const destroyFormsState = createDefaultAction(FORMS_DESTROY_STATE)

export const saveFormData = createDefaultAction(FORMS_SAVE_DATA)

export const submitForm = formData =>
  (dispatch, getState) => {
    const { config: { bootstrapConfig } } = getState()

    const mappedData = []
    // eslint-disable-next-line array-callback-return
    formData.data.map((item) => {
      let title = item.title
      if (item.id === 'vatNumbers') {
        title = `${formData.vatHeading}\n${item.title}`
      }
      mappedData.push({
        title,
        value: item.value,
      })
    })

    const base = bootstrapConfig.tm_formula_ext_url
    const apiUrl = `${base}/form/${formData.formType}/${formData.locale}?customerType=BUSINESS`
    const callParams = {
      method: 'POST',
      body: JSON.stringify(mappedData),
    }

    return apiCall(apiUrl, callParams, { }, undefined, false)
      .then((response) => {
        dispatch(saveFormData({
          ...formData,
          id: response.id,
          date: response.date,
        }))
      })
      .catch(() => {
        dispatch(showGlobalNotification({
          level: 'error',
          modal: true,
          message: messages.errorSubmittingForm,
        }))
      })
  }
