import { handleActions, combineActions } from 'redux-actions'
import { get } from 'lodash'
import { PERMITS_DESTROY_STATE } from '../constants'
import {
  DESTROY_PERMIT_STATE,
  PERMIT_STRUCTURE_FETCHED,
  PERMIT_DATA_FETCHED,
  APPLICATION_ATTACHMENT_UPLOADING,
  APPLICATION_ATTACHMENT_UPLOADED,
  APPLICATION_ATTACHMENT_IN_PROGRESS,
  SAVE_INITIAL_VALUES,
} from './constants'

const initialState = {
  currentStep: false,
  errorFetchingPermit: false,
  isUploadingAttachment: false,
  isAttachmentInProgress: false,
}

const commonReducerMap = {
  [PERMIT_STRUCTURE_FETCHED]: (state, { payload }) => ({
    ...state,
    structure: payload,
  }),
  [PERMIT_DATA_FETCHED]: {
    next: state => ({
      ...state,
      errorFetchingPermit: false,
    }),
    throw: (state, action) => ({
      ...state,
      errorFetchingPermit: get(action, 'meta.message') || true,
    }),
  },
  [combineActions(DESTROY_PERMIT_STATE, PERMITS_DESTROY_STATE)]: () => ({
    ...initialState,
  }),
  [APPLICATION_ATTACHMENT_UPLOADING]: state => ({
    ...state,
    isUploadingAttachment: true,
  }),
  [APPLICATION_ATTACHMENT_UPLOADED]: state => ({
    ...state,
    isUploadingAttachment: false,
  }),
  [APPLICATION_ATTACHMENT_IN_PROGRESS]: (state, { payload }) => ({
    ...state,
    isAttachmentInProgress: payload,
  }),
  [SAVE_INITIAL_VALUES]: (state, { payload }) => ({
    ...state,
    initialValues: payload,
  }),
}

export default handleActions({
  ...commonReducerMap,
}, initialState)
