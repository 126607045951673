import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Grid, Col, Row } from 'react-bootstrap'
import { FormattedMessage, injectIntl } from 'react-intl'
import { get, has } from 'lodash'
import ReactMarkdown from 'react-markdown'
import moment from 'moment'
import Loader from 'src/components/Loader'
import showNotification from 'src/utils/notifications'
import ErrorBoundary from 'src/components/ErrorBoundary'
import PanelNavigation from 'src/components/PanelNavigation'
import InfoArea from 'src/components/InfoArea'
import Heading from 'src/layout/Heading'
import { collectCmsMessages } from 'src/utils'
import SearchResults from './components/SearchResults'
import messages from './messages'
import api from './api'
import MRNForm from './MRNForm'
import Modal from '../../components/Modal'
import Table from '../../components/Table/Table'
import styles from './MRNRoot.scss'


function MRNRoot(props) {
  const {
    cmsMessages,
    intl: { formatMessage },
  } = props
  const [componentKey, setComponentKey] = useState(moment()
    .format('x'))
  const [helpTexts, setHelpTexts] = useState({})
  const [isFetching, setIsFetching] = useState(false)
  const [isTemporarilyUnavailable, setIsTemporarilyUnavailable] = useState(false)
  const [MRN, setMRN] = useState(null)
  const [searchResults, setSearchResults] = useState(null)
  // We have to separate the MRN format and the results format when we first search an utu-entry
  // MRN and then try to search for a non utu-entry one
  const [isUtuEntryMrn, setIsUtuEntryMrn] = useState(false)
  const [useUtuEntryResultsTable, setUseUtuEntryResultsTable] = useState(false)
  const [tooManyResults, setTooManyResults] = useState(false)
  const [modalProps, setModalProps] = useState({
    show: false,
  })

  // Replaces componentDidMount
  useEffect(() => {
    getHelpTexts()
  }, [])

  // Replaces componentDidUpdate
  useEffect(() => {
    getHelpTexts(cmsMessages)
  }, [cmsMessages])

  const getHelpTexts = () => {
    setHelpTexts({
      common: collectCmsMessages('/mrn', cmsMessages),
      fields: collectCmsMessages('/mrn/fields', cmsMessages),
    })
  }

  const renderModal = () => {
    if (!modalProps?.rowData) {
      return null
    }

    const tableProperties = {
      headings: [
        { value: formatMessage(messages.columnLabelGoodsItem) },
        { value: formatMessage(messages.columnLabelGoodsItemStatus) },
      ],
      properties: [
        'goodsItemNumber',
        'state',
      ],
    }

    const formatters = {
      state: row => (has(messages, `state_${row.state}`) ?
        <FormattedMessage {...messages[`state_${row.state}`]} /> : row.state),
    }

    const {
      rowData: {
        transportDocumentID,
        goodsItem,
      },
    } = modalProps

    return (
      <Modal
        titleMessage={formatMessage(messages.modalTitle)?.replace('{transportDocumentID}', transportDocumentID)}
        {...modalProps}
        showContinue={false}
        showCancel={true}
        cancelMessage={messages.modalBack}
        onClickCancel={() => setModalProps({ show: false })}
      >
        <Table
          className={styles.modalTable}
          {...tableProperties}
          contentRows={goodsItem}
          formatters={formatters}
          sortable
          pagination={{
            amountOfResultsPerPage: 5,
            paginationRowText: formatMessage(messages.paginationRowText),
          }}
        />
      </Modal>
    )
  }

  const clearResults = () => {
    setComponentKey(moment().format('x'))
    setTooManyResults(false)
  }

  const doSearch = async (formData) => {
    setIsFetching(true)
    setMRN(formData.mrn)
    setTooManyResults(false)

    try {
      const results = await api.searchMRN(
        formData.mrn,
        formData.goodsItem,
        formData.transportDocumentID,
        isUtuEntryMrn)

      setIsFetching(false)
      setSearchResults(results.rows)

      if (results.isUtuEntry) {
        setUseUtuEntryResultsTable(true)
      } else {
        setUseUtuEntryResultsTable(false)
      }

      document.getElementById('search-results-label')
        ?.focus()
    } catch (error) {
      setIsFetching(false)
      const errorCode = get(error, 'value.code')

      if (errorCode === 'tooManyRequests') {
        setIsTemporarilyUnavailable(true)
      } else if (errorCode === 'TOO_MANY_RESULTS') {
        setTooManyResults(true)
        setSearchResults(null)
      } else {
        showNotification({
          level: 'error',
          message: messages.searchingMrnErrors,
          autoDismiss: true,
        })

        throw error
      }
    }

    return null
  }

  return (
    <div key={componentKey}>
      <Heading message={messages.heading} />
      {isFetching && <Loader blocking />}
      <main className="container" role="main" id="main">
        {renderModal()}
        <Row>
          <Col xs={12}>
            <ErrorBoundary>
              <PanelNavigation
                title={messages.headingPanel}
                active
              >
                <Grid fluid>
                  <Row>
                    <Col xs={12}>
                      {isTemporarilyUnavailable && <InfoArea icon="attention" type="error">
                        <FormattedMessage {...messages.notificationTemporarilyUnavailable} />
                      </InfoArea>}
                    </Col>
                    <Col xs={12} md={10}>
                      <ReactMarkdown
                        source={get(helpTexts, 'common.intro', '')}
                      />
                      <br />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12}>
                      <MRNForm
                        onSubmit={doSearch}
                        onReset={clearResults}
                        helpTexts={helpTexts}
                        tooManyResults={tooManyResults}
                        setIsUtuEntryMrn={setIsUtuEntryMrn}
                        isUtuEntryMrn={isUtuEntryMrn}
                      />
                    </Col>
                  </Row>

                  {searchResults && <Row>
                    <Col xs={12}>
                      <div id="search-results-label" tabIndex="-1" role="heading" aria-level="2">
                        <b>
                          <FormattedMessage

                            {...messages.resultsCount}
                            values={{
                              count: searchResults.length,
                            }}
                          />
                        </b>
                      </div>
                      <SearchResults
                        rows={searchResults}
                        MRN={MRN}
                        isUtuEntry={useUtuEntryResultsTable}
                        onRowClick={setModalProps}
                      />
                    </Col>
                  </Row>}
                </Grid>
              </PanelNavigation>
            </ErrorBoundary>
          </Col>
        </Row>
      </main>
    </div>
  )
}

const mapStateToProps = state => ({
  locale: state.locale,
})

const mapActionCreators = {}

export default connect(mapStateToProps, mapActionCreators)(injectIntl(MRNRoot))
