import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { sendLogoutRequestAndExit } from 'src/actions'
import IATSelector from './IATSelector'
import requireAuthentication from '../../containers/requireAuthentication'
import { checkIATAuthorization } from '../../utils/auth'

const mapStateToProps = state =>
  ({
    auth: state.auth,
    locale: state.locale,
  })

const mapActionCreators = {
  sendLogoutRequestAndExit,
}

export default injectIntl(
  connect(mapStateToProps, mapActionCreators)(requireAuthentication(IATSelector, checkIATAuthorization))
)
