// eslint-disable-next-line import/prefer-default-export
export const referencePeriodToDate = (referencePeriod) => {
  if (!referencePeriod) {
    return referencePeriod
  }
  return `${referencePeriod.substring(0, 4)}-${referencePeriod.substring(4, 6)}-01`
}

export const checkReporterTypes = (reportingUnits, acceptableTypes) => {
  if (reportingUnits) {
    return reportingUnits.some(unit => acceptableTypes.includes(unit.reporterType))
  }

  return false
}
