import React from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'

import IndividualGuaranteeFrontPage from './container'
import IndividualGuaranteePaymentPendingPage from './routes/pending/container'
import IndividualGuaranteePaymentCancelPage from './routes/cancel/container'
import IndividualGuaranteeStopPage from './routes/stop/container'
import PageNotFound from 'src/components/PageNotFound'

import {
  PAYMENT_PENDING_ROUTE,
  PAYMENT_CANCEL_ROUTE,
  STOP_PAGE_ROUTE,
} from './constants'
import requireAuthentication from '../../containers/requireAuthentication'
import { checkIndividualGuaranteeAuthorization } from '../../utils/auth'


function IndividualGuaranteeRoutes() {
  let { path } = useRouteMatch()
  return (
    <Switch>
      <Route exact path={path}>
        <IndividualGuaranteeFrontPage />
      </Route>
      <Route path={`${path}/${PAYMENT_PENDING_ROUTE}/:timestamp/:checksum/:paymentReference/:correlationId/:guaranteeCategory?`}>
        <IndividualGuaranteePaymentPendingPage />
      </Route>
      <Route path={`${path}/${PAYMENT_CANCEL_ROUTE}/:timestamp/:checksum/:paymentReference/:correlationId/:guaranteeCategory?`}>
        <IndividualGuaranteePaymentCancelPage />
      </Route>
      <Route path={`${path}/${STOP_PAGE_ROUTE}`}>
        <IndividualGuaranteeStopPage />
      </Route>
      <Route component={PageNotFound} />
    </Switch>
  )
}

export default requireAuthentication(
  IndividualGuaranteeRoutes,
  checkIndividualGuaranteeAuthorization,
)
