import React from 'react'
import { get } from 'lodash'
import { FormattedMessage } from 'react-intl'
import { Nav, NavItem, Col, Row, Well, Button } from 'react-bootstrap'
import { AutoAffix } from 'react-overlays'
import { withRouter } from 'react-router-dom'
import moment from 'moment'
import ErrorBoundary from 'src/components/ErrorBoundary'
import Heading from 'src/layout/Heading'
import Modal from 'src/components/Modal'
import Icon from 'src/components/Icon'
import Loader from 'src/components/Loader'
import PanelNavigation from 'src/components/PanelNavigation'
import styles from 'src/styles/_invalidRows.scss'
import { getStepPath, getDeclarationIdFromRoute, formatReferencePeriod, getStepPathForHref } from '../utils'
import messages from '../messages'
import { ROWS_ROUTE_PATH, HEADERS_ROUTE_PATH, CONFIRMATION_ROUTE_PATH } from '../constants'
import { INTRASTAT_ROUTE_PATH } from '../../constants'
import ConfirmCloseModal from './ConfirmCloseModal'
import declarationStyles from './Declaration.scss'

class EditDeclaration extends React.Component {
  constructor(props) {
    super(props)
    this.preserveDraft = this.preserveDraft.bind(this)
    this.showOnlyInvalidRows = this.showOnlyInvalidRows.bind(this)
    this.closeDeclaration = this.closeDeclaration.bind(this)
  }

  UNSAFE_componentWillMount() {
    this.props.initializeForm(getDeclarationIdFromRoute(this.props), this.props.history)
  }

  componentDidMount() {
    const declId = getDeclarationIdFromRoute(this.props)
    if (!declId) {
      this.props.setToNew()
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const prevId = getDeclarationIdFromRoute(this.props)
    const nextId = getDeclarationIdFromRoute(nextProps)
    // If declaration id changes in route from one id to another, reinitialize form with new declaration data
    if (prevId && nextId && prevId !== nextId) {
      this.props.initializeForm(nextId, nextProps.history)
    }
  }

  componentWillUnmount() {
    this.props.destroyState()
  }

  getPanelPropsForCurrentRoute() {
    const {
      location,
      cmsMessages,
      formFieldValues,
      fetchingDeclarationRows,
      intl: { formatMessage },
      rowsCount,
      warningRowsCount,
      isHelpVisible,
      setHelpVisible,
    } = this.props

    const currentRoute = location?.pathname
    if (currentRoute.includes('headers')) {
      let headersTitleExtras
      if (formFieldValues.nextDeclarationId) {
        headersTitleExtras = (
          <span>
            <span className="label label-inverse">
              <FormattedMessage {...messages.replacedDeclarationVersion} />
            </span>
            {headersTitleExtras}
          </span>
        )
      }
      const helpPath = '/declarations/intrastat/global'
      return {
        title: messages.headers,
        titleExtras: headersTitleExtras,
        helpText: get(cmsMessages, helpPath),
        isHelpVisible: () => isHelpVisible(helpPath),
        setHelpVisible: visible => setHelpVisible(helpPath, visible),
      }
    } else if (currentRoute.includes('rows')) {
      let rowCountText
      if (fetchingDeclarationRows) {
        rowCountText = (
          <span>
            <FormattedMessage {...messages.fetchingRows} />
            <Loader small inline inverse style={{ marginBottom: '4px' }} />
          </span>
        )
      } else if (warningRowsCount > 0) {
        rowCountText = (
          <span>
            <a href="#" onClick={this.showOnlyInvalidRows} id-qa-test="link-panelHeading-showOnlyInvalidRows">
              <span className="label label-danger">
                {formatMessage(messages.rowErrorCountSummary, { warningRowsCount })}
              </span>
            </a>
            {formatMessage(messages.rowCountSummary, { rowsCount })}
          </span>
        )
      } else {
        rowCountText = (
          <span>
            {formatMessage(messages.rowCountSummary, { rowsCount })}
          </span>
        )
      }
      return {
        title: messages.rows,
        titleExtras: rowCountText,
        helpText: this.props.rowsCount === 0 ? this.props.intl.formatMessage(messages.zeroRows) : undefined,
      }
    } else if (currentRoute.includes('confirmation')) {
      return {
        title: messages.confirmation,
      }
    }
    return {}
  }

  preserveDraft() {
    this.props.history.push(`/${INTRASTAT_ROUTE_PATH}`)
  }

  showOnlyInvalidRows(event) {
    event.stopPropagation()
    this.props.setOnlyInvalidRowsVisibility(true)
    this.props.history.push(`${getStepPath(ROWS_ROUTE_PATH, getDeclarationIdFromRoute(this.props))}`)
  }

  closeDeclaration() {
    // Correcting a declaration, need to confirm close
    if (this.props.formFieldValues.previousDeclarationId) {
      this.props.onShowConfirmCloseModal()
    } else {
      this.props.history.push(`/${INTRASTAT_ROUTE_PATH}`)
    }
  }

  render() {
    const declarationId = getDeclarationIdFromRoute(this.props)
    const {
      isFormReady,
      isNew,
      children,
      history,
      formFieldValues,
      rowsCount,
      warningRowsCount,
      totalInvoicedAmount,
      totalNetMass,
      psiObject,
      lastSaveTime,
      showConfirmCloseModal,
      onHideConfirmCloseModal,
      showPreserveDraftOnExitModal,
      onHidePreserveDraftOnExitModal,
      deletingDeclaration,
      deleteDraft,
      currentStep,
      fetchingDeclaration,
      fetchingDeclarationRows,
      errorFetchingDeclaration,
      errorFetchingDeclarationRows,
      intl: { formatMessage },
      location,
    } = this.props

    if (!isFormReady) {
      return <Loader blocking />
    }

    if (fetchingDeclaration) {
      return <Loader blocking message={messages.fetchingDeclaration} />
    }

    const intrastatHeading = (
      <Heading message={isNew ? messages.headingNew : messages.headingEdit}>
        <Button
          onClick={this.closeDeclaration}
          className={declarationStyles.exitReadOnlyDeclaration}
          id-qa-test={'btn-exitDeclaration'}
          aria-label={formatMessage(messages.exit)}
        >
          <Icon name="close" /><FormattedMessage {...messages.exit} />
        </Button>
      </Heading>
    )

    if (errorFetchingDeclaration || errorFetchingDeclarationRows) {
      let errorMessage = messages.errorFetchingDeclaration
      if (errorFetchingDeclaration && errorFetchingDeclaration.id) {
        errorMessage = errorFetchingDeclaration
      } else if (errorFetchingDeclarationRows && errorFetchingDeclarationRows.id) {
        errorMessage = errorFetchingDeclarationRows
      }
      return (
        <div>
          {intrastatHeading}
          <div className="lead absolute-centered">
            <FormattedMessage {...errorMessage} />
          </div>
        </div>
      )
    }

    const panelProps = Object.assign(this.getPanelPropsForCurrentRoute(), { intl: this.props.intl })
    let flowCodeMessage
    let flowCodeIcon
    if (formFieldValues.flowCode === 'ARRIVAL_OF_GOODS') {
      flowCodeMessage = messages.importLabel
      flowCodeIcon = 'service-tullaus-import'
    } else if (formFieldValues.flowCode === 'DISPATCH_OF_GOODS') {
      flowCodeMessage = messages.exportLabel
      flowCodeIcon = 'service-tullaus-export'
    }

    const isCurrentElement = PATH => {
      const currentRoute = location?.pathname
      return currentRoute.includes(PATH)
    }

    const InfoElement = props => (
      <span>
        <strong>{props.title} </strong>
        <span id-qa-test={props.testId}>{props.value}</span>
      </span>
    )

    return (
      <div>
        {intrastatHeading}
        <main className="container" role="main" id="main">
          {formFieldValues.declarationId &&
            <Well bsSize="small" className="has-icon">
              {flowCodeIcon &&
                <Icon xlg name={flowCodeIcon} className="well-icon" />
              }
              <div>
                <Row>
                  <Col xs={6} sm={3}>
                    {flowCodeMessage &&
                      <strong style={{ fontSize: '1.6rem' }}>
                        <FormattedMessage {...flowCodeMessage} /> {
                          formatReferencePeriod(formFieldValues.referencePeriod)}
                      </strong>
                    }
                  </Col>
                  <Col xs={6} sm={3}>
                    {formFieldValues.declarationId &&
                      <InfoElement
                        title={<FormattedMessage {...messages.declarationId} />}
                        value={formFieldValues.declarationId}
                        testId="infoRow-declarationId"
                      />
                    }
                  </Col>
                  <Col xs={6} sm={3}>
                    {formFieldValues.noDeclarationRows &&
                      <strong><FormattedMessage {...messages.nilDeclaration} /></strong>
                    }
                    {!formFieldValues.noDeclarationRows &&
                      <InfoElement
                        title={<FormattedMessage {...messages.totalNumberLines} />}
                        value={fetchingDeclarationRows ? <Loader tiny inline /> : rowsCount}
                        testId="infoRow-rowCount"
                      />
                    }
                  </Col>
                  <Col xs={6} sm={3}>
                    <InfoElement
                      title={<FormattedMessage {...messages.totalInvoicedAmount} />}
                      value={fetchingDeclarationRows ? <Loader tiny inline /> : `${totalInvoicedAmount} €`}
                      testId="infoRow-totalInvoicedAmount"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={6} sm={3}>
                    {psiObject && <span id-qa-test="infoRowValue-psi">{psiObject.name} ({psiObject.id})</span>}
                  </Col>
                  <Col xs={6} sm={3}>
                    {formFieldValues.dateOfCreate &&
                      <InfoElement
                        title={<FormattedMessage {...messages.dateCreated} />}
                        value={moment(formFieldValues.dateOfCreate).calendar()}
                        testId="infoRow-dateCreated"
                      />
                    }
                  </Col>
                  <Col xs={6} sm={3}>
                    {warningRowsCount > 0 &&
                      <span className="text-danger bold-first-word">
                        <a
                          href="#"
                          className={styles.linkInvalidRows}
                          onClick={this.showOnlyInvalidRows}
                          id="link-showOnlyInvalidRows"
                          id-qa-test="link-showOnlyInvalidRows"
                        >
                          <Icon name="attention-bold" />
                          <FormattedMessage {...messages.rowErrorCountSummary} values={{ warningRowsCount }} />
                        </a>
                      </span>
                    }
                  </Col>
                  <Col xs={6} sm={3}>
                    <InfoElement
                      title={<FormattedMessage {...messages.totalNetMass} />}
                      value={fetchingDeclarationRows ? <Loader tiny inline /> : `${totalNetMass} kg`}
                      testId="infoRow-totalNetMass"
                    />
                  </Col>
                </Row>
              </div>
            </Well>
          }
          <Row>
            <Col md={2} lg={3}>
              <AutoAffix viewportOffsetTop={20} container={this}>
                <Nav
                  bsStyle="pills"
                  stacked
                  className="horizontal-on-sm"
                  aria-label={formatMessage(messages.declarationPages)}
                >
                  <NavItem
                    id-qa-test={'btn-section-headers'}
                    aria-selected={isCurrentElement(HEADERS_ROUTE_PATH)}
                    // eslint-disable-next-line jsx-a11y/aria-props
                    aria-current={isCurrentElement(HEADERS_ROUTE_PATH) ? 'step' : false}
                    autoFocus={isCurrentElement(HEADERS_ROUTE_PATH)}
                    className={isCurrentElement(HEADERS_ROUTE_PATH) ? 'active' : ''}
                    href={getStepPathForHref(HEADERS_ROUTE_PATH, declarationId)}
                  >
                    {formatMessage(messages.headers)}
                  </NavItem>
                  {(!formFieldValues.noDeclarationRows || rowsCount > 0 || currentStep === ROWS_ROUTE_PATH) &&
                    <NavItem
                      id-qa-test={'btn-section-rows'}
                      aria-selected={isCurrentElement(ROWS_ROUTE_PATH)}
                      // eslint-disable-next-line jsx-a11y/aria-props
                      aria-current={isCurrentElement(ROWS_ROUTE_PATH) ? 'step' : false}
                      autoFocus={isCurrentElement(ROWS_ROUTE_PATH)}
                      className={isCurrentElement(ROWS_ROUTE_PATH) ? 'active' : ''}
                      href={getStepPathForHref(ROWS_ROUTE_PATH, declarationId)}
                    >
                      {formatMessage(messages.rows)}
                    </NavItem>
                  }
                  <NavItem
                    id-qa-test={'btn-section-confirmation'}
                    aria-selected={isCurrentElement(CONFIRMATION_ROUTE_PATH)}
                    // eslint-disable-next-line jsx-a11y/aria-props
                    aria-current={isCurrentElement(CONFIRMATION_ROUTE_PATH) ? 'step' : false}
                    autoFocus={isCurrentElement(CONFIRMATION_ROUTE_PATH)}
                    className={isCurrentElement(CONFIRMATION_ROUTE_PATH) ? 'active' : ''}
                    href={getStepPathForHref(CONFIRMATION_ROUTE_PATH, declarationId)}
                  >
                    {formatMessage(messages.confirmation)}
                  </NavItem>
                </Nav>
              </AutoAffix>
            </Col>
            <Col md={10} lg={9}>
              <ErrorBoundary>
                <PanelNavigation
                  {...panelProps}
                  active
                >
                  {children}
                </PanelNavigation>

                {lastSaveTime &&
                  <div className="text-muted text-right">
                    <FormattedMessage
                      {...messages.lastSave}
                      values={{ time: moment(lastSaveTime).calendar() }}
                    />
                  </div>
                }
              </ErrorBoundary>
            </Col>
          </Row>
        </main>
        {showPreserveDraftOnExitModal &&
          <Modal
            backdrop
            onEscKey={onHidePreserveDraftOnExitModal}
            show={showPreserveDraftOnExitModal}
            showCancel
            cancelMessage={messages.preserveDraftOnExitModalCancel}
            showContinue
            titleMessage={messages.preserveDraftOnExitModalTitle}
            continueMessage={messages.preserveDraftOnExitModalContinue}
            cancelDisabled={deletingDeclaration}
            continueDisabled={deletingDeclaration}
            onClickContinue={this.preserveDraft}
            onClickCancel={
              () => deleteDraft(
                formFieldValues,
                () => history.push(`/${INTRASTAT_ROUTE_PATH}`),
              )
            }
            loading={deletingDeclaration}
          >
            <p className="lead"><FormattedMessage {...messages.preserveDraftOnExitModalMessage} /></p>
          </Modal>
        }
        {showConfirmCloseModal &&
          <ConfirmCloseModal
            onHide={onHideConfirmCloseModal}
            show={showConfirmCloseModal}
            declarationId={this.props.formFieldValues.previousDeclarationId}
            referencePeriod={this.props.formFieldValues.referencePeriod}
          />
        }

      </div>
    )
  }
}

export default withRouter(EditDeclaration)