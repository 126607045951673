import React from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'

import {
  SUMMARY_ROUTE_PATH,
} from './constants'

import EoriFrontPage from './container'
import EoriSummaryPage from './routes/summary'

import EoriReducer from './reducer'
import withReducer from '../../../reducers/withReducer'
import PageNotFound from 'src/components/PageNotFound'
import { checkEoriFormAuthorization } from '../../../utils/auth'
import requireAuthentication from '../../../containers/requireAuthentication'


const EoriRoutes = () => {
  let { path } = useRouteMatch()
  return (
    <Switch>
      <Route exact path={path}>
        <EoriFrontPage />
      </Route>
      <Route path={`${path}/${SUMMARY_ROUTE_PATH}/:viewMode?`}>
        <EoriSummaryPage />
      </Route>
      <Route component={PageNotFound} />
    </Switch>
  )
}

export default withReducer([{ key: 'forms', reducer: EoriReducer }])(requireAuthentication(EoriRoutes, checkEoriFormAuthorization))