import React from 'react'
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl'
import { FormControl } from 'react-bootstrap'
import { get } from 'lodash'
import Checkbox from 'src/components/form/Checkbox'
import Loader from 'src/components/Loader'
import InteractiveElement from 'src/components/InteractiveElement'
import Icon from 'src/components/Icon'
import styles from 'src/styles/_forms.scss'
import { updateStatusMessageForInput } from 'src/utils'
import ValidationMessage from '../validation/ValidationMessage'

export const messages = defineMessages({
  clearInputValue: {
    id: 'form.input.clear',
    description: 'Label for clearing selected form input value',
    defaultMessage: 'Clear selection',
  },
})

/**
 * Input area for a form control. Renders input control, validation result and help panel based on given props
 */
function InputArea(props) {
  const {
    id,
    input,
    inputRef,
    onChange,
    onBlur,
    onClear,
    children,
    componentClass,
    placeholderMessage,
    disableValidation,
    forceValidation,
    disabled,
    mandatory,
    fetchError,
    loading,
    labelId,
    inputStyle,
    meta,
    onBlurNoParam,
    intl: { formatMessage },
  } = props
  let inputComponent

  // This wrapper grabs the validation message (if there is one) and updates it to the global status element
  const onBlurWrapper = (event) => {
    const targetInputName = get(event, 'nativeEvent.target.name')
    const relatedTargetInputName = get(event, 'nativeEvent.relatedTarget.name')
    if (targetInputName !== relatedTargetInputName) {
      updateStatusMessageForInput(input.name)
      if (onBlur) {
        onBlur(event)
      } else if (onBlurNoParam) {
        input.onBlur()
      } else {
        input.onBlur(event)
      }
    }
  }

  if (componentClass === 'checkbox') {
    inputComponent = (
      <Checkbox
        id={id}
        input={input}
        id-qa-test={`input-${input.name}`}
        disabled={disabled || false}
        labelId={labelId || `${input.name}Label`}
        aria-describedby={`${input.name}-error`}
      >
        <FormattedMessage {...placeholderMessage} />
      </Checkbox>
    )
  } else if (componentClass === 'static') {
    inputComponent = (
      <FormControl.Static><FormattedMessage {...placeholderMessage} /></FormControl.Static>
    )
  } else if (componentClass === 'textarea') {
    inputComponent = (
      <FormControl
        id={id}
        {...input}
        id-qa-test={`textarea-${input.name}`}
        componentClass={componentClass}
        style={inputStyle}
        inputRef={inputRef || input.inputRef}
        onChange={onChange || input.onChange}
        onBlur={onBlurWrapper}
        disabled={disabled || false}
        placeholder={placeholderMessage && formatMessage(placeholderMessage)}
        aria-describedby={`${input.name}-error`}
      />
    )
  } else if (componentClass) {
    const ariaProps = {
      'aria-required': mandatory,
    }
    if (labelId) {
      ariaProps['aria-labelledby'] = labelId
    }
    inputComponent = (
      <FormControl
        id={id}
        {...ariaProps}
        {...input}
        id-qa-test={`input-${input.name}`}
        style={inputStyle}
        inputRef={inputRef || input.inputRef}
        onChange={onChange || input.onChange}
        onBlur={onBlurWrapper}
        disabled={disabled || false}
        placeholder={placeholderMessage && formatMessage(placeholderMessage)}
        aria-labelledby={props.labelId}
        aria-describedby={`${input.name}-error`}
      />
    )
  }

  return (
    <div style={{ position: 'relative' }}>
      {loading && <Loader input />}
      {!loading && onClear &&
        <InteractiveElement
          className={styles.inputActionIcon}
          onClick={onClear}
          id-qa-test={`btn-clear-selection-${input.name}`}
        >
          <Icon name="close" inline />
          <span className="sr-only"><FormattedMessage {...messages.clearInputValue} /></span>
        </InteractiveElement>
      }
      {inputComponent}
      {children && React.cloneElement(children, { onBlur: onBlurWrapper })}
      {meta.touched && !disableValidation && <ValidationMessage field={props} requireTouched={!forceValidation} />}
      {fetchError && fetchError.id &&
        <p className="help-block">
          <span className="text-danger">
            <FormattedMessage {...fetchError} />
          </span>
        </p>
      }
    </div>
  )
}

export default injectIntl(InputArea)
