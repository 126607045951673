import React from 'react'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import ReactMarkdown from 'react-markdown'

import PanelNavigation from 'src/components/PanelNavigation'
import classNames from 'classnames'
import { get } from 'lodash'
import { collectCmsMessages } from 'src/utils'

import Icon from '../../../components/Icon'
import styles from './LocationInformationPanel.scss'
import Table from '../../../components/Table/Table'
import { TABLE_COLUMNS, ASCENDING } from '../constants'

function LocationInformationPanel(props) {
  const {
    className,
    tableHeadings,
    tableData,
    tableProperties,
    rowClick,
    locationInformationPanelContent,
  } = props

  const {
    title,
    helpText,
    locationInformationFormButtonText,
    tableCaption,
  } = locationInformationPanelContent

  return (
    <div className={classNames(className, styles.panelContainer)}>
      <PanelNavigation
        id="locationInformation"
        title={title}
        active
        noFocus
        style={{ minHeight: '150px', paddingTop: '0', paddingBottom: '10px', paddingLeft: '0', paddingRight: '0' }}
      >
        <div className={classNames('alert alert-info alert-dismissable', styles.alertBox)}>
          <div className="row">
            <div className={classNames('col-xs-1', styles.iconContainer)}>
              <Icon name="info" className="pull-left icon-blue" lg />
            </div>
            <div className="col-xs-11">
              <div className={styles.alertTexts}>
                <div className={styles.textContainer}>
                  <div id="office-help-panel">
                    <ReactMarkdown
                      source={helpText}
                    />
                  </div>
                </div>
              </div>
              <Link
                to="/forms/location-information"
                className={classNames('btn btn-default', styles.locationInformationFormLink)}
              >
                <span>{locationInformationFormButtonText}</span>
                <Icon className={styles.chevronIcon} name="chevron-tight-right" />
              </Link>
            </div>
          </div>
        </div>
        <div className={styles.tableWrapper}>
          <Table
            headings={tableHeadings}
            contentRows={tableData}
            properties={tableProperties}
            className={styles.contentTable}
            onRowClick={rowClick}
            sortable
            sortByColumn={TABLE_COLUMNS.id}
            sortByDirection={ASCENDING}
            useRowHeadings
            caption={tableCaption}
          />
        </div>
      </PanelNavigation>
    </div>
  )
}

const getTableData = (customerInformation) => {
  const offices = get(customerInformation, 'offices', [])

  return offices.map((office) => {
    const officeId = `${customerInformation.customerIdentifier} ${office.customerOfficeCode}`

    return {
      id: officeId,
      name: office.name,
      address: get(office, 'streetAddress.address', ''),
    }
  })
}

const mapStateToProps = (state, props) => {
  const { customerInformation } = props
  const cmsMessages = get(state, 'content.cmsMessages', {})
  const cmsContent = collectCmsMessages('/customer-information/locationinformation', cmsMessages)
  const headings = [
    { value: cmsContent.idTableHeading },
    { value: cmsContent.nameTableHeading },
    { value: cmsContent.streetAddressTableHeading },
  ]
  const data = getTableData(customerInformation)
  const properties = [TABLE_COLUMNS.id, TABLE_COLUMNS.name, TABLE_COLUMNS.address]

  return {
    locale: state.locale,
    tableHeadings: headings,
    tableData: data,
    tableProperties: properties,
    locationInformationPanelContent: cmsContent,
  }
}

const mapActionCreators = {}

export default connect(mapStateToProps, mapActionCreators)(injectIntl(LocationInformationPanel))
