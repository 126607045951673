import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { injectIntl, FormattedMessage } from 'react-intl'
import classNames from 'classnames'
import { get } from 'lodash'
import { formatUTCdateStringIntoFinnishFormat } from 'src/utils'
import styles from 'src/styles/_tables.scss'
import Table, { DESCENDING } from 'src/components/Table/Table'
import ErrorBoundary from 'src/components/ErrorBoundary'
import Loader from 'src/components/Loader'
import Checkbox from 'src/components/form/Checkbox'
import messages from '../messages'
import api from '../../api'
import {
  PERMIT_DEFAULT_SORT_BY,
} from '../constants'
import {
  PERMIT_STATUS_ACTIVE,
  PERMITS_ROUTE_PATH,
  PERMIT_ROUTE_PATH,
} from '../../constants'
import { INIT_ROUTE_PATH } from '../../permit/constants'
import {
  companySelectionChanged,
  encodeReferenceNumber,
} from '../../permit/utils'

const formatters = {
  decisionDate: (row, property) => (row[property] ? formatUTCdateStringIntoFinnishFormat(row[property]) : '-'),
  startDate: (row, property) => (row[property] ? formatUTCdateStringIntoFinnishFormat(row[property]) : '-'),
  endDate: (row, property) => (row[property] ? formatUTCdateStringIntoFinnishFormat(row[property]) : '-'),
}

class PermitsTab extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      list: [],
      filteredList: [],
      fetchingPermits: false,
    }

    this.onRowClick = this.onRowClick.bind(this)
    this.selectRow = this.selectRow.bind(this)
    this.handleGlobalClick = this.handleGlobalClick.bind(this)
    this.fetchRows = this.fetchRows.bind(this)
    this.toggleShowOnlyActivePermits = this.toggleShowOnlyActivePermits.bind(this)
  }

  componentDidMount() {
    this.fetchRows()
    setTimeout(() => window.addEventListener('click', this.handleGlobalClick), 0)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (companySelectionChanged(this.props, nextProps)) {
      this.fetchRows(nextProps.selectedAuthorizationOrDecisionHolderIdentification)
    }
    if (this.props.locale !== nextProps.locale) {
      this.fetchRows()
    }
  }

  componentWillUnmount() {
    this.props.storeStateInRedux({
      selectedRowId: this.state.selectedRowId || null,
      showOnlyActivePermits: this.props.showOnlyActivePermits,
    })
    window.removeEventListener('click', this.handleGlobalClick)
  }

  onRowClick({ row }) {
    if (row && row.referenceNumber) {
      this.selectRow(row.referenceNumber)
      this.props.history.push(
        // eslint-disable-next-line max-len
        `/${PERMITS_ROUTE_PATH}/${PERMIT_ROUTE_PATH}/${encodeReferenceNumber(row.referenceNumber)}:${row.version || 0}/${INIT_ROUTE_PATH}`
      )
    }
  }

  handleGlobalClick(event) {
    // Remove row activation when user clicks outside this component
    if (this.state.selectedRowId && this.containerRef && !this.containerRef.contains(event.target)) {
      this.selectRow(null)
    }
  }

  toggleShowOnlyActivePermits() {
    const { showOnlyActivePermits } = this.props
    let filteredList = [...this.state.list]
    if (!showOnlyActivePermits) {
      filteredList = this.state.list.filter(permit => permit.state === PERMIT_STATUS_ACTIVE)
    }
    this.setState({ filteredList })
    this.props.storeStateInRedux({
      showOnlyActivePermits: !showOnlyActivePermits,
    })
  }

  fetchRows(authorizationOrDecisionHolderIdentification) {
    const { selectedAuthorizationOrDecisionHolderIdentification, formatRowData } = this.props

    this.setState({ fetchingPermits: true, errorFetchingRows: null })
    const identification = authorizationOrDecisionHolderIdentification || selectedAuthorizationOrDecisionHolderIdentification

    api.fetchPermits(identification)
      .then((result) => {
        const formattedRows = []
        result.forEach(row => formattedRows.push(formatRowData({
          ...row,
          id: row.archiveId,
        })))
        return formattedRows
      })
      .then((rows) => {
        const filteredList = this.filterData(rows)
        const nextState = {
          list: rows,
          filteredList,
        }
        this.setState(nextState)
      })
      .catch((fetchError) => {
        if (fetchError && fetchError.id) {
          this.setState({ errorFetchingRows: fetchError })
        } else if (fetchError) {
          this.setState({ errorFetchingRows: messages.permitsListFetchError })
        }
      })
      .then(() => this.setState({ fetchingPermits: false }))
  }

  filterData(permits) {
    if (this.props.showOnlyActivePermits) {
      return permits.filter(permit => permit.state === PERMIT_STATUS_ACTIVE)
    }
    return permits
  }

  selectRow(id) {
    this.setState({ selectedRowId: id })
  }

  render() {
    const {
      filteredList,
      fetchingPermits,
    } = this.state

    const {
      intl: { formatMessage },
      showOnlyActivePermits,
      showApplicationType,
    } = this.props

    const noRows = filteredList.size === 0

    // remove showApplicationType usage after Q4/2023
    const typeOrCompanyLabel = { value: formatMessage(showApplicationType ? messages.columnLabelType : messages.columnLabelCompany) }
    const typeOrCompanyProperty = showApplicationType ? 'type' : 'companyName'

    const tableProperties = [
      'referenceNumber',
      typeOrCompanyProperty,
      'name',
      'decisionDate',
      'startDate',
      'endDate',
      'statusName',
      'version',
    ]
    const tableHeadings = [
      { value: formatMessage(messages.columnLabelPermitId) },
      typeOrCompanyLabel,
      { value: formatMessage(messages.columnLabelPermitName) },
      { value: formatMessage(messages.columnLabelDecisionDate) },
      { value: formatMessage(messages.columnLabelStartDate) },
      { value: formatMessage(messages.columnLabelEndDate) },
      { value: formatMessage(messages.columnLabelStatus) },
      { value: formatMessage(messages.columnLabelVersion) },
    ]

    return (
      <div ref={(container) => { this.containerRef = container }}>
        <div
          id="permit-tab-permits-tabpanel"
          className="panel"
          role="tabpanel"
          aria-labelledby="permit-tab-permits-tab"
        >
          <div
            className={classNames('panel-body', 'panel-navigation', noRows && styles.verticalAlignmentForChildren)}
          >
            <ErrorBoundary>
              <div id="permitTabFilterBox">
                <Checkbox
                  checked={showOnlyActivePermits}
                  onChange={this.toggleShowOnlyActivePermits}
                  id-qa-test={`checkbox-${this.props.code}`}
                >
                  <span>{formatMessage(messages.showOnlyActivePermits)}</span>
                </Checkbox>
              </div>
              {fetchingPermits && (
                <div>
                  <Loader blocking message={messages.loadingPermits} />
                </div>
              )}
              {this.state.errorFetchingRows && (
                <div className="text-danger text-center">
                  <FormattedMessage {...this.state.errorFetchingRows} />
                </div>
              )}
              {(!fetchingPermits && !this.state.errorFetchingRows) &&
                <Table
                  headings={tableHeadings}
                  contentRows={filteredList}
                  properties={tableProperties}
                  formatters={formatters}
                  onRowClick={this.onRowClick}
                  sortable
                  sortByColumn={PERMIT_DEFAULT_SORT_BY}
                  sortByDirection={DESCENDING}
                  className={styles.rowPadding}
                />
              }
            </ErrorBoundary>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const {
    selectedRowId,
    showOnlyActivePermits,
  } = state.permits.list

  return {
    companies: state.auth.authorizations,
    selectedRowId,
    showOnlyActivePermits,
    showApplicationType: get(state.config.features, 'ALLOW_REVOKE_DECISION', false),
  }
}

export default withRouter(
  connect(mapStateToProps, undefined)(
    injectIntl(PermitsTab)
  )
)
