import {
  PERMIT_TYPE_GROUPS,
  PERSON_ALLOWED_PERMIT_TYPES,
} from '../routes/permits/permit/constants'

const PERMIT_PREFIX = 'PERMIT_'

export const getEnabledPermitGroups = (features, isPerson) =>
  Object.keys(PERMIT_TYPE_GROUPS)
    .filter((type) => {
      if (isPerson && !PERSON_ALLOWED_PERMIT_TYPES.includes(type)) {
        return false
      }

      return isFeatureEnabled(`${PERMIT_PREFIX}${type}`, features)
    })
    .reduce((groups, type) => {
      // eslint-disable-next-line no-param-reassign
      groups[type] = PERMIT_TYPE_GROUPS[type]
      return groups
    }, {})

export const isPermitType = feature => feature.indexOf(PERMIT_PREFIX) === 0

export const getEnabledPermitTypes = features =>
  Object.keys(features)
    .filter(feat =>
      isPermitType(feat) &&
        isFeatureEnabled(feat, features))
    .map(feature => feature.substring(PERMIT_PREFIX.length))

export const isFeatureEnabled = (feat, features) =>
  !!feat &&
  !!features &&
  !!features[feat]
