import React from 'react'
import { ProgressBar } from 'react-bootstrap'
import { FormattedMessage, defineMessages } from 'react-intl'
import { isEmpty } from 'lodash'
import Loader from 'src/components/Loader'
import Icon from 'src/components/Icon'
import Modal from 'src/components/Modal'
import 'components/notifications.scss'

import headerMessages from '../messages'
import rowMessages from '../../rows/messages'

// Header-related intl message definitions
const messages = defineMessages({
  title: {
    id: 'intrastat.import.title',
    description: 'Title for intrastat file import modal',
    defaultMessage: 'Intrastat file import',
  },
  descriptionOnProgress: {
    id: 'intrastat.import.description.importing',
    description: 'Description for file import modal while importing data',
    defaultMessage: 'Importing data from file, please wait...',
  },
  descriptionOnDone: {
    id: 'intrastat.import.description.done',
    description: 'Description for file import modal when done importing',
    defaultMessage: 'Successfully imported data from file',
  },
  descriptionDoneWithWarnings: {
    id: 'intrastat.import.description.doneWithWarnings',
    description: 'Description for file import modal when done importing, but warnings occured',
    defaultMessage: 'Imported data with {warningCount} warnings on {warningRowCount} rows.',
  },
  close: {
    id: 'intrastat.import.button.close',
    description: 'Label for intrastat file import modal close button when import is done',
    defaultMessage: 'Close',
  },
  rowNum: {
    id: 'intrastat.import.errors.rowNum',
    description: 'Label for row number in intrastat import errors list',
    defaultMessage: 'Row',
  },
  warningCount: {
    id: 'intrastat.import.warnings.count',
    description: 'Count of import errors',
    defaultMessage: 'Warnings',
  },
  warningRowCount: {
    id: 'intrastat.import.warnings.rowCount',
    description: 'Count of import row errors',
    defaultMessage: 'Row warnings',
  },
})

const inputLabelMessagesByFieldName = {
  tdp: headerMessages.tdpLabel,
  psi: headerMessages.psiSelectionLabel,
  tdpReportingUnit: headerMessages.reportingUnitLabel,
  psiReportingUnit: headerMessages.reportingUnitLabel,
  flowCode: headerMessages.flowCodeInputLabel,
  referencePeriod: headerMessages.referencePeriodInputLabel,
  noDeclarationRows: headerMessages.noDeclarationRowsInputLabel,
  memberState: rowMessages.memberStateInputLabel,
  partnerId: rowMessages.partnerIdInputLabel,
  countryOfOrigin: rowMessages.countryOfOriginInputLabel,
  CN8Code: rowMessages.CN8CodeInputLabel,
  natureOfTransactionCode: rowMessages.natureOfTransactionInputLabel,
  modeOfTransport: rowMessages.modeOfTransportInputLabel,
  netMass: rowMessages.netMassInputLabel,
  quantityInSU: rowMessages.quantityInSUInputLabel,
  invoicedAmount: rowMessages.invoicedAmountInputLabel,
  invoicedValueCurrencyCode: rowMessages.invoicedValueCurrencySelectionInputLabel,
  statisticalValue: rowMessages.statisticalValueInputLabel,
  statisticalValueCurrencyCode: rowMessages.statisticalValueCurrencySelectionInputLabel,
  invoiceNumber: rowMessages.invoiceNumberLabel,
}

const ErrorItem = ({ message, field }) => {
  if (typeof message === 'string') {
    return (<span>{message}</span>)
  }
  if (isEmpty(message) || !message.id) {
    return null
  }
  let fieldLabelMesage
  if (inputLabelMessagesByFieldName[field]) {
    fieldLabelMesage = <strong><FormattedMessage {...inputLabelMessagesByFieldName[field]} />: </strong>
  }
  return (
    <span>
      {fieldLabelMesage} <FormattedMessage {...message} />
    </span>
  )
}

function ImportWarnings({ warningHeaderCount, warningRowCount }) {
  return (
    <ul className="list-unstyled text-danger">
      {warningHeaderCount > 0 && (
        <li>
          <Icon name="attention" />
          <FormattedMessage values={{ count: warningHeaderCount }} {...messages.warningCount} />
        </li>)}
      {warningRowCount > 0 && (
        <li>
          <Icon name="attention" />
          <FormattedMessage values={{ count: warningRowCount }} {...messages.warningRowCount} />
        </li>)}
    </ul>
  )
}

function ImportErrors({ errors }) {
  return (
    <ul className="list-unstyled">
      {Object.keys(errors).sort().map((fieldKey) => {
        if (fieldKey === 'rows') {
          return Object.keys(errors[fieldKey]).map((rowNum) => {
            const rowData = errors[fieldKey][rowNum]
            const rowKeys = Object.keys(rowData)
            if (!rowKeys.length) {
              return null
            }
            return (
              <li key={rowNum}>
                <dl className="dl-horizontal">
                  <dt>
                    <FormattedMessage {...messages.rowNum} /> {rowNum}:
                  </dt>
                  <dd>
                    {rowKeys.length === 1 && <ErrorItem message={rowData[rowKeys[0]]} field={rowKeys[0]} />}
                    {rowKeys.length > 1 &&
                      <ol>
                        {rowKeys.sort().map(
                          rowField =>
                            <li key={`${rowNum}${rowField}`}>
                              <ErrorItem message={rowData[rowField]} field={rowField} />
                            </li>
                        )}
                      </ol>
                    }
                  </dd>
                </dl>
              </li>
            )
          })
        }
        return (<li key={fieldKey}><ErrorItem message={errors[fieldKey]} field={fieldKey} /></li>)
      })}
    </ul>
  )
}

function ImportModal({
  rowSaveResult,
  rowSaveProgress,
  show,
  onHide,
  onHideErrors,
  importing,
  importErrors,
  contentRef,
}) {
  let progress
  if (rowSaveProgress && rowSaveProgress.total > 1) {
    const percentDone = Math.round((100 * parseInt(rowSaveProgress.current, 10)) / parseInt(rowSaveProgress.total, 10))
    progress = <ProgressBar now={percentDone} label={`${percentDone || 0}%`} />
  } else if (importing && !rowSaveProgress) {
    progress = <Loader center />
  }

  let warningHeaderCount
  let warningRowCount
  if (rowSaveResult) {
    warningHeaderCount = rowSaveResult.warningCount - rowSaveResult.warningRowCount
    warningRowCount = rowSaveResult.warningRowCount
  }

  let leadTextMessage
  let modalClassName
  let icon

  if (importErrors) {
    modalClassName = 'notification-modal-error has-icon'
    icon = (<Icon name="alert-triangle" md className="modal-icon text-danger" />)
  } else if (rowSaveResult && rowSaveResult.warningCount > 0) {
    modalClassName = 'notification-modal-warning'
  }
  if (importing) {
    leadTextMessage = messages.descriptionOnProgress
  } else if (!importErrors) {
    if (rowSaveResult && rowSaveResult.warningCount > 0) {
      leadTextMessage = {
        ...messages.descriptionDoneWithWarnings,
      }
    } else {
      leadTextMessage = messages.descriptionOnDone
    }
  }

  return (
    <div>
      <Modal
        show={show}
        showCancel={false}
        showContinue={!importing}
        continueBtnStyle="primary"
        className={modalClassName}
        titleMessage={messages.title}
        continueMessage={messages.close}
        continueDisabled={importing}
        onClickContinue={onHide}
        onEscKey={onHide}
      >
        <div ref={contentRef} tabIndex={-1}>
          {leadTextMessage &&
            <p className="lead">{icon} <FormattedMessage {...leadTextMessage} /></p>}
          {importErrors &&
            <div>
              {!leadTextMessage && icon}
              <ImportErrors errors={importErrors} onDismiss={() => onHideErrors()} />
            </div>
          }
          {(warningHeaderCount || warningRowCount) &&
            <div>
              <ImportWarnings warningHeaderCount={warningHeaderCount} warningRowCount={warningRowCount} />
            </div>
          }
          {progress}
        </div>
      </Modal>
    </div>
  )
}

export default ImportModal
