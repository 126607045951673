import React from 'react'
import IntrastatFrontpage from './list/components/IntrastatFrontpage'

export default class IntrastatRoot extends React.Component {
  componentWillUnmount() {
    this.props.destroyIntrastatState()
  }
  render() {
    const { children } = this.props
    if (children) {
      return (<div>{children}</div>)
    }
    return (
      <IntrastatFrontpage />
    )
  }
}
