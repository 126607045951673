
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { get } from 'lodash'
import { injectIntl } from 'react-intl'
import { fetchCodeset, fetchNonCachedCodesets } from 'src/components/codeset/actions'
import { submitForm } from './actions'
import CustomsInformationForm from './components/CustomsInformationForm'
import { getSelectedAuthorization } from '../../../utils/auth'

const mapStateToProps = state => ({
  locale: state.locale,
  authenticatedUserName: get(state.auth, 'name'),
  selectedAuthorizationObject: getSelectedAuthorization(),
  cmsMessages: get(state, 'content.cmsMessages', {}),
  codesets: get(state, 'codeset.cachedCodesets'),
})

const mapActionCreators = {
  fetchCodeset,
  fetchNonCachedCodesets,
  submitForm,
}

export default withRouter(
  connect(mapStateToProps, mapActionCreators)(
    injectIntl(
      CustomsInformationForm
    )))
