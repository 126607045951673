import React, { useState } from 'react'
import classNames from 'classnames'
import { useParams, Link as RouterLink } from 'react-router-dom'
import Icon from 'src/components/Icon'
import {
  getStepPath,
  getReferenceNumberAndVersionFromParams,
} from '../../utils'
import messages from '../../messages'
import styles from './stepNavigation.scss'

function StatusRow(props) {
  const { num, header, statusClass, link, active } = props
  if (link) {
    return (
      <li
        className={classNames(styles.statusRow, statusClass)}
        // eslint-disable-next-line jsx-a11y/aria-props
        aria-current={active ? 'step' : undefined}
      >
        <RouterLink
          to={link}
        >
          <Link
            id-qa-test={`${num}`}
            active={active}
            // eslint-disable-next-line jsx-a11y/aria-props
            aria-current={active ? 'step' : undefined}
            autoFocus={active}
          >
            <div className={styles.ballWrapper}>
              <div className={styles.statusBall}>
                <span>{num}</span>
              </div>
              <div className={styles.statusLine} />
            </div>
            <span className={styles.statusRowHeader}>{header}</span>
          </Link>
        </RouterLink>
      </li>
    )
  }
  return (
    <li
      className={classNames(styles.statusRow, statusClass)}
      aria-current={active ? 'step' : undefined}
    >
      <div className={styles.ballWrapper}>
        <div className={styles.statusBall}>
          <span>{num}</span>
        </div>
        <div className={styles.statusLine} />
      </div>
      <span className={styles.statusRowHeader}>{header}</span>
    </li>
  )
}

export default function StepNavigation({ formatMessage, structure, currentNavIndex, locale }) {
  const [open, setOpen] = useState(false)
  const params = useParams()

  const statusBalls = structure.groups && structure.groups.filter(group => group.visible)
    .map((group, i) => {
      const num = i + 1
      if (i < currentNavIndex) {
        const linkTo = getStepPath(group.code, getReferenceNumberAndVersionFromParams(params))
        return (
          <StatusRow
            num={num}
            header={group.name[locale]}
            statusClass={styles.statusRowDone}
            key={i}
            link={linkTo}
          />
        )
      }
      if (i === currentNavIndex) {
        return (
          <StatusRow
            num={num}
            header={group.name[locale]}
            key={i}
            statusClass={styles.statusRowActive}
            active
          />
        )
      }
      return (
        <StatusRow num={num} header={group.name[locale]} key={i} />
      )
    })
  return (
    <div className={styles.headerContainer}>
      <div className={styles.declarationHeader}>
        <nav aria-labelledby="steps-title">
          <div className={`clearfix ${styles.mobileHeaderControls}`}>
            <h2 tabIndex={-1} id="steps-title">
              {formatMessage(messages.applicationPages)}
            </h2>
            <button
              className={
                open
                  ? `btn-compact visible-xs ${styles.btnIcon} ${styles.btnIconOpen}`
                  : `btn-compact visible-xs ${styles.btnIcon}`
              }
              onClick={() => setOpen(!open)}
              aria-pressed={open}
            >
              <span className="sr-only">{formatMessage(messages.navigationViewExtended)}</span>
              <span aria-hidden="true"><Icon name="arrow-down" /></span>
            </button>
          </div>
          <ul
            className={
              open
                ? classNames(styles.statusBallContainer, styles.statusBallContainerOpen)
                : classNames(styles.statusBallContainer, styles.statusBallContainerClose)
            }
          >
            {statusBalls}
          </ul>
        </nav>
      </div>
    </div>
  )
}

function Link({ active, children, ...props }) {
  // Fix a recurring react error where the active prop injected by
  // Link causes a distracting error message in dev mode
  return <a {...props}>{children}</a>
}
