import { createDefaultAction } from 'src/utils/redux'
import logger from 'src/utils/logger'
import { apiCall } from 'src/utils/http'

import {
  CUSTOMER_INFORMATION_FETCHED,
  CUSTOMER_INFORMATION_REQUEST,
  REPRESENTATIVE_INFORMATION_FETCHED,
  REPRESENTATIVE_INFORMATION_REQUEST,
  PERMIT_REPRESENTATIVE_INFORMATION_FETCHED,
  PERMIT_REPRESENTATIVE_INFORMATION_REQUEST,
  GUARANTEE_STATUSES_FETCHED,
  GUARANTEE_STATUSES_REQUEST,
  SHOW_NO_CUSTOMER_INFORMATION_MODAL,
  HIDE_NO_CUSTOMER_INFORMATION_MODAL,
  CURRENT_REPRESENTATIVE_TO_PERMIT_REPRESENTATIVE,

} from './constants'

const customerInformationRequest = createDefaultAction(CUSTOMER_INFORMATION_REQUEST)
const representativeInformationRequest = createDefaultAction(REPRESENTATIVE_INFORMATION_REQUEST)
const permitRepresentativeInformationRequest = createDefaultAction(PERMIT_REPRESENTATIVE_INFORMATION_REQUEST)

export const customerInformationFetched = createDefaultAction(CUSTOMER_INFORMATION_FETCHED)
export const representativeInformationFetched = createDefaultAction(REPRESENTATIVE_INFORMATION_FETCHED)
export const permitRepresentativeInformationFetched = createDefaultAction(PERMIT_REPRESENTATIVE_INFORMATION_FETCHED)

export const currentRepresentativeToPermitRepresentative = createDefaultAction(CURRENT_REPRESENTATIVE_TO_PERMIT_REPRESENTATIVE)

export const setCurrentRepresentativeToPermitRepresentative = (representativeId, representativeIdentification) =>
  (dispatch) => {
    dispatch(currentRepresentativeToPermitRepresentative({ representativeId, representativeIdentification }))
  }

export const fetchPermitRepresentativeInformation = representativeId =>
  (dispatch, getState) => {
    dispatch(permitRepresentativeInformationRequest())

    const { config: { bootstrapConfig } } = getState()
    return apiCall(
      `${bootstrapConfig.tm_customer_ext_url}/publicCustomerInformation/?customerIdentifier=${representativeId}`,
      { method: 'GET', cache: 'default' },
      null,
      dispatch,
      false
    )
      .then((data) => {
        dispatch(permitRepresentativeInformationFetched({ representativeId, data }))
      })
      .catch((error) => {
        logger.error(`Error in fetching representative (${representativeId}) information`, error)
        dispatch(permitRepresentativeInformationFetched(error))
      })
  }

export const fetchRepresentativeInformation = representativeId =>
  (dispatch, getState) => {
    dispatch(representativeInformationRequest())

    const { config: { bootstrapConfig } } = getState()
    return apiCall(
      `${bootstrapConfig.tm_customer_ext_url}/publicCustomerInformation/?customerIdentifier=${representativeId}`,
      { method: 'GET', cache: 'default' },
      null,
      dispatch,
      false
    )
      .then((data) => {
        dispatch(representativeInformationFetched({ representativeId, data }))
      })
      .catch((error) => {
        logger.error(`Error in fetching representative (${representativeId}) information`, error)
        dispatch(representativeInformationFetched(error))
      })
  }

export const fetchCustomerInformation = (customerId, includeAllOffices = false, includeWarehouses = false) =>
  (dispatch, getState) => {
    dispatch(customerInformationRequest())

    const { config: { bootstrapConfig } } = getState()

    return apiCall(
      `${bootstrapConfig.tm_customer_ext_url}/customer/${customerId}?includeAllOffices=${includeAllOffices}&includeWarehouses=${includeWarehouses}`,
      { method: 'GET', cache: 'default' },
      null,
      dispatch,
      false
    )
      .then((data) => {
        const modifiedData = {
          ...data,
          AEOCertificateType: data.aeoCertificateType,
        }
        delete modifiedData.aeoCertificateType

        dispatch(customerInformationFetched({ customerId, data: modifiedData }))

        return modifiedData
      })
      .catch((error) => {
        logger.error(`Error in fetching customer (${customerId}) information`, error)
        dispatch(customerInformationFetched(error))
      })
  }

const guaranteeStatusesRequest = createDefaultAction(GUARANTEE_STATUSES_REQUEST)
const guaranteeStatusesFetched = createDefaultAction(GUARANTEE_STATUSES_FETCHED)

export const fetchGuaranteeStatuses = (authorizationOrDecisionHolderIdentification, customerId) =>
  (dispatch, getState) => {
    dispatch(guaranteeStatusesRequest())

    const { config: { bootstrapConfig } } = getState()

    // We fetch the guarantee data with authorizationOrDecisionHolderIdentification, but it will be saved in the state under the customerId
    return apiCall(
      `${bootstrapConfig.tm_guarantee_ext_url}/guaranteeStatus/${authorizationOrDecisionHolderIdentification}`,
      { method: 'GET', cache: 'default' },
      null,
      dispatch,
      false
    )
      .then((result) => {
        dispatch(guaranteeStatusesFetched({ customerId, data: result }))
      })
      .catch((error) => {
        logger.error(`Error in fetching guarantee statuses with ID: (${authorizationOrDecisionHolderIdentification})`, error)
        dispatch(guaranteeStatusesFetched(error))
      })
  }

const showNoCustomerInformationModal = createDefaultAction(SHOW_NO_CUSTOMER_INFORMATION_MODAL)
const hideNoCustomerInformationModal = createDefaultAction(HIDE_NO_CUSTOMER_INFORMATION_MODAL)

export const setShowNoCustomerInfoModal = value =>
  (dispatch) => {
    if (value === true) {
      dispatch(showNoCustomerInformationModal())
    } else {
      dispatch(hideNoCustomerInformationModal())
    }
  }
