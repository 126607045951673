import { isEmpty, replace, toNumber, round, isNil, find } from 'lodash'
import moment from 'moment'
import big from 'big.js'
import { NEW_DECLARATION_PATH_ID } from './constants'
import { INTRASTAT_ROUTE_PATH, DECLARATION_ROUTE_PATH } from '../constants'
import messages from './messages'

/**
 * Default string parser for converting empty strings to null
 */
export const parseString = value =>
  (value === '' ? null : value)

const parseFinnishNumber = value =>
  replace(replace(value, /\s/, ''), ',', '.')

/**
 * Parses number from user input (string) to integer. Rounds decimal numbers and restricts them to safe integer size.
 *
 * NOTE: Large JavaScript numbers are presented in exponential format and return value is not ready to be shown in UI.
 */
export const parseInteger = (value) => {
  if (isEmpty(value)) {
    // Empty value: Use null instead of undefined to make sure action erases field
    return null
  }
  const number = round(toNumber(parseFinnishNumber(value)))
  if (!Number.isSafeInteger(number)) {
    // Invalid number: No change to previous value, use undefined instead of null to keep old field value
    return undefined
  }
  return number
}

export const parseBigInteger = (value) => {
  if (isEmpty(value)) {
    // Empty value: Use null instead of undefined to make sure action erases field
    return null
  }
  try {
    const bigNumber = big(parseFinnishNumber(value))
    return bigNumber.round().toFixed() // returns string
  } catch (error) {
    // Could not parse: return undefined so no change will happen in store
    return undefined
  }
}

/**
 * Format large integers in normal notation instead of exponential.
 */
export const formatInteger = (value) => {
  if (isNil(value) || value === '') {
    return ''
  }
  return big(value).toFixed()
}

export const formatReferencePeriod = (value) => {
  if (/^[0-9]{6}$/.test(value)) {
    return `${value.slice(0, 4)}/${value.slice(4, 6)}`
  }
  return value
}

export function getReferencePeriodSelectOptions(
  flowCode,
  selectedReportingUnit,
  selectedPsiObject,
  formatMessage,
  locale
) {
  let referencePeriods = selectedPsiObject.referencePeriods || []

  // If possible, filter referencePeriods for given reporting unit only
  if (selectedReportingUnit) {
    const selectedReportingUnitObject =
      find(selectedPsiObject.reportingUnits, ['intCode', selectedReportingUnit])
    if (selectedReportingUnitObject) {
      referencePeriods = selectedReportingUnitObject.referencePeriods || []
    }
  }
  moment.locale(locale)
  return referencePeriods
    .filter(refPeriod => refPeriod.flow === flowCode)
    .map((referencePeriod) => {
      let title = referencePeriod.label
      const referencePeriodMonthName = moment(referencePeriod.referencePeriod, 'YYYY/MM').format('MMMM')

      if (referencePeriod.deadline) {
        title = formatMessage(messages.referencePeriodOption, {
          label: `${referencePeriod.label} ${referencePeriodMonthName}`,
          deadline: moment(referencePeriod.deadline, 'YYYY-MM-DD').format('DD.MM.YYYY'),
        })
      }

      return {
        id: `${referencePeriod.referencePeriod}-${referencePeriod.flow}`,
        value: referencePeriod.referencePeriod,
        title,
      }
    })
    .sort((a, b) => b.title.localeCompare(a.title))
}

export function getGroupedReferencePeriodSelectOptions(flowCode, selectedReportingUnit,
  selectedPsiObject, formatMessage, locale) {
  let referencePeriods = selectedPsiObject.referencePeriods || []
  // If possible, filter referencePeriods for given reporting unit only
  if (selectedReportingUnit) {
    const selectedReportingUnitObject =
      find(selectedPsiObject.reportingUnits, ['intCode', selectedReportingUnit])
    if (selectedReportingUnitObject) {
      referencePeriods = selectedReportingUnitObject.referencePeriods || []
    }
  }
  moment.locale(locale)
  return referencePeriods
    .filter(refPeriod => refPeriod.flow === flowCode)
    .map((referencePeriod) => {
      let title = referencePeriod.label
      const referencePeriodMonthName = moment(referencePeriod.referencePeriod, 'YYYY/MM').format('MMMM')
      const referencePeriodYear = moment(referencePeriod.referencePeriod, 'YYYY/MM').format('YYYY')

      if (referencePeriod.deadline) {
        title = formatMessage(messages.referencePeriodOption, {
          label: `${referencePeriod.label} ${referencePeriodMonthName}`,
          deadline: moment(referencePeriod.deadline, 'YYYY-MM-DD').format('DD.MM.YYYY'),
        })
      }

      return {
        id: `${referencePeriod.referencePeriod}-${referencePeriod.flow}`,
        value: referencePeriod.referencePeriod,
        title: `${title}`,
        group: {
          labelMessage: messages.referencePeriodGroupTitle,
          labelMessageParams: {
            referencePeriodYear,
          },
        },
      }
    })
    .sort((a, b) => b.title.localeCompare(a.title))
}

export function InvalidFileSyntaxError(errors) {
  this.validationErrors = errors
}
InvalidFileSyntaxError.prototype = Object.create(Error.prototype)
InvalidFileSyntaxError.prototype.name = 'InvalidFileSyntaxError'
InvalidFileSyntaxError.prototype.message = 'Invalid import file syntax'
InvalidFileSyntaxError.prototype.constructor = InvalidFileSyntaxError

export function ImportValidationError(errors) {
  this.validationErrors = errors
}
ImportValidationError.prototype = Object.create(Error.prototype)
ImportValidationError.prototype.name = 'ImportValidationError'
ImportValidationError.prototype.message = 'Import data validation failed'
ImportValidationError.prototype.constructor = ImportValidationError

export function parseOrganizationIdAndReportingUnit(intIdentifier) {
  const result = intIdentifier ? intIdentifier.trim().match(/^(.*?)(INT[0-9]+)?$/i) : []
  return {
    organizationId: result[1] || undefined,
    reportingUnit: result[2] || undefined,
  }
}

export const getDeclarationIdFromRoute = (props) => {
  const idInRoute = props.match.params.declarationId
  return idInRoute === NEW_DECLARATION_PATH_ID ? null : idInRoute
}

export const getStepPath = (stepName, declarationId) =>
  `/${INTRASTAT_ROUTE_PATH}/${DECLARATION_ROUTE_PATH}/${declarationId || NEW_DECLARATION_PATH_ID}/${stepName}`

export const getStepPathForHref = (stepName, declarationId) =>
  `/asiointipalvelu${getStepPath(stepName, declarationId)}`

export const pathIsAFormStep = pathname =>
  new RegExp(`/${INTRASTAT_ROUTE_PATH}/${DECLARATION_ROUTE_PATH}`).test(pathname)

export const getSimstatMode = (referencePeriod, flowCode, psi, cachedCustomers) => {
  const selectedPsiCustomer = find(cachedCustomers, customer => customer.id === psi)
  if (selectedPsiCustomer === undefined) {
    return undefined
  }

  const findOptionFromReferencePeriods = (objectWithReferencePeriods) => {
    for (const refPeriod of objectWithReferencePeriods.referencePeriods) {
      if (refPeriod.flow === flowCode && refPeriod.referencePeriod === referencePeriod) {
        return parseInt(refPeriod.simstatOption, 10)
      }
    }
    return undefined
  }

  const simstatOption = findOptionFromReferencePeriods(selectedPsiCustomer)

  if (simstatOption) {
    return simstatOption
  }
  for (const reportingUnit of selectedPsiCustomer.reportingUnits) {
    return findOptionFromReferencePeriods(reportingUnit) || 2 // return safest simstat option if not found
  }

  return undefined
}

export function calculateRowTotals(rows) {
  const result = {
    totalNetMass: 0,
    totalStatisticalValue: 0,
    totalInvoicedAmount: 0,
  }
  if (!isEmpty(rows)) {
    for (const row of rows) {
      result.totalNetMass += parseInt(row.netMass, 10) || 0
      result.totalStatisticalValue += parseInt(row.statisticalValueInEur, 10) || 0
      result.totalInvoicedAmount += parseInt(row.invoicedAmountInEur, 10) || 0
    }
  }
  return result
}

export function hasLiability(selectedPsiObject) {
  if (selectedPsiObject.referencePeriods.length > 0) {
    return true
  }
  return selectedPsiObject.reportingUnits &&
    selectedPsiObject.reportingUnits.some(reportUnit => reportUnit.referencePeriods.length > 0)
}

export function shouldRenderNoReferencePeriods(selectedPsiObject) {
  if (!selectedPsiObject) {
    return false
  }
  if (hasLiability(selectedPsiObject)) {
    return false
  }
  return true
}

export function rowHasItemNumber(rows) {
  if (!isEmpty(rows)) {
    return Object.values(rows).some(value => value.itemNumber !== 0)
  }
  return false
}
