
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { withRouter } from 'react-router-dom'
import { get } from 'lodash'

import PaymentCancel from './PaymentCancel'

function mapStateToProps(state) {
  const cmsMessages = get(state, 'content.cmsMessages', {})
  const loadingText = cmsMessages['/individual-guarantee/cancelLoadingText']
  return {
    locale: state.locale,
    config: state.config.bootstrapConfig,
    cancelLoadingText: loadingText,
  }
}

const mapActionCreators = {}

export default withRouter(connect(mapStateToProps, mapActionCreators)(injectIntl(PaymentCancel)))
