import React from 'react'
import moment from 'moment'
import { withRouter } from 'react-router-dom'
import { injectIntl, FormattedMessage } from 'react-intl'
import Heading from 'src/layout/Heading'
import ErrorBoundary from 'src/components/ErrorBoundary'
import Loader from 'src/components/Loader'
import PanelNavigation from 'src/components/PanelNavigation'
import ExitPermitButton from './ExitPermitButton'
import PermitSummaryReadOnly from '../PermitSummaryReadOnly'
import {
  getReferenceNumberAndVersionFromParams,
  encodeReferenceNumber,
} from '../../utils'
import messages from '../../messages'
import api from '../../../api'
import { first, get } from 'lodash'
import { DECISION_TYPE_CODESET } from '../../../../../components/codeset/constants'

const defaultState = {
  permitData: null,
  doneInitializingPage: false,
  decisionTypes: [],
}

class Permit extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      ...defaultState,
    }
  }

  componentDidMount() {
    this.initialization(this.props.locale)
  }

  async getDecisionTypes(permitData) {
    const decisionDate = permitData.decisionDate
    const date = moment(decisionDate).format('YYYY-MM-DD')

    try {
      const codesets = await this.props.fetchNonCachedCodesets([DECISION_TYPE_CODESET], date)
      return get(first(codesets), `${DECISION_TYPE_CODESET}.[${date}]`)
    } catch (error) {
      throw error 
    }
  }

  // eslint-disable-next-line
  initialization() {
    const {
      applicant,
      fetchCustomerInformation,
      match,
      isPerson,
    } = this.props
    const authorizationOrDecisionHolderIdentification = applicant.authorizationOrDecisionHolderIdentification

    this.setState({
      ...defaultState,
    })

    const { referenceNumber, version } = getReferenceNumberAndVersionFromParams(match.params)
    const encodedReferenceNumber = encodeReferenceNumber(referenceNumber)

    if (isPerson) {
      const { customer } = this.props
      api.fetchPermitTypes()
        .then(permitTypes =>
          api.fetchPermit(encodedReferenceNumber, authorizationOrDecisionHolderIdentification, version)
            .then((permitData) => {
              const modifiedPermitData = {
                permit: permitData[0],
                permitTypes,
                customerInformation: customer.customerInformation[applicant.id],
              }

              this.getDecisionTypes(permitData).then((decisionTypes) => {
                if (permitData[0].authorisationTypeCode === 'PAA' && permitData[0].decisionReference) {
                  api.fetchPermitApplication(permitData[0].decisionReference.attributes?.referenceNumber)
                    .then((rejoinder) => {
                      this.setState({
                        permitData: {
                          ...modifiedPermitData,
                          rejoinder,
                        },
                        doneInitializingPage: true,
                        decisionTypes,
                      })
                    })
                } else {
                  this.setState({
                    permitData: modifiedPermitData,
                    doneInitializingPage: true,
                    decisionTypes,
                  })
                }
              })
            })
            .catch((err) => {
              throw err
            })
        )
    } else {
      fetchCustomerInformation(applicant.id)
        .then((customerResponse) => {
          const { customer } = this.props
          api.fetchPermitTypes()
            .then(permitTypes =>
              api.fetchPermit(encodedReferenceNumber, authorizationOrDecisionHolderIdentification, version)
                .then((permitData) => {
                  const modifiedPermitData = {
                    permit: permitData[0],
                    permitTypes,
                    customerInformation: customerResponse,
                  }

                  this.getDecisionTypes(permitData).then((decisionTypes) => {
                    if (permitData[0].authorisationTypeCode === 'PAA' && permitData[0].decisionReference) {
                      api.fetchPermitApplication(permitData[0].decisionReference.attributes?.referenceNumber)
                        .then((rejoinder) => {
                          this.setState({
                            permitData: {
                              ...modifiedPermitData,
                              rejoinder,
                            },
                            doneInitializingPage: true,
                            decisionTypes,
                          })
                        })
                    } else {
                      this.setState({
                        permitData: modifiedPermitData,
                        doneInitializingPage: true,
                        decisionTypes,
                      })
                    }
                  })

                })

                .catch((err) => {
                  throw err
                })
            )
        })
    }
  }


  render() {
    const {
      applicant,
      customer,
      locale,
      history,
      intl: { formatMessage },
      helpTexts,
      isPerson,
    } = this.props

    if (customer.fetchingCustomer) {
      return (
        <Loader
          blocking
          message={{ ...messages.loadingCustomerInformation }}
        />
      )
    } else if (!this.state.doneInitializingPage) {
      return (
        <Loader
          blocking
          message={{ ...messages.loadingPermit }}
        />
      )
    }

    const { permitData } = this.state

    const isProposal = permitData.permit.decisionType && permitData.permit.decisionType.includes('PROPOSAL')

    return (
      <div>
        <Heading message={isProposal ? <FormattedMessage {...messages.headingDecisionProposal} /> : <FormattedMessage {...messages.headingPermit} />}>
          <ExitPermitButton title={messages.exitPermit} />
        </Heading>
        <main className="container" role="main" id="main" aria-label={formatMessage(messages.applicationPages)}>
          <ErrorBoundary>
            <PanelNavigation
              title={
                isProposal ?
                  messages.decisionProposalTitle
                  :
                  {
                    ...messages.permitTitle,
                    values: {
                      referenceNumber: permitData.referenceNumber,
                    },
                  }
              }
              active
            >
              <PermitSummaryReadOnly
                isPerson={isPerson}
                permitData={this.state.permitData}
                locale={locale}
                history={history}
                helpTexts={helpTexts}
                decisionTypes={this.state.decisionTypes}
              />
            </PanelNavigation>
          </ErrorBoundary>
        </main>
      </div>
    )
  }
}

export default withRouter(injectIntl(Permit))
