import { memoize } from 'lodash'
import { apiCall } from 'src/utils/http'
import logger from 'src/utils/logger'
import { getStore } from '../../store'
import { TM_APA_FEEDBACK_INSERT_ROUTE } from './constants'

const getTmApaUrl = memoize(() => getStore().getState().config.bootstrapConfig.tm_apa_ext_url)

function post(url, body = {}) {
  return apiCall(url, { method: 'POST', body: JSON.stringify(body) }, {}, undefined, false, false)
    .catch((error) => {
      logger.error('API call failed', error)
      throw error
    })
}


const sendFeedback = (service, rating, freeText) => {
  const apiUrl = `${getTmApaUrl()}/${TM_APA_FEEDBACK_INSERT_ROUTE}`
  return post(apiUrl, {
    rating,
    text: freeText,
    service,
  })
}

export default sendFeedback
