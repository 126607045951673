import React from 'react'
import { Form, Button, ButtonToolbar } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { find, isNil, isEqual, some, get, isEmpty } from 'lodash'
import repetitiveComponentMessages from 'src/routes/permits/permit/components/RepetitiveComponent/messages'
import ErrorBoundary from 'src/components/ErrorBoundary'
import Loader from 'src/components/Loader'
import Icon from 'src/components/Icon'
import { EditStepBase } from '../../../components/EditStepBase'
import EditDeclarationButtonToolbar from '../../../components/EditDeclarationButtonToolbar'
import rowMessages from '../messages'
import RowFormContainer from '../containers/RowFormContainer'
import RowsListContainer from '../containers/RowsListContainer'
import { getStepPath, getDeclarationIdFromRoute } from '../../../utils'

/**
 * React presentational component for Intrastat step 2: rows listing and editing.
 */
export default class RowsView extends EditStepBase {
  constructor(props) {
    super(props)
    this.editNewRow = this.editNewRow.bind(this)
    this.setGetNextAndPrevRowFns = this.setGetNextAndPrevRowFns.bind(this)
    this.addRowCancelCallback = this.addRowCancelCallback.bind(this)
    this.state = {
      focusAddNewRow: false,
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.referencePeriod && nextProps.referencePeriod !== this.props.referencePeriod) {
      this.props.fetchCodesets(nextProps.referencePeriod)
    }
  }

  componentDidMount() {
    super.componentDidMount()
    const {
      referencePeriod,
      fetchCodesets,
    } = this.props
    if (referencePeriod) {
      fetchCodesets(referencePeriod)
    }
  }

  /**
   * Only update when props changing directly this component change.
   *
   * Props changing only RowsList (row data) should not trigger render on this component.
   */
  shouldComponentUpdate(nextProps) {
    // Consider null equal with undefined, but neither is equal with 0 or false
    const isValueChanged = key =>
      !(isNil(nextProps[key]) && isNil(this.props[key])) && nextProps[key] !== this.props[key]

    if (isValueChanged('selectedRow')
      || isValueChanged('newRow')
      || isValueChanged('referencePeriod')
      || isValueChanged('fetchingDeclaration')
      || isValueChanged('fetchingCustomers')
      || isValueChanged('fetchingDeclarationRows')
      || isValueChanged('fetchingCodesets')
      || (nextProps.rowCount > 0) !== (this.props.rowCount > 0)
      || !isEqual(nextProps.warning, this.props.warning)
    ) {
      return true
    }
    return false
  }

  addRowCancelCallback() {
    this.setState({ focusAddNewRow: true })
    document.getElementById('editNewRow').focus()
  }

  editNewRow(e) {
    if (e) {
      e.preventDefault()
    }
    if (!this.props.accepted && !this.props.invalidated) {
      if (this.props.selectedRow) {
        this.props.selectRow(null)
      }
      this.props.toggleNewRowEditing(true)
    }
  }

  setGetNextAndPrevRowFns(getNextRowIdFn, getPrevRowIdFn) {
    this.getNextRowId = getNextRowIdFn
    this.getPrevRowId = getPrevRowIdFn
  }

  render() {
    const {
      submitting,
      newRow,
      selectedRow,
      selectRow,
      noDeclarationRows,
      flowCode,
      rows,
      handleSubmit,
      referencePeriod,
      accepted,
      invalidated,
      warning,
      fetchingDeclaration,
      fetchingDeclarationRows,
      fetchingCustomers,
      fetchingCodesets,
      intl,
      selectedDelegateCompanyObject,
      tdp,
      cachedCustomers,
    } = this.props

    const selectedDelegateCompanyId = get(selectedDelegateCompanyObject, 'id')
    const selectedTdpObject = tdp && find(cachedCustomers, { id: selectedDelegateCompanyId || tdp })
    const disableFormBecauseTdp = !selectedDelegateCompanyId && !isEmpty(selectedTdpObject)

    const { focusAddNewRow } = this.state

    const pathToBack = getStepPath(this.props.prevStep, getDeclarationIdFromRoute(this.props))
    if (fetchingDeclaration || fetchingDeclarationRows || fetchingCustomers || some(fetchingCodesets)) {
      return <Loader blocking />
    }

    if (!referencePeriod) {
      return (
        <div>
          <div className="text-danger">
            <FormattedMessage {...rowMessages.referencePeriodBeforeRows} />
          </div>
          <EditDeclarationButtonToolbar
            className={'rowsView'}
            pathToBack={pathToBack}
            showBack
            showNext={false}
            disabled={submitting || this.props.newRow}
          />
        </div>
      )
    }

    // Get row data by id selected for editing
    let selectedRowData
    if (selectedRow != null && newRow !== true) {
      selectedRowData = find(rows, { id: selectedRow })
    }

    return (
      <ErrorBoundary>
        {(newRow || selectedRowData) && (
          <RowFormContainer
            getNextRowId={this.getNextRowId}
            getPrevRowId={this.getPrevRowId}
            newRow={newRow}
            selectedRow={selectedRow}
            selectedRowData={selectedRowData}
            selectRow={selectRow}
            cancelCallback={this.addRowCancelCallback}
            addNewButtonRef={this.editNewRowButtonRef}
            autoFocus={!focusAddNewRow}
          />
        )}
        <div className="form-group">
          {!noDeclarationRows && !accepted && !invalidated &&
            <ButtonToolbar>
              <Button
                aria-describedby="newRowDescription"
                bsStyle="primary"
                onClick={this.editNewRow}
                disabled={fetchingDeclarationRows || accepted || invalidated || disableFormBecauseTdp}
                id-qa-test="btn-add-row"
                id="editNewRow"
                onBlur={() => this.setState({ focusAddNewRow: false })}
                ref={(ref) => { this.editNewRowButtonRef = ref }}
              >
                <Icon name="add" /><FormattedMessage {...rowMessages.addRow} />
              </Button>
            </ButtonToolbar>
          }

          <Form horizontal onSubmit={handleSubmit(this.stepSubmitHandler)}>
            {warning && warning.id && <div className="text-danger" role="alert"><FormattedMessage {...warning} /></div>}
            <RowsListContainer
              setGetNextAndPrevRowFns={this.setGetNextAndPrevRowFns}
              selectedRow={selectedRow}
              selectRow={selectRow}
              flowCode={flowCode}
              disableFormBecauseTdp={disableFormBecauseTdp}
            />

            <p
              id="newRowDescription"
              className="help-block"
              aria-label={intl.formatMessage(repetitiveComponentMessages.mandatoryRowDescription)}
            >
              {intl.formatMessage(repetitiveComponentMessages.mandatoryRowDescription)}
            </p>
            <EditDeclarationButtonToolbar
              className={'rowsView'}
              pathToBack={pathToBack}
              showBack
              showNext
              disabled={fetchingDeclarationRows || this.props.newRow}
              exitAction={this.exitAction}
            />
          </Form>
        </div>
      </ErrorBoundary>
    )
  }
}
