import React from 'react'
import { isEmpty, isEqual, get, forEach, find, toNumber, findIndex } from 'lodash'
import { injectIntl, FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import Popper from 'src/components/Popper'
import Table, { SORTING_TYPE_NUMBER } from 'src/components/Table/Table'
import Checkbox from 'src/components/form/Checkbox'
import Icon from 'src/components/Icon'
import noRowsRenderer from 'src/components/lists/noRowsRenderer'
import messages from '../../messages'
import { generateRowsListData, generateCodesetData } from '../helpers'
import styles from './RowsList.scss'

/**
 * React presentational component for Intrastat rows listing,
 * using common ListView (Table from React Virtualized)
 * combined with ReduxForm's form data and validation
 */

class RowsList extends React.Component {
  constructor(props, context) {
    super(props, context)

    this.state = {
      showOnlyInvalidRows: false,
      containsErrors: false,
      list: generateRowsListData(props.rows, props.rowErrors),
      codeset: generateCodesetData(props.codeset, props.locale),
      showConfirm: false,
    }
    this.toggleOnlyInvalids = this.toggleOnlyInvalids.bind(this)
    this.getNextRowId = this.getNextRowId.bind(this)
    this.getPrevRowId = this.getPrevRowId.bind(this)
    this.getList = this.getList.bind(this)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // Update row count and table height on props change to make sure all rows are rendered.
    const nextState = {}

    // Regenerate list data when row related data changes (and on first render)
    if (!this.state.list
      || !isEqual(nextProps.rows, this.props.rows)
      || !isEqual(nextProps.rowErrors, this.props.rowErrors)
    ) {
      nextState.list = generateRowsListData(nextProps.rows, nextProps.rowErrors)
    }
    if (!isEqual(nextProps.codeset, this.props.codeset)) {
      nextState.codeset = generateCodesetData(nextProps.codeset, nextProps.locale)
    }
    if (this.state.showOnlyInvalidRows !== nextProps.showOnlyInvalidRows) {
      this.toggleOnlyInvalids(nextProps.showOnlyInvalidRows)
    }
    if (this.state.containsErrors !== nextProps.containsErrors && !nextProps.containsErrors) {
      this.props.setOnlyInvalidRowsVisibility(false)
    }
    if (nextProps.cn8Locale !== nextProps.locale && !isEmpty(nextProps.cn8Codes)) {
      nextProps.fetchCN8Codes(Object.keys(nextProps.cn8Codes), nextProps.referencePeriod)
    }
    nextState.containsErrors = nextProps.containsErrors
    nextState.showOnlyInvalidRows = nextProps.showOnlyInvalidRows
    this.setState(nextState)
  }

  componentDidUpdate(prevProps) {
    if (this.props.showOnlyInvalidRows && !prevProps.showOnlyInvalidRows) {
      const showInvalidCheckboxElement = document.getElementById('invalidRowsFilter')
      showInvalidCheckboxElement.focus()
    }
  }

  componentWillUnmount() {
    this.props.setOnlyInvalidRowsVisibility(false)
  }

  getList() {
    const { list, showOnlyInvalidRows } = this.state
    let filteredList = list
    if (showOnlyInvalidRows) {
      filteredList = list.filter(row => row.errors || row.warnings)
    }
    return filteredList
  }

  getNextRowId() {
    const { selectedRow } = this.props
    let nextRowId
    if (selectedRow) {
      const list = this.getList()
      const currentIndex = findIndex(list, row => row.id === selectedRow)
      if ((currentIndex + 1) < list.length) {
        nextRowId = list[currentIndex + 1].id
      }
    }
    return nextRowId
  }

  getPrevRowId() {
    const { selectedRow } = this.props
    let prevRowId
    if (selectedRow) {
      const list = this.getList()
      const currentIndex = findIndex(list, row => row.id === selectedRow)
      if (currentIndex > 0) {
        prevRowId = list[currentIndex - 1].id
      }
    }
    return prevRowId
  }

  toggleOnlyInvalids(status) {
    this.setState({
      showOnlyInvalidRows: status,
    })
  }

  /**
   * Sorted rows data is generated on each render (when underlying data changes)
   */
  render() {
    const {
      containsErrors,
      flowCode,
      referencePeriod,
      cn8Codes,
      fetchCN8Codes,
      secondaryUnits,
      selectRow,
      intl: { formatMessage },
      getSimstatMode,
      setGetNextAndPrevRowFns,
      hasItemNumber,
      disableFormBecauseTdp,
    } = this.props

    const {
      codeset,
      list,
      showOnlyInvalidRows,
    } = this.state

    if (!this.props.rowCount) {
      return noRowsRenderer(messages.noRows, true)
    }

    let filteredList = list
    if (showOnlyInvalidRows) {
      filteredList = list.filter(row => row.errors || row.warnings)
    }

    const simstatMode = getSimstatMode()
    const countriesOfOriginCodeset =
       simstatMode !== 1 ? 'countriesOfOriginForSimstat' : 'countriesOfOrigin'

    setGetNextAndPrevRowFns(this.getNextRowId, this.getPrevRowId)
    const onRowClick = ({ row }) => {
      selectRow(row.id)
    }

    const hasErrors = find(filteredList, 'errors')
    const hasWarnings = find(filteredList, 'warnings')
    const hasErrorsOrWarnings = hasErrors || hasWarnings

    const headings = [
      { value: formatMessage(messages.rowIdLabel) },
      { value: formatMessage(
        flowCode === 'ARRIVAL_OF_GOODS' ?
          messages.arrivalMemberStateInputLabel : messages.dispatchMemberStateInputLabel
      ) },
      { value: formatMessage(messages.countryOfOriginInputLabel) },
      { value: formatMessage(messages.CN8CodeInputLabel) },
      /*
      { value: formatMessage(messages.natureOfTransactionCodeInputLabel) },
      { value: formatMessage(messages.modeOfTransportInputLabel) },
      */
      { value: formatMessage(messages.netMassInputLabel) },
      { value: formatMessage(messages.quantityInSUInputLabel) },
      { value: formatMessage(messages.invoicedAmountInEurLabel) },
      { value: formatMessage(messages.invoiceNumberInputLabel) },
    ]

    const properties = [
      'index',
      'memberState',
      'countryOfOrigin',
      'CN8Code',
      /*
      'natureOfTransactionCode',
      'modeOfTransport',
      */
      'netMass',
      'quantityInSU',
      'invoicedAmountInEur',
      'invoiceNumber',
    ]

    const sortingTypes = {
      index: SORTING_TYPE_NUMBER,
      netMass: SORTING_TYPE_NUMBER,
      invoicedAmountInEur: SORTING_TYPE_NUMBER,
    }

    if (hasErrorsOrWarnings) {
      headings.push('')
      properties.push('errors')
    }

    const formatters = {
      index: (row, property) => hasItemNumber ? toNumber(row[property]) : toNumber(row[property]) + 1,
      CN8Code: (row, property, index) => {
        const cn8Item = get(cn8Codes, row[property])
        let tooltipContent
        if (!cn8Item) {
          tooltipContent = <FormattedMessage {...messages.CN8LoadingLabel} />
        } else {
          tooltipContent = cn8Item.selfExplanatoryText || cn8Item.description
        }
        return (<Tooltip
          id={`${index}-${property}`}
          key={`${index}-${property}`}
          content={row[property]}
          tooltipContent={tooltipContent}
          onShow={() => {
            if (!get(cn8Codes, row[property])) {
              fetchCN8Codes(row[property], referencePeriod)
            }
          }}
        />)
      },
      memberState: (row, property, index) => {
        const fullText = get(codeset, ['memberStates', row[property]])
        return (<Tooltip
          id={`${index}-${property}`}
          key={`${index}-${property}`}
          content={row[property]}
          tooltipContent={fullText}
        />)
      },
      countryOfOrigin: (row, property, index) => {
        const fullText = get(codeset, [countriesOfOriginCodeset, row[property]])
        return (<Tooltip
          id={`${index}-${property}`}
          key={`${index}-${property}`}
          content={row[property]}
          tooltipContent={fullText}
        />)
      },
      /*
      natureOfTransactionCode: (row, property) => get(codeset, ['transactionTypes', row[property]]),
      modeOfTransport: (row, property) => get(codeset, ['transportTypes', row[property]]),
      */
      quantityInSU: (row, property, index) => {
        const cn8Item = get(cn8Codes, row.CN8Code)
        let content
        if (!cn8Item || isEmpty(secondaryUnits)) {
          content = <FormattedMessage {...messages.CN8LoadingLabel} />
        } else {
          content = secondaryUnits[cn8Item.quantityInSU]
        }
        return (<Tooltip
          id={`${index}-${property}`}
          key={`${index}-${property}`}
          content={row[property]}
          tooltipContent={content}
          onShow={() => {
            if (!get(cn8Codes, row.CN8Code)) {
              fetchCN8Codes(row.CN8Code, referencePeriod)
            }
          }}
        />)
      },
      errors: (row, property, index) => {
        if (isEmpty(row.errors) && isEmpty(row.warnings)) {
          return undefined
        }
        const tooltipContent = []
        const errorsAndWarnings = {
          ...row.warnings,
          ...row.errors,
        }
        forEach(errorsAndWarnings,
          (error, key) => {
            const keyMessage = messages[`${key}InputLabel`]
            if (error && error.id) {
              tooltipContent.push(
                <div key={key}>
                  {keyMessage && (<span>{formatMessage(keyMessage)}: </span>)}
                  {formatMessage(error)}
                </div>)
            }
          }
        )
        const content = (
          <Icon
            name="attention"
            className="text-danger"
            style={{ display: 'block' }}
          />
        )
        return (<Tooltip
          id={`${index}-${property}`}
          key={`${index}-${property}`}
          content={content}
          tooltipContent={tooltipContent}
        />)
      },
    }

    const Tooltip = (props) => {
      const { id, key, content, tooltipContent, onShow } = props
      if (content) {
        return (
          <Popper
            key={key}
            type="tooltip"
            trigger="hover"
            id={`tooltip-${id}`}
            placement="bottom"
            content={tooltipContent}
            onShow={onShow}
            usePortal
          >
            <span>{content}</span>
          </Popper>
        )
      }
      return null
    }

    return (
      <div className={styles.rowsListSelector}>
        {containsErrors &&
          <div className={styles.rowsListSelector}>
            <Checkbox
              id="invalidRowsFilter"
              checked={showOnlyInvalidRows}
              onClick={() => {
                this.props.setOnlyInvalidRowsVisibility(!this.state.showOnlyInvalidRows)
              }}
              qaTestId="checkbox-showOnlyInvalidRows"
            >
              <FormattedMessage {...messages.invalidRowsFilterToggle} />
            </Checkbox>
          </div>
        }
        <Table
          className="intrastatRowsList"
          headings={headings}
          properties={properties}
          contentRows={filteredList}
          formatters={formatters}
          onRowClick={onRowClick}
          sortable
          sortingTypes={sortingTypes}
          onRowClickDisabled={disableFormBecauseTdp}
        />
      </div>
    )
  }
}

const mapStateToProps = state => ({ locale: state.locale })

export default connect(mapStateToProps)(injectIntl(RowsList))
