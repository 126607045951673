import React from 'react'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import PanelNavigation from 'src/components/PanelNavigation'
import { get } from 'lodash'
import classnames from 'classnames'
import { collectCmsMessages } from 'src/utils'
import { Row, Col } from 'react-bootstrap'
import Table from 'src/components/Table/Table'
import DoughnutGraph from 'src/components/DoughnutGraph'

import styles from './GuaranteeInformationPanel.scss'
import Icon from '../../../components/Icon'
import { GUARANTEE_TYPES, GUARANTEE_STATUSES, GUARANTEE_CLOSED_STATUS, GRAPH_COLORS } from '../constants'

function GuaranteeInformationPanel(props) {
  const { className,
    panelContent,
    customerInformation,
    tableHeadings,
    tableProperties,
    rawGuaranteeData,
    tableData,
  } = props
  const { title, noGuarantees, tableCaption } = panelContent
  const graphData = []

  rawGuaranteeData.forEach((guarantee) => {
    let percentage = 0
    let percentageUiText = panelContent.guaranteeClosedPercentageText
    if (guarantee.status !== GUARANTEE_CLOSED_STATUS) {
      percentage = guarantee.reservedPercentage
      percentageUiText = guarantee.percentageUiText
    }

    graphData.push({
      items: [
        {
          name: 'used',
          value: percentage,
          color: percentage >= 50 ? GRAPH_COLORS.red : GRAPH_COLORS.blue,
        },
        {
          name: 'available',
          value: 100 - percentage,
          color: GRAPH_COLORS.grey,
        },
      ],
      percentageUsed: percentageUiText,
      closed: guarantee.status === GUARANTEE_CLOSED_STATUS,
      type: guarantee.type,
    })
  })

  return (
    <div className={className}>
      <PanelNavigation
        id="guaranteeInformation"
        title={title}
        active
        noFocus
        className={styles.panelContainer}
      >
        {(customerInformation &&
          customerInformation.guaranteeStatuses &&
          customerInformation.guaranteeStatuses.length &&
          <div className={styles.tableWrapper}>
            <Table
              headings={tableHeadings}
              contentRows={tableData}
              properties={tableProperties}
              dontBoldFirstCell
              caption={tableCaption}
              useRowHeadings
              className={styles.tableStyling}
            />
          </div>)
          ||
          <div className={classnames(styles.noGuaranteeWrapper, styles.centerChildren)}>
            <Icon className={classnames(styles.noGuaranteeIcon, styles.centerChildren)} name="file-graph-pie" xlg />
            <span className={classnames(styles.noGuaranteeText, styles.centerChildren)}>{noGuarantees}</span>
          </div>
        }
        <div className={styles.graphContainer} aria-hidden="true">
          <Row>
            {graphData && graphData.map((item, index) => {
              const isSingle = graphData && graphData.length === 1
              return (
                <Col key={`graph-col-${index}`} md={isSingle ? 8 : 4} sm={12} className={styles.graphColumn}>
                  <DoughnutGraph
                    percentage={item.percentageUsed}
                    data={item.items}
                    isSingle={isSingle}
                    isSmallPercentageText={item.closed}
                    description={panelContent[GUARANTEE_TYPES[item.type]]}
                  />
                </Col>
              )
            })}
          </Row>
        </div>
      </PanelNavigation>
    </div >
  )
}

function getTableData(guarantees, content) {
  let data = []

  if (guarantees) {
    data = guarantees.map((guarantee) => {
      const typeText = content[GUARANTEE_TYPES[guarantee.type]]

      return {
        type: typeText,
        status: content[GUARANTEE_STATUSES[guarantee.status]],
        grn: guarantee.grn,
        percentageUiText:
          guarantee.status !== GUARANTEE_CLOSED_STATUS ?
            guarantee.percentageUiText : content.guaranteeClosedPercentageText,
      }
    })
  }

  return data
}

function addPercentageUiText(statuses, content) {
  let formatted = []

  if (statuses) {
    const uiText = get(content, 'percentageUiText', '')
    formatted = statuses.map(item => (
      {
        ...item,
        percentageUiText: `${item.reservedPercentage.toFixed(1).replace('.', ',')}${uiText}`,
      }
    ))
  }

  return formatted
}

const mapStateToProps = (state, props) => {
  const { customerInformation } = props
  const cmsMessages = get(state, 'content.cmsMessages', {})
  const cmsContent = collectCmsMessages('/customer-information/guaranteeinformation', cmsMessages)
  const headings = [
    { value: cmsContent.typeTableHeading },
    { value: cmsContent.statusTableHeading },
    { value: cmsContent.grnTableHeading },
    { value: cmsContent.percentageTableHeading },
  ]
  const properties = ['type', 'status', 'grn', 'percentageUiText']
  const statuses = get(customerInformation, 'guaranteeStatuses', [])
  const formattedStatuses = addPercentageUiText(statuses, cmsContent)
  const data = getTableData(formattedStatuses, cmsContent)

  return {
    locale: state.locale,
    panelContent: cmsContent,
    tableHeadings: headings,
    tableProperties: properties,
    tableData: data,
    rawGuaranteeData: formattedStatuses,
  }
}

const mapActionCreators = {}

export default connect(mapStateToProps, mapActionCreators)(injectIntl(GuaranteeInformationPanel))
