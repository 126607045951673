/* eslint no-underscore-dangle: 0 */
import {
  initialize,
  touch,
  stopSubmit,
  SubmissionError,
} from 'redux-form'
import moment from 'moment'
import { omitBy, isNil, isEmpty, isEqual, get, omit } from 'lodash'
import { createDefaultAction } from 'src/utils/redux'
import { showGlobalNotification, loadingAction } from 'src/actions'
import logger from 'src/utils/logger'
import { apiCall } from 'src/utils/http'
import { getFieldErrors } from 'src/utils/validation'
import importFile from '../../import/importFile'
import {
  INTRASTAT_FORM_NAME,
  DECLARATION_STATUS_ACCEPTED,
  DECLARATION_STATUS_DEFACED,
  HEADERS_ROUTE_PATH,
  IGNORE_INVALID_TDP,
  DECLARATION_HEADERS_FETCHED,
  DECLARATION_HEADERS_SAVED,
  TOGGLE_IMPORT_MODAL,
  IMPORT_STARTED,
  IMPORT_DONE,
  SUBMITTING_HEADERS,
} from '../../constants'
import { INTRASTAT_ROUTE_PATH } from '../../../constants'
import apiMessages from '../../apiMessages'
import intrastatMessages from '../../messages'
import headerMessages from './messages'
import { handleServerErrors } from '../../actions'
import { saveRows, fetchDeclarationRows } from '../rows/actions'
import { fetchNonCachedCustomers } from '../../../actions'
import { getStepPath } from '../../utils'
import { getSelectedAuthorization, getSelectedDelegateCompanyFromAuthentication } from '../../../../../utils/auth'

const messages = {
  ...intrastatMessages,
  ...headerMessages,
}

export const ignoreInvalidTdp = createDefaultAction(IGNORE_INVALID_TDP)
export const headersFetched = createDefaultAction(DECLARATION_HEADERS_FETCHED)

export const fetchHeaders = (declarationId, history) =>
  async (dispatch, getState) => {
    dispatch(loadingAction({ key: DECLARATION_HEADERS_FETCHED, value: true }))
    const { config: { bootstrapConfig } } = getState()
    const apiUrl = `${bootstrapConfig.tm_intrastat_ext_url}/declarations/${declarationId}`

    try {
      let response = await apiCall(apiUrl, { method: 'GET' }, {}, dispatch, false)
      if (response && response.tdp && response.nextDeclarationId) {
        const corrDeclaration = await apiCall(`${bootstrapConfig.tm_intrastat_ext_url}/declarations/${response.nextDeclarationId}`, { method: 'GET' }, {}, dispatch, false)
        if (corrDeclaration && corrDeclaration.tdp) {
          response.ownByTdp = true
        }
      }
      dispatch(headersFetched(declarationId))
      dispatch(loadingAction({ key: DECLARATION_HEADERS_FETCHED, value: false }))
      return response
    } catch (error) {
      logger.error('Error in fetching Intrastat declaration headers', error)
      const message = get(error, 'errors._error')
      dispatch(headersFetched(error, { message, declarationId }))
      dispatch(loadingAction({ key: DECLARATION_HEADERS_FETCHED, value: false }))
      dispatch(showGlobalNotification({
        level: 'error',
        modal: true,
        message: messages.errorFetchingDeclaration,
        additionalInfo: message && message.id ? message : undefined,
        onClickContinue: () => history.push(`/${INTRASTAT_ROUTE_PATH}`),
      }))
      throw error
    }
  }

export const headersSaved = createDefaultAction(DECLARATION_HEADERS_SAVED)

/**
 * Submits declaration headers as a create or update operation.
 * Does nothing if declaration state is accepted.
 */
export const submitHeaders = (formData, fromFile = false) =>
  (dispatch, getState) => {
    dispatch(loadingAction({ key: SUBMITTING_HEADERS, value: true }))
    const { rows, ...declaration } = formData
    const { config: { bootstrapConfig } } = getState()
    let apiUrl = `${bootstrapConfig.tm_intrastat_ext_url}/declarations/`
    let method = 'POST'
    if (declaration.declarationId) {
      apiUrl = `${apiUrl}${declaration.declarationId}`
      method = 'PUT'
    }
    const declarationWithoutNilValues = omitBy(declaration, isNil)
    return apiCall(apiUrl, { method, body: JSON.stringify(declarationWithoutNilValues) }, {}, dispatch, true)
      .catch(handleServerErrors.bind(this, dispatch))
      .then((response) => {
        const { errors, ...responseDeclaration } = response
        // When importing from file and when referencePeriod is more than 3 months old, clear it
        if (fromFile) {
          const isOlderThanThreeMonths = Boolean(moment()
            .diff(moment(responseDeclaration.referencePeriod, 'YYYYMM'), 'month') > 3)

          if (isOlderThanThreeMonths) {
            responseDeclaration.referencePeriod = undefined
          }
        }

        dispatch(initialize(
          INTRASTAT_FORM_NAME,
          { ...responseDeclaration, rows },
          { keepDirty: false, keepSubmitSucceeded: true, updateUnregisteredFields: true }
        ))
        dispatch(touch(INTRASTAT_FORM_NAME, ...Object.keys(responseDeclaration)))
        dispatch(headersSaved(responseDeclaration))
        dispatch(loadingAction({ key: SUBMITTING_HEADERS, value: false }))

        // Throw server-side warnings to Redux Form as SubmissionErrors.
        // Prevents proceeding if headers have warnings, even though they did not prevent persistence!
        if (errors) {
          throw new SubmissionError(getFieldErrors(response, apiMessages))
        }

        return responseDeclaration
      })
      .catch((error) => {
        dispatch(headersSaved(error))
        dispatch(loadingAction({ key: SUBMITTING_HEADERS, value: false }))
        logger.error('Error in submitting Intrastat declaration headers', JSON.stringify(error))

        if (error && error.errors && error.errors._error) {
          const errorKey = error.errors._error.id
          let errorCode = null
          if (errorKey.startsWith('api.intrastat.exception.')) {
            errorCode = errorKey.slice(24)
          }
          const globalIntlMessage = apiMessages[errorCode]
          if (errorCode) {
            dispatch(showGlobalNotification({
              level: 'error',
              message: globalIntlMessage,
            }))
          }
        }
        throw error
      })
  }

export const headersStepSubmit = (values, reduxFormDispatch, props) => {
  if (!props.dirty || [DECLARATION_STATUS_ACCEPTED, DECLARATION_STATUS_DEFACED].includes(values.status)) {
    return () => Promise.resolve(omit(values, 'rows'))
  }
  return (dispatch, getState) =>
    submitHeaders(values)(dispatch, getState)
      .then((declaration) => {
        fetchDeclarationRows(declaration.declarationId)(dispatch, getState)
          .then((fetchedRows) => {
            if (!isEqual(values.rows, fetchedRows)) {
              dispatch(initialize(
                INTRASTAT_FORM_NAME,
                {
                  ...declaration,
                  rows: fetchedRows,
                },
                {
                  keepDirty: false,
                  keepSubmitSucceeded: true,
                  updateUnregisteredFields: true,
                }
              ))
            }
          })
        return declaration
      })
}

export const toggleImportModal = createDefaultAction(TOGGLE_IMPORT_MODAL)
export const importStarted = createDefaultAction(IMPORT_STARTED)
export const importDone = createDefaultAction(IMPORT_DONE)

/**
 * Redux Thunk
 *
 * Parse form data from the first file in files and asynchronously dispatch actions when parsing is ready.
 * If declarationId is given, only rows are imported.
 *
 * @param String declaration - Declaration id if importing data to existing declaration
 * @param {Array} files - Web API FileList
 * @returns
 */
export const importFromFile = (declarationId, previousDeclarationId, files, intl, history) =>
  (dispatch, getState) => {
    const selectedAuthorization = getSelectedAuthorization()
    const userOrganizationId = get(selectedAuthorization, 'id', undefined)
    const userOrganizationName = get(selectedAuthorization, 'name', undefined)
    const tdp = getSelectedDelegateCompanyFromAuthentication()
    const declarationExists = Boolean(previousDeclarationId)

    dispatch(importStarted())
    importFile(files[0], declarationExists, userOrganizationId, userOrganizationName, intl)
      .then((data) => {
        const modifiedData = {
          ...data,
        }
        const executeRowsSave = id => saveRows(id, modifiedData.rows)(dispatch, getState)
        // If declaration exists, save only rows from file
        if (declarationExists) {
          return executeRowsSave(declarationId)
        }


        const customers = [modifiedData.psi]
        /*
          We have to use the delegate that is saved in the authorization object
          We ignore tdp(delegate) in the file
        */
        if (tdp) {
          modifiedData.tdp = tdp.id
        }

        return fetchNonCachedCustomers(customers)(dispatch, getState)
          .then(() => submitHeaders(modifiedData, true)(dispatch, getState))
          .then((declaration) => {
            history.replace(getStepPath(HEADERS_ROUTE_PATH, declaration.declarationId))
            return executeRowsSave(declaration.declarationId)
          })
      })
      .then((rowSaveResult) => {
        dispatch(importDone(rowSaveResult))
        if (!rowSaveResult.warningCount) {
          dispatch(showGlobalNotification({
            level: 'success',
            title: messages.importSuccessTitle,
            message: {
              ...messages.importSuccessMessage,
              values: { rowCount: rowSaveResult.rowCount },
            },
            autoDismiss: true,
          }))
        }
      })
      .catch((error) => {
        logger.error('Error in importing Intrastat declaration from file', error)
        let errors
        if (error && error.validationErrors) {
          errors = error.validationErrors
        } else if (error instanceof SubmissionError) {
          errors = error.errors
        }
        if (isEmpty(errors)) {
          errors = { _error: apiMessages['service.exception'] }
        }
        dispatch(importDone(error, { errors }))
        // Since we are not submitting from form, we need to trigger errors separately to Redux Form
        dispatch(stopSubmit(INTRASTAT_FORM_NAME, errors))
      })
  }
