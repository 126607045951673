import React from 'react'
import { Grid, Col, Row, Button, ButtonToolbar, Nav, NavItem } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { Form } from 'react-form'
import { AutoAffix } from 'react-overlays'
import moment from 'moment'
import { find, get, isEmpty, concat, omit, xor, has, some, isEqual } from 'lodash'
import Loader from 'src/components/Loader'
import Icon from 'src/components/Icon'
import ErrorBoundary from 'src/components/ErrorBoundary'
import PanelNavigation from 'src/components/PanelNavigation'
import CommonFormGroup from 'src/components/form_v2/CommonFormGroup'
import Heading from 'src/layout/Heading'
import { messages as validationMessages } from 'src/utils/validation'
import { collectCmsMessages, focusFirstElementByIdOrName, focusAndScrollFirstFocusableElementInPanel } from 'src/utils'
import { EU_MEMBER_STATES_CODE } from 'src/components/codeset/constants'
import {
  EORI_FORM_ROUTE_PATH,
  SUMMARY_ROUTE_PATH,
  POPULAR_COUNTRY_CODES,
} from '../constants'
import {
  FORMS_ROUTE_PATH,
} from '../../constants'
import messages from '../messages'

const getFieldValidationMessage = (fieldCode) => {
  const customMessages = {
    'vat-number': messages.enterVatNumber,
    'vat-countryCode': messages.enterCountry,
  }
  const trimmedFieldCode = fieldCode.replace(/-\d+/, '')
  if (has(customMessages, trimmedFieldCode)) {
    return customMessages[trimmedFieldCode]
  }
  return validationMessages.required
}

export default class EoriRegistrationForm extends React.Component {
  constructor(props) {
    super(props)
    this.formApi = null
    this.state = {
      errorCustomerNotFound: false,
      errorFetchingCustomer: false,
      customerHasEori: false,
      vatNumbersIndexes: [moment().format('x')],
      isSubmitting: false,
      activePanel: null,
      helpTexts: {},
    }
    this.connectFormApi = this.connectFormApi.bind(this)
    this.submitForm = this.submitForm.bind(this)
  }

  componentDidMount() {
    this.updateHelpTexts()
    this.fetchCustomer()
    this.fetchCodesets()
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const selectedAuthorizationId = this.props.selectedAuthorizationObject ?
      this.props.selectedAuthorizationObject.id
      :
      undefined

    if (nextProps.selectedAuthorizationObject &&
      nextProps.selectedAuthorizationObject.id !== selectedAuthorizationId) {
      this.fetchCustomer(nextProps)
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.cmsMessages !== prevProps.cmsMessages) {
      this.updateHelpTexts()
    }
    if (!isEqual(this.props.customer, prevProps.customer)) {
      this.handleCustomerInformation()
    }
  }

  updateHelpTexts() {
    this.setState({
      helpTexts: {
        common: collectCmsMessages('/forms/eori', this.props.cmsMessages),
        fields: collectCmsMessages('/forms/eori/fields', this.props.cmsMessages),
      },
    })
  }

  fetchCodesets() {
    const { fetchNonCachedCodesets } = this.props
    return fetchNonCachedCodesets([EU_MEMBER_STATES_CODE], moment().format('YYYY-MM-DD'))
  }

  fetchCustomer(props = this.props) {
    const { fetchCustomerInformation, selectedAuthorizationObject } = props
    if (fetchCustomerInformation && selectedAuthorizationObject) {
      fetchCustomerInformation(selectedAuthorizationObject.id)
    }
  }

  handleCustomerInformation() {
    const { selectedAuthorizationObject, customer } = this.props
    if (isEmpty(customer.customerInformation)) {
      const errorCode = get(customer, 'error.code')
      if (errorCode === 'customer.notFound') {
        this.setState({
          errorCustomerNotFound: true,
        })
      } else {
        this.setState({
          errorFetchingCustomer: true,
        })
      }
    }

    const companyFullName = get(selectedAuthorizationObject, 'name')
    const customerId = get(selectedAuthorizationObject, 'identifier')
    const customerInformation = {
      customerId: selectedAuthorizationObject.id,
      ...customer.customerInformation[selectedAuthorizationObject.id],
    }
    const customerAddress = find(get(customerInformation, 'addresses'), { addressType: 'POST_ADDRESS' })
    const hasEori = !!customerInformation.hasEori
    const customerQueryAddress = get(customerAddress, 'address', undefined)
    const customerQueryZipCode = get(customerAddress, 'zipCode', undefined)
    const customerQueryCity = get(customerAddress, 'city', undefined)
    const customerQueryVatNumber = get(customerInformation, 'vatRegistered') ?
      selectedAuthorizationObject.id : undefined

    this.setState({
      customerHasEori: hasEori,
      customerQueryVatNumber,
    })
    this.formApi.setAllValues({
      companyFullName,
      customerId,
      alvNumber: customerQueryVatNumber,
      address: customerQueryAddress,
      zipCode: customerQueryZipCode,
      city: customerQueryCity,
    })
  }

  connectFormApi(formApi) {
    this.formApi = formApi
  }

  checkSomeValuesExistInForm(fieldCodes) {
    const formState = this.formApi.getFormState()
    return some(fieldCodes, fieldCode =>
      (has(formState.values, fieldCode) && !isEmpty(formState.values[fieldCode])))
  }

  submitForm() {
    const {
      locale,
      submitForm,
      cachedCodesets,
      intl: { formatMessage },
      history,
    } = this.props
    const formState = this.formApi.getFormState()
    const formData = get(formState, 'values')
    const fieldCodes = [
      'companyFullName',
      'customerId',
      'alvNumber',
      'address',
      'zipCode',
      'city',
      'contactName',
      'contactEmail',
    ]
    let fieldCodesToValidate = fieldCodes.slice(0)
    fieldCodesToValidate = fieldCodesToValidate.filter(e => e !== 'alvNumber')

    this.state.vatNumbersIndexes.forEach((index) => {
      if (this.checkSomeValuesExistInForm([`vat-countryCode-${index}`, `vat-number-${index}`])) {
        fieldCodesToValidate.push(`vat-countryCode-${index}`)
        fieldCodesToValidate.push(`vat-number-${index}`)
      } else {
        this.formApi.setError(`vat-countryCode-${index}`, null)
        this.formApi.setError(`vat-number-${index}`, null)
      }
    })

    let hasErrors = false
    fieldCodesToValidate.map((fieldCode) => {
      if (!get(formData, fieldCode)) {
        this.formApi.setError(fieldCode, getFieldValidationMessage(fieldCode))
        hasErrors = true
      } else {
        this.formApi.setError(fieldCode, null)
      }
      return null
    })

    if (hasErrors) {
      this.formApi.submitForm()
        .then(() => {
          const errors = get(this.formApi.getFormState(), 'errors', {})
          focusFirstElementByIdOrName(Object.keys(errors))
        })
      return null
    }

    const dataArr = fieldCodes.map(fieldCode => ({
      id: fieldCode,
      title: formatMessage(messages[`${fieldCode}FieldLabel`]),
      value: get(formData, fieldCode) || '-',
    }))

    const vatNumbers = []
    const date = moment().format('YYYY-MM-DD')
    // eslint-disable-next-line array-callback-return
    this.state.vatNumbersIndexes.forEach((index) => {
      const countryCode = get(formData, `vat-countryCode-${index}`)
      const number = get(formData, `vat-number-${index}`)
      if (countryCode && number) {
        const countryName = find(cachedCodesets[EU_MEMBER_STATES_CODE][date], { code: countryCode }).name[locale] || '-'
        vatNumbers.push(`${countryName}, ${number}`)
      }
    })

    dataArr.push({
      id: 'vatNumbers',
      // eslint-disable-next-line max-len
      title: `${formatMessage(messages.countryCodeFieldLabel)}, ${formatMessage(messages.vatNumberFieldLabel)}`,
      value: isEmpty(vatNumbers) ? '-' : vatNumbers.join('\n'),
    })

    this.setState({ isSubmitting: true })

    submitForm({
      formType: 'EORI',
      locale,
      data: dataArr,
      vatHeading: formatMessage(messages.headingPanelVATNumbers),
    }).then(() => {
      this.setState({
        isSubmitting: false,
      })
      history.push(`/${FORMS_ROUTE_PATH}/${EORI_FORM_ROUTE_PATH}/${SUMMARY_ROUTE_PATH}`)
    })

    return null
  }

  removeVatRow(index) {
    const { formApi } = this
    const { vatNumbersIndexes } = this.state
    const currentFormState = formApi.getFormState()
    formApi.setFormState({
      ...currentFormState,
      values: {
        ...omit(currentFormState.values, [`vat-countryCode-${index}`, `vat-number-${index}`]),
      },
    })
    this.setState({
      vatNumbersIndexes: xor(vatNumbersIndexes, [index]),
    })
  }

  renderField(name, label, formApi, mandatory, staticField) {
    if (staticField) {
      return this.renderStaticField(name, label)
    }
    return this.renderGenericField(name, label, formApi, mandatory)
  }

  renderStaticField(name, label) {
    const { intl: { formatMessage } } = this.props
    return (
      <CommonFormGroup
        formGroupClassname="formElementGutter"
        formName="EORI"
        input={{
          help: { content: get(this.state.helpTexts, ['fields', name], null) },
          name,
          type: 'text',
          validate: false,
          validation: {
            mandatory: true,
          },
          static: true,
          visible: true,
          multilineLabel: true,
        }}
        label={formatMessage(label)}
        showLabel
        type="TEXT"
      />
    )
  }

  renderGenericField(name, label, formApi, mandatory = true) {
    const { intl: { formatMessage } } = this.props
    return (
      <CommonFormGroup
        errors={formApi.errors}
        formApi={formApi}
        formGroupClassname="formElementGutter"
        formName="EORI"
        input={{
          help: { content: get(this.state.helpTexts, ['fields', name], null) },
          name,
          type: 'text',
          validate: true,
          validation: {
            strict: true,
            mandatory,
          },
          visible: true,
          multilineLabel: true,
        }}
        label={formatMessage(label)}
        showLabel
        type="TEXT"
      />
    )
  }

  renderApplicantInformation(formApi) {
    const {
      customerQueryVatNumber,
    } = this.state
    return (
      <PanelNavigation
        id="applicantInformation"
        title={messages.headingPanelApplicantInformation}
        active
        noFocus
      >
        <Grid fluid>
          <Row>
            <Col xs={12} md={6}>
              {this.renderField('companyFullName', messages.companyFullNameFieldLabel, formApi, true, true)}
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6}>
              {this.renderField('customerId', messages.customerIdFieldLabel, formApi, true, true)}
            </Col>
            <Col xs={12} md={6}>
              {this.renderField('alvNumber', messages.alvNumberFieldLabel, formApi, false, !!customerQueryVatNumber)}
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6}>
              {this.renderField('address', messages.addressFieldLabel, formApi, true, false)}
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6}>
              {this.renderField('zipCode', messages.zipCodeFieldLabel, formApi, true, false)}
            </Col>
            <Col xs={12} md={6}>
              {this.renderField('city', messages.cityFieldLabel, formApi, true, false)}
            </Col>
          </Row>
        </Grid>
      </PanelNavigation>
    )
  }

  renderContactInformation(formApi) {
    const {
      intl: { formatMessage },
    } = this.props
    return (
      <PanelNavigation
        id="contactInformation"
        title={messages.headingPanelContactInformation}
        active
        noFocus
      >
        <Grid fluid>
          <Row>
            <Col xs={12} md={6}>
              <CommonFormGroup
                errors={formApi.errors}
                formApi={formApi}
                formGroupClassname="formElementGutter"
                formName="EORI"
                input={{
                  help: { content: get(this.state.helpTexts.fields, 'fields.contactName', null) },
                  name: 'contactName',
                  type: 'text',
                  validate: true,
                  validation: {
                    strict: true,
                    mandatory: true,
                    maxLength: '70',
                    minLength: '0',
                  },
                  visible: true,
                }}
                label={formatMessage(messages.contactNameFieldLabel)}
                showLabel
                type="TEXT"
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6}>
              <CommonFormGroup
                errors={formApi.errors}
                formApi={formApi}
                formGroupClassname="formElementGutter"
                formName="EORI"
                input={{
                  help: { content: get(this.state.helpTexts, 'fields.contactEmail', null) },
                  name: 'contactEmail',
                  type: 'email',
                  validate: true,
                  validation: {
                    strict: true,
                    mandatory: true,
                    maxLength: '70',
                    minLength: '0',
                  },
                  visible: true,
                }}
                label={formatMessage(messages.contactEmailFieldLabel)}
                showLabel
                type="EMAIL"
              />
            </Col>
          </Row>
        </Grid>
      </PanelNavigation>
    )
  }

  renderVATNumberSection(formApi) {
    const {
      fetchingCodesets,
      cachedCodesets,
      intl: { formatMessage },
    } = this.props
    const { vatNumbersIndexes } = this.state
    const countryCodesetFetched =
      get(cachedCodesets, EU_MEMBER_STATES_CODE) && !get(fetchingCodesets, EU_MEMBER_STATES_CODE)

    return (
      <PanelNavigation
        id="vatNumbers"
        title={messages.headingPanelVATNumbers}
        active
        noFocus
      >
        <Grid fluid>
          {!countryCodesetFetched && <Loader small />}
          {countryCodesetFetched && this.state.vatNumbersIndexes.map(index =>
            <Row key={index}>
              <Col xs={12} md={5}>
                <CommonFormGroup
                  codesetObj={{
                    codesetExtension: null,
                    codesetName: EU_MEMBER_STATES_CODE,
                    codesetValidValues: null,
                    codesetInvalidValues: ['FI'],
                    subCodesetName: null,
                  }}
                  errors={formApi.errors}
                  formApi={formApi}
                  formGroupClassname="formElementGutter"
                  input={{
                    help: { content: get(this.state.helpTexts, 'fields.countryCodeFieldLabel', null) },
                    name: `vat-countryCode-${index}`,
                    type: 'codesetAutocomplete',
                    validate: true,
                    validation: {
                      strict: false,
                      mandatory: this.checkSomeValuesExistInForm([
                        `vat-countryCode-${index}`,
                        `vat-number-${index}`,
                      ]),
                      customErrorMessage: messages.enterCountry,
                      maxLength: '2',
                      minLength: '2',
                    },
                    visible: true,
                  }}
                  optionGroups={[{
                    sort: 1,
                    values: POPULAR_COUNTRY_CODES,
                    title: messages.selectGroupPopularTitle,
                  }]}
                  label={formatMessage(messages.countryCodeFieldLabel)}
                  showLabel
                  type="COUNTRY_CODE"
                />
              </Col>
              <Col xs={12} md={5}>
                <CommonFormGroup
                  errors={formApi.errors}
                  formApi={formApi}
                  formGroupClassname="formElementGutter"
                  input={{
                    help: { content: get(this.state.helpTexts, 'fields.vatNumberFieldLabel', null) },
                    name: `vat-number-${index}`,
                    type: 'text',
                    validate: true,
                    validation: {
                      strict: false,
                      mandatory: this.checkSomeValuesExistInForm([
                        `vat-countryCode-${index}`,
                        `vat-number-${index}`,
                      ]),
                      customErrorMessage: messages.enterVatNumber,
                      maxLength: '70',
                      minLength: '0',
                    },
                    visible: true,
                  }}
                  label={formatMessage(messages.vatNumberFieldLabel)}
                  showLabel
                  type="TEXT"
                />
              </Col>
              <Col xs={12} md={2} style={{ padding: '23px 10px' }}>
                <Button
                  bsStyle="default"
                  id-qa-test="button-remove-vat-row"
                  onClick={() => this.removeVatRow(index)}
                >
                  <FormattedMessage {...messages.removeBtnText} />
                </Button>
              </Col>
            </Row>
          )}
          {countryCodesetFetched && <Button
            bsStyle="primary"
            id-qa-test="button-add-vat-row"
            onClick={() => this.setState({
              vatNumbersIndexes: concat(vatNumbersIndexes, [moment().format('x')]),
            })}
          >
            <Icon name="add" /> <FormattedMessage {...messages.addNewBtnText} />
          </Button>}
        </Grid>
      </PanelNavigation>
    )
  }

  render() {
    const {
      fetchingCustomer,
      intl: { formatMessage },
    } = this.props

    const permitsHeading = (
      <Heading
        message={messages.heading}
      />
    )
    if (fetchingCustomer) {
      return (
        <Loader blocking />
      )
    }
    return (
      <div>
        {permitsHeading}
        {this.state.isSubmitting && <Loader blocking />}
        <main className="container" role="main" id="main">
          <Row>
            <Col md={2} lg={3}>
              <AutoAffix viewportOffsetTop={20} container={this}>
                <Nav
                  bsStyle="pills"
                  stacked
                  className="horizontal-on-sm"
                  activeKey={this.state.activePanel}
                  onSelect={selectedKey => this.setState({ activePanel: selectedKey })}
                >
                  <NavItem
                    id-qa-test={'btn-nav-applicantInformation'}
                    eventKey="#applicantInformation"
                    onClick={(event) => {
                      event.preventDefault()
                      focusAndScrollFirstFocusableElementInPanel('#applicantInformation')
                    }}
                  >
                    {formatMessage(messages.headingPanelApplicantInformation)}
                  </NavItem>
                  <NavItem
                    id-qa-test={'btn-nav-contactInformation'}
                    eventKey="#contactInformation"
                    onClick={(event) => {
                      event.preventDefault()
                      focusAndScrollFirstFocusableElementInPanel('#contactInformation')
                    }}
                  >
                    {formatMessage(messages.headingPanelContactInformation)}
                  </NavItem>
                  <NavItem
                    id-qa-test={'btn-nav-vatNumbers'}
                    eventKey="#vatNumbers"
                    onClick={(event) => {
                      event.preventDefault()
                      focusAndScrollFirstFocusableElementInPanel('#vatNumbers')
                    }}
                  >
                    {formatMessage(messages.headingPanelVATNumbers)}
                  </NavItem>
                </Nav>
              </AutoAffix>
            </Col>
            <Col md={10} lg={9}>
              <ErrorBoundary>
                <Form
                  getApi={this.connectFormApi}
                  render={formApi => (
                    <form
                      onSubmit={(event) => {
                        event.preventDefault()
                        this.submitForm()
                      }}
                    >
                      {this.renderApplicantInformation(formApi)}
                      {this.renderContactInformation(formApi)}
                      {this.renderVATNumberSection(formApi)}
                      <ButtonToolbar>
                        <Button
                          type="submit"
                          bsStyle="primary"
                          id-qa-test="button-submit-form"
                        >
                          <FormattedMessage {...messages.submit} />
                        </Button>
                      </ButtonToolbar>
                    </form>
                  )}
                />
              </ErrorBoundary>
            </Col>
          </Row>
        </main>
      </div>
    )
  }
}
