import React from 'react'
import classNames from 'classnames'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { get } from 'lodash'
import ReactMarkdown from 'react-markdown'

import { collectCmsMessages } from 'src/utils'
import styles from './LocationModalContent.scss'
import Icon from '../../../components/Icon'

import {
  STREET_ADDRESS_TYPE_NUMBER,
  POST_ADDRESS_TYPE_NUMBER,
} from '../constants'

function getAddressTypeTitle(addressType, locationModalContent) {
  let addressTypeText = null
  const { streetAddressHeading, postAddressHeading } = locationModalContent

  if (addressType) {
    if (addressType === POST_ADDRESS_TYPE_NUMBER) {
      addressTypeText = postAddressHeading
    } else if (addressType === STREET_ADDRESS_TYPE_NUMBER) {
      addressTypeText = streetAddressHeading
    }
  }

  return addressTypeText
}

function renderFunctions(func, type) {
  const foundFunction = func.find(f => f.functionType === type)

  if (foundFunction) {
    return foundFunction.oldFunctionExtensions.oldFunctionExtension.map((extension, extIndex) => (
      <div key={extIndex}>
        {extension}
      </div>
    ))
  }

  return null
}

function LocationModalContent(props) {
  const {
    cmsMessages,
    office,
    fullName,
    id,
  } = props

  const locationModalContent = collectCmsMessages('/customer-information/locationinformation/modal', cmsMessages)

  const {
    officeIdHeading,
    officeNameHeading,
    companyNameHeading,
    infoText,
    arexTableHeading,
    importTableHeading,
    exportTableHeading,
    transitTableHeading,
    taxBorderTableHeading,
    warehouseTableHeading,
    noExtensionForThisFunctionText,
    yesExtensionForThisFunctionText,
    tableCaption,
  } = locationModalContent

  return (
    <div>
      <div className={styles.row}>
        <div className={styles.column} tabIndex="-1" id="location-information-modal-officeId">
          <div className={styles.title}>
            {officeIdHeading}
          </div>
          <div className={styles.valueText}>
            {id}
          </div>
        </div>
        <div className={styles.widerColumn}>
          <div className={styles.title}>
            {officeNameHeading}
          </div>
          <div className={styles.valueText}>
            {office.name}
          </div>
        </div>
        <div className={styles.column}>
          <div className={styles.title}>
            {companyNameHeading}
          </div>
          <div className={styles.valueText}>
            {fullName}
          </div>
        </div>
      </div>
      <div className={styles.addressRow}>
        {office.addresses && office.addresses.map((addr, index) => (
          <div className={styles.addressColumn} key={index}>
            <div className={styles.title}>
              {getAddressTypeTitle(addr.addressType, locationModalContent)}
            </div>
            <div className={classNames(styles.valueText, styles.streetText)}>
              {addr.address}
            </div>
            <div className={styles.valueText}>
              {addr.zipCode} <div className={styles.cityText}>{addr.postOffice}</div>
            </div>
            <div className={styles.valueText}>
              {addr.countryCode}
            </div>
          </div>
        ))
        }
      </div>
      <div className={classNames('alert alert-info alert-dismissable', styles.alertBox)}>
        <div className="row">
          <div className={classNames('col-xs-1', styles.iconContainer)}>
            <Icon name="info" className="pull-left icon-blue" lg />
          </div>
          <div className={classNames('col-xs-11', styles.textWrapper)}>
            <span className={styles.alertTexts}>
              <p className={styles.textContainer}>
                <span id="office-help">
                  <ReactMarkdown
                    source={infoText}
                  />
                </span>
              </p>
            </span>
          </div>
        </div>
      </div>
      <div className={styles.tableContainer}>
        <table>
          {tableCaption && <caption className={'sr-only'}>{tableCaption}</caption>}
          <thead>
            <tr>
              <th>
                {arexTableHeading}
              </th>
              <th>
                {importTableHeading}
              </th>
              <th>
                {exportTableHeading}
              </th>
              <th>
                {transitTableHeading}
              </th>
              <th>
                {taxBorderTableHeading}
              </th>
              <th>
                {warehouseTableHeading}
              </th>
            </tr>
          </thead>

          <tbody>
            {office && office.functions &&
              <tr>
                <td>
                  {renderFunctions(office.functions.function, 14)
                    ||
                    noExtensionForThisFunctionText
                  }
                </td>
                <td>
                  {renderFunctions(office.functions.function, 3)
                    ||
                    noExtensionForThisFunctionText
                  }
                </td>
                <td>
                  {renderFunctions(office.functions.function, 13)
                    ||
                    noExtensionForThisFunctionText
                  }
                </td>
                <td>
                  {renderFunctions(office.functions.function, 4)
                    ||
                    noExtensionForThisFunctionText
                  }
                </td>
                <td>
                  {office.functions.function.find(f => f.functionType === 11) ?
                    yesExtensionForThisFunctionText :
                    noExtensionForThisFunctionText
                  }
                </td>
                <td>
                  {office.warehouses && office.warehouses.warehouse.length > 0 ?
                    yesExtensionForThisFunctionText :
                    noExtensionForThisFunctionText
                  }
                </td>
              </tr>
            }
            {!office.functions &&
              <tr>
                <td>
                  {noExtensionForThisFunctionText}
                </td>
                <td>
                  {noExtensionForThisFunctionText}
                </td>
                <td>
                  {noExtensionForThisFunctionText}
                </td>
                <td>
                  {noExtensionForThisFunctionText}
                </td>
                <td>
                  {noExtensionForThisFunctionText}
                </td>
                <td>
                  {noExtensionForThisFunctionText}
                </td>
              </tr>
            }
          </tbody>
        </table>
      </div>
    </div >
  )
}

const mapStateToProps = state => ({
  cmsMessages: get(state, 'content.cmsMessages', {}),
})

export default connect(mapStateToProps)(injectIntl(LocationModalContent))
