import React from 'react'
import { get, includes } from 'lodash'
import ReactMarkdown from 'react-markdown'
import classNames from 'classnames'
import { Form } from 'react-form'
import CommonFormGroup from 'src/components/form_v2/CommonFormGroup'

import {
  Grid,
  Col,
  Row,
  Button,
  ControlLabel,
  FormControl,
  FormGroup,
} from 'react-bootstrap'

import { AUTH_TYPES } from 'src/constants'
import { formatBusinessId } from 'src/utils'
import showNotification from 'src/utils/notifications'
import Checkbox from 'src/components/form/Checkbox'
import Icon from 'src/components/Icon'
import PanelNavigation from 'src/components/PanelNavigation'
import Alert from 'src/components/Alert'
import LoadingModal from './LoadingModal'
import HeaderMenu from './HeaderMenu'
import AccessibleMessage from '../../../components/AccessibleMessage'

import {
  FORM_STATE,
  PAYMENT_STARTED,
  PAYMENT_ALREADY_STARTED,
  PAYMENT_CODE_PAID,
  INDIVIDUAL_GUARANTEE_ROUTE_PATH,
  STOP_PAGE_ROUTE,
  PAYBACK_IBAN_ID,
  PAYBACK_BIC_ID,
  INVALID_PARAMETERS_ERROR,
  INDIVIDUAL_GUARANTEE_CATEGORY_TRANSIT,
} from '../constants'
import {
  getMessage,
  validateIbanField,
  validateBicField,
  validateCheckedTerms,
  formatIban,
  formatEuros,
  isGuaranteePaid,
} from '../utils'


import styles from './IndividualGuaranteeForm.scss'
import { getIndividualGuaranteeStatus, sendBankingDetails, startPayment } from '../api'
import ErrorBoundary from '../../../components/ErrorBoundary'
import Modal from '../../../components/Modal'
import { getSelectedAuthorization } from '../../../utils/auth'

export default class IndividualGuaranteeForm extends React.Component {
  constructor(props) {
    super(props)
    this.formApi = null

    this.state = {
      guaranteeState: FORM_STATE.UNKNOWN,
      fetchingGuaranteeDetails: false,
      submittingPaybackDetails: false,
      paymentReferenceNumber: '',
      paybackIban: '',
      paybackBic: '',
      paybackCheckedTermsError: undefined,
      paybackTermsChecked: false,
      paybackTermsTouched: false,
      updateFailed: false,
      showPaybackCheckModal: false,
      showContinueToPaymentModal: false,
      showStopModal: false,
      guaranteeCallHasSucceeded: false,
      MERCHANT_ID: '',
      URL_SUCCESS: '',
      URL_CANCEL: '',
      URL_NOTIFY: '',
      ORDER_NUMBER: '',
      PARAMS_IN: '',
      AMOUNT: '',
      AUTHCODE: '',
      PARAMS_OUT: '',
      LOCALE: '',
    }

    this.handleTermsChange = this.handleTermsChange.bind(this)
    this.handleTermsBlur = this.handleTermsBlur.bind(this)
    this.handleSubmitPaybackDetails = this.handleSubmitPaybackDetails.bind(this)
    this.handlePayment = this.handlePayment.bind(this)
  }

  componentDidMount() {
    this.updateCustomerInformation()
    const { grn, correlationId, timestamp, checksum, guaranteeCategory, invalidParameters, cmsMessages } = this.props

    if (invalidParameters) {
      const invalidParametersText = getMessage('invalidParametersText', cmsMessages)
      showNotification({
        level: 'error',
        message: invalidParametersText,
        autoDismiss: true,
      })
      throw new Error(INVALID_PARAMETERS_ERROR)
    }

    this.updateIndividualGuaranteeStatus(grn, correlationId, timestamp, checksum, guaranteeCategory)
  }

  componentDidUpdate(prevProps) {
    if (this.props.grn !== prevProps.grn) {
      const { grn, correlationId, timestamp, checksum, guaranteeCategory } = this.props
      this.updateIndividualGuaranteeStatus(grn, correlationId, timestamp, checksum, guaranteeCategory)
    }
    if (this.props.selectedOrganizationId !== prevProps.selectedOrganizationId) {
      this.updateCustomerInformation()
    }
  }

  getButtonToolbar() {
    const { guaranteeState } = this.state
    const { cmsMessages } = this.props
    const nextButtonText = cmsMessages['/individual-guarantee/nextButtonText']
    const continueToPaymentButtonText = cmsMessages['/individual-guarantee/continueToPaymentButtonText']
    const refreshStatusButtonText = cmsMessages['/individual-guarantee/refreshStatusButtonText']
    const stopButtonText = cmsMessages['/individual-guarantee/stopButtonText']

    if (isGuaranteePaid(guaranteeState)) {
      return null
    }
    return (
      <div className={styles.buttonToolbar}>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          {(guaranteeState === FORM_STATE.INPAYMENT ||
            guaranteeState === FORM_STATE.PAYABLE ||
            guaranteeState === FORM_STATE.CREATED) &&
            <Button
              bsStyle="default"
              className={styles.leftButton}
              onClick={() => this.changeShowStopModal(true)}
            >
              {stopButtonText}
            </Button>
          }
          {guaranteeState === FORM_STATE.CREATED &&
            <Button
              bsStyle="primary"
              onClick={this.validatePaybackDetails}
            >
              {nextButtonText}
            </Button>
          }
          {guaranteeState === FORM_STATE.INPAYMENT &&
            <Button
              bsStyle="default"
              className={styles.leftButton}
              onClick={this.updateGuaranteeStatusButtonClick}
            >
              {refreshStatusButtonText}
            </Button>
          }
          {(guaranteeState === FORM_STATE.PAYABLE || guaranteeState === FORM_STATE.INPAYMENT) &&
            <Button
              type="submit"
              bsStyle="primary"
              className={'payment-button'}
              onClick={e => this.verifyStatus(e)}
            >
              {continueToPaymentButtonText}
            </Button>
          }
        </div>
      </div>
    )
  }

  getFormTitle() {
    const { cmsMessages } = this.props
    const { guaranteeState } = this.state
    let formTitle
    if (guaranteeState === FORM_STATE.CREATED) {
      formTitle = getMessage('createdTitle', cmsMessages)
    } else if (guaranteeState === FORM_STATE.PAYABLE || guaranteeState === FORM_STATE.INPAYMENT) {
      formTitle = getMessage('payableTitle', cmsMessages)
    } else if (isGuaranteePaid(guaranteeState)) {
      formTitle = getMessage('paidTitle', cmsMessages)
    }
    return formTitle
  }

  getHeadingMenuItems() {
    const { cmsMessages } = this.props
    const { guaranteeState } = this.state
    return [
      {
        text: getMessage('createdTitle', cmsMessages),
        active: guaranteeState === FORM_STATE.CREATED,
      },
      {
        text: getMessage('payableTitle', cmsMessages),
        active: (guaranteeState === FORM_STATE.PAYABLE || guaranteeState === FORM_STATE.INPAYMENT),
      },
      {
        text: getMessage('paidTitle', cmsMessages),
        active: isGuaranteePaid(guaranteeState),
      },
    ]
  }

  getPaybackTermsText() {
    const { guaranteeCategory, cmsMessages } = this.props
    let paybackTerms
    if (guaranteeCategory) {
      paybackTerms = getMessage(`${guaranteeCategory}/paybackTerms`, cmsMessages)
    }
    if (!paybackTerms) {
      paybackTerms = getMessage('paybackTerms', cmsMessages)
    }
    return paybackTerms
  }

  updateCustomerInformation() {
    const { userName, authorization } = this.props
    this.setState({ name: userName })

    if (authorization && authorization.type === AUTH_TYPES.business) {
      this.setState({ company: authorization })
    } else {
      this.setState({ company: null })
    }
  }

  updateGrnAndCorrelationId(grn, correlationId) {
    this.setState({ correlationId, grn })
  }

  updateGuaranteeStatusButtonClick = () => {
    const { grn, correlationId, timestamp, checksum, guaranteeCategory } = this.props

    this.updateIndividualGuaranteeStatus(grn, correlationId, timestamp, checksum, guaranteeCategory)
  }

  updateIndividualGuaranteeStatus(grn, correlationId, timestamp, checksum, guaranteeCategory) {
    if (grn && correlationId && timestamp && checksum) {
      this.setState({ fetchingGuaranteeDetails: true })
      getIndividualGuaranteeStatus(grn, correlationId, timestamp, checksum, guaranteeCategory).then((result) => {
        this.setState({
          fetchingGuaranteeDetails: false,
          guaranteeState: result.guaranteeState,
          guaranteeAmount: result.guaranteeAmount,
          paybackBic: result.bic,
          paybackIban: result.iban,
          paymentReferenceNumber: result.paymentReferenceNumber,
          guaranteeCallHasSucceeded: true,
        })
      }).catch(() => {
        this.setState({ fetchingGuaranteeDetails: false, updateFailed: true })
      })
    }
  }

  focusInvalidInput = (ibanError, bicError) => {
    const bic = document.getElementById(PAYBACK_BIC_ID)
    const iban = document.getElementById(PAYBACK_IBAN_ID)
    if (ibanError && iban) {
      iban.focus()
    } else if (bicError && bic) {
      bic.focus()
    }
  }

  validatePayback() {
    this.formApi.validate(PAYBACK_IBAN_ID)
    this.formApi.validate(PAYBACK_BIC_ID)
    const form = this.formApi.getFormState()
    const errors = get(form, 'errors')
    const touched = get(form, 'touched')
    const values = get(form, 'values')
    const paybackIbanError = get(errors, PAYBACK_IBAN_ID)
    const paybackBicError = get(errors, PAYBACK_BIC_ID)
    const paybackIbanTouched = get(touched, PAYBACK_IBAN_ID)
    const paybackBicTouched = get(touched, PAYBACK_BIC_ID)
    const paybackIban = get(values, PAYBACK_IBAN_ID)
    const paybackBic = get(values, PAYBACK_BIC_ID)

    const ibanError = paybackIbanError || this.ibanValidation(paybackIban)
    const bicError = paybackBicError || this.bicValidation(paybackBic)

    this.focusInvalidInput(ibanError, bicError)

    return (!ibanError && !bicError && paybackIbanTouched && paybackBicTouched)
  }

  validateTermsChecked() {
    const { paybackTermsChecked } = this.state
    this.setState({
      paybackTermsTouched: true,
    })
    return paybackTermsChecked
  }

  verifyStatus = (e) => {
    const { guaranteeState } = this.state
    const validForm = this.validateTermsChecked()

    if (validForm) {
      if (guaranteeState === FORM_STATE.INPAYMENT) {
        this.setState({ showContinueToPaymentModal: true })
      } else {
        this.handlePayment(e)
      }
    } else {
      const requiredMessage = validateCheckedTerms(false)
      this.setState({ paybackCheckedTermsError: requiredMessage })
    }
  }

  handlePayment(e) {
    this.disablePaymentButton(true)
    e.preventDefault()
    const {
      grn,
      correlationId,
      timestamp,
      checksum,
      guaranteeCategory,
    } = this.props

    startPayment(grn, correlationId, timestamp, checksum, guaranteeCategory).then((res) => {
      if (res.status === PAYMENT_STARTED || res.status === PAYMENT_ALREADY_STARTED) {
        if (res.status === PAYMENT_STARTED) {
          const paymentGateway = res.response.href // TODO prefer rendering payment options directly in Tuttu
          window.location.replace(paymentGateway)
        } else if (res.status === PAYMENT_CODE_PAID) {
          this.setState({ showPaidConfirmation: true })
        } else if (res.status === PAYMENT_ALREADY_STARTED) {
          this.setState({ showConfirmation: true })
        }
      }
      this.disablePaymentButton(false)
    })
  }

  // eslint-disable-next-line class-methods-use-this
  disablePaymentButton(disabled) {
    const button = document.querySelector('.payment-button')

    if (button) {
      button.disabled = disabled
    }
  }

  handleTermsChange() {
    const { paybackTermsChecked } = this.state
    const updatedTermsChecked = !paybackTermsChecked
    const validatedCheckedTerms = validateCheckedTerms(updatedTermsChecked)
    this.setState({
      paybackTermsChecked: updatedTermsChecked,
      paybackTermsTouched: true,
      paybackCheckedTermsError: validatedCheckedTerms,
    })
  }

  bicValidation = (value) => {
    const paybackBic = value ? value.trim().toUpperCase() : ''
    return validateBicField(paybackBic)
  }

  ibanValidation = (value) => {
    const paybackIban = value ? value.trim().toUpperCase() : ''
    return validateIbanField(paybackIban)
  }

  handleIbanChange = (value) => {
    const paybackIban = value ? value.trim().toUpperCase() : ''
    this.setState({ paybackIban })
  }

  handleBicChange = (value) => {
    const paybackBic = value ? value.trim().toUpperCase() : ''
    this.setState({ paybackBic })
  }

  handleTermsBlur() {
    const { paybackTermsChecked } = this.state
    const validatedCheckedTerms = validateCheckedTerms(paybackTermsChecked)
    this.setState({ paybackTermsTouched: true, paybackCheckedTermsError: validatedCheckedTerms })
  }

  hidePaybackCheckModal = () => {
    this.setState({ showPaybackCheckModal: false })
  }

  hideContinueToPaymentModal = () => {
    this.setState({ showContinueToPaymentModal: false })
  }

  changeShowStopModal = (boolValue) => {
    this.setState({ showStopModal: boolValue })
  }

  validatePaybackDetails = () => {
    const validForm = this.validatePayback()
    if (validForm) {
      this.setState({ showPaybackCheckModal: true })
    }
  }

  handleSubmitPaybackDetails() {
    const validForm = this.validatePayback()
    if (validForm) {
      const { paybackIban, paybackBic } = this.state
      const { grn, correlationId, timestamp, checksum, guaranteeCategory } = this.props

      this.setState({ submittingPaybackDetails: true, showPaybackCheckModal: false })

      const bankingDetails = {
        iban: paybackIban,
        bic: paybackBic,
      }

      sendBankingDetails(grn, correlationId, timestamp, checksum, bankingDetails, guaranteeCategory).then((response) => {
        this.setState({
          submittingPaybackDetails: false,
          guaranteeState: response.guaranteeState,
          guaranteeAmount: response.guaranteeAmount,
          paybackBic: response.bic,
          paybackIban: response.iban,
          paymentReferenceNumber: response.paymentReferenceNumber,
        })
      })
    }
  }

  connectFormApi = (formApi) => {
    this.formApi = formApi
  }

  renderCompany(formApi) {
    const { cmsMessages } = this.props
    const {
      name,
      company,
    } = this.state

    const informationTitle = getMessage('informationTitle', cmsMessages)
    const customerIdLabel = getMessage('customerIdLabel', cmsMessages)
    const companyNameLabel = getMessage('companyNameLabel', cmsMessages)
    const userNameLabel = getMessage('userNameLabel', cmsMessages)

    return (
      <Grid fluid>
        <h3>{informationTitle}</h3>
        {company &&
          <Row>
            <Col xs={12} sm={4}>
              <FormGroup>
                <ControlLabel>
                  {customerIdLabel}
                </ControlLabel>
                <FormControl.Static>
                  {formatBusinessId(company.id)}
                </FormControl.Static>
              </FormGroup>
            </Col>
            <Col xs={12} sm={4}>
              <FormGroup>
                <ControlLabel>
                  {companyNameLabel}
                </ControlLabel>
                <FormControl.Static>
                  {company.name}
                </FormControl.Static>
              </FormGroup>
            </Col>
            <Col xs={12} sm={4}>
              <FormGroup>
                <ControlLabel>
                  {userNameLabel}
                </ControlLabel>
                <FormControl.Static>
                  {name}
                </FormControl.Static>
              </FormGroup>
            </Col>
          </Row>
        }
        {this.renderBase(formApi)}
      </Grid>

    )
  }

  renderPerson(formApi) {
    const { cmsMessages } = this.props
    const {
      name,
    } = this.state

    const informationTitle = getMessage('informationTitle', cmsMessages)
    const userNameLabel = getMessage('userNameLabel', cmsMessages)

    return (
      <Grid fluid>
        <h3>{informationTitle}</h3>
        <Row>
          <Col xs={12} sm={6}>
            <FormGroup className={styles.formGroup}>
              <ControlLabel>
                {userNameLabel}
              </ControlLabel>
              <FormControl.Static>
                {name}
              </FormControl.Static>
            </FormGroup>
          </Col>
        </Row>
        {this.renderBase(formApi)}

      </Grid>

    )
  }

  renderBase(formApi) {
    const {
      guaranteeState,
      paybackIban,
      paybackBic,
      paybackCheckedTermsError,
      paybackTermsChecked,
      paybackTermsTouched,
      guaranteeAmount,
      paymentReferenceNumber,
    } = this.state
    const {
      grn,
      correlationId,
      cmsMessages,
    } = this.props

    const paybackIbanHelpText = getMessage('paybackIbanHelpText', cmsMessages)
    const paybackTerms = this.getPaybackTermsText()
    const grnLabel = getMessage('grnLabel', cmsMessages)
    const mrnLabel = getMessage('mrnLabel', cmsMessages)
    const paymentReferenceNumberLabel = getMessage('paymentReferenceNumberLabel', cmsMessages)
    const paymentInformationTitle = getMessage('paymentInformationTitle', cmsMessages)
    const guaranteeAmountLabel = getMessage('guaranteeAmountLabel', cmsMessages)
    const paybackHeading = getMessage('paybackHeading', cmsMessages)
    const individualGuaranteeInformationTitle = getMessage('individualGuaranteeInformationTitle', cmsMessages)
    const overviewPageWarningText = getMessage('overviewPageWarningText', cmsMessages)
    const paybackIbanLabel = getMessage('paybackIbanLabel', cmsMessages)
    const paybackBicLabel = getMessage('paybackBicLabel', cmsMessages)
    const ibanHelpText = getMessage('ibanHelpText', cmsMessages)
    const ibanValidationErrorMessage = getMessage('ibanValidationErrorMessage', cmsMessages)
    const bicValidationErrorMessage = getMessage('bicValidationErrorMessage', cmsMessages)

    return ([
      <h3 key={0} className={styles.infoTitle}>{individualGuaranteeInformationTitle}</h3>,
      <Row key={1}>
        <Col xs={12} sm={6}>
          <FormGroup>
            <ControlLabel>
              {mrnLabel}
            </ControlLabel>
            <FormControl.Static>
              {correlationId}
            </FormControl.Static>
          </FormGroup>
        </Col>
        <Col xs={12} sm={6}>
          <FormGroup>
            <ControlLabel>
              {grnLabel}
            </ControlLabel>
            <FormControl.Static>
              {grn}
            </FormControl.Static>
          </FormGroup>
        </Col>
      </Row>,
      <h3 key={2} className={styles.infoTitle}>{paybackHeading}</h3>,
      guaranteeState === FORM_STATE.CREATED &&
      <div key={3}>
        <Row>
          <Col xs={12} sm={6}>
            <CommonFormGroup
              label={paybackIbanLabel}
              showLabel
              input={{
                name: PAYBACK_IBAN_ID,
                type: 'text',
                help: { content: ibanHelpText },
                validate: true,
                validation: {
                  mandatory: true,
                  customErrorMessage: ibanValidationErrorMessage,
                  validator: {
                    fn: this.ibanValidation,
                  },
                },
                visible: true,
                onChange: this.handleIbanChange,
              }}
              formApi={formApi}
              helpText={paybackIbanHelpText}
              errors={formApi.errors}
              inputStyle={{ textTransform: 'uppercase' }}
            />
          </Col>
          <Col xs={12} sm={6}>
            <CommonFormGroup
              label={paybackBicLabel}
              showLabel
              input={{
                name: PAYBACK_BIC_ID,
                type: 'text',
                validate: true,
                validation: {
                  mandatory: true,
                  customErrorMessage: bicValidationErrorMessage,
                  validator: {
                    fn: this.bicValidation,
                  },
                },
                visible: true,
                onChange: this.handleBicChange,
              }}
              formApi={formApi}
              errors={formApi.errors}
              inputStyle={{ textTransform: 'uppercase' }}
            />
          </Col>
        </Row>
      </div>,
      includes([FORM_STATE.PAYABLE, FORM_STATE.INPAYMENT, FORM_STATE.PAID, FORM_STATE.AVAILABLE,
        FORM_STATE.RESERVED, FORM_STATE.LIABILITY_RESERVED], guaranteeState) &&
      <Row key={4}>
        <Col xs={12} sm={6}>
          <FormGroup>
            <ControlLabel>
              {paybackIbanLabel}
            </ControlLabel>
            <FormControl.Static>
              {formatIban(paybackIban)}
            </FormControl.Static>
          </FormGroup>
        </Col>
        <Col xs={12} sm={6}>
          <FormGroup>
            <ControlLabel>
              {paybackBicLabel}
            </ControlLabel>
            <FormControl.Static>
              {paybackBic}
            </FormControl.Static>
          </FormGroup>
        </Col>
      </Row>,
      includes([FORM_STATE.PAYABLE], guaranteeState) &&
      <Alert key={5} warning className={styles.gridWarning}>
        <Icon name="attention" className="icon-yellow" lg />
        <div>{overviewPageWarningText}</div>
      </Alert>,
      !includes([FORM_STATE.CREATED], guaranteeState) && [
        <h3 key={6} className={styles.infoTitle}>{paymentInformationTitle}</h3>,
        <Row
          key={7}
          className={!includes([FORM_STATE.PAID, FORM_STATE.AVAILABLE, FORM_STATE.RESERVED,
            FORM_STATE.LIABILITY_RESERVED], guaranteeState) ? styles.paymentInformation : undefined}
        >
          <Col xs={12} sm={6}>
            <FormGroup>
              <ControlLabel>
                {guaranteeAmountLabel}
              </ControlLabel>
              <FormControl.Static>
                {formatEuros(guaranteeAmount)}
              </FormControl.Static>
            </FormGroup>
          </Col>
          {paymentReferenceNumber &&
            <Col xs={12} sm={6}>
              <FormGroup>
                <ControlLabel>
                  {paymentReferenceNumberLabel}
                </ControlLabel>
                <FormControl.Static>
                  {paymentReferenceNumber}
                </FormControl.Static>
              </FormGroup>
            </Col>
          }
        </Row>],
      (guaranteeState === FORM_STATE.PAYABLE || guaranteeState === FORM_STATE.INPAYMENT) &&
      <Row key={8}>
        <Col xs={12}>
          <FormGroup validationState={(paybackTermsTouched && !paybackTermsChecked) ? 'error' : null}>
            <Checkbox
              id-qa-test={'individual-gurantee.termsCheckbox'}
              checked={paybackTermsChecked}
              onClick={this.handleTermsChange}
              onBlur={this.handleTermsBlur}
              aria-describedby="handle-terms-error"
            >
              <ReactMarkdown source={paybackTerms} />
            </Checkbox>
            {paybackTermsTouched && !paybackTermsChecked && paybackCheckedTermsError &&
              <AccessibleMessage id="handle-terms-error" message={paybackCheckedTermsError} className="help-block" />
            }
          </FormGroup>
        </Col>
      </Row>]
    )
  }


  render() {
    const {
      cmsMessages,
      payment,
      missingParameters,
      history,
      guaranteeCategory,
    } = this.props

    const {
      fetchingGuaranteeDetails,
      submittingPaybackDetails,
      company,
      guaranteeState,
      paybackIban,
      paybackBic,
      MERCHANT_ID,
      URL_CANCEL,
      URL_SUCCESS,
      URL_NOTIFY,
      ORDER_NUMBER,
      PARAMS_IN,
      AMOUNT,
      AUTHCODE,
      PARAMS_OUT,
      LOCALE,
      updateFailed,
      guaranteeCallHasSucceeded,
      showPaybackCheckModal,
      showContinueToPaymentModal,
      showStopModal,
    } = this.state

    const paybackIbanLabel = getMessage('paybackIbanLabel', cmsMessages)
    const paybackBicLabel = getMessage('paybackBicLabel', cmsMessages)
    const infoBoxContent = getMessage('infoBoxContent', cmsMessages)
    const continueModalTitle = getMessage('continueModalTitle', cmsMessages)
    const continueModalContent = getMessage('continueModalContent', cmsMessages)
    const continueModalContinueButtonText = getMessage('continueModalContinueButtonText', cmsMessages)
    const continueModalCancelButtonText = getMessage('continueModalCancelButtonText', cmsMessages)
    const paybackWarningModalTitle = getMessage('paybackWarningModalTitle', cmsMessages)
    const paybackWarningModalBodyHeading = getMessage('paybackWarningModalBodyHeading', cmsMessages)
    const paybackWarningModalCancelButtonText = getMessage('paybackWarningModalCancelButtonText', cmsMessages)
    const paybackWarningModalContinueButtonText = getMessage('paybackWarningModalContinueButtonText', cmsMessages)
    const stopModalTitle = getMessage('stopModalTitle', cmsMessages)
    const stopModalContent = getMessage('stopModalContent', cmsMessages)
    const stopModalContentTransit = getMessage('stopModalContentTransit', cmsMessages)
    const stopModalAcceptButtonText = getMessage('stopModalAcceptButtonText', cmsMessages)
    const stopModalCancelButtonText = getMessage('stopModalCancelButtonText', cmsMessages)
    const headingMenuSrOnlyText = getMessage('headingMenuSrOnlyText', cmsMessages)
    const paymentError = getMessage('paymentError', cmsMessages)
    const inPaymentWarningMessage = getMessage('inPaymentWarningMessage', cmsMessages)
    const individualGuaranteeFailedErrorContent = getMessage('individualGuaranteeFailedErrorContent', cmsMessages)

    const isTransit = guaranteeCategory === INDIVIDUAL_GUARANTEE_CATEGORY_TRANSIT
    const updateCallFailedFirstTimeInTransit = updateFailed && !guaranteeCallHasSucceeded && isTransit
    const stopRoute = `${INDIVIDUAL_GUARANTEE_ROUTE_PATH}/${STOP_PAGE_ROUTE}${isTransit ? `?guaranteeCategory=${INDIVIDUAL_GUARANTEE_CATEGORY_TRANSIT}` : ''}`

    return (
      <ErrorBoundary
        forceError={updateFailed || missingParameters}
        content={updateCallFailedFirstTimeInTransit ? individualGuaranteeFailedErrorContent : undefined}
      >
        <Grid>
          <Row>
            <Col xs={12} sm={4} md={3}>
              <h2 className={'sr-only'}>{headingMenuSrOnlyText}</h2>
              <HeaderMenu items={this.getHeadingMenuItems()} />
            </Col>
            <Col xs={12} sm={8} md={9}>
              <PanelNavigation
                title={this.getFormTitle()}
                active
                style={{ paddingBottom: 0, marginBottom: guaranteeState === FORM_STATE.AVAILABLE ? 0 : undefined }}
              >
                <Modal
                  titleMessage={paybackWarningModalTitle}
                  show={showPaybackCheckModal}
                  showContinue
                  onClickContinue={this.handleSubmitPaybackDetails}
                  onClickCancel={this.hidePaybackCheckModal}
                  continueMessage={paybackWarningModalContinueButtonText}
                  cancelMessage={paybackWarningModalCancelButtonText}
                  bodyStyle={{ padding: 0 }}
                  size="lg"
                  focusDisableButton
                >
                  <div id={'dialog-message'}>
                    <div className={styles.warningModalBody}>
                      <h3>{paybackWarningModalBodyHeading}</h3>
                      <Row className={styles.warningModalRow}>
                        <Col xs={12} sm={6}>
                          <FormGroup>
                            <ControlLabel>
                              {paybackIbanLabel}
                            </ControlLabel>
                            <FormControl.Static>
                              {paybackIban && formatIban(paybackIban)}
                            </FormControl.Static>
                          </FormGroup>
                        </Col>
                        <Col xs={12} sm={6}>
                          <FormGroup>
                            <ControlLabel>
                              {paybackBicLabel}
                            </ControlLabel>
                            <FormControl.Static>
                              {paybackBic}
                            </FormControl.Static>
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                    <Alert warning>
                      <Icon name="attention" className="icon-yellow" lg />
                      <div>{getMessage('paybackWarningModalText', cmsMessages)}</div>
                    </Alert>
                  </div>
                </Modal>
                <Modal
                  titleMessage={continueModalTitle}
                  show={showContinueToPaymentModal}
                  showContinue
                  onClickContinue={e => this.handlePayment(e)}
                  onClickCancel={this.hideContinueToPaymentModal}
                  continueMessage={continueModalContinueButtonText}
                  cancelMessage={continueModalCancelButtonText}
                  focusDisableButton
                >
                  <div id={'dialog-message'}>
                    <ReactMarkdown source={continueModalContent} />
                  </div>
                </Modal>
                <Modal
                  titleMessage={stopModalTitle}
                  show={showStopModal}
                  showContinue
                  onClickContinue={() => history.push(stopRoute)}
                  onClickCancel={() => this.changeShowStopModal(false)}
                  continueMessage={stopModalAcceptButtonText}
                  cancelMessage={stopModalCancelButtonText}
                  focusDisableButton
                >
                  <div id={'dialog-message'}>
                    <ReactMarkdown source={isTransit ? stopModalContentTransit : stopModalContent} />
                  </div>
                </Modal>
                <LoadingModal
                  visible={fetchingGuaranteeDetails}
                  titleText={getMessage('loadingGuaranteeDetailsTitle', cmsMessages)}
                  messageText={getMessage('loadingGuaranteeDetails', cmsMessages)}
                />
                <LoadingModal
                  visible={submittingPaybackDetails}
                  titleText={getMessage('submittingPaybackDetailsTitle', cmsMessages)}
                  messageText={getMessage('submittingPaybackDetails', cmsMessages)}
                />
                {(payment === 'success' || isGuaranteePaid(guaranteeState)) &&
                  <Alert success stretch>
                    <Icon name="checkmark" className="icon-green" lg />
                    <div>{getMessage('paymentSuccess', cmsMessages)}</div>
                  </Alert>
                }
                {payment === 'error' &&
                  [
                    <Alert error stretch key={0}>
                      <Icon name="attention" className="icon-red" lg />
                      <div>{paymentError}</div>
                    </Alert>,
                    <AccessibleMessage
                      key={1}
                      message={paymentError}
                      hideMessage
                    />,
                  ]
                }
                {guaranteeState === FORM_STATE.INPAYMENT &&
                  [
                    <Alert warning stretch key={0}>
                      <Icon name="attention" className="icon-yellow" lg />
                      <ReactMarkdown source={inPaymentWarningMessage} className={styles.inPaymentWarning} />
                    </Alert>,
                    <AccessibleMessage
                      key={1}
                      message={inPaymentWarningMessage}
                      hideMessage
                    />,
                  ]
                }
                <Form
                  getApi={this.connectFormApi}
                  render={formApi => (
                    <form>
                      {!fetchingGuaranteeDetails && ((company &&
                        this.renderCompany(formApi))
                        ||
                        this.renderPerson(formApi))}
                    </form>
                  )}
                />

                {isGuaranteePaid(guaranteeState) &&
                  <div className={classNames('alert alert-info alert-dismissable', styles.alertBox)}>
                    <div className="row">
                      <div className={classNames('col-xs-1', styles.iconContainer)}>
                        <Icon name="info" className="pull-left icon-blue" lg />
                      </div>
                      <div className={classNames('col-xs-11', styles.textWrapper)}>
                        <span className={styles.alertTexts}>
                          <p className={styles.textContainer}>
                            <span id="office-help">
                              <ReactMarkdown
                                source={infoBoxContent}
                              />
                            </span>
                          </p>
                        </span>
                      </div>
                    </div>
                  </div>}
              </PanelNavigation>
              <form
                action="https://payment.paytrail.com/e2"
                method="post"
                id="paytrail-form"
              >
                <input name="MERCHANT_ID" type="hidden" value={MERCHANT_ID} />
                <input name="URL_SUCCESS" type="hidden" value={URL_SUCCESS} />
                <input name="URL_CANCEL" type="hidden" value={URL_CANCEL} />
                <input name="URL_NOTIFY" type="hidden" value={URL_NOTIFY} />
                <input name="ORDER_NUMBER" type="hidden" value={ORDER_NUMBER} />
                <input name="PARAMS_IN" type="hidden" value={PARAMS_IN} />
                <input name="PARAMS_OUT" type="hidden" value={PARAMS_OUT} />
                <input name="AMOUNT" type="hidden" value={AMOUNT} />
                <input name="AUTHCODE" type="hidden" value={AUTHCODE} />
                <input name="LOCALE" type="hidden" value={LOCALE} />
              </form>
              {this.getButtonToolbar()}
            </Col>
          </Row>
        </Grid>
      </ErrorBoundary>
    )
  }
}
