import React from 'react'
import ReactMarkdown from 'react-markdown'
import { get } from 'lodash'
import Heading from 'src/layout/Heading'
import LinkRenderer from 'src/components/LinkRenderer'
import Loader from 'src/components/Loader'
import { LOGGED_OUT_HASH } from 'src/constants'
import AuthenticatedFrontpage from './AuthenticatedFrontpage'
import AnonymousFrontpage from './AnonymousFrontpage'
import DisruptionNotice from './DisruptionNotice'
import LoggedOutModal from './LoggedOutModal'
import NoCustomerInfoModal from './NoCustomerInfoModal'
import messages from '../messages'

export default function Frontpage(props) {
  const {
    auth,
    locale,
    router,
    helpText,
    services,
    serviceGroups,
    intl,
    disruptionNoticeUrls,
    helpTextAll,
    showNoCustomerInfoModal,
    setShowNoCustomerInfoModal,
  } = props
  if (auth.isAuthenticating) {
    return <Loader blocking />
  }
  const ContentComponent = auth.isAuthenticated ? AuthenticatedFrontpage : AnonymousFrontpage
  const servicesInUse = services && services
    .filter(service => isPublished(service) && isAllowed(service, auth))
  const isLoggedOut = get(router, 'location.hash') === LOGGED_OUT_HASH
  return (
    <div>
      <Heading message={messages.title} compact noFocus />
      <div className="container">
        <DisruptionNotice
          noMargin
          locale={locale}
          className="container"
          disruptionNoticeUrls={disruptionNoticeUrls}
        />
      </div>
      <LoggedOutModal
        visible={isLoggedOut}
        helpTexts={helpTextAll}
      />
      <NoCustomerInfoModal
        visible={showNoCustomerInfoModal}
        helpTexts={helpTextAll}
        setShowModal={setShowNoCustomerInfoModal}
      />
      {helpText &&
        <div className="container welcome-panel">
          <ReactMarkdown
            source={helpText}
            renderers={{ Link: LinkRenderer }}
          />
        </div>
      }
      <main className="container" role="main" id="main">
        <ContentComponent
          locale={locale}
          services={servicesInUse}
          serviceGroups={serviceGroups}
          auth={auth}
          intl={intl}
        />
      </main>
    </div>
  )
}

function isAllowed(service, auth) {
  if (auth.isAuthenticated) {
    return get(service.extensionValues.find(extValue => extValue.extensionName === 'groupIdAuthenticated'), 'value')
  }
  return get(service.extensionValues.find(extValue => extValue.extensionName === 'groupIdUnauthenticated'), 'value')
}

function isPublished(service) {
  return get(service.extensionValues.find(extValue => extValue.extensionName === 'published'), 'value') === 'true'
}
