import {
  filter,
  find,
  get,
  has,
  includes,
  set,
  sortBy,
  uniq,
  zip,
  unzip,
  isString,
} from 'lodash'
import moment from 'moment'
import { uniqueId } from 'src/utils'
import { CODESET_STRING_DELIMITER } from 'src/components/codeset/constants'
import {
  getInfoElementsFromApplicationData,
  findInfoElementFromApplicationData,
  isCodeset,
  hasRepresentativeIdentification } from '../../permitHelper'
import {
  PERMIT_EQUIVALENT_GOOD_FIELD_PREFIX,
  PERMIT_EQUIVALENT_GOOD_CODE_FIELD_NAME,
  hiddenInfoElements,
} from './constants'
import {
  PERMIT_INFOELEMENT_SORT_ORDER,
  PERMIT_BOOLEAN_CHECKBOX_FIELDS,
  PERMIT_FIELD_SORT_ORDER,
  APPLICATION_TYPE_NEW_ALIAS,
  APPLICATION_TYPE_AMENDMENT,
  APPLICATION_TYPE_AMENDMENT_ALIAS,
  APPLICATION_TYPE_RENEW,
  APPLICATION_TYPE_RENEW_ALIAS,
  APPLICATION_TYPE_MAP,
  APPLICATION_TYPE_REVOCATION_ALIAS,
  APPLICATION_TYPE_REVOCATION,
  infoElementGroups,
  APPLICATION_TYPE_REJOINDER,
  RELIEF_SUB_CODESET_NAME,
  RELIEF_TITLE_SUB_CODESET_NAME,
  EXCEPTION_SUB_CODESET_NAME,
} from '../../constants'
import {
  USE_EQUIVALENT_GOOD_FIELD_NAME,
} from '../GoodsToBePlacedUnderProcedure/constants'
import api from '../../../api'
import {
  PERMIT_APPLICATION_STATUS_DRAFT,
} from '../../../constants'
import {
  getInfoElementCustomParams,
  getFieldCustomParams,
} from './customParams'
import { encodeReferenceNumber } from '../../utils'
import { AUTH_TYPES } from '../../../../../constants'

const additionalCodesets = ['VakuudenTaso']

export function getApplicationDate(applicationData) {
  if (applicationData.state === 'DRAFT') {
    return moment().toISOString()
  }
  const issueNumberInfoElement = findInfoElementFromApplicationData(applicationData, 'issueNumber')
  const issueNumberDate = find(issueNumberInfoElement.fieldValues, { code: 'issueNumberDate' })
  return moment(issueNumberDate.value).toISOString()
}

export function fetchPermitData(applicationData, authorizationOrDecisionHolderIdentification) {
  const applicationType = parseInt(get(applicationData, 'initialValues.applicationType.applicationType'), 10)
  const permitReferenceNumber = get(applicationData, 'initialValues.referenceNumber.referenceNumber')
  const encodedPermitReferenceNumber = encodeReferenceNumber(permitReferenceNumber)
  if ((applicationType === APPLICATION_TYPE_AMENDMENT ||
    applicationType === APPLICATION_TYPE_RENEW ||
    applicationType === APPLICATION_TYPE_REVOCATION ||
    applicationType === APPLICATION_TYPE_REJOINDER
  ) && encodedPermitReferenceNumber) {
    return api.fetchPermitLatestVersion(
      encodedPermitReferenceNumber,
      authorizationOrDecisionHolderIdentification
    ).then(permitData => ({ permitData, parsedApplicationData: applicationData }))
  }
  return Promise.resolve(null)
}

export function fetchCodesets(fetchNonCachedCodesets, structure, applicationDateISO) {
  const date = moment(applicationDateISO).format('YYYY-MM-DD')
  const codesets = []
  structure.infoElements.map(infoElement =>
    filter(infoElement.fields, field => isCodeset(field.type))
      .map((field) => {
        const subCodesetName = get(field, 'subCodesetName') || get(field, 'customsOfficeAuthorization') || ''
        // eslint-disable-next-line
        codesets.push(`${field.codesetName}${CODESET_STRING_DELIMITER}${subCodesetName}`)

        // We want to enrich relief data, AL-775
        if (subCodesetName === RELIEF_SUB_CODESET_NAME || subCodesetName === EXCEPTION_SUB_CODESET_NAME) {
          codesets.push(`${field.codesetName}${CODESET_STRING_DELIMITER}${RELIEF_TITLE_SUB_CODESET_NAME}`)
        }

        return null
      })
  )


  if (additionalCodesets) {
    additionalCodesets.forEach((additionalCodeset) => {
      codesets.push(additionalCodeset)
    })
  }

  return fetchNonCachedCodesets(uniq(codesets), date)
}

export function modifyStructure(structure, applicationData, opts) {
  const { locale, helpTexts, customerHasAEO, authType, codesets, applicationDate } = opts

  return {
    ...structure,
    groups: modifyGroups(structure, applicationData.state),
    infoElements: modifyInfoElements(
      addHelpTexts(
        structure.infoElements, helpTexts, locale, structure.code, applicationData, authType
      ),
      {
        applicationTypeCode: structure.code,
        customerHasAEO,
        authType,
        codesets,
        applicationDate,
      }
    ),
  }
}

function modifyGroups(structure, applicationState) {
  const hasInfoElements = group => filter(structure.infoElements, infoElement => infoElement.group === group.code && !hiddenInfoElements.includes(infoElement.code)).length > 0
  const originalGroups = structure.groups || []
  const groupsWithInfoElements = originalGroups.filter(hasInfoElements)

  return [
    ...groupsWithInfoElements.map(group =>
      ({
        ...group,
        visible: PERMIT_APPLICATION_STATUS_DRAFT === applicationState,
      })
    ),
    {
      code: 'summary',
      name: {
        fi: 'Yhteenveto ja lähetys',
        en: 'Summary and submission',
        sv: 'Sammandrag och sändning',
      },
      navigation: {
        next: false,
      },
      visible: true,
    },
  ]
}

function modifyInfoElements(originalInfoElements, opts) {
  const infoElements = originalInfoElements.map((infoElement) => {
    const repeat = parseInt(infoElement.repeat, 10)
    const isSingleField = repeat === 1 ||
      (repeat > 1 &&
        infoElement.fields.length === 1 &&
        isCodeset(infoElement.fields[0].type)
      )


    return modifyFields({
      ...infoElement,
      mandatory: infoElement.mandatory === 'true',
      type: isSingleField ? 'SINGLE' : 'REPETITIVE',
      repeat,
      ...getInfoElementCustomParams(infoElement.code, opts),
    }, opts)
  })

  return sortBy(infoElements, infoElement =>
    PERMIT_INFOELEMENT_SORT_ORDER[infoElement.code]
  )
}

function modifyFields(sourceInfoElement, opts) {
  const infoElement = { ...sourceInfoElement }

  const isSingleField = infoElement.type === 'SINGLE'
  const fields = infoElement.fields.map((field) => {
    const code = isSingleField ? `${infoElement.code}.${field.code}` : field.code

    return {
      ...field,
      ...parseValidationLimits(field),
      infoElement,
      code,
      fieldCode: field.code,
      mandatory: infoElement.mandatory && field.mandatory === 'true',
      readonlyInApplication: field.readonlyInApplication === 'true',
      ...getFieldCustomParams(field, infoElement, {
        ...opts,
        infoElement,
      }),
    }
  })

  infoElement.fields = sortBy(fields, field =>
    PERMIT_FIELD_SORT_ORDER[field.code]
  )
  return infoElement
}

function parseValidationLimits(field) {
  const limits = {}
  const validationLimitKeys = ['minLength', 'maxLength', 'maxDecimalLength']
  validationLimitKeys.forEach((key) => {
    if (field[key]) {
      limits[key] = parseInt(field[key], 10)
    }
  })

  return limits
}

function addHelpTexts(infoElements, helpTexts, locale, applicationTypeCode, applicationData, authType) {
  const applicationTypeValue = getInfoElementValueFromApplicationData(
    applicationData,
    'applicationType',
    'applicationType'
  )

  const getHelpObject = (targetCode, secondaryCode, texts, auth) => toHelpObject(applyHelpTextToElement(
    targetCode,
    secondaryCode,
    texts,
    applicationTypeCode,
    applicationTypeValue,
    auth
  ), locale)

  const getNameObject = (targetCode, secondaryCode, texts, auth) => toNameObject(applyHelpTextToElement(
    targetCode,
    secondaryCode,
    texts,
    applicationTypeCode,
    applicationTypeValue,
    auth
  ), locale)

  return infoElements.map(infoElement => ({
    ...infoElement,
    ...getHelpObject(
      infoElement.code,
      null,
      helpTexts.infoElements,
      authType
    ),
    ...getNameObject(
      infoElement.code,
      null,
      helpTexts.infoElementNames,
      authType
    ),
    fields: infoElement.fields.map(
      ({
        help: originalHelp,
        ...field
      }) => ({
        ...field,
        originalHelp,
        ...getHelpObject(
          field.code,
          infoElement.code,
          helpTexts.fields,
          authType
        ),
        ...getNameObject(
          field.code,
          infoElement.code,
          helpTexts.fieldNames,
          authType
        ),
      })
    ),
  }))
}

export function getInfoElementValueFromApplicationData(applicationData, infoElementCode, fieldCode) {
  const infoElementInData = findInfoElementFromApplicationData(applicationData, infoElementCode)
  const fieldObj = find(infoElementInData.fieldValues, { code: fieldCode })
  return get(fieldObj, 'value')
}

export function applyHelpTextToElement(targetCode, secondaryCode, helpTexts, applicationTypeCode, applicationTypeValue, authType) {
  const defaultHelpText = helpTexts[targetCode]
  const personTextKey = '_PERSON'
  const specialHelpTextKeys = Object.keys(helpTexts).filter(key => key.indexOf(`${targetCode}_`) === 0)

  let specialHelpText
  const isPerson = authType === AUTH_TYPES.principal || authType === AUTH_TYPES.private

  for (const specialKey of specialHelpTextKeys) {
    let permitTypeCodesArr = []
    let applicationType = null
    let targetPersonText = null

    const includesPersonAttribute = specialKey.includes(personTextKey)

    specialKey.split('_').forEach((attribute, index) => {
      if (/^\[(.*?)\]$/i.test(attribute)) {
        const permitTypeCodes = attribute.replace(/\[|\]/g, '')
        permitTypeCodesArr = permitTypeCodes.split(',')
      } else if (index === 1) {
        if (includes([
          APPLICATION_TYPE_NEW_ALIAS,
          APPLICATION_TYPE_AMENDMENT_ALIAS,
          APPLICATION_TYPE_RENEW_ALIAS,
          APPLICATION_TYPE_REVOCATION_ALIAS], attribute)
        ) {
          applicationType = attribute
          permitTypeCodesArr.push(applicationTypeCode)
        } else {
          if (attribute === 'PERSON') {
            targetPersonText = true
          }

          permitTypeCodesArr.push(attribute)
        }
      } else if (index === 2) {
        if (attribute !== 'PERSON') {
          applicationType = attribute
        }
      }
    })


    const isCorrectApplicationType = APPLICATION_TYPE_MAP[parseInt(applicationTypeValue, 10)] === applicationType ||
      applicationType === null

    if (targetPersonText && isPerson) {
      specialHelpText = helpTexts[specialKey]
    }

    if (includes(permitTypeCodesArr, applicationTypeCode) && isCorrectApplicationType && !includesPersonAttribute) {
      specialHelpText = helpTexts[specialKey]
    }

    // For person user, try to use application type key containing _PERSON -key
    if (includes(permitTypeCodesArr, applicationTypeCode) && isCorrectApplicationType && includesPersonAttribute && isPerson) {
      specialHelpText = helpTexts[specialKey]
    }
  }

  if (specialHelpText) {
    return specialHelpText
  }

  return defaultHelpText
}

function toNameObject(name, locale) {
  if (!name) {
    return {}
  }
  return {
    name: { [locale]: name },
  }
}

function toHelpObject(helpText, locale) {
  if (!helpText) {
    return {}
  }
  return {
    help: { [locale]: helpText },
  }
}

export function parseApplicationData(applicationData, structure, generateId = uniqueId) {
  const infoElementMeta = {}
  structure.infoElements.forEach((infoElement) => {
    infoElementMeta[infoElement.code] = {
      repeat: infoElement.repeat,
      isSingleCodeset: infoElement.repeat > 1 && infoElement.type === 'SINGLE',
      groupCode: infoElement.group,
    }
  })

  let initialValues = {
    applicationTypeCode: applicationData.applicationTypeCode,
  }
  getInfoElementsFromApplicationData(applicationData, generateId)
    .filter(infoElement =>
      infoElement.fieldValues && infoElement.fieldValues.some(fieldValue => Boolean(fieldValue.value))
    )
    .forEach((infoElement) => {
      const infoElementInStructure = infoElementMeta[infoElement.code]

      if (!infoElementInStructure) {
        return
      }

      if (infoElementInStructure.repeat > 1) {
        if (infoElementInStructure.isSingleCodeset) {
          if (!initialValues[infoElement.code]) {
            initialValues[infoElement.code] = {}
          }
          infoElement.fieldValues.forEach((fieldValue) => {
            if (!initialValues[infoElement.code][fieldValue.code]) {
              initialValues[infoElement.code][fieldValue.code] = []
            }

            initialValues[infoElement.code][fieldValue.code].push(fieldValue.value)
          })
        } else {
          initialValues[infoElement.code] = initialValues[infoElement.code] || []
          const id = isCombinedInfoElement(infoElementInStructure.groupCode)
            ? infoElement.groupId
            : generateId()

          initialValues[infoElement.code].push({
            id,
            ...getInfoElementValues(infoElement),
          })
        }
      } else {
        initialValues[infoElement.code] = getInfoElementValues(infoElement)
      }
    })


  // Fallback for AL-798
  const representative = hasRepresentativeIdentification(applicationData)
  if (representative) {
    const representativeDetails = get(initialValues, 'representativeDetails')

    if (representativeDetails) {
      initialValues.representativeDetails.representativeIdentification = representative
    }
  }

  initialValues = customizeInitialValues({
    ...initialValues,
    ...setCombinedInfoElementsIds(initialValues, structure),
  })


  return {
    structure,
    initialValues,
  }
}

function isCombinedInfoElement(groupCode) {
  return has(infoElementGroups, groupCode)
}

function getInfoElementValues(infoElement) {
  const targetObj = {}
  infoElement.fieldValues.forEach((fieldValue) => {
    let value = fieldValue.value || ''
    if (PERMIT_BOOLEAN_CHECKBOX_FIELDS.includes(fieldValue.code)) {
      value = value === 'true'
    }
    targetObj[fieldValue.code] = value

    if (has(fieldValue, 'fieldValueSource')) {
      targetObj[`${fieldValue.code}FieldValueSource`] = fieldValue.fieldValueSource
    }
  })

  return targetObj
}

// Combined infoElements needs to have equal count of values, so generate empty ones
function setCombinedInfoElementsIds(values, structure) {
  const goodsInfoElements = filter(structure.infoElements, { group: 'goods' })
  const ids = uniq(
    goodsInfoElements
      .flatMap(infoElement => get(values, infoElement.code, []))
      .map(infoElementValues => infoElementValues.id)
  )
  const newValues = {}

  goodsInfoElements.forEach((goodsInfoElement) => {
    set(newValues, goodsInfoElement.code, [])
    const infoElementFieldCodes = goodsInfoElement.fields.map(field => field.code)
    const defaultValues = {}
    infoElementFieldCodes.forEach((code) => {
      defaultValues[code] = ''
    })
    ids.forEach((id) => {
      const existingValues = find(values[goodsInfoElement.code], { id })
      const newValue = {
        ...defaultValues,
        ...existingValues,
        id,
      }
      newValues[goodsInfoElement.code].push(newValue)
    })
  })

  return newValues
}

function customizeInitialValues(values) {
  if (has(values, 'goodsIdentification') && has(values, 'equivalentGood')) {
    const { ...otherValues } = values

    const [goodsIdentification, equivalentGood] = unzip(
      zip(values.goodsIdentification, values.equivalentGood)
        .map(([goodsIdentificationValue, equivalentGoodValue]) => {
          const codeFieldName = `${PERMIT_EQUIVALENT_GOOD_FIELD_PREFIX}${PERMIT_EQUIVALENT_GOOD_CODE_FIELD_NAME}`
          const codeFieldValue = get(equivalentGoodValue, codeFieldName)
          const useEquivalentGood = isString(codeFieldValue) && codeFieldValue !== ''
            ? 'true'
            : 'false'

          return [
            { ...goodsIdentificationValue, [USE_EQUIVALENT_GOOD_FIELD_NAME]: useEquivalentGood },
            { ...equivalentGoodValue, [USE_EQUIVALENT_GOOD_FIELD_NAME]: useEquivalentGood },
          ]
        })
    )


    return {
      ...otherValues,
      goodsIdentification,
      equivalentGood,
    }
  }
  return values
}
