/* eslint-disable max-len */
import { defineMessages } from 'react-intl'

export default defineMessages({
  heading: {
    id: 'mrn.heading',
    description: 'Heading of the page',
    defaultMessage: 'MRN search service',
  },
  headingPanel: {
    id: 'mrn.panelheading',
    description: 'Heading of the panel',
    defaultMessage: 'Search declaration by MRN search service',
  },
  mrnFieldLabel: {
    id: 'mrn.mrnFieldLabel',
    description: 'Label of field',
    defaultMessage: 'MRN',
  },
  goodsItemFieldLabel: {
    id: 'mrn.goodsItemFieldLabel',
    description: 'Label of field',
    defaultMessage: 'Goods item',
  },
  transportDocumentIDFieldLabel: {
    id: 'mrn.transportDocumentIDFieldLabel',
    description: 'Label of field',
    defaultMessage: 'Transport document ID',
  },
  mrnValidationRegexError: {
    id: 'mrn.mrnValidationRegexError',
    description: 'Validation error message',
    defaultMessage: 'MRN number has to be 18 characters long and its format has to be eg. 18FI12345678912314',
  },
  mrnValidationError: {
    id: 'mrn.mrnValidationError',
    description: 'Validation error message',
    defaultMessage: 'MRN number is not valid',
  },
  mrnValidationUTUPresentationNotificationError: {
    id: 'mrn.mrnValidationUTUPresentationNotificationError',
    description: 'Validation error message',
    defaultMessage: 'MRN\'s for presentation notifications are not accepted',
  },
  mrnValidationErrorNoticeOfArrival: {
    id: 'mrn.mrnValidationErrorNoticeOfArrival',
    description: 'Validation error message',
    defaultMessage: 'MRN\'s for notice of arrivals are not accepted',
  },
  goodsItemValidationError: {
    id: 'mrn.goodsItemValidationError',
    description: 'Validation error message',
    defaultMessage: 'Good batch number is not valid',
  },
  helpText: {
    id: 'mrn.mainHelpText',
    description: 'Heading of the section',
    defaultMessage: 'Information Service for business customers',
  },
  search: {
    id: 'mrn.search',
    description: 'Text of button',
    defaultMessage: 'Search',
  },
  modalBack: {
    id: 'mrn.modalBack',
    description: 'Modal back button text',
    defaultMessage: 'Back',
  },
  clear: {
    id: 'mrn.clear',
    description: 'Text of button',
    defaultMessage: 'Clear',
  },
  columnLabelMRN: {
    id: 'mrn.columnLabelMRN',
    description: 'Table column label',
    defaultMessage: 'MRN',
  },
  columnLabelGoodsItem: {
    id: 'mrn.columnLabelGoodsItem',
    description: 'Table column label',
    defaultMessage: 'Goods item',
  },
  columnLabelStatus: {
    id: 'mrn.columnLabelStatus',
    description: 'Table column label',
    defaultMessage: 'Status',
  },
  columnLabelGoodsItemStatus: {
    id: 'mrn.columnLabelGoodsItemStatus',
    description: 'Table column label for goods item status',
    defaultMessage: 'Status',
  },
  columnLabelDeclarationType: {
    id: 'mrn.columnLabelDeclarationType',
    description: 'Table column label',
    defaultMessage: 'Declarationtype',
  },
  columnLabelGoodsItemAmount: {
    id: 'mrn.columnLabelGoodsItemAmount',
    description: 'Table column label',
    defaultMessage: 'Amount of Goods items',
  },
  columnLabelTransportDocument: {
    id: 'mrn.columnLabelTransportDocument',
    description: 'Table column label',
    defaultMessage: 'Transport document ID',
  },
  columnLabelActions: {
    id: 'mrn.columnLabelActions',
    description: 'Table column label',
    defaultMessage: 'Actions',
  },
  showGoodsItemsButtonText: {
    id: 'mrn.showGoodsItemsButtonText',
    description: 'Show goods items action button text',
    defaultMessage: 'Show goods items',
  },
  paginationRowText: {
    id: 'mrn.paginationRowText',
    description: 'Pagination row text',
    defaultMessage: 'goods items',
  },
  tooManyResults: {
    id: 'mrn.tooManyResults',
    description: 'Too many results error text',
    defaultMessage: 'Too many results.',
  },
  resultsCount: {
    id: 'mrn.resultsCount',
    description: 'Notification text',
    defaultMessage: 'Search results {count}',
  },
  modalTitle: {
    id: 'mrn.modalTitle',
    description: 'Modal title text',
    defaultMessage: 'Transport document {transportDocumentID}',
  },
  noResults: {
    id: 'mrn.noResults',
    description: 'Notification text in table',
    defaultMessage: 'There is no information for MRN number {mrn}. Please check that the number is correct and try searching again.',
  },
  searchingMrnErrors: {
    id: 'mrn.searchingMrnErrors',
    description: 'Error notification',
    defaultMessage: 'There was an error in search. Please try again later.',
  },
  notificationTemporarilyUnavailable: {
    id: 'mrn.notificationTemporarilyUnavailable',
    description: 'Label in notification',
    defaultMessage: 'MRN-hakupalvelussa on tällä hetkellä ruuhkaa. Ole hyvä ja kokeile hetken päästä uudestaan.',
  },
  state_EI_LUOVUTETTU_TULLIVARASTOINTIMENETTELYYN: {
    id: 'mrn.state_EI_LUOVUTETTU_TULLIVARASTOINTIMENETTELYYN',
    description: '',
    defaultMessage: 'Not released for the customs warehousing procedure',
  },
  state_EI_LUOVUTETTU_VIENTIMENETTELYYN: {
    id: 'mrn.state_EI_LUOVUTETTU_VIENTIMENETTELYYN',
    description: '',
    defaultMessage: 'Not released for the export procedure',
  },
  state_EI_TULLISELVITETTAVISSA: {
    id: 'mrn.state_EI_TULLISELVITETTAVISSA',
    description: '',
    defaultMessage: 'Not set for customs clearance',
  },
  state_EI_VOIDA_ESITTAA_POISTUVAKSI: {
    id: 'mrn.state_EI_VOIDA_ESITTAA_POISTUVAKSI',
    description: '',
    defaultMessage: 'Not set for presentation as exiting goods',
  },
  state_ESITETTAVISSA: {
    id: 'mrn.state_ESITETTAVISSA',
    description: '',
    defaultMessage: 'All set for presentation',
  },
  state_ESITETTY_POISTUVAKSI: {
    id: 'mrn.state_ESITETTY_POISTUVAKSI',
    description: '',
    defaultMessage: 'Presented as exiting goods',
  },
  state_LUOVUTETTU_VIENTIMENETTELYYN: {
    id: 'mrn.state_LUOVUTETTU_VIENTIMENETTELYYN',
    description: '',
    defaultMessage: 'Released for the export procedure',
  },
  state_MITATOITY: {
    id: 'mrn.state_MITATOITY',
    description: '',
    defaultMessage: 'Invalidated',
  },
  state_MRN_TUNTEMATON: {
    id: 'mrn.state_MRN_TUNTEMATON',
    description: '',
    defaultMessage: 'Unknown MRN',
  },
  state_POISTUNUT: {
    id: 'mrn.state_POISTUNUT',
    description: '',
    defaultMessage: 'Exited',
  },
  state_SAAPUNUT_POISTUMISPAIKALLE: {
    id: 'mrn.state_SAAPUNUT_POISTUMISPAIKALLE',
    description: '',
    defaultMessage: 'Arrived at the place of exit',
  },
  state_SELVITETTY_TOISAALLA: {
    id: 'mrn.state_SELVITETTY_TOISAALLA',
    description: '',
    defaultMessage: 'Customs cleared elsewhere',
  },
  state_ODOTTAA_MITATOINTIA: {
    id: 'mrn.state_ODOTTAA_MITATOINTIA',
    description: '',
    defaultMessage: 'Being processed',
  },
  state_TULLISELVITETTAVISSA: {
    id: 'mrn.state_TULLISELVITETTAVISSA',
    description: '',
    defaultMessage: 'All set for customs clearance',
  },
  state_TULLIVARASTOINTIMENETTELYSSA: {
    id: 'mrn.state_TULLIVARASTOINTIMENETTELYSSA',
    description: '',
    defaultMessage: 'Under the customs warehousing procedure',
  },
  state_TULLIVARASTOINTIMENETTELY_PAATETTY: {
    id: 'mrn.state_TULLIVARASTOINTIMENETTELY_PAATETTY',
    description: '',
    defaultMessage: 'Customs warehousing procedure discharged',
  },
  state_TUNTEMATON: {
    id: 'mrn.state_TUNTEMATON',
    description: '',
    defaultMessage: 'Unknown',
  },
  state_VOIDAAN_ESITTAA_POISTUVAKSI: {
    id: 'mrn.state_VOIDAAN_ESITTAA_POISTUVAKSI',
    description: '',
    defaultMessage: 'All set for presentation as exiting goods',
  },
  state_EI_LUOVUTETTU_TULLIMENETTELYYN: {
    id: 'mrn.state_EI_LUOVUTETTU_TULLIMENETTELYYN',
    description: '',
    defaultMessage: 'Ei luovutettu tullimenettelyyn',
  },
  state_TULLIMENETTELY_PAATETTY_TULLISELVITETTY: {
    id: 'mrn.state_TULLIMENETTELY_PAATETTY_TULLISELVITETTY',
    description: '',
    defaultMessage: 'Tulli menettely päätetty / tulliselvitetty',
  },
  state_ESITETTAVISSA_JA_ENNAKOLTA_TULLISELVITETTAVISSA: {
    id: 'mrn.state_ESITETTAVISSA_JA_ENNAKOLTA_TULLISELVITETTAVISSA',
    description: '',
    defaultMessage: 'Esitettävissä ja ennakolta tulliselvitettävissä',
  },
  state_KULJETUSASIAKIRJA_TUNTEMATON: {
    id: 'mrn.state_KULJETUSASIAKIRJA_TUNTEMATON',
    description: '',
    defaultMessage: 'Kuljetusasiakirja tuntematon',
  },
  state_TAVARAERAN_NUMERO_TUNTEMATON: {
    id: 'mrn.state_TAVARAERAN_NUMERO_TUNTEMATON',
    description: '',
    defaultMessage: 'Tavaraerän numero tuntematon',
  },
  state_TULLIMENETTELYSSA: {
    id: 'mrn.state_TULLIMENETTELYSSA',
    description: '',
    defaultMessage: 'Tullimenettelyssä',
  },
  state_CLOSED: {
    id: 'mrn.state_CLOSED',
    description: '',
    defaultMessage: 'T2L on esitetty ja tavarat voi luovuttaa',
  },
  state_PROOF_NOT_VALID: {
    id: 'mrn.state_PROOF_NOT_VALID',
    description: '',
    defaultMessage: 'T2L/T2LF-todistetta ei voi käyttää',
  },
  state_PRESENTED: {
    id: 'mrn.state_PRESENTED',
    description: '',
    defaultMessage: 'T2LF on esitetty ja tavarat voi tulliselvittää',
  },
  state_MANUAL_PROCESSING: {
    id: 'mrn.state_MANUAL_PROCESSING',
    description: '',
    defaultMessage: 'T2L/T2LF on käsittelyssä. Tavaroita ei voi luovuttaa vielä',
  },
  type_FI313A: {
    id: 'mrn.type_FI313A',
    description: '',
    defaultMessage: 'IE313 Entry summary declaration amendment',
  },
  type_FI315A: {
    id: 'mrn.type_FI315A',
    description: '',
    defaultMessage: 'IE315 Entry summary declaration',
  },
  type_FI344A: {
    id: 'mrn.type_FI344A',
    description: '',
    defaultMessage: 'IE344 Summary declaration',
  },
  type_FI613A: {
    id: 'mrn.type_FI613A',
    description: '',
    defaultMessage: 'IE613 Exit summary declaration amendment',
  },
  type_FI615A: {
    id: 'mrn.type_FI615A',
    description: '',
    defaultMessage: 'IE615 Exit summary declaration',
  },
})
