/**
 * @file Intrastat app route definition with Webpack code splitting
 * and asynchronous reducer injection to Redux store
 *
 * @author Sami Tiilikainen
 */

import React from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'

import IntrastatHeadersPage from './routes/headers'
import IntrastatRowsPage from './routes/rows'
import IntrastatConfirmationPage from './routes/confirmation'
import IntrastatSummaryPage from './routes/summary'
import { CONFIRMATION_ROUTE, HEADERS_ROUTE, ROWS_ROUTE, SUMMARY_ROUTE } from '../../constants'
import withReducer from '../../../reducers/withReducer'
import intrastatDeclarationReducer from './reducer'
import EditDeclarationContainer from './containers/EditDeclarationContainer'
import ReadOnlyDeclarationContainer from './containers/ReadOnlyDeclarationContainer'
import PageNotFound from 'src/components/PageNotFound'

const IntrastatDeclarationRoutes = () => {
  let { path } = useRouteMatch()
  const declarationBasePath = `${path}/:declarationId`
  return (
    <Switch>
      <Route path={`${declarationBasePath}${HEADERS_ROUTE}`}>
        <EditDeclarationContainer>
          <IntrastatHeadersPage />
        </EditDeclarationContainer>
      </Route>
      <Route path={`${declarationBasePath}${ROWS_ROUTE}`}>
        <EditDeclarationContainer>
          <IntrastatRowsPage />
        </EditDeclarationContainer>
      </Route>
      <Route path={`${declarationBasePath}${CONFIRMATION_ROUTE}`}>
        <EditDeclarationContainer>
          <IntrastatConfirmationPage />
        </EditDeclarationContainer>
      </Route>
      <Route path={`${declarationBasePath}${SUMMARY_ROUTE}/:viewMode?`}>
        <ReadOnlyDeclarationContainer>
          <IntrastatSummaryPage />
        </ReadOnlyDeclarationContainer>
      </Route>
      <Route component={PageNotFound} />
    </Switch>
  )
}

export default withReducer([
  { key: 'intrastat.declaration', reducer: intrastatDeclarationReducer },
])(IntrastatDeclarationRoutes)
