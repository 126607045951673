import { trim, isEmpty } from 'lodash'
import big from 'big.js'
import {
  InvalidFileSyntaxError,
  ImportValidationError,
  parseOrganizationIdAndReportingUnit,
  parseInteger,
} from '../utils'
import messages from './messages'

function validateHeaderLineData(fieldArray, index) {
  if (!fieldArray) {
    throw new InvalidFileSyntaxError({
      _error: {
        ...messages.invalidFileSyntaxOnLine,
        values: { lineNumber: index + 1 },
      },
    })
  }
  const functionCode = trim(fieldArray[5])
  if (functionCode !== 'T' && functionCode !== 'NIL') {
    throw new ImportValidationError({
      _error: {
        ...messages.invalidInputOnColumn,
        values: {
          lineNumber: index + 1,
          columnNumber: 5,
          inputValue: functionCode,
        },
      },
    })
  }
}

// eslint-disable-next-line max-len
const headerLineRegExp = /^(OTS)([A-Z0-9\s]{13})([AD]{1})([0-9]{4})([A-Z\s]{3})(.{13})([A-Z0-9\s]{17})([A-Z0-9\s]{17})([A-Z0-9\s]{10})(.{17})([A-Z\s]{3})/i

function parseCurrencyFromHeaderLine(content) {
  const fieldArray = content.match(headerLineRegExp)
  return trim(fieldArray[11]) || undefined
}

function parseHeaderLine(content, index, userOrganizationId, userOrganizationName, confirmationCallback) {
  const fieldArray = content.match(headerLineRegExp)

  validateHeaderLineData(fieldArray, index)

  const headers = {
    currency: trim(fieldArray[11]) || undefined,
  }

  const rawFlowCode = trim(fieldArray[3])
  if (rawFlowCode === 'A') {
    headers.flowCode = 'ARRIVAL_OF_GOODS'
  } else if (rawFlowCode === 'D') {
    headers.flowCode = 'DISPATCH_OF_GOODS'
  }

  const rawReferencePeriod = trim(fieldArray[4])
  if (/^[0-9]{4}$/.test(rawReferencePeriod)) {
    headers.referencePeriod = `20${rawReferencePeriod}`
  }

  const rawFunctionCode = trim(fieldArray[5])
  if (rawFunctionCode === 'T') {
    headers.noDeclarationRows = false
  } else if (rawReferencePeriod === 'NIL') {
    headers.noDeclarationRows = true
  }

  const psiData = parseOrganizationIdAndReportingUnit(trim(fieldArray[7]))
  if (psiData.organizationId) {
    headers.psi = psiData.organizationId
  }
  if (psiData.reportingUnit) {
    headers.psiReportingUnit = psiData.reportingUnit
  }

  const tdpIsRelevant = userOrganizationId !== psiData.organizationId
  const tdpData = parseOrganizationIdAndReportingUnit(trim(fieldArray[8]))
  if (tdpIsRelevant) {
    if (tdpData.organizationId === userOrganizationId) {
      headers.tdp = tdpData.organizationId
      if (tdpData.reportingUnit) {
        headers.tdpReportingUnit = tdpData.reportingUnit
      }
    } else {
      const userAllowsTdpReplace = confirmationCallback({
        ...messages.invalidTdpConfirmation,
        values: {
          invalidOrganizationId: tdpData.organizationId,
          userOrganizationId,
          userOrganizationName,
        },
      })
      if (!userAllowsTdpReplace) {
        throw new ImportValidationError({
          _error: {
            ...messages.invalidTdpRejectedByUser,
            values: {
              invalidTdp: tdpData.organizationId,
            },
          },
        })
      }
      headers.tdp = userOrganizationId
    }
  }

  return headers
}

function validateNomenclatureLineData(fieldArray, index) {
  if (!fieldArray) {
    throw new InvalidFileSyntaxError({
      _error: {
        ...messages.invalidFileSyntaxOnLine,
        values: { lineNumber: index + 1 },
      },
    })
  }
  const netMassType = trim(fieldArray[11])
  if (netMassType && netMassType !== 'WT') {
    throw new ImportValidationError({
      _error: {
        ...messages.invalidInputOnColumn,
        values: {
          lineNumber: index + 1,
          columnNumber: 11,
          inputValue: netMassType,
        },
      },
    })
  }
  const netMassUnit = trim(fieldArray[12])
  if (netMassUnit && netMassUnit !== 'KGM') {
    throw new ImportValidationError({
      _error: {
        ...messages.invalidInputOnColumn,
        values: {
          lineNumber: index + 1,
          columnNumber: 12,
          inputValue: netMassUnit,
        },
      },
    })
  }
  const quantityInSUType = trim(fieldArray[14])
  if (quantityInSUType && quantityInSUType !== 'AAE') {
    throw new ImportValidationError({
      _error: {
        ...messages.invalidInputOnColumn,
        values: {
          lineNumber: index + 1,
          columnNumber: 14,
          inputValue: quantityInSUType,
        },
      },
    })
  }
}

function parseNomenclatureLine(content, index, currency = 'EUR') {
  // eslint-disable-next-line max-len
  const fieldArray = content.match(/^(NIM)([\d]{5})([\d\s]{8})([\d\s]{2})([A-Z\s]{2})([A-Z\s]{2})([A-Z\s]{2})([\d\s]{1})([\d\s]{10})([\w\s-]{15})([A-Z\s]{3})([A-Z\s]{3})([\d\s]{10})([A-Z\s]{3})([A-Z\s]{3})([\d\s]{10})([\d\s]{10})?([A-Z0-9]{1,14})?/i)
  validateNomenclatureLineData(fieldArray, index)
  const rowData = {
    index,
    CN8Code: trim(fieldArray[3]) || undefined,
    natureOfTransactionCode: parseInteger(trim(fieldArray[4])) || undefined,
    countryOfOrigin: trim(fieldArray[5]) || undefined,
    memberState: trim(fieldArray[6]) || trim(fieldArray[7]) || undefined,
    modeOfTransport: parseInteger(trim(fieldArray[8])) || undefined,
    statisticalValue: parseInteger(trim(fieldArray[9])) || undefined,
    invoiceNumber: trim(fieldArray[10]) || undefined,
    netMass: trim(fieldArray[13]),
    quantityInSU: parseInteger(trim(fieldArray[16])) || undefined,
    invoicedAmount: parseInteger(trim(fieldArray[17])) || undefined,
    partnerId: trim(fieldArray[18]) || undefined,
  }
  // In netMass only non-zero empty values are considered undefined
  // NOTE: On the contrary in quantityInSU also zeros are considered undefined!
  if (isEmpty(rowData.netMass)) {
    rowData.netMass = undefined
  } else {
    rowData.netMass = parseInteger(rowData.netMass)
  }
  // In statistical value convert zeros to undefined
  // NOTE: On the contrary in invoiced amount zeros are allowed!
  if (rowData.statisticalValue === 0) {
    rowData.statisticalValue = undefined
  }
  if (rowData.statisticalValue) {
    rowData.statisticalValueCurrencyCode = currency
  }
  if (rowData.invoicedAmount) {
    rowData.invoicedValueCurrencyCode = currency
  }
  return rowData
}

function parseSumLine(content, index) {
  // eslint-disable-next-line max-len
  const fieldArray = content.match(/^(SUM)([0-9]{18})([0-9]{18})/i)
  if (!fieldArray) {
    throw new InvalidFileSyntaxError({
      _error: {
        ...messages.invalidFileSyntaxOnLine,
        values: { lineNumber: index + 1 },
      },
    })
  }

  return {
    rowCount: parseInt(trim(fieldArray[2]), 10) || 0,
    totalInvoicedAmount: parseInteger(trim(fieldArray[3])) || undefined,
  }
}

export default function parse(data, onlyRows, userOrganizationId, userOrganizationName, confirmationCallback) {
  const arrayOfLines = data.match(/[^\r\n]+/g)
  let currencyUsedInRows
  let headers
  const rows = []
  let headerLine
  const nomenclatureLines = []
  let sumLine
  arrayOfLines.forEach((content, index) => {
    if (/^OTS/.test(content)) {
      headerLine = { content, index }
    } else if (/^SUM/.test(content)) {
      sumLine = { content, index }
    } else if (/^NIM/.test(content)) {
      nomenclatureLines.push({ content, index })
    }
  })

  if (onlyRows) {
    currencyUsedInRows = parseCurrencyFromHeaderLine(headerLine.content)
  } else {
    if (headerLine) {
      const {
        currency,
        ...parsedHeaders
      } = parseHeaderLine(
        headerLine.content,
        headerLine.index,
        userOrganizationId,
        userOrganizationName,
        confirmationCallback
      )
      currencyUsedInRows = currency
      headers = parsedHeaders
    }

    if (!headers) {
      throw new ImportValidationError({
        _error: messages.missingHeaders,
      })
    }
  }

  if (nomenclatureLines.length) {
    nomenclatureLines.forEach((line) => {
      rows.push(parseNomenclatureLine(line.content, line.index, currencyUsedInRows))
    })
  }

  if (sumLine) {
    const customerSums = parseSumLine(sumLine.content, sumLine.index)
    const calculatedTotalInvoicedAmount =
      parseInt(rows.reduce((sum, row) => sum.plus(row.invoicedAmount || 0), big(0)).toFixed(), 10)

    if (customerSums.rowCount !== rows.length ||
      customerSums.totalInvoicedAmount !== calculatedTotalInvoicedAmount) {
      const values = {
        sumRowCount: (customerSums.rowCount || 0).toString(),
        actualRowCount: (rows.length || 0).toString(),
        sumInvoicedAmount: (customerSums.totalInvoicedAmount || 0).toString(),
        actualInvoicedAmount: (calculatedTotalInvoicedAmount || 0).toString(),
      }
      const userAllowsInvalidSums = confirmationCallback({
        ...messages.incorrectAsciiSumsConfirmation,
        values,
      })
      if (!userAllowsInvalidSums) {
        throw new ImportValidationError({
          _error: messages.sumsNotMatching,
        })
      }
    }
  }

  return {
    headers,
    rows,
  }
}
