import React from 'react'
import { Form, Button } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { isEmpty, find, isArray } from 'lodash'
import { withRouter, Link } from 'react-router-dom'
import ErrorBoundary from 'src/components/ErrorBoundary'
import Notification from 'src/components/Notification'
import Loader from 'src/components/Loader'
import Icon from 'src/components/Icon'
import Modal from 'src/components/Modal'
import InfoAlert from 'src/components/InfoAlert'
import apiMessages from 'src/routes/intrastat/declaration/apiMessages'
import AccessibleMessage from 'src/components/AccessibleMessage'
import EditDeclarationButtonToolbar from '../../components/EditDeclarationButtonToolbar'
import DeclarationSummary from '../../components/DeclarationSummary'
import confirmationMessages from './messages'
import commonMessages from '../../messages'
import {
  getStepPath,
  getDeclarationIdFromRoute,
  getReferencePeriodSelectOptions,
  calculateRowTotals,
} from '../../utils'
import { EditStepBase } from '../../components/EditStepBase'
import {
  HEADERS_ROUTE_PATH,
  ROWS_ROUTE_PATH,
  SUMMARY_ROUTE_PATH,
  SUMMARY_PRINT_ROUTE_PATH,
} from '../../constants'
import InlineNotification from '../../components/InlineNotification'
import { INTRASTAT_ROUTE_PATH } from '../../../constants'
import AmendmentModal from '../../components/AmendmentModal'
import CopyModal from '../../components/CopyModal'
import { focusElementById } from '../../../../../utils/index'
import styles from './ConfirmationView.scss'

const messages = {
  ...commonMessages,
  ...confirmationMessages,
  ...apiMessages,
}

/**
 * Last Intrastat form step with summary and submit to server
 */
class ConfirmationView extends EditStepBase {
  constructor(props) {
    super(props)
    this.resolveSubmitLocation = this.resolveSubmitLocation.bind(this)
  }

  resolveSubmitLocation(location = null, declarationId) {
    const { rows, noDeclarationRows } = this.props
    // Jump over rows if noDeclarationRows is selected
    if (noDeclarationRows === true && location === getStepPath(ROWS_ROUTE_PATH, declarationId) && isEmpty(rows)) {
      return getStepPath(HEADERS_ROUTE_PATH, declarationId)
    }
    return location
  }

  componentWillUnmount() {
    this.props.clearFormErrors()
  }

  render() {
    const {
      locale,
      submitting,
      error,
      handleSubmit,
      declaration,
      rows,
      cachedCustomers,
      submitAcceptedStatus,
      forceSubmitAcceptedStatus,
      accepted,
      invalidated,
      duplicateWarning,
      dismissDuplicateWarning,
      onShowDeleteModal,
      onHideDeleteModal,
      history,
      destroy,
      showAcceptedModal,
      showDeleteModal,
      fetchingDeclaration,
      fetchingDeclarationRows,
      submittingHeaders,
      deleteDraft,
      deletingDeclaration,
      showAmendmentModal,
      creatingNewVersion,
      onShowAmendmentModal,
      onHideAmendmentModal,
      createAndOpenNewVersion,
      showCopyModal,
      creatingNewCopy,
      createAndOpenNewCopy,
      onShowCopyModal,
      onHideCopyModal,
      intl,
      nilDeclaration,
      removeTdp,
      selectedDelegateCompanyObject,
    } = this.props

    if (!declaration) {
      return null
    }

    if (fetchingDeclaration || fetchingDeclarationRows || submittingHeaders) {
      return <Loader blocking />
    }

    let showTdpRemoveNotification = false
    const findOrganization = id => find(cachedCustomers, { id })
    const selectedTdpObject = declaration.tdp && findOrganization(declaration.tdp)
    if (!selectedDelegateCompanyObject && declaration.tdp) {
      showTdpRemoveNotification = true
    }

    const rowTotals = calculateRowTotals(rows)

    let prevStepBasedOnRows = this.props.prevStep
    if (declaration.noDeclarationRows && isEmpty(rows)) {
      prevStepBasedOnRows = HEADERS_ROUTE_PATH
    }
    const pathToBack = getStepPath(prevStepBasedOnRows, getDeclarationIdFromRoute(this.props))

    const missingRows = isEmpty(rows) && !nilDeclaration

    const submitMessage = submitting ? messages.btnSubmitting : messages.submitForm

    const hasErrorInQuantitySU = isArray(rows) && Boolean(rows.filter(row =>
      (row.warnings && row.warnings.quantityInSU)).length > 0)

    const submitDisabled = submitting ||
      fetchingDeclaration ||
      fetchingDeclarationRows ||
      deletingDeclaration ||
      missingRows ||
      showTdpRemoveNotification

    return (
      <ErrorBoundary>
        <Form onSubmit={handleSubmit(this.stepSubmitHandler)}>
          {submitting && <Loader blocking />}

          {showTdpRemoveNotification &&
            <div>
              <InlineNotification
                buttonText={intl.formatMessage(commonMessages.removeTdpNotificationButton)}
                buttonIcon={'delete'}
                onClick={() => removeTdp()}
                warning
              >
                <FormattedMessage
                  {...commonMessages.removeTdpNotificationMessage}
                  values={{
                    tdpName: selectedTdpObject && `${selectedTdpObject.name || ''} (${selectedTdpObject.id})`,
                  }}
                />
              </InlineNotification>
            </div>
          }

          <DeclarationSummary
            {...declaration}
            totalNetMass={rowTotals.totalNetMass}
            totalStatisticalValue={rowTotals.totalStatisticalValue}
            totalInvoicedAmount={rowTotals.totalInvoicedAmount}
            cachedCustomers={cachedCustomers}
            rowCount={rows && rows.length}
          />

          <EditDeclarationButtonToolbar
            className={'confirmationView'}
            pathToBack={pathToBack}
            showBack
            showNext={false}
            exitBsStyle={accepted ? 'primary' : undefined}
            exitAlignment={accepted ? 'right' : 'left'}
            disabled={submitting}
            exitAction={this.exitAction}
          >
            <Link to={getStepPath(SUMMARY_PRINT_ROUTE_PATH, declaration.declarationId)} className="printDeclaration">
              <Button id-qa-test="btn-printDeclaration">
                <Icon name="printer" /><FormattedMessage {...messages.printView} />
              </Button>
            </Link>

            {!accepted && !invalidated &&
              <Button
                type="button"
                className="pull-right"
                id="deleteDraft"
                disabled={submitting || fetchingDeclaration || fetchingDeclarationRows || deletingDeclaration}
                onClick={onShowDeleteModal}
                id-qa-test="btn-deleteDraft"
              >
                <Icon name="delete" /><FormattedMessage {...messages.deleteDraft} />
              </Button>
            }

            <Button
              onClick={onShowCopyModal}
              className="pull-right"
              id="copyDraft"
              id-qa-test="btn-copyDeclaration"
            >
              <Icon name="add" /><FormattedMessage {...messages.copyDeclarationButton} />
            </Button>

            {!accepted && !invalidated &&
              <Button
                type="button"
                className="submitDeclaration"
                bsStyle="primary"
                disabled={submitDisabled}
                onClick={!hasErrorInQuantitySU ? handleSubmit(submitAcceptedStatus) : undefined}
                id="submitDeclaration"
                id-qa-test="btn-submitDeclaration"
              >
                <FormattedMessage {...submitMessage} />
              </Button>
            }

            {accepted && !declaration.nextDeclarationId &&
              <Button
                onClick={onShowAmendmentModal}
                className="pull-right"
                id="amendDraft"
                id-qa-test="btn-declaration-amendment"
              >
                <Icon name="edit" /><FormattedMessage {...messages.correctButton} />
              </Button>
            }

          </EditDeclarationButtonToolbar>

          {showTdpRemoveNotification &&
            <div className={`text-danger text-right ${styles.errorMessage}`}>
              <AccessibleMessage message={intl.formatMessage(messages.validationRemoveTdp)} />
            </div>
          }

          {missingRows &&
            <div className={`text-danger text-right ${styles.errorMessage}`}>
              <AccessibleMessage message={intl.formatMessage(messages.zeroRows)} />
            </div>
          }

          {error && error.id &&
            <div className={`text-danger text-right ${styles.errorMessage}`}>
              <AccessibleMessage message={intl.formatMessage(error)} />
            </div>
          }

          {hasErrorInQuantitySU &&
            <div className={`text-danger text-right ${styles.errorMessage}`}>
              <AccessibleMessage message={intl.formatMessage(messages.errorsOnRows)} />
            </div>
          }

          {!nilDeclaration && duplicateWarning && <Modal
            show={duplicateWarning}
            showCancel
            cancelMessage={messages.duplicateWarningModalCancel}
            showContinue
            titleMessage={messages.duplicateWarningModalTitle}
            continueMessage={messages.duplicateWarningModalContinue}
            continueDisabled={submitting}
            onClickContinue={handleSubmit(forceSubmitAcceptedStatus)}
            onClickCancel={dismissDuplicateWarning}
            loading={submitting}
            focusDisableButton
          >
            <p className="lead"><FormattedMessage {...messages.duplicateWarning} /></p>
          </Modal>
          }

          {showDeleteModal &&
            <Modal
              show={showDeleteModal}
              showCancel
              cancelMessage={messages.deleteModalCancel}
              showContinue
              titleMessage={messages.deleteModalTitle}
              continueMessage={messages.deleteModalDelete}
              cancelDisabled={deletingDeclaration}
              continueDisabled={submitting || deletingDeclaration}
              focusDisableButton
              onClickContinue={
                () => deleteDraft(
                  declaration,
                  () => history.push(`/${INTRASTAT_ROUTE_PATH}`),
                )
              }
              onClickCancel={() => {
                onHideDeleteModal()
                focusElementById('deleteDraft')
              }}
              loading={deletingDeclaration}
            >
              <p className="lead"><FormattedMessage {...messages.deleteModalMessage} /></p>
            </Modal>
          }

          {accepted && showAcceptedModal &&
            <Modal
              show={accepted}
              showCancel={Boolean(error)}
              cancelMessage={messages.acceptedModalCancel}
              showContinue={!error}
              titleMessage={messages.acceptedModalTitle}
              continueMessage={messages.sendSuccessModalButton}
              continueDisabled={submitting || error}
              focusDisableButton
              disableEscKey
              onClickContinue={() => {
                destroy()
                history.push(getStepPath(SUMMARY_ROUTE_PATH, declaration.declarationId))
              }}
            >
              <InfoAlert
                message={messages.sentToCustoms}
                id="dialog-message"
              />
              {error && <Notification message={error} category="error" wrapInContainer={false} />}
            </Modal>
          }

          {showAmendmentModal &&
            <AmendmentModal
              show={showAmendmentModal}
              loading={creatingNewVersion}
              onHide={() => {
                onHideAmendmentModal()
                focusElementById('amendDraft')
              }}
              declarationId={declaration.declarationId}
              referencePeriod={declaration.referencePeriod}
              createAndOpenNewVersion={createAndOpenNewVersion}
            />
          }

          {showCopyModal &&
            <CopyModal
              show={showCopyModal}
              loading={creatingNewCopy}
              onHide={() => {
                onHideCopyModal()
                focusElementById('copyDraft')
              }}
              declarationId={declaration.declarationId}
              getReferencePeriods={() => {
                const selectedPsiObject = find(cachedCustomers, ['id', declaration.psi])
                return getReferencePeriodSelectOptions(
                  declaration.flowCode, declaration.psiReportingUnit, selectedPsiObject, intl.formatMessage, locale
                )
              }}
              createAndOpenNewCopy={createAndOpenNewCopy}
            />
          }
        </Form>
      </ErrorBoundary>
    )
  }
}

export default withRouter(ConfirmationView)