import { defineMessages } from 'react-intl'
import commonPermitsMessages from '../messages'

const messages = defineMessages({
  appName: {
    id: 'header.appName',
    description: 'Application name heading shown in header',
    defaultMessage: '?? Online services channel',
  },
  title: {
    id: 'permits.list.title',
    description: 'Title for monitoring page',
    defaultMessage: '?? Authorisations',
  },
  newPermitApplication: {
    id: 'permits.list.newPermitApplication',
    description: 'Label for create new application button in authorisations listing',
    defaultMessage: '?? Create new authorisation application',
  },
  noPermits: {
    id: 'permits.list.noPermits',
    description: 'Text to display when there are zero authorisations in either of the authorisation lists',
    defaultMessage: '?? No authorisations',
  },
  noApplications: {
    id: 'permits.list.noApplications',
    description: 'Text to display when there are zero applications in the applications list',
    defaultMessage: '?? No applications',
  },
  showOnlyActivePermits: {
    id: 'permits.list.showOnlyActivePermits',
    description: 'Label for checkbox to list only active authorisations',
    defaultMessage: '?? Show only active authorisations',
  },
  loadingPermits: {
    id: 'permits.listing.loading',
    description: 'Info text shown while loading authorisations',
    defaultMessage: '?? Loading authorisations...',
  },
  loadingApplications: {
    id: 'permits.listing.loadingApplications',
    description: 'Info text shown while loading authorisation applications',
    defaultMessage: '?? Loading applications...',
  },
  decisionProposalExists: {
    id: 'permits.listing.decisionProposalExists',
    description: 'Text inside info alert guiding user to give a rejoinder (vastine)',
    defaultMessage: '?? You have new decision proposals',
  },
  openDecisionProposals: {
    id: 'permits.listing.openDecisionProposals',
    description: 'Button label',
    defaultMessage: '?? Open decision proposals',
  },
  permitsListFetchError: {
    id: 'permits.listing.fetchError',
    description: 'Error text shown when authorisations could not be fetched',
    defaultMessage: '?? Could not fetch all authorisations',
  },
  tabLabelDraftPermits: {
    id: 'permits.tabLabelDraftPermits',
    description: 'Label for draft authorisations tab',
    defaultMessage: '?? Application drafts',
  },
  tabLabelReadyPermits: {
    id: 'permits.tabLabelReadyPermits',
    description: 'Label for ready authorisations tab',
    defaultMessage: '?? Granted',
  },
  tabLabelProcessingPermits: {
    id: 'permits.tabLabelProcessingPermits',
    description: 'Label for processing authorisations tab',
    defaultMessage: '?? In processing',
  },
  tabLabelReplyPermits: {
    id: 'permits.tabLabelReplyPermits',
    description: 'Label for reply tab',
    defaultMessage: '?? Reply',
  },
  noDecisionProposalsFound: {
    id: 'permits.noDecisionProposalsFound',
    description: 'Info message for no decision proposals found',
    defaultMessage: '?? No decision proposals found',
  },
  columnLabelReferenceNumber: {
    id: 'permits.listing.columnLabelReferenceNumber',
    description: 'Label for completed authorisations reference',
    defaultMessage: '?? Reference number',
  },
  columnLabelCompany: {
    id: 'permits.listing.columnLabelCompany',
    description: 'Label for authorisations listing column "company" (provider of statistical information)',
    defaultMessage: '?? Company',
  },
  columnLabelPermitId: {
    id: 'permits.listing.columnLabelPermitId',
    description: 'Label for authorisations listing column "authorisation id"',
    defaultMessage: '?? Authorisation reference number',
  },
  columnLabelPermitOrApplicationId: {
    id: 'permits.listing.columnLabelPermitOrApplicationId',
    description: 'Label for rejoinders and decision proposals tab column',
    defaultMessage: '?? Authorisation reference number or issue number',
  },
  columnLabelApplicationId: {
    id: 'permits.listing.columnLabelApplicationId',
    description: 'Label for applications listing column',
    defaultMessage: '?? Issue number',
  },
  columnLabelDraftId: {
    id: 'permits.listing.columnLabelDraftId',
    description: 'Label for drafts listing column',
    defaultMessage: '?? Reference number',
  },
  columnLabelPermitName: {
    id: 'permits.listing.columnLabelPermitName',
    description: 'Label for authorisations listing column "authorisation name"',
    defaultMessage: '?? Authorisation',
  },
  columnLabelPermitCreatedAt: {
    id: 'permits.listing.columnLabelPermitCreatedAt',
    description: 'Label for authorisations listing column "created at"',
    defaultMessage: '?? Created at',
  },
  columnLabelStartDate: {
    id: 'permits.listing.columnLabelStartDate',
    description: 'Label for authorisations listing column "start date"',
    defaultMessage: '?? Start date',
  },
  columnLabelEndDate: {
    id: 'permits.listing.columnLabelEndDate',
    description: 'Label for authorisations listing column "end date"',
    defaultMessage: '?? End date',
  },
  columnLabelRegistered: {
    id: 'permits.listing.columnLabelRegistered',
    description: 'Label for authorisations listing column "registered"',
    defaultMessage: '?? Registered',
  },
  columnLabelDecisionDate: {
    id: 'permits.listing.columnLabelDecisionDate',
    description: 'Label for authorisations listing column "decision date"',
    defaultMessage: '?? Decision date',
  },
  columnLabelDateOfApply: {
    id: 'permits.listing.columnLabelDateOfApply',
    description: 'Label for authorisations listing column "date of applied"',
    defaultMessage: '?? Date of applied',
  },
  columnLabelDeadline: {
    id: 'permits.listing.columnLabelDeadline',
    description: 'Label for authorisations listing column "deadline',
    defaultMessage: '?? Deadline',
  },
  columnLabelReference: {
    id: 'permits.listing.columnLabelReference',
    description: 'Label for authorisations listing column "customer reference"',
    defaultMessage: '?? Customer reference',
  },
  columnLabelDateModified: {
    id: 'permits.listing.columnLabelDateModified',
    description: 'Label for authorisations listing column "date modified"',
    defaultMessage: '?? Modified',
  },
  columnLabelActions: {
    id: 'permits.listing.columnLabelActions',
    description: 'Label for authorisations listing column "actions"',
    defaultMessage: '?? Actions',
  },
  columnLabelType: {
    id: 'permits.listing.columnLabelType',
    description: 'Label for type listing column',
    defaultMessage: '?? Type',
  },
  actionsColumnScreenReaderLabel: {
    id: 'permits.listing.actionsColumnScreenReaderLabel',
    description: 'Screen reader label for column "actions"',
    defaultMessage: '?? Remove application draft',
  },
  columnLabelEditor: {
    id: 'permits.listing.columnLabelEditor',
    description: 'Label for authorisations listing column "date modified"',
    defaultMessage: '?? Modified',
  },
  columnLabelDateSent: {
    id: 'permits.listing.columnLabelDateSent',
    description: 'Label for authorisations listing column "date sent"',
    defaultMessage: '?? Sent',
  },
  columnLabelStatus: {
    id: 'permits.listing.columnLabelStatus',
    description: 'Label for authorisations listing column "status"',
    defaultMessage: '?? Status',
  },
  columnLabelRepresentative: {
    id: 'permits.listing.columnLabelRepresentative',
    description: 'Label for authorisations listing column "representative"',
    defaultMessage: '?? Representative',
  },
  columnLabelVersion: {
    id: 'permits.listing.columnLabelVersion',
    description: 'Label for authorisations listing column "version"',
    defaultMessage: '?? Version',
  },
  loadingRow: {
    id: 'permits.listing.loadingRow',
    description: 'Text shown on a row that is being fetched from server',
    defaultMessage: '?? Loading authorisation...',
  },
  errorLoadingRow: {
    id: 'permits.listing.errorLoadingRow',
    description: 'Text shown on a row that could not be fetched from server',
    defaultMessage: '?? Could not load authorisation.',
  },

  labelApplicationStatusDraft: {
    id: 'permits.labelApplicationStatusDraft',
    description: 'Text shown on draft status',
    defaultMessage: '?? Draft',
  },
  labelApplicationStatusRegistered: {
    id: 'permits.labelApplicationStatusRegistered',
    description: 'Text shown on registered status',
    defaultMessage: '?? Registered',
  },
  labelApplicationStatusVerification: {
    id: 'permits.labelApplicationStatusVerification',
    description: 'Text shown on verification status',
    defaultMessage: '?? Verification',
  },
  labelApplicationStatusAccepted: {
    id: 'permits.labelApplicationStatusAccepted',
    description: 'Text shown on accepted status',
    defaultMessage: '?? Accepted',
  },
  labelApplicationStatusNotAccepted: {
    id: 'permits.labelApplicationStatusNotAccepted',
    description: 'Text shown on not accepted status',
    defaultMessage: '?? Not accepted',
  },
  labelApplicationStatusFavourable: {
    id: 'permits.labelApplicationStatusFavourable',
    description: 'Text shown on favourable status',
    defaultMessage: '?? Favourable',
  },
  labelApplicationStatusNonFavourable: {
    id: 'permits.labelApplicationStatusNonFavourable',
    description: 'Text shown on non favourable status',
    defaultMessage: '?? Non favourable',
  },
  labelApplicationStatusWithdrawn: {
    id: 'permits.labelApplicationStatusWithdrawn',
    description: 'Text shown on withdrawn status',
    defaultMessage: '?? Withdrawn',
  },
  labelRejoinderStatusProcessed: {
    id: 'permits.labelRejoinderStatusProcessed',
    description: 'Text shown on processed status',
    defaultMessage: '?? Processed',
  },
  labelPermitStatusDraft: {
    id: 'permits.labelPermitStatusDraft',
    description: 'Text shown on draft status',
    defaultMessage: '?? Draft',
  },
  labelPermitStatusApproved: {
    id: 'permits.labelPermitStatusApproved',
    description: 'Text shown on approved status',
    defaultMessage: '?? Approved',
  },
  labelPermitStatusActive: {
    id: 'permits.labelPermitStatusActive',
    description: 'Text shown on active status',
    defaultMessage: '?? Active',
  },
  labelPermitStatusSuspended: {
    id: 'permits.labelPermitStatusSuspended',
    description: 'Text shown on suspended status',
    defaultMessage: '?? Suspended',
  },
  labelPermitStatusEnded: {
    id: 'permits.labelPermitStatusEnded',
    description: 'Text shown on ended status',
    defaultMessage: '?? Ended',
  },
  labelPermitStatusAnnulled: {
    id: 'permits.labelPermitStatusAnnulled',
    description: 'Text shown on annulled status',
    defaultMessage: '?? Annulled',
  },
  labelPermitStatusRevoked: {
    id: 'permits.labelPermitStatusRevoked',
    description: 'Text shown on revoked status',
    defaultMessage: '?? Revoked',
  },
  labelDecisionProposalStatusProcessed: {
    id: 'permits.labelDecisionProposalStatusProcessed',
    description: 'Text shown on processed status',
    defaultMessage: '?? Processed',
  },
  labelDecisionProposalStatusDeadlineFuture: {
    id: 'permits.labelDecisionProposalStatusDeadlineFuture',
    description: 'Text shown on deadline future status',
    defaultMessage: '?? Rejoinder time left',
  },
  labelDecisionProposalStatusDeadlinePast: {
    id: 'permits.labelDecisionProposalStatusDeadlinePast',
    description: 'Text shown on deadline past status',
    defaultMessage: '?? No rejoinder time left',
  },
  labelPermitTypeApplication: {
    id: 'permits.labelPermitTypeApplication',
    description: 'Text shown on application type',
    defaultMessage: '?? Application',
  },
  labelPermitTypeAuthorisation: {
    id: 'permits.labelPermitTypeAuthorisation',
    description: 'Text shown on authorisation type',
    defaultMessage: '?? Authorisation',
  },
  labelPermitTypeRevocation: {
    id: 'permits.labelPermitTypeRevocation',
    description: 'Text shown on revocation type',
    defaultMessage: '?? Revocation',
  },
  labelPermitTypeDecisionProposal: {
    id: 'permits.labelPermitTypeDecisionProposal',
    description: 'Text shown on decision proposal type',
    defaultMessage: '?? Decision proposal',
  },
  labelPermitTypeRejoinder: {
    id: 'permits.labelPermitTypeRejoinder',
    description: 'Text shown on rejoinder type',
    defaultMessage: '?? Rejoinder',
  },
  showActions: {
    id: 'permits.listing.showActions',
    description: 'Text in button to show actions for an application',
    defaultMessage: '?? Show actions',
  },
  hideActions: {
    id: 'permits.listing.hideActions',
    description: 'Text in button to hide actions for an application',
    defaultMessage: '?? Hide actions',
  },
})

export default {
  ...commonPermitsMessages,
  ...messages,
}
