
import React, { useState } from 'react'
import ReactDOM from 'react-dom'
import { usePopper } from 'react-popper'
import { useRootClose } from 'react-overlays-5'
import { FormattedMessage } from 'react-intl'
import classNames from 'classnames'
import InteractiveElement from 'src/components/InteractiveElement'
import Icon from 'src/components/Icon'
import messages from './messages'


export default function Popper(props) {
  const { id, type, key, content, placement, closeIcon, className, large, children, usePortal } = props
  let { trigger, rootClose } = props

  const [popperOpen, setPopperOpen] = useState(false)
  const [referenceElement, setReferenceElement] = useState(null)
  const [popperElement, setPopperElement] = useState(null)
  const [arrowElement, setArrowElement] = useState(null)
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [{ name: 'arrow', options: { element: arrowElement } }],
  })

  // Root close makes sure popper is closed on outside click, esc-button press etc.
  if (rootClose) {
    const handleRootClose = () => setPopperOpen(false)

    useRootClose(popperElement, handleRootClose, {
      disabled: !popperOpen,
    })
  }

  const togglePopper = (open) => {
    const newStateOpen = typeof open === 'boolean' ? open : !popperOpen
    if (newStateOpen && !popperOpen && typeof props.onShow === 'function') {
      props.onShow()
    }
    setPopperOpen(newStateOpen)
  }

  if (type !== 'tooltip' && type !== 'popover') {
    throw new Error('Missing required type prop on Popper')
  }
  if (trigger === undefined) {
    trigger = type === 'tooltip' ? 'hover' : 'click'
  }
  if (rootClose === undefined) {
    rootClose = type === 'popover' || false
  }

  const cloneProps = {
    'aria-expanded': popperOpen,
  }
  if (id && popperOpen) {
    cloneProps['aria-describedby'] = id
  }
  if (trigger === 'click') {
    cloneProps.onClick = togglePopper
  } else if (trigger === 'hover') {
    cloneProps.onMouseOver = cloneProps.onFocus = () => togglePopper(true)
    cloneProps.onMouseOut = cloneProps.onBlur = () => togglePopper(false)
  }
  const child = React.Children.only(children)
  const targetChild = React.cloneElement(child, cloneProps)

  let popper
  if (popperOpen) {
    popper = (
      <div ref={setPopperElement} style={styles.popper} {...attributes.popper} id={id} key={key} placement={placement} className={classNames(type, large && 'large')}>
        <div className={`${type}-content`}>
          {closeIcon &&
            <InteractiveElement
              aria-hidden="true"
              onClick={() => togglePopper(false)}
              className="close"
              tabIndex="-1"
            >
              <Icon name="close" />
              <span className="sr-only"><FormattedMessage {...messages.close} /></span>
            </InteractiveElement>
          }
          {content}
        </div>
        <div className="arrow" ref={setArrowElement} style={styles.arrow} />
      </div>
    )

    // Portal is used to move dom element to document body for better rendering in overflow: hidden containers.
    if (usePortal) {
      popper = ReactDOM.createPortal(popper, document.getElementsByTagName('body')[0])
    }
  }
  return (
    <>
      <span
        className={className}
        ref={setReferenceElement}
      >
        {targetChild}
      </span>
      {popper}
    </>
  )
}
