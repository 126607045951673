import React from 'react'
import { FormattedMessage } from 'react-intl'
import ReactMarkdown from 'react-markdown'
import { has, uniqBy, chain } from 'lodash'
import { Button, ButtonToolbar } from 'react-bootstrap'
import {withRouter} from 'react-router-dom'
import Icon from 'src/components/Icon'
import LabelArea from 'src/components/form_v2/LabelArea'
import Select from 'src/components/form_v2/Select'
import Modal from 'src/components/Modal'
import { formatCmsMessage, formatOptionalCmsMessage } from 'src/utils'
import showNotification from 'src/utils/notifications'
import api from '../../../api'
import { createDraftObj } from '../../permitHelper'

import permitMessages from '../../messages'
import messages from '../../../messages'
import css from './PermitSelector.scss'
import {
  PERMITS_ROUTE_PATH,
  PERMIT_APPLICATION_ROUTE_PATH,
} from '../../../constants'
import {
  INIT_ROUTE_PATH,
  PERMIT_GROUP_GUARANTEE,
  PERMIT_GROUP_SIMPLIFICATION,
  PERMIT_GROUP_TRANSPORT_WAREHOUSING,
  PERMIT_GROUP_SPECIAL_PROCEDURES,
  PERMIT_TYPES_SORT_ORDER,
  PERMIT_GROUP_OTHER,
  PERMIT_GROUP_RELIEF,
} from '../../constants'

class PermitSelectorV2 extends React.Component {
  constructor(props) {
    super(props)

    const groupOptions = this.getPermitGroupOptions()

    this.state = {
      selectedPermitGroup: groupOptions.length === 1 ? groupOptions[0].value : '',
      selectedPermitType: '',
      showModal: false,
      usePreviousApplication: false,
      isLoading: false,
      permitGroupId: null,
    }
    this.showModal = this.showModal.bind(this)
    this.hideModal = this.hideModal.bind(this)
    this.getPermitTitle = this.getPermitTitle.bind(this)
  }

  onContinue() {
    const {
      applicant,
      representative,
      locale,
      customer,
      history,
    } = this.props
    const { selectedPermitType } = this.state
    this.setState({
      isLoading: true,
    })

    api.createDraft({ applicationTypeCode: selectedPermitType })
      .then((response) => {
        const draftObject = createDraftObj(selectedPermitType, applicant, representative, customer, locale)
        return api.saveDraft(draftObject, response.referenceNumber, response.version)
      })
      .then((response) => {
        this.setState({
          isLoading: false,
        })
        showNotification({
          level: 'success',
          icon: 'checkmark',
          message: permitMessages.savedApplicationDraft,
          autoDismiss: 4, // the notification should be visible for 4 seconds
        })

        // eslint-disable-next-line max-len
        history.push(`/${PERMITS_ROUTE_PATH}/${PERMIT_APPLICATION_ROUTE_PATH}/${response.referenceNumber}:${response.version}/${INIT_ROUTE_PATH}`)
      })
  }

  getPermitCategoryTitle(permitCategoryId) {
    const {
      intl: { formatMessage },
    } = this.props

    switch (permitCategoryId) {
    case PERMIT_GROUP_GUARANTEE: {
      return formatMessage(messages.titleGuaranteePermitGroup)
    }
    case PERMIT_GROUP_SIMPLIFICATION: {
      return formatMessage(messages.titleSimplificationPermitGroup)
    }
    case PERMIT_GROUP_TRANSPORT_WAREHOUSING: {
      return formatMessage(messages.titleWarehousingPermitGroup)
    }
    case PERMIT_GROUP_SPECIAL_PROCEDURES: {
      return formatMessage(messages.titleSpecialProceduresPermitGroup)
    }
    case PERMIT_GROUP_RELIEF: {
      return formatMessage(messages.titleReliefPermitGroup)
    }
    case PERMIT_GROUP_OTHER: {
      return formatMessage(messages.titleOtherPermitGroup)
    }
    default:
      break
    }
    return null
  }

  getPermitListOptions(permitGroupId) {
    const {
      locale,
      permitTypes,
      permitGroupMap,
    } = this.props
    return chain(permitTypes)
      .filter(item => permitGroupMap[item.code] === permitGroupId)
      .map(item => ({
        title: `${item.name[locale]} (${item.code})`,
        value: item.code,
      }))
      .sort((a, b) => a.value.localeCompare(b.value))
      .sortBy(item => PERMIT_TYPES_SORT_ORDER[item.value])
      .value()
  }

  getPermitGroupOptions() {
    const permitGroupMap = this.props.permitGroupMap
    const permitCategoryOptions = []
    Object.keys(permitGroupMap).forEach((typeCode) => {
      const permitCategoryId = permitGroupMap[typeCode]
      permitCategoryOptions.push({
        value: permitCategoryId,
        title: this.getPermitCategoryTitle(permitCategoryId),
      })
    })

    return uniqBy(permitCategoryOptions, 'value')
  }

  getPermitTitle = (listOptions, selectedType) => {
    const selected = listOptions.filter(option => option.value === selectedType)

    return selected.length ? selected[0].title : ''
  }

  hideModal() {
    const permitGroupOptions = this.getPermitGroupOptions()

    if (permitGroupOptions.length === 1) {
      this.setState({
        showModal: false,
        selectedPermitGroup: permitGroupOptions[0].value,
        selectedPermitType: '',
      })
    } else {
      this.setState({
        showModal: false,
        selectedPermitGroup: '',
        selectedPermitType: '',
      })
    }
  }

  showModal() {
    this.setState({ showModal: true })
  }

  showSelectedPermitGroupTitle() {
    const options = this.getPermitGroupOptions()

    const selectedOptionValue = this.state.selectedPermitGroup
    const selected = options.find(option => option.value === selectedOptionValue)

    return selected.title
  }

  render() {
    const {
      isFetchingCustomer,
      intl: { formatMessage },
      intl,
      customerInformationUnavailable,
      isPerson,
    } = this.props
    const {
      selectedPermitGroup,
      selectedPermitType,
      isLoading,
    } = this.state

    const permitListOptions = this.getPermitListOptions(selectedPermitGroup)
    const permitGroupOptions = this.getPermitGroupOptions()

    const permitTitle = this.getPermitTitle(permitListOptions, selectedPermitType)

    return (
      <div>
        <ButtonToolbar>
          <Button
            bsStyle="primary"
            onClick={this.showModal}
            id-qa-test={`${messages.newPermitApplication.defaultMessage.replace(/\s+/g, '-').toLowerCase()}`}
            disabled={customerInformationUnavailable || isFetchingCustomer}
          >
            <Icon name="add" /><FormattedMessage {...messages.newPermitApplication} />
          </Button>
        </ButtonToolbar>

        <Modal
          show={this.state.showModal}
          titleMessage={messages.permitSelectorTitle}
          cancelMessage={messages.permitSelectorCancel}
          showCancel
          onClickCancel={this.hideModal}
          continueMessage={messages.permitSelectorContinue}
          continueDisabled={!selectedPermitGroup || !selectedPermitType}
          onClickContinue={() => this.onContinue()}
          loading={isLoading}
          showContinue
        >
          <HelpTextMessage
            selectedPermitGroup={selectedPermitGroup}
            selectedPermitType={selectedPermitType}
            intl={intl}
            isPerson={isPerson}
          />
          <div className={css.labelArea}>
            <LabelArea
              id={'label-choose-permit-type'}
              input={{ name: 'permit-selector', multilineLabel: true }}
              help={{ content: formatOptionalCmsMessage(intl, '/permits/permitGroup') }}
              showLabel
              mandatory
              label={formatMessage(messages.labelChoosePermitType)}
              htmlFor={'select-choose-permit-type'}
            >
              <FormattedMessage
                {...messages.labelChoosePermitType}
              />:
            </LabelArea>
            <div style={{ position: 'relative' }}>
              {permitGroupOptions.length === 1 && <p>{this.showSelectedPermitGroupTitle()}</p>}
              {permitGroupOptions.length > 1 && <Select
                id={'select-choose-permit-type'}
                autoFocus
                input={{
                  name: 'permitGroup',
                  onChange: (permitGroupId) => {
                    this.setState({
                      selectedPermitGroup: permitGroupId,
                      selectedPermitType: '',
                    })
                  },
                  value: selectedPermitGroup,
                }}
                placeholderMessage={formatMessage(messages.placeholderChoosePermitCategory)}
                options={permitGroupOptions}
              />}
            </div>
          </div>

          {selectedPermitGroup && permitListOptions.length > 0 &&
            <div>
              <LabelArea
                id={'label-choose-permit'}
                input={{ name: 'permit-selector', multilineLabel: true }}
                help={{ content: isPerson ? formatCmsMessage(formatMessage, '/permits/permitPersonSelectorInfoText') : formatCmsMessage(formatMessage, '/permits/permitSelectorInfoText') }}
                showLabel
                mandatory
                label={formatMessage(messages.labelChoosePermit)}
                htmlFor={'select-choose-permit'}
              >
                <FormattedMessage
                  {...messages.labelChoosePermit}
                />:
              </LabelArea>
              <div className={css.permitSelector}>
                <Select
                  id={'select-choose-permit'}
                  id-qa-test={'select-permit-type'}
                  autoFocus
                  input={{
                    name: 'permits',
                    onChange: (permitTypeCode) => {
                      this.setState({
                        selectedPermitType: permitTypeCode,
                      })
                    },
                    value: selectedPermitType,
                  }}

                  placeholderMessage={formatMessage(messages.placeholderChoosePermitCategory)}
                  options={permitListOptions}
                />
              </div>
            </div>
          }
          <p className={css.selectedPermitType}>{permitTitle}</p>
        </Modal>
      </div>
    )
  }
}

function HelpTextMessage({ selectedPermitType, selectedPermitGroup, intl, intl: { formatMessage }, isPerson }) {
  const permitGroupBusinessMessages = {
    [PERMIT_GROUP_SIMPLIFICATION]: '/permits/applicationSimplificationInfoText',
    [PERMIT_GROUP_SPECIAL_PROCEDURES]: '/permits/applicationSpecialProceduresInfoText',
    [PERMIT_GROUP_TRANSPORT_WAREHOUSING]: '/permits/applicationWarehousingInfoText',
    [PERMIT_GROUP_RELIEF]: '/permits/applicationReliefInfoText',
  }

  const permitGroupPersonMessages = {
    [PERMIT_GROUP_RELIEF]: '/permits/applicationPersonReliefInfoText',
  }

  let messageId = isPerson ? '/permits/applicationPersonInfoText' : '/permits/applicationInfoText'

  if (!isPerson && selectedPermitGroup in permitGroupBusinessMessages) {
    messageId = permitGroupBusinessMessages[selectedPermitGroup]
  }

  if (isPerson && selectedPermitGroup in permitGroupPersonMessages) {
    messageId = permitGroupPersonMessages[selectedPermitGroup]
  }

  if (selectedPermitType !== '' && has(intl.messages, `/permits/application${selectedPermitType}InfoText`)) {
    messageId = isPerson ? `/permits/applicationPerson${selectedPermitType}InfoText` : `/permits/application${selectedPermitType}InfoText`
  }

  const isWarning = ['PPP', 'VVI'].includes(selectedPermitType) ||
    selectedPermitGroup === PERMIT_GROUP_TRANSPORT_WAREHOUSING

  return <div className={`${css.helpContainer} ${isWarning ? css.containerWarning : css.containerInfo}`}>
    <div id="dialog-message" className={css.containerInner}>
      <Icon name="info" className={css.icon} />
      <div className={css.containerText}>
        <ReactMarkdown
          source={formatMessage({ id: messageId })}
          linkTarget="_blank"
        />
      </div>
    </div>
  </div>
}

export default withRouter(PermitSelectorV2)