import React from 'react'
import { Grid, Col, Row, Button, Nav, NavItem } from 'react-bootstrap'
import { Form } from 'react-form'
import { AutoAffix } from 'react-overlays'
import { find, get, isEmpty } from 'lodash'
import Loader from 'src/components/Loader'
import ErrorBoundary from 'src/components/ErrorBoundary'
import PanelNavigation from 'src/components/PanelNavigation'
import CommonFormGroup from 'src/components/form_v2/CommonFormGroup'
import Heading from 'src/layout/Heading'
import Icon from 'src/components/Icon'
import { messages as validationMessages } from 'src/utils/validation'
import { focusFirstElementByIdOrName, focusAndScrollFirstFocusableElementInPanel } from 'src/utils'
import {
  LOCATION_INFORMATION_FORM_ROUTE_PATH,
  SUMMARY_ROUTE_PATH,
  FORM_TYPE,
  CUSTOMER_INFORMATION_PATH,
} from '../constants'
import {
  FORMS_ROUTE_PATH,
} from '../../constants'
import styles from './LocationInformationForm.scss'

export default class LocationInformationForm extends React.Component {
  constructor(props) {
    super(props)
    this.formApi = null
    this.state = {
      errorCustomerNotFound: false,
      errorFetchingCustomer: false,
      customerHasEori: false,
      isSubmitting: false,
      activePanel: null,
    }
    this.connectFormApi = this.connectFormApi.bind(this)
    this.submitForm = this.submitForm.bind(this)
  }

  componentDidMount() {
    this.fetchCustomer()
  }

  componentDidUpdate(prevProps) {
    const selectedAuthorizationId = prevProps.selectedAuthorizationObject ?
      prevProps.selectedAuthorizationObject.id
      :
      undefined

    if (this.props.selectedAuthorizationObject
      && this.props.selectedAuthorizationObject.id !== selectedAuthorizationId) {
      this.fetchCustomer(this.props)
    }

    if (this.props.customer.customerInformation && this.props.customer.customerInformation !== prevProps.customer.customerInformation) {
      const customer = this.props.customer
      if (isEmpty(customer.customerInformation)) {
        if (customer.error.code === 'customer.notFound') {
          this.setState({
            errorCustomerNotFound: true,
          })
          this.formApi.setAllValues({
            companyName: get(this.props.selectedAuthorizationObject, 'name', null),
            customerId: get(this.props.selectedAuthorizationObject, 'identifier', null),
          })
        } else {
          this.setState({
            errorFetchingCustomer: true,
          })
        }
        return
      }

      if (customer.customerInformation[this.props.selectedAuthorizationObject.id].hasEori) {
        this.setState({ customerHasEori: true })
      }

      const customerInformation = {
        customerId: this.props.selectedAuthorizationObject.identifier,
        ...customer.customerInformation[this.props.selectedAuthorizationObject.id],
      }
      // WHY ADDRESS IS DIFFERENT WHEN allOffices parameter is true / false
      const customerAddress = find(get(customerInformation, 'addresses'), { addressType: 'POST_ADDRESS' })

      this.formApi.setAllValues({
        companyName: get(customerInformation, 'fullName', null),
        customerId: get(customerInformation, 'customerId', null),
        address: get(customerAddress, 'address', null),
        zipCode: get(customerAddress, 'zipCode', null),
        city: get(customerAddress, 'city', null),
        countryCode: get(customerAddress, 'countryCode', null),
        eori: customerInformation.hasEori ? this.props.selectedAuthorizationObject.eori : undefined,
      })
    }
  }

  fetchCustomer(props = this.props) {
    const { fetchCustomerInformation, selectedAuthorizationObject } = props
    if (fetchCustomerInformation && selectedAuthorizationObject) {
      fetchCustomerInformation(selectedAuthorizationObject.id)
    }
  }

  connectFormApi(formApi) {
    this.formApi = formApi
  }

  submitForm() {
    const {
      locale,
      submitForm,
      cmsMessages,
    } = this.props
    const formState = this.formApi.getFormState()
    const formData = get(formState, 'values')
    const fieldCodes = [
      'companyName',
      'customerId',
      'eori',
      'address',
      'zipCode',
      'city',
      'countryCode',
      'reason',
      'contactName',
      'contactEmail',
    ]
    const fieldCodesToValidate = [
      'companyName',
      'customerId',
      'contactName',
      'contactEmail',
      'reason',
    ]

    let hasErrors = false
    fieldCodesToValidate.map((fieldCode) => {
      if (!get(formData, fieldCode)) {
        this.formApi.setError(fieldCode, validationMessages.required)
        hasErrors = true
      } else {
        this.formApi.setError(fieldCode, null)
      }
      return null
    })

    if (hasErrors) {
      this.formApi.submitForm()
        .then(() => {
          const errors = get(this.formApi.getFormState(), 'errors', {})
          focusFirstElementByIdOrName(Object.keys(errors))
        })
      return null
    }

    const dataArr = fieldCodes.filter(item => get(formData, item)).map(fieldCode => (
      {
        id: fieldCode,
        title: cmsMessages[`/forms/location-information/fields/${fieldCode}Heading`],
        value: get(formData, fieldCode),
      }
    ))

    this.setState({ isSubmitting: true })

    submitForm({
      formType: FORM_TYPE,
      locale,
      data: dataArr,
    }).then(() => {
      this.setState({
        isSubmitting: false,
      })
      this.props.history.push(`/${FORMS_ROUTE_PATH}/${LOCATION_INFORMATION_FORM_ROUTE_PATH}/${SUMMARY_ROUTE_PATH}`)
    })

    return null
  }

  renderEmptyInformationField() {
    const { cmsMessages } = this.props
    const informationMissingLabel = cmsMessages['/forms/location-information/fields/informationMissingLabel']

    return (
      <div>
        <span className={styles.value} aria-hidden="true">--</span>
        <span className="sr-only">{informationMissingLabel}</span>
      </div>
    )
  }

  renderCompanyInformation(formApi) {
    const { errorCustomerNotFound } = this.state
    const { cmsMessages } = this.props
    const companyInformationHeading = cmsMessages['/forms/location-information/companyInformationHeading']
    const companyNameHeading = cmsMessages['/forms/location-information/fields/companyNameHeading']
    const customerIdHeading = cmsMessages['/forms/location-information/fields/customerIdHeading']
    const eoriHeading = cmsMessages['/forms/location-information/fields/eoriHeading']
    const postAddressHeading = cmsMessages['/forms/location-information/fields/postAddressHeading']
    const { values } = formApi
    const { address, city, companyName, countryCode, customerId, eori, zipCode } = values

    return (
      <PanelNavigation
        id="companyInformation"
        title={companyInformationHeading}
        active
        noFocus
      >
        {!errorCustomerNotFound && <Grid fluid>
          <Row>
            <Col xs={12} md={3} className={styles.companyInfoColumn}>
              <strong className={styles.title}>{companyNameHeading}</strong>
              {(companyName &&
                <span className={styles.value}>
                  {`${companyName}`}
                </span>)
                ||
                this.renderEmptyInformationField()
              }
            </Col>
            <Col xs={12} md={3} className={styles.companyInfoColumn}>
              <strong className={styles.title}>{customerIdHeading}</strong>
              {(customerId &&
                <span className={styles.value}>
                  {`${customerId}`}
                </span>)
                ||
                this.renderEmptyInformationField()
              }

            </Col>
            <Col xs={12} md={3} className={styles.companyInfoColumn}>
              <strong className={styles.title}>{eoriHeading}</strong>
              {(eori &&
                <span className={styles.value}>
                  {`${eori}`}
                </span>)
                ||
                this.renderEmptyInformationField()
              }
            </Col>
            <Col xs={3} className={styles.companyInfoColumn}>
              <strong className={styles.title}>{postAddressHeading}</strong>
              {(address &&
                <span className={styles.value}>
                  <div>{`${address}`}</div>
                  {`${zipCode} `}
                  {`${city} `}
                  {`${countryCode}`}
                </span>)
                ||
                this.renderEmptyInformationField()
              }
            </Col>
          </Row>
        </Grid>}
      </PanelNavigation>
    )
  }

  renderContactInformation(formApi) {
    const { cmsMessages } = this.props
    const contactInformationHeading = cmsMessages['/forms/location-information/contactInformationHeading']
    const contactNameHeading = cmsMessages['/forms/location-information/fields/contactNameHeading']
    const contactEmailHeading = cmsMessages['/forms/location-information/fields/contactEmailHeading']

    return (
      <PanelNavigation
        id="contactInformation"
        title={contactInformationHeading}
        active
        noFocus
      >
        <Grid fluid>
          <Row>
            <Col xs={12} md={6}>
              <CommonFormGroup
                errors={formApi.errors}
                formApi={formApi}
                formGroupClassname="formElementGutter"
                formName="location-information"
                input={{
                  name: 'contactName',
                  type: 'text',
                  validate: true,
                  validation: {
                    strict: true,
                    mandatory: true,
                    maxLength: '70',
                    minLength: '0',
                  },
                  visible: true,
                }}
                label={contactNameHeading}
                showLabel
                type="TEXT"
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6}>
              <CommonFormGroup
                errors={formApi.errors}
                formApi={formApi}
                formGroupClassname="formElementGutter"
                formName="location-information"
                input={{
                  name: 'contactEmail',
                  type: 'email',
                  validate: true,
                  validation: {
                    strict: true,
                    mandatory: true,
                    maxLength: '70',
                    minLength: '0',
                  },
                  visible: true,
                }}
                label={contactEmailHeading}
                showLabel
                type="EMAIL"
              />
            </Col>
          </Row>
        </Grid>
      </PanelNavigation>
    )
  }

  renderChangeReason(formApi) {
    const { cmsMessages } = this.props
    const changeReasonHeading = cmsMessages['/forms/location-information/changeReasonHeading']
    const reasonHeading = cmsMessages['/forms/location-information/fields/reasonHeading']
    const reasonHelpText = cmsMessages['/forms/location-information/fields/reasonHelpText']

    return (
      <PanelNavigation
        id="changeReason"
        title={changeReasonHeading}
        active
        noFocus
      >
        <Grid fluid>
          <Row>
            <Col xs={12} md={12}>
              <CommonFormGroup
                errors={formApi.errors}
                formApi={formApi}
                formGroupClassname="formElementGutter"
                formName="location-information"
                helpText={reasonHelpText}
                input={{
                  name: 'reason',
                  type: 'textarea',
                  validate: true,
                  validation: {
                    strict: true,
                    mandatory: true,
                    maxLength: '2000',
                    minLength: '0',
                  },
                  visible: true,
                }}
                label={reasonHeading}
                showLabel
                type="TEXT"
              />
            </Col>
          </Row>
        </Grid>
      </PanelNavigation>
    )
  }

  render() {
    const {
      fetchingCustomer,
      history,
      cmsMessages,
    } = this.props

    const companyInformationHeading = cmsMessages['/forms/location-information/companyInformationHeading']
    const contactInformationHeading = cmsMessages['/forms/location-information/contactInformationHeading']
    const changeReasonHeading = cmsMessages['/forms/location-information/changeReasonHeading']
    const title = cmsMessages['/forms/location-information/title']
    const sendButtonText = cmsMessages['/forms/location-information/sendButtonText']
    const backButtonText = cmsMessages['/forms/location-information/backButtonText']
    const anchorNavigationLabel = cmsMessages['/forms/location-information/anchorNavigationLabel']

    const locationInformationFormHeading = (
      <Heading
        message={title}
      />
    )
    if (fetchingCustomer) {
      return (
        <Loader blocking />
      )
    }

    return (
      <div>
        {locationInformationFormHeading}
        {this.state.isSubmitting && <Loader blocking />}
        <main className="container" id="main">
          <Row>
            <Col md={2} lg={3}>
              <AutoAffix viewportOffsetTop={20} container={this}>
                <nav aria-label={anchorNavigationLabel}>
                  <Nav
                    bsStyle="pills"
                    stacked
                    className="horizontal-on-sm"
                    activeKey={this.state.activePanel}
                    onSelect={selectedKey => this.setState({ activePanel: selectedKey })}
                  >
                    <NavItem
                      id-qa-test={'btn-nav-companyInformation'}
                      eventKey="#companyInformation"
                      onClick={(event) => {
                        event.preventDefault()
                        focusAndScrollFirstFocusableElementInPanel('#companyInformation')
                      }}
                    >
                      {companyInformationHeading}
                    </NavItem>
                    <NavItem
                      id-qa-test={'btn-nav-changeReason'}
                      eventKey="#changeReason"
                      onClick={(event) => {
                        event.preventDefault()
                        focusAndScrollFirstFocusableElementInPanel('#changeReason')
                      }}
                    >
                      {changeReasonHeading}
                    </NavItem>
                    <NavItem
                      id-qa-test={'btn-nav-contactInformation'}
                      eventKey="#contactInformation"
                      onClick={(event) => {
                        event.preventDefault()
                        focusAndScrollFirstFocusableElementInPanel('#contactInformation')
                      }}
                    >
                      {contactInformationHeading}
                    </NavItem>
                  </Nav>
                </nav>
              </AutoAffix>
            </Col>
            <Col md={10} lg={9}>
              <ErrorBoundary>
                <Form
                  getApi={this.connectFormApi}
                  render={formApi => (
                    <form
                      onSubmit={(event) => {
                        event.preventDefault()
                        this.submitForm()
                      }}
                    >
                      {this.renderCompanyInformation(formApi)}
                      {this.renderChangeReason(formApi)}
                      {this.renderContactInformation(formApi)}
                      <div style={{ margin: '0 10px' }}>
                        <Button
                          className="pull-left"
                          id-qa-test="button-return"
                          onClick={() => history.replace(`/${CUSTOMER_INFORMATION_PATH}`)}
                        >
                          <Icon name="chevron-left" />
                          {backButtonText}
                        </Button>
                        <Button
                          type="submit"
                          bsStyle="primary"
                          className="pull-right"
                          id-qa-test="button-submit-form"
                        >
                          {sendButtonText}
                        </Button>
                      </div>
                    </form>
                  )}
                />
              </ErrorBoundary>
            </Col>
          </Row>
        </main>
      </div>
    )
  }
}
