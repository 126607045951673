import React from 'react'
import { Modal } from 'react-bootstrap'

import Loader from 'src/components/Loader'
import styles from './LoadingModal.scss'

function LoadingModal({
  visible,
  titleText,
  messageText,
}) {
  return (
    <Modal
      show={visible}
    >
      <Modal.Header>
        <Modal.Title componentClass="div">
          <h1 id="dialog-title">
            {titleText}
          </h1>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className={styles.container}>
          <div className={styles.loader}>
            <Loader center />
          </div>
          <div>
            <p>{messageText}</p>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default LoadingModal
