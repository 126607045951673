import React from 'react'
import { Button, Jumbotron } from 'react-bootstrap'
import { defineMessages, FormattedMessage } from 'react-intl'
import { get, delay } from 'lodash'
import { containsAnyRole, IAT_ROLES } from 'src/utils/auth'
import logger from 'src/utils/logger'
import Heading from 'src/layout/Heading'
import Radio from 'src/components/form/Radio'
import JumbotronMessage from 'src/components/JumbotronMessage'
import sendIatLoginRequest from './utils'


const MINIMUM_REDIRECT_TIME_MS = 3000

// Header-related intl message definitions
const messages = defineMessages({
  title: {
    id: 'iatSelector.headingTitle',
    description: 'Heading title for IAT page',
    defaultMessage: 'IAT',
  },
  roleSelectorTitle: {
    id: 'iatSelector.roleSelectorTitle',
    description: 'Title for IAT role selection modal',
    defaultMessage: 'Company selection for IAT',
  },
  continue: {
    id: 'iatSelector.continue',
    description: 'Button label for IAT selection operation "continue"',
    defaultMessage: 'Continue to IAT',
  },
  logout: {
    id: 'iatSelector.logout',
    description: 'Button label for IAT selector logout operation',
    defaultMessage: 'Logout',
  },
  cancel: {
    id: 'iatSelector.cancel',
    description: 'Button label for IAT selector exit operation (resulting in IAT mode teardown)',
    defaultMessage: 'Cancel',
  },
  error: {
    id: 'iatSelector.error',
    description: 'Unexpected error in IAT login handling',
    defaultMessage: 'Unexpected error occured.',
  },
  noAuthorizations: {
    id: 'iatSelector.noAuthorizations',
    description: 'Information text shown when user has no companies to choose from',
    defaultMessage: 'You are not authorized to use IAT.',
  },
  preSelectedSingleAuthorization: {
    id: 'iatSelector.preSelectedSingleAuthorization',
    description: 'Information text shown when user has one pre-selected company',
    defaultMessage: 'You are about to log in to IAT-application as {corporateName} ({organizationId}).',
  },
  selectAuthorization: {
    id: 'iatSelector.selectAuthorization',
    description: 'Information text shown when user needs to select a company for IAT',
    defaultMessage: 'Please select a company you wish to use in IAT-application login.',
  },
  iatWindowLoading: {
    id: 'iatSelector.iatWindowLoading',
    description: 'Information text shown in new window before IAT-application has loaded',
    defaultMessage:
      'Loading IAT application in new window. You may log out or continue using services in previous window.',
  },
  redirectedToIAT: {
    id: 'iatSelector.redirectedToIAT',
    description: 'Information text shown when user has been redirected to IAT',
    defaultMessage: 'IAT is now opened in a new window. You may log out or continue using services here.',
  },
})

class IATSelector extends React.Component {
  static getStateForNewAuthorizations(authorizations) {
    const newState = {
      allowedAuthorizations: [],
      selectedCompany: null,
    }
    if (authorizations) {
      newState.allowedAuthorizations = authorizations.filter(
        authorization => containsAnyRole(authorization.roles, IAT_ROLES)
      )
    }
    if (newState.allowedAuthorizations.length === 1) {
      newState.selectedCompany = 0
    }
    return newState
  }

  constructor(props) {
    super(props)
    this.state = {
      redirectedToIAT: false,
      error: false,
      ...IATSelector.getStateForNewAuthorizations(props.auth.authorizations),
    }
  }

  handleSelection = (index) => {
    this.setState({ selectedCompany: index })
  }

  continueToIAT = () => {
    const {
      intl,
      locale,
    } = this.props

    const {
      selectedCompany,
      allowedAuthorizations,
    } = this.state

    // We must open new window immediately in click handler to make sure browser doesn't prevent it
    const iatWindow = window.open()
    const timestampWindowOpened = Date.now()
    iatWindow.opener = null
    iatWindow.document.write(
      `<div style="
        font: 18px Arial, sans-serif;
        position:absolute;
        top: 50%;
        width: 95%;
        text-align: center;
        margin-top: -20px;
      ">
        ${intl.formatMessage(messages.iatWindowLoading)}
      </div>`)

    sendIatLoginRequest(get(allowedAuthorizations, `[${selectedCompany}].id`), locale)
      .then((loginResponse) => {
        const timestampUrlCreated = Date.now()
        const redirectToIAT = () => { iatWindow.location = loginResponse.redirect }
        if (timestampUrlCreated - timestampWindowOpened < MINIMUM_REDIRECT_TIME_MS) {
          delay(redirectToIAT, MINIMUM_REDIRECT_TIME_MS - (timestampUrlCreated - timestampWindowOpened))
        } else {
          redirectToIAT()
        }
        this.setState({
          error: false,
          redirectedToIAT: true,
        })
      })
      .catch((error) => {
        logger.error('Error in IAT login', error)
        this.setState({ error: true })
        iatWindow.close()
      })
  }

  render() {
    const {
      sendLogoutRequestAndExit,
      router,
    } = this.props

    const {
      error,
      redirectedToIAT,
      selectedCompany,
      allowedAuthorizations,
    } = this.state

    const authorizationOptionsCount = !error && !redirectedToIAT && allowedAuthorizations.length
    if (authorizationOptionsCount === 0) {
      return <JumbotronMessage titleMessage={messages.title} contentMessage={messages.noAuthorizations} fullpage />
    }

    return (
      <div>
        <Heading message={messages.title} compact />
        <main className="container" role="main" id="main">
          <Jumbotron>
            <h2><FormattedMessage {...messages.roleSelectorTitle} /></h2>
            {error && <p className="text-danger"><FormattedMessage {...messages.error} /></p>}
            {redirectedToIAT &&
              <FormattedMessage {...messages.redirectedToIAT} tagName="p" />
            }
            {(authorizationOptionsCount === 1) &&
              <FormattedMessage
                {...messages.preSelectedSingleAuthorization}
                values={{
                  organizationId: allowedAuthorizations[0].id,
                  corporateName: allowedAuthorizations[0].name,
                }}
                tagName="p"
              />
            }
            {(authorizationOptionsCount > 1) && (
              <div>
                <FormattedMessage {...messages.selectAuthorization} tagName="p" />
                {allowedAuthorizations.map((authorization, index) =>
                  <Radio
                    key={`role${index}`}
                    name="role"
                    value={index}
                    checked={parseInt(selectedCompany, 10) === parseInt(index, 10)}
                    onClick={event => this.handleSelection(event.target.value)}
                  >
                    {authorization.id} {authorization.name}
                  </Radio>
                )}
              </div>
            )}
            <div className="btn-toolbar">
              <Button
                className="pull-left"
                onClick={() => router.push('/')}
              >
                <FormattedMessage {...messages.cancel} />
              </Button>
              <Button
                className="pull-left"
                onClick={() => sendLogoutRequestAndExit()}
              >
                <FormattedMessage {...messages.logout} />
              </Button>
              {(!redirectedToIAT || error) &&
                <Button
                  bsStyle="primary"
                  disabled={this.state.selectedCompany == null}
                  className="pull-right"
                  onClick={this.continueToIAT}
                >
                  <FormattedMessage {...messages.continue} />
                </Button>
              }
            </div>
          </Jumbotron>
        </main>
      </div>
    )
  }
}

export default IATSelector
