import { createDefaultAction } from 'src/utils/redux'
import { showGlobalNotification } from 'src/actions'
import { apiCall } from 'src/utils/http'
import {
  FORMS_SAVE_DATA,
  FORMS_DESTROY_STATE,
} from './constants'

export const destroyFormsState = createDefaultAction(FORMS_DESTROY_STATE)

export const saveFormData = createDefaultAction(FORMS_SAVE_DATA)

const jsonFields = ['reason', 'contactName', 'contactEmail']

const errorMessage = {
  id: 'forms.locationInformation.errorSubmittingForm',
  description: 'Text of notification',
  defaultMessage: 'The location change request was unsuccessful. Please try again later.',
}

export const submitForm = formData =>
  (dispatch, getState) => {
    const { config: { bootstrapConfig } } = getState()

    const mappedData = formData.data
      .filter(field => jsonFields.includes(field.id))
      .map(item => (
        {
          title: item.title,
          value: item.value,
          oneliner: false,
        }
      ))

    const base = bootstrapConfig.tm_formula_ext_url
    const apiUrl = `${base}/form/${formData.formType}/${formData.locale}?customerType=BUSINESS`
    const callParams = {
      method: 'POST',
      body: JSON.stringify(mappedData),
    }

    return apiCall(apiUrl, callParams, {}, undefined, false)
      .then((response) => {
        dispatch(saveFormData({
          ...formData,
          id: response.id,
          date: response.date,
        }))
      })
      .catch(() => {
        dispatch(showGlobalNotification({
          level: 'error',
          modal: true,
          message: errorMessage,
        }))
      })
  }
