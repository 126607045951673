import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Icon from 'src/components/Icon'
import messages from '../messages'


export default function EditDeclarationButtonToolbar({
  className,
  showBack,
  pathToBack,
  showNext,
  showExit,
  exitBsStyle,
  exitAlignment = 'left',
  disabled,
  children,
  exitAction,
}) {
  return (
    <div className={`panel-footer btn-toolbar ${className}`}>
      {showBack &&
        <Link to={pathToBack} className="backButton">
          <Button disabled={disabled} id-qa-test={'btn-back'}>
            <Icon name="chevron-left" />
            <FormattedMessage {...messages.previousStep} />
          </Button>
        </Link>
      }
      {showExit &&
        <Button
          disabled={disabled}
          bsStyle={exitBsStyle}
          className={`pull-${exitAlignment}`}
          onClick={exitAction}
          id-qa-test={'btn-exit'}
        >
          <Icon name="close" /><FormattedMessage {...messages.exit} />
        </Button>
      }
      {showNext &&
        <Button type="submit" bsStyle="primary" disabled={disabled} className="nextButton" id-qa-test={'btn-next'}>
          <FormattedMessage {...messages.nextStep} />
          <Icon name="chevron-right" style={{ marginLeft: '8px', marginRight: 0 }} />
        </Button>
      }
      {children}
    </div>
  )
}
