/**
 * Main header component
 * @module layout/Navigation
 */

import React from 'react'
import { Row, Col, Button } from 'react-bootstrap'
import { Link, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import { get } from 'lodash'
import BrowserInfo from 'src/components/BrowserInfo'
import Icon from 'src/components/Icon'
import { AUTH_PLURALITY_TYPES, AUTH_TYPES, RETURN_URI_QUERY_PARAM, SHOW_SWITCH_ROLE_CONFIRMATION_MODAL_FROM_LEVEL } from 'src/constants'
import { FEEDBACK_ROUTE_PATH } from '../routes/feedback/constants'

import SwitchLocale from '../components/SwitchLocale.js'
import './layout.scss'
import {
  switchLocale,
  sendSelectAuthorization,
  showSetAuthorizationNotificationAction,
  changeShowSwitchRoleConfirmationModal,
  changeShowLogoutConfirmationModal,
  changeReturnUriAfterRoleSwitch,
} from '../actions'
import { findServicePath, getAuthPlurality, getSelectedAuthorization, getSelectedDelegateCompanyFromAuthentication, redirectToLoginService, redirectToRoleSelector } from '../utils/auth'
import messages from './messages'
import { collectCmsMessages, formatRepresentativeName, formatSelectedAuthorizationName } from '../utils/index.js'
import { getRepresentative } from '../routes/permits/permit/permitHelper'

function HeaderBar(props) {
  // TODO: should we show a notification when role is changed?
  const {
    locale,
    onSwitchLocale,
    name,
    authorizations,
    isAuthenticated,
    isAuthenticating,
    selectedAuthorization,
    selectedDelegateCompanyId,
    browserInfoEnabled,
    content,
    representative,
    history,
    location,
  } = props
  // Hide auth actions on anonymous frontpage only
  const showAuthActions = (isAuthenticated || location.pathname !== '/')
    && location.pathname !== `/${FEEDBACK_ROUTE_PATH}`
  const delegateCompanyLabel = get(content, 'delegateCompanyLabel')

  const dontShowConfirmationModal = pathItems => pathItems.length < SHOW_SWITCH_ROLE_CONFIRMATION_MODAL_FROM_LEVEL ||
    (pathItems.length === SHOW_SWITCH_ROLE_CONFIRMATION_MODAL_FROM_LEVEL &&
      pathItems[SHOW_SWITCH_ROLE_CONFIRMATION_MODAL_FROM_LEVEL - 1] === '')


  const showRoleSelectorModal = (e) => {
    e.preventDefault()
    const { showRoleSelectorConfirmationModal, hideRoleSelectorConfirmationModal, setReturnUriAfterRoleSwitch } = props
    const uri = findServicePath(location.pathname)
    const encodedRedirectUri = encodeURIComponent(`${uri}${location.search}`)
    const returnUrl = `?${RETURN_URI_QUERY_PARAM}=${encodedRedirectUri}`
    const pathItems = location.pathname.split('/')

    if (dontShowConfirmationModal(pathItems)) {
      redirectToRoleSelector(returnUrl, history, encodedRedirectUri, setReturnUriAfterRoleSwitch, hideRoleSelectorConfirmationModal)
    } else {
      showRoleSelectorConfirmationModal()
    }
  }

  const showLogoutModal = () => {
    const {
      showLogoutConfirmationModal,
    } = props
    const pathItems = location.pathname.split('/')

    if (dontShowConfirmationModal(pathItems)) {
      history.push('/logout')
    } else {
      showLogoutConfirmationModal()
    }
  }

  // Show narrow locale selector if there is login actions next to it
  const narrowLocaleSelector = (showAuthActions && !isAuthenticating && !isAuthenticated) || (showAuthActions && isAuthenticated)
  const nonPrivateAuthorizations = authorizations?.filter(a => a.type !== AUTH_TYPES.private)
  const moreThanOneAuthorization = getAuthPlurality(nonPrivateAuthorizations) === AUTH_PLURALITY_TYPES.MULTIPLE

  return (
    <div>
      {browserInfoEnabled && <BrowserInfo />}
      <header className="header-navbar navbar navbar-inverse navbar-static-top">
        <div className="container">
          <Row>
            <Col xs={narrowLocaleSelector ? 6 : 12} sm={narrowLocaleSelector ? 7 : 12}>
              <SwitchLocale
                location={location.pathname}
                locale={locale}
                onSwitchLocale={onSwitchLocale}
                inNavigation
                className="nav-locale nav navbar-nav"
              />
            </Col>
            <Col xs={6} sm={5} className="px-xs-0 px-sm-0">
              {showAuthActions && !isAuthenticating && !isAuthenticated &&
                <div className="nav-user nav navbar-nav">
                  <Button
                    bsStyle="warning"
                    className="btn btn-compact navbar-btn btn-login"
                    style={{ textTransform: 'uppercase' }}
                    onClick={() => redirectToLoginService(locale, location.pathname)}
                    id-qa-test="btn-login"
                  >
                    <Icon name="lock-close" /><FormattedMessage {...messages.login} />
                  </Button>
                </div>
              }
              {showAuthActions && isAuthenticated &&
                <div className="nav-user nav navbar-nav">
                  <Button
                    onClick={showLogoutModal}
                    className="btn navbar-btn btn-logout pull-right"
                    id-qa-test={'btn-logout'}
                  >
                    <Icon name="lock-open" /><FormattedMessage {...messages.logout} />
                  </Button>
                </div>
              }
            </Col>
          </Row>
          {showAuthActions && isAuthenticated &&
            <div className="second-row">
              <div className="navbar-username pull-left">
                <Icon name="user" />
                <span> {name && name.toUpperCase()} {
                  selectedAuthorization &&
                    selectedAuthorization.type === AUTH_TYPES.business ?
                    `/ ${formatSelectedAuthorizationName(selectedAuthorization, selectedDelegateCompanyId, delegateCompanyLabel)}` :
                    ` ${formatRepresentativeName(representative, delegateCompanyLabel)}`
                }
                </span>
              </div>
              {showAuthActions && isAuthenticated && moreThanOneAuthorization &&
                <div className="pull-right">
                  <Link
                    onClick={(e) => showRoleSelectorModal(e)}
                    className="btn navbar-btn btn-link btn-switch-role pull-right"
                  >
                    <Icon name="user-change" /><FormattedMessage {...messages.switchRole} />
                  </Link>
                </div>
              }
            </div>
          }
        </div>
      </header>
    </div>
  )
}

const mapStateToProps = (state) => {
  const {
    auth: {
      authorizations,
      isAuthenticated,
      isAuthenticating,
      name,
    },
    locale,
    config,
  } = state
  const cmsMessages = get(state, 'content.cmsMessages', {})
  const content = collectCmsMessages('/authentication', cmsMessages)
  const representative = getRepresentative()

  return {
    authorizations,
    isAuthenticated,
    isAuthenticating,
    name,
    selectedAuthorization: getSelectedAuthorization(),
    selectedDelegateCompanyId: get(getSelectedDelegateCompanyFromAuthentication(), 'identifier'),
    locale,
    browserInfoEnabled: get(config, 'features.BROWSER_INFO', false),
    content,
    representative,
  }
}

const mapActionCreators = {
  setReturnUriAfterRoleSwitch: changeReturnUriAfterRoleSwitch,
  onSwitchLocale: switchLocale,
  onSelectAuthorization: sendSelectAuthorization,
  showSetAuthorizationNotification: showSetAuthorizationNotificationAction,
  showRoleSelectorConfirmationModal: () => changeShowSwitchRoleConfirmationModal(true),
  hideRoleSelectorConfirmationModal: () => changeShowSwitchRoleConfirmationModal(false),
  showLogoutConfirmationModal: () => changeShowLogoutConfirmationModal(true),
}

// Inject intl to get formatMessage in props
// React-redux connect is not pure to make sure navigation active state triggers update
export default withRouter(injectIntl(
  connect(mapStateToProps, mapActionCreators, null)(HeaderBar)
))
