import React from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'

import PermitsRootContainer from './container'
import PermitsChildRoutes from './permit/PermitsChildRoutes'
import PermitsReducer from './reducer'
import PermitsListReducer from './list/reducer'
import withReducer from '../../reducers/withReducer'
import PageNotFound from 'src/components/PageNotFound'
import { checkPermitsAuthorization } from '../../utils/auth'
import requireAuthentication from '../../containers/requireAuthentication'

const PermitsRoutes = () => {
  let { path } = useRouteMatch()

  return (
    <Switch>
      <Route exact path={path}>
        <PermitsRootContainer />
      </Route>
      <Route path={`${path}/:permitPath`}>
        <PermitsRootContainer>
          <PermitsChildRoutes />
        </PermitsRootContainer>
      </Route>
      <Route component={PageNotFound} />
    </Switch>
  )
}

export default withReducer([
  { key: 'permits.common', reducer: PermitsReducer },
  { key: 'permits.list', reducer: PermitsListReducer },
])(requireAuthentication(PermitsRoutes, checkPermitsAuthorization)
)
