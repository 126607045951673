import React from 'react'
import { Row, Col, Button } from 'react-bootstrap'
import ReactMarkdown from 'react-markdown'
import classNames from 'classnames'

import Icon from 'src/components/Icon'
import styles from './InlineNotification.scss'
import Modal from '../../../../components/Modal'

export const NOTIFICATION_TYPE = {
  MODAL: 'modal',
  INLINE: 'inline',
}

export function InlineAlert({ message, error, warning, info, ariaLabel, icon = 'info', contentId, srMessage }) {
  let alertClass = ''

  if (info) {
    alertClass = styles.info
  }
  if (error) {
    alertClass = styles.error
  }
  if (warning) {
    alertClass = styles.warning
  }

  return (
    <section id={contentId || 'inline-alert'} className={classNames(styles.alert, styles.flex, alertClass)} aria-label={ariaLabel}>
      <Icon name={icon} className={classNames(styles.icon, styles.inlineIcon)} />
      {srMessage && <span className="sr-only">{srMessage}</span>}
      <ReactMarkdown
        className={styles.permitInfoAlertContent}
        source={message}
      />
    </section>
  )
}

export default function InlineNotification({ notificationType = NOTIFICATION_TYPE.INLINE, ...restProps }) {
  if (notificationType === NOTIFICATION_TYPE.MODAL) {
    return <NotificationModal {...restProps} />
  }

  return <NotificationInline {...restProps} />
}

function NotificationInline({ message, buttonText, buttonIcon, onClick, ariaLabel, error, warning, info }) {
  let alertClass = ''

  if (info) {
    alertClass = styles.info
  }
  if (error) {
    alertClass = styles.error
  }
  if (warning) {
    alertClass = styles.warning
  }

  return (
    <section className={classNames(styles.alert, alertClass)} aria-label={ariaLabel}>
      <Row>
        <Col xs={1}>
          <Icon name="info" lg className={styles.icon} />
        </Col>
        <Col xs={9}>
          <ReactMarkdown
            className={styles.permitInfoAlertContent}
            source={message}
          />
          {onClick &&
          <Button onClick={onClick} className={styles.button}>
            { buttonIcon && <Icon name={buttonIcon} /> }
            <span>{buttonText}</span>
          </Button>
          }
        </Col>
      </Row>
    </section>
  )
}

function NotificationModal({ message, buttonText, onClick, representativeInformationTitle, showModal = false, onClickCancel }) {
  return (<Modal
    show={showModal}
    onClickCancel={onClickCancel}
    onClickContinue={onClick}
    continueMessage={buttonText}
    titleMessage={representativeInformationTitle}
    focusDisableButton
  >
    <p>{message}</p>
  </Modal>)
}
