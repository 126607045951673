import React from 'react'
import { injectIntl } from 'react-intl'
import { Field } from 'redux-form'
import { SelectFieldGroup } from 'src/components/form/Select'
import messages from '../messages'
import { formatReferencePeriod, getGroupedReferencePeriodSelectOptions } from '../../../utils'

export default injectIntl((props) => {
  const {
    locale,
    selectedPsiObject,
  } = props

  if (selectedPsiObject && props.flowCode && props.hasLiability(selectedPsiObject)) {
    const groupedReferencePeriodSelectOptions = getGroupedReferencePeriodSelectOptions(
      props.flowCode, props.psiReportingUnit, selectedPsiObject, props.intl.formatMessage, locale
    )


    if (groupedReferencePeriodSelectOptions.length) {
      return (
        <Field
          component={SelectFieldGroup}
          name="referencePeriod"
          mandatory
          props={{
            label: messages.referencePeriodInputLabel,
            placeholderMessage: messages.referencePeriodInputPlaceholder,
            options: groupedReferencePeriodSelectOptions,
            disabled: props.disabled || false,
            nonExistingValueFormatter: formatReferencePeriod,
            help: ({
              content: props.helpText,
            }),
          }}
        />
      )
    }
  }
  return (<Field
    component={SelectFieldGroup}
    name="referencePeriod"
    mandatory
    props={{
      label: messages.referencePeriodInputLabel,
      placeholderMessage: messages.referencePeriodInputPlaceholder,
      disabled: true,
      nonExistingValueFormatter: formatReferencePeriod,
      help: ({
        content: props.helpText,
      }),
    }}
  />)
})
