import React from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'

import CustomsInformationRoutes from './customsInformation/CustomsInformationRoutes'
import EoriRoutes from './eori/EoriRoutes'
import LocationInformationRoutes from './locationInformation/LocationInformationRoutes'
import PageNotFound from 'src/components/PageNotFound'


export default function FormsRoutes(props) {
  let { path } = useRouteMatch()
  const {
    features: {
      FORM_CUSTOMS_INFORMATION,
      FORM_EORI,
      FORM_LOCATION_INFORMATION,
    },
  } = props
  return (
    <Switch>
      {FORM_CUSTOMS_INFORMATION &&
        <Route path={`${path}/customs-information`}>
          <CustomsInformationRoutes />
        </Route>
      }
      {FORM_EORI &&
        <Route path={`${path}/eori`}>
          <EoriRoutes />
        </Route>
      }
      {FORM_LOCATION_INFORMATION &&
        <Route path={`${path}/location-information`}>
          <LocationInformationRoutes />
        </Route>
      }
      <Route component={PageNotFound} />
    </Switch>
  )
}