import React from 'react'
import { Grid, Col, Row, Button, ButtonToolbar } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import { get, isEmpty } from 'lodash'
import moment from 'moment'
import InfoArea from 'src/components/InfoArea'
import Icon from 'src/components/Icon'
import ErrorBoundary from 'src/components/ErrorBoundary'
import PanelNavigation from 'src/components/PanelNavigation'
import Heading from 'src/layout/Heading'
import messages from '../../messages'
import { FORMS_ROUTE_PATH } from '../../../constants'
import { CUSTOMS_INFORMATION_FORM_ROUTE_PATH, SUMMARY_ROUTE_PATH } from '../../constants'

export default class Summary extends React.Component {
  componentDidMount() {
    if (isEmpty(this.props.formData)) {
      this.props.history.replace(`/${FORMS_ROUTE_PATH}/${CUSTOMS_INFORMATION_FORM_ROUTE_PATH}`)
    }
  }

  render() {
    const {
      formData,
      history,
      intl: { formatMessage },
      match,
    } = this.props

    const date = get(formData, 'date', '')
    const dateFormatted = moment(date).format('DD.MM.YYYY HH:mm:ss')
    const code = get(formData, 'id', '')
    const content = get(formData, 'data') || []

    const usePrintView = get(match, 'params.viewMode') === 'print'
    let logoRowButtons
    if (usePrintView) {
      logoRowButtons = (
        <div className="btn-toolbar pull-right">
          <Button onClick={() => window.print()} id-qa-test="btn-print">
            <Icon name="printer" /><FormattedMessage {...messages.print} />
          </Button>
          <Button onClick={() => history.goBack()} id-qa-test="btn-close">
            <Icon name="close" /><FormattedMessage {...messages.close} />
          </Button>
        </div>
      )
    }
    const heading = (
      <Heading
        message={messages.heading}
        logoRowContent={logoRowButtons}
        print={usePrintView}
        titleExtras={usePrintView}
      >
        {!usePrintView &&
          <Link to={`/${FORMS_ROUTE_PATH}/${CUSTOMS_INFORMATION_FORM_ROUTE_PATH}`}>
            <Button className="pull-right" id-qa-test="button-exit" aria-label={formatMessage(messages.close)}>
              <Icon name="close" /><FormattedMessage {...messages.close} />
            </Button>
          </Link>
        }
      </Heading>
    )

    return (
      <div>
        {heading}
        <main className="container" role="main" id="main">
          <Row>
            <Col xs={12}>
              {!usePrintView && <InfoArea
                icon="checkmark"
              >
                <FormattedMessage {...messages.questionReceived} values={{ code }} />
              </InfoArea>}
              <ErrorBoundary>
                <PanelNavigation
                  title={{
                    ...messages.summaryTitle,
                    values: {
                      datetime: dateFormatted,
                      code,
                    },
                  }}
                  active
                >
                  <Grid fluid>
                    <Row>
                      <Col xs={12}>
                        {content.map(item => <div>
                          <div><b>{item.title}</b></div>
                          <div>{item.value}<br /><br /></div>
                        </div>)}
                      </Col>
                    </Row>
                  </Grid>
                  {!usePrintView && <ButtonToolbar>
                    <Link active={false} to={`/${FORMS_ROUTE_PATH}/${CUSTOMS_INFORMATION_FORM_ROUTE_PATH}`}>
                      <Button
                        bsStyle="default"
                        id-qa-test="button-return"
                      >
                        <Icon name="chevron-left" /> <FormattedMessage {...messages.return} />
                      </Button>
                    </Link>
                    <Link
                      to={`/${FORMS_ROUTE_PATH}/${CUSTOMS_INFORMATION_FORM_ROUTE_PATH}/${SUMMARY_ROUTE_PATH}/print`}
                    >
                      <Button
                        bsStyle="default"
                        className="pull-right"
                        id-qa-test="button-print"
                      >
                        <Icon name="printer" /> <FormattedMessage {...messages.printing} />
                      </Button>
                    </Link>
                  </ButtonToolbar>}
                </PanelNavigation>
              </ErrorBoundary>
            </Col>
          </Row>
        </main>
      </div>
    )
  }
}
