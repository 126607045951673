import React from 'react'
import { find, isEmpty, isEqual, includes, get, result, has } from 'lodash'
import { FormattedMessage } from 'react-intl'
import { Col, Row, Well, Button } from 'react-bootstrap'
import { Form } from 'react-form'
import ErrorBoundary from 'src/components/ErrorBoundary'
import ReactMarkdown from 'react-markdown'
import Icon from 'src/components/Icon'
import Loader from 'src/components/Loader'
import Modal from 'src/components/Modal'
import PanelNavigation from 'src/components/PanelNavigation'
import showNotification from 'src/utils/notifications'
import classNames from 'classnames'
import { focusFirstElementByIdOrName } from 'src/utils'
import { PERMIT_USER_TYPES, selectedAuthorizationHasRole, getUid, isApplicantPerson } from 'src/utils/auth'
import Heading from 'src/layout/Heading'
import styles from 'src/layout/heading.scss'
import {
  AUTH_TYPES,
  FEATURES,
} from 'src/constants'
import { SENDING_ACCEPTED, SENDING_TRIED } from '../AcceptTermsCheckbox'
import PermitPageContext from './context'
import ExitPermitButton, { ExitPrintPermitButton, ExitWithoutSavingButton, exitPermit } from './ExitPermitButton'
import PermitDraftNavigationLayout from './PermitDraftNavigationLayout'
import Permit from './Permit'
import {
  getReferenceNumberAndVersionFromRoute,
  fetchDecisionFile,
  getAdjacentStepPath,
  encodeReferenceNumber,
  companySelectionChanged,
  handleApplicationExistsError,
  isRejoinder,
} from '../../utils'
import {
  getApplicationDate,
  fetchPermitData,
  fetchCodesets,
  modifyStructure,
  parseApplicationData,
} from './permitManager'
import mapPermitApplication from '../../permitMapper'
import {
  checkInfoElementsInGroup,
  getFormDefaultValues,
  modifyFormValues,
  validateGoodsToBePlacedUnderProcedure,
} from '../../permitManager'
import messages from '../../messages'
import {
  INIT_ROUTE_PATH,
  APPLICATION_TYPE_NEW,
  APPLICATION_TYPE_AMENDMENT,
  APPLICATION_TYPE_RENEW,
  ERROR_TYPES,
  PERMIT_TYPES_REQUIRING_TERMS_ACCEPTANGE,
  APPLICATION_TYPE_REVOCATION,
  APPLICATION_TYPE_REJOINDER,
  APPLICATION_TYPE_MAP,
  SSA,
} from '../../constants'
import {
  PERMITS_ROUTE_PATH,
  PERMIT_ROUTE_PATH,
  PERMIT_APPLICATION_ROUTE_PATH,
  PERMIT_APPLICATION_STATUS_DRAFT,
  PERMIT_APPLICATION_STATUS_REGISTERED,
  PERMIT_APPLICATION_STATUS_VERIFICATION,
  PERMIT_APPLICATION_STATUS_ACCEPTED,
  PERMIT_APPLICATION_STATUS_NOT_ACCEPTED,
  PERMIT_APPLICATION_STATUS_WITHDRAWN,
  PERMIT_APPLICATION_STATUS_NON_FAVOURABLE,
} from '../../../constants'
import api from '../../../api'
import { hasRepresentativeIdentification } from '../../permitHelper'
import { transformArrayIntoObject } from '../../../../../utils/utils'

function userHasAdminAuthorization() {
  return selectedAuthorizationHasRole(PERMIT_USER_TYPES.admin)
}

function userHasHandlerAuthorization() {
  return selectedAuthorizationHasRole(PERMIT_USER_TYPES.handler)
}

function userIsAuthorizedToCopyApplication() {
  return userHasAdminAuthorization() || userHasHandlerAuthorization()
}

const defaultState = {
  initialValues: null,
  structure: null,
  customerHasEori: false,
  representativeHasEori: false,
  representativeHasAEO: false,
  customerHasAEO: false,
  activeGuarantee: null,
  fetchingActiveGuarantee: false,
  doneInitializingPage: false,
  errorFetchingActiveGuarantee: false,
  errorInitializingPage: false,
  errorFetchingCustomer: false,
  errorCustomerNotFound: false,
  redirectRequested: false,
  pageLoaderVisible: false,
  pageLoaderMessage: null,
  isFetchingFile: false,
  formModified: false,
  showApplicationExistsModal: false,
}

export default class PermitPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      ...defaultState,
    }
    this.submittingStepState = false
    this.setPageLoader = this.setPageLoader.bind(this)
    this.fetchDecision = this.fetchDecision.bind(this)
    this.stepSubmitHandler = this.stepSubmitHandler.bind(this)
    this.saveDraft = this.saveDraft.bind(this)
    this.sendApplication = this.sendApplication.bind(this)
    this.connectFormApi = this.connectFormApi.bind(this)
    this.cancelApplication = this.cancelApplication.bind(this)
    this.copyApplication = this.copyApplication.bind(this)
    this.fixApplication = this.fixApplication.bind(this)
  }

  componentDidMount() {
    this.initialize(this.props.locale)
  }

  // eslint-disable-next-line
  initializeYPAPermit(wantedLocale) {
    const {
      applicant,
      fetchCustomerInformation,
      history,
      helpTextLocale,
      locale,
      saveInitialValues,
      representative,
      match,
    } = this.props

    const authorizationOrDecisionHolderIdentification = applicant.authorizationOrDecisionHolderIdentification

    this.setPageLoader(true)
    if (helpTextLocale !== wantedLocale) {
      setTimeout(() => {
        this.initializeYPAPermit(wantedLocale)
      }, 10)
      return
    }

    this.setState({
      ...defaultState,
    })

    if (!includes([PERMIT_APPLICATION_ROUTE_PATH, PERMIT_ROUTE_PATH], match.params.permitPath)) {
      history.replace(`/${PERMITS_ROUTE_PATH}`)
    } else if (match.params.permitPath === PERMIT_APPLICATION_ROUTE_PATH) {
      fetchCustomerInformation(applicant.id)
        .then((res) => {
          if (isEmpty(res)) {
            if (res?.error?.code === 'customer.notFound') {
              this.setState({ errorCustomerNotFound: true })
            } else {
              this.setState({ errorFetchingCustomer: true })
            }
            return
          }

          const { hasEori, AEOCertificateType } = res
          if (hasEori || AEOCertificateType) {
            this.setState({
              customerHasEori: hasEori,
              customerHasAEO: !!AEOCertificateType,
            })
          }

          const { referenceNumber, version } = getReferenceNumberAndVersionFromRoute(this.props.match)
          const encodedReferenceNumber = encodeReferenceNumber(referenceNumber)

          api.fetchPermitTypes().then(permitTypes => this.setState({ permitTypes }))

          api.fetchPermitApplication(encodedReferenceNumber, version)
            .then((applicationData) => {
              const applicationDateISO = getApplicationDate(applicationData)
              if (!applicationDateISO) {
                this.setState({ errorInitializingPage: true })
                return
              }

              /* Fetch current representativeId if delegate user */
              if (representative?.id) {
                this.getCurrentRepresentativeInformation()
              }

              const permitRepresentativeIdentifier = hasRepresentativeIdentification(applicationData)

              if (permitRepresentativeIdentifier) {
                this.getPermitRepresentativeInformation(permitRepresentativeIdentifier)
              }

              this.setState({ fetchingActiveGuarantee: true, applicationDate: applicationDateISO })
              api.fetchActiveGuarantee(authorizationOrDecisionHolderIdentification)
                .then((activeGuarantee) => {
                  if (isEmpty(activeGuarantee)) {
                    return api.fetchActiveGuaranteeApplication(authorizationOrDecisionHolderIdentification)
                  }
                  return { activeGuarantee }
                })
                .then((guaranteeData) => {
                  const hasActiveGuarantee = has(guaranteeData, 'activeGuarantee')
                  this.setState({
                    activeGuarantee: hasActiveGuarantee && guaranteeData.activeGuarantee,
                    guaranteeApplication: !hasActiveGuarantee && guaranteeData,
                    errorFetchingActiveGuarantee: false,
                    fetchingActiveGuarantee: false,
                  })
                })
                .catch((err) => {
                  this.setState({ fetchingActiveGuarantee: false, errorFetchingActiveGuarantee: true })
                  throw err
                })
              api.fetchPermitStructure(applicationData.applicationTypeCode, applicationDateISO)
                .then(structure =>
                  fetchCodesets(this.props.fetchNonCachedCodesets, structure, applicationDateISO)
                    .then((codesets) => {
                      const modifiedCodesetResponse = transformArrayIntoObject(codesets)
                      const modifiedStructure = modifyStructure(
                        structure,
                        applicationData,
                        {
                          locale,
                          helpTexts: this.props.helpTexts,
                          customerHasAEO: !!AEOCertificateType,
                          authType: this.props.auth.selectedAuthorizationTypes,
                          codesets: modifiedCodesetResponse,
                          applicationDate: applicationDateISO,
                        }
                      )

                      const parsedApplicationData = parseApplicationData(applicationData, modifiedStructure)
                      return fetchPermitData(parsedApplicationData, authorizationOrDecisionHolderIdentification)
                        .then(permitData => ({ permitData, parsedApplicationData }))
                    }))
                .then(({ permitData, parsedApplicationData }) => {
                  saveInitialValues(parsedApplicationData.initialValues)

                  this.setState({
                    ...parsedApplicationData,
                    relatedPermit: get(permitData, 'permitData[0]', null),
                    doneInitializingPage: true,
                    applicationState: applicationData.state,
                  })
                })
                .catch((err) => {
                  this.setState({
                    errorInitializingPage: true,
                  })
                  throw err
                })
            })
            .catch(() => {
              this.setState({
                doneInitializingPage: true,
                errorInitializingPage: true,
                pageLoaderVisible: false,
              })
            })
        })
    }
  }

  // eslint-disable-next-line
  initializePrivatePermit(wantedLocale) {
    const {
      applicant,
      history,
      helpTextLocale,
      locale,
      saveInitialValues,
      match,
    } = this.props

    const authorizationOrDecisionHolderIdentification = applicant.authorizationOrDecisionHolderIdentification

    this.setPageLoader(true)
    if (helpTextLocale !== wantedLocale) {
      setTimeout(() => {
        this.initialize(wantedLocale)
      }, 10)
      return
    }

    this.setState({
      ...defaultState,
    })

    if (!includes([PERMIT_APPLICATION_ROUTE_PATH, PERMIT_ROUTE_PATH], match.params.permitPath)) {
      history.replace(`/${PERMITS_ROUTE_PATH}`)
    } else if (match.params.permitPath === PERMIT_APPLICATION_ROUTE_PATH) {
      const { referenceNumber, version } = getReferenceNumberAndVersionFromRoute(match)
      const encodedReferenceNumber = encodeReferenceNumber(referenceNumber)

      api.fetchPermitTypes().then(permitTypes => this.setState({ permitTypes }))

      api.fetchPermitApplication(encodedReferenceNumber, version)
        .then((applicationData) => {
          const applicationDateISO = getApplicationDate(applicationData)
          if (!applicationDateISO) {
            this.setState({ errorInitializingPage: true })
            return
          }

          this.setState({ fetchingActiveGuarantee: true, applicationDate: applicationDateISO })
          api.fetchPermitStructure(applicationData.applicationTypeCode, applicationDateISO)
            .then(structure =>
              fetchCodesets(this.props.fetchNonCachedCodesets, structure, applicationDateISO)
                .then((codesets) => {
                  const modifiedCodesetResponse = transformArrayIntoObject(codesets)
                  const modifiedStructure = modifyStructure(
                    structure,
                    applicationData,
                    {
                      locale,
                      helpTexts: this.props.helpTexts,
                      customerHasAEO: false,
                      authType: this.props.auth.selectedAuthorizationTypes,
                      codesets: modifiedCodesetResponse,
                      applicationDate: applicationDateISO,
                    }
                  )

                  const parsedApplicationData = parseApplicationData(applicationData, modifiedStructure)
                  return fetchPermitData(parsedApplicationData, authorizationOrDecisionHolderIdentification)
                    .then(permitData => ({ permitData, parsedApplicationData }))
                }))
            .then(({ permitData, parsedApplicationData }) => {
              saveInitialValues(parsedApplicationData.initialValues)

              this.setState({
                ...parsedApplicationData,
                relatedPermit: get(permitData, 'permitData[0]', null),
                doneInitializingPage: true,
                applicationState: applicationData.state,
              })
            })
            .catch((err) => {
              this.setState({
                errorInitializingPage: true,
              })
              throw err
            })
        })
        .catch(() => {
          this.setState({
            doneInitializingPage: true,
            errorInitializingPage: true,
            pageLoaderVisible: false,
          })
        })
    }
  }

  // eslint-disable-next-line react/sort-comp
  getPermitRepresentativeInformation(id) {
    const {
      fetchPermitRepresentativeInformation,
    } = this.props

    fetchPermitRepresentativeInformation(id).then(() => {
      const { customer } = this.props
      if (isEmpty(customer.permitRepresentativeInformation || [])) {
        if (customer.permitRepresentativeError?.code === 'customer.notFound') {
          this.setState({ errorPermitRepresentativeNotFound: true })
        } else {
          this.setState({ errorPermitFetchingRepresentative: true })
        }
      }
    })
  }

  getCurrentRepresentativeInformation() {
    const {
      representative,
      fetchRepresentativeInformation,
    } = this.props

    fetchRepresentativeInformation(representative.id).then(() => {
      const { customer } = this.props

      if (isEmpty(customer.representativeInformation || [])) {
        if (customer.representativeError?.code === 'customer.notFound') {
          this.setState({ errorRepresentativeNotFound: true })
        } else {
          this.setState({ errorFetchingRepresentative: true })
        }
        return
      }

      const {
        hasEori: representativeHasEori,
        AEOCertificateType: representatiAEOCertificateType,
      } = customer.representativeInformation[representative.id]
      if (representativeHasEori || representatiAEOCertificateType) {
        this.setState({
          representativeHasEori,
          representativeHasAEO: !!representatiAEOCertificateType,
        })
      }
    })
  }

  componentDidUpdate(prevProps, prevState) {
    const { isPerson, match } = this.props
    const { structure, redirectRequested, applicationState, initialValues } = this.state
    const permitTypeCode = get(structure, 'code')
    const hasOnboardingContent = isPerson ? has(this.props.helpTexts, `onboarding.header_${permitTypeCode}_person`) : has(this.props.helpTexts, `onboarding.header_${permitTypeCode}`)
    if (structure &&
      match.params.pathId === INIT_ROUTE_PATH &&
      structure.groups.length > 0 &&
      !redirectRequested &&
      permitTypeCode &&
      initialValues
    ) {
      if (!FEATURES.PERMITS_ONBOARDING ||
        !hasOnboardingContent ||
        applicationState !== PERMIT_APPLICATION_STATUS_DRAFT ||
        parseInt(get(initialValues, 'applicationType.applicationType', 1), 10) !== APPLICATION_TYPE_NEW
      ) {
        this.redirectToFirstPage()
      }
    }

    if (companySelectionChanged(prevProps, this.props)) {
      prevProps.history.replace(`/${PERMITS_ROUTE_PATH}`)
    }

    const permitId = prevProps.match?.params?.permitId
    const nextPermitId = this.props.match?.params?.permitId

    // If we are moving to another permitId in the same route or locale changes, initialize
    if ((permitId && nextPermitId && permitId !== nextPermitId) || (prevProps.locale !== this.props.locale)) {
      this.initialize(this.props.locale)
    }
  }

  componentWillUnmount() {
    this.props.destroyState()
  }

  getAdditionalDataForApplication() {
    const { structure, applicationDate } = this.state

    return {
      customerName: this.props.auth.name,
      structure,
      codesets: this.props.codeset,
      applicationDate,
    }
  }

  /**
   * Handle step change from form submit (not from a link or direct url change).
   */
  stepSubmitHandler(data) {
    const { match, history } = this.props
    const { structure } = this.state
    const currentGroup = match.params.pathId
    const validationStatus = validateGoodsToBePlacedUnderProcedure(data, structure, currentGroup)

    if (!validationStatus) {
      showNotification({
        level: 'error',
        message: messages.stepErrors,
        modal: true,
        title: messages.errorModalTitle,
      })
      return
    }

    const modifiedValues = modifyFormValues(data, this.getAdditionalDataForApplication())
    const errors = checkInfoElementsInGroup(modifiedValues, structure, currentGroup)

    if (!isEmpty(errors)) {
      Object.entries(errors).forEach(([key, fieldErrors]) => {
        if (!this.formApi.getError()) {
          this.formApi.setError(key, fieldErrors[0])
        }
      })

      focusFirstElementByIdOrName(Object.keys(errors))
      return
    }
    this.submittingStepState = true
    this.formApi.setAllValues(modifiedValues)
    this.submittingStepState = false
    history.push(getAdjacentStepPath(structure, match.params, 1))
  }

  async sendApplication() {
    const { formApi } = this
    const { locale, intl: { formatMessage, messages: messageData }, match, isPerson, applicant, representative } = this.props
    const { structure } = this.state
    this.setPageLoader(true)
    const formState = formApi.getFormState()
    const modifiedValues = modifyFormValues(formState.values, this.getAdditionalDataForApplication())
    const mappedPermitApplication = mapPermitApplication(modifiedValues, structure, locale.toUpperCase(), applicant, representative)
    const { referenceNumber, version } = getReferenceNumberAndVersionFromRoute(match)

    if (!isPerson && PERMIT_TYPES_REQUIRING_TERMS_ACCEPTANGE.includes(structure.code)) {
      const isAccepted = formApi.getValue(SENDING_ACCEPTED)
      formApi.setValue(SENDING_TRIED, isAccepted ? undefined : true)
      if (!isAccepted) {
        focusFirstElementByIdOrName([SENDING_ACCEPTED])
        this.setPageLoader(false)
        return null
      }
    }

    try {
      const response = await api.sendApplication(mappedPermitApplication, referenceNumber, version)
        .catch(err => checkSendSuccessOnTimeout(err, referenceNumber, version))

      if (response.errors.length !== 0) {
        response.errors.forEach((err) => {
          const errorMessage = formatMessage({ id: getFieldErrorMessageCode(err, messageData) })
          if (errorMessage && !isEmpty(errorMessage)) {
            formApi.setError(`${err.infoElementCode}.${err.fieldCode}`, errorMessage)
            if (has(err, 'groupIndex')) {
              formApi.setError(`${err.infoElementCode}.${err.fieldCode}-${err.groupIndex}`, errorMessage)
            }
          }
        })

        showNotification({
          level: 'error',
          message: messages.validationErrors,
          modal: true,
          title: messages.errorModalTitle,
        })
        return null
      }

      return response
    } catch (e) {
      showNotification({
        level: 'error',
        message: messages.submitErrors,
        modal: true,
        title: messages.errorModalTitle,
      })
      return null
    } finally {
      this.setPageLoader(false)
    }
  }

  connectFormApi(formApi) {
    this.formApi = formApi
  }

  isPrintMode(props = this.props) {
    return get(props, 'match.params.viewMode') === 'print'
  }

  fetchDecision() {
    fetchDecisionFile({
      permitData: this.state.relatedPermit,
      formatMessage: this.props.intl.formatMessage,
      setLoader: () => this.setState({ isFetchingFile: true }),
      clearLoader: () => this.setState({ isFetchingFile: false }),
    })
  }

  saveDraft(optionalFormData) {
    const { locale, match, applicant, representative } = this.props
    const { structure } = this.state
    const formState = this.formApi.getFormState()
    const values = optionalFormData || formState.values

    this.setPageLoader(true)
    const modifiedValues = modifyFormValues(values, this.getAdditionalDataForApplication())
    const mappedPermitApplication = mapPermitApplication(modifiedValues, structure, locale.toUpperCase(), applicant, representative)
    const { version, referenceNumber } = getReferenceNumberAndVersionFromRoute(match)
    return api.saveDraft(mappedPermitApplication, referenceNumber, version)
      .then(() => {
        this.formApi.setAllValues(modifiedValues)
        this.setPageLoader(false)
        showNotification({
          level: 'success',
          icon: 'checkmark',
          message: messages.savedApplicationDraft,
          autoDismiss: 4, // the notification should be visible for 4 seconds
        })
      })
      .catch((err) => {
        showNotification({
          level: 'error',
          message: messages.savingApplicationDraftErrors,
          modal: true,
          title: messages.errorModalTitle,
        })
        this.setPageLoader(false)
        throw err
      })
  }

  cancelApplication() {
    const { match, history } = this.props
    const { referenceNumber, version } = getReferenceNumberAndVersionFromRoute(match)
    this.setPageLoader(true)
    return api.cancelApplication(referenceNumber, version)
      .then(() => {
        showNotification({
          level: 'success',
          message: messages.applicationCancelled,
          autoDismiss: true,
        })
        history.replace(`/${PERMITS_ROUTE_PATH}`)
        this.setPageLoader(false)
        return null
      })
      .catch((err) => {
        this.setPageLoader(false)
        throw err
      })
  }

  copyApplication() {
    const { structure, initialValues } = this.state
    const { helpTexts, history, match } = this.props
    const { referenceNumber, version } = getReferenceNumberAndVersionFromRoute(match)
    this.setPageLoader(true)
    return api.copyApplication(referenceNumber, version, structure.code)
      .then((response) => {
        if (!has(response, 'applicationTypeCode')) {
          throw new Error('no applicationTypeCode')
        }
        return api.saveDraft(response, response.referenceNumber, response.version)
      })
      .then((response) => {
        // eslint-disable-next-line max-len
        history.replace(`/${PERMITS_ROUTE_PATH}?redirect=${PERMIT_APPLICATION_ROUTE_PATH}/${response.referenceNumber}:${response.version}/${INIT_ROUTE_PATH}`)
      })
      .catch((e) => {
        const applicationTypeNumber = parseInt(result(initialValues, 'applicationType.applicationType'), 10)
        const applicationType = APPLICATION_TYPE_MAP[applicationTypeNumber]

        const applicationExistsError = handleApplicationExistsError(
          applicationType,
          e?.value,
          helpTexts.common,
          {
            error: true,
            errorDetails: {
              level: 'error',
              message: helpTexts.common.copyErrors,
              modal: true,
              title: helpTexts.common.errorModalTitle,
            },
          }
        )

        if (applicationExistsError.error && applicationExistsError.errorDetails) {
          showNotification(applicationExistsError.errorDetails)
        } else if (applicationExistsError.state) {
          this.setState(applicationExistsError.state)
        }
      })
      .finally(() => {
        this.setPageLoader(false)
      })
  }

  fixApplication() {
    const { match } = this.props
    const { referenceNumber, version } = getReferenceNumberAndVersionFromRoute(match)
    this.setPageLoader(true)
    return api.cancelApplication(referenceNumber, version)
      .then(() => this.copyApplication())
  }

  getPanelTitle() {
    const { match, locale, isPerson } = this.props
    const { structure } = this.state

    if (this.isPrintMode()) {
      return ''
    }
    const group = structure && find(structure.groups, { code: match.params.pathId })
    if (group) {
      return group.name[locale]
    }

    const permitTypeCode = get(structure, 'code')
    const onboardingTitle = isPerson ? get(this.props.helpTexts, `onboarding.header_${permitTypeCode}_person`) : get(this.props.helpTexts, `onboarding.header_${permitTypeCode}`)
    if (match.params.pathId === INIT_ROUTE_PATH && onboardingTitle) {
      return onboardingTitle
    }

    return ''
  }

  setPageLoader(value, message = null) {
    this.setState({
      pageLoaderVisible: value,
      pageLoaderMessage: message,
    })
  }

  initialize(locale) {
    if (this.props?.isPerson) {
      this.initializePrivatePermit(locale)
    } else {
      this.initializeYPAPermit(locale)
    }
  }

  redirectToFirstPage() {
    const { history, match } = this.props
    this.setState({ redirectRequested: true })
    const firstVisibleGroup = find(this.state.structure.groups, 'visible')
    history.replace(
      `/${PERMITS_ROUTE_PATH}/${match.params.permitPath}/${match.params.permitId}/${firstVisibleGroup.code}`
    )
  }

  getApplicantName() {
    const { isPerson, selectedAuthorizationObject, applicant, auth } = this.props

    if (isPerson && applicant.type === AUTH_TYPES.private) {
      const uid = getUid(auth)
      return `${selectedAuthorizationObject.name || ''} (${uid})`
    }

    return selectedAuthorizationObject && `${selectedAuthorizationObject.name || ''} (${selectedAuthorizationObject.identifier})`
  }

  renderTitle() {
    const { locale } = this.props
    const { initialValues, structure, relatedPermit, isFetchingFile, permitTypes } = this.state

    const applicationType = parseInt(result(initialValues, 'applicationType.applicationType'), 10)
    const isAmendmentApplication = applicationType === APPLICATION_TYPE_AMENDMENT
    const isRenewalApplication = applicationType === APPLICATION_TYPE_RENEW
    const isRevocationApplication = applicationType === APPLICATION_TYPE_REVOCATION
    const isRejoinderApplication = applicationType === APPLICATION_TYPE_REJOINDER

    let typeCode = structure.code
    if (relatedPermit?.applicationDecisionReference) {
      typeCode = relatedPermit.applicationDecisionReference.attributes.authorizationTypeCode
    } else if (relatedPermit?.authorisationTypeCode) {
      typeCode = relatedPermit.authorisationTypeCode
    }

    const permitType = find(permitTypes, { code: typeCode })
    const permitTypeName = has(permitType, `name.${locale}`) ? permitType.name[locale] : '-'
    const name = `${permitTypeName} (${typeCode})`
    const issueNumber = initialValues?.issueNumber?.issueNumber

    if (get(initialValues, 'applicationTypeCode') || get(initialValues, 'authorisationTypeCode')) {
      return (
        <Row>
          <Col xs={12} sm={6}>
            <strong className={styles.permitName}>
              <span id-qa-test="label-title-permit-name">
                {name}
                {issueNumber && `, ${issueNumber}`}
              </span>
            </strong><br />
            <span id-qa-test="label-title-applicant-name">
              {this.getApplicantName()}
            </span>
          </Col>
          {(isAmendmentApplication || isRenewalApplication || isRevocationApplication || isRejoinderApplication) &&
            <Col xs={12} sm={6} className="text-right">
              <strong>
                {isAmendmentApplication && <FormattedMessage {...messages.amendmentApplicationRelatedToDecision} />}
                {isRenewalApplication && <FormattedMessage {...messages.renewalApplicationRelatedToDecision} />}
                {isRevocationApplication && <FormattedMessage {...messages.revokeApplicationRelatedToDecision} />}
                {isRejoinderApplication && <FormattedMessage {...messages.rejoinderApplicationRelatedToDecision} />}
              </strong>
              {` ${get(initialValues, 'referenceNumber.referenceNumber')}`}
              {isFetchingFile && <Loader inline tiny />}
              {relatedPermit && relatedPermit.hasPdf && !isFetchingFile && <Button
                bsStyle="link"
                onClick={this.fetchDecision}
                id-qa-test="btn-openPermitPdf"
              >
                <Icon name="pdf" />
              </Button>}
            </Col>}
        </Row>
      )
    }

    return <div />
  }

  redirectToApplication = (referenceNumber, version) => {
    // eslint-disable-next-line max-len
    this.props.history.replace(`/${PERMITS_ROUTE_PATH}/${PERMIT_APPLICATION_ROUTE_PATH}/${referenceNumber}:${version}/${INIT_ROUTE_PATH}`)
  }

  redirectToExistingApplication = () => {
    const { existingApplicationReferenceNumber, existingApplicationVersion } = this.state
    if (existingApplicationReferenceNumber && existingApplicationVersion) {
      this.redirectToApplication(existingApplicationReferenceNumber, existingApplicationVersion)
    } else {
      // should not happen
    }
  }

  render() {
    const {
      auth,
      children,
      customer,
      locale,
      isUploadingAttachment,
      history,
      helpTexts,
      applicant,
      fetchCustomerInformation,
      fetchRepresentativeInformation,
      intl: { formatMessage },
      showApplicationTabActionButtons,
      isPerson,
      match,
    } = this.props
    const isPrintMode = this.isPrintMode()

    if (match.params.permitPath === PERMIT_ROUTE_PATH) {
      return <Permit
        isPerson={isPerson}
        customer={customer}
        locale={locale}
        helpTexts={helpTexts}
        applicant={applicant}
        fetchCustomerInformation={fetchCustomerInformation}
        fetchRepresentativeInformation={fetchRepresentativeInformation}
        fetchNonCachedCodesets={this.props.fetchNonCachedCodesets}
      />
    }

    const errorMessage = getErrorMessage(this.state, helpTexts)
    if (errorMessage) {
      return (
        <Modal
          show
          showCancel={false}
          continueMessage={messages.continue}
          onClickContinue={() => exitPermit(history)}
          disableEscKey
          locale={locale}
          focusButton
          titleMessage={messages.attention}
        >
          {errorMessage}
        </Modal>
      )
    }

    if (customer.fetchingCustomer) {
      return (
        <Loader
          blocking
          message={{ ...messages.loadingCustomerInformation }}
        />
      )
    } else if (!this.state.doneInitializingPage) {
      return (
        <Loader
          blocking
          message={{ ...messages.loadingApplicationStructure }}
        />
      )
    }

    const {
      applicationState,
      structure,
      customerHasAEO,
      customerHasEori,
      representativeHasAEO,
      representativeHasEori,
      initialValues,
      applicationDate,
      activeGuarantee,
      guaranteeApplication,
      fetchingActiveGuarantee,
      pageLoaderVisible,
      formModified,
      showCancelModal,
      showFixModal,
      showApplicationExistsModal,
      applicationExistsModalText,
      applicationExistsModalContinue,
      applicationExistsModalTitle,
      showContinueButton,
    } = this.state

    let issueNumber
    if (showCancelModal || showFixModal) {
      issueNumber = this.state.initialValues.issueNumber.issueNumber
    }

    const isDraft = PERMIT_APPLICATION_STATUS_DRAFT === applicationState
    const showCancelAndFixApplication = includes([
      PERMIT_APPLICATION_STATUS_REGISTERED,
      PERMIT_APPLICATION_STATUS_VERIFICATION,
      PERMIT_APPLICATION_STATUS_ACCEPTED,
    ], applicationState) &&
      userHasAdminAuthorization() && showApplicationTabActionButtons

    const showCopyApplication = includes([
      PERMIT_APPLICATION_STATUS_NOT_ACCEPTED,
      PERMIT_APPLICATION_STATUS_WITHDRAWN,
      PERMIT_APPLICATION_STATUS_NON_FAVOURABLE,
    ], applicationState) &&
      userIsAuthorizedToCopyApplication() && showApplicationTabActionButtons


    const NavigationLayout = isDraft ? PermitDraftNavigationLayout : NoNavigation

    const additionalData = {
      businessId: applicant && applicant.id,
      customerHasAEO,
      customerName: auth.name,
    }

    const contextData = {
      applicationDate,
      applicationState,
      initialValues,
      structure,
      activeGuarantee,
      guaranteeApplication,
      fetchingActiveGuarantee,
      customerHasEori,
      customerHasAEO,
      representativeHasAEO,
      representativeHasEori,
      setPageLoader: this.setPageLoader,
      pageLoaderVisible,
      sendApplication: this.sendApplication,
      isPrintMode,
      formModified,
    }

    const rejoinder = isRejoinder(initialValues)

    return (
      <Form
        defaultValues={getFormDefaultValues(initialValues, additionalData, structure)}
        onSubmit={this.stepSubmitHandler}
        onChange={(newState, formApi) => {
          const { values, errors: oldErrors } = formApi.getFormState()
          const formChangedByHuman = !this.submittingStepState
            && !isEqual(newState.values, values)

          /*
          We dont really clear infoElement-based errors especially in conditionally rendered elements.
          Check if there are old errors and if they have been cleared since last trigger
          Still a bit TODO since validatiion is kinda rabbithole
          */
          if (oldErrors && values.applicationTypeCode === SSA) {
            const currentGroup = match.params.pathId
            const modifiedValues = modifyFormValues(newState.values, this.getAdditionalDataForApplication())
            const errors = checkInfoElementsInGroup(modifiedValues, structure, currentGroup)

            if (isEmpty(errors)) {
              formApi.setFormState({
                ...newState,
                errors: {},
              })
            }
          }

          if (formChangedByHuman) {
            this.setState({ formModified: true })
          }
        }}
        getApi={this.connectFormApi}
        render={formApi => (
          <PermitPageContext.Provider value={{ formApi, ...contextData }}>
            {this.state.pageLoaderVisible && <Loader
              blocking
              message={this.state.pageLoaderMessage || messages.savingApplicationDraft}
              containerStyle={{ position: 'fixed' }}
            />}
            {isUploadingAttachment && <Loader
              blocking
              message={{ ...messages.uploadingAttachment }}
              containerStyle={{ position: 'fixed' }}
            />}
            <Modal
              show={showCancelModal}
              showCancel
              cancelMessage={messages.cancelModalCancel}
              showContinue
              titleMessage={formatMessage({ id: '/permits/cancelModalTitle' }, { issueNumber })}
              continueMessage={formatMessage({ id: '/permits/cancelModalProceed' })}
              cancelDisabled={pageLoaderVisible}
              continueDisabled={pageLoaderVisible}
              onClickContinue={this.cancelApplication}
              onClickCancel={() => this.setState({ showCancelModal: false })}
              loading={pageLoaderVisible}
              focusDisableButton
            >
              <div id="dialog-message">
                <ReactMarkdown
                  source={issueNumber ? formatMessage({ id: '/permits/cancelModalMessage' }, { issueNumber }) : ''}
                />
              </div>
            </Modal>
            <Modal
              show={showFixModal}
              showCancel
              cancelMessage={messages.cancelModalCancel}
              showContinue
              titleMessage={formatMessage({ id: '/permits/fixModalTitle' }, { issueNumber })}
              continueMessage={formatMessage({ id: '/permits/fixModalProceed' })}
              cancelDisabled={pageLoaderVisible}
              continueDisabled={pageLoaderVisible}
              onClickContinue={this.fixApplication}
              onClickCancel={() => this.setState({ showFixModal: false })}
              loading={pageLoaderVisible}
              focusDisableButton
            >
              <div id="dialog-message">
                <ReactMarkdown
                  source={issueNumber ? formatMessage({ id: '/permits/fixModalMessage' }, { issueNumber }) : ''}
                />
              </div>
            </Modal>
            <Modal
              show={showApplicationExistsModal}
              titleMessage={applicationExistsModalTitle}
              continueMessage={applicationExistsModalContinue}
              cancelMessage={get(helpTexts, 'common.applicationExistsModalCancel')}
              onClickCancel={() => this.setState({
                showApplicationExistsModal: false,
              })}
              onClickContinue={this.redirectToExistingApplication}
              locale={locale}
              focusDisableButton
              showContinue={showContinueButton}
              focusButton
            >
              <ReactMarkdown
                source={applicationExistsModalText || ''}
              />
            </Modal>
            {isDraft ?
              <Heading
                message={`${formatMessage(messages.applicationDraft)}: ${formatMessage(getApplicationTypeHeadingMessage(initialValues))}`}
                wrapHeadingContent
              >
                <div className={classNames(styles.headingContainer, styles.headingActionButtons, styles.wrapHeading)}>
                  <SaveDraftButton
                    onClick={() => this.saveDraft().then(() =>
                      setTimeout(() => exitPermit(history), 100)
                    )}
                  />
                  <ExitWithoutSavingButton className={styles.adaptiveButton} />
                </div>
              </Heading>
              :
              <Heading
                message={formatMessage(getApplicationTypeHeadingMessage(initialValues))}
                usePrintView={isPrintMode}
                logoRowContent={isPrintMode && <ExitPrintPermitButton title={messages.exit} />}
              >
                {!isPrintMode &&
                  <div className={styles.headingActionButtons}>
                    <ExitPermitButton title={rejoinder ? messages.exitRejoinder : messages.exit} />
                    {showCancelAndFixApplication &&
                      <React.Fragment>
                        <Button
                          key="btnCancelApplication"
                          bsStyle="default"
                          onClick={() => this.setState({ showCancelModal: true })}
                          id-qa-test="button-cancel-application"
                        >
                          <Icon name="delete" />
                          <FormattedMessage {...rejoinder ? messages.cancelRejoinder : messages.cancelApplication} />
                        </Button>
                        <Button
                          key="btnFixApplication"
                          bsStyle="default"
                          onClick={() => this.setState({ showFixModal: true })}
                          id-qa-test="button-fix-application"
                        >
                          <Icon name="edit" />
                          <FormattedMessage {...rejoinder ? messages.fixRejoinder : messages.fixApplication} />
                        </Button>
                      </React.Fragment>
                    }
                    {showCopyApplication &&
                      <Button
                        key="btnCopyApplication"
                        bsStyle="default"
                        onClick={() => this.copyApplication()}
                        id-qa-test="button-copy-application"
                      >
                        <Icon name="document-refresh" />
                        <FormattedMessage {...messages.copyApplication} />
                      </Button>
                    }
                  </div>
                }
              </Heading>
            }
            <main className="container" id="main">
              {isPrintMode
                ? this.renderTitle()
                : (
                  <Well bsSize="small" className="has-icon">
                    <Icon xlg name="service-luvat" className="well-icon" />
                    {this.renderTitle()}
                  </Well>
                )
              }
              <NavigationLayout
                formatMessage={formatMessage}
                structure={structure}
                locale={locale}
                container={this}
              >
                <ErrorBoundary>
                  <PanelNavigation title={this.getPanelTitle()} active>
                    {React.Children.map(children, child =>
                      React.cloneElement(child, {
                        formApi,
                        initialValues,
                        structure,
                        customerHasAEO,
                        representativeHasAEO,
                        customerHasEori: this.state.customerHasEori,
                        representativeHasEori: this.state.representativeHasEori,
                        applicationState,
                        activeGuarantee: this.state.activeGuarantee,
                        guaranteeApplication: this.state.guaranteeApplication,
                        saveDraft: this.saveDraft,
                        setPageLoader: this.setPageLoader,
                        pageLoaderVisible: this.state.pageLoaderVisible,
                        isPrintMode,
                      })
                    )}
                  </PanelNavigation>
                </ErrorBoundary>
              </NavigationLayout>
            </main>
          </PermitPageContext.Provider>
        )}
      />
    )
  }
}

async function checkSendSuccessOnTimeout(e, referenceNumber, version) {
  const isTimeout = e.name === 'ServerTimeoutError'

  if (isTimeout) {
    const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))
    const pollTimeoutMs = 10000
    await sleep(pollTimeoutMs)

    const permitApplication = await api.fetchPermitApplication(referenceNumber, version)

    if (permitApplication.state === 'REGISTERED') {
      return permitApplication
    }
  }

  throw e
}


function SaveDraftButton({ onClick }) {
  return (
    <Button
      className={styles.adaptiveButton}
      onClick={onClick}
      id-qa-test="button-save-draft"
    >
      <Icon name="save" />
      <FormattedMessage {...messages.saveAndClose} />
    </Button>
  )
}

function getFieldErrorMessageCode(err, messageData) {
  // Temporary error message for guaranteeGRN field specifically
  if (!has(err, 'errorCode') && err.fieldCode === 'guaranteeGRN') {
    return messages.checkGRNNumber
  }
  const commonMessageCode = `/permits/fields/${ERROR_TYPES[err.errorCode]}`
  const fieldMessageCode = `${commonMessageCode}-${err.fieldCode}`
  return has(messageData, fieldMessageCode)
    ? fieldMessageCode
    : commonMessageCode
}

function getErrorMessage(state, helpTexts) {
  if (state.errorInitializingPage) {
    return <p><FormattedMessage {...messages.initializeError} /></p>
  } else if (state.errorCustomerNotFound) {
    return (
      <ReactMarkdown
        source={helpTexts.common.missingCustomerModalText ? helpTexts.common.missingCustomerModalText : ''}
        linkTarget="_blank"
      />
    )
  } else if (state.errorFetchingCustomer) {
    return <p><FormattedMessage {...messages.fetchCustomerError} /></p>
  }
  return null
}

function getApplicationTypeHeadingMessage(initialValues, isDraft) {
  const applicationType = parseInt(result(initialValues, 'applicationType.applicationType'), 10)
  switch (applicationType) {
  case APPLICATION_TYPE_AMENDMENT:
    return messages.headingAmendmentApplication
  case APPLICATION_TYPE_RENEW:
    return messages.headingRenewalApplication
  case APPLICATION_TYPE_REVOCATION:
    return messages.headingRevokeApplication
  case APPLICATION_TYPE_REJOINDER:
    return messages.headingRejoinderApplication
  default:
    return isDraft ? messages.headingNewApplication : messages.headingApplication
  }
}

function NoNavigation({ children }) {
  return <React.Fragment>{children}</React.Fragment>
}
