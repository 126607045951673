import React, { useCallback, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { injectIntl, FormattedMessage } from 'react-intl'
import { Button } from 'react-bootstrap'
import classNames from 'classnames'

import { formatUTCdateStringIntoFinnishFormat } from 'src/utils'
import styles from 'src/styles/_tables.scss'
import Table, { DESCENDING } from 'src/components/Table/Table'
import ErrorBoundary from 'src/components/ErrorBoundary'
import Loader from 'src/components/Loader'
import InfoArea from 'src/components/InfoArea'
import Icon from 'src/components/Icon'

import repliesTabStyles from './repliesTabStyles.scss'
import {
  REJOINDER_DEFAULT_SORT_BY,
} from '../constants'
import { PERMITS_ROUTE_PATH, PERMIT_APPLICATION_ROUTE_PATH, PERMIT_ROUTE_PATH, PERMIT_STATUS_DRAFT } from '../../constants'
import { INIT_ROUTE_PATH } from '../../permit/constants'
import messages from '../messages'
import api from '../../api'
import { isApplication } from '../utils'
import DeleteApplicationModal from '../../permit/components/DeleteApplicationModal'
import { focusElementById } from '../../../../utils/index'

function RepliesTab({ // TODO fix name?
  selectedOrganizationEori,
  storeStateInRedux,
  formatRowData,
  decisionProposalsAndRejoindersPromise,
  locale,
  history,
  intl: { formatMessage },
}) {
  const [fetchingRows, setFetchingRows] = useState(true)
  const [errorFetchingRows, setErrorFetchingRows] = useState(null)
  // const [list, setList] = useState([])
  const [filteredList, setFilteredList] = useState([])
  const [selectedRowId, setSelectedRowId] = useState(null)

  const [selectedDeleteDraftReferenceNumber, setSelectedDeleteDraftReferenceNumber] = useState(null)
  const [selectedDeleteDraftVersion, setSelectedDeleteDraftVersion] = useState(null)

  let containerRef

  useEffect(() => {
    fetchRows(selectedOrganizationEori)
    setTimeout(() => window.addEventListener('click', handleGlobalClick), 0)
    return () => {
      storeStateInRedux({
        selectedRowId: selectedRowId || null,
      })
      window.removeEventListener('click', handleGlobalClick)
      containerRef = null
    }
  }, [selectedOrganizationEori, locale])

  function fetchRows(organizationEori, forceFetch = false) {
    setFetchingRows(true)
    setErrorFetchingRows(null)
    const eori = organizationEori || selectedOrganizationEori
    const promise = forceFetch ? api.fetchRepliesAndProposals(eori) : decisionProposalsAndRejoindersPromise || api.fetchRepliesAndProposals(eori)

    promise.then((result) => {
      const formattedRows = result.map(row => formatRowData(row, result))
      setFilteredList(formattedRows)
    }).catch((fetchError) => {
      if (fetchError && fetchError.id) {
        setErrorFetchingRows(fetchError)
      } else if (fetchError) {
        setErrorFetchingRows(messages.permitsListFetchError)
      }
    }).finally(() => setFetchingRows(false))
  }

  const onRowClick = useCallback(({ row }) => {
    // If focus is on delete icon, we dont want to open draft with enter key
    if (document.activeElement?.id.includes('deleteIcon-')) {
      return
    }

    if (row && row.referenceNumber) {
      selectRow(row.referenceNumber)
      history.push(
        // eslint-disable-next-line max-len
        `/${PERMITS_ROUTE_PATH}/${isApplication(row.state) ? PERMIT_APPLICATION_ROUTE_PATH : PERMIT_ROUTE_PATH}/${row.referenceNumber}:${row.version || 0}/${INIT_ROUTE_PATH}`
      )
    }
  }, [setSelectedRowId])

  function handleGlobalClick(event) {
    // Remove row activation when user clicks outside this component
    if (selectedRowId && containerRef && !containerRef.contains(event.target)) {
      selectRow(null)
    }
  }

  function selectRow(id) {
    setSelectedRowId(id)
  }

  function showDeleteApplicationModal(event, rowData) {
    event.stopPropagation()
    setSelectedDeleteDraftReferenceNumber(rowData.id)
    setSelectedDeleteDraftVersion(rowData.version)
  }

  function hideDeleteApplicationModal(selectedId = null) {
    // If we cancel the deletion of the draft, we focus the delete icon on correct row
    if (selectedId) {
      focusElementById(`deleteIcon-${selectedId}`)
    }

    setSelectedDeleteDraftReferenceNumber(null)
    setSelectedDeleteDraftVersion(null)
  }

  function createFormatters() {
    return {
      registeredDate: (row, property) => (row[property] ? formatUTCdateStringIntoFinnishFormat(row[property]) : '-'),
      deleteDraft: (row) => {
        if (row.state !== PERMIT_STATUS_DRAFT) {
          return ' '
        }
        return <Button
          bsStyle="link"
          className={classNames('btn-row btn-default', styles.deleteButton)}
          onClick={event => showDeleteApplicationModal(event, row)}
          id-qa-test={`btn-delete-draft-${row.id}`}
          id={`deleteIcon-${row.id}`}
        >
          <Icon name="delete" />
          <span className={'sr-only'}>{formatMessage(messages.actionsColumnScreenReaderLabel)}</span>
        </Button>
      }
      ,
    }
  }

  const noRows = filteredList.size === 0

  const headingProperties = ['referenceNumber', 'type', 'name', 'registeredDate', 'statusName', 'deleteDraft']

  return <div
    id="permit-tab-reply-tabpanel"
    className="panel"
    role="tabpanel"
    aria-labelledby="permit-tab-reply-tab"
    ref={(container) => { containerRef = container }}
  >
    {selectedDeleteDraftReferenceNumber && selectedDeleteDraftVersion !== null &&
      <DeleteApplicationModal
        referenceNumber={selectedDeleteDraftReferenceNumber}
        version={selectedDeleteDraftVersion}
        onClickContinue={() => {
          hideDeleteApplicationModal()
          fetchRows(selectedOrganizationEori, true)
        }}
        onClickCancel={() => hideDeleteApplicationModal(selectedDeleteDraftReferenceNumber)}
      />
    }
    <div
      className={classNames(
        'panel-body',
        'panel-navigation',
        !fetchingRows && filteredList.length === 0 && repliesTabStyles.repliesTabNavigation,
        noRows && styles.verticalAlignmentForChildren
      )}
    >
      <ErrorBoundary>
        {fetchingRows && (
          <div>
            <Loader blocking message={messages.loadingPermits} />
          </div>
        )}
        {errorFetchingRows && (
          <div className="text-danger text-center">
            <FormattedMessage {...errorFetchingRows} />
          </div>
        )}
        {(!fetchingRows && !errorFetchingRows) &&
          <React.Fragment>
            {filteredList.length > 0 ?
              <Table
                headings={[
                  { value: formatMessage(messages.columnLabelPermitOrApplicationId) },
                  { value: formatMessage(messages.columnLabelType) },
                  { value: formatMessage(messages.columnLabelPermitName) },
                  { value: formatMessage(messages.columnLabelRegistered) },
                  { value: formatMessage(messages.columnLabelStatus) },
                  { value: formatMessage(messages.columnLabelActions), hide: true },
                ]}
                contentRows={filteredList}
                properties={headingProperties}
                formatters={createFormatters()}
                onRowClick={onRowClick}
                sortable
                sortByColumn={REJOINDER_DEFAULT_SORT_BY}
                sortByDirection={DESCENDING}
                className={styles.rowPadding}
              />
              :
              <InfoArea
                extraClasses={[repliesTabStyles.repliesTabInfoArea]}
              >
                <FormattedMessage {...messages.noDecisionProposalsFound} />
              </InfoArea>
            }
          </React.Fragment>
        }
      </ErrorBoundary>
    </div>
  </div>
}


const mapStateToProps = (state) => {
  const {
    selectedRowId,
    showOnlyActivePermits,
  } = state.permits.list

  return {
    companies: state.auth.authorizations,
    selectedRowId,
    showOnlyActivePermits,
  }
}

export default withRouter(
  connect(mapStateToProps, undefined)(
    injectIntl(RepliesTab)
  )
)
