import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { injectIntl } from 'react-intl'
import { get } from 'lodash'
import CustomerInformation from '../components/CustomerInformation'
import { getSelectedAuthorization } from '../../../utils/auth'
import { getApplicant } from '../../../routes/permits/permit/permitHelper'

const mapStateToProps = (state, props) => {
  const customer = get(state, 'customer')

  return {
    auth: state.auth,
    applicant: getApplicant(),
    customerInformation: customer.customerInformation,
    fetchingCustomer: customer.fetchingCustomer,
    selectedAuthorizationObject: getSelectedAuthorization(),
    values: props?.formApi?.values || props?.values || {},
  }
}

export default withRouter(
  connect(mapStateToProps)(
    injectIntl(
      CustomerInformation
    )))
