import { Component } from 'react'
import { defineMessages } from 'react-intl'
import { isBrowserInternetExplorer } from 'src/utils'
import { isFunction } from 'lodash'
import { withRouter } from 'react-router-dom'
import { getStepPath, getDeclarationIdFromRoute, pathIsAFormStep } from '../utils'
import { HEADERS_ROUTE_PATH } from '../constants'
import { INTRASTAT_ROUTE_PATH } from '../../constants'

export const messages = defineMessages({
  dirtyFormExitWarning: {
    id: 'form.dirtyFormExitWarning',
    description: 'Warning text for user trying to exit incomplete form page',
    defaultMessage: 'Are you sure you want to leave this page? Form is not completed.',
  },
})

/**
 * Common base for form steps, including submit from route change.
 *
 * Submit flow:
 *  1. handleSubmit (redux-form)
 *     - runs validations
 *  2. stepSubmitHandler
 *     - handles step change and next location
 *  3. onSubmit (thunk action)
 *     - actual submit action for sending data to server
 *  4. routerWillLeave
 *     - checks if submit is done before route change, calls handleSubmit if not
 */
export class EditStepBase extends Component {
  constructor(props) {
    super(props)
    this.isRouteChangeAllowed = this.isRouteChangeAllowed.bind(this)
    this.routerWillLeave = this.routerWillLeave.bind(this)
    this.UNSAFE_componentWillMount = this.UNSAFE_componentWillMount.bind(this)
    this.leavingFromSubmit = false
    this.stepSubmitHandler = this.stepSubmitHandler.bind(this)
    this.pageWillUnload = this.pageWillUnload.bind(this)
    this.throttledUnload = this.throttledUnload.bind(this)
    this.showConfirmationDialog = this.showConfirmationDialog.bind(this)
    this.exitAction = this.exitAction.bind(this)
  }

  /**
   * Update current step when form component mounts
   */
  UNSAFE_componentWillMount() {
    if (this.props.onStepChange && this.props.thisStep && this.props.thisStep !== this.props.currentStep) {
      this.props.onStepChange(this.props.thisStep)
    }
  }

  /**
   * Set route leave hook when component has mounted to make sure we catch page transition before it happens.
   *
   * Only if form is pristine, automatically leave to first step without a confirmation.
   */
  componentDidMount() {
    const declarationId = getDeclarationIdFromRoute(this.props)
    if (this.props.pristine && !declarationId && this.props.thisStep !== HEADERS_ROUTE_PATH) {
      const headersPath = getStepPath(HEADERS_ROUTE_PATH, declarationId)
      this.props.history.push(headersPath)
    } else if (isBrowserInternetExplorer()) {
      this.defaultOnBeforeUnload = window.onbeforeunload
      window.onbeforeunload = this.throttledUnload
    }
  }

  componentWillUnmount() {
    if (isBrowserInternetExplorer()) {
      window.onbeforeunload = this.defaultOnBeforeUnload
    } else {
      window.removeEventListener('beforeunload', this.pageWillUnload)
    }
    if (isFunction(this.removeLeaveHook)) {
      this.removeLeaveHook()
    }
  }

  /**
   * Override in subclass if you want special exit action for that subclass
   */
  exitAction() {
    if (this.props.id && this.props.isNew) {
      return this.props.showPreserveDraftOnExitModalAction
    }
    return () => this.props.history.push(`/${INTRASTAT_ROUTE_PATH}`)
  }

  /**
   * Override in subclass if submit location is conditional
   */
  // eslint-disable-next-line class-methods-use-this
  resolveSubmitLocation(location, declarationId) {
    return location
  }

  /**
   * Handle step change from form submit (not from a link or direct url change).
   */
  stepSubmitHandler(data, dispatch, props, nextLocationPath) {
    if (this.isRouteChangeAllowed()) {
      this.leavingFromSubmit = true
      return this.props.onSubmit(data, dispatch, props)
        .then((declaration) => {
          let declarationId = declaration && declaration.declarationId
          if (!declarationId) {
            declarationId = getDeclarationIdFromRoute(this.props)
          }
          const nextStepPath = getStepPath(props.nextStep, declarationId)
          let nextLocationPathWithDeclarationId = nextLocationPath
          if (declarationId && nextLocationPathWithDeclarationId) {
            nextLocationPathWithDeclarationId = nextLocationPathWithDeclarationId.replace('/new/', `/${declarationId}/`)
          }
          this.props.history.push(this.resolveSubmitLocation(nextLocationPathWithDeclarationId || nextStepPath, declarationId))
          return declaration
        })
    }
    return Promise.reject()
  }

  isRouteChangeAllowed() {
    return this.props.valid
  }

  showConfirmationDialog() {
    if (this.props.deleted || this.props.accepted) {
      return false
    }
    if (this.props.dirty) {
      return true
    }
    return false
  }

  /**
   * Routing leave hook for handling moving between form steps.
   * If moving to another form step without submitting first (e.g. using link or url), submit form before route change.
   * If moving outside of this form wizard and form is dirty, ask for confirmation.
   *
   * @param nextLocation - the next router location object containing property 'pathname'
   * @returns {boolean|string} true if route change is allowed, false if blocked and string for confirmation message
   */
  routerWillLeave(nextLocation) {
    const wasLeavingFromSubmit = this.leavingFromSubmit
    const { formatMessage } = this.props.intl
    this.leavingFromSubmit = false
    if (nextLocation && pathIsAFormStep(nextLocation.pathname)) {
      if (nextLocation.action === 'REPLACE') {
        return true
      }
      if (wasLeavingFromSubmit) {
        // Leaving form step after submitting - simple route change
        return this.isRouteChangeAllowed()
      }
      // Trying to leave form step without submitting, validate and submit now and prevent this route change.
      this.props.handleSubmit(
        (data, dispatch, props) => this.stepSubmitHandler(data, dispatch, props, nextLocation.pathname)
      )()
      return false
    } else if (this.showConfirmationDialog()) {
      // Leaving the form - show confirmation dialog
      return formatMessage(messages.dirtyFormExitWarning)
    }
    return true
  }

  /**
   * Beforeunload event handler for browser leaving without involving react router. E.g. page refresh.
   */
  pageWillUnload(event) {
    const routeLeaveResult = this.routerWillLeave()
    if (!routeLeaveResult) {
      /**
       * NOTE on IE:
       * Must call event.preventDefault, but ONLY when dialog should not be shown.
       */
      event.preventDefault()
    }
    if (typeof routeLeaveResult === 'string') {
      /**
       * NOTE on Chrome:
       * Must have event.returnValue, but ONLY with message strings.
       * Dialog will not be shown without it, regardless of return value.
       */
      // eslint-disable-next-line no-param-reassign
      event.returnValue = routeLeaveResult
      return routeLeaveResult
    }
    /**
     * NOTE on IE:
     * Must not return routeLeaveResult on true/false, or boolean value will be shown on dialog
     */
    return undefined
  }

  /**
   * IE will trigger multiple unload events, this seems to be by design.
   * Throttle to only handle the first one with a dialog and use default behavior for subsequent events.
   */
  throttledUnload(event) {
    window.onbeforeunload = this.defaultOnBeforeUnload
    setTimeout(() => { window.onbeforeunload = this.throttledUnload }, 100)
    return this.pageWillUnload(event)
  }
}

export default withRouter(EditStepBase)