import { memoize } from 'lodash'
import { getStore } from 'src/store'
import { post, put, patch } from 'src/utils/apiBase'

const getTmIntrastatUrl = memoize(() => getStore().getState().config.bootstrapConfig.tm_intrastat_ext_url)

const api = {
  createNewDeclarationVersion: (declarationId, copyRows) =>
    post(`${getTmIntrastatUrl()}/declarations/${declarationId}/version?copyRows=${copyRows}`),
  invalidateOldDeclarationVersion: (payload, declarationId) =>
    patch(
      `${getTmIntrastatUrl()}/declarations/${declarationId}`,
      payload
    ),
  removeTdpFromDeclaration: (payload, declarationId) =>
    put(
      `${getTmIntrastatUrl()}/declarations/${declarationId}`,
      payload
    ),
}

export default api
