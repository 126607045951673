import React from 'react'
import { Grid, Col, Row } from 'react-bootstrap'
import { get, find } from 'lodash'
import styles from './Summary.scss'

export default function SummaryContent(props) {
  const {
    formData,
    cmsMessages,
  } = props

  function renderContentByName(name) {
    const content = get(formData, 'data') || []
    const item = find(content, { id: name })
    if (!get(item, 'value')) {
      return null
    }
    return (
      <div className={styles.infoWrapper}>
        <b>{get(item, 'title')}</b>
        {get(item, 'value')}
      </div>
    )
  }
  const companyInformationHeading = cmsMessages['/forms/location-information/companyInformationHeading']
  const changeReasonHeading = cmsMessages['/forms/location-information/changeReasonHeading']
  const contactInformationHeading = cmsMessages['/forms/location-information/contactInformationHeading']
  return (
    <Grid fluid>
      <Row>
        <Col xs={12}>
          <h3>{companyInformationHeading}</h3>
        </Col>
      </Row>
      <Row className={styles.informationRow}>
        <Col xs={12}>
          {renderContentByName('companyName')}
        </Col>
      </Row>
      <Row className={styles.informationRow}>
        <Col xs={12}>
          {renderContentByName('customerId')}
        </Col>
      </Row>
      <Row className={styles.informationRow}>
        <Col xs={12}>
          {renderContentByName('eori')}
        </Col>
      </Row>
      <Row className={styles.informationRow}>
        <Col xs={12}>
          {renderContentByName('address')}
        </Col>
      </Row>
      <Row className={styles.informationRow}>
        <Col xs={12}>
          {renderContentByName('zipCode')}
        </Col>
      </Row>
      <Row className={styles.informationRow}>
        <Col xs={12}>
          {renderContentByName('city')}
        </Col>
      </Row>
      <Row className={styles.informationRow}>
        <Col xs={12}>
          {renderContentByName('countryCode')}
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <h3>{changeReasonHeading}</h3>
        </Col>
      </Row>
      <Row className={styles.informationRow}>
        <Col xs={12}>
          {renderContentByName('reason')}
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <h3>{contactInformationHeading}</h3>
        </Col>
      </Row>
      <Row className={styles.informationRow}>
        <Col xs={12}>
          {renderContentByName('contactName')}
        </Col>
      </Row>
      <Row className={styles.informationRow}>
        <Col xs={12}>
          {renderContentByName('contactEmail')}
        </Col>
      </Row>
    </Grid>
  )
}
