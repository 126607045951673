/**
 * @file React Intl translation messages for Intrastat headers page
 * @see {@link https://github.com/yahoo/react-intl}
 */

import { defineMessages } from 'react-intl'

const messages = defineMessages({
  /**
   * Note: Messages property name matches error.code sent from api.
   *       Message id matches intl key in ui translation files.
   */
  requiredField: {
    id: 'api.intrastat.requiredField',
    description: 'Validation error for missing required field value',
    defaultMessage: 'Field is mandatory',
  },
  invalidFieldValue: {
    id: 'api.intrastat.invalidFieldValue',
    description: 'Validation error for invalid field value',
    defaultMessage: 'Value is invalid',
  },
  fieldValueTooLong: {
    id: 'api.intrastat.fieldValueTooLong',
    description: 'Validation error for too long field value',
    defaultMessage: 'Value is too long',
  },
  fieldValueTooShort: {
    id: 'api.intrastat.fieldValueTooShort',
    description: 'Validation error for too short field value',
    defaultMessage: 'Value is too short',
  },
  fieldValueTooLow: {
    id: 'api.intrastat.fieldValueTooLow',
    description: 'Validation error for too low field value',
    defaultMessage: 'Value is too low',
  },
  fieldValueTooHigh: {
    id: 'api.intrastat.fieldValueTooHigh',
    description: 'Validation error for too high field value',
    defaultMessage: 'Value is too high',
  },
  valueMustBeGreaterThanZero: {
    id: 'api.intrastat.valueMustBeGreaterThanZero',
    description: 'Validation error for zero field value, when greater than zero is required',
    defaultMessage: 'Value must be greater than 0',
  },
  invalidFieldFormat: {
    id: 'api.intrastat.invalidFieldFormat',
    description: 'Validation error for invalid field value format',
    defaultMessage: 'Value format is invalid',
  },
  'referencePeriod.invalid': {
    id: 'api.intrastat.referencePeriod.invalid',
    description: 'Validation error for invalid reference period',
    defaultMessage: 'Reference period is not allowed',
  },
  'reference.invalidFormat': {
    id: 'api.intrastat.reference.invalidFormat',
    description: 'Validation error for invalid reference format',
    defaultMessage: 'Reference format is not allowed',
  },
  'customer.notLiableInPeriod': {
    id: 'api.intrastat.customer.notLiableInPeriod',
    description: 'Validation error for customer not being liable in given period',
    defaultMessage: 'Customer is not liable in this period',
  },
  'customer.notFound': {
    id: 'api.intrastat.customer.notFoundOrNotUsable',
    description: 'Validation error for customer that is either not found or can not be selected',
    defaultMessage: 'Invalid customer',
  },
  'declaration.exists': {
    id: 'api.intrastat.declaration.exists',
    description: 'Validation error for declaration that is similar with existing declaration',
    defaultMessage: 'Similar declaration exists',
  },
  'service.exception': {
    id: 'api.intrastat.serviceException',
    description: 'Error message for unknown service exception caught from intrastat services',
    defaultMessage: 'Unknown service error',
  },
  errorsOnRows: {
    id: 'api.intrastat.errorsOnRows',
    description: 'Error message shown after failed status change to accepted, blocked by errors on rows',
    defaultMessage: 'Errors on rows prevent submission to customs.',
  },
  invalidRowNotSaved: {
    id: 'api.intrastat.invalidRowNotSaved',
    description: 'Error message shown for row that could not be saved because of fatal errors',
    defaultMessage: 'Errors on row prevent row handling.',
  },
  'totalInvoicedAmount.mustBeZero': {
    id: 'api.intrastat.totalInvoicedAmount.mustBeZero',
    description: 'Error message shown when nil declaration has total invoiced amount other than zero',
    defaultMessage: 'The sum of invoice values in nil returns must be zero.',
  },
  'totalInvoicedAmount.invalid': {
    id: 'api.intrastat.totalInvoicedAmount.invalid',
    description: 'Error message shown when non-nil declaration does not have total invoiced amount greater than zero',
    defaultMessage: 'The sum of invoice values must be at least 1.',
  },
  'customer.invalidDeclarantCode': {
    id: 'api.intrastat.customer.invalidDeclarantCode',
    description: 'Error message shown when third declaring party has incorrect declarant code.',
    defaultMessage: 'Declarant code is incorrect for third declaring party.',
  },
  'reportingUnit.notFound': {
    id: 'api.intrastat.reportingUnit.notFound',
    description: 'Error message shown when code of reporting unit does not exist.',
    defaultMessage: 'The code of reporting unit does not exist.',
  },

  // Parametrized errors
  'modeOfTransport.mustBe7': {
    id: 'api.intrastat.modeOfTransport.mustBe7',
    description: 'Error message shown for row that should have modeOfTransport value 7',
    defaultMessage: 'Mode of transport value 7 is required.',
  },
  'modeOfTransport.mustNotBe7': {
    id: 'api.intrastat.modeOfTransport.mustNotBe7',
    description: 'Error message shown for row that should not have modeOfTransport value 7',
    defaultMessage: 'Mode of transport value 7 is not allowed.',
  },
  'modeOfTransport.mustNotBe9': {
    id: 'api.intrastat.modeOfTransport.mustNotBe9',
    description: 'Error message shown for row that should not have modeOfTransport value 9',
    defaultMessage: 'Mode of transport value 9 is not allowed.',
  },
  'modeOfTransport.notAllowed': {
    id: 'api.intrastat.modeOfTransport.notAllowed',
    description: 'Error message shown for row that has incorrect modeOfTransport value',
    defaultMessage: 'Mode of transport value is not allowed.',
  },
  'partnerId.countryCodeMismatch': {
    id: 'api.intrastat.partnerId.countryCodeMismatch',
    description: 'Error message shown for row that has incorrect partner id country code',
    defaultMessage: 'Country code mismatch in partner id.',
  },
  'countryOfOrigin.mustBeFI': {
    id: 'api.intrastat.countryOfOrigin.mustBeFI',
    description: 'Error message shown for row that has incorrect country of origin',
    defaultMessage: 'In export with nature of transaction 21 country of origin must be FI.',
  },
  // Origin and member country must equal with given transaction type
  'country.countryOfOriginMustEqualMemberState': {
    id: 'api.intrastat.country.countryOfOriginMustEqualMemberState',
    description: 'Error message shown when origin and member countries must be equal (for given transactin type)',
    defaultMessage: 'Origin and member country must be equal.',
  },
  'country.dispatchMustNotBeFinland': {
    id: 'api.intrastat.country.dispatchMustNotBeFinland',
    description: 'Error message shown for row that has Finland as country of dispatch',
    defaultMessage: 'Country of dispatch must not be Finland.',
  },
  'country.destinationMustNotBeFinland': {
    id: 'api.intrastat.country.destinationMustNotBeFinland',
    description: 'Error message shown for row that has Finland as country of destination',
    defaultMessage: 'Country of destination must not be Finland.',
  },
  'country.invalidContryPair': {
    id: 'api.intrastat.country.invalidContryPair',
    description: 'Error message shown for row that has incorrect country pair (origin and member)',
    defaultMessage: 'Incorrect country pair of origin and member country.',
  },
  'invoicedAmount.minimumValue': {
    id: 'api.intrastat.invoicedAmount.minimumValue',
    description: 'Error message shown for row that has invoicing amount too large for small consignment',
    defaultMessage: 'Value is below minimum invoiced amount for small consignment.',
  },
  'transactionType.notPermitted': {
    id: 'api.intrastat.transactionType.notPermitted',
    description: 'Error message shown for row that has transaction type not permitted with chosen CN8 code',
    defaultMessage: 'Transaction type is not permitted with chosen CN8 code',
  },
  'rows.invalidRowCount': {
    id: 'api.intrastat.rows.invalidRowCount',
    description: 'Error message shown for declaration that has incorrect amount of rows',
    defaultMessage: 'Invalid amount of rows',
  },
  invalidQuantityForCN8Code: {
    id: 'api.intrastat.invalidQuantityForCN8Code',
    description: 'Error message shown for declaration row that has invalid quantity (net mass)',
    defaultMessage: 'Invalid net mass for given CN8 code',
  },
  'CN8Code.invalid': {
    id: 'api.intrastat.CN8Code.notAllowed',
    description: 'Error message shown for declaration row that has existing but not allowed cn8 code',
    defaultMessage: 'CN8 code not allowed',
  },
  'commodityCode.onlyInExport': {
    id: 'api.intrastat.CN8Code.onlyInExport',
    description: 'Error message shown for import declaration row that has cn8 code available only in export',
    defaultMessage: 'CN8 code not allowed in arrival of goods',
  },

  // NAKKI-2826 Handling for some specific error cases in backend, which before caused
  // 'odottamaton virhe' error messages to UI
  psiCheckFailed: {
    id: 'api.intrastat.exception.psiCheckFailed',
    description: 'Checking the psi of declaration against roles failed',
    defaultMessage: 'Checking the psi of declaration against roles failed.',
  },
  tdpCheckFailed: {
    id: 'api.intrastat.exception.tdpCheckFailed',
    description: 'Checking the tdp of declaration against roles failed',
    defaultMessage: 'Checking the tdp of declaration against roles failed.',
  },
  tcCustomerCallFailed: {
    id: 'api.intrastat.exception.tcCustomerCallFailed',
    description: 'Calling tc_customer failed',
    defaultMessage: 'Calling tc_customer failed.',
  },
  noDeclarationWithGivenId: {
    id: 'api.intrastat.exception.noDeclarationWithGivenId',
    description: 'Declaration was not found with given ID',
    defaultMessage: 'Declaration was not found with given ID.',
  },
  declarationInWrongState: {
    id: 'api.intrastat.exception.declarationInWrongState',
    description: 'Declaration is in wrong state for the operation',
    defaultMessage: 'Declaration is in wrong state for the operation.',
  },
  declarationInWrongStateCancellation: {
    id: 'api.intrastat.exception.declarationInWrongStateCancellation',
    description: 'Declaration is in wrong state for cancellation',
    defaultMessage: 'Declaration is in wrong state for cancellation.',
  },
  newVersionFailedInvalidPeriod: {
    id: 'api.intrastat.exception.newVersionFailedInvalidPeriod',
    description: 'Creating new version failed because of invalid period',
    defaultMessage: 'Creating new version failed because of invalid period.',
  },
  declarationInWrongStateCanNotAddRows: {
    id: 'api.intrastat.exception.declarationInWrongStateCanNotAddRows',
    description: 'Declaration is in wrong state to add rows',
    defaultMessage: 'Declaration is in wrong state to add rows.',
  },
  userNotAllowedToUpdateRowData: {
    id: 'api.intrastat.exception.userNotAllowedToUpdateRowData',
    description: 'User is not allowed to update rowdata',
    defaultMessage: 'User is not allowed to update rowdata.',
  },
  userNotAllowedToInsertOrUpdateDeclaration: {
    id: 'api.intrastat.exception.userNotAllowedToInsertOrUpdateDeclaration',
    description: 'User is not allowed to insert or update declaration',
    defaultMessage: 'User is not allowed to insert or update declaration.',
  },
  declarationDBIdNotAllowedInInsert: {
    id: 'api.intrastat.exception.declarationDBIdNotAllowedInInsert',
    description: 'In insert declaration can not have DB ID set',
    defaultMessage: 'In insert declaration can not have DB ID set.',
  },
  declarationIdentifierNotAllowedInInsert: {
    id: 'api.intrastat.exception.declarationIdentifierNotAllowedInInsert',
    description: 'In insert declaration can not have identifier set',
    defaultMessage: 'In insert declaration can not have identifier set.',
  },
  declarationIdentifierMandatory: {
    id: 'api.intrastat.exception.declarationIdentifierMandatory',
    description: 'Declaration identifier is mandatory',
    defaultMessage: 'Declaration identifier is mandatory.',
  },
  declarationDBIdMandatory: {
    id: 'api.intrastat.exception.declarationDBIdMandatory',
    description: 'Declaration DB ID is mandatory',
    defaultMessage: 'Declaration DB ID is mandatory.',
  },
  declarationPsiMandatory: {
    id: 'api.intrastat.exception.declarationPsiMandatory',
    description: 'Psi is mandatory',
    defaultMessage: 'Psi is mandatory.',
  },
  declarationDBIdAndIdentifierMismatch: {
    id: 'api.intrastat.exception.declarationDBIdAndIdentifierMismatch',
    description: 'Declaration DB ID and identifier point to different declarations',
    defaultMessage: 'Declaration DB ID and identifier point to different declarations.',
  },
  onlyReferenceCanBeUpdatedInCorrecting: {
    id: 'api.intrastat.exception.onlyReferenceCanBeUpdatedInCorrecting',
    description: 'In correcting only reference can be edited',
    defaultMessage: 'In correcting only reference can be edited.',
  },
  declarationNewStatusMandatory: {
    id: 'api.intrastat.exception.declarationNewStatusMandatory',
    description: 'In update status new status is mandatory',
    defaultMessage: 'In update status new status is mandatory.',
  },
  declarationCancellationWrongPeriod: {
    id: 'api.intrastat.exception.declarationCancellationWrongPeriod',
    description: 'Cancellation failed because of invalid period',
    defaultMessage: 'Cancellation failed because of invalid period.',
  },
  declarationNewVersionAlreadyExists: {
    id: 'api.intrastat.exception.declarationNewVersionAlreadyExists',
    description: 'New version already exists',
    defaultMessage: 'New version already exists.',
  },
  referencePeriodMissingOrInvalid: {
    id: 'api.intrastat.exception.referencePeriodMissingOrInvalid',
    description: 'Invalid or missing reference period',
    defaultMessage: 'Invalid or missing reference period.',
  },
  companyIdMandatoryInSearch: {
    id: 'api.intrastat.exception.companyIdMandatoryInSearch',
    description: 'In search company id is mandatory',
    defaultMessage: 'In search company id is mandatory.',
  },
  browseForwardMandatoryWhenSearchIdGiven: {
    id: 'api.intrastat.exception.browseForwardMandatoryWhenSearchIdGiven',
    description: 'In search when search id is given then brows forward is mandatory',
    defaultMessage: 'In search when search id is given then brows forward is mandatory.',
  },
  browseRoleChecksFailed: {
    id: 'api.intrastat.exception.browseRoleChecksFailed',
    description: 'Role checks failed',
    defaultMessage: 'Role checks failed.',
  },
  validateRowInvalidParams: {
    id: 'api.intrastat.exception.validateRowInvalidParams',
    description: 'Invalid parameters for validate operation',
    defaultMessage: 'Invalid parameters for validate operation.',
  },
})

export default messages
