import { isEmpty } from 'lodash'
import logger from 'src/utils/logger'
import {
  InvalidFileSyntaxError,
  ImportValidationError,
} from '../utils'
import messages from './messages'
import { validateRow } from '../routes/rows/validator'
import { syncValidator as validateHeaders } from '../routes/headers/validator'
import parseCSV from './csvParser'
import parseASCII from './asciiParser'

const MAX_IMPORT_ROW_COUNT = 3000

function intlConfirm(formatMessage, confirmationMessage) {
  const { values, ...message } = confirmationMessage
  // eslint-disable-next-line no-alert
  return confirm(
    formatMessage(message, values)
  )
}

function getContentTypeForData(data) {
  const contentArray = data.match(/[^\r\n]+/g)
  if (contentArray[0].includes(';')) {
    return 'csv'
  } else if (/^(KON|OTS|NIM)/.test(contentArray[0])) {
    return 'ascii'
  }
  return undefined
}

function parseAndValidateFile(
  data,
  onlyRows,
  userOrganizationId,
  userOrganizationName,
  resolve,
  reject,
  confirmationCallback,
  getContentTypeForDataFn,
  parsers,
  validateRowFn,
  validateHeadersFn,
  maxRowCount
) {
  let parsedData
  const resultDeclaration = {}
  const resultErrors = {}

  if (!data) {
    reject({ _error: messages.fileReaderError })
    return
  }

  const contentType = getContentTypeForDataFn(data)
  const parser = parsers[contentType]
  if (!parser) {
    reject(new ImportValidationError({
      _error: messages.invalidFileContent,
    }))
    return
  }

  // Parse data
  try {
    parsedData = parser(data, onlyRows, userOrganizationId, userOrganizationName, confirmationCallback)
  } catch (error) {
    logger.error('Error in parsing import file', error)
    if (error instanceof InvalidFileSyntaxError) {
      reject(new ImportValidationError(error.validationErrors))
      return
    }
    if (error instanceof ImportValidationError) {
      reject(error)
      return
    }
    throw error
  }

  // Validate and populate declaration rows
  if (parsedData.rows) {
    if (parsedData.rows.length > maxRowCount) {
      Object.assign(resultErrors, {
        tooManyRows: {
          ...messages.tooManyRows,
          values: {
            maxRowCount,
          },
        },
      })
    } else {
      const rowValidationErrors = {}
      const rows = []
      parsedData.rows.forEach(({ index, ...row }) => {
        const validationErrors = validateRowFn(row, { import: true, onlyFormalityValidation: true })
        if (isEmpty(validationErrors)) {
          rows.push(row)
        } else {
          rowValidationErrors[index] = validationErrors
        }
      })
      if (isEmpty(rowValidationErrors)) {
        resultDeclaration.rows = rows
      } else {
        resultErrors.rows = rowValidationErrors
      }
    }
  }

  if (!onlyRows) {
    resultDeclaration.noDeclarationRows = isEmpty(resultDeclaration.rows)

    // Validate and populate declaration header fields
    const headerValidationErrors = validateHeadersFn(parsedData.headers, { onlyStaticValidation: true })
    if (isEmpty(headerValidationErrors)) {
      Object.assign(resultDeclaration, parsedData.headers, { status: 'RECEIVED', source: 'FILE' })
    } else {
      Object.assign(resultErrors, headerValidationErrors)
    }
  }

  if (isEmpty(resultErrors)) {
    resolve(resultDeclaration)
  } else {
    reject(new ImportValidationError(resultErrors))
  }
}

/**
 * Read file contents asynchronously and parse CSV document.
 */

function importFileImplementation(
  fileReaderCreator,
  parseAndValidateFileFn,
  file,
  onlyRows,
  userOrganizationId,
  userOrganizationName,
  intl
) {
  // Callback used when incorrect field data is found, but can be automatically fixed
  const confirmationCallback = intlConfirm.bind(null, intl.formatMessage)

  const parsers = {
    csv: parseCSV,
    ascii: parseASCII,
  }

  return new Promise((resolve, reject) => {
    if (!file) {
      reject(new ImportValidationError({ _error: messages.noFile }))
      return
    }
    // Only allow files with .csv and .txt extensions, or no extension at all
    if (file.name && file.name.includes('.') && !/^.+(\.csv|\.txt)$/i.test(file.name)) {
      reject(new ImportValidationError({ _error: messages.invalidFileType }))
      return
    }

    const fileReader = fileReaderCreator()
    /* eslint-disable no-param-reassign */
    fileReader.onerror = () => {
      reject(new ImportValidationError({ _error: messages.fileReaderError }))
    }
    fileReader.onload = (event) => {
      parseAndValidateFileFn(
        event.target.result,
        onlyRows,
        userOrganizationId,
        userOrganizationName,
        resolve,
        reject,
        confirmationCallback,
        getContentTypeForData,
        parsers,
        validateRow,
        validateHeaders,
        MAX_IMPORT_ROW_COUNT
      )
    }
    /* eslint-enable no-param-reassign */
    fileReader.readAsText(file)
  })
}


function importFile(file, onlyRows, userOrganizationId, userOrganizationName, intl) {
  return importFileImplementation(
    () => new FileReader(), parseAndValidateFile, file, onlyRows, userOrganizationId, userOrganizationName, intl
  )
}

export {
  importFile as default,
  importFileImplementation,
  parseAndValidateFile,
  getContentTypeForData,
}
