import React from 'react'
import { FormattedMessage, defineMessages } from 'react-intl'
import { FormGroup, ControlLabel } from 'react-bootstrap'
import { withRouter } from 'react-router-dom'
import Radio from 'src/components/form/Radio'
import Select from 'src/components/form/Select'
import Modal from 'src/components/Modal'
import { formatReferencePeriod } from '../utils'


// Header-related intl message definitions
const messages = defineMessages({
  title: {
    id: 'intrastat.copyDeclaration.title',
    description: 'Title for Intrastat copy declaration modal',
    defaultMessage: 'Copy declaration',
  },
  copyWithoutQuantitiesLabel: {
    id: 'intrastat.copyDeclaration.copyWithoutQuantities.label',
    description: 'Label for copying without nomenclature quantities option in declaration copy modal',
    defaultMessage: 'Copy without nomenclature quantities',
  },
  copyWithQuantitiesLabel: {
    id: 'intrastat.copyDeclaration.copyWithQuantities.label',
    description: 'Label for copying with nomenclature quantities option in declaration copy modal',
    defaultMessage: 'Copy with nomenclature quantities',
  },
  referencePeriodLabel: {
    id: 'intrastat.copyDeclaration.referencePeriod.label',
    description: 'Label for reference period in declaration copy modal',
    defaultMessage: 'Declaration reference period',
  },
  continue: {
    id: 'intrastat.copyDeclaration.continue',
    description: 'Label for Intrastat copy declaration modal submit button',
    defaultMessage: 'OK',
  },
  cancel: {
    id: 'intrastat.copyDeclaration.cancel',
    description: 'Label for Intrastat copy declaration modal cancel button',
    defaultMessage: 'Cancel',
  },
  referencePeriodPlaceholder: {
    id: 'intrastat.copyDeclaration.referencePeriod.placeholder',
    description: 'Placeholder for reference period in declaration copy modal',
    defaultMessage: 'Select reference period...',
  },

})

class CopyModal extends React.Component {
  constructor(props) {
    super(props)
    this.onReferencePeriodSelected = this.onReferencePeriodSelected.bind(this)
    this.onCopyWithQuantitiesSelected = this.onCopyWithQuantitiesSelected.bind(this)
    this.onCopyWithoutQuantitiesSelected = this.onCopyWithoutQuantitiesSelected.bind(this)
    this.state = {
      copyRows: true,
      copyQuantities: false,
    }
  }

  onReferencePeriodSelected(event) {
    this.setState({
      referencePeriod: event.target.value,
    })
  }

  onCopyWithQuantitiesSelected() {
    this.setState({
      copyQuantities: true,
    })
  }

  onCopyWithoutQuantitiesSelected() {
    this.setState({
      copyQuantities: false,
    })
  }

  render() {
    const {
      show,
      loading,
      onHide,
      declarationId,
      getReferencePeriods,
      createAndOpenNewCopy,
      history,
    } = this.props

    const referencePeriods = getReferencePeriods()
    return (
      <Modal
        show={show}
        showCancel
        showContinue
        cancelDisabled={loading}
        continueDisabled={loading || !this.state.referencePeriod}
        continueMessage={messages.continue}
        cancelMessage={messages.cancel}
        onClickCancel={onHide}
        onClickContinue={() => createAndOpenNewCopy(
          declarationId, this.state.referencePeriod, this.state.copyRows, this.state.copyQuantities, history
        )}
        loading={loading}
        continueBtnStyle="primary"
        titleMessage={messages.title}
      >
        <FormGroup controlId="referencePeriod">
          <ControlLabel><FormattedMessage {...messages.referencePeriodLabel} /></ControlLabel>
          <div style={{ position: 'relative' }}>
            <Select
              autoFocus
              input={{ value: this.state.referencePeriod, name: 'referencePeriod' }}
              options={referencePeriods}
              onChange={this.onReferencePeriodSelected}
              placeholderMessage={messages.referencePeriodPlaceholder}
              nonExistingValueFormatter={formatReferencePeriod}
            />
          </div>
        </FormGroup>
        <Radio
          name="quantityOption"
          checked={!this.state.copyQuantities}
          onClick={() => this.onCopyWithoutQuantitiesSelected()}
          value="copyWithoutQuantities"
        >
          <FormattedMessage {...messages.copyWithoutQuantitiesLabel} />
        </Radio>
        <Radio
          name="quantityOption"
          checked={this.state.copyQuantities}
          onClick={() => this.onCopyWithQuantitiesSelected()}
          value="copyWithQuantities"
        >
          <FormattedMessage {...messages.copyWithQuantitiesLabel} />
        </Radio>
      </Modal>
    )
  }
}

export default withRouter(CopyModal)
