import React from 'react'
import { Grid } from 'react-bootstrap'
import { get } from 'lodash'
import ReactMarkdown from 'react-markdown'
import InfoArea from 'src/components/InfoArea'
import messages from '../messages'

export default function (props) {
  const { formApi, helpTexts } = props
  const typeCode = get(formApi, 'values.applicationTypeCode')

  const defaultContentText = get(helpTexts, 'kiloInfoText')
  const contentText = get(helpTexts, `kiloInfoText_${typeCode}`, defaultContentText) || defaultContentText

  const liabilityGuaranteeLevel = get(formApi.values, 'guaranteeLevel.liabilityGuaranteeLevel')
  const showInfoArea = liabilityGuaranteeLevel !== 'BA'
    && liabilityGuaranteeLevel !== null
    && contentText !== ''

  return (
    <div>
      {showInfoArea &&
        <Grid fluid>
          <InfoArea title={messages.infoAreaKiLoTitle}>
            <ReactMarkdown
              source={contentText}
              linkTarget="_blank"
            />
          </InfoArea>
        </Grid>
      }
    </div>
  )
}
