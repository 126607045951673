import React from 'react'
import URLSearchParamsPolyfill from 'url-search-params'
import { Grid, Col, Row, Button, ButtonToolbar } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { Form } from 'react-form'
import ReactMarkdown from 'react-markdown'
import { find, get, includes, isString, filter } from 'lodash'
import { collectCmsMessages, focusFirstElementByIdOrName } from 'src/utils'
import Loader from 'src/components/Loader'
import ErrorBoundary from 'src/components/ErrorBoundary'
import PanelNavigation from 'src/components/PanelNavigation'
import CommonFormGroup from 'src/components/form_v2/CommonFormGroup'
import Heading from 'src/layout/Heading'
import { messages as validationMessages } from 'src/utils/validation'
import {
  FORM_TYPE_GROUP,
  CUSTOMER_TYPE_PRIVATE,
  CUSTOMER_TYPE_BUSINESS,
  DEFAULT_PHONE,
  CODE_COMMODITY_CODE,
  CODE_POSTPACKAGE,
  CODE_ALAND,
  CUSTOMS_INFORMATION_FORM_ROUTE_PATH,
  SUMMARY_ROUTE_PATH,
} from '../constants'
import messages from '../messages'
import api from '../../api'

export default class CustomsInformationForm extends React.Component {
  constructor(props) {
    super(props)
    this.formApi = null
    this.state = {
      customerType: CUSTOMER_TYPE_PRIVATE,
      formGroupTypes: null,
      code: null,
      formGroupTypesFetched: false,
      formGroupTypesFetchFailed: false,
      previousFormState: null,
      isSubmitting: false,
      submitFailed: false,
    }
    this.connectFormApi = this.connectFormApi.bind(this)
    this.submitForm = this.submitForm.bind(this)
  }

  componentDidMount() {
    this.setParamsToState()
    this.fetchFormGroupTypes()
    this.updateCmsMessages()
  }

  componentDidUpdate(prevProps) {
    const selectedAuthorizationId = prevProps.selectedAuthorizationObject ?
      prevProps.selectedAuthorizationObject.id
      :
      undefined

    if (prevProps.cmsMessages !== this.props.cmsMessages) {
      this.updateCmsMessages()
      this.setQuestion()
    }
    if (this.props.selectedAuthorizationObject
      && this.props.selectedAuthorizationObject.id !== selectedAuthorizationId) {
      this.formApi.setValue('name', this.props.selectedAuthorizationObject.name)
    }
  }

  setParamsToState() {
    if (!global.URLSearchParams) {
      global.URLSearchParams = URLSearchParamsPolyfill
    }
    const searchParams = new URLSearchParams(window.location.search)
    const customerTypeParam = searchParams.get('type')
    let params = {
      code: searchParams.get('code'),
    }
    if (includes([CUSTOMER_TYPE_PRIVATE, CUSTOMER_TYPE_BUSINESS], customerTypeParam)) {
      params = {
        ...params,
        customerType: customerTypeParam,
      }
    }

    this.setState({
      ...params,
    })
  }

  getDefaultSubject(type) {
    if (isString(this.state.code) && this.state.code === CODE_ALAND) {
      return CODE_ALAND
    }

    const customerType = type || this.state.customerType

    if (customerType === CUSTOMER_TYPE_PRIVATE) {
      return CODE_POSTPACKAGE
    } else if (customerType === CUSTOMER_TYPE_BUSINESS) {
      return CODE_COMMODITY_CODE
    }

    return ''
  }

  getQuestionPlaceholder() {
    const {
      intl: { formatMessage },
    } = this.props
    const selectedSubject = get(this.formApi.getFormState(), 'values.subject')
    if (selectedSubject === CODE_COMMODITY_CODE) {
      return get(this.state.helpTexts, 'common.commodityCodeInfoText')
    }
    return formatMessage(messages.questionFieldPlaceholder)
  }

  getQuestionTitle() {
    const formState = this.formApi.getFormState()
    const selectedSubject = get(formState, 'values.subject')
    return selectedSubject === CODE_POSTPACKAGE ?
      messages.instructionsFieldLabel :
      messages.questionFieldLabel
  }

  setQuestion() {
    const formState = this.formApi.getFormState()
    const selectedSubject = get(formState, 'values.subject')

    if (selectedSubject === CODE_POSTPACKAGE) {
      this.formApi.setValue('question', get(this.state.helpTexts, 'common.postPackageInfoText'))
    } else {
      this.formApi.setValue('question', '')
    }
  }

  updateCmsMessages() {
    this.setState({
      helpTexts: {
        common: collectCmsMessages('/forms/customs-information', this.props.cmsMessages),
        fields: collectCmsMessages('/forms/customs-information/fields', this.props.cmsMessages),
      },
    })
  }

  fetchFormGroupTypes() {
    api.getFormGroupTypes(FORM_TYPE_GROUP)
      .then(response => this.setState({
        formGroupTypes: response,
        formGroupTypesFetched: true,
      }))
      .catch(() => {
        this.setState({
          formGroupTypesFetchFailed: true,
        })
      })
  }

  updateForm() {
    const { previousFormState } = this.state
    const formState = this.formApi.getFormState()
    const selectedSubject = get(formState, 'values.subject')
    const subjectChanged = selectedSubject !== get(previousFormState, 'subject')
    const customerType = get(formState, 'values.customerType')
    const customerTypeChanged = customerType !== get(previousFormState, 'customerType')

    if (subjectChanged) {
      if (selectedSubject !== '') {
        this.formApi.setError('subject', null)
      }
      this.setQuestion()
    }

    if (customerTypeChanged) {
      this.formApi.setValue('subject', this.getDefaultSubject(customerType))
    }

    if (this.state.customerType !== customerType) {
      this.setState({
        customerType,
      })
    }

    if (subjectChanged || customerTypeChanged) {
      this.setState({
        previousFormState: formState.values,
      })
    }
  }

  connectFormApi(formApi) {
    this.formApi = formApi
  }

  submitForm(formData) {
    const {
      locale,
      submitForm,
      intl: { formatMessage },
    } = this.props
    const {
      formGroupTypes,
    } = this.state

    let hasErrors = false
    if (!get(formData, 'email')) {
      this.formApi.setError('email', validationMessages.required)
      hasErrors = true
    }
    if (!get(formData, 'subject')) {
      this.formApi.setError('subject', validationMessages.required)
      hasErrors = true
    }
    if (!get(formData, 'question')) {
      this.formApi.setError('question', validationMessages.required)
      hasErrors = true
    }

    if (hasErrors) {
      return null
    }

    const subject = get(formData, 'subject')
    const selectedSubject = find(formGroupTypes, { id: subject })
    let phone = get(formData, 'phone')
    if (phone === '' || phone === DEFAULT_PHONE) {
      phone = '-'
    }
    const customerType = get(formData, 'customerType')
    let customerTypeText = ''
    if (customerType === 'business') {
      customerTypeText = formatMessage(messages.customerTypeTextBusiness)
    } else {
      customerTypeText = formatMessage(messages.customerTypeTextPrivate)
    }

    const dataArr = [
      {
        id: 'customerTypeText',
        title: formatMessage(messages.customerTypeFieldLabel),
        value: customerTypeText,
      },
      {
        id: 'name',
        title: formatMessage(messages.nameFieldLabel),
        value: get(formData, 'name'),
      },
      {
        id: 'email',
        title: formatMessage(messages.emailFieldLabel),
        value: get(formData, 'email'),
      },
      {
        id: 'phone',
        title: formatMessage(messages.phoneFieldLabel),
        value: phone,
      },
      {
        id: 'subject',
        title: formatMessage(messages.subjectFieldLabel),
        value: get(selectedSubject, `title.${locale}`),
      },
      {
        id: 'question',
        title: formatMessage(messages.questionFieldLabel),
        value: get(formData, 'question'),
      },
    ]

    this.setState({ isSubmitting: true })

    submitForm({
      formType: subject,
      locale,
      data: dataArr,
      customerType,
    }).then(() => {
      this.setState({
        isSubmitting: false,
      })
      this.props.router.push(`/forms/${CUSTOMS_INFORMATION_FORM_ROUTE_PATH}/${SUMMARY_ROUTE_PATH}`)
    }).catch(() => {
      this.setState({
        isSubmitting: false,
        submitFailed: true,
      })
    })

    return null
  }

  render() {
    const {
      authenticatedUserName,
      locale,
      intl: { formatMessage },
    } = this.props
    const {
      formGroupTypes,
    } = this.state

    const isPrivate = this.state.customerType === CUSTOMER_TYPE_PRIVATE
    const permitsHeading = (
      <Heading
        message={null}
      />
    )

    if (!this.state.formGroupTypesFetched && !this.state.formGroupTypesFetchFailed) {
      return (
        <Loader blocking />
      )
    }

    const formGroupTypeOptions = []
    filter(formGroupTypes, (type) => {
      if (isPrivate && includes(type.visibility, 'PRIVATE')) {
        return true
      } else if (!isPrivate && includes(type.visibility, 'BUSINESS')) {
        return true
      }
      return false
    })
      .map((type) => {
        formGroupTypeOptions.push({
          value: type.id,
          title: type.title[locale],
        })
        return null
      })

    return (
      <div>
        {permitsHeading}
        {this.state.isSubmitting && <Loader blocking />}
        <main className="container" role="main" id="main">
          {!this.state.summaryData && <Row>
            <Col xs={12}>
              <ErrorBoundary forceError={this.state.formGroupTypesFetchFailed}>
                <PanelNavigation
                  title={messages.headingPanelCustomsInformationService}
                  active
                >
                  <Grid fluid>
                    <Row>
                      <Col xs={12} md={6}>
                        <Form
                          defaultValues={{
                            name: authenticatedUserName,
                            phone: DEFAULT_PHONE,
                            customerType: this.state.customerType,
                            subject: this.getDefaultSubject(),
                          }}
                          onSubmit={this.submitForm}
                          getApi={this.connectFormApi}
                          render={(formApi) => {
                            this.updateForm()
                            let isPostPackage = false
                            if (get(formApi, 'values.subject') === CODE_POSTPACKAGE) {
                              isPostPackage = true
                            }
                            return (
                              <form
                                onSubmit={(event) => {
                                  event.preventDefault()
                                  formApi.submitForm()
                                    .then(() => {
                                      const errors = get(formApi.getFormState(), 'errors', {})
                                      focusFirstElementByIdOrName(Object.keys(errors))
                                    })
                                }}
                              >
                                <CommonFormGroup
                                  errors={formApi.errors}
                                  formApi={formApi}
                                  formGroupClassname="formElementGutter"
                                  formName="CustomsInformation"
                                  input={{
                                    help: { content: get(this.state.helpTexts, 'fields.customerType', null) },
                                    name: 'customerType',
                                    static: false,
                                    type: 'radioListWithField',
                                    validate: true,
                                    validation: {
                                      mandatory: true,
                                    },
                                    visible: true,
                                    multilineLabel: true,
                                  }}
                                  label={formatMessage(messages.customerTypeFieldLabel)}
                                  options={[{
                                    title: messages.radioSelectionTypePrivate,
                                    value: CUSTOMER_TYPE_PRIVATE,
                                  },
                                  {
                                    title: messages.radioSelectionTypeBusiness,
                                    value: CUSTOMER_TYPE_BUSINESS,
                                  }]}
                                  showLabel
                                  type="BOOLEAN"
                                />
                                <CommonFormGroup
                                  errors={formApi.errors}
                                  formApi={formApi}
                                  formGroupClassname="formElementGutter"
                                  formName="CustomsInformation"
                                  input={{
                                    help: { content: get(this.state.helpTexts, 'fields.name', null) },
                                    name: 'name',
                                    placeholder: formatMessage(messages.nameFieldLabel),
                                    static: true,
                                    type: 'text',
                                    validate: true,
                                    validation: {
                                      strict: true,
                                      mandatory: true,
                                      maxLength: '70',
                                      minLength: '0',
                                    },
                                    visible: true,
                                    multilineLabel: true,
                                  }}
                                  label={formatMessage(messages.nameFieldLabel)}
                                  showLabel
                                  type="TEXT"
                                />
                                <CommonFormGroup
                                  errors={formApi.errors}
                                  formApi={formApi}
                                  formGroupClassname="formElementGutter"
                                  formName="CustomsInformation"
                                  input={{
                                    help: { content: get(this.state.helpTexts, 'fields.email', null) },
                                    name: 'email',
                                    placeholder: formatMessage(messages.emailFieldLabel),
                                    type: 'email',
                                    validate: true,
                                    validation: {
                                      strict: true,
                                      mandatory: true,
                                      maxLength: '70',
                                      minLength: '0',
                                      customErrorMessage: messages.emailValidationError,
                                    },
                                    visible: true,
                                    multilineLabel: true,
                                  }}
                                  label={formatMessage(messages.emailFieldLabel)}
                                  showLabel
                                  type="TEXT"
                                />
                                <CommonFormGroup
                                  errors={formApi.errors}
                                  formApi={formApi}
                                  formGroupClassname="formElementGutter"
                                  formName="CustomsInformation"
                                  input={{
                                    help: { content: get(this.state.helpTexts, 'fields.phone', null) },
                                    name: 'phone',
                                    placeholder: formatMessage(messages.phoneFieldLabel),
                                    type: 'phone',
                                    validate: true,
                                    validation: {
                                      strict: false,
                                      mandatory: false,
                                      maxLength: '20',
                                      minLength: '0',
                                    },
                                    visible: true,
                                    multilineLabel: true,
                                  }}
                                  label={formatMessage(messages.phoneFieldLabel)}
                                  showLabel
                                  type="TEXT"
                                />
                                <CommonFormGroup
                                  errors={formApi.errors}
                                  formApi={formApi}
                                  formGroupClassname="formElementGutter"
                                  formName="CustomsInformation"
                                  input={{
                                    help: { content: get(this.state.helpTexts, 'fields.subject', null) },
                                    name: 'subject',
                                    options: formGroupTypeOptions,
                                    placeholder: formatMessage(messages.subjectFieldLabel),
                                    type: 'select',
                                    validate: true,
                                    validation: {
                                      mandatory: true,
                                      strict: true,
                                    },
                                    visible: true,
                                    multilineLabel: true,
                                  }}
                                  label={formatMessage(messages.subjectFieldLabel)}
                                  showLabel
                                  type="SELECT"
                                />
                                {!isPostPackage && <CommonFormGroup
                                  errors={formApi.errors}
                                  formApi={formApi}
                                  formGroupClassname="formElementGutter"
                                  formName="CustomsInformation"
                                  input={{
                                    help: { content: get(this.state.helpTexts, 'fields.question', null) },
                                    name: 'question',
                                    placeholder: this.getQuestionPlaceholder(),
                                    type: 'textarea',
                                    validate: true,
                                    validation: {
                                      strict: true,
                                      mandatory: true,
                                      maxLength: '2000',
                                      minLength: '0',
                                    },
                                    visible: true,
                                    multilineLabel: true,
                                  }}
                                  label={formatMessage(this.getQuestionTitle())}
                                  showLabel
                                  type="TEXT"
                                  disabled={isPostPackage}
                                />}
                                {isPostPackage && <div>
                                  <b>{formatMessage(this.getQuestionTitle())}</b>
                                  <br />
                                  <ReactMarkdown
                                    source={get(formApi, 'values.question', '')}
                                    linkTarget="_blank"
                                  />
                                </div>}
                                <ButtonToolbar>
                                  <Button
                                    type="submit"
                                    bsStyle="primary"
                                    disabled={isPostPackage}
                                    id-qa-test="button-submit-form"
                                  >
                                    <FormattedMessage {...messages.submit} />
                                  </Button>
                                </ButtonToolbar>
                              </form>
                            )
                          }}
                        />
                      </Col>
                    </Row>
                  </Grid>
                </PanelNavigation>
              </ErrorBoundary>
            </Col>
          </Row>}
        </main>
      </div>
    )
  }
}
