/* global snoobi */
import 'whatwg-fetch'
import URLSearchParamsPolyfill from 'url-search-params'
import React from 'react'
import { createRoot } from 'react-dom/client'

import moment from 'moment'
import '@formatjs/intl-pluralrules/polyfill'
import '@formatjs/intl-pluralrules/locale-data/en'
import '@formatjs/intl-pluralrules/locale-data/fi'
import '@formatjs/intl-pluralrules/locale-data/sv'
import 'react-dates/initialize'

import { createStore } from './store'
import Root from './containers/Root'
import RootError from './containers/RootError'
import {
  getUserData,
  storeBootstrapConfig,
  storeCmsContent,
  switchCmsLocale,
  showGlobalNotification,
  suomiFiAuthorizationTypeRequest,
  sendLogoutRequestAndExit,
  changeReturnUriAfterRoleSwitch,
  switchLocaleAction,
} from './actions'
import {
  getParams,
  getValidLocale,
} from './utils/index'
import {
  isTermsAcceptedInBrowserStorage,
  handleAfterLoginRedirects,
} from './utils/auth'
import { checkHttpStatus, parseJSON } from './utils/http'
import apiMessages from './utils/apiMessages'

import { RETURN_URI_QUERY_PARAM } from './constants'

const ROOT_ELEMENT = document.getElementById('root')
const root = createRoot(ROOT_ELEMENT)

const helpServiceUrl = `${window ? window.location.origin : 'https://localhost:3000'}/asiointipalvelu/palvelut/tm_help`

if (window) {
  window.helpServiceUrl = helpServiceUrl
}

// TODO: Dynamically import and add locales for languages specified in constants?
//       ES6 imports are static, try System.import?
// TODO: Move languages from constants to t2_config and fetch them?

if (!global.URLSearchParams) {
  global.URLSearchParams = URLSearchParamsPolyfill
}
const searchParams = new URLSearchParams(window.location.search)
const queryLocale = searchParams.get('lang') || null

/**
 * NOTE:
 * Navigator.language is the browser/system language, not necessarily editable by the user.
 * Navigator.languages instead contains all languages user specified in browser settings.
 */
// Get initial language from user's browser
const initialStoredLocale = localStorage.getItem('locale')
const browserLocale = navigator.language && navigator.language.split('-')[0]
const initialLocale = getValidLocale(queryLocale, initialStoredLocale, browserLocale)
const urlSearchParams = getParams(window.location)
const loginError = urlSearchParams.get('errorCode') || null
const suomiFiAuthorizationCallback = urlSearchParams.get('suomiFiAuthorizationCallback') || null
const suomiFiAuthorization = urlSearchParams.get('suomiFiAuthorization') || null
const suomiFiAuthorizationError = urlSearchParams.get('suomiFiAuthorizationError') || null
const rejectedAuthorizationsError = urlSearchParams.get('rejectedAuthorizations') || null
const returnUriAfterRoleSwitchParam = urlSearchParams.get(RETURN_URI_QUERY_PARAM) || null
const redirectAfterLoginLocation = localStorage.getItem('redirectAfterLoginLocation')
// We allow the authorization type selector only when logging in,
// redirectAfterLoginLocation value is removed when authorization type is selected
const authorizationTypeSelectorAllowed = suomiFiAuthorization && redirectAfterLoginLocation


if (initialLocale) {
  moment.locale(initialLocale)
}
moment.updateLocale('sv-FI', {
  longDateFormat: {
    L: 'DD.MM.YYYY',
  },
})

// Initial state for redux store
const initialState = {
  locale: initialLocale,
  auth: {},
  ...window.__INITIAL_STATE__, // eslint-disable-line no-underscore-dangle
}

const store = createStore(initialState, history)

// Further token/locale/tos changes in redux store are persisted to browser storage

store.dispatch(switchCmsLocale({ locale: initialLocale }))
store.dispatch(switchLocaleAction(initialLocale))
moment.locale(initialLocale)

const checkReturnUriAfterRoleSwitch = (returnUriAfterRoleSwitch) => {
  if (returnUriAfterRoleSwitch) {
    store.dispatch(changeReturnUriAfterRoleSwitch(returnUriAfterRoleSwitch))
  }
}

let render = (key = null) => {
  if (!authorizationTypeSelectorAllowed && !suomiFiAuthorizationError) {
    handleAfterLoginRedirects(store)
  } else if (suomiFiAuthorizationCallback) {
    handleAfterLoginRedirects(store)
  }

  Promise.all([
    fetch(`${helpServiceUrl}/config/bootstrapConfig`)
      .then(checkHttpStatus)
      .then(parseJSON),
    fetch(`${helpServiceUrl}/config/services`)
      .then(checkHttpStatus)
      .then(parseJSON),
    fetch(`${helpServiceUrl}/component/common`)
      .then(checkHttpStatus)
      .then(parseJSON),
  ])
    .catch((e) => {
      root.render(
        <RootError locale={initialLocale} />,
      )
      throw e
    })
    .then(([bootstrapConfig, services, cmsContentData]) => {
      store.dispatch(storeBootstrapConfig({ bootstrapConfig, ...services }))
      store.dispatch(storeCmsContent({ locale: initialLocale, data: cmsContentData }))

      if (loginError || suomiFiAuthorizationError) {
        const errorMsg = loginError || suomiFiAuthorizationError
        store.dispatch(showGlobalNotification({
          modal: true,
          level: 'error',
          message: apiMessages[errorMsg] || apiMessages.suomiFiAuthorizationFailure,
        }))
      }

      if (suomiFiAuthorizationError) {
        store.dispatch(sendLogoutRequestAndExit())
      }

      if (!authorizationTypeSelectorAllowed && !suomiFiAuthorizationError) {
        // Dispatch getUserData thunk to get all user data from server
        store.dispatch(getUserData(cmsContentData, isTermsAcceptedInBrowserStorage))
      }

      if (authorizationTypeSelectorAllowed) {
        store.dispatch(suomiFiAuthorizationTypeRequest(true))
      }
      checkReturnUriAfterRoleSwitch(returnUriAfterRoleSwitchParam)

      if (rejectedAuthorizationsError) {
        store.dispatch(showGlobalNotification({
          level: 'error',
          message: apiMessages.rejectedAuthorizationsError,
          title: apiMessages.authorizationNoteHeader,
          modal: true,
        }))
      }

      root.render(
        <Root
          store={store}
          routerKey={key}
        />,
      )
    })
}

// Enable HMR and catch runtime errors in RedBox
// This code is excluded from production bundle
if (DEV && module.hot) {
  const RedBox = require('redbox-react').default // eslint-disable-line global-require

  const renderApp = render
  const renderError = (error) => {
    root.render(<RedBox error={error} />)
  }
  render = () => {
    try {
      renderApp(Math.random())
    } catch (error) {
      renderError(error)
    }
  }
}

// All modern browsers, except `Safari`, have implemented
// the `ECMAScript Internationalization API`.
// For that we need to patch in on runtime.
if (!global.Intl) {
  import(/* webpackChunkName: "i18n" */ 'intl').then((module) => {
    module.default // eslint-disable-line
    render()
  })
} else {
  render()
}
