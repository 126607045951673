import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { injectIntl, FormattedMessage } from 'react-intl'
import ReactMarkdown from 'react-markdown'
import { has, includes, get } from 'lodash'
import moment from 'moment'
import classNames from 'classnames'
import { formatUTCdateStringIntoFinnishFormat, collectCmsMessages } from 'src/utils'
import Table, { DESCENDING } from 'src/components/Table/Table'
import styles from 'src/styles/_tables.scss'
import ErrorBoundary from 'src/components/ErrorBoundary'
import Loader from 'src/components/Loader'
import showNotification from 'src/utils/notifications'
import Icon from 'src/components/Icon'
import Modal from 'src/components/Modal'
import commonMessages from '../../permit/messages'
import listMessages from '../messages'
import api from '../../api'
import {
  APPLICATION_DEFAULT_SORT_BY,
  PERMIT_APPLICATION_STATUS_DRAFT,
  PERMIT_APPLICATION_STATUS_REGISTERED,
  PERMIT_APPLICATION_STATUS_VERIFICATION,
  PERMIT_APPLICATION_STATUS_ACCEPTED,
  PERMIT_APPLICATION_STATUS_NOT_ACCEPTED,
  PERMIT_APPLICATION_STATUS_WITHDRAWN,
  PERMIT_APPLICATION_STATUS_NON_FAVOURABLE,
  PERMIT_APPLICATION_STATUS_FAVOURABLE,
} from '../constants'

import { PERMITS_ROUTE_PATH, PERMIT_APPLICATION_ROUTE_PATH } from '../../constants'
import { INIT_ROUTE_PATH } from '../../permit/constants'
import { companySelectionChanged, handleApplicationExistsError } from '../../permit/utils'
import ActionButton from './ActionButton'

function filterData(permits) {
  return permits.filter(permit => !includes([PERMIT_APPLICATION_STATUS_DRAFT], permit.status))
}

class PermitApplicationsTab extends React.Component {
  constructor(props, context) {
    super(props, context)
    this.state = {
      list: [],
      fetchingPermits: false,
      selectedRowId: this.props.selectedRowId,
      actionsExpanded: null,
      modalAction: null,
    }

    this.onRowClick = this.onRowClick.bind(this)
    this.selectRow = this.selectRow.bind(this)
    this.handleGlobalClick = this.handleGlobalClick.bind(this)
    this.fetchRows = this.fetchRows.bind(this)
    this.cancelApplication = this.cancelApplication.bind(this)
    this.copyApplication = this.copyApplication.bind(this)
    this.fixApplication = this.fixApplication.bind(this)
    this.hideActionModal = this.hideActionModal.bind(this)
  }

  componentDidMount() {
    this.fetchRows()
    setTimeout(() => window.addEventListener('click', this.handleGlobalClick), 0)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (companySelectionChanged(this.props, nextProps)) {
      this.fetchRows(nextProps.selectedAuthorizationOrDecisionHolderIdentification)
    }
    if (this.props.locale !== nextProps.locale) {
      this.fetchRows()
    }
  }

  componentWillUnmount() {
    this.props.storeStateInRedux({
      selectedRowId: this.state.selectedRowId || null,
    })
    window.removeEventListener('click', this.handleGlobalClick)
  }

  onRowClick({ row }) {
    // If focus is on action buttons, we dont want to open application with enter key
    if (document.activeElement?.id.includes('btn-action-')) {
      return
    }

    if (row && row.id) {
      this.selectRow(row.id)
      this.props.history.push(
        // eslint-disable-next-line max-len
        `/${PERMITS_ROUTE_PATH}/${PERMIT_APPLICATION_ROUTE_PATH}/${row.id}:${row.version}/${INIT_ROUTE_PATH}`
      )
    }
  }

  storeStateInRedux() {
    this.props.storeListViewState({
      selectedRowId: this.state.selectedRowId || null,
    })
  }

  handleGlobalClick(event) {
    // Remove row activation when user clicks outside this component
    if (this.state.selectedRowId && this.containerRef && !this.containerRef.contains(event.target)) {
      this.selectRow(null)
    }
  }

  async fetchRows(authorizationOrDecisionHolderIdentification) {
    const { selectedAuthorizationOrDecisionHolderIdentification, formatRowData } = this.props

    this.setState({ fetchingPermits: true, errorFetchingRows: null })
    const identification = authorizationOrDecisionHolderIdentification || selectedAuthorizationOrDecisionHolderIdentification

    await api.fetchPermitApplications(identification)
      .then((result) => {
        const formattedRows = []
        result.forEach(row => formattedRows.push(formatRowData(row)))
        return formattedRows
      })
      .then((result) => {
        const filteredList = filterData(result)
        const nextState = {
          list: filteredList,
        }
        this.setState(nextState)
      })
      .catch((fetchError) => {
        if (fetchError && fetchError.id) {
          this.setState({ errorFetchingRows: fetchError })
        } else if (fetchError) {
          this.setState({ errorFetchingRows: listMessages.permitsListFetchError })
        }
      })
      .then(() => this.setState({ fetchingPermits: false }))
  }

  selectRow(id) {
    this.setState({ selectedRowId: id })
  }

  toggleActions(event, hide, id) {
    event.stopPropagation()
    this.setState({ actionsExpanded: hide ? null : id })
  }


  showActionModal(event, rowData, action) {
    event.stopPropagation()
    let modalTitle
    let modalBody
    let modalContinueLabel
    let modalContinueHandler
    switch (action) {
    case `btn-action-cancel-${rowData.id}`:
      modalTitle = { id: '/permits/cancelModalTitle' }
      modalBody = { id: '/permits/cancelModalMessage' }
      modalContinueLabel = { id: '/permits/cancelModalProceed' }
      modalContinueHandler = this.cancelApplication
      break
    case `btn-action-fix-${rowData.id}`:
      modalTitle = { id: '/permits/fixModalTitle' }
      modalBody = { id: '/permits/fixModalMessage' }
      modalContinueLabel = { id: '/permits/fixModalProceed' }
      modalContinueHandler = this.fixApplication
      break
    default:
      break
    }
    this.setState({
      modalAction: action,
      selectedApplicationReferenceNumber: rowData.id,
      selectedApplicationVersion: rowData.version,
      issueNumber: rowData.issueNumber,
      applicationTypeCode: rowData.applicationTypeCode,
      modalTitle,
      modalBody,
      modalContinueLabel,
      modalContinueHandler,
      prevAction: event.target,
    })
  }

  hideActionModal() {
    this.setState({
      modalAction: null,
      selectedDraftReferenceNumber: null,
      selectedDraftVersion: null,
    }, () => setTimeout(() => this.state.prevAction.focus(), 100))
  }

  cancelApplication() {
    const { selectedApplicationReferenceNumber, selectedApplicationVersion } = this.state
    this.setState({ isLoading: true })
    return api.cancelApplication(selectedApplicationReferenceNumber, selectedApplicationVersion)
      .then(() => {
        showNotification({
          level: 'success',
          message: commonMessages.applicationCancelled,
          autoDismiss: true,
        })
        this.setState({
          modalAction: null,
          actionsExpanded: null,
          isLoading: false,
        })
        this.fetchRows().then(() => {
          setTimeout(() => document.getElementById(`row-status-cancel-${selectedApplicationReferenceNumber}`).focus(), 100)
        })
        return null
      })
      .catch((err) => {
        throw err
      })
      .finally(() => {
        this.setState({ isLoading: false })
      })
  }

  copyApplication(event, row) {
    if (event) { event.stopPropagation() }
    const { history } = this.props

    this.setState({ isLoading: true })

    return api.copyApplication(row?.id, row?.version, row?.applicationTypeCode)
      .then((response) => {
        if (!has(response, 'applicationTypeCode')) {
          throw new Error('no applicationTypeCode')
        }
        return api.saveDraft(response, response.referenceNumber, response.version)
      })
      .then((response) => {
        // eslint-disable-next-line max-len
        history.replace(`/${PERMITS_ROUTE_PATH}/${PERMIT_APPLICATION_ROUTE_PATH}/${response.referenceNumber}:${response.version}/${INIT_ROUTE_PATH}`)
        this.setState({ isLoading: false })
      })
      .catch((e) => {
        const applicationExistsError = handleApplicationExistsError(
          row.issueType,
          e?.value,
          this.props.commonTexts,
          {
            error: true,
            errorDetails: {
              level: 'error',
              message: commonMessages.copyErrors,
              modal: true,
              title: commonMessages.errorModalTitle,
            },
          }
        )

        if (applicationExistsError.error && applicationExistsError.errorDetails) {
          showNotification(applicationExistsError.errorDetails)
        } else if (applicationExistsError.state) {
          this.setState(applicationExistsError.state)
        }
      })
      .finally(() => {
        this.setState({ isLoading: false })
      })
  }

  fixApplication() {
    const { selectedApplicationReferenceNumber, selectedApplicationVersion, applicationTypeCode } = this.state
    this.setState({
      modalAction: null,
      actionsExpanded: null,
      isLoading: true,
    })

    return api.cancelApplication(selectedApplicationReferenceNumber, selectedApplicationVersion)
      .then(() => this.copyApplication(null, { id: selectedApplicationReferenceNumber, version: selectedApplicationVersion, applicationTypeCode }))
      .finally(() => this.setState({ isLoading: false }))
  }


  redirectToApplication = (referenceNumber, version) => {
    // eslint-disable-next-line max-len
    this.props.history.replace(`/${PERMITS_ROUTE_PATH}/${PERMIT_APPLICATION_ROUTE_PATH}/${referenceNumber}:${version}/${INIT_ROUTE_PATH}`)
  }

  redirectToExistingApplication = () => {
    const { existingApplicationReferenceNumber, existingApplicationVersion } = this.state
    if (existingApplicationReferenceNumber && existingApplicationVersion) {
      this.redirectToApplication(existingApplicationReferenceNumber, existingApplicationVersion)
    } else {
      // should not happen
    }
  }

  createFormatters() {
    return {
      submitDate: (row, property) => formatUTCdateStringIntoFinnishFormat(row[property]),
      dueDate: (row) => {
        if (row.dueDate &&
          (row.status === PERMIT_APPLICATION_STATUS_VERIFICATION ||
            row.status === PERMIT_APPLICATION_STATUS_ACCEPTED ||
            row.status === PERMIT_APPLICATION_STATUS_REGISTERED)) {
          const locale = this.props.locale
          const dueDate = row.dueDate && formatUTCdateStringIntoFinnishFormat(row.dueDate)
          const dueDateDifference = row.dueDate && moment(row.dueDate).diff(moment(), 'days', true)
          return `${dueDate} (${Math.ceil(dueDateDifference)}${locale === 'fi' ? 'pv' : 'd'})`
        }
        return null
      },
      statusName: (row) => {
        if (row.status === PERMIT_APPLICATION_STATUS_WITHDRAWN) {
          return <span id={`row-status-cancel-${row.id}`} tabIndex="-1">{row.statusName}</span>
        }
        return row.statusName
      },
      actions: (row) => {
        const showActionButtons = row.status !== PERMIT_APPLICATION_STATUS_FAVOURABLE
        if (!showActionButtons) { return null }
        const isOpen = this.state.actionsExpanded === row.id
        const allowCancelOrEdit = includes([PERMIT_APPLICATION_STATUS_REGISTERED, PERMIT_APPLICATION_STATUS_ACCEPTED, PERMIT_APPLICATION_STATUS_VERIFICATION], row.status)
        const allowApplyAgain = includes([PERMIT_APPLICATION_STATUS_NOT_ACCEPTED, PERMIT_APPLICATION_STATUS_WITHDRAWN, PERMIT_APPLICATION_STATUS_NON_FAVOURABLE], row.status)
        return <div>
          <ActionButton
            onClick={event => this.toggleActions(event, isOpen, row.id)}
            id={`btn-action-${isOpen ? 'hide' : 'show'}-${row.id}`}
            ariaProps={{
              'aria-expanded': isOpen,
              'aria-controls': `action-buttons-${row.id}`,
            }}
          >
            {isOpen ?
              <React.Fragment>
                <Icon name="chevron-tight-up" md />
                <FormattedMessage {...listMessages.hideActions} />
              </React.Fragment>
              :
              <React.Fragment>
                <Icon name="chevron-tight-down" md />
                <FormattedMessage {...listMessages.showActions} />
              </React.Fragment>
            }
          </ActionButton>
          <div id={`action-buttons-${row.id}`}>

            {isOpen && allowCancelOrEdit &&
              <React.Fragment>
                <ActionButton
                  id={`btn-action-cancel-${row.id}`}
                  onClick={event => this.showActionModal(event, row, `btn-action-cancel-${row.id}`)}
                >
                  <Icon name="delete" md />
                  <FormattedMessage {...commonMessages.cancelApplication} />
                </ActionButton>
                <ActionButton
                  id={`btn-action-fix-${row.id}`}
                  onClick={event => this.showActionModal(event, row, `btn-action-fix-${row.id}`)}
                >
                  <Icon name="edit" md />
                  <FormattedMessage {...commonMessages.fixApplication} />
                </ActionButton>
              </React.Fragment>
            }
            {isOpen && allowApplyAgain &&
              <ActionButton
                id={`btn-action-copy-${row.id}`}
                onClick={event => this.copyApplication(event, row)}
              >
                <Icon name="document-refresh" md />
                <FormattedMessage {...commonMessages.copyApplication} />
              </ActionButton>
            }
          </div>
        </div>
      },
    }
  }

  render() {
    const {
      list,
      fetchingPermits,
      issueNumber,
      modalAction,
      modalTitle,
      modalBody,
      modalContinueLabel,
      modalContinueHandler,
      isLoading,
      showApplicationExistsModal,
      applicationExistsModalText,
      applicationExistsModalContinue,
      applicationExistsModalTitle,
      showContinueButton,
    } = this.state

    const {
      showApplicationTabActionButtons,
      showApplicationType,
      intl: { formatMessage },
      locale,
      commonTexts,
    } = this.props

    const noRows = list.size === 0

    // remove showApplicationType usage after Q4/2023
    const typeOrCompanyLabel = { value: formatMessage(showApplicationType ? listMessages.columnLabelType : listMessages.columnLabelCompany) }
    const typeOrCompanyProperty = showApplicationType ? 'type' : 'companyName'

    const tableProperties = ['issueNumber', typeOrCompanyProperty, 'name', 'submitDate', 'dueDate', 'statusName']
    const tableHeadings = [
      { value: formatMessage(listMessages.columnLabelApplicationId) },
      typeOrCompanyLabel,
      { value: formatMessage(listMessages.columnLabelPermitName) },
      { value: formatMessage(listMessages.columnLabelDateOfApply) },
      { value: formatMessage(listMessages.columnLabelDeadline) },
      { value: formatMessage(listMessages.columnLabelStatus) },
    ]
    if (showApplicationTabActionButtons) {
      tableHeadings.push({ value: formatMessage(listMessages.columnLabelActions), isSortableHeading: false })
      tableProperties.push('actions')
    }

    return (
      <div ref={(container) => { this.containerRef = container }}>
        <Modal
          show={modalAction !== null}
          titleMessage={{
            ...modalTitle,
            values: {
              issueNumber,
            },
          }}
          showCancel
          cancelMessage={commonMessages.cancelModalCancel}
          showContinue
          continueMessage={modalContinueLabel}
          cancelDisabled={isLoading}
          continueDisabled={isLoading}
          onClickContinue={modalContinueHandler}
          onClickCancel={this.hideActionModal}
          loading={isLoading}
          focusDisableButton
        >
          <div id="dialog-message">
            {modalBody &&
              <ReactMarkdown
                source={formatMessage({ ...modalBody }, { issueNumber })}
              />
            }
          </div>
        </Modal>
        <Modal
          show={showApplicationExistsModal}
          titleMessage={applicationExistsModalTitle}
          continueMessage={applicationExistsModalContinue}
          cancelMessage={get(commonTexts, 'applicationExistsModalCancel')}
          onClickCancel={() => this.setState({
            showApplicationExistsModal: false,
          })}
          onClickContinue={this.redirectToExistingApplication}
          locale={locale}
          focusDisableButton
          showContinue={showContinueButton}
          focusButton
        >
          <ReactMarkdown
            source={applicationExistsModalText}
          />
        </Modal>
        <div
          id="permit-tab-applications-tabpanel"
          className={classNames('panel', showApplicationTabActionButtons && 'permit-tab-applications-table')}
          role="tabpanel"
          aria-labelledby="permit-tab-applications-tab"
        >
          <div
            className={classNames('panel-body', 'panel-navigation', noRows && styles.verticalAlignmentForChildren)}
          >
            <ErrorBoundary>
              {fetchingPermits && (
                <div>
                  <Loader blocking message={listMessages.loadingApplications} />
                </div>
              )}
              {isLoading && (
                <div>
                  <Loader blocking />
                </div>
              )}
              {this.state.errorFetchingRows && (
                <div className="text-danger text-center">
                  <FormattedMessage {...this.state.errorFetchingRows} />
                </div>
              )}
              {(!fetchingPermits && !this.state.errorFetchingRows) &&
                <Table
                  headings={tableHeadings}
                  contentRows={list}
                  properties={tableProperties}
                  formatters={this.createFormatters()}
                  onRowClick={this.onRowClick}
                  sortable
                  sortByColumn={APPLICATION_DEFAULT_SORT_BY}
                  sortByDirection={DESCENDING}
                />
              }
            </ErrorBoundary>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const {
    selectedRowId,
  } = state.permits.list

  const cmsMessages = get(state, 'content.cmsMessages', {})

  return {
    commonTexts: collectCmsMessages('/permits', cmsMessages),
    selectedRowId,
    showApplicationTabActionButtons: get(state, 'config.features.SHOW_APPLICATION_TAB_ACTION_BUTTONS', false),
    showApplicationType: get(state, 'config.features.ALLOW_REVOKE_DECISION', false),
  }
}

export default withRouter(
  connect(mapStateToProps, undefined)(
    injectIntl(PermitApplicationsTab)
  )
)
