import React from 'react'
import { AUTH_TYPES } from 'src/constants'
import { get } from 'lodash'
import ServiceList from './ServiceList'
import messages from '../messages'
import {
  getOrderedAuthenticatedServiceGroups,
  getAuthenticatedServicesByGroup,
  getAllowedServices,
  getDeniedServices,
} from '../serviceListingHelper'
import { getSelectedAuthorization } from '../../../utils/auth'

export default function AuthenticatedFrontpage(props) {
  const { auth, locale, services, serviceGroups, router, intl } = props
  const selectedAuthorization = getSelectedAuthorization()
  const selectedAuthorizationType = get(selectedAuthorization, 'type')
  const orderedAuthenticatedServiceGroups = getOrderedAuthenticatedServiceGroups(serviceGroups)
  const allDeniedServices = []

  return (
    <div id="main-panel-group" className="frontpage-panels">
      {orderedAuthenticatedServiceGroups &&
        orderedAuthenticatedServiceGroups.map((serviceGroup, index) => {
          const servicesInGroup = getAuthenticatedServicesByGroup(services, serviceGroup)
          const allowedServicesInGroup = getAllowedServices(servicesInGroup, auth, selectedAuthorization)
          allDeniedServices.push(...getDeniedServices(servicesInGroup, allowedServicesInGroup))

          if (allowedServicesInGroup.length === 0) {
            return null
          }

          const header = serviceGroup.basicAttributes.find(basicAttr => basicAttr.languageCode === locale).name
          return (
            <div className="service-list-wrapper" key={index}>
              <h2>{header}</h2>
              <ServiceList
                services={allowedServicesInGroup}
                router={router}
                locale={locale}
              />
            </div>
          )
        })
      }
      {allDeniedServices.length > 0 &&
        selectedAuthorizationType === AUTH_TYPES.business &&
        <div className="service-list-wrapper">
          <h2>{intl.formatMessage(messages.internalDeniedFrontpageServices)}</h2>
          <ServiceList
            services={allDeniedServices}
            router={router}
            locale={locale}
          />
        </div>
      }
    </div>
  )
}
