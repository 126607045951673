import React, { useEffect } from 'react'
import Loader from 'src/components/Loader'
import { apiCall } from 'src/utils/http'

import PaymentRoot from '../../components/PaymentRoot'
import {
  PAYMENT_CODE_INTERNAL_ERROR,
  PAYMENT_CODE_PAID,
  INDIVIDUAL_GUARANTEE_ROUTE_PATH,
  PAYMENT_SUCCESS_API_ROUTE,
} from '../../constants'
import styles from './PaymentPending.scss'

function processPayment(props) {
  const {
    location: { search },
    config,
    match: {
      params: { timestamp, checksum, paymentReference, correlationId, guaranteeCategory },
    },
  } = props

  if (search) {
    const paytrailParams = `&${search.substring(1)}`

    let url

    if(guaranteeCategory) {
      url = `${config.tm_guarantee_ext_url}/${PAYMENT_SUCCESS_API_ROUTE}?mrn=` +
      `${correlationId}&timestamp=${timestamp}&checksum=${checksum}&paymentReference=${paymentReference}&guaranteeCategory=${guaranteeCategory}` +
      `${paytrailParams}`
    } else {
      url = `${config.tm_guarantee_ext_url}/${PAYMENT_SUCCESS_API_ROUTE}?mrn=` +
      `${correlationId}&timestamp=${timestamp}&checksum=${checksum}&paymentReference=${paymentReference}` +
      `${paytrailParams}`
    }

    apiCall(url)
      .then((result) => {
        const code = result.status
        const grn = result.grn
        if (code === PAYMENT_CODE_PAID && timestamp && checksum && paymentReference) {
          redirectToSuccess(props, code, timestamp, checksum, grn, correlationId, paymentReference, guaranteeCategory)
        } else {
          redirectToSuccess(props, PAYMENT_CODE_INTERNAL_ERROR, timestamp, checksum,
            grn, correlationId, paymentReference, guaranteeCategory)
        }
      })
      .catch(() => {
        redirectToSuccess(props, PAYMENT_CODE_INTERNAL_ERROR, timestamp, checksum,
          undefined, correlationId, paymentReference, guaranteeCategory)
      })
  }
}

function redirectToSuccess(props, code, timestamp, checksum, grn, correlationId, paymentReference, guaranteeCategory) {
  const { history } = props
  const paymentStatus = code === PAYMENT_CODE_PAID ? 'success' : 'error'
  let path = `/${INDIVIDUAL_GUARANTEE_ROUTE_PATH}?payment=${paymentStatus}`
  if (grn) {
    path = `${path}&grn=${grn}`
  }
  if (correlationId) {
    path = `${path}&correlationId=${correlationId}`
  }
  if (timestamp) {
    path = `${path}&timestamp=${timestamp}`
  }
  if (checksum) {
    path = `${path}&checksum=${checksum}`
  }
  if (paymentReference) {
    path = `${path}&paymentReferenceNumber=${paymentReference}`
  }
  if (guaranteeCategory) {
    path = `${path}&guaranteeCategory=${guaranteeCategory}`
  }
  history.push(path)
}

export default function PaymentPending(props) {
  useEffect(() => {
    processPayment(props)
  }, [])

  const { pendingLoadingText } = props
  return (
    <PaymentRoot>
      <Loader active center />
      <div>
        <p className={styles.text}>{pendingLoadingText}</p>
      </div>
    </PaymentRoot>
  )
}
