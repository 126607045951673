import React from 'react'
import { Route, Switch } from 'react-router-dom'

import {
  CUSTOMS_INFORMATION_FORM_ROUTE_PATH,
  SUMMARY_ROUTE_PATH,
} from './constants'

import CustomsInformationFrontPage from './container'
import CustomsInformationSummaryPage from './routes/summary/index'

export default function CustomsInformationRoutes() {
  return (
    <Switch>
      <Route path={CUSTOMS_INFORMATION_FORM_ROUTE_PATH}>
        <CustomsInformationFrontPage />
      </Route>
      <Route path={`${SUMMARY_ROUTE_PATH}(/:viewMode)`}>
        <CustomsInformationSummaryPage />
      </Route>
    </Switch>
  )
}
