import { defineMessages } from 'react-intl'

export default defineMessages({
  browseHeading: {
    id: 'cn8Browser.heading.browse',
    description: 'Heading for browse tree view CN8 browser component',
    defaultMessage: 'Browse CN8 codes',
  },
  searchHeading: {
    id: 'cn8Browser.heading.search',
    description: 'Heading for text search results view in CN8 browser component',
    defaultMessage: 'CN8 search results',
  },
  searchResultDescription: {
    id: 'cn8Browser.searchResults.description',
    description: 'Description for cn8 browser text search results, including searchText and resultCount',
    defaultMessage: 'Found {resultCount} results for search "{searchText}"',
  },
  searchResultDescriptionForOver100: {
    id: 'cn8Browser.searchResults.descriptionForOver100',
    description: 'Description for cn8 browser text search results with over 100 results, ' +
      'including searchText and resultCount',
    defaultMessage: 'Found over {resultCount} results for search "{searchText}"',
  },
  close: {
    id: 'cn8Browser.close',
    description: 'Label for close/back button in CN8 browser component',
    defaultMessage: 'Close',
  },
  searchInputLabel: {
    id: 'cn8Browser.textSearchInput.label',
    description: 'Label for text search input in CN8 browser component',
    defaultMessage: 'Nimikehaku',
  },
  searchInputPlaceholder: {
    id: 'cn8Browser.textSearchInput.placeholder',
    description: 'Placeholder for text search input in CN8 browser component',
    defaultMessage: 'Type keywords...',
  },
  noResults: {
    id: 'cn8Browser.noResults',
    description: 'Information shown when there are no search results in CN8 browser component',
    defaultMessage: 'No search results',
  },
  showInTreeView: {
    id: 'cn8Browser.showInTree',
    description: 'CN8 text search results link label to show item in tree view',
    defaultMessage: 'Show in tree browser',
  },
  searchIconText: {
    id: 'cn8Browser.textSearchInput.searchIconText',
    description: 'CN8 text search screen reader search icon text',
    defaultMessage: 'Search',
  },
})
