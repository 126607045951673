import { get, groupBy, find, isArray, isBoolean, size, isEmpty, forEach, uniq, isObject, has, indexOf, omit } from 'lodash'
import {
  PERMIT_BOOLEAN_CHECKBOX_FIELDS,
  GUARANTOR_CODE_UNDERTAKING,
  infoElementGroups,
  APPLICANT_CUSTOMS_RESPONSIBLE_RADIO_NAME,
} from './constants'
import { getGroupedInfoElementsByGroupName } from './permitHelper'
import { USE_EQUIVALENT_GOOD_FIELD_NAME } from './components/GoodsToBePlacedUnderProcedure/constants'

export default function mapPermitApplication(rawFormData, structure, language, applicant, representative) {
  const { applicationTypeCode, ...formData } = cleanFormValues(rawFormData, structure)
  const mappedPermitApplication = {
    applicationTypeCode,
    language,
    groupValues: [],
  }

  const infoElements = Object.keys(formData)
    .map(key => find(structure.infoElements, { code: key }))
    .filter(infoElement => infoElement !== undefined)
  const groups = groupBy(infoElements, 'group')

  forEach(groups, (infoElementsInGroup, groupCode) => {
    mapGroup(infoElementsInGroup, groupCode, mappedPermitApplication, formData, applicant, representative)
  })

  return mappedPermitApplication
}

export function cleanFormValues(values, structure) {
  let newValues = omit(values, APPLICANT_CUSTOMS_RESPONSIBLE_RADIO_NAME, 'checkboxSameAsAccountsLocation')

  // Checkboxes use booleans as values while permits use 'true' and 'false'. Let's fix this when sending values
  structure.infoElements
    .filter(infoElement => newValues[infoElement.code])
    .flatMap(infoElement => infoElement.fields)
    .filter(field => field.type === 'BOOLEAN' && PERMIT_BOOLEAN_CHECKBOX_FIELDS.includes(field.fieldCode))
    .forEach((field) => {
      if (field.infoElement.repeat > 1) {
        throw new Error('Checkbox value mapping not supported in repeating info elements')
      }

      const currentValue = newValues[field.infoElement.code][field.fieldCode]
      if (isBoolean(currentValue)) {
        const newValue = newValues[field.infoElement.code][field.fieldCode]
          ? 'true'
          : 'false'
        newValues[field.infoElement.code] = {
          ...newValues[field.infoElement.code],
          [field.fieldCode]: newValue,
        }
      }
    })

  if (has(values, 'guaranteeForm.guaranteeForm') && values.guaranteeForm.guaranteeForm !== GUARANTOR_CODE_UNDERTAKING) {
    newValues = omit(newValues, [
      'guaranteeForm.guarantorAddress',
      'guaranteeForm.guarantorCity',
      'guaranteeForm.guarantorCountryCode',
      'guaranteeForm.guarantorName',
      'guaranteeForm.guarantorPostcode',
    ])
  }

  if (isArray(get(values, 'attachedDocument'))) {
    const newRows = []
    values.attachedDocument.forEach((attachment) => {
      if (attachment.status !== 'FAILED') {
        const attachmentToBeSaved = Object.assign({}, attachment)
        delete attachmentToBeSaved.status
        newRows.push(attachmentToBeSaved)
      }
    })
    newValues.attachedDocument = newRows
  }

  if (isArray(get(values, 'goodsToBePlacedUnderProcedure'))) {
    let newRows = []
    for (const row of values.goodsToBePlacedUnderProcedure) {
      if (!isEmpty(row.errors)) {
        // TODO: is this relevant?
        // Don't store any goods if there are any errors in them.
        // This is done to avoid a situation where somebody manages
        // to go back, press next on the previous step and by doing so
        // to save a draft with the errors.
        // If the same person would then reload the page, the error
        // notifications would be gone, but the errors still there.
        // Better to just not store erroneous data at all.
        newRows = []
        break
      }
      newRows.push(omit(row, ['errors']))
    }
    newValues.goodsToBePlacedUnderProcedure = newRows
  }

  if (isArray(get(values, 'equivalentGood'))) {
    const newRows = []
    values.equivalentGood.forEach(row =>
      newRows.push(omit(row, [
        USE_EQUIVALENT_GOOD_FIELD_NAME,
      ]))
    )
    newValues.equivalentGood = newRows
  }
  if (isArray(get(values, 'goodsIdentification'))) {
    const newRows = []
    values.goodsIdentification.forEach(row =>
      newRows.push(omit(row, [
        USE_EQUIVALENT_GOOD_FIELD_NAME,
      ]))
    )
    newValues.goodsIdentification = newRows
  }
  if (isArray(get(values, 'goodsLocation'))) {
    const newRows = []
    values.goodsLocation.forEach(row =>
      newRows.push(omit(row, [
        'goodsLocationName',
        'goodsLocationAddress',
        'goodsLocationPostalCode',
        'goodsLocationCity',
      ]))
    )
    newValues.goodsLocation = newRows
  }
  if (isArray(get(values, 'goodsMovement'))) {
    const newRows = []
    values.goodsMovement.forEach(row =>
      newRows.push(omit(row, [
        'goodsMovementName',
        'goodsMovementAddress',
        'goodsMovementPostalCode',
        'goodsMovementCity',
      ]))
    )
    newValues.goodsMovement = newRows
  }
  if (isArray(get(values, 'processingOrUsePlace'))) {
    const newRows = []
    values.processingOrUsePlace.forEach(row =>
      newRows.push(omit(row, [
        'processingOrUsePlaceName',
        'processingOrUsePlaceAddress',
        'processingOrUsePlacePostalCode',
        'processingOrUsePlaceCity',
      ]))
    )
    newValues.processingOrUsePlace = newRows
  }

  // The next two if's basically just pick the values in the actual fields present in the structure
  if (get(values, 'customsProcedureType')) {
    newValues.customsProcedureType = omit(values.customsProcedureType, [
      'liabilityGuaranteeProcedureType',
      'debtGuaranteeProcedureType',
    ])
  }
  if (get(values, 'operationCount')) {
    newValues.operationCount = {
      operationCount: values.operationCount.operationCount,
    }
  }

  // Guaranteelevel data structure is a mess. Let's fix it
  if (get(values, 'guaranteeLevel')) {
    newValues.guaranteeLevel = values.guaranteeLevel.guaranteeLevel.map(val => ({
      guaranteeLevel: val,
      guaranteeLevelAdditionalInformation: values.guaranteeLevel.guaranteeLevelAdditionalInformation,
    }))
  }

  Object.keys(values).forEach((valueKey) => {
    if (isArray(newValues[valueKey])) {
      const rowsCopy = newValues[valueKey].slice()
      const newRows = []

      const goodsInfoElementsToGroup = getGroupedInfoElementsByGroupName(
        structure,
        infoElementGroups.goods
      )

      // In case of grouped infoElements we want to preserve the row IDs for grouping
      const infoElementIsInGroup = isObject(find(goodsInfoElementsToGroup, { code: valueKey }))
      if (size(goodsInfoElementsToGroup) > 1 && infoElementIsInGroup) {
        rowsCopy.forEach((row) => {
          newRows.push(row)
        })
      } else {
        rowsCopy.forEach((row) => {
          newRows.push(omit(row, 'id'))
        })
      }
      newValues[valueKey] = newRows
    }
  })

  return newValues
}

function mapGroup(infoElementsInGroup, groupCode, mappedPermitApplication, formData, applicant, representative) {
  const groupedValues = []

  const pushInfoElementsToGroupedValues = (infoElements) => {
    if (!isEmpty(infoElements)) {
      groupedValues.push({
        infoElements,
      })
    }
  }

  const groupInfoElements = has(infoElementGroups, groupCode)
  if (groupInfoElements) {
    const rowIDs = uniq(
      infoElementsInGroup
        .flatMap(infoElement => formData[infoElement.code])
        .map(row => row.id)
    )
    const groupValues = rowIDs.map(() => [])

    infoElementsInGroup.forEach((infoElement) => {
      formData[infoElement.code].forEach((row) => {
        // infoElements are grouped based on their ID in values
        const idIndex = indexOf(rowIDs, row.id)
        groupValues[idIndex].push(
          ...mapMultipleInfoElements([omit(row, 'id')], infoElement.code)
        )
      })
    })

    groupValues.forEach(pushInfoElementsToGroupedValues)
  } else {
    infoElementsInGroup.forEach((infoElement) => {
      const infoElementCode = infoElement.code
      const isSingleCodeset = infoElement.type === 'SINGLE' && infoElement.repeat > 1
      const infoElementData = formData[infoElementCode]
      let infoElementsArr

      if (Array.isArray(infoElementData)) {
        infoElementsArr = mapMultipleInfoElements(infoElementData, infoElementCode)
      } else if (isSingleCodeset && !isEmpty(infoElementData)) {
        infoElementsArr = mapSingleCodesetInfoElements(infoElementData, infoElement)
      } else {
        infoElementsArr = mapSingleInfoElement(infoElementData, infoElementCode, applicant, representative)
      }

      pushInfoElementsToGroupedValues(infoElementsArr)
    })
  }

  if (!isEmpty(groupedValues)) {
    mappedPermitApplication.groupValues.push({
      groupCode,
      groupedValues,
    })
  }
}

function getApplicantType(fieldCode, applicant, representative) {
  if (fieldCode === 'authorizationOrDecisionHolderIdentification') {
    return { applicantType: get(applicant, 'applicantType') }
  }

  if (fieldCode === 'representativeIdentification') {
    return { applicantType: get(representative, 'applicantType') }
  }

  return {}
}

function getFieldValueSource(fieldCode, formInfoElement) {
  let fieldValueSource = null
  Object.keys(formInfoElement)
    .forEach((fieldKey) => {
      if (fieldKey.startsWith(fieldCode) && fieldKey.endsWith('FieldValueSource')) {
        fieldValueSource = get(formInfoElement, fieldKey, null)
      }
    })

  if (fieldValueSource) {
    return {
      fieldValueSource,
    }
  }
  return null
}

function mapMultipleInfoElements(formInfoElementsArray, infoElementKey) {
  return formInfoElementsArray.map(formInfoElement => (
    {
      code: infoElementKey,
      fieldValues: Object.keys(formInfoElement)
        .filter(key => !key.endsWith('FieldValueSource'))
        .map(fieldCode =>
          ({
            code: fieldCode,
            value: formInfoElement[fieldCode],
            ...getFieldValueSource(fieldCode, formInfoElement),
          })
        ),
    }
  ))
}

function mapSingleCodesetInfoElements(
  infoElementData,
  infoElement,
) {
  const fieldCode = infoElement.fields[0].fieldCode
  return infoElementData[fieldCode].map(value => (
    {
      code: infoElement.code,
      fieldValues: [
        {
          code: fieldCode,
          value,
        },
      ],
    }
  ))
}

function mapSingleInfoElement(formInfoElementObject, infoElementKey, applicant, representative) {
  if (!formInfoElementObject) {
    return null
  }

  const fieldCodes = Object.keys(formInfoElementObject)
  if (!fieldCodes.length) {
    return []
  }

  if (Array.isArray(formInfoElementObject[fieldCodes[0]])) {
    // case fieldValues with the same code
    /*
    const fieldValues = formInfoElementObject[fieldValueKeys[0]].map(fieldValueValue =>
      ({
        code: fieldValueKeys[0],
        value: fieldValueValue,
      })
    )
    */
    throw new Error('This should not happen')
  }

  const fieldValues = fieldCodes.map(fieldValueKey => ({
    code: fieldValueKey,
    value: formInfoElementObject[fieldValueKey],
    ...getFieldValueSource(fieldValueKey, formInfoElementObject),
    ...getApplicantType(fieldValueKey, applicant, representative),
  }))

  return [
    {
      code: infoElementKey,
      fieldValues,
    },
  ]
}
