import { handleActions } from 'redux-actions'
import { unionBy } from 'lodash'
import {
  INTRASTAT_DESTROY_STATE,
  INTRASTAT_CODESET_REQUEST,
  INTRASTAT_CACHE_CODESET,
  INTRASTAT_CACHE_CUSTOMERS,
} from './constants'

const initialState = {
  fetchingCodesets: {},
  customerError: false,
  cachedCustomers: [],
  cachedCodesets: {},
  fetchingCN8Tree: false,
  cachedCN8Codes: {},
  codesetErrors: {},
}

export default handleActions({
  [INTRASTAT_DESTROY_STATE]: () => initialState,
  [INTRASTAT_CODESET_REQUEST]: (state, { payload: { name, referencePeriod } }) => ({
    ...state,
    fetchingCodesets: {
      ...state.fetchingCodesets,
      [referencePeriod]: {
        ...state.fetchingCodesets[referencePeriod],
        [name]: true,
      },
    },
    codesetErrors: {
      ...state.codesetErrors,
      [referencePeriod]: {
        ...state.codesetErrors[referencePeriod],
        [name]: false,
      },
    },
  }),
  [INTRASTAT_CACHE_CODESET]: {
    next: (state, { payload, meta: { name, referencePeriod } }) => ({
      ...state,
      cachedCodesets: {
        ...state.cachedCodesets,
        [referencePeriod]: {
          ...state.cachedCodesets[referencePeriod],
          [name]: payload,
        },
      },
      fetchingCodesets: {
        ...state.fetchingCodesets,
        [referencePeriod]: {
          ...state.fetchingCodesets[referencePeriod],
          [name]: false,
        },
      },
      codesetErrors: {
        ...state.codesetErrors,
        [referencePeriod]: {
          ...state.codesetErrors[referencePeriod],
          [name]: false,
        },
      },
    }),
    throw: (state, { payload, meta: { name, referencePeriod } }) => ({
      ...state,
      codesetErrors: {
        ...state.codesetErrors,
        [referencePeriod]: {
          ...state.codesetErrors[referencePeriod],
          [name]: payload.globalIntlMessage || true,
        },
      },
      fetchingCodesets: {
        ...state.fetchingCodesets,
        [referencePeriod]: {
          ...state.fetchingCodesets[referencePeriod],
          [name]: false,
        },
      },
    }),
  },
  [INTRASTAT_CACHE_CUSTOMERS]: {
    next: (state, { payload }) => ({
      ...state,
      customerError: false,
      cachedCustomers: unionBy(
        payload,
        state.cachedCustomers,
        customer => customer.id
      ),
    }),
    throw: (state, { meta: { message } }) => ({
      ...state,
      customerError: message || true,
    }),
  },
}, initialState)

