import { handleActions } from 'redux-actions'
import { PERMITS_DESTROY_STATE } from './constants'

const initialState = {
  customerError: false,
}

export default handleActions({
  [PERMITS_DESTROY_STATE]: () => initialState,
}, initialState)

