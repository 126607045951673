import React from 'react'
import { injectIntl } from 'react-intl'
import { Row, Col } from 'react-bootstrap'
import { get, some, has, isEqual, filter } from 'lodash'
import InfoElementHeaderArea from 'src/components/form_v2/InfoElementHeaderArea'
import { focusElementById } from 'src/utils'
import ItemForm from './components/ItemForm'
import ItemsList from './components/ItemsList'
import messages from './messages'
import intrastatMessages from '../../../../intrastat/declaration/routes/rows/messages'
import { FOCUS_TARGET_ID_PREFIX, PERSON_ALLOWED_PERMIT_TYPES } from '../../constants'
import InfoElementValidationMessages from '../InfoElementValidationMessages'

class RepetitiveComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedItem: null,
      showValidation: false,
    }
    this.addNew = this.addNew.bind(this)
    this.cancelEditing = this.cancelEditing.bind(this)
    this.updateItem = this.updateItem.bind(this)
    this.deleteItem = this.deleteItem.bind(this)
  }

  componentDidUpdate(prevProps) {
    const submits = get(this.props, 'formApi.submits', 0)
    const prevSubmits = get(prevProps, 'formApi.submits', 0)
    if (submits > prevSubmits) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ showValidation: true })
    }
  }

  addNew(data) {
    const { formApi, infoElement } = this.props
    if (!has(formApi.values, infoElement.code)) {
      formApi.values[infoElement.code] = []
    }
    const newItems = formApi.values[infoElement.code]
    newItems.push(data)
    formApi.setValue(infoElement.code, newItems)

    if (infoElement.code === 'useOfMessageDeclarantProvider') {
      this.focus(data.messageDeclarantProviderIdentification)
    }
  }

  cancelEditing() {
    this.setState({ selectedItem: null })
  }

  updateItem(oldData, newData) {
    const { formApi, infoElement } = this.props
    const newItems = filter(formApi.values[infoElement.code], item => !isEqual(item, oldData))
    newItems.push(newData)
    formApi.setValue(infoElement.code, newItems)

    if (infoElement.code === 'useOfMessageDeclarantProvider') {
      this.focus(newData.messageDeclarantProviderIdentification)
    }
  }

  deleteItem(index) {
    const { formApi, infoElement } = this.props
    const newItems = formApi.values[infoElement.code].slice(0)
    newItems.splice(index, 1)
    formApi.setValue(infoElement.code, newItems)
  }

  focus = (listId) => {
    const destId = `${FOCUS_TARGET_ID_PREFIX}${listId}`
    focusElementById(destId, 1000)
  }

  render() {
    const {
      infoElement,
      locale,
      formApi,
      renderField,
      intl,
      codesets,
    } = this.props

    const { showValidation } = this.state

    const fields = get(this.props.infoElement, 'fields')
    const absolutelyRequired = get(this.props.infoElement, 'mandatory') && some(fields, 'mandatory')
    const conditionallyRequired = infoElement.requiredWhen && infoElement.requiredWhen(formApi.values)
    const mandatory = absolutelyRequired || conditionallyRequired
    const newRowDescriptionId = `new-row-description-${this.props.infoElement.code}`
    const items = formApi.values[infoElement.code] || []
    const errors = get(formApi, `errors.${infoElement.code}`)
    const isReliefPermit = PERSON_ALLOWED_PERMIT_TYPES.includes(this.props?.structure?.code)
    const infoElementCode = this.props.infoElement.code
    const shouldRenderCustomPlaceOfUseForm = isReliefPermit && infoElementCode === 'placeOfUse'

    return (
      <Col xs={12}>
        <Row>
          <Col xs={12}>
            <hr />
            <InfoElementHeaderArea
              infoElement={infoElement}
              locale={locale}
            />
          </Col>
        </Row>
        <Row className="form-group">
          <Col xs={12}>
            <ItemForm
              buttonDescriptionId={newRowDescriptionId}
              selectedItem={this.state.selectedItem}
              cancelEditing={this.cancelEditing}
              updateItem={this.updateItem}
              locale={locale}
              infoElement={infoElement}
              renderField={renderField}
              addNew={this.addNew}
              structure={this.props?.structure}
              shouldRenderCustomPlaceOfUseForm={shouldRenderCustomPlaceOfUseForm}
            />
            <ItemsList
              items={items}
              fields={infoElement.fields}
              locale={locale}
              infoElement={infoElement}
              selectItem={item => this.setState({ selectedItem: item })}
              deleteItem={this.deleteItem}
              codesets={codesets}
            />
            {mandatory && items.length === 0 &&
              <div className={showValidation ? 'has-error' : ''}>
                <p
                  id={newRowDescriptionId}
                  className="help-block"
                >
                  {intl.formatMessage(messages.mandatoryRowDescription)}
                </p>
              </div>
            }
            {errors && <div className={showValidation ? 'has-error' : ''}>
              <p
                className="help-block"
              >
                {intl.formatMessage(intrastatMessages.errorsInRows)}
              </p>
            </div>}
          </Col>
          {items.length > 0 && <InfoElementValidationMessages infoElement={infoElement} formApi={this.props.formApi} />}
        </Row>
      </Col>
    )
  }
}

export default injectIntl(RepetitiveComponent)
