import {
  size,
  filter,
  has,
  get,
  dropRightWhile,
  isBoolean,
  find,
  endsWith,
  includes,
  omit,
  isEmpty,
  isArray,
  set,
} from 'lodash'
import {
  email,
  phone,
  required,
  requiredConditionally,
  minLength as checkMinLength,
  maxLength as checkMaxLength,
  nonNegativeInteger,
  nonNegativeDecimal,
} from 'src/utils/validation'
import logger from 'src/utils/logger'
import PermitSummary from './components/PermitSummary'
import Onboarding from './components/Onboarding'
import Currency from './components/Currency'
import PersonInCharge from './components/PersonInCharge/PersonInCharge'
import PersonInChargeReadOnly from './components/PersonInCharge/PersonInChargeReadOnly'
import RepetitiveComponent from './components/RepetitiveComponent/RepetitiveComponent'
import RepetitiveComponentReadOnly from './components/RepetitiveComponent/RepetitiveComponentReadOnly'
import ApplicantCustomsResponsible from './components/ApplicantCustomsResponsible'
import ApplicationBasis from './components/ApplicationBasis/ApplicationBasis'
import RecordsLocation from './components/RecordsLocation'
import AuthorizationOrDecisionHolder from './components/AuthorizationOrDecisionHolder'
import Representative from './components/Representative'
import ComprehensiveGuarantee from './components/ComprehensiveGuarantee'
import GuaranteeForm from './components/GuaranteeForm'
import GuaranteeFormReadOnly from './components/GuaranteeFormReadOnly'
import GuaranteeLevel from './components/GuaranteeLevel'
import GuaranteeLevelReadOnly from './components/GuaranteeLevelReadOnly'
import SmallHeaderInfoElement from './components/SmallHeaderInfoElement'
import CustomsProcedureType from './components/CustomsProcedureType'
import CustomsProcedureTypeReadOnly from './components/CustomsProcedureTypeReadOnly'
import CustomsProcedureOperationCount from './components/CustomsProcedureOperationCount'
import Attachments from './components/Attachments/Attachments'
import GoodsCombined from './components/GoodsCombined/GoodsCombined'
import GoodsToBePlacedUnderProcedure from './components/GoodsToBePlacedUnderProcedure/GoodsToBePlacedUnderProcedure'
import GoodsToBePlacedUnderProcedureReadOnly from
'./components/GoodsToBePlacedUnderProcedure/GoodsToBePlacedUnderProcedureReadOnly'
import GoodsLocation from './components/GoodsLocation/GoodsLocation'
import LocationFields from './components/LocationFields'
import {
  getGroupedInfoElementsByGroupName,
  isCodeset,
} from './permitHelper'
import {
  PERMIT_GUARANTEE_PROCEDURE_TYPE_ALL_VISIBLE,
  PERMIT_BOOLEAN_CHECKBOX_FIELDS,
  PERMIT_DEBT_GUARANTEE_PROCEDURE_TYPES,
  PERMIT_LIABILITY_GUARANTEE_PROCEDURE_TYPES,
  PERMIT_DEBT_GUARANTEE_LEVELS,
  PERMIT_LIABILITY_GUARANTEE_LEVELS,
  POPULAR_COUNTRY_CODES,
  POPULAR_CURRENCIES,
  APPLICATION_TYPE_NEW,
  infoElementGroups,
  APPLICATION_BASIS_TAT_INFO_ELEMENT_CODE,
  APPLICATION_BASIS_ADDITIONAL_INFORMATION_TEMPORARY_CODE,
  APPLICATION_BASIS_TAP_INFO_ELEMENT_CODE,
  APPLICANT_CUSTOMS_RESPONSIBLE_RADIO_NAME,
  DEFAULT_PERMIT_LIABILITY_GUARANTEE_LEVEL,
} from './constants'
import messages from './messages'
import BusinessIdInput from './components/BusinessIdInput'
import AuthorizationOrDecisionHolderReadOnly from './components/AuthorizationOrDecisionHolderReadOnly'
import RepresentativeDetailsReadOnly from '../../../components/representative/components/RepresentativeInformationReadOnly'
import {
  PERMIT_GOODS_TO_ENJOY_RELIEF_FROM_DUTIES_AMOUNT_FIELD_NAME,
  PERMIT_GOODS_TO_ENJOY_RELIEF_FROM_DUTIES_CURRENCY_FIELD_NAME,
  PERMIT_GOODS_TO_ENJOY_RELIEF_FROM_DUTIES_FIELD_PREFIX,
} from './components/PermitPage/constants'
import ApplicantCustomsResponsibleDateOfBirth from './components/ApplicantCustomsResponsible/components/ApplicantCustomsResponsibleDateOfBirth'
import ApplicantCustomsResponsibleDateEori from './components/ApplicantCustomsResponsible/components/ApplicantCustomsResponsibleDateEori'
import GuaranteePerCustomsProcedure from './components/GuaranteePerCustomsProcedure/GuaranteePerCustomsProcedure'
import GuaranteePerCustomsReadOnly from './components/GuaranteePerCustomsProcedure/GuaranteePerCustomsReadOnly'
import ConcentToSendingDecisionElectronically from './components/ConcentToSendingDecisionElectronically'
import { getCodeset } from './components/PermitPage/customParams'

export function getFormDefaultValues(initialValues, additionalData, structure) {
  const applicationTypeCode = get(initialValues, 'applicationTypeCode')

  let defaultValues = {
    applicationType: {
      applicationType: APPLICATION_TYPE_NEW,
    },
    signature: {
      signature: get(additionalData, 'customerName', ''),
    },
    personInCharge: [],
    messageCustomerContact: {
      messageCustomerHimself: 'true',
    },
    ...initialValues,
    attachedDocumentCount: {
      attachedDocumentCount: 0,
    },
  }

  // Set default values for customsProcedureType and guaranteeLevel
  let customsProcedureTypeObj = {}
  let guaranteeLevelObj = {}

  const customsProcedureType = get(initialValues, 'customsProcedureType.customsProcedureType')
  const guaranteeLevel = {
    guaranteeLevels: [],
    guaranteeLevelAdditionalInformation: '',
  }

  if (has(initialValues, 'applicantCustomsResponsible')) {
    const {
      applicationContactPersonEmail,
      applicationContactPersonName,
      applicationContactPersonPhone,
    } = get(initialValues, 'applicationContactPerson', {})

    const {
      applicantCustomsResponsiblePhone,
      applicantCustomResponsibleEmail,
      applicantCustomsResponsibleName,
      applicantCustomsResponsibleDateEori,
    } = get(initialValues, 'applicantCustomsResponsible', {})

    if (
      applicationContactPersonEmail === applicantCustomResponsibleEmail &&
      applicationContactPersonName === applicantCustomsResponsibleName &&
      applicationContactPersonPhone === applicantCustomsResponsiblePhone
    ) {
      defaultValues = set(defaultValues, APPLICANT_CUSTOMS_RESPONSIBLE_RADIO_NAME, 'sameAsUser')
    } else if (applicantCustomsResponsibleDateEori) {
      defaultValues = set(defaultValues, APPLICANT_CUSTOMS_RESPONSIBLE_RADIO_NAME, 'otherCompany')
    } else {
      defaultValues = set(defaultValues, APPLICANT_CUSTOMS_RESPONSIBLE_RADIO_NAME, 'otherPerson')
    }
  }

  if (has(initialValues, 'guaranteeLevel')) {
    get(initialValues, 'guaranteeLevel').forEach((item) => {
      guaranteeLevel.guaranteeLevels.push(item.guaranteeLevel)
      guaranteeLevel.guaranteeLevelAdditionalInformation = item.guaranteeLevelAdditionalInformation
      return null
    })
  }

  if (includes(PERMIT_GUARANTEE_PROCEDURE_TYPE_ALL_VISIBLE, applicationTypeCode)) {
    customsProcedureTypeObj = {
      customsProcedureType,
    }
    guaranteeLevelObj = guaranteeLevel
  } else {
    customsProcedureTypeObj = {
      debtGuaranteeProcedureType: customsProcedureType && filter(customsProcedureType, item =>
        PERMIT_DEBT_GUARANTEE_PROCEDURE_TYPES.includes(item)),
      liabilityGuaranteeProcedureType: customsProcedureType && filter(customsProcedureType, item =>
        PERMIT_LIABILITY_GUARANTEE_PROCEDURE_TYPES.includes(item)),
    }
    guaranteeLevelObj = {
      debtGuaranteeLevel: (guaranteeLevel.guaranteeLevels && filter(guaranteeLevel.guaranteeLevels, item =>
        PERMIT_DEBT_GUARANTEE_LEVELS.includes(item)).toString())
        || (get(additionalData, 'customerHasAEO') ? 'AB' : 'AA'),
      liabilityGuaranteeLevel: (guaranteeLevel.guaranteeLevels && filter(guaranteeLevel.guaranteeLevels, item =>
        PERMIT_LIABILITY_GUARANTEE_LEVELS.includes(item)).toString())
        || DEFAULT_PERMIT_LIABILITY_GUARANTEE_LEVEL,
      guaranteeLevel: [],
    }
  }

  if (find(structure.infoElements, infoElement => infoElement.code === 'customsProcedureType')) {
    defaultValues = {
      ...defaultValues,
      customsProcedureType: {
        ...initialValues.customsProcedureType,
        ...customsProcedureTypeObj,
      },
    }
  }
  if (find(structure.infoElements, infoElement => infoElement.code === 'guaranteeLevel')) {
    defaultValues = {
      ...defaultValues,
      guaranteeLevel: {
        ...guaranteeLevelObj,
        guaranteeLevelAdditionalInformation: guaranteeLevel.guaranteeLevelAdditionalInformation,
      },
    }
  }
  if (find(structure.infoElements, infoElement => infoElement.code === 'dischargePeriod')) {
    defaultValues = set(defaultValues, 'dischargePeriod.dischargePeriodAutomaticExtension', false)
  }
  if (find(structure.infoElements, infoElement => infoElement.code === 'releaseForFreeCirculationByDischargeBill')) {
    defaultValues = set(
      defaultValues,
      'releaseForFreeCirculationByDischargeBill.releaseForFreeCirculationByDischargeBill',
      false
    )
  }
  if (applicationTypeCode === 'OPO' &&
    find(structure.infoElements, infoElement => infoElement.code === 'economicConditions')) {
    defaultValues = set(
      defaultValues,
      'economicConditions.economicConditionsAdditionalInformation',
      // eslint-disable-next-line max-len
      'Taloudelliset edellytykset täyttyvät. Unionin tuottajien keskeisille eduille ei katsota aiheutuvan haittaa UTK 211 art. 5 kohta.'
    )
  }

  if (applicationTypeCode === 'VVI' &&
    find(structure.infoElements, infoElement => infoElement.code === 'geographicalValidityUnion')) {
    defaultValues = set(
      defaultValues,
      'geographicalValidityUnion.geographicalValidityCountryCodeUnion[0]',
      'EU'
    )
  }

  structure.infoElements.forEach((infoElement) => {
    infoElement.fields.forEach((field) => {
      if (field.type === 'LOCATION') {
        let disaggregatedValues = null
        if (infoElement.repeat === 1) {
          const locationValue = get(initialValues, field.code, '')
          const split = locationValue.split(/\n/)
          disaggregatedValues = {
            ...initialValues[infoElement.code],
            [`${infoElement.code}Name`]: split[0] || '',
            [`${infoElement.code}Address`]: split[1] || '',
            [`${infoElement.code}PostalCode`]: split[2] || '',
            [`${infoElement.code}City`]: split[3] || '',
            [`${infoElement.code}CountryCode`]: split[4] ||
              get(initialValues, `${infoElement.code}.${infoElement.code}CountryCode`, ''),
          }
        } else {
          const locationValues = get(initialValues, `${infoElement.code}`, [])
          disaggregatedValues = []
          locationValues.forEach((locationValue) => {
            const split = locationValue[field.code].split(/\n/)
            disaggregatedValues.push({
              ...locationValue,
              [`${infoElement.code}Name`]: split[0] || '',
              [`${infoElement.code}Address`]: split[1] || '',
              [`${infoElement.code}PostalCode`]: split[2] || '',
              [`${infoElement.code}City`]: split[3] || '',
              [`${infoElement.code}CountryCode`]: split[4] || locationValue[`${infoElement.code}CountryCode`] || '',
            })
          })
        }
        defaultValues = {
          ...defaultValues,
          [infoElement.code]: disaggregatedValues,
        }
      }
    })
  })

  return defaultValues
}

/**
 * If one of the guarantee infoElements is found in structure, then we assume the application needs an active guarantee.
 */
export function isGuaranteeRequired(structure) {
  const guaranteeInfoElements = filter(structure.infoElements, infoElement =>
    includes(['comprehensiveGuarantee', 'guarantee'], infoElement.code)
  )
  return guaranteeInfoElements.length > 0
}

export function getFieldInputType(field) {
  const inputTypes = {
    BOOLEAN: 'radioListWithField',
    CUSTOMERREGISTRY: 'text',
    COMPANY: 'text',
    CUSTOMSOFFICE_REGISTRY: 'codeset',
    CODESET: 'codeset',
    DATE: 'date',
    NAME: 'text',
    EMAIL: 'email',
    STREET_ADDRESS: 'text',
    POSTAL_CODE: 'text',
    POST_OFFICE: 'text',
    COUNTRY_CODE: 'codesetAutocomplete',
    CURRENCY: 'codeset',
    FREE_TEXT: 'textarea',
    GUARANTEE_LEVEL: 'codeset',
    NUMBER: 'number',
    REFERENCE_NUMBER: 'text',
    TEXT: 'text',
    SELECT: 'select',
    TARIC_CODE: 'number',
    RADIO: 'radioListWithField',
    CODESET_AUTOCOMPLETE: 'codesetAutocomplete',
  }

  if (isPhoneField(field)) {
    return 'phone'
  }

  if (endsWith(field.code, 'requestedStartDate')) {
    return 'date'
  }

  if (endsWith(field.code, 'DateOfBirth')) {
    return 'dateOfBirth'
  }

  if (endsWith(field.code, 'paymentTimeLimit') ||
    endsWith(field.code, 'paymentDefermentType') ||
    endsWith(field.code, 'replacementProduct') ||
    endsWith(field.code, 'standardExchangeSystemCode') ||
    endsWith(field.code, 'messageServiceUsage') ||
    endsWith(field.code, 'ApplicantRole') ||
    endsWith(field.code, 'reasonRevocation') ||
    endsWith(field.code, 'acceptanceOfProposal')) {
    return 'codesetRadio'
  }

  if (endsWith(field.code, 'reasonRevocation.additionalInformation')) {
    return 'textarea'
  }

  if (PERMIT_BOOLEAN_CHECKBOX_FIELDS.includes(field.fieldCode)) {
    return 'labeledCheckbox'
  }

  if (field.code === 'messageDeclarations2.messageIds') {
    return 'codesetCheckboxes'
  }
  if (field.infoElement.repeat > 1 && field.infoElement.type === 'SINGLE') {
    return 'codesetAutocomplete'
  }

  if (has(inputTypes, field.type)) {
    return inputTypes[field.type]
  }

  return field.type
}

function isPhoneField(field) {
  return field.code.includes('Phone')
}

export function getCodesetObj(field) {
  if (isCodeset(field.type)) {
    let output = {
      codesetName: field.codesetName,
      subCodesetName: field.subCodesetName || field.customsOfficeAuthorization || null,
      codesetValidValues: field.codesetValidValues || null,
      codesetExtension: field.codesetExtension || null,
    }
    if (endsWith(field.code, 'paymentTimeLimit') ||
      endsWith(field.code, 'paymentDefermentType')) {
      output = {
        ...output,
        codesetExtension: 'AsiointiTeksti',
      }
    }
    return output
  }

  return {}
}

export function getFieldProps({
  field,
  input,
  locale,
  staticValue,
  showHelp,
  validate = true,
  options,
  formatMessage,
  formApi,
} = {}) {
  let helpVisible = null
  if (isBoolean(showHelp)) {
    helpVisible = showHelp
  } else {
    helpVisible = get(field, 'help')
  }

  let isVisible = true
  if (has(field, 'visible')) {
    isVisible = field.visible
  } else if (input && has(input, 'visible')) {
    isVisible = input.visible
  }

  const { infoElement } = field
  const defaultProps = {
    label: has(field.name, 'id') ? field.name : `${field.name[locale]}`,
    input: {
      type: getFieldInputType(field),
      multilineLabel: true,
      ...input,
      name: field.code,
      placeholder: has(input, 'placeholder') ? input.placeholder : field.name[locale],
      static: staticValue || field.readonlyInApplication || false,
      help: helpVisible && ({
        content: field.help[locale],
      }),
      options,
      multiple: has(input, 'multiple')
        ? input.multiple
        : (infoElement.repeat > 1 && infoElement.type === 'SINGLE'),
      validate,
      validation: getFieldValidation(input, field, formatMessage),
      visible: isVisible,
    },
    formGroupClassname: 'formElementGutter',
  }

  if (field.code === 'messageDeclarantProviderIdentification') {
    const customPlaceholder = formatMessage(messages.messageDeclarantProviderIdentificationDesc)
    return {
      ...defaultProps,
      input: {
        ...defaultProps?.input,
        placeholder: customPlaceholder,
      },
    }
  }

  if (field.code.includes('messageCustomerContact.messageCustomerHimself')) {
    return {
      ...defaultProps,
      type: 'radioListWithField',
      label: messages.messageCustomerHimselfTitle,
      options: [
        {
          title: messages.messageCustomerHimselfYes,
          value: 'true',
        },
        {
          title: messages.messageCustomerHimselfNo,
          value: 'false',
        },
      ],
    }
  }

  if (field.code === 'concentToSendingDecisionElectronically.concentToSendingDecisionElectronically') {
    const customErrorMessage = messages.mandatorySelection

    return {
      ...defaultProps,
      options: [
        {
          title: messages.concentYes,
          value: 'true',
        },
        {
          title: messages.concentNo,
          value: 'false',
          customAriaDescribedBy: 'concentNo',
        },
      ],
      input: {
        ...defaultProps?.input,
        validation: {
          ...defaultProps?.input?.validation,
          customErrorMessage,
        },
      },
    }
  }

  if (field.type === 'BOOLEAN') {
    if (PERMIT_BOOLEAN_CHECKBOX_FIELDS.includes(field.fieldCode)) {
      return {
        ...defaultProps,
        formGroupClassname: 'noFormElementGutter',
      }
    }

    return {
      ...defaultProps,
      options: [
        {
          title: messages.radioSelectionYes,
          value: 'true',
        },
        {
          title: messages.radioSelectionNo,
          value: 'false',
        },
      ],
    }
  }

  if (field.code === 'messageDeclarations2.messageIds') {
    const GROUPING_EXTENSION = 'Sovellus'
    const customErrorMessage = messages.checkAtLeastOne

    return {
      ...defaultProps,
      input: {
        ...defaultProps?.input,
        validation: {
          ...defaultProps?.input?.validation,
          customErrorMessage,
        },
      },
      groupOptionsBy: option => option.item.extensionValues?.[GROUPING_EXTENSION]?.[locale],
    }
  }

  if (
    field.code === APPLICATION_BASIS_ADDITIONAL_INFORMATION_TEMPORARY_CODE && infoElement.code === APPLICATION_BASIS_TAT_INFO_ELEMENT_CODE) {
    const customErrorMessage = messages.otherReliefError_TAT
    const customLabel = messages.ttAMuuLabel

    return {
      ...defaultProps,
      label: customLabel,
      input: {
        ...defaultProps?.input,
        validation: {
          ...defaultProps?.input?.validation,
          ...field.input.validation,
          customErrorMessage,
        },
        controlled: true,
        onChange: field.onChange,
      },
    }
  }

  if (
    field.code === APPLICATION_BASIS_ADDITIONAL_INFORMATION_TEMPORARY_CODE && infoElement.code === APPLICATION_BASIS_TAP_INFO_ELEMENT_CODE) {
    const customErrorMessage = messages.otherReliefError_TAP
    const customLabel = messages.ttAMuuLabel

    return {
      ...defaultProps,
      label: customLabel,
      input: {
        ...defaultProps?.input,
        validation: {
          ...defaultProps?.input?.validation,
          ...field.input.validation,
          customErrorMessage,
        },
        controlled: true,
        onChange: field.onChange,
      },
    }
  }

  if (field.code === PERMIT_GOODS_TO_ENJOY_RELIEF_FROM_DUTIES_CURRENCY_FIELD_NAME && infoElement.code === PERMIT_GOODS_TO_ENJOY_RELIEF_FROM_DUTIES_FIELD_PREFIX) {
    const customPlaceholder = messages.reliefQuantityPlaceholder

    return {
      ...defaultProps,
      input: {
        ...defaultProps?.input,
        placeholder: customPlaceholder,
      },
      optionGroups: [{
        sort: 1,
        values: POPULAR_CURRENCIES,
        title: messages.selectGroupPopularTitle,
      }],
    }
  }

  if (field.code === PERMIT_GOODS_TO_ENJOY_RELIEF_FROM_DUTIES_AMOUNT_FIELD_NAME && infoElement.code === PERMIT_GOODS_TO_ENJOY_RELIEF_FROM_DUTIES_FIELD_PREFIX) {
    return {
      ...defaultProps,
      input: {
        ...defaultProps?.input,
        validation: {
          ...defaultProps.input.validation,
          maxDecimalLength: 2,
        },
      },
    }
  }

  if (field.type === 'COUNTRY_CODE') {
    return {
      ...defaultProps,
      optionGroups: [{
        sort: 1,
        values: POPULAR_COUNTRY_CODES,
        title: messages.selectGroupPopularTitle,
      }],
    }
  }

  if (field.code === 'guaranteeExistingDebtCurrency' || field.code === 'guaranteePotentialDebtCurrency') {
    return {
      ...defaultProps,
      optionGroups: [{
        sort: 1,
        values: POPULAR_CURRENCIES,
        title: messages.selectGroupPopularTitle,
      }],
      input: {
        ...defaultProps?.input,
        defaultValue: 'EUR',
        static: true,
      },
    }
  }

  if (
    field.code === 'applicantCustomsResponsible.applicantCustomsResponsibleName' ||
    field.code === 'applicantCustomsResponsible.applicantCustomsResponsiblePhone' ||
    field.code === 'applicantCustomsResponsible.applicantCustomResponsibleEmail'
  ) {
    const sameAsUserSelected = get(formApi, `values.${APPLICANT_CUSTOMS_RESPONSIBLE_RADIO_NAME}`) === 'sameAsUser'

    if (sameAsUserSelected) {
      return {
        ...defaultProps,
        disabled: sameAsUserSelected,
      }
    }

    return defaultProps
  }

  if (field.code === 'referenceAmount.referenceAmountCurrency') {
    return {
      ...defaultProps,
      input: {
        ...defaultProps.input,
        static: true,
      },
    }
  }

  if (field.type === 'CURRENCY') {
    return {
      ...defaultProps,
      optionGroups: [{
        sort: 1,
        values: POPULAR_CURRENCIES,
        title: messages.selectGroupPopularTitle,
      }],
    }
  }

  return defaultProps
}

function getFieldValidation(input, field, formatMessage) {
  const validation = {
    ...get(input, 'validation'),
    maxDecimalLength: field.maxDecimalLength && field.maxDecimalLength,
    minLength: field.minLength && field.minLength,
    maxLength: field.maxLength && field.maxLength,
    mandatory: field.mandatory,
    handlerFn: field.handlerFn && field.handlerFn,
  }

  if (field.code === 'messageDeclarantProviderIdentification') {
    return {
      ...validation,
      regex: {
        expression: /^[a-zA-Z]{2}.{6,15}$/, // validate EORI
        errorMessage: formatMessage({ id: '/permits/fields/companyIdNotValid' }),
      },
    }
  }

  return validation
}


export function getStepComponent(stepName) {
  const components = {
    init: Onboarding,
    summary: PermitSummary,
  }
  return components[stepName]
}

function fieldExist(fields, code) {
  return find(fields, field => endsWith(field.code, code))
}
function fieldExistByType(fields, code) {
  return find(fields, field => field.type === code)
}

const EmptyComponent = () => null

export function getInfoElementComponent(infoElement, structure) {
  if (infoElement.code === 'goodsToBePlacedUnderProcedure') {
    const goodsInfoElementsToGroupCount = getGroupedInfoElementsByGroupName(structure, infoElementGroups.goods)

    return size(goodsInfoElementsToGroupCount) > 1
      ? GoodsCombined
      : GoodsToBePlacedUnderProcedure
  }

  const components = {
    authorizationOrDecisionHolder: AuthorizationOrDecisionHolder,
    applicationBasis: ApplicationBasis,
    applicationBasis2: ApplicationBasis,
    concentToSendingDecisionElectronically: ConcentToSendingDecisionElectronically,
    // This is fallback for AL-798
    representative: Representative,
    representativeDetails: Representative,
    personInCharge: PersonInCharge,
    applicantCustomsResponsible: ApplicantCustomsResponsible,
    comprehensiveGuarantee: ComprehensiveGuarantee,
    guaranteeForm: GuaranteeForm,
    guaranteeLevel: GuaranteeLevel,
    customsProcedureType: CustomsProcedureType,
    guaranteePerCustomsProcedure: GuaranteePerCustomsProcedure,
    operationCount: CustomsProcedureOperationCount,
    attachedDocument: Attachments,
    processedProduct: GoodsToBePlacedUnderProcedure,
    replacementProduct: GoodsToBePlacedUnderProcedure,
    goodsToEnjoyReliefFromDuties: GoodsToBePlacedUnderProcedure,
    goodsLocation: GoodsLocation,
    goodsMovement: GoodsLocation,
    originatingProducts: GoodsToBePlacedUnderProcedure,
    processingOrUsePlace: GoodsLocation,
    firstProcessingOrUsePlace: GoodsLocation,
    recordsLocation: RecordsLocation,
    // recordsType gets rendered in recordsLocation (https://jira.tulli.csc.fi/browse/NAKKI-2279)
    recordsType: EmptyComponent,
    // Combining goods infoElements in all applications, so hide combined infoElements
    equivalentGood: EmptyComponent,
    yieldRate: EmptyComponent,
    goodsIdentification: EmptyComponent,
    economicConditions: EmptyComponent,
  }

  const Component = components[infoElement.code]
  if (Component) {
    return Component
  }

  if (infoElement.repeat > 1) {
    if (infoElement.fields.length === 1 && !isCodeset(infoElement.fields[0].type)) {
      return RepetitiveComponent
    }
    if (infoElement.fields.length > 1) {
      return RepetitiveComponent
    }
  }

  const contactInformationType = ['Name', 'Phone', 'Email']
  const addressType = ['Address', 'PostalCode', 'City', 'CountryCode']
  const currencyType = ['CURRENCY', 'NUMBER']
  if (contactInformationType.every(fieldExist.bind(null, infoElement.fields))) {
    return SmallHeaderInfoElement
  }
  if (addressType.every(fieldExist.bind(null, infoElement.fields))) {
    return SmallHeaderInfoElement
  }
  if (currencyType.every(fieldExistByType.bind(null, infoElement.fields))) {
    return Currency
  }

  return null
}

export function getCustomFieldComponent(field) {
  if (field.type === 'LOCATION') {
    return LocationFields
  }
  if (field.code === 'applicantCustomsResponsible.applicantCustomsResponsibleDateEori') {
    return ApplicantCustomsResponsibleDateEori
  }
  if (field.code === 'applicantCustomsResponsible.applicantCustomsResponsibleDateOfBirth') {
    return ApplicantCustomsResponsibleDateOfBirth
  }
  if (field.type === 'CUSTOMERREGISTRY' || field.type === 'COMPANY') {
    return BusinessIdInput
  }

  return null
}

export function getReadOnlyInfoElementComponent(infoElement, structure) {
  let components = {
    authorizationOrDecisionHolder: AuthorizationOrDecisionHolderReadOnly,
    representativeDetails: RepresentativeDetailsReadOnly,
    personInCharge: PersonInChargeReadOnly,
    guaranteeLevel: GuaranteeLevelReadOnly,
    guaranteeForm: GuaranteeFormReadOnly,
    attachedDocument: Attachments,
    customsProcedureType: CustomsProcedureTypeReadOnly,
    goodsLocation: GoodsLocation,
    goodsMovement: GoodsLocation,
    processingOrUsePlace: GoodsLocation,
    firstProcessingOrUsePlace: GoodsLocation,
    applicationBasis: GoodsToBePlacedUnderProcedureReadOnly,
    applicationBasis2: GoodsToBePlacedUnderProcedureReadOnly,
    placeOfUse: GoodsToBePlacedUnderProcedureReadOnly,
    goodsToEnjoyReliefFromDuties: GoodsToBePlacedUnderProcedureReadOnly,
    goodsToBePlacedUnderProcedure: GoodsToBePlacedUnderProcedureReadOnly,
    processedProduct: GoodsToBePlacedUnderProcedureReadOnly,
    replacementProduct: GoodsToBePlacedUnderProcedureReadOnly,
    // Combining goods infoElements in all applications, so hide combined infoElements
    equivalentGood: EmptyComponent,
    yieldRate: EmptyComponent,
    goodsIdentification: EmptyComponent,
    economicConditions: EmptyComponent,
  }

  const goodsInfoElementsToGroupCount = getGroupedInfoElementsByGroupName(structure, infoElementGroups.goods)

  if (size(goodsInfoElementsToGroupCount) > 1) {
    components = {
      ...components,
      goodsToBePlacedUnderProcedure: GoodsCombined,
    }
  }

  const Component = components[infoElement.code]

  if (Component) {
    return Component
  }

  if (infoElement.repeat > 1) {
    if (infoElement.code === 'guaranteePerCustomsProcedure') {
      return GuaranteePerCustomsReadOnly
    }
    if (infoElement.fields.length === 1 && !isCodeset(infoElement.fields[0].type)) {
      return RepetitiveComponentReadOnly
    }
    if (infoElement.fields.length > 1) {
      return RepetitiveComponentReadOnly
    }
  }

  return null
}

export function getInfoElementTitle(infoElementCode) {
  switch (infoElementCode) {
  case 'accountsLocation':
    return messages.infoElementTitleAccountsLocation
  case 'recordsLocation':
    return messages.infoElementTitleRecordsLocation
  default:
    break
  }
  return null
}

export function checkInfoElementsInGroup(formData, structure, group) {
  const infoElements = filter(structure.infoElements, { group })
  const infoElementsToCheck = filter(infoElements, infoElement =>
    !includes(['comprehensiveGuarantee'], infoElement.code)
  )

  const errors = {}
  filter(infoElementsToCheck, 'visible')
    .forEach((infoElement) => {
      Object.assign(errors, getInfoElementValueErrors(infoElement, formData))
    })

  if (!isEmpty(errors)) {
    logger.debug('field validation errors:', errors)
  }

  return errors
}

export function getInfoElementValueErrors(infoElement, formData) {
  const { type, fields, code: elementCode } = infoElement
  const errors = {}
  const addErrors = (key, fieldErrors, index) => {
    if (fieldErrors.length > 0) {
      if (index) {
        const arr = errors[key] || []
        arr.push(...fieldErrors)
        errors[key] = arr
        errors[`${key}-${index}`] = arr
      } else {
        errors[key] = fieldErrors
      }
    }
  }

  if (infoElement.validate) {
    const error = infoElement.validate(formData, infoElement)
    if (error) {
      addErrors(
        `${infoElement.code}-custom-validation`,
        [error]
      )
      addErrors(
        fields[0]?.code,
        [error]
      )
    }
  }

  if (type === 'SINGLE') {
    fields.forEach((field) => {
      addErrors(
        field.code,
        getFieldErrors(field, formData, formData)
      )
    })
  } else if (type === 'REPETITIVE') {
    const allValues = get(formData, elementCode, [])

    if (!isArray(allValues)) {
      // Nothing to do. Weird edge case caused by guaranteeLevel using its own
      // data structure
    } else if (allValues.length === 0) {
      const conditionallyRequired = infoElement.requiredWhen && infoElement.requiredWhen(formData)
      if (infoElement.mandatory || conditionallyRequired) {
        addErrors(
          `new-row-${infoElement.code}`,
          [messages.required]
        )
      }
    } else {
      allValues.forEach((values, index) => {
        fields.forEach((field) => {
          addErrors(
            `${infoElement.code}.${field.code}`,
            getFieldErrors(field, values, formData),
            index,
          )
        })
      })
    }
  }

  return errors
}

export function getFieldErrors(field, data, applicationValues = {}) {
  if (!field.visible) {
    return []
  }

  const value = get(data, field.code)
  const {
    isMandatoryConditionally,
    mandatory,
  } = isFieldMandatory(field, data, applicationValues)

  if (isArray(value)) {
    const errorMessages = []
    const addError = msg => msg && errorMessages.push(msg)
    if (mandatory) {
      addError(
        isMandatoryConditionally
          ? requiredConditionally(value, field.code)
          : required(value)
      )
    }
    const valueErrorMessages = value
      .map(val => getFieldValueErrors(field, val, mandatory, isMandatoryConditionally))
      .flat()
    return [...errorMessages, ...valueErrorMessages]
  }

  return getFieldValueErrors(field, value, data, mandatory, isMandatoryConditionally)
}

function getFieldValueErrors(field, value, data, mandatory, isMandatoryConditionally) {
  const errorMessages = []
  const addError = msg => msg && errorMessages.push(msg)

  if (mandatory) {
    addError(
      isMandatoryConditionally
        ? requiredConditionally(value, field.code)
        : required(value)
    )
  }

  if (value) {
    const {
      minLength,
      maxLength,
      maxDecimalLength,
    } = field
    if (!['BOOLEAN', 'DATE', 'CODESET'].includes(field.type)) {
      if (minLength) {
        addError(checkMinLength(minLength)(value))
      }
      if (maxLength) {
        addError(checkMaxLength(maxLength)(value))
      }
    }
    if (field.type === 'EMAIL') {
      addError(email(value))
    }
    if (isPhoneField(field)) {
      addError(phone(value, minLength, maxLength))
    }
    if (field.type === 'NUMBER') {
      if (maxDecimalLength > 0) {
        addError(nonNegativeDecimal(
          maxLength,
          maxDecimalLength,
        )(value))
      } else {
        addError(nonNegativeInteger(value))
      }
    }
  }

  return errorMessages
}

// The difference between formValues and applicationValues is that formValues
// is the data of the current form (may be a dialog containing a specific
// repeating info element) and applicationValues is the values in the whole
// permit form
export function isFieldMandatory(field, formValues, applicationValues) {
  const isMandatoryConditionally = isFieldMandatoryConditionally(field, formValues, applicationValues)
  const mandatory = isMandatoryConditionally !== null
    ? isMandatoryConditionally
    : isInfoElementMandatory(field.infoElement, formValues, applicationValues) && field.mandatory
  return {
    mandatory,
    isMandatoryConditionally,
  }
}

// The difference between formValues and applicationValues is that formValues
// is the data of the current form (may be a dialog containing a specific
// repeating info element) and applicationValues is the values in the whole
// permit form
export function isInfoElementMandatory(infoElement, formValues, applicationValues) {
  if (!infoElement) {
    return false
  }
  const visible = isInfoElementVisibleConditionally(infoElement, applicationValues)
  if (!visible) {
    return false
  }
  if (infoElement.requiredWhen) {
    const isRequired = infoElement.requiredWhen(formValues, applicationValues)
    if (isBoolean(isRequired)) {
      return isRequired
    }
  }
  return infoElement.mandatory
}

/* Checks the field requiredByField and requiredWhen fields and form values
 * and determines if the fields should be mandatory based on those conditions
 *
 * Returns:
 * true if the field is mandatory based on the conditions
 * true/false if field has overrideInfoElementMandatory, then field's own value
 * false if it is NOT mandatory based on the conditions
 * null if the it should default to the fields mandatory attributes
 */
function isFieldMandatoryConditionally(field, formValues, applicationValues) {
  if (field?.overrideInfoElementMandatory) {
    return field.mandatory
  }

  if (!isFieldVisibleConditionally(field, formValues, applicationValues)) {
    return false
  }
  if (field.requiredByField) {
    const otherValue = get(formValues, field.requiredByField)
    if (otherValue) {
      return true
    }
  }
  if (field.requiredWhen) {
    const isRequired = field.requiredWhen(formValues, applicationValues)
    if (isBoolean(isRequired)) {
      return isRequired
    }
  }
  return null
}


// See isFieldMandatoryConditionally for reference on the parameters formValues and applicationValues
export function isFieldVisibleConditionally(field, formValues, applicationValues) {
  if (field.visibleWhen) {
    const isVisible = field.visibleWhen(formValues, applicationValues)
    if (!isVisible) {
      return false
    }
  }
  return true
}

export function isInfoElementVisibleConditionally(infoElement, formValues) {
  if (infoElement.visibleWhen) {
    const isVisible = infoElement.visibleWhen(formValues)
    if (!isVisible) {
      return false
    }
  }
  return true
}

export function validateGoodsToBePlacedUnderProcedure(values, structure, currentGroup) {
  if (isArray(get(values, 'goodsToBePlacedUnderProcedure'))) {
    const groupsToValidate = dropRightWhile(structure.groups, group => group.code !== currentGroup)
      .map(group => group.code)
    const goodsInfoElement = find(structure.infoElements, { code: 'goodsToBePlacedUnderProcedure' })
    const needsValidation = goodsInfoElement && includes(groupsToValidate, goodsInfoElement.group)
    if (!needsValidation) {
      return true
    }

    for (const row of values.goodsToBePlacedUnderProcedure) {
      if (!isEmpty(row.errors)) {
        return false
      }
    }
  }
  return true
}

export function modifyFormValues(values, additionalData) {
  const newValues = { ...values }

  if (has(values, 'signature') && additionalData) {
    newValues.signature.signature = get(additionalData, 'customerName')
  }

  if (has(values, 'checkboxSameAsAccountsLocation') && values.checkboxSameAsAccountsLocation) {
    if (!has(newValues, 'recordsLocation')) {
      newValues.recordsLocation = {}
    }

    const source = get(values, 'accountsLocation', null)
    const target = newValues.recordsLocation
    target.recordsLocationAddress = get(source, 'accountsLocationAddress', '')
    target.recordsLocationPostalCode = get(source, 'accountsLocationPostalCode', '')
    target.recordsLocationCity = get(source, 'accountsLocationCity', '')
    target.recordsLocationCountryCode = get(source, 'accountsLocationCountryCode', '')

    // accountsType and recordsType are seperate objects
    newValues.recordsType = {
      recordsType: get(values, 'accountsType.accountsType', ''),
    }
  }

  const guaranteePerCustomsProcedure = get(values, 'guaranteePerCustomsProcedure', [])
  const customsProcedureGuaranteeCodeset = getCodeset('CustomsProcedureGuarantee', {
    codesets: additionalData.codesets,
    applicationDate: additionalData.applicationDate,
  })

  // Get list of debtGuaranteeProcedureTypes and liabilityGuaranteeProcedureTypes from guaranteePerCustomsProcedure's
  const {
    debtGuaranteeProcedureTypes,
    liabilityGuaranteeProcedureTypes,
  } = guaranteePerCustomsProcedure.reduce((acc, item) => {
    const selectedCustomsProcedureGuaranteeCodeset = customsProcedureGuaranteeCodeset && Object.values(
      customsProcedureGuaranteeCodeset).find(customsProcedureGuarantee =>
      customsProcedureGuarantee.code === item.guaranteeCustomsProcedure
    )

    if (!selectedCustomsProcedureGuaranteeCodeset) {
      return acc
    }

    const isExisting = get(selectedCustomsProcedureGuaranteeCodeset, 'extensionValues.velka')
    const isPotential = get(selectedCustomsProcedureGuaranteeCodeset, 'extensionValues.vastuu')

    if (isExisting) {
      acc.debtGuaranteeProcedureTypes.push(selectedCustomsProcedureGuaranteeCodeset)
    }

    if (isPotential) {
      acc.liabilityGuaranteeProcedureTypes.push(selectedCustomsProcedureGuaranteeCodeset)
    }

    return acc
  }, {
    debtGuaranteeProcedureTypes: [],
    liabilityGuaranteeProcedureTypes: [],
  })

  const legacyDebtGuaranteeProcedureType = get(values, 'customsProcedureType.debtGuaranteeProcedureType')
  const legacyLiabilityGuaranteeProcedureType = get(values, 'customsProcedureType.liabilityGuaranteeProcedureType')

  const debtGuaranteeLevel = get(values, 'guaranteeLevel.debtGuaranteeLevel')
  const liabilityGuaranteeLevel = get(values, 'guaranteeLevel.liabilityGuaranteeLevel')

  if (legacyDebtGuaranteeProcedureType || legacyLiabilityGuaranteeProcedureType) {
    newValues.customsProcedureType.customsProcedureType = []
    if (legacyDebtGuaranteeProcedureType) {
      legacyDebtGuaranteeProcedureType.forEach((item) => {
        newValues.customsProcedureType.customsProcedureType.push(item)
      })
    }
    if (legacyLiabilityGuaranteeProcedureType) {
      legacyLiabilityGuaranteeProcedureType.forEach((item) => {
        newValues.customsProcedureType.customsProcedureType.push(item)
      })
    }
  }

  if (debtGuaranteeLevel || liabilityGuaranteeLevel) {
    newValues.guaranteeLevel.guaranteeLevel = []
    if ((!isEmpty(legacyDebtGuaranteeProcedureType) || !isEmpty(debtGuaranteeProcedureTypes)) && debtGuaranteeLevel) {
      newValues.guaranteeLevel.guaranteeLevel.push(debtGuaranteeLevel)
    }
    if ((!isEmpty(legacyLiabilityGuaranteeProcedureType) || !isEmpty(liabilityGuaranteeProcedureTypes)) && liabilityGuaranteeLevel) {
      newValues.guaranteeLevel.guaranteeLevel.push(liabilityGuaranteeLevel)
    }
  }

  const useOfMessageDeclarantProvider = get(values, 'useOfMessageDeclarantProvider')
  if (isArray(useOfMessageDeclarantProvider)) {
    newValues.useOfMessageDeclarantProvider = []
    useOfMessageDeclarantProvider.forEach((item) => {
      const newItem = {
        ...item,
      }
      newValues.useOfMessageDeclarantProvider.push(newItem)
    })
  }

  // Remove all data for fields and infoElements that are invisible because of user choices
  additionalData.structure.infoElements
    .forEach((infoElement) => {
      const hidden = infoElement.visibleWhen && !infoElement.visibleWhen(values, values)

      if (hidden) {
        delete newValues[infoElement.code]
        return
      }

      const hiddenFields = infoElement.fields
        .filter((field) => {
          if (!field.visibleWhen) {
            return false
          }

          if (infoElement.type !== 'SINGLE' && infoElement.code !== 'guaranteePerCustomsProcedure') {
            // eslint-disable-next-line max-len
            throw new Error('visibleWhen is not currently supported for repeating infoElements. Please implement the missing logic for scrubbing non-visible data here')
          }

          if (infoElement.code === 'guaranteePerCustomsProcedure') {
            return false
          }

          const visible = field.visibleWhen(values, values)


          return !visible
        })
        .map(
          field => field.fieldCode
        )
      if (hiddenFields.length) {
        newValues[infoElement.code] = omit(newValues[infoElement.code], hiddenFields)
      }
    })

  return newValues
}
