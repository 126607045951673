import React from 'react'
import { Form, Button, Grid, Row, Col, FormControl } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import ReactMarkdown from 'react-markdown'
import ErrorBoundary from 'src/components/ErrorBoundary'
import Modal from 'src/components/Modal'
import LinkRenderer from 'src/components/LinkRenderer'
import FormControlGroup from 'src/components/form/FormControlGroup'
import Icon from 'src/components/Icon'
import Loader from 'src/components/Loader'
import { Field, touch } from 'redux-form'
import Dropzone from 'react-dropzone'
import { find, get, isEmpty } from 'lodash'
import { collectCmsMessages } from 'src/utils'
import { EditStepBase } from '../../../components/EditStepBase'
import EditDeclarationButtonToolbar from '../../../components/EditDeclarationButtonToolbar'
import FlowRadioFieldGroup from './FlowRadioFieldGroup'
import TdpReportingUnitColumn from './TdpReportingUnitColumn'
import PsiReportingUnitColumn from './PsiReportingUnitColumn'
import ReferencePeriods from './ReferencePeriods'
import ImportModal from './ImportModal'
import messages from '../messages'
import { ROWS_ROUTE_PATH, CONFIRMATION_ROUTE_PATH, INTRASTAT_FORM_NAME } from '../../../constants'
import {
  getStepPath,
  getDeclarationIdFromRoute,
  parseString,
  hasLiability,
} from '../../../utils'
import { AUTH_TYPES } from '../../../../../../constants'
import tdpMessages from '../../../messages'
import InlineNotification from '../../../components/InlineNotification'

/**
 * React presentational component for intrastat step 1: headers form
 */
export default class HeadersView extends EditStepBase {
  constructor(props) {
    super(props)
    this.resolveSubmitLocation = this.resolveSubmitLocation.bind(this)
    this.focusImportModalContent = this.focusImportModalContent.bind(this)
    this.state = {
      helpTexts: {},
    }
  }

  componentDidMount() {
    super.componentDidMount()
    const {
      selectPsi,
      selectedAuthorizationObject: {
        id: psiValue,
      },
      selectedDelegateCompanyObject,
      tdp,
    } = this.props
    const tdpValue = tdp || get(selectedDelegateCompanyObject, 'id', null)
    selectPsi(psiValue, tdpValue, false, psiValue === tdpValue)
    this.updateHelpTexts()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.cmsMessages !== this.props.cmsMessages) {
      this.updateHelpTexts()
    }

    if (prevProps.importing && !this.props.importing) {
      this.focusImportModalContent()
    }
  }

  focusImportModalContent() {
    if (this.modalContentRef) {
      this.modalContentRef.focus()
    }
  }

  // Handle the displaying of errors
  static getDerivedStateFromProps(nextProps) {
    if (nextProps.declarationId && nextProps.syncErr) {
      nextProps.dispatch(touch(INTRASTAT_FORM_NAME, ...Object.keys(nextProps.syncErr)))
    }
    if (nextProps.declarationId && nextProps.submitErr) {
      nextProps.dispatch(touch(INTRASTAT_FORM_NAME, ...Object.keys(nextProps.submitErr)))
    }

    return null
  }

  updateHelpTexts() {
    const helpTexts = collectCmsMessages('/declarations/intrastat', this.props.cmsMessages)
    this.setState({ helpTexts })
  }

  resolveSubmitLocation(location = null, declarationId) {
    const noRows = !this.props.rows || this.props.rows.length === 0
    // Jump over rows if noDeclarationRows is selected
    if (this.props.noDeclarationRows === true && location === getStepPath(ROWS_ROUTE_PATH, declarationId) && noRows) {
      return getStepPath(CONFIRMATION_ROUTE_PATH, declarationId)
    }
    return location
  }

  render() {
    const {
      submitting,
      submittingHeaders,
      importFromFile,
      handleSubmit,
      selectPsi,
      selectedAuthorizationObject,
      selectedDelegateCompanyObject,
      psi,
      tdp,
      tdpReportingUnit,
      locale,
      cachedCustomers,
      showImportModal,
      onHideImportModal,
      importing,
      importErrors,
      rows,
      rowSaveResult,
      rowSaveProgress,
      accepted,
      invalidated,
      declarationId,
      intl,
      fetchingCustomers,
      fetchingDeclaration,
      fetchingDeclarationRows,
      error,
      previousDeclarationId,
      userAcceptsInvalidTdp,
      onIgnoreInvalidTdp,
      auth,
      content,
      intl: { formatMessage },
      removeTdp,
      history,
    } = this.props

    const selectedAuthorizationId = get(selectedAuthorizationObject, 'id')
    const selectedDelegateCompanyId = get(selectedDelegateCompanyObject, 'id')
    const selectedPsiObject = find(cachedCustomers, { id: selectedAuthorizationId })
    const selectedTdpObject = tdp && find(cachedCustomers, { id: selectedDelegateCompanyId || tdp })
    const showTdpRemoveNotification = !selectedDelegateCompanyId && !isEmpty(selectedTdpObject)

    const shouldDisableForm =
      importing || submitting || accepted || fetchingDeclaration || fetchingDeclarationRows || invalidated || showTdpRemoveNotification
    const editingDeclaration = Boolean(getDeclarationIdFromRoute(this.props))

    const showInvalidTdpModal = !accepted && !userAcceptsInvalidTdp && selectedAuthorizationObject &&
      psi && ![psi, tdp].includes(selectedAuthorizationObject.id)

    return (
      <ErrorBoundary>
        <Form onSubmit={handleSubmit(this.stepSubmitHandler)}>
          {(fetchingDeclaration || fetchingDeclarationRows || fetchingCustomers || submitting || submittingHeaders) &&
            <Loader blocking />
          }
          {showTdpRemoveNotification &&
            <Grid fluid>
              <div>
                <InlineNotification
                  buttonText={formatMessage(tdpMessages.removeTdpNotificationButton)}
                  buttonIcon={'delete'}
                  onClick={() => removeTdp()}
                  warning
                >
                  <FormattedMessage
                    {...tdpMessages.removeTdpNotificationMessage}
                    values={{
                      tdpName: selectedTdpObject && `${selectedTdpObject.name || ''} (${selectedTdpObject.id})`,
                    }}
                  />
                </InlineNotification>
              </div>
            </Grid>
          }
          {showImportModal &&
            <ImportModal
              show={showImportModal}
              onHide={onHideImportModal}
              importErrors={importErrors}
              importing={importing}
              rowSaveProgress={rowSaveProgress}
              rowSaveResult={rowSaveResult}
              contentRef={(ref) => {
                this.modalContentRef = ref
              }}
            />
          }
          {showInvalidTdpModal &&
            <Modal
              show={showInvalidTdpModal}
              showContinue
              showCancel
              continueMessage={messages.fixInvalidTdp}
              cancelMessage={messages.ignoreInvalidTdp}
              onClickCancel={onIgnoreInvalidTdp}
              onClickContinue={() => selectPsi(psi, selectedAuthorizationObject.id, false, true)}
              focusButton
              titleMessage={messages.invalidTdpDescription}
            >
              <p className="lead">
                <FormattedMessage
                  {...messages.invalidTdpDescription}
                  values={{
                    tdpName: selectedTdpObject && selectedTdpObject.name,
                    tdpVat: tdp,
                    currentRoleName: selectedAuthorizationObject.name,
                    currentRoleVat: selectedAuthorizationObject.id,
                  }}
                />
              </p>
              <p className="lead">
                <FormattedMessage {...messages.invalidTdpConfirmation} />
              </p>
              <FormattedMessage tagName="p" {...messages.invalidTdpNotice} />
            </Modal>
          }
          {!fetchingDeclaration && !fetchingDeclarationRows && !(rows && rows.length) &&
            <div>
              <Grid fluid>
                <h3><FormattedMessage {...messages.importFromFile} /></h3>
                <Dropzone
                  disabled={showTdpRemoveNotification}
                  ref={(node) => {
                    this.dropzoneRef = node
                  }}
                  onDrop={files => importFromFile(declarationId, previousDeclarationId, files, intl, history)}
                  disableClick
                  multiple={false}
                  className="dropzone"
                  activeClassName="dropzone-active"
                >
                  {this.state.helpTexts && this.state.helpTexts.fileImport &&
                    <div className="dropzone-help">
                      <Icon name="info" className="text-primary" lg />
                      <ReactMarkdown
                        source={this.state.helpTexts.fileImport}
                        renderers={{ Link: LinkRenderer }}
                      />
                      {editingDeclaration &&
                        <p>
                          <strong>
                            <FormattedMessage {...messages.importToExistingDeclaration} />
                          </strong>
                        </p>
                      }
                    </div>
                  }
                  <div className="dropzone-buttons">
                    <Button
                      disabled={showTdpRemoveNotification}
                      onClick={() => {
                        this.dropzoneRef.open()
                      }}
                      bsStyle="primary"
                      id-qa-test="btn-import-file"
                    >
                      <Icon name="addnew" /><FormattedMessage {...messages.importFromFile} />
                    </Button>
                  </div>
                </Dropzone>
              </Grid>
            </div>
          }
          <Grid fluid>
            <h3><FormattedMessage {...messages.fillFormHeader} /></h3>
            {auth && auth.authorizations.filter(a => a.type !== AUTH_TYPES.private).length > 1 &&
              <Row className="bottom-margin">
                <Col sm={12}>
                  <p>{get(content, 'howToChangePsi')}</p>
                </Col>
              </Row>
            }
            <Row>
              <Col md={6} sm={8}>
                <Field
                  component={FormControlGroup}
                  name="psi"
                  props={{
                    label: messages.psiSelectionLabel,
                  }}
                >
                  <FormControl.Static>
                    {selectedPsiObject &&
                      `${selectedPsiObject.name || ''} (${selectedPsiObject.id})`}
                    {!selectedPsiObject &&
                      <FormattedMessage {...messages.tdpPlaceholderTdpEqualsPsi} />}
                  </FormControl.Static>
                </Field>
              </Col>
              <Col sm={4}>
                <PsiReportingUnitColumn
                  {...this.props}
                  hasLiability={hasLiability}
                  disabled={shouldDisableForm || !selectedPsiObject || fetchingCustomers || previousDeclarationId}
                  helpText={this.state.helpTexts.psiReportingUnit}
                />
              </Col>
            </Row>
            <Row>
              <Col md={6} sm={8}>
                <Field
                  component={FormControlGroup}
                  name="tdp"
                  props={{
                    label: messages.tdpLabel,
                  }}
                >
                  <FormControl.Static>
                    {selectedTdpObject &&
                      `${selectedTdpObject.name || ''} (${selectedTdpObject.id})`}
                    {!selectedTdpObject &&
                      <FormattedMessage {...messages.tdpPlaceholderTdpEqualsPsi} />}
                  </FormControl.Static>
                </Field>
              </Col>
              <Col sm={4}>
                <TdpReportingUnitColumn
                  disabled={
                    tdp !== selectedDelegateCompanyId ||
                    shouldDisableForm || fetchingCustomers || previousDeclarationId
                  }
                  helpText={this.state.helpTexts.tdpReportingUnit}
                  tdpId={tdp}
                  tdpReportingUnit={tdpReportingUnit}
                  tdpOrganization={selectedTdpObject}
                />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Field
                  component={FlowRadioFieldGroup}
                  name="flowCode"
                  onBlurNoParam
                  mandatory
                  locale={locale} // pass locale to trigger rerendering on locale change
                  props={{
                    disabled: shouldDisableForm || !selectedPsiObject || fetchingCustomers || previousDeclarationId,
                    label: messages.flowCodeInputLabel,
                    help: ({
                      content: this.state.helpTexts.flowCode,
                    }),
                  }}
                />
              </Col>
              <Col md={6}>
                <ReferencePeriods
                  {...this.props}
                  disabled={shouldDisableForm || !selectedPsiObject || fetchingCustomers || previousDeclarationId}
                  hasLiability={hasLiability}
                  helpText={this.state.helpTexts.referencePeriod}
                  selectedPsiObject={selectedPsiObject}
                />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Field
                  component={FormControlGroup}
                  name="noDeclarationRows"
                  parse={Boolean}
                  locale={locale} // pass locale to trigger rerendering on locale change
                  props={{
                    disabled: shouldDisableForm || previousDeclarationId,
                    componentClass: 'checkbox',
                    label: messages.noDeclarationRowsInputLabel,
                    placeholderMessage: messages.noDeclarationRowsInputPlaceholder,
                    help: ({
                      content: this.state.helpTexts.functionCode,
                    }),
                  }}
                />
              </Col>
              <Col md={6}>
                <Field
                  component={FormControlGroup}
                  name="reference"
                  parse={parseString}
                  props={{
                    disabled: shouldDisableForm,
                    type: 'text',
                    componentClass: 'input',
                    label: messages.referenceInputLabel,
                    placeholderMessage: messages.referenceInputPlaceholder,
                    help: ({
                      content: this.state.helpTexts.reference,
                    }),
                  }}
                />
              </Col>
            </Row>
          </Grid>

          <EditDeclarationButtonToolbar
            showBack={false}
            showNext
            disabled={importing || submitting || fetchingDeclaration}
            exitAction={this.exitAction}
            className={'headersView'}
          />
          {error && error.id &&
            <div className="text-danger text-right" role="alert"><FormattedMessage {...error} /></div>
          }

        </Form>
      </ErrorBoundary>
    )
  }
}
