import { rowHasItemNumber } from '../../../utils'

export function codesetToArray(codeset, locale) {
  if (!Array.isArray(codeset)) {
    return []
  }
  return codeset.reduce((accumulator, localizedItem) => {
    const itemWithLocale = localizedItem.find(item => item.locale === locale)
    return Object.assign(accumulator, { [itemWithLocale.code]: itemWithLocale.name })
  }, {})
}

export function generateRowsListData(rows, rowErrors) {
  const rowData = rows ? rows.map((row, index) => ({ ...row, index: rowHasItemNumber(rows) ? row.itemNumber : index })) : []
  if (rowErrors && rowErrors.length) {
    for (const [index, fieldErrors] of rowErrors.entries()) {
      if (!rowData[index]) {
        rowData[index] = {}
      }
      rowData[index].errors = { ...fieldErrors }
    }
  }
  return rowData
}

export function generateCodesetData(rawCodeset, locale) {
  if (rawCodeset) {
    return {
      memberStates: codesetToArray(rawCodeset.memberStates, locale),
      countriesOfOrigin: codesetToArray(rawCodeset.countriesOfOrigin, locale),
      countriesOfOriginForSimstat: codesetToArray(rawCodeset.countriesOfOriginForSimstat, locale),
      transactionTypes: codesetToArray(rawCodeset.transactionTypes, locale),
      transportTypes: codesetToArray(rawCodeset.transportTypes, locale),
    }
  }
  return {}
}
