import React from 'react'
import { Grid, Row, Col, FormGroup } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import moment from 'moment'
import { find } from 'lodash'
import {
  getStepPath,
  formatReferencePeriod,
} from '../utils'

import {
  HEADERS_ROUTE_PATH,
  SUMMARY_ROUTE_PATH,
} from '../constants'

import commonMessages from '../messages'
import headerMessages from '../routes/headers/messages'
import rowMessages from '../routes/rows/messages'
import summaryMessages from '../routes/summary/messages'
import confirmationMessages from '../routes/confirmation/messages'
import InlineNotification from './InlineNotification'

const messages = {
  ...commonMessages,
  ...headerMessages,
  ...rowMessages,
  ...confirmationMessages,
  ...summaryMessages,
}

export default function (props) {
  const {
    tdp,
    psi,
    psiReportingUnit,
    tdpReportingUnit,
    flowCode,
    referencePeriod,
    declarationId,
    previousDeclarationId,
    nextDeclarationId,
    cachedCustomers,
    totalNetMass,
    totalStatisticalValue,
    totalInvoicedAmount,
    dateOfModify,
    status,
    reference,
    rowCount,
    usePrintView,
    showTdpRemoveNotification,
  } = props

  const findOrganization = id => find(cachedCustomers, { id })
  const selectedTdpObject = tdp && findOrganization(tdp)
  const selectedPsiObject = psi && findOrganization(psi)
  const flowCodeValueMessageKey = flowCode === 'ARRIVAL_OF_GOODS' ? 'flowCodeArrival' : 'flowCodeDispatch'

  const lastSaveTime = new Date(dateOfModify).getTime()
  const formattedLastSaveTime = moment(lastSaveTime).format('l LT')

  const statusMessageMapping = {
    RECEIVED: messages.statusDraft,
    ACCEPTED: messages.statusReady,
    INCORRECT: messages.statusError,
    INVALIDATED: messages.statusInvalidated,
    DELETED: messages.statusDeleted,
    DEFACED: messages.statusDefaced,
  }

  return (
    <Grid fluid style={{ marginTop: '10px', marginBottom: '40px' }}>
      { !usePrintView && showTdpRemoveNotification &&
        <Row>
          <Col xs={12}>
            <InlineNotification info>
              <FormattedMessage
                {...messages.removeTdpInfoMessage}
              />
            </InlineNotification>
          </Col>
        </Row>
      }
      <Row>
        <Col md={9}>
          <Row>
            <Col md={6}>
              <FormGroup>
                <label>
                  <FormattedMessage {...messages.psiSelectionLabel} />
                </label>
                {selectedPsiObject &&
                  <p className="lead" id-qa-test="declarationSummary-psi">
                    {selectedPsiObject.name} ({selectedPsiObject.id}{psiReportingUnit && ` ${psiReportingUnit}`})
                  </p>
                }
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <label><FormattedMessage {...messages.flowCodeAndReferencePeriod} /></label>
                <p className="lead" id-qa-test="declarationSummary-flowCodeAndReferencePeriod">
                  {flowCode &&
                    <FormattedMessage {...messages[flowCodeValueMessageKey]}>{text => text}</FormattedMessage>
                  } {formatReferencePeriod(referencePeriod)}
                </p>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup>
                <label>
                  <FormattedMessage {...messages.tdpLabel} />
                </label>
                <p className="lead" id-qa-test="declarationSummary-tdp">
                  {selectedTdpObject &&
                    `${selectedTdpObject.name || ''}
                    (${selectedTdpObject.id}${tdpReportingUnit ? ` ${tdpReportingUnit}` : ''})`
                  }
                  {!selectedTdpObject &&
                    <FormattedMessage {...messages.tdpPlaceholderTdpEqualsPsi} />}
                </p>
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <label><FormattedMessage {...messages.declarationId} /></label>
                <p className="lead" id-qa-test="declarationSummary-declarationId">
                  {declarationId}
                </p>
              </FormGroup>
            </Col>

          </Row>
          <Row>
            <Col md={3}>
              <label><FormattedMessage {...messages.totalNumberLines} /></label>
            </Col>
            <Col md={3}>
              <span id-qa-test="declarationSummary-rowCount">{rowCount || 0}</span>
            </Col>
            <Col md={3}>
              <label><FormattedMessage {...messages.totalInvoicedAmount} /></label>
            </Col>
            <Col md={3}>
              <span id-qa-test="declarationSummary-totalInvoicedAmount">{totalInvoicedAmount} €</span>
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <label><FormattedMessage {...messages.totalNetMass} /></label>
            </Col>
            <Col md={3}>
              <span id-qa-test="declarationSummary-totalNetMass">{`${totalNetMass} kg`}</span>
            </Col>
            <Col md={3}>
              <label><FormattedMessage {...messages.totalStatisticalValue} /></label>
            </Col>
            <Col md={3}>
              <span id-qa-test="declarationSummary-totalStatisticalValue">{totalStatisticalValue} €</span>
            </Col>
          </Row>
        </Col>
        <Col md={3}>
          <FormGroup>
            <label><FormattedMessage {...messages.status} /></label>
            <div>
              <p className="lead" id-qa-test="declarationSummary-status">
                {status && <FormattedMessage {...statusMessageMapping[status]}>{text => text}</FormattedMessage>}
              </p>
            </div>
          </FormGroup>
          {previousDeclarationId && !showTdpRemoveNotification &&
            <FormGroup>
              <label><FormattedMessage {...messages.previousDeclaration} /></label>
              <p>
                <Link to={getStepPath(SUMMARY_ROUTE_PATH, previousDeclarationId)}>
                  {previousDeclarationId}
                </Link>
              </p>
            </FormGroup>
          }
          {nextDeclarationId && !showTdpRemoveNotification &&
            <FormGroup>
              <label><FormattedMessage {...messages.nextDeclaration} /></label>
              <p>
                <Link to={getStepPath(HEADERS_ROUTE_PATH, nextDeclarationId)}>
                  {nextDeclarationId}
                </Link>
              </p>
            </FormGroup>
          }
          <FormGroup>
            <label>
              <FormattedMessage {...messages.reference} />
            </label>
            <p id-qa-test="declarationSummary-reference">
              {reference}
            </p>
          </FormGroup>
          <FormGroup>
            <label>
              <FormattedMessage {...messages.lastSaveLabel} />
            </label>
            <p id-qa-test="declarationSummary-lastSaveTime">
              {formattedLastSaveTime}
            </p>
          </FormGroup>
        </Col>
      </Row>
    </Grid>
  )
}
