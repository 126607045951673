import React, { useEffect } from 'react'
import { Grid, Col, Row, Button, ButtonToolbar } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import { get, isEmpty, find } from 'lodash'
import moment from 'moment'
import InfoArea from 'src/components/InfoArea'
import Icon from 'src/components/Icon'
import ErrorBoundary from 'src/components/ErrorBoundary'
import PanelNavigation from 'src/components/PanelNavigation'
import Heading from 'src/layout/Heading'
import messages from '../../messages'
import { FORMS_ROUTE_PATH } from '../../../constants'
import { EORI_FORM_ROUTE_PATH, SUMMARY_ROUTE_PATH } from '../../constants'

const renderContentByName = (formData, name) => {
  const content = get(formData, 'data') || []
  const item = find(content, { id: name })
  return (
    <div>
      <b>{get(item, 'title')}</b><br />
      {get(item, 'value')}<br /><br />
    </div>
  )
}

const renderVATCountries = (formData) => {
  const content = get(formData, 'data') || []
  const vatCountries = find(content, { id: 'vatNumbers' })
  const rows = vatCountries && get(vatCountries, 'value').split('\n')

  if (rows) {
    return ([
      <Row>
        <Col xs={12} md={4}>
          <b>{get(vatCountries, 'title')}</b><br />
          {rows.map((item, index) =>
            <span key={index}>{item}<br /></span>
          )}
          <br /><br />
        </Col>
      </Row>,
    ])
  }
  return null
}

function Summary(props) {
  const {
    formData,
    history,
    match,
  } = props

  useEffect(() => {
    if (isEmpty(formData)) {
      history.replace(`/${FORMS_ROUTE_PATH}/${EORI_FORM_ROUTE_PATH}`)
    }
  }, [formData])

  const date = get(formData, 'date', '')
  const dateFormatted = moment(date).format('DD.MM.YYYY HH:mm:ss')
  const code = get(formData, 'id', '')

  const usePrintView = get(match, 'params.viewMode') === 'print'
  let logoRowButtons
  if (usePrintView) {
    logoRowButtons = (
      <div className="btn-toolbar pull-right">
        <Button onClick={() => window.print()} id-qa-test="btn-print">
          <Icon name="printer" /><FormattedMessage {...messages.print} />
        </Button>
        <Button onClick={() => history.goBack()} id-qa-test="btn-close">
          <Icon name="close" /><FormattedMessage {...messages.close} />
        </Button>
      </div>
    )
  }
  const heading = (
    <Heading
      message={messages.heading}
      logoRowContent={logoRowButtons}
      print={usePrintView}
      titleExtras={usePrintView}
    />
  )

  return (
    <div>
      {heading}
      <main className="container" role="main" id="main">
        <Row>
          <Col xs={12}>
            {!usePrintView && <InfoArea
              icon="checkmark"
            >
              <FormattedMessage {...messages.applicationReceived} values={{ code }} />
            </InfoArea>}
            <ErrorBoundary>
              <PanelNavigation
                title={{
                  ...messages.summaryTitle,
                  values: {
                    dateFormatted,
                  },
                }}
                active
              >
                <Grid fluid>
                  <Row>
                    <Col xs={12}>
                      <h4><FormattedMessage {...messages.headingPanelApplicantInformation} /></h4>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={4}>
                      {renderContentByName(formData, 'companyFullName')}
                    </Col>
                    <Col xs={12} md={4}>
                      {renderContentByName(formData, 'customerId')}
                    </Col>
                    <Col xs={12} md={4}>
                      {renderContentByName(formData, 'alvNumber')}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={4}>
                      {renderContentByName(formData, 'address')}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={4}>
                      {renderContentByName(formData, 'zipCode')}
                    </Col>
                    <Col xs={12} md={4}>
                      {renderContentByName(formData, 'city')}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12}>
                      <hr />
                      <h4><FormattedMessage {...messages.headingPanelContactInformation} /></h4>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={4}>
                      {renderContentByName(formData, 'contactName')}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={4}>
                      {renderContentByName(formData, 'contactEmail')}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12}>
                      <hr />
                      <h4><FormattedMessage {...messages.headingPanelVATNumbers} /></h4>
                    </Col>
                  </Row>
                  {renderVATCountries(formData)}
                </Grid>
                {!usePrintView && <ButtonToolbar>
                  <Link active={false} to={'/'}>
                    <Button
                      bsStyle="default"
                      id-qa-test="button-return"
                    >
                      <Icon name="close" /> <FormattedMessage {...messages.close} />
                    </Button>
                  </Link>
                  <Link
                    to={`/${FORMS_ROUTE_PATH}/${EORI_FORM_ROUTE_PATH}/${SUMMARY_ROUTE_PATH}/print`}
                  >
                    <Button
                      bsStyle="default"
                      className="pull-right"
                      id-qa-test="button-print"
                    >
                      <Icon name="printer" /> <FormattedMessage {...messages.printing} />
                    </Button>
                  </Link>
                </ButtonToolbar>}
              </PanelNavigation>
            </ErrorBoundary>
          </Col>
        </Row>
      </main>
    </div>
  )
}

export default Summary