import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { FormattedMessage, injectIntl } from 'react-intl'
import moment from 'moment'
import { range } from 'src/utils'
import componentMessages from 'src/components/messages'

import messages from '../messages'

class DateOfBirthFields extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      dateOfBirthInvalid: false,
    }

    moment.locale(props.locale)

    this.dateSelectionObj = {
      days: [],
      months: [],
      years: [],
    }
    Array.from(range(1, 32)).map(day =>
      this.dateSelectionObj.days.push({ id: day, value: `${day}`, title: day })
    )
    moment.months().map((month, index) =>
      this.dateSelectionObj.months.push({ id: index + 1, value: `${index + 1}`, title: month })
    )
    Array.from(range(moment().year() - 100, moment().year() - 15))
      .reverse()
      .map(year =>
        this.dateSelectionObj.years.push({ id: year, value: `${year}`, title: year })
      )
  }

  renderElement(field, options) {
    const {
      locale,
      renderField,
      intl: { formatMessage },
    } = this.props

    const titles = {
      day: messages.dateOfBirthDayPlaceholder,
      month: messages.dateOfBirthMonthPlaceholder,
      year: messages.dateOfBirthYearPlaceholder,
    }

    return renderField({
      field: {
        ...field,
        name: titles[field.code],
      },
      input: {
        placeholder: formatMessage(titles[field.code]),
        customAriaLabelledBy: 'dob-error-message',
      },
      locale,
      showLabel: false,
      options,
    })
  }

  render() {
    const {
      field,
      locale,
      dateOfBirthInvalid,
      formApi,
    } = this.props

    const dayField = {
      ...field,
      code: 'day',
      type: 'SELECT',
    }
    const monthField = {
      ...field,
      code: 'month',
      type: 'SELECT',
    }
    const yearField = {
      ...field,
      code: 'year',
      type: 'SELECT',
    }

    const yearValue = formApi.getValue('year')
    const monthValue = formApi.getValue('month')
    const dayValue = formApi.getValue('day')

    const className = dateOfBirthInvalid ? 'customLabel text-danger' : 'customLabel'

    return (
      <div role="group" aria-labelledby="dob-group">
        <span id="dob-group" className={className}>
          {field.name[locale]} {!field.mandatory && <FormattedMessage {...componentMessages.formFieldOptional}>{message => `(${message})`}</FormattedMessage>}
        </span>
        <Row>
          <React.Fragment>
            <Col md={4} className={dateOfBirthInvalid && !dayValue ? 'has-error' : ''}>
              {this.renderElement(
                dayField,
                this.dateSelectionObj.days,
              )}
            </Col>
            <Col md={4} className={dateOfBirthInvalid && !monthValue ? 'has-error' : ''}>
              {this.renderElement(
                monthField,
                this.dateSelectionObj.months,
              )}
            </Col>
            <Col md={4} className={dateOfBirthInvalid && !yearValue ? 'has-error' : ''}>
              {this.renderElement(
                yearField,
                this.dateSelectionObj.years,
              )}
            </Col>
          </React.Fragment>
          {dateOfBirthInvalid &&
          <div id="dob-error-message" className="text-danger customErrorMessage">
            <FormattedMessage {...messages.dateNotValid} />
          </div>
          }
        </Row>
      </div>
    )
  }
}

export default injectIntl(DateOfBirthFields)
