import logger from 'src/utils/logger'
import { memoize } from 'lodash'
import { apiCall } from 'src/utils/http'
import { getStore } from '../../store'

function get(url) {
  return apiCall(url, { method: 'GET' }, {}, undefined, false)
    .catch((error) => {
      logger.error('API call failed', error)
      throw error
    })
}

const getTmApaUrl = memoize(() => getStore()
  .getState().config.bootstrapConfig.tm_apa_ext_url)

const api = {
  searchMRN: (MRN, goodsItem, transportDocumentID, isUtuEntryMrn) => {
    const params = [
      ['transportDocumentID', transportDocumentID && encodeURI(transportDocumentID.replaceAll(/^\s+|\s+$/g, ''))],
      ['goodsItem', goodsItem],
      ['isUtuEntry', isUtuEntryMrn ? isUtuEntryMrn : undefined],
    ].filter(
      // eslint-disable-next-line no-unused-vars
      ([key, value]) => value,
    )
    const query = params.length ? `?${new URLSearchParams(params).toString()}` : ''
    return get(`${getTmApaUrl()}/mrn/${MRN}${query}`)
  },
  getCountryCodesEU: () => get(`${window.helpServiceUrl}/config/countryCodes`),
}

export default api
