import moment from 'moment'
import { get } from 'lodash'

import {
  USE_EQUIVALENT_GOOD_FIELD_NAME,
} from '../GoodsToBePlacedUnderProcedure/constants'
import {
  EUS,
  OPO,
  PERMIT_GOODS_COMBINED_IN,
  PERMIT_GOODS_CW_NOT_MANDATORY_IN,
  PERMIT_GOODS_TARIC_CODE_MANDATORY_IN,
  PERMIT_GOODS_UNDER_PROCEDURE_FULL_FEATURED,
  PERMIT_EQUIVALENT_GOOD_FULL_FEATURED,
  PERMIT_PROCESSED_PRODUCT_FULL_FEATURED,
  PERMIT_REPLACEMENT_PRODUCT_FULL_FEATURED,
  PERMIT_ORIGINATING_PRODUCT_FULL_FEATURED,
  PERMIT_GOODS_UNDER_PROCEDURE_QUANTITY_FEATURED,
  PERMIT_TYPE_CODE_PPP,
  FLAG_YES,
  MESSAGE_SERVICE_USAGE_YES_CODE,
  PERSON_ALLOWED_PERMIT_TYPES,
  HIDE_AEO_PERMIT_TYPES,
} from '../../constants'
import {
  PERMIT_GOODS_FIELD_PREFIX,
  PERMIT_GOODS_CODE_FIELD_NAME,
  PERMIT_GOODS_UNDER_PROCEDURE_BASIC_FEATURES,
  PERMIT_EQUIVALENT_GOOD_CODE_FIELD_NAME,
  PERMIT_EQUIVALENT_GOOD_BASIC_FEATURES,
  PERMIT_PROCESSED_PRODUCT_CODE_FIELD_NAME,
  PERMIT_REPLACEMENT_PRODUCT_CODE_FIELD_NAME,
  PERMIT_ORIGINATING_PRODUCT_CODE_FIELD_NAME,
  PERMIT_ORIGINATING_PRODUCT_FIELD_PREFIX,
  TARIC_CODE_FIELD_NAME,
  FIELD_REQUIRED_BY,
  APPLICANT_ROLE_MESSAGE_NOTIFIER,
  APPLICANT_ROLE_SERVICE_PROVIDER,
  APPLICANT_ROLE_SOFTWARE_HOUSE,
  PERMIT_GOODS_TO_ENJOY_RELIEF_FROM_DUTIES_CODE_FIELD_NAME,
  PERMIT_GOODS_TO_ENJOY_RELIEF_FROM_DUTIES_FIELD_PREFIX,
  PERMIT_GOODS_TO_ENJOY_RELIEF_FROM_DUTIES_CURRENCY_FIELD_NAME,
  PERMIT_GOODS_TO_ENJOY_RELIEF_FROM_DUTIES_AMOUNT_FIELD_NAME,
  hiddenInfoElements,
} from './constants'
import messages from '../../messages'
import { AUTH_TYPES } from '../../../../../constants'

export function getInfoElementCustomParams(infoElementCode, additionalParams) {
  const visibleWhen = getInfoElementVisibleWhenFunction(infoElementCode)
  const requiredWhen = getInfoElementRequiredWhenFunction(infoElementCode, additionalParams.applicationTypeCode)

  return {
    visible: !hiddenInfoElements.includes(infoElementCode),
    visibleWhen,
    requiredWhen,
    ...getOtherInfoElementCustomParams(infoElementCode, additionalParams),
  }
}

/* Returns a function that returns true if the infoelement should be visible based
 * on field values.
 * The returned function takes one parameters:
 *  applicationValues: Always contains all the values of the form
 */
function getInfoElementVisibleWhenFunction(code) {
  switch (code) {
  case 'messageExchanger':
    return (({ messageCustomerRole = {} }) =>
      messageCustomerRole.ApplicantRole === APPLICANT_ROLE_MESSAGE_NOTIFIER
    )
  case 'useOfMessageDeclarantProvider':
    return (({ messageCustomerRole = {}, messageExchanger = {} }) =>
      messageCustomerRole.ApplicantRole === APPLICANT_ROLE_MESSAGE_NOTIFIER && messageExchanger.MessageProvider
    )
  case 'messageCustomerContact':
    return (({ messageCustomerRole = {}, messageExchanger = {} }) =>
      messageCustomerRole.ApplicantRole === APPLICANT_ROLE_SERVICE_PROVIDER ||
      (
        messageCustomerRole.ApplicantRole === APPLICANT_ROLE_MESSAGE_NOTIFIER &&
        messageExchanger.MessageProviderDeclarant
      )
    )
  case 'messageServiceUse':
    return showMessageServiceUseField
  default:
    return null
  }
}

function getInfoElementRequiredWhenFunction(code, applicationTypeCode) {
  switch (code) {
  case 'guarantee':
    if (applicationTypeCode === OPO) {
      return formValues => (
        Number(formValues?.replacementProductPriorImport?.replacementProductPriorImport) === FLAG_YES ||
          Number(formValues?.processedProductsPriorImportOPIMEX?.processedProductsPriorImportOPIMEX) === FLAG_YES
      )
    }
    return null
  case 'equivalentGoods':
    return (formValues => formValues[USE_EQUIVALENT_GOOD_FIELD_NAME] === 'true')
  case 'goodsIdentification':
    return (formValues => formValues[USE_EQUIVALENT_GOOD_FIELD_NAME] !== 'true')
  case 'useOfMessageDeclarantProvider':
    return (({ messageCustomerRole = {}, messageExchanger = {} }) =>
      messageCustomerRole.ApplicantRole === APPLICANT_ROLE_MESSAGE_NOTIFIER && messageExchanger.MessageProvider
    )
  default:
    return null
  }
}

export function getOtherInfoElementCustomParams(infoElementCode, { customerHasAEO, applicationTypeCode }) {
  switch (infoElementCode) {
  case 'concentToSendingDecisionElectronically':
    return { mandatory: true }
  case 'applicantCustomsResponsible':
    return {
      mandatory: true,
    }
  case 'personInCharge':
  case 'accountsLocation':
  case 'accountsType':
    return { mandatory: !customerHasAEO || HIDE_AEO_PERMIT_TYPES.includes(applicationTypeCode) }
  case 'goodsToBePlacedUnderProcedure':
    return {
      mandatory: true,
      codeFieldName: PERMIT_GOODS_CODE_FIELD_NAME,
    }
  case 'goodsToEnjoyReliefFromDuties':
    return {
      mandatory: true,
      codeFieldName: PERMIT_GOODS_TO_ENJOY_RELIEF_FROM_DUTIES_CODE_FIELD_NAME,
    }
  case 'processedProduct':
  {
    const goodProps = {
      codeFieldName: PERMIT_PROCESSED_PRODUCT_CODE_FIELD_NAME,
    }
    if (applicationTypeCode === EUS) {
      return {
        ...goodProps,
        mandatory: true,
      }
    }
    return { ...goodProps }
  }
  case 'replacementProduct':
    return {
      codeFieldName: PERMIT_REPLACEMENT_PRODUCT_CODE_FIELD_NAME,
    }
  case 'originatingProducts':
    return {
      codeFieldName: PERMIT_ORIGINATING_PRODUCT_CODE_FIELD_NAME,
      fieldPrefix: PERMIT_ORIGINATING_PRODUCT_FIELD_PREFIX,
    }
  case 'equivalentGood':
    return {
      codeFieldName: PERMIT_EQUIVALENT_GOOD_CODE_FIELD_NAME,
    }
  case 'operationCount':
  case 'goodsIdentification':
    return { mandatory: true }
  // Allow making the conditionally shown fields in this infoElement mandatory.
  // This might be redundant if the SSA form is changed
  case 'messageWarehouseInterface':
    return { mandatory: true }
  case 'attachedDocument': {
    if (PERSON_ALLOWED_PERMIT_TYPES.includes(applicationTypeCode)) {
      return {
        descriptionText: { id: '/permits/reliefPermitAttachmentDescription' },
      }
    }
    return {}
  }
  case 'messageExchanger':
    return {
      validate: ({ messageCustomerRole = {}, messageExchanger = {} }) => {
        const applicantRole = messageCustomerRole?.ApplicantRole
        const valid =
          applicantRole !== APPLICANT_ROLE_MESSAGE_NOTIFIER ||
          messageExchanger?.MessageProviderDeclarant || messageExchanger?.MessageProvider
        if (!valid) {
          return messages.checkAtLeastOne
        }
        return undefined
      },
    }
  case 'useOfMessageDeclarantProvider':
    return {
      validate: (values, infoElement) => {
        // Dont care if not required
        if (!infoElement.requiredWhen(values, infoElement)) {
          return undefined
        }

        const allFieldsValid = values?.useOfMessageDeclarantProvider?.some(repetitiveItem =>
          repetitiveItem.messageDeclarantProviderApp
            && repetitiveItem.messageDeclarantProviderIdentification)

        if (!allFieldsValid) {
          return messages.stepMissingRequiredFields
        }

        return undefined
      },
    }
  default:
    return {}
  }
}

export function getFieldCustomParams(field, infoElement, opts) {
  const { applicationTypeCode, customerHasAEO, authType, codesets, applicationDate } = opts

  const visible = getFieldVisibility(field.code, infoElement.code, opts.applicationTypeCode)
  const visibleInCombinedLists = isVisibleInCombinedList(infoElement.code, field.code)

  const isSingleField = infoElement.type === 'SINGLE'
  const code = isSingleField ? `${infoElement.code}.${field.code}` : field.code

  const requiredByField = FIELD_REQUIRED_BY[field.code]
  const requiredWhen = getFieldRequiredWhenFunction(code, { applicationTypeCode, codesets })
  const visibleWhen = getFieldVisibleWhenFunction(code, { codesets, applicationDate })

  return {
    visible,
    visibleInCombinedLists,
    requiredByField,
    requiredWhen,
    visibleWhen,
    name: getFieldCustomName(field),
    ...getOtherFieldCustomParams(code, {
      customerHasAEO,
      applicationTypeCode,
      authType,
      infoElement,
    }),
  }
}

function getFieldCustomName(field) {
  switch (field.code) {
  case 'messageDeclarantProviderApp':
    return {
      fi: 'Asiointisovellus',
      sv: 'E-tjänst',
      en: 'Transaction application',
    }
  case 'goodsToBePlacedUnderProcedureCombinedNomenclatureAndTaricCode':
  case 'processedProductCombinedNomenclatureCode':
  case 'originatingGoodsCombinedNomenclature':
  case 'replacementProductTaricCode':
  case 'equivalentGoodCombinedNomenclatureCode':
  case 'combinedNomenclatureCode':
    return TARIC_CODE_FIELD_NAME
  default:
    return field.name
  }
}

/* Returns a function that returns true if the fields should be mandatory based
 * on other field values.
 * The returned function takes two parameters:
 *  1) form values: Contains the values of the current form. This may be either
 *     the whole permit form or the values in a single repetition of a
 *     repetitive info element (eg. GoodsToBePlacedUnderProcedure)
 *  2) applicationValues: Always contains all the values of the form
 */
function getFieldRequiredWhenFunction(fieldCode, { applicationTypeCode, codesets }) {
  switch (fieldCode) {
  case 'equivalentGoodCombinedNomenclatureCode':
  case 'equivalentGoodDescription':
  case 'equivalentGoodsIdentification':
  case 'equivalentGoodsAntidumping':
    return (formValues => formValues[USE_EQUIVALENT_GOOD_FIELD_NAME] === 'true')
  case 'goodsIdentificationAdditionalInformation':
  case 'goodsIdentification':
    return (formValues => formValues[USE_EQUIVALENT_GOOD_FIELD_NAME] !== 'true')
  case 'priorExportationIPEXIM.priorExportationIPEXIMTimeLimitMonths':
    return (formValues => formValues['priorExportationIPEXIM.priorExportationIPEXIM'] === FLAG_YES)
  case 'guaranteeGRN':
    if (applicationTypeCode === OPO) {
      return (_, applicationValues) =>
        (
          Number(applicationValues.replacementProductPriorImport?.replacementProductPriorImport) === FLAG_YES ||
          Number(applicationValues.processedProductsPriorImportOPIMEX?.processedProductsPriorImportOPIMEX) === FLAG_YES
        )
    }
    return null
  case 'messageCustomerContact.messageSoftwareSupplierInfo':
    return isMessageSupplierInfoUsed
  case 'messageDeclarations2.messageIds':
    return ({ messageWarehouseInterface }) => {
      const isMessageInterface = messageWarehouseInterface?.messageInterface
      return isMessageInterface !== 'true'
    }
  case 'messageDeclarantProviderIdentification':
  case 'messageDeclarantProviderApp':
    return (_, applicationValues) => {
      const { messageCustomerRole, messageExchanger } = applicationValues
      return messageCustomerRole?.ApplicantRole === APPLICANT_ROLE_MESSAGE_NOTIFIER &&
        messageExchanger?.MessageProvider
    }
  case 'messageCustomerContact.messageSoftwareSupplierName':
    return (({ messageCustomerContact = {} }) =>
      messageCustomerContact?.messageCustomerHimself === 'false'
    )
  case 'reasonRevocation.additionalInformation':
    return (({ reasonRevocation }) => reasonRevocation && reasonRevocation.reasonRevocation === '9')
  case PERMIT_GOODS_TO_ENJOY_RELIEF_FROM_DUTIES_AMOUNT_FIELD_NAME:
  case PERMIT_GOODS_TO_ENJOY_RELIEF_FROM_DUTIES_CURRENCY_FIELD_NAME: {
    if (PERSON_ALLOWED_PERMIT_TYPES.includes(applicationTypeCode)) {
      return formValues => !!formValues?.[PERMIT_GOODS_TO_ENJOY_RELIEF_FROM_DUTIES_AMOUNT_FIELD_NAME] || !!formValues?.[PERMIT_GOODS_TO_ENJOY_RELIEF_FROM_DUTIES_CURRENCY_FIELD_NAME]
    }
    return null
  }
  case 'applicantCustomsResponsible.applicantCustomsResponsiblePhone':
  case 'applicantCustomsResponsible.applicantCustomResponsibleEmail':
    return (({ checkboxSameAsCustomsResponsibleRadio }) => checkboxSameAsCustomsResponsibleRadio && checkboxSameAsCustomsResponsibleRadio !== 'noInput')
  case 'applicantCustomsResponsible.applicantCustomsResponsibleName':
  case 'applicantCustomsResponsible.applicantCustomsResponsibleDateOfBirth':
  case 'applicantCustomsResponsible.applicantCustomsResponsibleNationalIdentificationNumber':
    return (({ checkboxSameAsCustomsResponsibleRadio }) => checkboxSameAsCustomsResponsibleRadio === 'otherPerson' || checkboxSameAsCustomsResponsibleRadio === 'sameAsUser')
  case 'applicantCustomsResponsible.applicantCustomsResponsibleDateEori':
    return (({ checkboxSameAsCustomsResponsibleRadio }) => checkboxSameAsCustomsResponsibleRadio === 'otherCompany')
  case 'guaranteeExistingDebt':
    return ({ guaranteeCustomsProcedure, guaranteePotentialDebt }) => {
      if (!guaranteeCustomsProcedure) {
        return false
      }

      const customsProcedureGuaranteeCodeset = getCodeset('CustomsProcedureGuarantee', { codesets })

      if (!customsProcedureGuaranteeCodeset) {
        return false
      }

      const selectedCustomsProcedureGuaranteeCodeset = Object.values(
        customsProcedureGuaranteeCodeset).find(customsProcedureGuarantee =>
        customsProcedureGuarantee.code === guaranteeCustomsProcedure
      )

      if (!selectedCustomsProcedureGuaranteeCodeset) {
        return false
      }

      const isExisting = get(selectedCustomsProcedureGuaranteeCodeset, 'extensionValues.velka')
      const isPotential = get(selectedCustomsProcedureGuaranteeCodeset, 'extensionValues.vastuu')

      if (isExisting && isPotential && !guaranteePotentialDebt) {
        return true
      }

      if (isExisting && !isPotential) {
        return true
      }

      return false
    }
  case 'guaranteePotentialDebt':
    return ({ guaranteeCustomsProcedure, guaranteeExistingDebt }) => {
      if (!guaranteeCustomsProcedure) {
        return false
      }

      const customsProcedureGuaranteeCodeset = getCodeset('CustomsProcedureGuarantee', { codesets })

      if (!customsProcedureGuaranteeCodeset) {
        return false
      }

      const selectedCustomsProcedureGuaranteeCodeset = Object.values(
        customsProcedureGuaranteeCodeset).find(customsProcedureGuarantee =>
        customsProcedureGuarantee.code === guaranteeCustomsProcedure
      )

      if (!selectedCustomsProcedureGuaranteeCodeset) {
        return false
      }

      const isExisting = get(selectedCustomsProcedureGuaranteeCodeset, 'extensionValues.velka')
      const isPotential = get(selectedCustomsProcedureGuaranteeCodeset, 'extensionValues.vastuu')

      if (isExisting && isPotential && !guaranteeExistingDebt) {
        return true
      }

      if (!isExisting && isPotential) {
        return true
      }

      return false
    }
  default:
    return null
  }
}

/* Returns a function that returns true if the fields should be visible based
 * on other field values.
 * The returned function takes two parameters:
 *  1) form values: Contains the values of the current form. This may be either
 *     the whole permit form or the values in a single repetition of a
 *     repetitive info element (eg. GoodsToBePlacedUnderProcedure)
 *  2) applicationValues: Always contains all the values of the form
 */
function getFieldVisibleWhenFunction(fieldCode, opts) {
  switch (fieldCode) {
  case 'messageCustomerContact.messageSoftwareSupplierName':
    return (({ messageCustomerContact = {} }) =>
      messageCustomerContact?.messageCustomerHimself === 'false'
    )
  case 'messageCustomerContact.messageSoftwareSupplierInfo':
    return isMessageSupplierInfoUsed
  case 'messageServiceUse.messageServiceUsage':
    return showMessageServiceUseField
  case 'messageServiceUse.messageTestUrl':
  case 'messageServiceUse.messageProductionUrl':
    return (formValues =>
      showMessageServiceUseField(formValues) &&
      formValues?.messageServiceUse?.messageServiceUsage === MESSAGE_SERVICE_USAGE_YES_CODE
    )
  case 'messageWarehouseInterface.messageWarehouseTestUrl':
  case 'messageWarehouseInterface.messageWarehouseProductionUrl':
    return (formValues =>
      formValues?.messageWarehouseInterface?.messageInterface === 'true'
    )
  case 'messageServiceUse.testingOfAttachments':
    return (({ messageCustomerRole = {}, messageCustomerContact = {} }) =>
      messageCustomerRole?.ApplicantRole === APPLICANT_ROLE_SOFTWARE_HOUSE ||
      messageCustomerContact?.messageCustomerHimself === 'true'
    )
  case 'reasonRevocation.additionalInformation':
    return (({ reasonRevocation }) => reasonRevocation && reasonRevocation.reasonRevocation === '9')
  case 'applicantCustomsResponsible.applicantCustomsResponsiblePhone':
  case 'applicantCustomsResponsible.applicantCustomResponsibleEmail':
    return (({ checkboxSameAsCustomsResponsibleRadio }) => checkboxSameAsCustomsResponsibleRadio && checkboxSameAsCustomsResponsibleRadio !== 'noInput')
  case 'applicantCustomsResponsible.applicantCustomsResponsibleName':
  case 'applicantCustomsResponsible.applicantCustomsResponsibleDateOfBirth':
  case 'applicantCustomsResponsible.applicantCustomsResponsibleNationalIdentificationNumber':
    return (({ checkboxSameAsCustomsResponsibleRadio }) => checkboxSameAsCustomsResponsibleRadio === 'otherPerson' || checkboxSameAsCustomsResponsibleRadio === 'sameAsUser')
  case 'applicantCustomsResponsible.applicantCustomsResponsibleDateEori':
    return (({ checkboxSameAsCustomsResponsibleRadio }) => checkboxSameAsCustomsResponsibleRadio === 'otherCompany')
  case 'guaranteeExistingDebt':
  case 'guaranteeExistingDebtCurrency':
  case 'guaranteeExistingDebtDescription':
    return ({ guaranteeCustomsProcedure }) => {
      if (!guaranteeCustomsProcedure) {
        return false
      }
      
      const customsProcedureGuaranteeCodeset = getCodeset('CustomsProcedureGuarantee', opts)

      if (!customsProcedureGuaranteeCodeset) {
        return false
      }

      const selectedCustomsProcedureGuaranteeCodeset = Object.values(
        customsProcedureGuaranteeCodeset).find(customsProcedureGuarantee =>
        customsProcedureGuarantee.code === guaranteeCustomsProcedure
      )

      if (!selectedCustomsProcedureGuaranteeCodeset) {
        return false
      }

      const isExisting = get(selectedCustomsProcedureGuaranteeCodeset, 'extensionValues.velka')

      if (isExisting) {
        return true
      }

      return false
    }
  case 'guaranteePotentialDebt':
  case 'guaranteePotentialDebtCurrency':
  case 'guaranteePotentialDebtDescription':
    return ({ guaranteeCustomsProcedure }) => {
      if (!guaranteeCustomsProcedure) {
        return false
      }

      const customsProcedureGuaranteeCodeset = getCodeset('CustomsProcedureGuarantee', opts)

      if (!customsProcedureGuaranteeCodeset) {
        return false
      }

      const selectedCustomsProcedureGuaranteeCodeset = Object.values(
        customsProcedureGuaranteeCodeset).find(customsProcedureGuarantee =>
        customsProcedureGuarantee.code === guaranteeCustomsProcedure
      )

      if (!selectedCustomsProcedureGuaranteeCodeset) {
        return false
      }

      const isPotential = get(selectedCustomsProcedureGuaranteeCodeset, 'extensionValues.vastuu')

      if (isPotential) {
        return true
      }

      return false
    }
  default:
    return null
  }
}

function isMessageSupplierInfoUsed({ messageCustomerContact }) {
  if (!messageCustomerContact) {
    return false
  }
  const customerHimself = messageCustomerContact.messageCustomerHimself
  const supplier = messageCustomerContact.messageSoftwareSupplierName
  const SUPPLIER_OTHER = '99'
  return customerHimself === 'false' && supplier === SUPPLIER_OTHER
}

function showMessageServiceUseField({ messageCustomerRole = {}, messageExchanger = {} }) {
  switch (messageCustomerRole.ApplicantRole) {
  case APPLICANT_ROLE_MESSAGE_NOTIFIER:
    return messageExchanger.MessageProviderDeclarant
  case APPLICANT_ROLE_SERVICE_PROVIDER:
  case APPLICANT_ROLE_SOFTWARE_HOUSE:
    return true
  default:
    return false
  }
}

function getOtherFieldCustomParams(fieldCode, { applicationTypeCode, customerHasAEO, infoElement, authType }) {
  switch (infoElement.code) {
  case 'applicantCustomsResponsible':
  case 'accountsLocation':
  case 'accountsType':
    return { mandatory: !customerHasAEO || HIDE_AEO_PERMIT_TYPES.includes(applicationTypeCode) }
  case 'personInCharge':
    return { mandatory: true, overrideInfoElementMandatory: true }
  default:
  }

  switch (fieldCode) {
  case 'goodsToBePlacedUnderProcedureCombinedNomenclatureAndTaricCode':
    if (
      PERMIT_GOODS_TARIC_CODE_MANDATORY_IN.includes(applicationTypeCode)
    ) {
      return { mandatory: true }
    }
    return {}
  case 'processedProductCombinedNomenclatureCode':
    if (applicationTypeCode === EUS) {
      return { mandatory: false }
    }
    // falls through
  case 'originatingGoodsCombinedNomenclature':
  case 'replacementProductTaricCode':
    if (applicationTypeCode === PERMIT_TYPE_CODE_PPP) {
      return { mandatory: true }
    }
    return {}
  case 'equivalentGoodCombinedNomenclatureCode':
    if (applicationTypeCode === PERMIT_TYPE_CODE_PPP) {
      return {
        mandatory: true,
      }
    }
    return {}
  case 'processedProductDescription':
    // on EUS processedProductDescription is required even though infoElement says its not
    if (applicationTypeCode === EUS) {
      return { mandatory: true }
    }
    return {}
  case 'concentToSendingDecisionElectronically.concentToSendingDecisionElectronically':
  case 'goodsToBePlacedUnderProcedureDescription':
  case 'operationCount.operationCount':
  case 'goodsIdentification':
  case 'goodsIdentificationAdditionalInformation':
    return { mandatory: true }
  // The following are conditionally visible fields. They're really mandatory only when they're visible
  case 'messageWarehouseInterface.messageWarehouseTestUrl':
  case 'messageWarehouseInterface.messageWarehouseProductionUrl':
  case 'messageServiceUse.messageTestUrl':
  case 'messageServiceUse.messageProductionUrl':
    return { mandatory: true }
  case 'goodsToBePlacedUnderProcedureAmount':
  case 'goodsToBePlacedUnderProcedureCurrency':
    if (PERMIT_GOODS_COMBINED_IN.includes(applicationTypeCode)) {
      return PERMIT_GOODS_CW_NOT_MANDATORY_IN.includes(applicationTypeCode) ? { mandatory: false } : { mandatory: true }
    }
    return {}
  case 'goodsToBePlacedUnderProcedureQuantity':
  case 'goodsToBePlacedUnderProcedureMeasurementUnit':
    if (applicationTypeCode !== EUS && PERMIT_GOODS_COMBINED_IN.includes(applicationTypeCode)) {
      return PERMIT_GOODS_CW_NOT_MANDATORY_IN.includes(applicationTypeCode) ? { mandatory: false } : { mandatory: true }
    }
    return {}
  case 'goodsMovementCountryCode':
  case 'processingOrUsePlaceCountryCode':
    return {
      mandatory: true,
      readonlyInApplication: applicationTypeCode !== OPO ? true : false,
    }
  case 'firstProcessingOrUsePlace.firstProcessingOrUsePlaceCountryCode':
    return {
      readonlyInApplication: true,
    }
  case 'goodsMovementLegalBaseCode':
  case 'goodsMovementLocationType':
  case 'goodsMovementIdentificationQualifier':
  case 'goodsLocationCountryCode':
  case 'goodsLocationTypeCode':
  case 'goodsLocationIdentificationQualifier':
  case 'processingOrUsePlaceCodeType':
  case 'processingOrUsePlaceIdentificationQualifier':
  case 'firstProcessingOrUsePlace.firstProcessingOrUsePlaceCodeType':
  case 'firstProcessingOrUsePlace.firstProcessingOrUsePlaceIdentificationQualifier':
    return {
      readonlyInApplication: true,
    }
  case 'dischargePeriod.dischargePeriodAutomaticExtension':
  case 'releaseForFreeCirculationByDischargeBill.releaseForFreeCirculationByDischargeBill':
    return {
      visible: false,
    }
  case `${PERMIT_GOODS_FIELD_PREFIX}MeasurementUnit`:
  case `${PERMIT_GOODS_FIELD_PREFIX}Quantity`:
    if (applicationTypeCode === PERMIT_TYPE_CODE_PPP) {
      return { mandatory: true }
    }
    return {}
  case 'authorizationOrDecisionHolder.authorizationOrDecisionHolderPostalCode':
  case 'authorizationOrDecisionHolder.authorizationOrDecisionHolderCity':
  case 'authorizationOrDecisionHolder.authorizationOrDecisionHolderCountryCode':
  case 'authorizationOrDecisionHolder.authorizationOrDecisionHolderAddress': {
    if (authType === AUTH_TYPES.principal) {
      return { mandatory: true }
    }

    return {}
  }
  case 'additionalInformation2':
  case 'additionalInformation': {
    if (infoElement.code === PERMIT_GOODS_TO_ENJOY_RELIEF_FROM_DUTIES_FIELD_PREFIX) {
      return { type: 'textarea' }
    }
    return {}
  }
  case 'applicantCustomsResponsible.applicantCustomsResponsibleDateOfBirth':
    return {
      mandatory: true,
    }

  default:
    return {}
  }
}

function isVisibleInCombinedList(infoElementCode, fieldCode) {
  switch (infoElementCode) {
  case 'goodsToBePlacedUnderProcedure':
    return PERMIT_GOODS_UNDER_PROCEDURE_BASIC_FEATURES.includes(fieldCode)
  case 'equivalentGood':
    return PERMIT_EQUIVALENT_GOOD_BASIC_FEATURES.includes(fieldCode)
  default:
    return false
  }
}

function getFieldVisibility(fieldCode, infoElementCode, applicationTypeCode) {
  switch (fieldCode) {
  case 'goodsToBePlacedUnderProcedureTaricAdditionalCode':
  case 'goodsToBePlacedUnderProcedureNationalAdditionalCode':
    return false
  default:
  }

  const goodsParams = getFieldGoodsParams(fieldCode)
  if (goodsParams) {
    const fieldPrefix = infoElementCode
    const { fullFeaturedIn, quantityFeaturedIn } = goodsParams

    switch (fieldCode) {
    case `${fieldPrefix}Currency`:
    case `${fieldPrefix}Amount`:
      return fullFeaturedIn.includes(applicationTypeCode)
    case `${fieldPrefix}Quantity`:
    case `${fieldPrefix}MeasurementUnit`:
      return fullFeaturedIn.includes(applicationTypeCode) || quantityFeaturedIn.includes(applicationTypeCode)
    // case `${fieldPrefix}Description`:
    default:
      return true
    }
  }
  return true
}

function getFieldGoodsParams(infoElementCode) {
  switch (infoElementCode) {
  case 'goodsToBePlacedUnderProcedure':
    return {
      fullFeaturedIn: PERMIT_GOODS_UNDER_PROCEDURE_FULL_FEATURED,
      quantityFeaturedIn: PERMIT_GOODS_UNDER_PROCEDURE_QUANTITY_FEATURED,
    }
  case 'processedProduct':
  {
    return {
      fullFeaturedIn: PERMIT_PROCESSED_PRODUCT_FULL_FEATURED,
    }
  }
  case 'replacementProduct':
    return {
      fullFeaturedIn: PERMIT_REPLACEMENT_PRODUCT_FULL_FEATURED,
    }
  case 'originatingProducts':
    return {
      fullFeaturedIn: PERMIT_ORIGINATING_PRODUCT_FULL_FEATURED,
    }
  case 'equivalentGood':
    return {
      fullFeaturedIn: PERMIT_EQUIVALENT_GOOD_FULL_FEATURED,
    }
  default:
    return null
  }
}

export function getCodeset(name, opts) {
  const date = (opts.applicationDate ? moment(opts.applicationDate) : moment()).format('YYYY-MM-DD')
  const codeset = get(opts, `codesets.${name}.${date}`)

  if (!codeset) {
    return null
  }

  return codeset
}
