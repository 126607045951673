import React from 'react'
import { Row, Col } from 'react-bootstrap'
import { get } from 'lodash'
import messages from '../messages'


import { renderField } from '../../../routes/permits/permit/components/InfoElementField'
import { FormattedMessage } from 'react-intl'

export default function ApplicantAddressForm({ infoElement, values, staticValue }) {
  const authorizationOrDecisionHolderAddressField = infoElement.fields.find(field => field.code === 'authorizationOrDecisionHolder.authorizationOrDecisionHolderAddress')
  const authorizationOrDecisionHolderPostalCodeField = infoElement.fields.find(field => field.code === 'authorizationOrDecisionHolder.authorizationOrDecisionHolderPostalCode')
  const authorizationOrDecisionHolderCityField = infoElement.fields.find(field => field.code === 'authorizationOrDecisionHolder.authorizationOrDecisionHolderCity')
  const authorizationOrDecisionHolderCountryCodeField = infoElement.fields.find(field => field.code === 'authorizationOrDecisionHolder.authorizationOrDecisionHolderCountryCode')

  if (staticValue) {
    const address = get(values, 'authorizationOrDecisionHolder.authorizationOrDecisionHolderAddress')
    const postalCode = get(values, 'authorizationOrDecisionHolder.authorizationOrDecisionHolderPostalCode')
    const city = get(values, 'authorizationOrDecisionHolder.authorizationOrDecisionHolderCity')
    const countryCode = get(values, 'authorizationOrDecisionHolder.authorizationOrDecisionHolderCountryCode')

    return (
      <Row>
        <Col sm={6}>
          <FormattedMessage {...messages.contactInformation} />
        </Col>
        <Col sm={6} id-qa-test={'label-contactInformation'}>
          <div>{address}</div>
          <div>{postalCode} {city} {countryCode}</div>
        </Col>
      </Row>
    )
  }

  return (
    <Row>
      <Col xs={12}>
        {renderField({ field: authorizationOrDecisionHolderAddressField, staticValue })}
      </Col>
      <Col xs={12} sm={6}>
        {renderField({ field: authorizationOrDecisionHolderPostalCodeField, staticValue })}
      </Col>
      <Col xs={12} sm={6}>
        {renderField({ field: authorizationOrDecisionHolderCityField, staticValue })}
      </Col>
      <Col xs={12}>
        {renderField({ field: authorizationOrDecisionHolderCountryCodeField, staticValue })}
      </Col>
    </Row>
  )
}
