export const TABLE_HEADER_HEIGHT = 40
export const TABLE_ROW_HEIGHT = 40
export const TABLE_MIN_HEIGHT = Math.ceil(TABLE_HEADER_HEIGHT + (TABLE_ROW_HEIGHT * 8.5))
export const DRAFT_DEFAULT_SORT_BY = 'updateDate'
export const APPLICATION_DEFAULT_SORT_BY = 'submitDate'
export const REJOINDER_DEFAULT_SORT_BY = 'registeredDate'
export const PERMIT_DEFAULT_SORT_BY = 'decisionDate'

export const PERMIT_TAB_DRAFTS = 'DRAFTS'
export const PERMIT_TAB_APPLICATIONS = 'APPLICATIONS'
export const PERMIT_TAB_REPLIES = 'REPLIES'
export const PERMIT_TAB_PERMITS = 'PERMITS'

export const PERMIT_APPLICATION_STATUS_DRAFT = 'DRAFT'
export const PERMIT_APPLICATION_STATUS_REGISTERED = 'REGISTERED'
export const PERMIT_APPLICATION_STATUS_VERIFICATION = 'VERIFICATION'
export const PERMIT_APPLICATION_STATUS_ACCEPTED = 'ACCEPTED'
export const PERMIT_APPLICATION_STATUS_NOT_ACCEPTED = 'NOT_ACCEPTED'
export const PERMIT_APPLICATION_STATUS_FAVOURABLE = 'FAVOURABLE'
export const PERMIT_APPLICATION_STATUS_NON_FAVOURABLE = 'NON_FAVOURABLE'
export const PERMIT_APPLICATION_STATUS_WITHDRAWN = 'WITHDRAWN'

export const STORE_UI_STATE = '@@nakki/permits/STORE_PERMIT_LIST_UI_STATE'
