/* eslint-disable no-underscore-dangle */
import {
  initialize,
  getFormValues,
  startAsyncValidation,
  stopAsyncValidation,
} from 'redux-form'
import { findIndex, get, isNil, chain, chunk, sortBy, omit } from 'lodash'
import { createDefaultAction } from 'src/utils/redux'
import { showGlobalNotification, loadingAction } from 'src/actions'
import logger from 'src/utils/logger'
import genericApiMessages from 'src/utils/apiMessages'
import { referencePeriodToDate } from '../../../utils'
import { rowHasItemNumber } from '../../utils'
import {
  INTRASTAT_FORM_NAME,
  INTRASTAT_ROW_FORM_NAME,
  SELECT_DECLARATION_ROW,
  TOGGLE_NEW_ROW_EDITING,
  DECLARATION_ROW_CREATED,
  DECLARATION_ROWS_SAVED,
  DECLARATION_ROWS_CHUNK_SAVED,
  DECLARATION_ROW_UPDATED,
  DECLARATION_ROW_DELETED,
  DECLARATION_ROWS_FETCHED,
  CN8_TREE_REQUEST,
  CN8_TREE_CHUNK,
  CN8_TREE_CHUNKS_FETCHED,
  CN8_TREE_BRANCH_REQUEST,
  CN8_FETCHED,
  CN8_TEXT_SEARCH_REQUEST,
  CN8_TEXT_SEARCH_RESULT,
  PRESERVE_ROW_FIELD_VALUE,
  CN8_CLEAR_SEARCH_RESULTS,
  SHOW_ONLY_INVALID_ROWS,
  DECLARATION_STATUS_INCORRECT,
  DECLARATION_STATUS_RECEIVED,
} from '../../constants'
import { CN8_ROOT_NODE_ID, ROWS_FETCH_RESULT_SIZE } from './constants'
import { apiCall, synchronousPromiseChain, ServerValidationError } from '../../../../../utils/http'
import { getFieldErrors } from '../../../../../utils/validation'
import apiMessages from '../../apiMessages'
import messages from './messages'
import { handleServerErrors } from '../../actions'


/**
 * Rows are submitted separately one at a time, this function is called when user
 * has finished handling rows and moves on to another form step.
 *
 * IMPROVEMENT: Update declaration from server at this point to be sure we have all
 *              declaration changes created by row handling (totals, timestamps...)
 */
// eslint-disable-next-line no-unused-vars
export const rowsStepSubmit = formData => dispatch => Promise.resolve()

export const toggleNewRowEditing = createDefaultAction(TOGGLE_NEW_ROW_EDITING)
export const selectRow = createDefaultAction(SELECT_DECLARATION_ROW)

export const notifyMissingInformation = () =>
  showGlobalNotification({
    level: 'error',
    message: messages.missingInformation,
    autoDismiss: true,
  })

export const notifyDoneSavingRow = () =>
  showGlobalNotification({
    level: 'success',
    message: messages.doneSavingRow,
    autoDismiss: true,
  })

/**
 * Redux bound action creator for adding new Intrastat row to form
 */
const saveNewRowsToServer = (declarationId, rows, dispatch, getState) => {
  const { config: { bootstrapConfig } } = getState()
  const apiUrl = `${bootstrapConfig.tm_intrastat_ext_url}/declarations/${declarationId}/rows/`
  return apiCall(apiUrl, { method: 'POST', body: JSON.stringify(rows) }, {}, dispatch, false)
}

const rowFieldsNotSentToServer = [
  'errors',
  'warnings',
  'preserveNewRowFields',
  'statisticalValueInEur',
  'invoicedAmountInEur',
]

const rowCreated = createDefaultAction(DECLARATION_ROW_CREATED)

/**
 * Single row add action to be used from redux form
 * @throws SubmissionError for validation
 */
export const addSingleNewRow = (declarationId, newRow) =>
  (dispatch, getState) => {
    const irrelevantField = (value, key) => {
      if (key === 'statisticalValueCurrencyCode' && !newRow.statisticalValue) {
        return true
      }
      return false
    }

    const relevantRowData = chain(newRow)
      .omitBy(isNil)
      .omitBy(irrelevantField)
      .omit(rowFieldsNotSentToServer)
      .value()

    return saveNewRowsToServer(declarationId, [relevantRowData], dispatch, getState)
      .catch((error) => {
        // Extract single-row error response
        if (error.name === 'ServerValidationError' && error.value && error.value.length === 1) {
          return handleServerErrors(dispatch, new ServerValidationError({ responseJson: error.value[0] }))
        }
        return handleServerErrors(dispatch, error)
      })
      .then((result) => {
        if (result && result.length === 1) {
          const formValues = getFormValues(INTRASTAT_FORM_NAME)(getState())
          const { rows = [], ...declaration } = formValues
          const { errors, ...savedRow } = result[0]
          if (errors) {
            savedRow.warnings = getFieldErrors(result[0], apiMessages)
          }
          declaration.status = (errors ? DECLARATION_STATUS_INCORRECT : DECLARATION_STATUS_RECEIVED)
          /**
           * We must reinitialize all data even if we only want to add one row, since we want
           * rows array to be pristine (it is now saved to server and contains no ui changes).
           *
           * IMPROVE: If (in future redux form versions) we could update initial value for rows only,
           *          we could use arrayPush action here.
           */
          dispatch(initialize(
            INTRASTAT_FORM_NAME,
            {
              ...declaration,
              rows: [
                ...rows,
                savedRow,
              ],
            },
            {
              keepDirty: false,
              keepSubmitSucceeded: true,
            }
          ))
          dispatch(rowCreated(savedRow))
        }
      })
      .catch((error) => {
        logger.error('Error in submitting Intrastat declaration row', error)
        dispatch(rowCreated(error))
        throw error
      })
  }

const rowsSaved = createDefaultAction(DECLARATION_ROWS_SAVED)
const rowsChunkSaved = createDefaultAction(DECLARATION_ROWS_CHUNK_SAVED)

const dispatchAllRowChunksSaved = (dispatch, getState, savedRows, error) => {
  const payload = {
    rowCount: savedRows.length || 0,
    warningCount: 0,
    warningRowCount: 0,
  }
  savedRows.forEach((row) => {
    if (row.warnings) {
      payload.warningCount += Object.keys(row.warnings).length
      payload.warningRowCount += 1
    }
  })
  if (error) {
    dispatch(rowsSaved(error, payload))
  } else {
    dispatch(rowsSaved(payload))
  }
  if (savedRows && savedRows.length) {
    const formValues = getFormValues(INTRASTAT_FORM_NAME)(getState())
    const declaration = omit(formValues, 'rows')
    dispatch(initialize(
      INTRASTAT_FORM_NAME,
      {
        ...declaration,
        rows: savedRows,
      },
      {
        keepDirty: false,
        keepSubmitSucceeded: true,
      }
    ))
  }
  return payload
}

const NEW_ROWS_CHUNK_SIZE = 50
export const saveRows = (declarationId, newRows) =>
  (dispatch, getState) => {
    const relevantRowData = newRows
      .map(newRow =>
        chain(newRow)
          .omitBy(isNil)
          .omit(rowFieldsNotSentToServer)
          .value()
      )
    const chunkedRows = chunk(relevantRowData, NEW_ROWS_CHUNK_SIZE)
    let savedRows = []

    // Save row, collect result and dispatch progress action
    const saveAction = (rowsChunk, currentStep, totalSteps) =>
      saveNewRowsToServer(declarationId, rowsChunk, dispatch, getState)
        .catch(handleServerErrors.bind(this, dispatch))
        .then((saveResult) => {
          const resultWithWarnings = saveResult.map((row) => {
            const { errors, ...rowData } = row
            if (errors) {
              rowData.warnings = getFieldErrors(row, apiMessages)
            }
            return rowData
          })
          savedRows = savedRows.concat(resultWithWarnings)
          dispatch(rowsChunkSaved(saveResult, { chunk: currentStep, totalChunks: totalSteps }))
          return saveResult
        })

    return synchronousPromiseChain(chunkedRows, saveAction)
      .catch((error) => {
        logger.error('Error in saving rows', error)
        dispatchAllRowChunksSaved(dispatch, getState, savedRows, error)
        if (error) {
          throw error
        }
      })
      .then(() => dispatchAllRowChunksSaved(dispatch, getState, savedRows))
  }

const rowUpdated = createDefaultAction(DECLARATION_ROW_UPDATED)
export const fetchAndUpdateExistingRow = (declarationId, rowData) =>
  (dispatch, getState) => {
    const { config: { bootstrapConfig } } = getState()
    const apiUrl = `${bootstrapConfig.tm_intrastat_ext_url}/declarations/${declarationId}/rows/${rowData.id}`
    const method = 'PUT'
    const relevantRowData = chain(rowData)
      .omitBy(isNil)
      .omit(rowFieldsNotSentToServer)
      .value()
    return apiCall(apiUrl, { method, body: JSON.stringify(relevantRowData) }, {}, dispatch, true)
      .catch(handleServerErrors.bind(this, dispatch))
      .then((response) => {
        updateExistingRow(dispatch, getState, response, rowData)
      })
      .catch((error) => {
        dispatch(rowUpdated(error))
        logger.error('Error in updating Intrastat declaration row', error)
        throw error
      })
  }


export const updateExistingRow = (dispatch, getState, response, rowData) => {
  dispatch(rowUpdated(response))
  const { errors, ...responseRow } = response
  const formValues = getFormValues(INTRASTAT_FORM_NAME)(getState())
  if (formValues && formValues.rows) {
    const { rows, ...declaration } = formValues
    // Find row's current position for triggering change
    const existingRowIndex = findIndex(rows, { id: rowData.id })
    if (existingRowIndex >= 0) {
      if (errors) {
        responseRow.warnings = getFieldErrors(response, apiMessages)
      }
      declaration.status = (errors ? DECLARATION_STATUS_INCORRECT : DECLARATION_STATUS_RECEIVED)
      /**
       * We must reinitialize all data even if we only want to edit one row, since we want
       * rows array to be pristine (it is now saved to server and contains no changes).
       */
      const updatedRows = rows.slice(0)
      updatedRows[existingRowIndex] = responseRow
      dispatch(initialize(
        INTRASTAT_FORM_NAME,
        {
          ...declaration,
          rows: updatedRows,
        },
        {
          keepDirty: false,
          keepSubmitSucceeded: true,
        }
      ))
      return
    }
  }
  throw new Error(`Could not find updated row ${rowData.id} in intrastat form`)
}

const rowDeleted = createDefaultAction(DECLARATION_ROW_DELETED)
export const deleteRow = (declarationId, rowData) =>
  (dispatch, getState) => {
    const { config: { bootstrapConfig } } = getState()
    const apiUrl = `${bootstrapConfig.tm_intrastat_ext_url}/declarations/${declarationId}/rows/${rowData.id}`
    const method = 'DELETE'
    dispatch(loadingAction({ key: DECLARATION_ROW_DELETED, value: true }))
    return apiCall(apiUrl, { method }, {}, dispatch, false)
      .catch(handleServerErrors.bind(this, dispatch))
      .then(() => {
        dispatch(loadingAction({ key: DECLARATION_ROW_DELETED, value: false }))
        const formValues = getFormValues(INTRASTAT_FORM_NAME)(getState())
        if (formValues && formValues.rows) {
          const { rows, ...declaration } = formValues
          /**
           * We must reinitialize all data even if we only want to remove one row, since we want
           * rows array to be pristine (it is now saved to server and contains no ui changes).
           *
           * IMPROVE: If (in future redux form versions) we could update initial value for rows only,
           *          we could use arrayRemove action here.
           */
          const indexToRemove = findIndex(rows, { id: rowData.id })
          if (indexToRemove >= 0) {
            const updatedRows = [
              ...rows.slice(0, indexToRemove),
              ...rows.slice(indexToRemove + 1),
            ]
            dispatch(initialize(
              INTRASTAT_FORM_NAME,
              {
                ...declaration,
                rows: updatedRows,
              },
              {
                keepDirty: false,
                keepSubmitSucceeded: true,
              }
            ))
            dispatch(rowDeleted(rowData))
            fetchDeclarationRows(declarationId)(dispatch, getState)
              .then((rows) => {
                const currentlyOpenDeclarationId = get(getState(), 'form.intrastat.values.declarationId')
                if (currentlyOpenDeclarationId !== declarationId) {
                  return
                }
                if (rows && rows.length) {
                  declaration.rows = rows
                  dispatch(
                    initialize(
                      INTRASTAT_FORM_NAME,
                      declaration,
                      { keepDirty: true, updateUnregisteredFields: true }
                    )
                  )
                }
              })
          }
        }
      })
      .catch((error) => {
        logger.error('Error in deleting Intrastat row', error)
        const message = get(error, 'errors._error')
        dispatch(rowDeleted(error, { rowData, message }))
        dispatch(loadingAction({ key: DECLARATION_ROW_DELETED, value: false }))
        dispatch(showGlobalNotification({
          level: 'error',
          modal: true,
          message: messages.errorDeletingRow,
          additionalInfo: message && message.id ? message : undefined,
        }))
      })
  }

const rowsFetched = createDefaultAction(DECLARATION_ROWS_FETCHED)

export const fetchDeclarationRows = declarationId =>
  async (dispatch, getState) => {
    const { config: { bootstrapConfig } } = getState()

    dispatch(loadingAction({ key: DECLARATION_ROWS_FETCHED, value: true }))

    let rows = []
    let searchId = null
    let end = false
    while (!end) {
      const base = bootstrapConfig.tm_intrastat_ext_url
      let apiUrl = `${base}/declarations/${declarationId}/rows/?fetchErrors=true&resultSize=${ROWS_FETCH_RESULT_SIZE}`

      if (searchId) {
        apiUrl = `${apiUrl}&searchId=${searchId}`
      }

      // eslint-disable-next-line no-await-in-loop
      await apiCall(apiUrl, { method: 'GET' }, {}, null, true)
        .catch((error) => {
          handleServerErrors(dispatch, error) // this throws always
        })
        .then((response) => { // eslint-disable-line no-loop-func
          if (response && response.length) {
            rows.push(...response.map((row) => {
              const { errors, ...rowData } = row
              if (errors) {
                rowData.warnings = getFieldErrors(row, apiMessages)
              }
              return rowData
            }))
            searchId = rows[rows.length - 1].id
            if (response.length !== ROWS_FETCH_RESULT_SIZE) {
              end = true
            }
          } else {
            end = true
          }
        })
        .catch((error) => {
          logger.error('Error in fetching Intrastat declaration rows', JSON.stringify(error))
          dispatch(rowsFetched(error))
          dispatch(loadingAction({ key: DECLARATION_ROWS_FETCHED, value: false }))

          if (error && error.errors && error.errors._error) {
            const errorKey = error.errors._error.id
            let errorCode = null
            if (errorKey.startsWith('api.intrastat.exception.')) {
              errorCode = errorKey.slice(24)
            }
            const globalIntlMessage = apiMessages[errorCode]
            if (errorCode) {
              dispatch(showGlobalNotification({
                level: 'error',
                message: globalIntlMessage,
              }))
            }
          }
          throw error
        })
    }
    rows = rowHasItemNumber(rows) ? sortBy(rows, it => parseInt(it.itemNumber, 10)) : sortBy(rows, it => parseInt(it.id, 10))
    dispatch(rowsFetched(rows))
    dispatch(loadingAction({ key: DECLARATION_ROWS_FETCHED, value: false }))
    return rows
  }

const showOnlyInvalid = createDefaultAction(SHOW_ONLY_INVALID_ROWS)

export const setOnlyInvalidRowsVisibility = status =>
  dispatch =>
    dispatch(showOnlyInvalid(status))

const cn8TreeRequest = createDefaultAction(CN8_TREE_REQUEST)
const cn8TreeChunk = createDefaultAction(CN8_TREE_CHUNK)
const allCn8TreeChunksFetched = createDefaultAction(CN8_TREE_CHUNKS_FETCHED)
const markCN8NodeAsLoading = createDefaultAction(CN8_TREE_BRANCH_REQUEST)

export const fetchCN8TreeByCode = (code, referencePeriod) =>
  (dispatch, getState) => {
    dispatch(cn8TreeRequest({ codes: [code], referencePeriod }))

    const locale = getState().locale
    const codesetDate = referencePeriodToDate(referencePeriod)

    const { config: { bootstrapConfig } } = getState()

    apiCall(
      `${bootstrapConfig.tm_query_ext_url}/cn8/tree/${code}?date=${codesetDate}&lang=${locale}`,
      { method: 'GET', cache: 'default' },
      null,
      dispatch,
      true
    )
      .then((data) => {
        dispatch(cn8TreeChunk(data, { code, referencePeriod, cn8Locale: locale }))
        dispatch(allCn8TreeChunksFetched({ codes: [code], referencePeriod }))
      })
      .catch((error) => {
        logger.error('Error in loading CN8 tree', error)
        dispatch(cn8TreeChunk(error, { code, referencePeriod, cn8Locale: locale }))
      })
  }

function getCN8RootNodeFromStateByReferencePeriod(state, referencePeriod) {
  const referencePeriodYear = referencePeriod.substr(0, 4)
  return get(state, ['intrastat', 'declaration', 'cn8Trees', referencePeriodYear, 'root'])
}

function getCN8Locale(state) {
  return get(state, ['intrastat', 'declaration', 'cn8Locale'])
}

function cn8AncestryAsArray(cn8Data) {
  const ancestorCodes = cn8Data.ancestors.map(ancestor => ancestor.code)
  ancestorCodes.reverse()
  ancestorCodes.push(cn8Data.code)
  return ancestorCodes
}

export const fetchCN8TreeRoot = referencePeriod =>
  (dispatch, getState) => {
    const cn8RootNode = getCN8RootNodeFromStateByReferencePeriod(getState(), referencePeriod)

    if (cn8RootNode) {
      const existingRootNode = cn8RootNode.first(node => node.model.code === CN8_ROOT_NODE_ID)
      const cn8Locale = getCN8Locale(getState())
      if (existingRootNode && existingRootNode.model.loaded && cn8Locale === getState().locale) {
        return
      }
    }

    dispatch(fetchCN8TreeByCode(CN8_ROOT_NODE_ID, referencePeriod))
  }

export const selectCN8TreeNode = (code, referencePeriod) =>
  (dispatch, getState) => {
    const cn8RootNode = getCN8RootNodeFromStateByReferencePeriod(getState(), referencePeriod)

    if (!cn8RootNode) {
      throw new Error('CN8 root node not found')
    }

    const selectedNode = cn8RootNode.first(node => node.model.code === code)

    if (!selectedNode) {
      throw new Error('Selected CN8 node does not exist')
    }

    // Selected node does not have children, nothing for us to do...
    if (!selectedNode.model.hasChildren) {
      return
    }

    // Node has children, but we don't have the data for it yet
    if (!selectedNode.model.children) {
      dispatch(markCN8NodeAsLoading({ code, referencePeriod }))
      dispatch(fetchCN8TreeByCode(code, referencePeriod))
    }
  }

export const fetchCN8TreeByPath = (codes, referencePeriod) =>
  async (dispatch, getState) => {
    await dispatch(cn8TreeRequest({ codes, referencePeriod }))

    const state = getState()
    const cn8RootNode = getCN8RootNodeFromStateByReferencePeriod(state, referencePeriod)

    if (!cn8RootNode) {
      throw new Error('Root node missing')
    }

    const date = referencePeriodToDate(referencePeriod)

    const allTreeChunks = codes.reduce((promise, code) => {
      const existingNode = cn8RootNode.first(node => node.model.code === code)

      if (!existingNode || !existingNode.model.loaded) {
        const { config: { bootstrapConfig } } = getState()

        return promise.then(() =>
          apiCall(
            `${bootstrapConfig.tm_query_ext_url}/cn8/tree/${code}?date=${date}&lang=${state.locale}`,
            { method: 'GET', cache: 'default' },
            null,
            dispatch,
            true
          )
            .then(data => data && dispatch(cn8TreeChunk(data, { code, referencePeriod, cn8Locale: state.locale })))
        )
      }

      return promise
    }, Promise.resolve())

    allTreeChunks.then(() => dispatch(allCn8TreeChunksFetched({ codes, referencePeriod })))
  }

const cn8FetchResult = createDefaultAction(CN8_FETCHED)

export const fetchCN8Codes = (cn8Codes, referencePeriod) =>
  (dispatch, getState) => {
    if (!Array.isArray(cn8Codes)) {
      cn8Codes = [cn8Codes] // eslint-disable-line no-param-reassign
    }

    if (cn8Codes.some(cn8 => cn8.length !== 8)) {
      return Promise.resolve()
    }

    const codesetDate = referencePeriodToDate(referencePeriod)

    const { config: { bootstrapConfig } } = getState()

    return apiCall(
      `${bootstrapConfig.tm_query_ext_url}/cn8/${cn8Codes.join(',')}?date=${codesetDate}&lang=${getState().locale}`,
      { method: 'GET', cache: 'default' },
      null,
      dispatch,
      false
    ).then((data) => {
      dispatch(cn8FetchResult(data, { codes: cn8Codes, referencePeriod, cn8Locale: getState().locale }))
      return data
    })
  }

export const fetchCN8Code = (cn8, referencePeriod) =>
  (dispatch, getState) => {
    if (cn8.length !== 8) {
      return Promise.resolve()
    }

    const state = getState()
    const referencePeriodYear = referencePeriod.substr(0, 4)
    const cachedCN8Code = get(state.intrastat.declaration.cachedCN8Codes, [referencePeriodYear, cn8])

    if (cachedCN8Code) {
      const ancestorCodes = cn8AncestryAsArray(cachedCN8Code)

      dispatch(cn8FetchResult([cachedCN8Code], { codes: [cn8], referencePeriod, cn8Locale: getState().locale }))
      dispatch(fetchCN8TreeByPath(ancestorCodes, referencePeriod))

      return Promise.resolve(ancestorCodes)
    }

    dispatch(loadingAction({ key: CN8_FETCHED, value: true }))
    dispatch(startAsyncValidation(INTRASTAT_ROW_FORM_NAME))

    return fetchCN8Codes(cn8, referencePeriod)(dispatch, getState).then((data) => {
      const ancestorCodes = cn8AncestryAsArray(data[0])

      dispatch(fetchCN8TreeByPath(ancestorCodes, referencePeriod))
      dispatch(stopAsyncValidation(INTRASTAT_ROW_FORM_NAME))
      dispatch(loadingAction({ key: CN8_FETCHED, value: false }))

      return ancestorCodes
    })
      .catch((error) => {
        logger.error(`Error in fetching CN8 code ${cn8} for ${referencePeriod}`, error)
        dispatch(cn8FetchResult(error, { codes: [cn8], referencePeriod, cn8Locale: getState().locale }))
        dispatch(loadingAction({ key: CN8_FETCHED, value: false }))
        dispatch(stopAsyncValidation(INTRASTAT_ROW_FORM_NAME, {
          CN8Code: messages.invalidCN8Code,
        }))
      })
  }

const cn8SearchRequest = createDefaultAction(CN8_TEXT_SEARCH_REQUEST)
const cn8SearchResult = createDefaultAction(CN8_TEXT_SEARCH_RESULT)
export const searchCN8Text = (text, referencePeriod) =>
  (dispatch, getState) => {
    if (!text || !text.length) {
      return Promise.resolve()
    }
    dispatch(cn8SearchRequest({ text, referencePeriod }))
    const codesetDate = referencePeriodToDate(referencePeriod)

    const {
      locale,
      config: { bootstrapConfig },
    } = getState()

    return apiCall(
      `${bootstrapConfig.tm_query_ext_url}/cn8/descriptions/?query=${text}&date=${codesetDate}&lang=${locale}`,
      { method: 'GET', cache: 'default' },
      null,
      dispatch,
      false
    ).then((data) => {
      dispatch(cn8SearchResult(data, { text, referencePeriod }))
      return data
    })
      .then(() => {
        const numberOfResults = document.getElementById('numberOfResults')
        if (numberOfResults) {
          numberOfResults.focus()
        }
      })
      .catch((error) => {
        logger.error(`Error in CN8 text search with term ${text} for ${referencePeriod}`, error)
        dispatch(showGlobalNotification({
          level: 'error',
          modal: true,
          message: genericApiMessages.unexpectedError,
        }))
        dispatch(cn8SearchResult(error, { text, referencePeriod }))
      })
  }
export const clearCN8SearchResults = createDefaultAction(CN8_CLEAR_SEARCH_RESULTS)

export const preserveRowFieldValue = createDefaultAction(PRESERVE_ROW_FIELD_VALUE)
