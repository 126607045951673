
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { get } from 'lodash'
import { getSelectedAuthorization } from 'src/utils/auth'
import { fetchCustomerInformation } from 'src/components/customer/actions'

import { submitForm } from './actions'
import LocationInformationForm from './components/LocationInformationForm'

const mapStateToProps = state => {
  return {
    locale: state.locale,
    selectedAuthorizationObject: getSelectedAuthorization(),
    cmsMessages: get(state, 'content.cmsMessages', {}),
    customer: state.customer,
    fetchingCustomer: get(state.customer, 'fetchingCustomer'),
  }
}

const mapActionCreators = {
  submitForm,
  fetchCustomerInformation,
}

export default withRouter(
  connect(mapStateToProps, mapActionCreators)(LocationInformationForm)
)
