import React from 'react'
import { injectIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import { has } from 'lodash'
import classNames from 'classnames'
import messages from './messages'
import css from './heading.scss'
import LogoFI from '../images/logos/customs-logo-fi-blue.svg'
import LogoSV from '../images/logos/customs-logo-sv-blue.svg'
import { LOCALE } from '../constants'

class Heading extends React.Component {
  componentDidMount() {
    if (!this.props.noFocus && this.headingRef) {
      this.headingRef.focus()
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    let formattedMessageOld = this.props.message
    let formattedMessageNew = nextProps.message
    if (has(this.props.message, 'id')) {
      formattedMessageOld = this.props.intl.formatMessage(this.props.message)
      formattedMessageNew = nextProps.intl.formatMessage(nextProps.message)
    }
    if (this.headingRef &&
      formattedMessageOld &&
      formattedMessageNew &&
      formattedMessageNew !== formattedMessageOld) {
      setTimeout(() => this.headingRef?.focus(), 1)
    }
  }

  render() {
    const {
      children,
      logoLink,
      logoRowContent,
      link,
      message,
      titleExtras,
      compact,
      print,
      intl,
      wrapHeadingContent,
      className,
    } = this.props
    let formattedMessageComponent = null
    if (has(message, 'id')) {
      formattedMessageComponent = intl.formatMessage(message)
    } else {
      formattedMessageComponent = message
    }
    const headingTitleRow = (
      <h1
        ref={(ref) => { this.headingRef = ref }}
        tabIndex="-1"
      >
        {formattedMessageComponent}
        {titleExtras}
      </h1>
    )
    return (
      <div className={classNames(css.headingArea, print && css.print, className)}>
        <div className={css.headingLogoRow}>
          <div className={classNames('container', css.headingContainer, wrapHeadingContent && css.wrapHeading)}>
            <Link to={logoLink || '/'} id-qa-test={'btn-logo'} className={print ? css.headingLogoLinkMargin : ''}>
              <img className={css.logo} src={intl.locale === LOCALE.sv ? LogoSV : LogoFI} alt={intl.formatMessage(messages.logoAlt)} />
            </Link>
            {logoRowContent}
            {compact && children}
            {(compact && link) &&
              <Link to={link}>
                {headingTitleRow}
              </Link>
            }
            {(compact && !link) && headingTitleRow}
          </div>
        </div>
        {!compact &&
          <div className={classNames('container', css.headingContainer, css.headingTitleRow, wrapHeadingContent && css.wrapHeading)}>
            <div className={css.heading}>
              {headingTitleRow}
            </div>
            <React.Fragment>
              {children}
            </React.Fragment>
          </div>
        }
      </div>
    )
  }
}

export default injectIntl(Heading)
