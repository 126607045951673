import React from 'react'
import { isNil } from 'lodash'
import { FormGroup, Col, Row } from 'react-bootstrap'
import { injectIntl, FormattedMessage } from 'react-intl'
import { Field } from 'redux-form'
import LabelArea from 'src/components/form/LabelArea'
import InputArea from 'src/components/form/InputArea'
import { getValidationClassName } from 'src/utils/validation'
import RowFormParseOnBlurInput from './RowFormParseOnBlurInput'
import { parseBigInteger } from '../../../utils'

// eslint-disable-next-line no-unused-vars
function CurrencyFieldComponent({ InputChildComponent, helpText: fieldHelp, ...fieldProps }) {
  return (
    <div className={getValidationClassName(fieldProps)}>
      <InputArea {...fieldProps}>
        {InputChildComponent &&
          <InputChildComponent {...fieldProps} />
        }
      </InputArea>
    </div>
  )
}

function RowFormCurrencyFields(props) {
  const {
    name,
    disabled,
    helpText,
    labelMessage,
    currencyLabelMessage,
    currencyFieldProps,
    valueFieldProps,
    valueInEur,
    valueInEurMessage,
    mandatory,
    forceValidation,
    intl: { formatMessage },
  } = props

  valueFieldProps.props.type = 'text'
  valueFieldProps.props.componentClass = 'input'

  return (
    <FormGroup
      className="clearfix"
    >
      <LabelArea
        id={`${valueFieldProps.name}Label`}
        htmlFor={valueFieldProps.name}
        input={{ name }}
        help={{
          content: helpText,
        }}
        mandatory={mandatory}
        disabled={disabled}
        label={formatMessage(labelMessage)}
      />
      <Row>
        <Col xs={4} sm={5} md={4} style={{ paddingRight: 0 }}>
          <Field
            component={CurrencyFieldComponent}
            {...currencyFieldProps}
            mandatory={mandatory}
            forceValidation={forceValidation}
            controlId={currencyFieldProps.name}
            ariaLabel={formatMessage(currencyLabelMessage)}
            ariaRequired={mandatory}
          />
        </Col>
        <Col xs={8} sm={7} md={8} style={{ paddingLeft: 0 }}>
          <Field
            component={RowFormParseOnBlurInput}
            onBlurParser={parseBigInteger}
            id={valueFieldProps.name}
            {...valueFieldProps}
            mandatory={mandatory}
            forceValidation={forceValidation}
            labelId={`${valueFieldProps.name}Label`}
            inputStyle={{ borderLeft: 'none' }}
            ariaRequired={mandatory}
          />
        </Col>
      </Row>
      {!isNil(valueInEur) &&
        <p className="form-control-static">
          <FormattedMessage {...valueInEurMessage} />&nbsp;
          {valueInEur} &euro;
        </p>
      }
    </FormGroup>
  )
}

export default injectIntl(RowFormCurrencyFields)
