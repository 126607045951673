import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { Button } from 'react-bootstrap'
import { get } from 'lodash'
import Heading from 'src/layout/Heading'
import Icon from 'src/components/Icon'
import { getDeclarationIdFromRoute } from '../utils'
import messages from '../messages'
import { INTRASTAT_ROUTE_PATH } from '../../constants'
import styles from './Declaration.scss'

export default function ReadOnlyDeclaration(props) {
  const {
    children,
    errorFetchingDeclaration,
    errorFetchingDeclarationRows,
    history,
    match,
  } = props

  const usePrintView = get(match, 'params.viewMode') === 'print'
  const declarationId = getDeclarationIdFromRoute(props)
  const declarationIdWithSpace = declarationId ? ` ${declarationId}` : null

  useEffect(() => {
    props.initializeForm(declarationId, history)

    return () => {
      props.destroyState()
    }
  }, [])

  useEffect(() => {
    props.initializeForm(declarationId, history)
  }, [declarationId])

  const logoRowButtons = (usePrintView) ? <div className="btn-toolbar pull-right">
    <Button onClick={() => window.print()} id-qa-test="btn-print">
      <Icon name="printer" /><FormattedMessage {...messages.print} />
    </Button>
    <Button onClick={() => history.goBack()} id-qa-test="btn-close">
      <Icon name="close" /><FormattedMessage {...messages.close} />
    </Button>
  </div> : null

  const heading = (
    <Heading
      message={messages.heading}
      logoRowContent={logoRowButtons}
      print={usePrintView}
      titleExtras={usePrintView && declarationIdWithSpace}
    >
      {!usePrintView &&
        <Link to={`/${INTRASTAT_ROUTE_PATH}`}>
          <Button className={styles.exitReadOnlyDeclaration}>
            <Icon name="close" /><FormattedMessage {...messages.exit} />
          </Button>
        </Link>
      }
    </Heading>
  )

  if (errorFetchingDeclaration || errorFetchingDeclarationRows) {
    let errorMessage = messages.errorFetchingDeclaration
    if (errorFetchingDeclaration && errorFetchingDeclaration.id) {
      errorMessage = errorFetchingDeclaration
    } else if (errorFetchingDeclarationRows && errorFetchingDeclarationRows.id) {
      errorMessage = errorFetchingDeclarationRows
    }
    return (
      <div>
        {heading}
        <div className="lead absolute-centered">
          <FormattedMessage {...errorMessage} />
        </div>
      </div>
    )
  }

  return (
    <div>
      {heading}
      <main className="container" role="main" id="main">
        <div className="panel panel-primary active" aria-expanded>
          {!usePrintView &&
            <div className="panel-heading" role="heading">
              <FormattedMessage
                {...messages.readOnlyHeading}
                values={{ declarationId }}
              />
            </div>
          }
          <div className="panel-body panel-navigation">
            {children}
          </div>
        </div>
      </main>
    </div>
  )
}
