import React from 'react'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import PanelNavigation from 'src/components/PanelNavigation'
import { collectCmsMessages } from 'src/utils'
import classNames from 'classnames'
import { get } from 'lodash'

import Icon from '../../../components/Icon'
import styles from './CustomerInformationPanel.scss'
import {
  STREET_ADDRESS_TYPE,
  POST_ADDRESS_TYPE,
  STREET_ADDRESS_ICON_NAME,
  POST_ADDRESS_ICON_NAME,
} from '../constants'

function renderAddress(address, index, content) {
  let addressTypeText = null
  let iconName = ''

  if (address) {
    if (address.addressType === POST_ADDRESS_TYPE) {
      addressTypeText = get(content, 'postAddressHeading')
      iconName = POST_ADDRESS_ICON_NAME
    } else if (address.addressType === STREET_ADDRESS_TYPE) {
      addressTypeText = get(content, 'streetAddressHeading')
      iconName = STREET_ADDRESS_ICON_NAME
    }
  }

  if (!addressTypeText) {
    return null
  }

  return (
    <div className={styles.infoRow} key={index}>
      <Icon name={iconName} className={styles.infoIcon} lg />
      <div className={styles.content}>
        <span className={styles.infoHeading}>{addressTypeText}</span>
        <span className={styles.streetText}>{address.address}</span>
        <span>{address.zipCode} <span className={styles.cityText}>{address.city}</span></span>
      </div>
    </div>
  )
}

function CustomerInformationPanel(props) {
  const {
    className,
    customer,
    selectedAuthorizationObject,
    wholeRow,
    cmsMessages,
    customerInformation,
  } = props

  if (!customer || !selectedAuthorizationObject) {
    return null
  }

  const content = collectCmsMessages('/customer-information/basicinformation', cmsMessages)

  return (
    <div className={className}>
      <PanelNavigation
        id="customerInformation"
        title={get(content, 'title')}
        active
        noFocus
        className={classNames(styles.panelContainer)}
        style={{ minHeight: '150px', paddingBottom: '0px' }}
      >
        {!customer.fetchingCustomer && customerInformation &&
          <div className={classNames(styles.informationContainer, wholeRow ? styles.wholeRow : '')}>
            {customerInformation.fullName &&
              <div className={styles.infoRow}>
                <Icon name="service-customer" className={styles.infoIcon} lg />
                <div className={styles.content}>
                  <span className={styles.infoHeading}>{get(content, 'companyNameHeading')}</span>
                  <span>{customerInformation.fullName}</span>
                </div>

              </div>
            }
            {customerInformation.customerIdentifier &&
              <div className={styles.infoRow}>
                <Icon name="service-luvat" className={styles.infoIcon} lg />
                <div className={styles.content}>
                  <span className={styles.infoHeading}>
                    {get(content, 'customerIdentifierHeading')}
                  </span>
                  <span>{customerInformation.customerIdentifier}</span>
                </div>
              </div>
            }
            {customerInformation.hasEori &&
              <div className={styles.infoRow}>
                <Icon name="globe-checked" className={styles.infoIcon} lg />
                <div className={styles.content}>
                  <span className={styles.infoHeading}>{get(content, 'eoriHeading')}</span>
                  <span>{selectedAuthorizationObject.eori}</span>
                </div>
              </div>
            }
            {customerInformation.addresses && customerInformation.addresses.length > 0 &&
              customerInformation.addresses.map(((address, index) => (
                renderAddress(address, index, content)
              )))
            }
            {customerInformation.phoneNumber &&
              <div className={styles.infoRow}>
                <Icon name="phone" className={styles.infoIcon} lg />
                <div className={styles.content}>
                  <span className={styles.infoHeading}>{get(content, 'phoneNumberHeading')}</span>
                  <span>{customerInformation.phoneNumber}</span>
                </div>
              </div>
            }
          </div>
        }
      </PanelNavigation>
    </div >
  )
}

const mapStateToProps = state => ({
  locale: state.locale,
  customer: state.customer,
  cmsMessages: get(state, 'content.cmsMessages', {}),
})

export default connect(mapStateToProps)(injectIntl(CustomerInformationPanel))
