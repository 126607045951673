import { DECLARATION_STATUS_CHANGED } from '../../constants'

export default {
  [DECLARATION_STATUS_CHANGED]: state => ({
    ...state,
    duplicateWarning: false,
    showAcceptedModal: true,
    lastSave: Date.now(),
  }),
}
