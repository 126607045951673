export function setCookie(cname, cvalue, exdays) {
  const d = new Date()
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000))
  const expires = `expires=${d.toUTCString()}`
  document.cookie = `${cname}=${cvalue};${expires};path=/`
}

export function setSessionCookie(cname, cvalue) {
  document.cookie = `${cname}=${cvalue};path=/`
}

export function getCookie(cname) {
  const name = `${cname}=`
  const decodedCookie = decodeURIComponent(document.cookie)
  const ca = decodedCookie.split(';')
  for (let i = 0; i < ca.length; i += 1) {
    let c = ca[i]
    while (c.charAt(0) === ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length)
    }
  }
  return false
}

export function deleteCookie(cname) {
  setCookie(cname, '', -1)
}

/*
Transforms an array of objects:
[
  {
    a: 1
  },
  {
    b: 2
  }
]

into an object
{
  a: 1,
  b: 2
}
*/
export function transformArrayIntoObject(array) {
  if (array) {
    return array.reduce((prev, curr) => {
      Object.assign(prev, curr)
      return prev
    }, {})
  }

  return null
}
