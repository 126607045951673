import React from 'react'
import { get } from 'lodash'
import { Button } from 'react-bootstrap'
import { withRouter } from 'react-router-dom'
import Loader from 'src/components/Loader'
import Icon from 'src/components/Icon'
import Heading from 'src/layout/Heading'
import sendFeedback from '../api'
import messages from '../messages'
import styles from './feedback.scss'

const STEP_INITIAL = 'initial'
const STEP_SUBMITTING = 'submitting'
const STEP_SUBMITTED = 'submitted'
const MAX_TEXT_LENGTH = 500
const KEYCODE_SPACEBAR = 32
const KEYCODE_LEFT_ARROW = 37
const KEYCODE_UP_ARROW = 38
const KEYCODE_RIGHT_ARROW = 39
const KEYCODE_DOWN_ARROW = 40

class Feedback extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      step: STEP_INITIAL,
      showMissingRatingError: false,
      rating: 0,
    }
    this.freeText = ''
    this.service = 'default'
  }

  componentDidMount() {
    this.service = localStorage.getItem('previousServicePath') || 'default'
  }

  onClickContinue = () => {
    localStorage.removeItem('previousServicePath')
    this.props.history.replace('/')
  }

  onClickSend = () => {
    if (this.state.rating > 0) {
      this.setState({ step: STEP_SUBMITTING })
      sendFeedback(this.service, this.state.rating, this.freeText)
      this.setState({ step: STEP_SUBMITTED })
      setTimeout(() => {
        document.getElementById('thanksheader').focus()
      }, 1)
    } else {
      this.setState({ showMissingRatingError: true })
      setTimeout(() => {
        document.getElementById('star1').focus()
      }, 1000)
    }
  }

  onTextChange = (text) => {
    this.freeText = text
  }

  onKeyPress(event) {
    const keyCode = event.keyCode
    let nextRating
    switch (keyCode) {
    case KEYCODE_UP_ARROW:
    case KEYCODE_LEFT_ARROW:
      event.preventDefault() // disable scroll when stars are focused
      nextRating = this.state.rating - 1
      break
    case KEYCODE_DOWN_ARROW:
    case KEYCODE_RIGHT_ARROW:
      event.preventDefault() // disable scroll when stars are focused
    // eslint-disable-next-line no-fallthrough
    case KEYCODE_SPACEBAR:
      nextRating = this.state.rating + 1
      break
    default:
      return
    }
    if (nextRating > 5) {
      nextRating = 1
    } else if (nextRating < 1) {
      nextRating = 5
    }
    this.setRating(nextRating)
  }

  setRating = (rating) => {
    const currStar = document.getElementById(`star${rating}`)
    currStar.focus()
    currStar.click()
    if (this.state.showMissingRatingError) {
      this.setState({ rating, showMissingRatingError: false })
    } else {
      this.setState({ rating })
    }
  }

  showStar(n) {
    const imageName = (n > this.state.rating) ? 'tulli-star.svg' : 'tulli-star-filled.svg'
    const tabIndex = (this.state.rating === 0 && n === 1) || (this.state.rating === n) ? '0' : '-1'
    return (
      <div
        className={styles.star}
        role="radio"
        aria-checked={(this.state.rating === n) ? 'true' : 'false'}
        value={n}
        onClick={() => this.setRating(n)}
        onKeyDown={e => this.onKeyPress(e)}
        tabIndex={tabIndex}
        key={n}
        id={`star${n}`}
        aria-label={get(this.props.content, `stars${n}`)}
      >
        <img src={`assets/images/${imageName}`} alt="" />
      </div>
    )
  }

  render() {
    return (
      <div>
        <Heading message={messages.title} compact noFocus />
        {this.state.step === STEP_SUBMITTING && <Loader blocking />}
        <main
          className={'container'}
          id="main"
        >
          <div className={styles.container}>
            {this.state.step === STEP_INITIAL &&
              <div>
                <div className="text-center">
                  <div className={styles.iconRow}>
                    <Icon name="smiley-smile" className={styles.smiley} xlg />
                  </div>
                  <h1 className={styles.greeter}>
                    <span className={styles.greeterText}>{get(this.props.content, 'greeter1')}</span>
                    <span className={styles.greeterText}>{get(this.props.content, 'greeter2')}</span>
                  </h1>
                  <div className={styles.starInput}>
                    <fieldset id="starinput">
                      <legend>
                        <span className={styles.starTitle}>
                          <strong>
                            {get(this.props.content, 'startitle')}
                          </strong>
                        </span>
                        <span className={styles.starHint}>
                          {get(this.props.content, 'starhint')}
                        </span>
                        {this.state.showMissingRatingError &&
                          <span className="sr-only">
                            {get(this.props.content, 'errormessage')}
                          </span>
                        }
                      </legend>
                      <div className={styles.starRowContainer}>
                        <div className={styles.starRow}>
                          {[1, 2, 3, 4, 5].map(n => this.showStar(n))}
                        </div>
                      </div>
                    </fieldset>
                  </div>
                </div>
                {this.state.rating > 0 &&
                  <div>
                    <div id="feedbacktone" className={styles.feedbackTone} >
                      {this.state.rating < 3 &&
                        <strong>
                          {get(this.props.content, 'badfeedback')}
                        </strong>
                      }
                      {this.state.rating > 2 &&
                        <strong>
                          {get(this.props.content, 'goodfeedback')}
                        </strong>
                      }
                    </div>
                    <div id="feedbacknote" className={styles.feedbackNote} >
                      {get(this.props.content, 'feedbacknote')}
                    </div>
                    <textarea
                      className={styles.freeText}
                      aria-labelledby="feedbacktone feedbacknote"
                      maxLength={MAX_TEXT_LENGTH}
                      placeholder={get(this.props.content, 'feedbackplaceholder')}
                      onChange={e => this.onTextChange(e.target.value)}
                    />
                  </div>
                }
                <div className={styles.footer}>
                  <div className={styles.buttonContainer}>
                    <Button
                      className={styles.buttonText}
                      onClick={this.onClickContinue}
                    >
                      {get(this.props.content, 'tofrontapage')}
                    </Button>
                    <Button
                      className={styles.buttonText}
                      bsStyle="primary"
                      onClick={this.onClickSend}
                    >
                      {get(this.props.content, 'send')}
                    </Button>
                  </div>
                  {this.state.showMissingRatingError &&
                    <div className={styles.errorText} aria-hidden="true">
                      {get(this.props.content, 'errormessage')}
                    </div>
                  }
                </div>
              </div>
            }
            {
              this.state.step === STEP_SUBMITTED &&
              <div className={styles.container}>
                <div className="text-center">
                  <Icon name="smiley-smile" className={styles.smiley} xlg />
                  <h1 id="thanksheader" className={styles.greeter} tabIndex="-1">
                    {get(this.props.content, 'thanksheader')}
                  </h1>
                  <p className={styles.thanksContent}>
                    <strong>
                      {get(this.props.content, 'thankscontent')}
                    </strong>
                  </p>
                  <Button
                    className={styles.buttonText}
                    onClick={this.onClickContinue}
                  >
                    {get(this.props.content, 'tofrontapage')}
                  </Button>
                </div>
              </div>
            }
          </div>
        </main>
      </div>
    )
  }
}

export default withRouter(Feedback)
