import { last, get } from 'lodash'
import logger from 'src/utils/logger'
import { createDefaultAction } from 'src/utils/redux'
import { apiCall } from 'src/utils/http'
import { showGlobalNotification, loadingAction } from 'src/actions'
import {
  INITIAL_FETCH_ITEMS_COUNT,
  MORE_DECLARATIONS_RECEIVED,
  STATUS_FILTER_CHANGE,
  INITIAL_DECLARATIONS_RECEIVED,
  DESTROY_DECLARATIONS_LIST_CACHE,
  ACTIVATE_DECLARATION_IN_LIST,
} from './constants'
import { getSelectedAuthorization } from '../../../utils/auth'
import apiMessages from '../declaration/apiMessages'

const fetchDeclarationsFromApi = (status, resultSize, searchId) =>
  (dispatch, getState) => {
    const { config: { bootstrapConfig } } = getState()
    const company = getSelectedAuthorization()
    const base = bootstrapConfig.tm_intrastat_ext_url
    let apiUrl = `${base}/declarations/?companyId=${company.id}&browseForward=false`
    if (status) {
      apiUrl += `&phaseType=${status}`
    }
    if (resultSize) {
      apiUrl += `&resultSize=${resultSize}`
    }
    if (searchId) {
      apiUrl += `&searchId=${searchId}`
    }

    return apiCall(apiUrl, { method: 'GET' }, { }, dispatch, true)
  }

const moreDeclarationsReceived = createDefaultAction(MORE_DECLARATIONS_RECEIVED)

const fetchMoreDeclarations = (status, lastId) =>
  (dispatch, getState) => {
    dispatch(loadingAction({ key: MORE_DECLARATIONS_RECEIVED, value: true }))

    return fetchDeclarationsFromApi(status, undefined, lastId)(dispatch, getState)
      .then((response) => {
        dispatch(moreDeclarationsReceived(response, { filterByStatus: status }))
        dispatch(loadingAction({ key: MORE_DECLARATIONS_RECEIVED, value: false }))
      })
      .catch((error) => {
        logger.error(`Error fetching more Intrastat declarations with status ${status}, last id ${lastId}`, error)

        const errorCode = error.value.code
        const globalIntlMessage = apiMessages[errorCode]
        if (globalIntlMessage) {
          dispatch(showGlobalNotification({
            level: 'error',
            message: globalIntlMessage,
          }))
        }

        dispatch(moreDeclarationsReceived(error, { filterByStatus: status }))
        dispatch(loadingAction({ key: MORE_DECLARATIONS_RECEIVED, value: false }))
      })
  }

const statusFilterChange = createDefaultAction(STATUS_FILTER_CHANGE)
const initialDeclarationsReceived = createDefaultAction(INITIAL_DECLARATIONS_RECEIVED)

export const fetchInitialDeclarations = status =>
  (dispatch, getState) => {
    dispatch(statusFilterChange(status))
    dispatch(loadingAction({ key: INITIAL_DECLARATIONS_RECEIVED, value: true }))

    return fetchDeclarationsFromApi(status, INITIAL_FETCH_ITEMS_COUNT, 0)(dispatch, getState)
      .then((response) => {
        if (get(getState(), 'intrastat.list.statusFilter') !== status) {
          // If user has changed filter conditions while fetching, do not continue with old response data
          return
        }
        dispatch(initialDeclarationsReceived(response, { filterByStatus: status }))
        dispatch(loadingAction({ key: INITIAL_DECLARATIONS_RECEIVED, value: false }))
        if (response.declarations && response.declarations.length < response.totalCount) {
          const lastId = last(response.declarations).id
          fetchMoreDeclarations(status, lastId)(dispatch, getState)
        }
      })
      .catch((error) => {
        logger.error(`Error fetching initial Intrastat declarations with status ${status}`, error)

        const errorCode = error.value.code
        const globalIntlMessage = apiMessages[errorCode]
        if (globalIntlMessage) {
          dispatch(showGlobalNotification({
            level: 'error',
            message: globalIntlMessage,
          }))
        }

        dispatch(initialDeclarationsReceived(error, { filterByStatus: status }))
        dispatch(loadingAction({ key: INITIAL_DECLARATIONS_RECEIVED, value: false }))
      })
  }

export const destroyCacheState = createDefaultAction(DESTROY_DECLARATIONS_LIST_CACHE)
export const activateDeclarationRow = createDefaultAction(ACTIVATE_DECLARATION_IN_LIST)
