/**
 * @file Container component connecting presentational React component to Redux state
 * @see {@link http://redux.js.org/docodeset/basicodeset/UsageWithReact.html}
 * @author Sami Tiilikainen
 */

import { connect } from 'react-redux'
import { reduxForm, getFormValues } from 'redux-form'
import { withRouter } from 'react-router-dom'
import { injectIntl } from 'react-intl'
import { get, find, isEmpty, isNil, sortBy, filter } from 'lodash'
import { focusFirstElementByIdOrName } from 'src/utils'
import { EU_MEMBER_STATES_CODE } from 'src/components/codeset/constants'
import RowForm from '../components/RowForm'
import messages from '../messages'
import {
  INTRASTAT_FORM_NAME,
  INTRASTAT_ROW_FORM_NAME,
  DECLARATION_STATUS_ACCEPTED,
  DECLARATION_STATUS_DEFACED,
  CN8_FETCHED,
} from '../../../constants'
import { validateRow as validate, shouldValidateRow as shouldValidate } from '../validator'
import {
  selectRow,
  toggleNewRowEditing,
  addSingleNewRow,
  deleteRow,
  notifyDoneSavingRow,
  fetchAndUpdateExistingRow,
  fetchCN8Code,
  searchCN8Text,
  fetchCN8TreeRoot,
  selectCN8TreeNode,
  clearCN8SearchResults,
  preserveRowFieldValue,
  notifyMissingInformation,
} from '../actions'
import { fetchNonCachedCodesets } from '../../../../actions'
import { getSimstatMode, rowHasItemNumber } from '../../../utils'

const onSubmitFail = (errors) => {
  const errorIds = Object.keys(errors)
  focusFirstElementByIdOrName(errorIds)
}

const formConfig = {
  form: INTRASTAT_ROW_FORM_NAME,
  validate,
  shouldValidate,
  keepDirtyOnReinitialize: false,
  onSubmitFail,
}

function codesetItemToSelectOption(item) {
  return {
    id: item.code,
    value: item.code,
    title: `${item.code} - ${item.name}`,
  }
}

function reversedCodesetItemToSelectOption(item) {
  return {
    id: item.code,
    value: item.code,
    title: `${item.name} (${item.code})`,
  }
}

// Creates a sort function that moves preferred codeset codes
// to the beginning of the array in the same order they are
// in the preferred codes array
function preferCodes(preferred) {
  return (a, b) => {
    const preferredIndexA = preferred.indexOf(a.code)
    const preferredIndexB = preferred.indexOf(b.code)

    if (preferredIndexA > -1) {
      if (preferredIndexB > -1) {
        return preferredIndexA - preferredIndexB // Both preferred
      }

      return -1 // A preferred, B not
    }

    if (preferredIndexB > -1) {
      return 1 // B preferred, A not
    }

    return a.code.localeCompare(b.code)
  }
}

function createCurrencyToSelectOptionMapper(popularCurrencies) {
  return (item) => {
    const option = {
      id: item.code,
      value: item.code,
      title: item.code,
    }

    if (popularCurrencies && popularCurrencies.length > 0) {
      option.group = {
        sort: popularCurrencies.includes(item.code) ? 1 : 2,
        labelMessage: popularCurrencies.includes(item.code) ?
          messages.popularCurrenciesGroupLabel : messages.otherCurrenciesGroupLabel,
      }
    }

    return option
  }
}

function currencyConversion(value, currencyCode, currencies) {
  if (isEmpty(currencies)) {
    return null
  }
  if (currencyCode === 'EUR') {
    return value
  }

  const rate = get(find(currencies, { code: currencyCode }), 'euroRate')
  if (rate) {
    return Math.round(parseFloat(value) / parseFloat(rate))
  }
  return null
}

const getIntrastatFormValues = getFormValues(INTRASTAT_FORM_NAME)
const getIntrastatRowFormValues = getFormValues(INTRASTAT_ROW_FORM_NAME)

const mapStateToProps = (state) => {
  const {
    rows,
    declarationId,
    referencePeriod,
    flowCode,
    status,
    psi,
  } = getIntrastatFormValues(state)

  const {
    newRow,
    selectedRow,
    deletingRow,
    fetchingCN8Tree,
    searchingCN8Text,
    fetchError,
    cn8Trees,
    cn8SearchResults,
    preservedRowFieldValues,
  } = state.intrastat.declaration

  const cn8Locale = state.intrastat.declaration.cn8Trees

  const fetchingCN8 = state.loading[CN8_FETCHED]

  // Convert codesets to select options
  const selectOptions = {}
  const codeset = get(state, `intrastat.common.cachedCodesets[${referencePeriod}]`)
  if (codeset && referencePeriod) {
    selectOptions.memberStates =
      codeset.memberStates &&
      sortBy(codeset.memberStates.map(items =>
        items.find(item => item.locale === state.locale)), 'name').map(reversedCodesetItemToSelectOption)
    selectOptions.countriesOfOrigin =
      codeset.countriesOfOrigin &&
      sortBy(codeset.countriesOfOrigin.map(items =>
        items.find(item => item.locale === state.locale)), 'name').map(reversedCodesetItemToSelectOption)
    selectOptions.countriesOfOriginForSimstat =
      codeset.countriesOfOriginForSimstat &&
      sortBy(codeset.countriesOfOriginForSimstat.map(items =>
        items.find(item => item.locale === state.locale)), 'name').map(reversedCodesetItemToSelectOption)
    selectOptions.transactionTypes =
      codeset.transactionTypes &&
      codeset.transactionTypes.map(items =>
        items.find(item => item.locale === state.locale)).map(codesetItemToSelectOption)
    selectOptions.transportTypes =
      codeset.transportTypes &&
      codeset.transportTypes.map(items =>
        items.find(item => item.locale === state.locale)).map(codesetItemToSelectOption)

    const currencyArray = codeset.currencies ? codeset.currencies : []
    const currenciesWithEuro = [{
      code: 'EUR',
      euroRate: '1.00',
      name: 'EUR',
    }].concat(currencyArray)

    selectOptions.currencies = currenciesWithEuro

    const popularCurrencies = !isEmpty(state.config.bootstrapConfig.intrastatPopularCurrencies)
      && filter(state.config.bootstrapConfig.intrastatPopularCurrencies.split(','))
    if (codeset.currencies && popularCurrencies) {
      selectOptions.currencies = currenciesWithEuro
        .sort(preferCodes(popularCurrencies))
        .map(createCurrencyToSelectOptionMapper(popularCurrencies))
    }
  }

  const codesetErrors = get(state, `intrastat.common.codesetErrors[${referencePeriod}]`, {})
  const fetchingCodesets = get(state, `intrastat.common.fetchingCodesets[${referencePeriod}]`, {})
  const hasDoneNotificationVisible = state.notifications.some(item => item.message.id === messages.doneSavingRow.id)

  // Map stored secondary units to props
  const secondaryUnits = {}
  if (codeset && codeset.secondaryUnits) {
    codeset.secondaryUnits.forEach((secondaryUnit) => {
      secondaryUnits[secondaryUnit.code] = secondaryUnit.abbreviation
    })
  }

  // Calculate eur values
  const values = { ...getIntrastatRowFormValues(state) }
  if (codeset && codeset.currencies && values) {
    if (values.statisticalValue && values.statisticalValueCurrencyCode) {
      values.statisticalValueInEur = currencyConversion(
        values.statisticalValue, values.statisticalValueCurrencyCode, codeset.currencies
      )
    } else {
      values.statisticalValueInEur = null
    }
    if (values.invoicedAmount && values.invoicedValueCurrencyCode) {
      values.invoicedAmountInEur = currencyConversion(
        values.invoicedAmount, values.invoicedValueCurrencyCode, codeset.currencies
      )
    }
  }

  let selectedRowNumber
  let serverValues = null
  if (!isNil(selectedRow) && rows && rows.length) {
    const index = rows.findIndex(row => row.id === selectedRow)
    if (index !== -1) {
      selectedRowNumber = rowHasItemNumber(rows) ? rows[index].itemNumber : index + 1
      serverValues = rows[index]
    }
  }

  const referencePeriodYear = referencePeriod.substr(0, 4)
  const getCN8Code = code => get(state.intrastat.declaration.cachedCN8Codes, [referencePeriodYear, code])

  return {
    locale: state.locale,
    cmsMessages: get(state, 'content.cmsMessages'),
    newRow,
    selectedRowNumber,
    deletingRow,
    declarationId,
    referencePeriod,
    rows,
    flowCode,
    selectOptions,
    codesetErrors,
    accepted: status === DECLARATION_STATUS_ACCEPTED,
    invalidated: status === DECLARATION_STATUS_DEFACED,
    rowCount: rows && rows.length,
    rowFieldValues: values,
    fetchingCN8Tree,
    fetchingCN8,
    searchingCN8Text,
    fetchError,
    fetchingCodesets,
    getCN8Code,
    secondaryUnits,
    cn8Data: referencePeriod && values && values.CN8Code && getCN8Code(values.CN8Code),
    cn8Trees,
    cn8Locale,
    cn8SearchResults,
    getSimstatMode: () => getSimstatMode(referencePeriod, flowCode, psi, state.intrastat.common.cachedCustomers),
    preservedRowFieldValues,
    hasDoneNotificationVisible,
    serverValues,
  }
}

const mapActionCreators = {
  fetchCN8Code,
  searchCN8Text,
  clearCN8SearchResults,
  fetchCN8TreeRoot,
  selectCN8TreeNode,
  toggleNewRowEditing,
  addSingleNewRow,
  fetchAndUpdateExistingRow,
  deleteRow,
  notifyDoneSavingRow,
  notifyMissingInformation,
  preserveRowFieldValue: (fieldName, value) => preserveRowFieldValue({ fieldName, value }),
  cancelRowEditing: () => selectRow(null),
  fetchCodesets: date =>
    fetchNonCachedCodesets(
      [
        'transactionTypes',
        'transportTypes',
        'memberStates',
        'countriesOfOrigin',
        'countriesOfOriginForSimstat',
        'currencies',
        'secondaryUnits',
        EU_MEMBER_STATES_CODE,
      ],
      date
    ),
}
export default withRouter(
  connect(mapStateToProps, mapActionCreators)(
    reduxForm(formConfig)(
      injectIntl(
        RowForm
      ))))
