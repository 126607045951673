import { configureStore as configure } from '@reduxjs/toolkit'

import { reducers } from './reducers/root'

let store

export function createStore(initialState = {}, history) {
  // Create store with empty structure for asynchronously injected reducers
  store = configure({
    reducer: reducers(),
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        thunk: true,
        immutableCheck: false,
        serializableCheck: false,
      }),
    initialState,
  })
  store.asyncReducers = {}

  // Webpack hot module replacement (live reload)
  if (module.hot) {
    module.hot.accept('./reducers/root', () => {
      // eslint-disable-next-line global-require
      const newReducers = require('./reducers/root').default

      store.replaceReducer(newReducers)
    })
  }

  return store
}

export function getStore() {
  return store
}
