import React from 'react'
import { isEmpty } from 'lodash'
import { Field } from 'redux-form'
import { SelectFieldGroup } from 'src/components/form/Select'
import messages from '../messages'
import { REPORTING_UNIT, PSI, TDP } from '../../../constants'


function getTdpReportingUnitSelectOptions(selectedTdpObject) {
  if (!selectedTdpObject || isEmpty(selectedTdpObject.reportingUnits)) {
    return []
  }
  return selectedTdpObject.reportingUnits.filter(reportingUnit =>
    [REPORTING_UNIT, PSI, TDP].includes(reportingUnit.reporterType)
  )
    .map(reportingUnit =>
      ({
        id: reportingUnit.intCode,
        value: reportingUnit.intCode,
        title: reportingUnit.intCode,
      })
    )
}

export default function TdpReportingUnitColumn({ tdpId, tdpReportingUnit, tdpOrganization, disabled, helpText }) {
  if (!tdpId) {
    return null
  }
  const selectOptions = getTdpReportingUnitSelectOptions(tdpOrganization)
  if (!selectOptions.length && !tdpReportingUnit) {
    return null
  }
  return (
    <Field
      component={SelectFieldGroup}
      name="tdpReportingUnit"
      props={{
        disabled,
        label: String.fromCharCode(160),
        placeholderMessage: (selectOptions.length || tdpReportingUnit) ?
          messages.reportingUnitPlaceholder : messages.noReportingUnitsPlaceholder,
        options: selectOptions,
        help: ({
          content: helpText,
        }),
      }}
    />
  )
}
