import { defineMessages } from 'react-intl'

const messages = defineMessages({
  noFile: {
    id: 'api.intrastat.noFileSelected',
    description: 'Error message for Intrastat import, when no file is specified',
    defaultMessage: 'No file selected',
  },
  fileReaderError: {
    id: 'api.intrastat.fileReadError',
    description: 'Error message for Intrastat import, when unexpected file read error occurs',
    defaultMessage: 'Could not read file',
  },
  invalidFileType: {
    id: 'api.intrastat.invalidFileType',
    description: 'Error message for invalid file type (file name extension)',
    defaultMessage: 'Invalid file type, file extension should be .txt or .csv, or no extension at all.',
  },
  invalidFileContent: {
    id: 'api.intrastat.invalidFileContent',
    description: 'Error message for invalid content in CSV or ASCII file',
    defaultMessage: 'The file structure is invalid. ' +
                    'Check that you are using correct separator characters and keywords.',
  },
  invalidNumberOfHeaderColumnsInCSV: {
    id: 'api.intrastat.invalidNumberOfHeaderColumnsInCSV',
    description: 'Error message for invalid number of header columns in CSV file.',
    defaultMessage: 'The CSV file has invalid number of header columns.',
  },
  invalidFileSyntax: {
    id: 'api.intrastat.invalidFileSyntax',
    description: 'Error message for invalid file syntax in csv import',
    defaultMessage: 'Invalid file syntax',
  },
  invalidNumberOfDataColumnsInCSV: {
    id: 'api.intrastat.invalidNumberOfDataColumnsInCSV',
    description: 'Error message for invalid number of data columns in a CSV file.',
    defaultMessage: 'Invalid number of data columns on line {lineNumber}.',
  },
  invalidFileSyntaxOnLine: {
    id: 'api.intrastat.invalidFileSyntaxOnLine',
    description: 'Error message for invalid file syntax in csv import, specific line',
    defaultMessage: 'Invalid file syntax on line {lineNumber}',
  },
  invalidTdpRejectedByUser: {
    id: 'api.intrastat.invalidTdpRejectedByUser',
    description: 'Error message for invalid TDP, after user has rejected proceeding in confirmation dialog',
    defaultMessage: 'Invalid third declaring party {invalidTdp} was rejected.',
  },
  invalidTdpConfirmation: {
    id: 'api.intrastat.invalidTdpConfirmation',
    description: 'Error confirmation message for invalid TDP, asking if user wants to continue with corrected TDP',
    defaultMessage: 'Third declaring party {invalidOrganizationId} does not match your current organization. ' +
                    'Do you want to continue with {userOrganizationId}?',
  },
  invalidInputOnColumn: {
    id: 'api.intrastat.invalidFileInputData',
    description: 'Error message for invalid file import data, ' +
                  'with variables {lineNumber}, {columnNumber} and {inputValue}',
    defaultMessage: 'Invalid value "{inputValue}" on line {lineNumber} column {columnNumber}.',
  },
  missingHeaders: {
    id: 'api.intrastat.import.missingHeaders',
    description: 'Error message for import files without declaration headers',
    defaultMessage: 'File does not contain declaration headers',
  },
  sumsNotMatching: {
    id: 'api.intrastat.import.sumsNotMatching',
    description: 'Error message for import files with sums that do not match actual rows',
    defaultMessage: 'Sums provided in file do not match declaration rows in file.',
  },
  incorrectAsciiSumsConfirmation: {
    id: 'api.intrastat.import.incorrectAsciiSumsConfirmation',
    description: 'Confirmation dialog message for incorrect sums, having ' +
                 '{sumRowCount}, {actualRowCount}, {sumInvoicedAmount} and {actualInvoicedAmount}',
    defaultMessage: 'Sums provided in file do not match declaration rows in file ' +
                 '(row count sum {sumRowCount} / actual {actualRowCount}, ' +
                 'invoiced amount sum {sumInvoicedAmount} / actual {actualInvoicedAmount}). ' +
                 'Continue with invalid sums?',
  },
  tooManyRows: {
    id: 'api.intrastat.import.tooManyRows',
    description: 'Error message for import files containing too many rows',
    defaultMessage: 'Too many rows. Maximum number of rows per declaration is {maxRowCount}.',
  },
})

export default messages
