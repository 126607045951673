
import moment from 'moment'
import { createDefaultAction } from 'src/utils/redux'
import logger from 'src/utils/logger'
import { apiCall } from 'src/utils/http'
import { parseCodesetData } from './utils'
import {
  CACHE_CODESET,
  CODESET_REQUEST,
  CODESET_STRING_DELIMITER,
} from './constants'

const codesetRequest = createDefaultAction(CODESET_REQUEST)

export const cacheCodeset = createDefaultAction(CACHE_CODESET)

export const fetchCodeset = (codesetName, subCodesetName, date) =>
  (dispatch, getState) => {
    const codesetNameCombined = codesetName + (subCodesetName ? `${CODESET_STRING_DELIMITER}${subCodesetName}` : '')
    const dateISO = date || moment().format('YYYY-MM-DD')
    dispatch(codesetRequest({ codesetNameCombined, date }))
    const subsetPath = subCodesetName ? `&subset=${subCodesetName}` : ''
    const dateString = `&date=${dateISO}`
    const { config: { bootstrapConfig } } = getState()
    return apiCall(
      `${bootstrapConfig.tm_codeset_ext_url}/codeset?codeset=${codesetName}${subsetPath}${dateString}`,
      { method: 'GET', cache: 'default' },
      null,
      dispatch,
      false
    )
      .then(data => parseCodesetData(data))
      .then((data) => {
        dispatch(cacheCodeset(data, { codesetNameCombined, date: dateISO }))
        return {
          [codesetNameCombined]: {
            [dateISO]: data,
          },
        }
      })
      .catch((error) => {
        logger.error(`Error in fetching codeset ${codesetName}`, error)
        dispatch(cacheCodeset(error, { codesetNameCombined, date: dateISO }))
        throw error
      })
  }


export const fetchNonCachedCodesets = (codesets, date) =>
  (dispatch, getState) => {
    const fetchPromises = codesets
      .map((codesetString) => {
        const codesetSplit = codesetString.split(CODESET_STRING_DELIMITER)
        return fetchCodeset(codesetSplit[0], codesetSplit[1], date)(dispatch, getState)
      })

    return Promise.all([...fetchPromises])
  }
