import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl'
import { Row, Col, Button } from 'react-bootstrap'
import { find, endsWith, get } from 'lodash'
import Icon from 'src/components/Icon'
import Loader from 'src/components/Loader'
import InfoElementHeaderArea from 'src/components/form_v2/InfoElementHeaderArea'
import { fetchDecisionFile } from '../utils'
import api from '../../api'
import PermitPageContext from './PermitPage/context'
import { createDraftObj, getApplicant, getRepresentative } from '../permitHelper'
import {
  PERMITS_ROUTE_PATH,
  PERMIT_APPLICATION_ROUTE_PATH,
} from '../../constants'
import {
  INIT_ROUTE_PATH,
  PERMIT_TYPE_CODE_CGU,
} from '../constants'

const messages = defineMessages({
  noGuaranteeFound: {
    id: 'permits.comprehensiveGuarantee.noGuaranteeFound',
    description: 'Label for missing guarantee permit',
    defaultMessage: '?? No CGU-authorisation',
  },
  openGuaranteeApplication: {
    id: 'permits.comprehensiveGuarantee.openGuaranteeApplication',
    description: 'Label for link',
    defaultMessage: '?? Open comprehensive guarantee application',
  },
  openPermit: {
    id: 'permits.openPermit',
    description: 'Text in button',
    defaultMessage: '?? Open permit',
  },
  createGuaranteeApplicationDraft: {
    id: 'permits.comprehensiveGuarantee.createGuaranteeApplicationDraft',
    description: 'Text in button',
    defaultMessage: '?? Create guarantee application',
  },
  creatingNewApplication: {
    id: 'permits.comprehensiveGuarantee.creatingNewApplication',
    description: 'Text in loader',
    defaultMessage: '?? Creating new application',
  },
})

class ComprehensiveGuarantee extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isFetchingFile: false,
    }
    this.createGuaranteeApplicationDraft = this.createGuaranteeApplicationDraft.bind(this)
  }

  componentDidMount() {
    this.setComprehensiveGuaranteeValue()
  }

  componentDidUpdate() {
    this.setComprehensiveGuaranteeValue()
  }

  getGuaranteeReferenceNumbers() {
    const { activeGuarantee, guaranteeApplication } = this.context
    return {
      guaranteeReferenceNumber: get(activeGuarantee, 'referenceNumber'),
      guaranteeApplicationReferenceNumber: get(guaranteeApplication, 'id'),
    }
  }

  setComprehensiveGuaranteeValue() {
    const { formApi } = this.props
    const { guaranteeReferenceNumber, guaranteeApplicationReferenceNumber } = this.getGuaranteeReferenceNumbers()
    if (!get(formApi, 'values.comprehensiveGuarantee.comprehensiveGuaranteeDecisionReferenceNumber') &&
      (guaranteeReferenceNumber || guaranteeApplicationReferenceNumber)) {
      formApi.setValue('comprehensiveGuarantee', {
        comprehensiveGuaranteeDecisionReferenceNumber: guaranteeReferenceNumber || guaranteeApplicationReferenceNumber,
      })
    }
  }
  static contextType = PermitPageContext

  createGuaranteeApplicationDraft() {
    const { applicant, representative, locale, setPageLoader, history, customer } = this.props
    setPageLoader(true, messages.creatingNewApplication)
    api.createDraft({ applicationTypeCode: PERMIT_TYPE_CODE_CGU })
      .then((response) => {
        const draftObj = createDraftObj(PERMIT_TYPE_CODE_CGU, applicant, representative, customer, locale)
        return api.saveDraft(draftObj, response.referenceNumber, response.version)
      })
      .then((response) => {
        setPageLoader(false)
        // eslint-disable-next-line max-len
        history.replace(`/${PERMITS_ROUTE_PATH}?redirect=${PERMIT_APPLICATION_ROUTE_PATH}/${response.referenceNumber}:${response.version}/${INIT_ROUTE_PATH}`)
      })
  }

  render() {
    const {
      infoElement,
      renderField,
      intl: { formatMessage },
      locale,
      structure,
      nakkiFrontpageUrl,
    } = this.props
    const { activeGuarantee, guaranteeApplication, fetchingActiveGuarantee } = this.context
    const { isFetchingFile } = this.state
    const typeCode = get(structure, 'code')

    const numberField = find(infoElement.fields, field =>
      endsWith(field.code, 'comprehensiveGuaranteeDecisionReferenceNumber'))
    const comprehensiveGuaranteeField = find(infoElement.fields, field =>
      endsWith(field.code, 'comprehensiveGuarantee'))
    const {
      guaranteeReferenceNumber,
      guaranteeApplicationReferenceNumber,
    } = this.getGuaranteeReferenceNumbers()
    const guaranteeNotFoundWarningText = formatMessage({
      id: `/permits/fields/comprehensiveGuaranteeDecisionReferenceNumberNotFound_${typeCode}`,
    })

    const guaranteeMissing = !guaranteeReferenceNumber
    const guaranteeApplicationMissing = !guaranteeApplicationReferenceNumber
    const guaranteeAndApplicationMissing = guaranteeMissing && guaranteeApplicationMissing

    const fieldTitleWhenMissing = !guaranteeMissing || guaranteeAndApplicationMissing ?
      formatMessage({ id: '/permits/fields/comprehensiveGuaranteeDecisionReferenceNumberTitlePermit' }) :
      formatMessage({ id: '/permits/fields/comprehensiveGuaranteeDecisionReferenceNumberTitleApplication' })
    const numberFieldName = {
      name: {
        fi: fieldTitleWhenMissing,
        sv: fieldTitleWhenMissing,
      },
    }

    return (
      <Col sm={12} xs={12}>
        <InfoElementHeaderArea
          infoElement={infoElement}
          locale={locale}
        />
        {fetchingActiveGuarantee &&
          <div className="help-block">
            <Loader inline small />
          </div>
        }
        {!fetchingActiveGuarantee &&
          <div>
            <Row>
              <Col sm={6} xs={12}>
                {comprehensiveGuaranteeField && renderField({
                  field: comprehensiveGuaranteeField,
                })}
              </Col>
            </Row>
            <Row>
              <Col xs={6}>
                {numberField && renderField({
                  field: {
                    ...numberField,
                    ...numberFieldName,
                  },
                  staticValue: true,
                  customValue: guaranteeAndApplicationMissing ? formatMessage(messages.noGuaranteeFound) : null,
                  warning: guaranteeAndApplicationMissing ? guaranteeNotFoundWarningText : null,
                })}
              </Col>
              <Col xs={6} style={{ marginTop: '25px' }}>
                {isFetchingFile && <Loader inline tiny />}
                {!guaranteeMissing && activeGuarantee.hasPdf && !isFetchingFile &&
                  <Button
                    style={{ marginTop: '2px' }}
                    bsStyle="link"
                    onClick={() => fetchDecisionFile({
                      permitData: activeGuarantee,
                      formatMessage,
                      setLoader: () => this.setState({ isFetchingFile: true }),
                      clearLoader: () => this.setState({ isFetchingFile: false }),
                    })}
                    id-qa-test="btn-openPermitPdf"
                  >
                    <Icon name="pdf" /> <FormattedMessage {...messages.openPermit} />
                  </Button>
                }
                {guaranteeMissing && !guaranteeApplicationMissing &&
                  <div style={{ margin: '10px' }}>
                    <a
                      style={{ fontSize: '1.6rem', textDecoration: 'underline' }}
                      // eslint-disable-next-line max-len
                      href={`${nakkiFrontpageUrl}/${PERMITS_ROUTE_PATH}/${PERMIT_APPLICATION_ROUTE_PATH}/${guaranteeApplication.id}:${guaranteeApplication.version}/summary/print`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Icon name="external" />
                      {formatMessage(messages.openGuaranteeApplication)}
                    </a>
                  </div>
                }
                {guaranteeAndApplicationMissing &&
                  <Button
                    style={{ marginTop: '2px' }}
                    bsStyle="primary"
                    onClick={() => this.createGuaranteeApplicationDraft()}
                    id-qa-test="btn-createCGUApplication"
                  >
                    <FormattedMessage {...messages.createGuaranteeApplicationDraft} />
                  </Button>
                }
              </Col>
            </Row>
          </div>
        }
      </Col>
    )
  }
}

const mapStateToProps = state =>
  ({
    applicant: getApplicant(),
    representative: getRepresentative(),
    nakkiFrontpageUrl: get(state, 'config.bootstrapConfig.nakki_front_page_url'),
    customer: get(state, 'customer'),
  })

export default withRouter(
  connect(mapStateToProps)(
    injectIntl(ComprehensiveGuarantee)
  ))
