import React from 'react'
import { FormattedMessage, defineMessages } from 'react-intl'
import Radio from 'src/components/form/Radio'
import Modal from 'src/components/Modal'
import InfoAlert from 'src/components/InfoAlert'
import { withRouter } from 'react-router-dom'
import { formatReferencePeriod } from '../utils'


// Header-related intl message definitions
const messages = defineMessages({
  title: {
    id: 'intrastat.amendmentRequest.title',
    description: 'Title for intrastat amendment request modal',
    defaultMessage: 'Amendment version',
  },
  description: {
    id: 'intrastat.amendmentRequest.description',
    description: 'Description for amendment request modal, containing variables declarationId and referencePeriod',
    defaultMessage:
      'New version of intrastat declaration {declarationId} will be created for period {referencePeriod}.',
  },
  descriptionWithTdp: {
    id: 'intrastat.amendmentRequest.descriptionWithTdp',
    description: 'Description for amendment request modal, containing variables declarationId and referencePeriod',
    defaultMessage: 'You are about to amend declaration {declarationId} for declaration period {referencePeriod} ' +
      'submitted by the agent {tdpName}.  If you wish to amend the declaration using your current transaction role, the information on the agent will be deleted.',
  },
  instruction: {
    id: 'intrastat.amendmentRequest.instruction',
    description: 'A more detailed instruction for amendment request',
    defaultMessage: 'Old version is invalidated only after new version is accepted.',
  },
  copyRows: {
    id: 'intrastat.amendmentRequest.copyRows',
    description: 'A more detailed instruction for amendment request',
    defaultMessage: 'Old version is invalidated only after new version is accepted.',
  },
  importRows: {
    id: 'intrastat.amendmentRequest.importRows',
    description: 'A more detailed instruction for amendment request',
    defaultMessage: 'Old version is invalidated only after new version is accepted.',
  },
  noRowsLabel: {
    id: 'intrastat.amendmentRequest.noRows.label',
    description: 'Label for no rows checkbox in amendment modal',
    defaultMessage: 'Do not copy nomenclature rows (rows may be imported from file)',
  },
  continue: {
    id: 'intrastat.amendmentRequest.continue',
    description: 'Label for intrastat amendment modal submit button',
    defaultMessage: 'OK',
  },
  cancel: {
    id: 'intrastat.amendmentRequest.cancel',
    description: 'Label for intrastat amendment modal cancel button',
    defaultMessage: 'Cancel',
  },

})

class AmendmentModal extends React.Component {
  constructor(props) {
    super(props)
    this.toggleCopyRows = this.toggleCopyRows.bind(this)
    this.setCopyRows = this.setCopyRows.bind(this)
    this.focusOnYesRadio = this.focusOnYesRadio.bind(this)
    this.state = {
      copyRows: true,
    }
  }

  setCopyRows(copyRows) {
    this.setState({
      copyRows,
    })
  }

  toggleCopyRows() {
    this.setState({
      copyRows: !this.state.copyRows,
    })
  }

  focusOnYesRadio() {
    if (this.copyRowsYesRef) {
      this.copyRowsYesRef.focus()
    }
  }

  render() {
    const {
      tdpName,
      show,
      loading,
      onHide,
      declarationId,
      referencePeriod,
      createAndOpenNewVersion,
      createAndOpenNewVersionWithoutTdp,
      history,
    } = this.props
    return (
      <Modal
        show={show}
        showCancel
        showContinue
        cancelDisabled={loading}
        continueDisabled={loading}
        continueMessage={messages.continue}
        cancelMessage={messages.cancel}
        onClickCancel={onHide}
        onClickContinue={() => (tdpName ? createAndOpenNewVersionWithoutTdp(declarationId, this.state.copyRows, history) : createAndOpenNewVersion(declarationId, this.state.copyRows, history))}
        loading={loading}
        continueBtnStyle="primary"
        className="modal-amendment"
        onShowCb={this.focusOnYesRadio}
        titleMessage={messages.title}
      >
        <div >
          <InfoAlert
            id={'dialog-message'}
            message={tdpName ? messages.descriptionWithTdp : messages.description}
            values={{ tdpName, declarationId, referencePeriod: formatReferencePeriod(referencePeriod) }}
          />
          <Radio
            inputRef={(ref) => { this.copyRowsYesRef = ref }}
            name="copyRows"
            checked={this.state.copyRows}
            onClick={() => this.setCopyRows(true)}
            defaultChecked
          >
            <FormattedMessage {...messages.copyRows} />
          </Radio>
          <Radio
            name="copyRows"
            checked={!this.state.copyRows}
            onClick={() => this.setCopyRows(false)}
          >
            <FormattedMessage {...messages.importRows} />
          </Radio>
        </div>
      </Modal>
    )
  }
}

export default withRouter(AmendmentModal)
