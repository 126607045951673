import React from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'

import {
  SUMMARY_ROUTE_PATH,
  PRINT_SUMMARY_ROUTE_PATH,
} from './constants'
import withReducer from '../../../reducers/withReducer'

import LocationInformationFrontPage from './container'
import LocationInformationSummaryPage from './routes/summary'
import LocationInformationPrintSummaryPage from './routes/printSummary'
import LocationInformationReducer from './reducer'
import PageNotFound from 'src/components/PageNotFound'
import { checkLocationInformationFormAuthorization } from '../../../utils/auth'
import requireAuthentication from '../../../containers/requireAuthentication'


const LocationInformationRoutes = () => {
  let { path } = useRouteMatch()
  return (
    <Switch>
      <Route exact path={path}>
        <LocationInformationFrontPage />
      </Route>
      <Route path={`${path}/${SUMMARY_ROUTE_PATH}`}>
        <LocationInformationSummaryPage />
      </Route>
      <Route path={`${path}/${PRINT_SUMMARY_ROUTE_PATH}/:viewMode?`}>
        <LocationInformationPrintSummaryPage />
      </Route>
      <Route component={PageNotFound} />
    </Switch>
  )
}

export default withReducer([{ key: 'forms', reducer: LocationInformationReducer }])(requireAuthentication(
  LocationInformationRoutes,
  checkLocationInformationFormAuthorization
))

