
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { injectIntl } from 'react-intl'
import { get } from 'lodash'
import { collectCmsMessages } from 'src/utils'
import { fetchCodeset, fetchNonCachedCodesets } from 'src/components/codeset/actions'
import { fetchCustomerInformation, fetchRepresentativeInformation, fetchPermitRepresentativeInformation } from 'src/components/customer/actions'
import PermitPage from '../components/PermitPage'
import {
  destroyState,
  saveInitialValues,
} from '../actions'
import { getSelectedAuthorization, isApplicantPerson } from '../../../../utils/auth'
import { getApplicant, getRepresentative } from '../permitHelper'

const mapStateToProps = (state) => {
  const {
    permitTypes,
  } = state.permits.common

  const cmsMessages = get(state, 'content.cmsMessages', {})

  const isPerson = isApplicantPerson()

  return {
    locale: state.locale,
    helpTexts: {
      common: collectCmsMessages('/permits', cmsMessages),
      onboarding: collectCmsMessages('/permits/onboarding', cmsMessages),
      infoElements: collectCmsMessages('/permits/infoelements', cmsMessages),
      fields: collectCmsMessages('/permits/fields', cmsMessages),
      infoElementNames: collectCmsMessages('/permits/infoElementNames', cmsMessages),
      fieldNames: collectCmsMessages('/permits/fieldNames', cmsMessages),
    },
    helpTextLocale: state.content.cmsLocale,
    auth: state.auth,
    selectedAuthorizationObject: getSelectedAuthorization(),
    applicant: getApplicant(),
    representative: getRepresentative(),
    customer: state.customer,
    codeset: get(state, 'codeset.cachedCodesets'),
    permitTypes,
    isUploadingAttachment: get(state, 'permits.permit.isUploadingAttachment'),
    showApplicationTabActionButtons: get(state, 'config.features.SHOW_APPLICATION_TAB_ACTION_BUTTONS', false),
    isPerson,
  }
}

const mapActionCreators = {
  fetchCodeset,
  fetchNonCachedCodesets,
  fetchCustomerInformation,
  fetchRepresentativeInformation,
  fetchPermitRepresentativeInformation,
  destroyState,
  saveInitialValues,
}

export default connect(mapStateToProps, mapActionCreators)(withRouter(injectIntl(PermitPage)))
