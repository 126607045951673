import React from 'react'
import { Col, Button, Row, ButtonToolbar } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { Form } from 'react-form'
import { filter, get, findIndex, set, cloneDeep, includes } from 'lodash'
import Icon from 'src/components/Icon'
import Modal from 'src/components/Modal'
import showNotification from 'src/utils/notifications'
import { warehouseIdentifier, eori } from 'src/utils/validation'
import { focusFirstInputWithErrorInModal, formatCmsMessage } from 'src/utils'
import messages from '../messages'
import {
  PERMIT_GOODSMOVEMENT_INFOELEMENT_NAME,
  PERMIT_GOODSLOCATION_INPUT_TYPE_WAREHOUSE,
  PERMIT_GOODSLOCATION_INPUT_TYPE_EORI,
  PERMIT_GOODSLOCATION_INPUT_TYPE_ADDRESS,
  PERMIT_GOODSLOCATION_WAREHOUSE_IDENTIFIER_SEARCH,
  PERMIT_GOODSLOCATION_INPUT_TYPES_BY_TYPE_CODE,
  PERMIT_GOODSLOCATION_FIELDS_MAP,
  PERMIT_GOODSLOCATION_DEFAULT_INPUT_TYPE_WAREHOUSE_VALUES,
  PERMIT_GOODSLOCATION_DEFAULT_INPUT_TYPE_ADDRESS_VALUES,
  PERMIT_GOODSLOCATION_DEFAULT_VALUES,
} from '../constants'
import {
  validateFormValues,
} from '../utils'
import { OPO } from '../../../constants'

export default class ItemForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isNew: null,
      showDeleteConfirmationModal: false,
      infoElement: null,
    }
    this.fieldPrefix = this.props.infoElement.code
    this.mappedFields = PERMIT_GOODSLOCATION_FIELDS_MAP[this.props.infoElement.code]

    this.formApi = null
    this.connectFormApi = this.connectFormApi.bind(this)
    this.save = this.save.bind(this)
    this.cancel = this.cancel.bind(this)
    this.showDeleteModal = this.showDeleteModal.bind(this)
    this.openForm = this.openForm.bind(this)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { infoElement } = this.state
    if (nextProps.selectedItem && !infoElement) {
      this.setFieldsToState(nextProps.selectedItem)
    }
  }

  onChangeField(field, value) {
    const formApi = this.formApi || this.props.formApi
    const formValues = {
      ...formApi.getFormState().values,
      [field.code]: value,
    }

    if (field.code === this.getFieldFullCode('LegalBaseCode')) {
      this.setFieldsToState(formValues)
    }
  }

  onChangeInputType(newInputType) {
    const currentFormState = this.formApi.getFormState()
    const currentValue = get(currentFormState, `values.${this.mappedFields.location}FieldValueSource`)
    let newFormState = currentFormState
    if (currentValue !== newInputType) {
      newFormState = {
        ...currentFormState,
        values: {
          ...currentFormState.values,
          [`${this.mappedFields.location}FieldValueSource`]: newInputType,
          [`${this.mappedFields.location}`]: '',
          [`${this.fieldPrefix}Name`]: '',
          [`${this.fieldPrefix}Address`]: '',
          [`${this.fieldPrefix}PostalCode`]: '',
          [`${this.fieldPrefix}City`]: '',
          ...this.getDefaultValues(newInputType),
        },
        errors: {},
      }
      this.formApi.setFormState(newFormState)
    }
    this.setFieldsToState({
      ...newFormState.values,
      [`values.${this.mappedFields.location}FieldValueSource`]: newInputType,
    })
  }

  getFieldFullCode(code) {
    const mappedField = get(this.mappedFields, code)
    return mappedField
  }

  setFieldsToState(formValues) {
    const inputType = get(formValues, `${this.mappedFields.location}FieldValueSource`)
    const filteredFields = this.filterVisibleFields(inputType, formValues)
    const fieldIndexes = {}
    filteredFields.forEach((field2, index) => {
      fieldIndexes[field2.code] = index
      set(filteredFields, `[${index}]`, this.getFieldProps(field2, inputType, formValues).field)
    })

    this.setState({
      infoElement: {
        ...this.state.infoElement,
        fields: filteredFields,
      },
    })
  }

  getDefaultValues(selectedInputType) {
    const { permitTypeCode, selectedItem } = this.props

    let currentInputType = selectedInputType
    if (!currentInputType) {
      currentInputType = get(selectedItem, `${this.mappedFields.location}FieldValueSource`)
    }

    let defaultValues

    if (permitTypeCode === OPO && currentInputType !== PERMIT_GOODSLOCATION_INPUT_TYPE_WAREHOUSE) {
      const existingValue = this.formApi && this.formApi.getValue('processingOrUsePlaceCountryCode')

      defaultValues = {
        [get(this.mappedFields, 'countryCode')]: existingValue || '',
        [get(this.mappedFields, 'legalBaseCode')]: get(PERMIT_GOODSLOCATION_DEFAULT_VALUES, 'legalBaseCode'),
      }
    } else {
      defaultValues = {
        [get(this.mappedFields, 'countryCode')]: get(PERMIT_GOODSLOCATION_DEFAULT_VALUES, 'countryCode'),
        [get(this.mappedFields, 'legalBaseCode')]: get(PERMIT_GOODSLOCATION_DEFAULT_VALUES, 'legalBaseCode'),
      }
    }

    let defaultValuesSource = PERMIT_GOODSLOCATION_DEFAULT_VALUES
    if (currentInputType === PERMIT_GOODSLOCATION_INPUT_TYPE_WAREHOUSE) {
      defaultValuesSource = PERMIT_GOODSLOCATION_DEFAULT_INPUT_TYPE_WAREHOUSE_VALUES
    } else if (currentInputType === PERMIT_GOODSLOCATION_INPUT_TYPE_ADDRESS) {
      defaultValuesSource = PERMIT_GOODSLOCATION_DEFAULT_INPUT_TYPE_ADDRESS_VALUES
    }

    if (defaultValuesSource) {
      defaultValues = {
        ...defaultValues,
        [get(this.mappedFields, 'typeCode')]: get(
          defaultValuesSource,
          `typeCode.${permitTypeCode}`,
          get(defaultValuesSource, 'typeCode.default')
        ),
        [get(this.mappedFields, 'identificationQualifier')]: get(
          defaultValuesSource,
          `identificationQualifier.${permitTypeCode}`,
          get(defaultValuesSource, 'identificationQualifier.default')
        ),
      }
    }

    return defaultValues
  }

  getFieldProps(field, selectedInputType, values) {
    const { infoElement, permitTypeCode, intl: { formatMessage } } = this.props
    const fieldProperties = {
      field,
    }

    if (includes([
      this.getFieldFullCode('location'),
      this.getFieldFullCode('legalBaseCode'),
      this.getFieldFullCode('identificationQualifier'),
      this.getFieldFullCode('typeCode'),
      this.getFieldFullCode('countryCode'),
    ], field.code)) {
      set(fieldProperties, 'field.mandatory', true)
    }

    const isGoodsMovement = infoElement.code === PERMIT_GOODSMOVEMENT_INFOELEMENT_NAME
    if (isGoodsMovement &&
      this.getFieldFullCode('eori') === field.code &&
      get(values, get(this.mappedFields, 'legalBaseCode')) === 'C'
    ) {
      set(fieldProperties, 'field.mandatory', true)
      set(fieldProperties, 'input', {
        ...fieldProperties.input,
        validate: true,
        validation: {
          validator: {
            fn: eori,
          },
        },
      })
    }

    if (this.getFieldFullCode('countryCode') === field.code) {
      set(fieldProperties, 'field.type', 'COUNTRY_CODE')
      if (permitTypeCode === OPO) {
        set(fieldProperties, 'field.readonlyInApplication', true)
      }
    }



    if (this.getFieldFullCode('location') === field.code) {
      if (selectedInputType === PERMIT_GOODSLOCATION_INPUT_TYPE_WAREHOUSE) {
        const locationFieldHelpText = formatCmsMessage(
          formatMessage,
          `/permits/fields/${this.mappedFields.location}Warehouse`
        )
        set(fieldProperties, 'field.name', {
          fi: 'Varastotunniste',
          sv: 'Identifieringsnummer för lagret',
        })
        set(fieldProperties, 'field.help', {
          fi: locationFieldHelpText,
          sv: locationFieldHelpText,
        })
        set(fieldProperties, 'input', {
          ...fieldProperties.input,
          validate: true,
          validation: {
            validator: {
              fn: warehouseIdentifier,
            },
          },
        })
      }

      if (selectedInputType === PERMIT_GOODSLOCATION_INPUT_TYPE_EORI) {
        const locationFieldHelpText = formatCmsMessage(
          formatMessage,
          `/permits/fields/${this.mappedFields.location}Eori`
        )
        set(fieldProperties, 'field.name', {
          fi: 'EORI-numero',
          sv: 'EORI-numret',
        })
        set(fieldProperties, 'field.help', {
          fi: locationFieldHelpText,
          sv: locationFieldHelpText,
        })
        set(fieldProperties, 'input', {
          ...fieldProperties.input,
          validate: true,
          validation: {
            validator: {
              fn: eori,
            },
          },
        })
      }
    }

    return fieldProperties
  }

  getValuesAndFilter(formApi) {
    const selectedInputType = get(formApi.values, `${this.mappedFields.location}FieldValueSource`)
    const filteredFields = this.filterVisibleFields(selectedInputType, formApi.values)
    const locationFieldIndex = findIndex(filteredFields, { code: this.getFieldFullCode('location') })
    return {
      selectedInputType,
      filteredFields,
      locationFieldIndex,
    }
  }

  copyInfoElementToState() {
    this.setState({ infoElement: this.props.infoElement })
  }
  clearInfoElementInState() {
    this.setState({ infoElement: null })
  }

  connectFormApi(formApi) {
    this.formApi = formApi
    this.formApi.setAllValues({
      ...this.getDefaultValues(),
    })
  }

  cancel(e) {
    if (e) {
      e.preventDefault()
    }

    if (this.state.isNew) {
      this.setState({ isNew: false })
    } else {
      this.props.cancelEditing()
    }
    this.clearInfoElementInState()
  }

  // Needs refactoring, similar logic in multiple locations
  save(formData) {
    const { addNew, updateItem, selectedItem } = this.props
    const { infoElement } = this.state
    const { isNew } = this.state
    const isValid = validateFormValues(infoElement, formData)

    if (isValid) {
      if (isNew) {
        addNew(formData)
        this.afterDoneSaving()
      } else if (selectedItem) {
        updateItem(formData)
        this.afterDoneSaving()
      }
    }
  }

  afterDoneSaving() {
    this.cancel()
    showNotification({
      level: 'success',
      message: messages.doneSavingItem,
      autoDismiss: true,
    })
  }

  showDeleteModal() {
    this.setState({
      showDeleteConfirmationModal: true,
    })
  }

  openForm(isNew) {
    this.copyInfoElementToState()
    this.setState({ isNew })
  }

  filterVisibleFields(selectedInputType, values) {
    const { infoElement } = this.props
    let fieldsCopy = cloneDeep(infoElement.fields)

    fieldsCopy = filter(fieldsCopy, field => field.code !== this.getFieldFullCode('additionalIdentifier'))

    if (get(values, this.getFieldFullCode('legalBaseCode')) !== 'C') {
      fieldsCopy = filter(fieldsCopy, field => field.code !== this.getFieldFullCode('eori'))
    }

    if (selectedInputType !== PERMIT_GOODSLOCATION_INPUT_TYPE_ADDRESS) {
      return fieldsCopy
    }

    return filter(fieldsCopy, field => field.code !== this.getFieldFullCode('countryCode'))
  }

  renderVisibleFields(formApi) {
    const { renderField, locale, intl: { formatMessage } } = this.props
    const {
      selectedInputType,
      filteredFields,
      locationFieldIndex,
    } = this.getValuesAndFilter(formApi)

    if (!selectedInputType) {
      return null
    } else if (selectedInputType === PERMIT_GOODSLOCATION_INPUT_TYPE_WAREHOUSE ||
      selectedInputType === PERMIT_GOODSLOCATION_INPUT_TYPE_EORI) {
      set(filteredFields, `[${locationFieldIndex}].type`, 'TEXT')
    } else if (selectedInputType === PERMIT_GOODSLOCATION_INPUT_TYPE_ADDRESS) {
      set(filteredFields, `[${locationFieldIndex}].type`, 'LOCATION')
    }


    return (
      <div>
        {filteredFields.map((field, index) => {
          const isLocationField = selectedInputType === PERMIT_GOODSLOCATION_INPUT_TYPE_WAREHOUSE &&
            index === locationFieldIndex
          const fieldSpecificProps = this.getFieldProps(field, selectedInputType, formApi.values)

          return (
            <Row key={`row-${field.code}`}>
              <Col xs={isLocationField ? 6 : 12}>
                {renderField({
                  ...fieldSpecificProps,
                  formApi,
                  input: {
                    ...fieldSpecificProps.input,
                    onChange: newValue => this.onChangeField(field, newValue),
                  },
                })}
              </Col>
              {isLocationField && <Col xs={6}>
                <div>
                  <br />
                  <a
                    style={{ fontSize: '1.6rem', textDecoration: 'underline' }}
                    href={PERMIT_GOODSLOCATION_WAREHOUSE_IDENTIFIER_SEARCH[locale]}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {formatMessage(messages.openWarehouseIdentifierSearch)}
                    <Icon name="external" />
                  </a>
                </div>
              </Col>}
            </Row>
          )
        })}
      </div>
    )
  }

  renderInputTypeSelector(fieldCode, formApi) {
    const { renderField, permitTypeCode, infoElement } = this.props

    const visibleInputTypes = PERMIT_GOODSLOCATION_INPUT_TYPES_BY_TYPE_CODE[permitTypeCode]
    const inputTypeOptions = []
    if (!visibleInputTypes || includes(visibleInputTypes, PERMIT_GOODSLOCATION_INPUT_TYPE_WAREHOUSE)) {
      inputTypeOptions.push({
        title: messages.optionWarehouseIdentifier,
        value: PERMIT_GOODSLOCATION_INPUT_TYPE_WAREHOUSE,
      })
    }
    if (!visibleInputTypes || includes(visibleInputTypes, PERMIT_GOODSLOCATION_INPUT_TYPE_EORI)) {
      inputTypeOptions.push({
        title: messages.optionEori,
        value: PERMIT_GOODSLOCATION_INPUT_TYPE_EORI,
      })
    }
    if (!visibleInputTypes || includes(visibleInputTypes, PERMIT_GOODSLOCATION_INPUT_TYPE_ADDRESS)) {
      inputTypeOptions.push({
        title: messages.optionAddress,
        value: PERMIT_GOODSLOCATION_INPUT_TYPE_ADDRESS,
      })
    }

    return renderField({
      field: {
        visible: true,
        code: fieldCode,
        help: {
          fi: '',
        },
        mandatory: infoElement.mandatory,
        infoElement,
        name: {
          en: '?? Sijaintipaikan syöttötapa',
          fi: 'Sijaintipaikan syöttötapa',
          sv: 'Välj inmatningssätt',
        },
        originalHelp: {},
        readonlyInApplication: false,
        type: 'RADIO',
      },
      options: inputTypeOptions,
      formApi,
      input: {
        onChange: newValue => this.onChangeInputType(newValue),
      },
      staticValue: false,
      autoFocus: true,
      validate: false,
    })
  }

  render() {
    const {
      selectedItem,
      error,
      deleteItem,
      infoElement,
    } = this.props

    const { isNew, showDeleteConfirmationModal } = this.state
    const titleMessage = isNew ? messages.newItemHeading : messages.itemEditHeading

    const slidingContentStyle = {
      transition: 'transform 200ms ease-in-out',
    }

    return (
      <div>
        <ButtonToolbar>
          <Button
            bsStyle="primary"
            onClick={() => this.openForm(true)}
            id={`new-row-${infoElement.code}`}
            aria-describedby={`new-row-description-${infoElement.code}`}
            id-qa-test={`button-add-${infoElement.code}`}
          >
            <Icon name="add" /><FormattedMessage {...messages.add} />
          </Button>
        </ButtonToolbar>
        <Modal
          show={Boolean(isNew || selectedItem)}
          size="lg"
          showCancel={false}
          showContinue={false}
          onEscKey={this.cancel}
          titleMessage={titleMessage}
          animation={false}
          contentRef={(ref) => { this.modalContentRef = ref }}
        >
          <Modal
            show={showDeleteConfirmationModal}
            showContinue
            showCancel
            titleMessage={messages.deleteItemText}
            continueMessage={messages.continueDeleteConfirmation}
            cancelMessage={messages.ignoreDeleteConfirmation}
            onClickCancel={() => this.setState({ showDeleteConfirmationModal: false })}
            onClickContinue={() => {
              deleteItem(selectedItem.id)
              this.setState({ showDeleteConfirmationModal: false })
              this.props.cancelEditing()
            }}
            focusDisableButton
          >
            <Row>
              <Col xs={2}>
                <Icon
                  name={'delete'}
                  style={{ display: 'block', fontSize: '26px' }}
                />
              </Col>
              <Col xs={10}>
                <p className="lead">
                  <FormattedMessage {...messages.deleteItemConfirmation} />
                </p>
              </Col>
            </Row>
          </Modal>
          <div
            ref={(ref) => { this.contentRef = ref }}
            style={slidingContentStyle}
            onTransitionEnd={this.onScrollToCN8TransitionEnd}
          >
            <Form
              defaultValues={selectedItem && selectedItem}
              getApi={this.connectFormApi}
              onSubmit={this.save}
              render={formApi =>
                <form>
                  <Row>
                    <Col xs={12}>
                      {this.renderInputTypeSelector(
                        `${this.mappedFields.location}FieldValueSource`,
                        formApi,
                      )}
                    </Col>
                  </Row>
                  {this.renderVisibleFields(formApi)}
                  <Button
                    key="modalCancel"
                    className="pull-left"
                    onClick={this.cancel}
                    id-qa-test="button-cancel"
                  >
                    <FormattedMessage {...messages.cancel} />
                  </Button>
                  <Button
                    key="modalSaveAndClose"
                    bsStyle="primary"
                    className="pull-right"
                    onClick={formData => formApi.submitForm(formData).then(focusFirstInputWithErrorInModal)}
                    id-qa-test="button-save"
                    disabled={!formApi.values[`${this.mappedFields.location}FieldValueSource`]}
                  >
                    <Icon name="addnew" /><FormattedMessage {...messages.save} />
                  </Button>
                  {!isNew && selectedItem &&
                    <Button
                      key="modalDelete"
                      bsStyle="default"
                      className="pull-right"
                      style={{ marginRight: '20px' }}
                      onClick={this.showDeleteModal}
                      id-qa-test="button-delete"
                    >
                      <Icon name="delete" /><FormattedMessage {...messages.delete} />
                    </Button>
                  }
                </form>
              }
            />
            <Row>
              <Col md={8} sm={12}>
                {error && error.id &&
                  <div className="text-danger text-right" role="alert" id-qa-test="label-error">
                    <FormattedMessage {...error} />
                  </div>
                }
              </Col>
            </Row>
          </div>
        </Modal>
      </div>
    )
  }
}
