import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { injectIntl } from 'react-intl'
import { get } from 'lodash'

import { getEnabledPermitGroups } from 'src/utils/features'
import { isApplicantPerson } from 'src/utils/auth'

import PermitSelectorV2 from '../components/PermitSelector/PermitSelectorV2'
import { getApplicant, getRepresentative } from '../permitHelper'

const mapStateToProps = (state, ownProps) => ({
  applicant: getApplicant(),
  representative: getRepresentative(),
  isAuthenticated: state.auth.isAuthenticated,
  isAuthenticating: state.auth.isAuthenticating,
  authorizations: state.auth.authorizations,
  permitTypes: state.permits.common.permitTypes,
  locale: state.locale,
  isPerson: isApplicantPerson(),
  customer: get(state, 'customer'),
  isFetchingCustomer: get(state, 'customer.fetchingCustomer'),
  permitGroupMap: getEnabledPermitGroups(get(state, 'config.features'), isApplicantPerson()),
  ...ownProps,
})

const mapActionCreators = {

}

export default withRouter(
  connect(mapStateToProps, mapActionCreators)(
    injectIntl(PermitSelectorV2)
  )
)
