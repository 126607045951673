import { defineMessages } from 'react-intl'
import commonIntrastatMessages from '../messages'

const messages = defineMessages({
  appName: {
    id: 'header.appName',
    description: 'Application name heading shown in header',
    defaultMessage: 'Online services channel',
  },
  title: {
    id: 'intrastat.list.title',
    description: 'Title for monitoring page',
    defaultMessage: 'Intrastat declarations',
  },
  createNewDeclaration: {
    id: 'intrastat.list.newDeclaration',
    description: 'Label for create new declaration button in declarations listing',
    defaultMessage: 'Create new intrastat declaration',
  },
  noDeclarations: {
    id: 'intrastat.list.noDeclarations',
    description: 'Text to display when there are zero declarations in either of the declaration lists',
    defaultMessage: 'No declarations',
  },
  loadingDeclarations: {
    id: 'intrastat.listing.loading',
    description: 'Info text shown while loading declarations list',
    defaultMessage: 'Loading...',
  },
  declarationsListFetchError: {
    id: 'intrastat.listing.fetchError',
    description: 'Error text shown when declarations could not be fetched',
    defaultMessage: 'Could not fetch all declarations',
  },
  tabLabelDraftDeclarations: {
    id: 'intrastat.tabLabelDraftDeclarations',
    description: 'Label for draft declarations tab',
    defaultMessage: 'In progress',
  },
  tabLabelReadyDeclarations: {
    id: 'intrastat.tabLabelReadyDeclarations',
    description: 'Label for ready declarations tab',
    defaultMessage: 'Completed',
  },
  columnLabelDeclarationId: {
    id: 'intrastat.listing.columnLabelDeclarationId',
    description: 'Label for Intrastat declarations listing column "statistical customs id"',
    defaultMessage: 'Declaration id',
  },
  columnLabelCompany: {
    id: 'intrastat.listing.columnLabelCompany',
    description: 'Label for Intrastat declarations listing column "company" (provider of statistical information)',
    defaultMessage: 'Company',
  },
  columnLabelReference: {
    id: 'intrastat.listing.columnLabelReference',
    description: 'Label for Intrastat declarations listing column "customer reference"',
    defaultMessage: 'Customer reference',
  },
  columnLabelDateModified: {
    id: 'intrastat.listing.columnLabelDateModified',
    description: 'Label for Intrastat declarations listing column "date modified"',
    defaultMessage: 'Modified',
  },
  columnLabelDateSent: {
    id: 'intrastat.listing.columnLabelDateSent',
    description: 'Label for Intrastat declarations listing column "date sent"',
    defaultMessage: 'Sent',
  },
  columnLabelFlowCode: {
    id: 'intrastat.listing.columnLabelFlowCode',
    description: 'Label for Intrastat declarations listing column "flow code" (direction dispatch or arrival)',
    defaultMessage: 'Flow code',
  },
  columnLabelPeriod: {
    id: 'intrastat.listing.columnLabelPeriod',
    description: 'Label for Intrastat declarations listing column "reference period"',
    defaultMessage: 'Period',
  },
  columnLabelStatus: {
    id: 'intrastat.listing.columnLabelStatus',
    description: 'Label for Intrastat declarations listing column "status"',
    defaultMessage: 'Status',
  },
  typeImport: {
    id: 'intrastat.listing.typeImport',
    description: 'Value for Intrastat declarations list flow code "import"',
    defaultMessage: 'Import',
  },
  typeExport: {
    id: 'intrastat.listing.typeExport',
    description: 'Value for Intrastat declarations list flow code "export"',
    defaultMessage: 'Export',
  },
  loadingRow: {
    id: 'intrastat.listing.loadingRow',
    description: 'Text shown on a row that is being fetched from server',
    defaultMessage: 'Loading declaration...',
  },
  errorLoadingRow: {
    id: 'intrastat.listing.errorLoadingRow',
    description: 'Text shown on a row that could not be fetched from server',
    defaultMessage: 'Could not load declaration.',
  },
})

export default {
  ...commonIntrastatMessages,
  ...messages,
}
