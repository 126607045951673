export const TARIC_CODE_FIELD_NAME = {
  fi: 'Tullinimike',
  sv: 'Varukod',
  en: '',
}

// Settings for goodsToEnjoyReliefFromDuties infoElement
export const PERMIT_GOODS_TO_ENJOY_RELIEF_FROM_DUTIES_FIELD_PREFIX = 'goodsToEnjoyReliefFromDuties'
export const PERMIT_GOODS_TO_ENJOY_RELIEF_FROM_DUTIES_CODE_FIELD_NAME = 'combinedNomenclatureCode'
export const PERMIT_GOODS_TO_ENJOY_RELIEF_FROM_DUTIES_DESCRIPTION_FIELD_NAME = 'goodsDescription'
export const PERMIT_GOODS_TO_ENJOY_RELIEF_FROM_DUTIES_QUANTITY_FIELD_NAME = 'goodsQuantity'
export const PERMIT_GOODS_TO_ENJOY_RELIEF_FROM_DUTIES_CURRENCY_FIELD_NAME = 'goodsCurrency'
export const PERMIT_GOODS_TO_ENJOY_RELIEF_FROM_DUTIES_AMOUNT_FIELD_NAME = 'goodsAmount'
export const PERMIT_GOODS_TO_ENJOY_RELIEF_FROM_DUTIES_COUNTRY_FIELD_NAME = 'countryOfConsignment'
export const PERMIT_GOODS_TO_ENJOY_RELIEF_FROM_DUTIES_ADDITIONAL_INFORMATION_FIELD_NAME = 'additionalInformation'
export const PERMIT_GOODS_TO_ENJOY_RELIEF_FROM_DUTIES_MEASUREMENT_UNIT_FIELD_NAME = 'measurementUnit'

// Settings for goodsToBePlacedUnderProcedure infoElement
export const PERMIT_GOODS_FIELD_PREFIX = 'goodsToBePlacedUnderProcedure'
export const PERMIT_GOODS_CODE_FIELD_NAME = 'CombinedNomenclatureAndTaricCode'
export const PERMIT_GOODS_UNDER_PROCEDURE_BASIC_FEATURES = [
  `${PERMIT_GOODS_FIELD_PREFIX}${PERMIT_GOODS_CODE_FIELD_NAME}`,
  `${PERMIT_GOODS_FIELD_PREFIX}Description`,
]

// Settings for equivalentGood infoElement
export const PERMIT_EQUIVALENT_GOOD_FIELD_PREFIX = 'equivalentGood'
export const PERMIT_EQUIVALENT_GOOD_CODE_FIELD_NAME = 'CombinedNomenclatureCode'
export const PERMIT_EQUIVALENT_GOOD_BASIC_FEATURES = [
  `${PERMIT_EQUIVALENT_GOOD_FIELD_PREFIX}${PERMIT_EQUIVALENT_GOOD_CODE_FIELD_NAME}`,
  `${PERMIT_EQUIVALENT_GOOD_FIELD_PREFIX}Description`,
]

// Settings for processedProduct infoElement
export const PERMIT_PROCESSED_PRODUCT_FIELD_PREFIX = 'processedProduct'
export const PERMIT_PROCESSED_PRODUCT_CODE_FIELD_NAME = 'CombinedNomenclatureCode'
export const PERMIT_PROCESSED_PRODUCT_QUANTITY_FEATURES = [
  `${PERMIT_GOODS_FIELD_PREFIX}Quantity`,
  `${PERMIT_GOODS_FIELD_PREFIX}MeasurementUnit`,
]

// Settings for replacementProduct infoElement
export const PERMIT_REPLACEMENT_PRODUCT_FIELD_PREFIX = 'replacementProduct'
export const PERMIT_REPLACEMENT_PRODUCT_CODE_FIELD_NAME = 'TaricCode'

export const PERMIT_ORIGINATING_PRODUCT_FIELD_PREFIX = 'originatingGoods'
export const PERMIT_ORIGINATING_PRODUCT_CODE_FIELD_NAME = 'CombinedNomenclature'

// Requirements between fields. Only works with fields in the same info element.
// Both the keys and values are field codes.
// If the value field is filled, the key field is treated as mandatory.
export const FIELD_REQUIRED_BY = {
  'guaranteeAmount.guaranteeAmount': 'guaranteeAmount.guaranteeAmountCurrency',
  'guaranteeAmount.guaranteeAmountCurrency': 'guaranteeAmount.guaranteeAmount',
  [`${PERMIT_GOODS_FIELD_PREFIX}MeasurementUnit`]: `${PERMIT_GOODS_FIELD_PREFIX}Quantity`,
  [`${PERMIT_GOODS_FIELD_PREFIX}Quantity`]: `${PERMIT_GOODS_FIELD_PREFIX}MeasurementUnit`,
  [`${PERMIT_GOODS_FIELD_PREFIX}Currency`]: `${PERMIT_GOODS_FIELD_PREFIX}Amount`,
  [`${PERMIT_GOODS_FIELD_PREFIX}Amount`]: `${PERMIT_GOODS_FIELD_PREFIX}Currency`,
}

export const APPLICANT_ROLE_MESSAGE_NOTIFIER = '1'
export const APPLICANT_ROLE_SERVICE_PROVIDER = '2'
export const APPLICANT_ROLE_SOFTWARE_HOUSE = '3'

export const hiddenInfoElements = [
  'applicationType',
  'issueNumber',
  'signature',
  'attachedDocumentCount',
  'releaseForFreeCirculationByDischargeBill',
  'decisionReferenceNumber', // TBA AL-775
]
